import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
} from '@material-ui/core';
import {
  DragHandle,
  LockOutlined,
} from '@material-ui/icons';
import { useReorderChild } from 'hooks/useReorder';

const useStyles = makeStyles(() => ({
  root: {
    cursor: 'move',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1rem 0',
    '&.disabled': {
      cursor: 'not-allowed',
    },
  },
  name: {
    fontWeight: 600,
  },
  actionsWrapper: {
    width: '260px',
    justifyContent: 'flex-end',
    display: 'flex',
    alignItems: 'center',
  },
  removeLock: {
    fill: '#BCBCBC',
    marginRight: '.75rem',
  },
  removeLink: {
    marginRight: '.75rem',
  },
}));

const BioQuestionSetAccordionRow = ({
  className,
  disabled,
  id,
  index,
  isDefault,
  moveQuestion,
  name,
  onDelete,
  onReorder,
  removeText,
  onFailedDrop,
}) => {
  const classes = useStyles();

  // ABSTRACTION!
  const {
    handlerId, ref, opacity,
  } = useReorderChild({
    index,
    moveFunction: moveQuestion,
    onReorder,
    id,
    disabled,
    onFailedDrop,
  });

  const getDeleteOptions = () => {
    if (isDefault) {
      return (<LockOutlined className={classes.removeLock} />);
    }

    return (
      <Button className={classes.removeLink} disabled={disabled} onClick={onDelete} color="primary">
        {removeText}
      </Button>
    );
  };

  return (
    <div
      key={id}
      ref={ref}
      className={disabled ? `${className} ${classes.root} disabled` : `${className} ${classes.root}`}
      style={{ opacity }}
      data-handler-id={handlerId}
    >
      <div className={classes.name}>
        {name}
      </div>

      <div>
        <div className={classes.actionsWrapper}>
          {getDeleteOptions()}
          <DragHandle color={disabled ? 'disabled' : 'inherit'} />
        </div>
      </div>
    </div>
  );
};

BioQuestionSetAccordionRow.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isDefault: PropTypes.bool,
  id: PropTypes.number,
  index: PropTypes.number,
  moveQuestion: PropTypes.func,
  name: PropTypes.string,
  onDelete: PropTypes.func,
  onReorder: PropTypes.func,
  removeText: PropTypes.string,
  onFailedDrop: PropTypes.func,
};

BioQuestionSetAccordionRow.defaultProps = {
  className: '',
  disabled: false,
  isDefault: false,
  id: null,
  index: null,
  moveQuestion: () => {},
  name: '',
  onDelete: () => {},
  onReorder: () => {},
  onFailedDrop: () => {},
  removeText: 'Remove from set',
};

export default BioQuestionSetAccordionRow;
