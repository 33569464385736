import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';
import Interweave from 'interweave';
import introImage from '../../../../images/stepIntro.png';

const useStyles = makeStyles((theme) => ({
  image: {
    // TODO: update so width and height isn't fixed
    width: '494px',
    height: '577px',
    objectFit: 'contain',
    borderRadius: '4px',
    boxShadow: '0px 10px 17px rgba(0, 0, 0, 0.12)',
  },
  heading: {
    marginBottom: '1rem',
  },
  subtitle: {
    fontSize: '18px',
  },
  listHeading: {
    color: theme.palette.primary.main,
    fontWeight: '600',
  },
  list: {
    fontSize: '14px',
    '& .MuiListItem-root': {
      padding: '0.75rem 0',
    },
    '& a': {
      color: theme.palette.primary.main,
    },
  },
  contentContainer: {
    flexGrow: 1,
    display: 'flex',
    margin: '1rem',
    maxHeight: '80vh',
    overflow: 'auto',
  },
  contentActions: {
    backgroundColor: '#eef4f8',
    display: 'block',
    padding: '.8rem 2rem',
  },
  actions: {
    textAlign: 'right',
    marginTop: '.75em',
    marginBottom: '.75em',
  },
  modalStyle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '98%',
    boxShadow: '0px 2px 4px rgba(0,0,0,0.18)',
    [theme.breakpoints.up('sm')]: {
      width: '70%',
    },
  },
  closeButton: {
    position: 'absolute',
    top: '0',
    right: '0',
    [theme.breakpoints.up('sm')]: {
      top: '1rem',
      right: '1rem',
    },
  },
}));

// eslint-disable-next-line react/display-name
const HowToModal = ({ messages, onClose }) => {
  const classes = useStyles();

  return (
    <Box className={classes.modalStyle}>
      <Card variant="outlined">
        <CardContent className={classes.contentContainer}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box sx={{ width: '75%' }} marginBottom={4}>
                <Typography variant="h1" paragraph>
                  {messages.title}
                </Typography>
                <Typography variant="body1" className={classes.subtitle}>
                  {messages.subtitle}
                </Typography>
              </Box>

              <Grid container spacing={4}>
                <Grid item xs={12} sm={5}>
                  <Typography variant="h3">
                    {messages.list.title}
                  </Typography>
                  <Typography variant="body1">
                    {messages.list.subtitle}
                  </Typography>

                  <List className={classes.list}>
                    <ListItem disableGutters>
                      <ListItemText>
                        <Typography className={classes.listHeading}>
                          {messages.list.race.title}
                        </Typography>
                        <Typography>
                          {messages.list.race.supporting}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <Divider />

                    <ListItem disableGutters>
                      <ListItemText>
                        <Typography className={classes.listHeading}>
                          {messages.list.candidate.title}
                        </Typography>
                        <Typography>
                          <Interweave content={messages.list.candidate.supporting} />
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <Divider />

                    <ListItem disableGutters>
                      <ListItemText>
                        <Typography className={classes.listHeading}>
                          {messages.list.bio.title}
                        </Typography>
                        <Typography>
                          <Interweave content={messages.list.bio.supporting} />
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <Divider />

                    <ListItem disableGutters>
                      <ListItemText>
                        <Typography className={classes.listHeading}>
                          {messages.list.raceQuestions.title}
                        </Typography>
                        <Typography>
                          <Interweave content={messages.list.raceQuestions.supporting} />
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <Divider />

                    <ListItem disableGutters>
                      <ListItemText>
                        <Typography className={classes.listHeading}>
                          {messages.list.districts.title}
                        </Typography>
                        <Typography>
                          <Interweave content={messages.list.districts.supporting} />
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <Divider />

                    <ListItem disableGutters>
                      <ListItemText>
                        <Typography>
                          <Interweave content={messages.list.bulk} />
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <img src={introImage} className={classes.image} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>

        <CardActions className={classes.contentActions}>
          <Grid container justifyContent="flex-end" alignItems="center">
            <Grid item xs={12} sm={6} className={classes.actions}>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                onClick={onClose}
              >
                Continue
              </Button>
            </Grid>
          </Grid>
        </CardActions>
        <IconButton
          color="primary"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </Card>
    </Box>
  );
};

HowToModal.propTypes = {
  messages: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

HowToModal.defaultProps = {
  onClose: () => {},
};

export default HowToModal;
