import React from 'react';
import PropTypes from 'prop-types';
import { createStyles, withStyles } from '@material-ui/core/styles';

const styles = ({ palette }) => createStyles({
  breadcrumb: {
    fontSize: '.8rem',
    color: '#666',
    marginBottom: '.4rem',
    '&:hover': {
      color: palette.primary.main,
      '& a': {
        color: palette.primary.main,
      },
    },
    '& a': {
      color: '#666',
      textDecoration: 'none',
    },
  },
});

const Breadcrumb = ({
  classes,
  text,
  url,
  otherClasses,
}) => (
  <div
    className={`${classes.breadcrumb} ${otherClasses}`}
    data-testid="breadcrumbs"
  >
    &lt;{' '}
    <a
      href={url}
      data-testid="breadcrumbs-link"
    >
      {text}
    </a>
  </div>
);

Breadcrumb.propTypes = {
  classes: PropTypes.object,
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  otherClasses: PropTypes.string,
};

export default withStyles(styles)(Breadcrumb);
