import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardContent,
  DialogContent,
  Divider,
  Grid,
  Modal,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  CategoryEditModalForm,
  CategoryRaceRow,
} from 'components/Categories';
import { useReorderParent } from 'hooks/useReorder';
import fetchUtil from 'helpers/Fetch';

const useStyles = makeStyles({
  root: {
    margin: '12px 0 16px',
  },
  actionButton: {
    minWidth: '75px',
  },
  destructiveButton: {
    color: '#ac1b3d',
    marginRight: '1rem',
    minWidth: '75px',
    '&:hover': {
      color: '#6B1126',
    },
  },
  disabled: {
    cursor: 'not-allowed',
  },
  rowContainer: {
    padding: '16px',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
});

const CategoryExpanded = ({
  category,
  clearErrors,
  errors,
  guideId,
  messages,
  onDelete,
  onEdit,
  onError,
  submittingEdit,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [catRaces, setCatRaces] = useState([...category.races]);

  const {
    disabled,
    moveItem: moveRace,
    onFailedDrop,
    onReorder,
    setDisabled,
    setOriginalCatRaces,
  } = useReorderParent({
    dataList: catRaces,
    setDataList: setCatRaces,
    originalStateName: 'originalCatRaces',
    apiEndpoint: `/api/v1/guide/${guideId}/category/${category.id}/reorder`,
    apiParamName: 'races',
    showError: () => onError('reorder', 'There was an issue trying to reorder your race. Try again or contact your guide admin if this continues'),
  });

  const handleEditClicked = () => setOpen(true);

  const handleClose = () => {
    clearErrors();
    setOpen(false);
  };

  const handleSubmit = (newName, newDescription) => {
    const newCategory = JSON.parse(JSON.stringify(category));
    newCategory.title = newName;
    newCategory.description = newDescription;
    // call passed in method here
    onEdit(newCategory, () => {
      handleClose();
    });
  };

  const sortRaces = (races) => {
    const copyRaces = races || [...catRaces];
    copyRaces.sort((a, b) => {
      const ac = a.name.toUpperCase();
      const bc = b.name.toUpperCase();
      if (ac > bc) {
        return 1;
      }
      if (bc > ac) {
        return -1;
      }
      return 0;
    });
    return copyRaces;
  };

  const handleAlphaSortRaces = () => {
    setDisabled(true);

    const copyRaces = sortRaces();
    const newRaceIndices = copyRaces.map((d, index) => ({ id: d.id, index }));

    fetchUtil(`/api/v1/guide/${guideId}/category/${category.id}/reorder`, 'PUT', { races: newRaceIndices })
      .then(() => {
        setCatRaces(copyRaces);
        setOriginalCatRaces(copyRaces);
        setDisabled(false);
      }).catch((err) => {
        console.error(err);
        onError('reorder', 'There was an issue trying to reorder your race. Try again or contact your guide admin if this continues');
        setDisabled(false);
      });
  };

  const handlePreview = (race) => {
    fetchUtil(`/api/v1/guide/${guideId}/race/${race.id}/preview`, 'GET')
      .then((data) => {
        if (data.link && data.loginCode) {
          const form = document.createElement('form');
          form.method = 'POST';
          form.target = '_blank';
          form.action = data.link;

          const usernameInput = document.createElement('input');
          usernameInput.name = 'username';
          usernameInput.value = data.username;
          form.appendChild(usernameInput);

          const loginCode = document.createElement('input');
          loginCode.name = 'loginCode';
          loginCode.value = data.loginCode;
          form.appendChild(loginCode);

          const ivSpec = document.createElement('input');
          ivSpec.name = 'ivSpec';
          ivSpec.value = data.ivSpec;
          form.appendChild(ivSpec);

          document.body.append(form);
          form.submit();
          form.parentNode.removeChild(form);
        }
      }).catch(() => {
        const err = 'The system encountered an issue loading the race preview.';
        console.error(err);
        onError('redirect', err);
      });
  };

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={2}
    >
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} className="ga-my-16">
                <Grid container justifyContent="space-between">
                  <Grid item>
                    {catRaces ? catRaces.length : 0} {messages.expanded.nbrRacesCat}
                  </Grid>
                  <Grid item>
                    {catRaces.length > 0 && (
                      <Button variant="outlined" color="secondary" onClick={handleAlphaSortRaces} disabled={disabled}>
                        Sort by A-Z
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <div>
                  <DndProvider backend={HTML5Backend}>
                    {catRaces && catRaces.map((row, index) => (
                      <CategoryRaceRow
                        canReorder={category.id != -1}
                        disabled={disabled}
                        handlePreview={handlePreview}
                        index={index}
                        key={row.id}
                        moveRace={moveRace}
                        onReorder={onReorder}
                        onFailedDrop={onFailedDrop}
                        race={row}
                        setDisabled={setDisabled}
                      />
                    ))}
                  </DndProvider>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      {category.id !== -1
        && !category.default
        && <Grid container justifyContent="flex-end" alignItems="center">
          <Grid item>
            <Button
              className={classes.destructiveButton}
              color="secondary"
              onClick={() => onDelete(category)}
              size="medium"
              variant="text"
            >
              {messages.expanded.delete}
            </Button>

            <Button
              className={classes.actionButton}
              color="primary"
              onClick={() => handleEditClicked()}
              size="medium"
              variant="text"
            >
              {messages.expanded.edit}
            </Button>

            <Modal
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              onClose={handleClose}
              open={open}
            >
              <DialogContent>
                <CategoryEditModalForm
                  description={category.description}
                  errors={errors}
                  messages={messages.edit}
                  name={category.title}
                  onClose={handleClose}
                  onSubmit={handleSubmit}
                  submitting={submittingEdit}
                />
              </DialogContent>
            </Modal>
          </Grid>
        </Grid>
      }

    </Grid>
  );
};

CategoryExpanded.propTypes = {
  category: PropTypes.object.isRequired,
  clearErrors: PropTypes.func.isRequired,
  errors: PropTypes.object,
  guideId: PropTypes.number.isRequired,
  messages: PropTypes.shape({
    existing: PropTypes.object,
    delete: PropTypes.object,
    expanded: PropTypes.object,
    edit: PropTypes.object,
  }).isRequired,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onError: PropTypes.func.isRequired,
  submittingEdit: PropTypes.bool.isRequired,
};

CategoryExpanded.defaultProps = {
  errors: {},
  onDelete: () => {},
  onEdit: () => {},
};

export default CategoryExpanded;
