import React, { useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Divider,
  Grid,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import Rsc from 'react-scrollbars-custom';
import HelpfulHint from 'components/HelpfulHint';
import PageActionsHeader from 'components/PageActionsHeader';
import PageHeader from 'components/PageHeader';
import SnackbarAlert from 'components/SnackbarAlert';
import WYSIWYG from 'components/WYSIWYG';
import fetchUtil from 'helpers/Fetch';
import GaUtils from 'helpers/GaUtils';
import theme from 'scripts/theme';

const SendLetterContainer = ({
  breadcrumbText,
  breadcrumbUrl,
  candidates,
  guideId,
  letterContent,
  messages,
  navigation,
}) => {
  const msgHeader = messages.header;
  const msgActs = messages.pageActions;

  const objLetter = {
    id: 1,
    templateType: 'invitation',
    macros: [
      { id: '#question_list()', description: "Displays the questions for the candidate's race." },
      { id: '#question_responses()', description: 'Displays the answer responses submitted by the candidate.' },
      { id: '#mailing_address()', description: "Displays the candidate's mailing address." },
      { id: '#today()', description: "Displays today's date." },
      { id: '#candidate_name()', description: "Displays the candidate's full name." },
      { id: '#contact_name()', description: 'Displays the contact name for a candidate.' },
      { id: '#race_name()', description: 'Displays the name of the race.' },
      // hiding for launch
      // { id: '#race_url()', description: "Displays the URL to the candidate's race page." },
      // { id: '#candidate_url()', description: "Displays the URL to the candidate's detail page." },
      { id: '#response_link()', description: 'Displays the URL where the candidate can input his/her response.' },
      // { id: '#response_url_short()', description: 'Displays the URL to the candidate login page.' },
      { id: '#security_code()', description: "Displays the candidate's 8-digit password." },
    ],
  };

  const [savedWYSIWYGValue, setSavedWYSIWYGValue] = useState(letterContent);
  const renderedWYSIWYGValue = useMemo(() => letterContent, [letterContent]);

  let candidateNotInvited = false;
  let sendToContacts = '';
  const candidateIds = [];
  if (candidates && candidates.length > 0) {
    let isFirst = true;
    candidates.forEach(c => {
      if (isFirst) {
        isFirst = false;
        sendToContacts += `${c.name}`;
      } else {
        sendToContacts += `, ${c.name}`;
      }
      // check if there are candidates not invited yet
      if (c.state && c.state.id == 1) {
        candidateNotInvited = true;
      }

      candidateIds.push(c.id);
    });
  }

  const _snackbar = useRef();
  // hooks

  // Functions
  const handleDownloadLabels = () => {
    const urlRequest = `/api/v1/guide/${guideId}/downloadLabels`;
    const body = { candidateIds };

    fetchUtil(urlRequest, 'POST', body, true)
      .then((blob) => {
        GaUtils.downloadFile(blob, 'labels.pdf');
        showSuccess(msgActs.successDownloadLabels);
      }).catch(error => {
        console.error(error);
        showError(msgActs.errorDownloadLabels);
      });
  };

  const showSuccess = (message) => {
    _snackbar.current.show('success', message);
  };

  const showError = (message) => {
    _snackbar.current.show('error', message);
  };

  const handleDownloadLetters = () => {
    const urlRequest = `/api/v1/guide/${guideId}/downloadLetters`;
    const body = {
      candidateIds,
      contentLetter: savedWYSIWYGValue,
    };

    fetchUtil(urlRequest, 'POST', body, true)
      .then((blob) => {
        GaUtils.downloadFile(blob, 'letters.pdf');
        if (candidateNotInvited) {
          showSuccess(msgActs.successDownloadLetterForCandidates);
        } else {
          showSuccess(msgActs.successDownloadLetter);
        }
      }).catch(error => {
        console.error(error);
        showError(msgActs.errorDownloadLetter);
      });
  };

  const handleWYSIWYGChange = (htmlData) => {
    setSavedWYSIWYGValue(htmlData);
  };

  const handleCancelPageActionClicked = () => {
    window.location.href = navigation.mailCenter;
  };

  // Render
  const TitleValue = ({ text, value }) => (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      className="ga-mb-20"
    >
      <Typography
        component="span"
        className="ga-mb-4"
        style={{ color: '#666666', fontWeight: 500, fontSize: '12px' }}
      >
        {text}
      </Typography>
      <Typography component="span" style={{ fontWeight: 600, fontSize: '15px' }}>
        {value}
      </Typography>
    </Grid>
  );
  TitleValue.propTypes = { text: PropTypes.string, value: PropTypes.string };

  const MacroCard = ({ title, description }) => (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Typography
        component="span"
        className="ga-mb-4"
        style={{ fontWeight: 700 }}
      >
        {title}
      </Typography>
      <Typography component="span">
        {description}
      </Typography>
    </Grid>
  );
  MacroCard.propTypes = { title: PropTypes.string, description: PropTypes.string };

  // Render
  return (
    <ThemeProvider theme={theme}>
      <SnackbarAlert ref={_snackbar} />
      <PageHeader
        breadcrumbText={breadcrumbText}
        breadcrumbUrl={breadcrumbUrl}
        heading={msgHeader.title}
        subheading={msgHeader.subtitle}
      />
      <PageActionsHeader>
        <Button
          color='primary'
          variant="text"
          size="small"
          onClick={() => handleCancelPageActionClicked()}
        >
          {msgActs.cancel}
        </Button>
        <Button
          color='secondary'
          variant="contained"
          size="small"
          onClick={handleDownloadLabels}
        >
          {msgActs.downloadLabels}
        </Button>
        <Button
          color='secondary'
          variant="contained"
          size="small"
          onClick={handleDownloadLetters}
        >
          {msgActs.downloadLetters}
        </Button>
      </PageActionsHeader>

      <div className="ga-container">
        <div className="mdc-layout-grid">
          <div className="ga-w-100-p">
            <TitleValue text={messages.templateType} value={objLetter.templateType} />
            <TitleValue text={messages.sendTo} value={sendToContacts} />
            <Typography className="ga-mb-20" variant="h6" component="span" color="primary"
              style={{ fontWeight: 600 }}>
              {messages.letter}
            </Typography>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={9} className="ga-p-8">
              <WYSIWYG
                value={renderedWYSIWYGValue}
                label={messages.placeholderContentLetter}
                onChange={handleWYSIWYGChange}
              />
            </Grid>
            <Grid item xs={3} className="ga-px-8 ga-pt-12">
              <HelpfulHint>
                <Rsc id="pScrollbar" style={{ width: '100%', height: '512px' }}>
                  <div className="ga-p-20">
                    { objLetter.macros && objLetter.macros.map((m, mIdx) => (
                      <div key={mIdx}>
                        <MacroCard title={m.id} description={m.description} />
                        <Divider className="ga-my-12" />
                      </div>
                    ))}
                  </div>
                </Rsc>
              </HelpfulHint>
            </Grid>
          </Grid>
        </div>
      </div>
    </ThemeProvider>
  );
};

SendLetterContainer.propTypes = {
  breadcrumbText: PropTypes.string,
  breadcrumbUrl: PropTypes.string,
  candidates: PropTypes.array,
  guideId: PropTypes.string,
  letterContent: PropTypes.string,
  messages: PropTypes.object,
  navigation: PropTypes.object,
};

SendLetterContainer.defaultProps = {
  breadcrumbText: 'Mail Center',
  breadcrumbUrl: '/mail',
  candidates: [],
  guideId: '',
  letterContent: '',
  messages: {},
  navigation: {},
};

export default SendLetterContainer;
