import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  destructiveButton: {
    margin: '8px 20px',
    minWidth: '50px',
    textTransform: 'none',
    color: '#ac1b3d',
    '&:hover': {
      color: '#6B1126',
    },
  },
}));

export { useStyles };
