import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/styles';
import {
  CircularProgress,
  Typography,
} from '@material-ui/core';
import { DataGridPro, LicenseInfo } from '@mui/x-data-grid-pro';
import ActionsDropdown from 'components/ActionsDropdown';
import EmptyState from 'components/EmptyState';
import useSavedTableSettings from 'hooks/useSavedTableSettings';

// Dynamic row height style for DataGrid component
const StyledDataGrid = withStyles({
  root: {
    '& .MuiDataGrid-renderingZone': {
      maxHeight: 'none !important',
    },
    '& .MuiDataGrid-cell': {
      lineHeight: 'unset !important',
      maxHeight: 'none !important',
      whiteSpace: 'normal',
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiDataGrid-row': {
      maxHeight: 'none !important',
    },
  },
})(DataGridPro);

const ROWS_PER_PAGE_OPTIONS = [50, 100, 500, 1000, 5000];

LicenseInfo.setLicenseKey(
  '5354fa8b30068069673634540842cbf3T1JERVI6MzU2NzcsRVhQSVJZPTE2NzM0NzU4MDAwMDAsS0VZVkVSU0lPTj0x',
);

const useStyles = makeStyles(({ palette }) => ({
  headingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: '47px', // Prevents the container from jumping around when bulk actions show/hide
  },
  tableContainer: {
    '& a': {
      color: palette.primary.main,
      fontWeight: 500,
      textDecoration: 'none',
    },
  },
  table: {
    border: 'none',
  },
}));

const DistrictTable = ({
  districts,
  types,
  loading,
}) => {
  const [pageSize, setPageSize] = useState(50);
  const [filterModel, setFilterModel, sortModel, setSortModel] = useSavedTableSettings({ key: 'districts' });
  const classes = useStyles();

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1.5,
      renderCell: (params) => (
        <a href={`/districts/${types.find(t => t.id === params.row.type.id)?.urlRoot}/${params.id}`}>{params.row.name}</a>
      ),
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 1,
      valueGetter: (params) => types.find(t => t.id === params.row.type.id)?.name,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      resizable: false,
      width: 120,
      renderCell: (params) => {
        const options = [
          { label: 'Edit', onClick: () => handleEdit(params) },
        ];

        return <ActionsDropdown options={options} />;
      },
    },
  ];

  const handleEdit = (params) => {
    const url = `/districts/${params.row.type.urlRoot}/${params.row.id}`;
    window.location.replace(url);
  };

  const renderDataTable = () => {
    if (loading) {
      return (
        <div className='spinner'>
          <CircularProgress />
        </div>
      );
    }

    if (!districts || districts.length <= 0) {
      return (
        <EmptyState>
          <p className="ga-m-bottom--large">
            There are currently no districts available for this guide.
          </p>
        </EmptyState>
      );
    }

    return (
      <div className={classes.tableContainer}>
        <StyledDataGrid
          autoHeight={true}
          checkboxSelection
          className={classes.table}
          columns={columns}
          filterModel={filterModel}
          onFilterModelChange={(model) => setFilterModel(model)}
          onPageSizeChange={(size) => setPageSize(size)}
          onSortModelChange={(model) => setSortModel(model)}
          pageSize={pageSize}
          pagination={true}
          rows={districts}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          sortModel={sortModel}
        />
      </div>
    );
  };

  return (
    <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12 ga-m-vertical--large">
      <div className={classes.headingContainer}>
        <Typography component="h2" variant="h3">
          Created Districts
        </Typography>
      </div>

      {renderDataTable()}
    </div>
  );
};

DistrictTable.propTypes = {
  districts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      type: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        urlRoot: PropTypes.string,
      }),
    }),
  ),
  types: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired),
  loading: PropTypes.bool.isRequired,
};

DistrictTable.defaultProps = {
  districts: [],
};

export default DistrictTable;
