import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import HelpfulHint from 'components/HelpfulHint';
import stateErrors from 'helpers/StateErrors';
import Interweave from 'interweave';

const RaceName = ({
  addNameErrors,
  messages,
  name,
  setName,
  submitting,
}) => {
  const getFormValue = (event) => {
    if (event.target.name == 'editable' || event.target.name == 'visible') {
      return event.target.checked;
    }
    return event.target.value;
  };

  // form state
  const handleInputChange = (event) => {
    const { name: fieldName } = event.target;
    const value = getFormValue(event);
    if (fieldName == 'name') {
      setName(value);
    }
  };

  const helpText = (enteredAnswer) => {
    if (stateErrors.hasError('name', addNameErrors)) {
      return stateErrors.getError('name', addNameErrors);
    }
    if (enteredAnswer.length > 0) {
      return `${enteredAnswer.length}/200`;
    }
    return messages.help;
  };
  return (
    <Box paddingBottom={5} marginBottom={5}>
      <Grid container direction="row" justifyContent="space-between" spacing={2}>
        <Grid item xs={9}>
          <Typography variant="h2" component="h3" paragraph>
            {messages.title}
          </Typography>

          <Typography variant="body1" component="p" paragraph>
            {messages.supporting}
          </Typography>

          <Grid container>
            {/* Width control */}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <TextField
                  id="category-add-title"
                  disabled={submitting}
                  error={stateErrors.hasError('name', addNameErrors)}
                  helperText={helpText(name)}
                  label={messages.label}
                  name="name"
                  required
                  variant="filled"
                  value={name}
                  onChange={handleInputChange}
                  inputProps={{ maxLength: 200 }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={3}>
          <HelpfulHint>
            <Interweave content={messages.tip} />
          </HelpfulHint>
        </Grid>
      </Grid>
    </Box>
  );
};

RaceName.propTypes = {
  submitting: PropTypes.bool,
  name: PropTypes.string.isRequired,
  setName: PropTypes.func.isRequired,
  messages: PropTypes.object.isRequired,
  addNameErrors: PropTypes.object,
};

RaceName.defaultProps = {
  submitting: false,
  addNameErrors: {},
};

export default RaceName;
