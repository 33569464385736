import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    color: '#666666',
    fontWeight: 500,
    fontSize: '12px',
    marginBottom: '4px',
  },
}));

const CandidateMetadataTitle = ({ text }) => {
  const classes = useStyles();

  return (
    <Typography className={classes.root} component="span">
      {text}
    </Typography>
  );
};

CandidateMetadataTitle.propTypes = {
  text: PropTypes.string,
};

CandidateMetadataTitle.defaultProps = {
  text: '',
};

export default CandidateMetadataTitle;
