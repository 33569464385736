import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/styles';
import NumberedStepper from 'components/NumberedStepper';

const styles = () => ({
  actionButton: {
    marginLeft: '1rem',
  },
  modalTitle: {
    alignItems: 'flex-start',
  },
  modalActions: {
    justifyContent: 'end',
  },
});

class RaceChecklistModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };
  }

  render() {
    const {
      onClose,
      messages,
      modalIsOpen,
      classes,
    } = this.props;

    return (
      <Dialog
        aria-labelledby="bulk-import-modal-title"
        maxWidth="md"
        onClose={onClose}
        open={modalIsOpen}
      >
        <DialogTitle
          className={classes.modalTitle}
          disableTypography
          id="race-checklist-modal-title"
          onClose={onClose}
        >
          <span>
            <Typography component="h1" variant="h1">
              {messages.title}
            </Typography>
            <Typography component="p" variant="body1">
              {messages.subtitle}
            </Typography>
          </span>
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <NumberedStepper
            steps={[
              {
                title: messages.step1.title,
                children: (
                  <div>
                    <Typography component="p" variant="body1" paragraph>
                      {messages.step1.body}
                    </Typography>
                  </div>
                ),
              },
              {
                title: messages.step2.title,
                children: (
                  <div>
                    <Typography component="p" variant="body1" paragraph>
                      {messages.step2.body}
                    </Typography>
                  </div>
                ),
              },
              {
                title: messages.step3.title,
                children: (
                  <div>
                    <Typography component="p" variant="body1" paragraph>
                      {messages.step3.body}
                    </Typography>
                  </div>
                ),
              },
              {
                title: messages.step4.title,
                children: (
                  <div>
                    <Typography component="p" variant="body1" paragraph>
                      {messages.step4.body}
                    </Typography>
                  </div>
                ),
              },
              {
                title: messages.step5.title,
                children: (
                  <div>
                    <Typography component="p" variant="body1" paragraph>
                      {messages.step5.body}
                    </Typography>
                  </div>
                ),
              },
              {
                title: messages.step6.title,
                children: (
                  <div>
                    <Typography component="p" variant="body1" paragraph>
                      {messages.step6.body}
                    </Typography>
                  </div>
                ),
              },
              {
                title: messages.step7.title,
                children: (
                  <div>
                    <Typography component="p" variant="body1" paragraph>
                      {messages.step7.body}
                    </Typography>
                  </div>
                ),
              },
            ]}
          />
        </DialogContent>

        <DialogActions className={classes.modalActions}>
          <Button
            className={classes.actionButton}
            color="secondary"
            onClick={onClose}
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

RaceChecklistModal.propTypes = {
  classes: PropTypes.object,
  modalIsOpen: PropTypes.bool.isRequired,
  messages: PropTypes.object,
  onClose: PropTypes.func,
};

RaceChecklistModal.defaultProps = {
  onClose: () => {},
  messages: {},
};

export default withStyles(styles)(RaceChecklistModal);
