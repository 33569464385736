import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';
import EditTemplatesForm from 'components/MailCenter/Partials/EditTemplatesForm';

const styles = makeStyles({
  modalContainer: {
    paddingBottom: '.5rem',
    overflow: 'hidden',
  },
  modalContentContainer: {
    padding: 0,
  },
  modalContent: {
    padding: '0rem 2rem',
    color: '#212121',
  },
});

const MailCenterModal = (props) => {
  // Variables and constants
  const {
    messages,
    template,
    onSetTemplate,
    openEditTemplates,
    templates,
    templatesLoaded,
    onCancel,
    onConfirm,
    submitting,
  } = props;

  const classes = styles();
  // hooks
  // Functions
  // Render
  return (
    <Dialog
      aria-labelledby="add-candidate-title"
      maxWidth="md"
      open={openEditTemplates}
      onClose={onCancel}
      className={classes.modalContainer}
    >
      <DialogTitle id="add-candidate-title" disableTypography>
        <Typography component="h1" variant="h1">
          {messages.title}
        </Typography>

        <IconButton onClick={onCancel} aria-label="close">
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.modalContentContainer}>
        <DialogContentText className={classes.modalContent}>
          {messages.subtitle}
        </DialogContentText>

        <EditTemplatesForm
          messages={messages}
          template={template}
          onSetTemplate={onSetTemplate}
          templates={templates}
          templatesLoaded={templatesLoaded}
          onCancel={onCancel}
          onConfirm={onConfirm}
          hasActions={true}
          submitting={submitting}
        />
      </DialogContent>
    </Dialog>
  );
};

MailCenterModal.propTypes = {
  messages: PropTypes.object.isRequired,
  template: PropTypes.object,
  onSetTemplate: PropTypes.func.isRequired,
  openEditTemplates: PropTypes.bool,
  templates: PropTypes.array.isRequired,
  templatesLoaded: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
};

MailCenterModal.defaultProps = {
  template: null,
  submitting: false,
};

export default MailCenterModal;
