import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, makeStyles, withStyles } from '@material-ui/core/styles';
import {
  AccordionSummary,
  AccordionDetails,
  Button,
  Checkbox,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Grid,
  Typography,
} from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NumberedStepper from 'components/NumberedStepper';
import UploadCard from 'components/UploadCard';
import PageHeader from 'components/PageHeader';
import PageActionsFooter from 'components/PageActionsFooter';
import theme from 'scripts/theme';
import fetchUtil from 'helpers/Fetch';
import SnackbarAlert from 'components/SnackbarAlert';

const useStyles = makeStyles({
  accordionHeading: {
    paddingLeft: '0px',
  },
  accordionDetailsContainer: {
    paddingLeft: '0px',
  },
  centeredContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  controlsSection: {
    maxWidth: '630px',
  },
  numberContainer: {
    padding: '2.188rem 0rem 2.188rem 0.625rem',
  },
  sectionContainer: {
    padding: '2.188rem 0rem',
  },
  helperText: {
    fontSize: '0.875rem',
    color: '#000',
    lineHeight: '1rem',
    marginLeft: '32px',
    marginTop: '-5px',
  },
});

const Accordion = withStyles({
  root: {
    borderWidth: '0 0 1px 0',
    borderColor: '#e5e5e5',
    borderRadius: '0',
    borderStyle: 'solid',
    boxShadow: 'none',
    '&:last-child': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const BulkRaceImportContainer = (props) => {
  // Variables and constants
  const { guideId, messages } = props;
  const classes = useStyles();
  const _snackbar = useRef();
  const msgActs = messages.pageActions;

  // hooks
  const [updateExistingRaces, setUpdateExistingRaces] = useState(false);
  const [file, setFile] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  // Functions

  const showError = (message) => {
    _snackbar.current.show('error', message);
  };

  const handleCsvFile = (csvFile) => {
    if (csvFile.length > 0) {
      setFile(csvFile);
    }
  };

  const handleSave = () => {
    const urlRequest = `/api/v1/guide/${guideId}/races/upload`;
    const data = new FormData();
    data.append('file', file[0], file[0].name);
    data.append('updateExistingRaces', updateExistingRaces);

    setSubmitting(true);
    fetchUtil(urlRequest, 'POST', data, false, true)
      .then(() => {
        setSubmitting(false);
        window.location.href = '/races?showSuccessMsgFrom=bulkRaceImport';
      })
      .catch((error) => {
        setSubmitting(false);
        console.error(error);
        showError(msgActs.errorUploadCsvFile);
      });
  };

  // Render
  return (
    <ThemeProvider theme={theme}>
      <SnackbarAlert ref={_snackbar} />

      <PageHeader
        heading={messages.title}
        subheading={messages.supporting}
      />

      <div className="ga-container">
        <div className="mdc-layout-grid">
          <div className="mdc-layout-grid__inner">
            <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-10">
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon />
                  }
                  aria-controls={messages.gettingStarted.id}
                  className={classes.accordionHeading}
                  id={messages.gettingStarted.id}
                >
                  <Typography component="h2" variant="h3">
                    {messages.gettingStarted.title}
                  </Typography>
                </AccordionSummary>

                <AccordionDetails className={classes.accordionDetailsContainer}>
                  <div>
                    <Typography component="p" variant="body1">
                      {messages.gettingStarted.subtext}
                    </Typography>

                    <div className={classes.numberContainer}>
                      <NumberedStepper
                        steps={[
                          {
                            title: 'Download our template',
                            children: (
                              <div>
                                <Typography component="p" variant="body1" paragraph>
                                  Our template contains the correct column names needed to properly import your candidates and their races. While you could use your own spreadsheet to keep track of this information, we find that is is easiest if you start by downloading our template.
                                </Typography>
                                <a
                                  href="https://s3.amazonaws.com/images.prod.thevoterguide.org/templates/VOTE411+Bulk+Import+Template.csv"
                                  download
                                  style={{ textDecoration: 'none' }}
                                >
                                  <Button
                                    variant='outlined'
                                    color='secondary'
                                    onClick={() => {}}
                                    size='large'
                                  >
                                    Download Template
                                  </Button>
                                </a>
                              </div>
                            ),
                          },
                          {
                            title: 'Start creating or updating your races & candidates',
                            children: (
                              <div>
                                <Typography component="p" variant="body1" paragraph>
                                  You can use the bulk import to create new candidates and races, or you could use it to update existing ones.
                                </Typography>

                                <Typography component="p" variant="body1" paragraph>
                                  To create new data, the required columns are <em>fullname</em>, <em>lastname</em>, and <em>races</em>. Don&apos;t worry if you don&apos;t know the rest of the column&apos;s information yet, as you can always come back and update it. However, the more information you have, the better!
                                </Typography>

                                <Typography component="p" variant="body1" paragraph>
                                  To update your races, just make the race updates you want are within the spreadsheet. When it is time to import your file, there is an option called &ldquo;Update existing races.&ldquo; By default, this is always turned on! If you made changes that you aren&apos;t ready to have in the system, just make sure to uncheck that option.
                                </Typography>
                              </div>
                            ),
                          },
                          {
                            title: 'You\'re almost done, but don\'t forget to check your work',
                            children: (
                              <div>
                                <Typography component="p" variant="body1" paragraph>
                                  Pretty easy, right? Before you go ahead and import your file, please double check your work! Look to make sure your file is formatted correctly and the data you&apos;ve added is accurate. It&apos;s very important that:
                                </Typography>

                                <ul className="styled-ul">
                                  <li>
                                    <Typography component="p" variant="body1" paragraph>
                                      The first row of your spreadsheet contains the correct column names:<br />
                                      <em>fullname</em>, <em>lastname</em>, <em>race</em>, <em>userdata</em>, <em>party</em>, <em>occupation</em>, <em>imageurl</em>, <em>contactname</em>, <em>contactemail</em>
                                    </Typography>
                                  </li>

                                  <li>
                                    <Typography component="p" variant="body1" paragraph>
                                      The <em>fullname</em>, <em>lastname</em>, and <em>race</em> column is filled out for each row (so that a candidate and its race can be added to the system)
                                    </Typography>
                                  </li>
                                </ul>

                                <Typography component="p" variant="body1" paragraph>
                                  Remember, you can import and update your races as many times as you&apos;d like. If you are waiting for more race data, you can import what you have now, or you could wait until you have all the information later.
                                </Typography>
                              </div>
                            ),
                          },
                        ]}
                      />
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>

              <div className={classes.sectionContainer}>
                <Typography component="h2" variant="h3" paragraph>
                  {messages.upload.title}
                </Typography>

                <Typography component="p" variant="body1">
                  {messages.upload.subtext}
                </Typography>

                <div className={classes.sectionContainer}>
                  <div className={classes.centeredContainer}>
                    <UploadCard
                      acceptedFileTypes=".csv"
                      text={messages.upload.cardTitle}
                      onChange={handleCsvFile}
                    />
                  </div>
                </div>

                <Grid container style={{ display: 'none' }}>
                  <Grid item xs={12} sm={6}>
                    <FormControl>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={updateExistingRaces}
                              onClick={() => setUpdateExistingRaces(!updateExistingRaces)}
                              name="updateExisting"
                            />}
                          label={messages.upload.uploadExistingLabel}
                        />
                        <FormHelperText className={classes.helperText}>
                          {messages.upload.uploadExistingHelpText}
                        </FormHelperText>
                      </FormGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </div>

      <PageActionsFooter sticky>
        <Button
          variant="text"
          color="primary"
          size="medium"
          onClick={() => window.history.go(-1)}
        >
          Cancel
        </Button>

        <Button
          variant='contained'
          color='secondary'
          size='large'
          disabled={submitting}
          onClick={() => handleSave()}
        >
          Save
        </Button>
      </PageActionsFooter>
    </ThemeProvider>
  );
};

BulkRaceImportContainer.propTypes = {
  guideId: PropTypes.string,
  messages: PropTypes.object,
};

BulkRaceImportContainer.defaultProps = {
  guideId: '',
  messages: {
    gettingStarted: {
      id: 'getting-started-accordion',
      subtext: "Bulk import can help save you a lot of time if you know what races you'll be covering ahead of time. You do have to know at least one candidate per race in order to import a new race correctly.",
      title: 'Getting started with a bulk import',
    },
    upload: {
      title: 'Import my races',
      subtext: 'Add your spreadsheet by clicking the "Attach" button to choose your file. Once it has been attached, click the "Save" button to add your races to your guide.',
      cardTitle: 'Attach your race file here (.csv)',
      uploadExistingLabel: 'Update existing races',
      uploadExistingHelpText: 'If checked and if there is a matching race name already existing in the system, the existing race data will be overwritten with the new data. If unchecked, these races will be skipped.',
    },
  },
};

export default BulkRaceImportContainer;
