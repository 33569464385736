import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Rsc from 'react-scrollbars-custom';

const useStyles = makeStyles(() => ({
  formContainer: {
    padding: '0rem 2rem',
  },
  addFormActions: {
    backgroundColor: '#eef4f8',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1.5rem 2rem',
    width: '100%',
    marginTop: '8px',
  },
  cardContainerNoOutline: {
    border: 'none',
    boxShadow: 'none',
  },
  actionButton: {
    marginRight: '1rem',
  },
  'actionButton:last-child': {
    marginRight: 0,
  },
  requiredAsterisk: {
    color: '#ac1b3d',
  },
}));

const AddCandidateForm = ({
  candidate,
  dataLoaded,
  errors,
  messages,
  onCancel,
  onChange,
  onSubmit,
  parties,
  races,
  submitting,
}) => {
  const classes = useStyles();
  const fieldNames = {
    race: 'race',
    fullName: 'name',
    lastName: 'lastName',
    contactUserName: 'contactName',
    contactUserEmail: 'contactEmail',
    inFavor: 'inFavor',
    parties: 'partiesIds',
  };

  const getRaceById = (race) => {
    let result = null;

    if (race && race.id && races && races.length > 0) {
      result = races.find((x) => x.id == race.id);
    }

    return result;
  };

  const partyIsSelected = (party) => {
    let result = false;

    if (candidate && candidate.parties && party) {
      result = candidate.parties.some((x) => x.id == party.id);
    }

    return result;
  };

  const hideMeasureCandidateInFavorCheckbox = () => candidate?.race?.clazz?.id !== 'F';
  const hasError = (fieldName) => Object.prototype.hasOwnProperty.call(errors, fieldName);
  const getErrorText = fieldName => (Object.prototype.hasOwnProperty.call(errors, fieldName) ? errors[fieldName] : '');

  return (
    <Card variant="outlined" className={classes.cardContainerNoOutline}>
      <CardContent className={classes.formContainer}>
        {dataLoaded && (
          <Autocomplete
            className="ga-px-8"
            disableClearable
            disabled={submitting || races.length === 1}
            fullWidth
            onChange={(event, value) => onChange(fieldNames.race, value)}
            options={races}
            getOptionLabel={opt => opt.name}
            renderInput={params => (
              <TextField
                {...params}
                error={hasError(fieldNames.race)}
                helperText={getErrorText(fieldNames.race)}
                label={messages.race}
                required
                variant="filled"
              />
            )}
            value={getRaceById(candidate.race)}
          />
        )}

        <Grid
          autoComplete="off"
          className="ga-px-8 ga-pt-16 ga-pb-4"
          component="form"
          container
          noValidate
          spacing={2}
        >
          <Grid item xs={8}>
            <Grid container spacing={2} className="ga-mb-32">
              <Grid item xs={6}>
                <TextField
                  disabled={submitting}
                  error={hasError(fieldNames.fullName)}
                  fullWidth
                  helperText={getErrorText(fieldNames.fullName)}
                  id={fieldNames.fullName}
                  label={messages.fullName}
                  onChange={event => onChange(fieldNames.fullName, event.target.value)}
                  required
                  value={candidate.fullName || candidate.name}
                  variant="filled"
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  disabled={submitting}
                  error={hasError(fieldNames.lastName)}
                  fullWidth
                  helperText={getErrorText(fieldNames.lastName)}
                  id={fieldNames.lastName}
                  label={messages.lastName}
                  onChange={event => onChange(fieldNames.lastName, event.target.value)}
                  required
                  value={candidate.lastName}
                  variant="filled"
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} className="ga-mb-32">
              <Grid item xs={6}>
                <TextField
                  disabled={submitting}
                  error={hasError(fieldNames.contactUserName)}
                  fullWidth
                  helperText={getErrorText(fieldNames.contactUserName)}
                  id={fieldNames.contactUserName}
                  label={messages.contactsName}
                  onChange={event => onChange(fieldNames.contactUserName, event.target.value)}
                  value={candidate.contactUser.name}
                  variant="filled"
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  disabled={submitting}
                  error={hasError(fieldNames.contactUserEmail)}
                  fullWidth
                  helperText={getErrorText(fieldNames.contactUserEmail)}
                  id={fieldNames.contactUserEmail}
                  label={messages.contactsEmail}
                  onChange={event => onChange(fieldNames.contactUserEmail, event.target.value)}
                  value={candidate.contactUser.email}
                  variant="filled"
                />
              </Grid>
            </Grid>

            <Grid item xs={8} hidden={hideMeasureCandidateInFavorCheckbox()}>
              <FormGroup className="ga-pl-12">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={candidate.inFavor}
                      color="primary"
                      disabled={submitting}
                      onChange={() => onChange(fieldNames.inFavor, !candidate.inFavor)}
                    />
                  }
                  label="Is the candidate in favor of this measure?"
                />
              </FormGroup>
            </Grid>
          </Grid>

          <Grid item xs={4}>
            <FormControl error={hasError(fieldNames.parties)} fullWidth>
              <Card variant="outlined">
                <CardContent>
                  <Box className="ga-mb-12" sx={{ fontWeight: 600, fontSize: 19 }}>
                    {messages.candidatePartyHeader}<span className={classes.requiredAsterisk}>*</span>
                  </Box>

                  <Rsc id="pScrollbar" style={{ width: '100%', height: '120px' }}>
                    <FormGroup className="ga-pl-12">
                      {parties && parties.map((party, index) => (
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              checked={partyIsSelected(party)}
                              color="primary"
                              disabled={submitting}
                              onChange={() => onChange(fieldNames.parties, party)}
                            />
                          }
                          label={party.name}
                        />
                      ))}
                    </FormGroup>
                  </Rsc>
                </CardContent>

                <Divider />

                <CardActions>
                  <Typography style={{ color: '#9e9e9e' }}>
                    {candidate.parties ? candidate.parties.length : 0}{' '}
                    {candidate.parties && (candidate.parties.length > 1 || candidate.parties.length === 0)
                      ? messages.candidatePartyFooterPlural
                      : messages.candidatePartyFooter}
                  </Typography>
                </CardActions>
              </Card>

              {hasError(fieldNames.parties) && (
                <FormHelperText>
                  {getErrorText(fieldNames.parties)}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>

      <CardActions className={classes.addFormActions}>
        <Typography component="span" variant="body2">
          {messages.requiredFields}
        </Typography>

        <div>
          <Button
            className={classes.actionButton}
            onClick={onCancel}
            disabled={submitting}
          >
            {messages.cancel}
          </Button>

          <Button
            className={classes.actionButton}
            color="secondary"
            disabled={submitting}
            onClick={onSubmit}
            variant="contained"
          >
            {messages.save}
          </Button>
        </div>
      </CardActions>
    </Card>
  );
};

AddCandidateForm.propTypes = {
  candidate: PropTypes.object,
  dataLoaded: PropTypes.bool,
  errors: PropTypes.object,
  messages: PropTypes.object.isRequired,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  parties: PropTypes.array.isRequired,
  races: PropTypes.array,
  submitting: PropTypes.bool,
};

AddCandidateForm.defaultProps = {
  candidate: {
    inFavor: false,
    name: '',
    lastName: '',
    contactUser: {
      name: '',
      email: '',
    },
    parties: [],
    race: null,
  },
  dataLoaded: false,
  errors: {},
  onCancel: () => {},
  onChange: () => {},
  onSubmit: () => {},
  races: [],
  submitting: false,
};

export default AddCandidateForm;
