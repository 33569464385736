import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import Rsc from 'react-scrollbars-custom';
import GaValidations from 'helpers/GaValidations';

const useStyles = makeStyles({
  formContainer: {
    padding: '0rem 2rem',
  },
  addFormActions: {
    backgroundColor: '#eef4f8',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1.5rem 2rem',
    width: '100%',
    marginTop: '8px',
  },
  cardContainerNoOutline: {
    border: 'none',
    boxShadow: 'none',
  },
  actionButton: {
    marginRight: '1rem',
  },
  'actionButton:last-child': {
    marginRight: 0,
  },
  requiredAsterisk: {
    color: '#ac1b3d',
  },
});

const CandidateInfoForm = ({
  candidate,
  candidateList,
  checkValidations,
  errors,
  hasActions,
  messages,
  onCancel,
  onConfirm,
  onSetCandidateList,
  parties,
  partiesErrors,
  races,
  racesLoaded,
  submitting,
}) => {
  // Variables and constants
  const lblRace = 'race';
  const lblFullName = 'name';
  const lblLastName = 'lastName';
  const lblContactUserName = 'contactUser.name';
  const lblContactUserEmail = 'contactUser.email';
  const lblInFavor = 'inFavor';
  const classes = useStyles();

  // hooks
  const [manualPartiesSelectedError, setManualPartiesSelectedError] = useState(false);

  // Functions
  const isPartySelected = (objParty) => {
    let result = false;

    if (candidate && candidate.parties && objParty) {
      result = candidate.parties.some((x) => x.id == objParty.id);
    }

    return result;
  };

  const handlePartyChange = (party) => {
    setManualPartiesSelectedError(false);
    const newCandidateList = [...candidateList];
    const candidateIndex = newCandidateList.findIndex(c => c.id == candidate.id);
    let updatedCandidate;

    if (candidateIndex >= 0) {
      updatedCandidate = newCandidateList[candidateIndex];
      const newParties = [...updatedCandidate.parties];
      const partyIndex = newParties.findIndex(p => p.id == party.id);

      if (partyIndex >= 0) {
        // Party is already checked, so remove it from the candidate
        newParties.splice(partyIndex, 1);
      } else {
        // Add party to the candidate
        newParties.push(party);
      }

      updatedCandidate.parties = newParties;
      onSetCandidateList(newCandidateList);
    }
  };

  const handleInputChange = (event, field) => {
    setManualPartiesSelectedError(false);
    const { value } = event.target;
    const newCandidateList = [...candidateList];
    const candidateIndex = newCandidateList.findIndex(c => c.id == candidate.id);
    let updatedCandidate;

    if (candidateIndex >= 0) {
      updatedCandidate = newCandidateList[candidateIndex];

      if (field == lblContactUserName) {
        updatedCandidate.contactUser.name = value;
      } else if (field == lblContactUserEmail) {
        updatedCandidate.contactUser.email = value;
      } else if (field == lblInFavor) {
        updatedCandidate.inFavor = !newCandidateList[candidateIndex].inFavor;
      } else {
        updatedCandidate[field] = value;
      }

      onSetCandidateList(newCandidateList);
    }
  };

  const updRaceField = (event, newValue) => {
    setManualPartiesSelectedError(false);
    const copyList = [...candidateList];
    const objItem = candidateList.find((cl) => cl.id == candidate.id);

    if (objItem) {
      objItem.race = newValue;
    }

    onSetCandidateList(copyList);
  };

  const hasErrorByField = (field, validations, value) => {
    const isValid = GaValidations.hasErrorByItemFieldWithCheck(
      errors,
      candidate.id,
      field,
      validations,
      value,
    );

    if (checkValidations) {
      return isValid;
    }

    return false;
  };

  const getRaceById = (race) => {
    let result = null;

    if (race && race.id && races && races.length > 0) {
      result = races.find((x) => x.id == race.id);
    }

    return result;
  };

  const checkPartiesSelectedError = () => {
    if (checkValidations) {
      return candidate.parties.length <= 0 && candidate?.race?.clazz?.id !== 'F';
    }
    return 'partiesIds' in partiesErrors;
  };

  const hideMeasureCandidateInFavorCheckbox = () => candidate?.race?.clazz?.id !== 'F';

  const checkInFavor = () => !!(candidate && candidate.inFavor);

  const handleConfirm = () => {
    setManualPartiesSelectedError(false);
    if (candidate.parties.length <= 0 && candidate?.race?.clazz?.id !== 'F') {
      setManualPartiesSelectedError(true);
      return;
    }
    onConfirm();
  };

  // Render
  return (
    <Card variant="outlined" className={classes.cardContainerNoOutline}>
      <CardContent className={classes.formContainer}>
        {racesLoaded && (
          <Autocomplete
            className="ga-px-8"
            disableClearable
            disabled={submitting || races.length === 1}
            fullWidth
            onChange={updRaceField}
            options={races}
            getOptionLabel={(opt) => opt.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label={messages.race}
                variant="filled"
                required
                error={hasErrorByField(
                  lblRace,
                  [GaValidations.IsRequired],
                  candidate.race,
                )}
                helperText={hasErrorByField(lblRace, [GaValidations.IsRequired], candidate.race) ? GaValidations.getMsgFirstErrorByItemField(
                  errors,
                  candidate.id,
                  lblRace,
                ) : ''}
              />
            )}
            value={getRaceById(candidate.race)}
          />
        )}

        <Grid
          autoComplete="off"
          className="ga-px-8 ga-pt-16 ga-pb-4"
          component="form"
          container
          noValidate
          spacing={2}
        >
          <Grid item xs={8}>
            <Grid container spacing={2} className="ga-mb-32">
              <Grid item xs={6}>
                <TextField
                  disabled={submitting}
                  error={hasErrorByField(
                    lblFullName,
                    [GaValidations.IsRequired, GaValidations.maxLength200],
                    candidate.name,
                  )}
                  fullWidth
                  id={lblFullName}
                  label={messages.fullName}
                  onChange={(event) => handleInputChange(event, lblFullName)}
                  required
                  value={candidate.fullName || candidate.name}
                  variant="filled"
                  helperText={hasErrorByField(
                    lblFullName,
                    [GaValidations.IsRequired, GaValidations.maxLength200],
                    candidate.name,
                  ) ? GaValidations.getMsgFirstErrorByItemField(
                      errors,
                      candidate.id,
                      lblFullName,
                    ) : ''
                  }
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  disabled={submitting}
                  error={hasErrorByField(
                    lblLastName,
                    [GaValidations.IsRequired, GaValidations.maxLength100],
                    candidate.lastName,
                  )}
                  fullWidth
                  id={lblLastName}
                  label={messages.lastName}
                  onChange={(event) => handleInputChange(event, lblLastName)}
                  helperText={hasErrorByField(
                    lblLastName,
                    [GaValidations.IsRequired, GaValidations.maxLength100],
                    candidate.lastName,
                  ) ? GaValidations.getMsgFirstErrorByItemField(
                      errors,
                      candidate.id,
                      lblLastName,
                    ) : messages.lastNameHelper
                  }
                  required
                  value={candidate.lastName}
                  variant="filled"
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} className="ga-mb-32">
              <Grid item xs={6}>
                <TextField
                  disabled={submitting}
                  fullWidth
                  id={lblContactUserName}
                  label={messages.contactsName}
                  onChange={(event) => handleInputChange(event, lblContactUserName)}
                  value={candidate.contactUser.name}
                  variant="filled"
                  error={hasErrorByField(
                    lblContactUserName,
                    [GaValidations.maxLength200],
                    candidate.contactUser.name,
                  )}
                  helperText={hasErrorByField(
                    lblContactUserName,
                    [GaValidations.maxLength200],
                    candidate.contactUser.name,
                  ) ? GaValidations.getMsgFirstErrorByItemField(
                      errors,
                      candidate.id,
                      lblContactUserName,
                    ) : ''
                  }
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  disabled={submitting}
                  error={hasErrorByField(
                    lblContactUserEmail,
                    [GaValidations.IsEmail, GaValidations.maxLength200],
                    candidate.contactUser.email,
                  )}
                  fullWidth
                  id={lblContactUserEmail}
                  label={messages.contactsEmail}
                  onChange={(event) => handleInputChange(event, lblContactUserEmail)}
                  value={candidate.contactUser.email}
                  variant="filled"
                  helperText={hasErrorByField(
                    lblContactUserEmail,
                    [GaValidations.maxLength200],
                    candidate.contactUser.email,
                  ) ? GaValidations.getMsgFirstErrorByItemField(
                      errors,
                      candidate.id,
                      lblContactUserEmail,
                    ) : ''
                  }
                />
              </Grid>
            </Grid>

            <Grid item xs={8} hidden={hideMeasureCandidateInFavorCheckbox()}>
              <FormGroup className="ga-pl-12">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkInFavor()}
                      color="primary"
                      disabled={submitting}
                      onChange={(event) => handleInputChange(event, lblInFavor)}
                    />
                  }
                  label="Is the candidate in favor of this measure?"
                />
              </FormGroup>
            </Grid>
          </Grid>

          <Grid item xs={4}>
            <FormControl error={checkPartiesSelectedError()} fullWidth>
              <Card variant="outlined">
                <CardContent>
                  <Box className="ga-mb-12" sx={{ fontWeight: 600, fontSize: 19 }}>
                    {messages.candidatePartyHeader}
                    {candidate?.race?.clazz?.id !== 'F' && (
                      <span className={classes.requiredAsterisk}>*</span>
                    )}
                  </Box>

                  <Rsc id="pScrollbar" style={{ width: '100%', height: '120px' }}>
                    <FormGroup className="ga-pl-12">
                      {parties && parties.map((party, pIdx) => (
                        <FormControlLabel
                          key={pIdx}
                          control={
                            <Checkbox
                              checked={isPartySelected(party)}
                              color="primary"
                              disabled={submitting}
                              onChange={() => handlePartyChange(party)}
                            />
                          }
                          label={party.name}
                        />
                      ))}
                    </FormGroup>
                  </Rsc>
                </CardContent>

                <Divider />

                <CardActions>
                  <Typography style={{ color: '#9e9e9e' }}>
                    {candidate.parties ? candidate.parties.length : 0}{' '}
                    {candidate.parties && (candidate.parties.length > 1 || candidate.parties.length === 0)
                      ? messages.candidatePartyFooterPlural
                      : messages.candidatePartyFooter}
                  </Typography>
                </CardActions>
              </Card>

              {(checkPartiesSelectedError() || manualPartiesSelectedError) && <FormHelperText>{messages.partiesError}</FormHelperText>}
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>

      {hasActions && (
        <CardActions className={classes.addFormActions}>
          <Typography component="span" variant="body2">
            {messages.requiredFields}
          </Typography>

          <div>
            <Button
              className={classes.actionButton}
              onClick={onCancel}
              disabled={submitting}
            >
              {messages.cancel}
            </Button>

            <Button
              className={classes.actionButton}
              color="secondary"
              disabled={submitting}
              onClick={handleConfirm}
              variant="contained"
            >
              {messages.save}
            </Button>
          </div>
        </CardActions>
      )}
    </Card>
  );
};

CandidateInfoForm.propTypes = {
  candidate: PropTypes.object,
  candidateList: PropTypes.array.isRequired,
  checkValidations: PropTypes.bool.isRequired,
  errors: PropTypes.array,
  hasActions: PropTypes.bool.isRequired,
  messages: PropTypes.object.isRequired,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  onSetCandidateList: PropTypes.func.isRequired,
  parties: PropTypes.array.isRequired,
  races: PropTypes.array,
  racesLoaded: PropTypes.bool.isRequired,
  submitting: PropTypes.bool,
  partiesErrors: PropTypes.object,
};

CandidateInfoForm.defaultProps = {
  candidate: {},
  errors: [],
  onCancel: () => {},
  onConfirm: () => {},
  races: [],
  submitting: false,
  partiesErrors: {},
};

export default CandidateInfoForm;
