/**
 * Converts various string formats to Title Case with enhanced customization.
 * The default `commonSeparators` list includes the characters '-', '_', and ' ', which are treated as word separators.
 * The default `commonLowercaseWords` list includes common English words like 'a', 'an', 'the', 'and', 'but', 'or', 'for', 'nor', 'on', 'at', 'to', 'from', 'by', and 'in', which are kept in lowercase unless they're the first or last word in the string.
 *
 * @param {string} inputString - The string to be converted to Title Case.
 * @param {Object} [options] - Optional configuration object.
 * @param {string[]} [options.additionalSeparators=[]] - Additional characters to be treated as word separators.
 * @param {string[]} [options.additionalLowercaseWords=[]] - Additional words to keep lowercase unless they're the first or last word.
 * @param {string[]} [options.excludedSeparators=[]] - Separators to exclude from the common separators list.
 * @param {string[]} [options.excludedLowercaseWords=[]] - Words to exclude from the common lowercase words list.
 * @returns {string} - The converted Title Case string.
 * @example
 * const inputString = 'hello-world';
 * const titleCaseString = convertStringToTitleCase(inputString);
 * console.log(convertStringToTitleCase(inputString)); // 'Hello World'
 */
export const convertStringToTitleCase = (inputString, options = {}) => {
  if (!inputString || typeof inputString !== 'string' || inputString.trim() === '') {
    return inputString;
  }

  const commonSeparators = ['-', '_', ' '];
  const commonLowercaseWords = ['a', 'an', 'the', 'and', 'but', 'or', 'for', 'nor', 'on', 'at', 'to', 'from', 'by', 'in'];

  const {
    separators: {
      include: includeSeparators = [],
      exclude: excludeSeparators = [],
    } = {},
    lowercaseWords: {
      include: includeLowercaseWords = [],
      exclude: excludeLowercaseWords = [],
    } = {},
  } = options;

  // Combine the common and additional separators and lowercase words
  const separators = [
    ...commonSeparators.filter(sep => !excludeSeparators.includes(sep)),
    ...includeSeparators,
  ];

  // Combine the common and additional lowercase words
  const lowercaseWords = [
    ...commonLowercaseWords.filter(word => !excludeLowercaseWords.includes(word)),
    ...includeLowercaseWords,
  ];

  // Escape special regex characters in separators and join them into a single string
  // This ensures that characters like . * + ? ^ $ { } ( ) | [ ] \ are treated as literal characters in the regex
  const escapedSeparators = separators.map(s => s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')).join('');

  // Create a regex pattern that matches one or more occurrences of any separator
  // The 'g' flag makes it match all occurrences in the string, not just the first one
  const separatorPattern = new RegExp(`[${escapedSeparators}]+`, 'g');

  // Split the string into words
  const words = inputString.toLowerCase().split(separatorPattern);

  // Capitalize each word, respecting the lowercase words rule
  const titleCaseWords = words.map((word, index, array) => {
    // Capitalize each word if it's in the first position, last position, or is not included in the list of lowercaseWords
    if (index === 0 || index === array.length - 1 || !lowercaseWords.includes(word)) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }
    return word;
  });

  return titleCaseWords.join(' ');
};
