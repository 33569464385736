export const GuideStatus = Object.freeze({
  1: 'Evaluation', // Status for an evaluation account (unpaid)
  2: 'Paid', // Status for a fully enabled account (paid).
  3: 'Disabled', // Status for a disabled account (may not login).
  6: 'Archived', // Can login but not edit data.
  7: 'Post-Election', // Status for a fully enabled account (paid).
  8: 'Subordinate', // A guide that is designed to be imported into another guide. This guide can't go live.
  9: 'Subordinate - Disabled', // The same as DISABLED but indicates that the guide was once in status SUBORDINATE.
  10: 'Subordinate - Archive', // The same as ARCHIVE but indicates that the guide was once in status SUBORDINATE.
});
