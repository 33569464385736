import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Link, Typography } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    backgroundColor: '#eef4f8',
    marginBottom: '1.6rem',
    padding: '16px 16px 24px',
  },
  resource: {
    borderBottom: '1px solid #e5e5e5',
    padding: '1.4rem 0',
    '&:first-child': {
      paddingTop: 0,
    },
    '&:last-child': {
      borderBottom: 'none',
      paddingBottom: 0,
    },
  },
  link: {
    textDecoration: 'underline',
  },
});

function ResourceCard({ heading, resources }) {
  const classes = useStyles();

  const renderResourceTitle = (resource) => {
    if (Object.prototype.hasOwnProperty.call(resource, 'url')) {
      return (
        <Link href={resource.url} className={classes.link}>
          {resource.title}
        </Link>
      );
    }

    return resource.title;
  };

  const renderResources = resources.map((resource, index) => (
    <div key={index} className={classes.resource}>
      <Typography component="p" variant="body2">
        {renderResourceTitle(resource)}
      </Typography>

      <Typography component="p" variant="body2">
        {resource.description}
      </Typography>
    </div>
  ));

  return (
    <div className={classes.container}>
      <Typography component="h2" variant="h3">
        {heading}
      </Typography>

      <div>
        {renderResources}
      </div>
    </div>
  );
}

ResourceCard.propTypes = {
  heading: PropTypes.string.isRequired,
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
      ]).isRequired,
      title: PropTypes.string.isRequired,
      url: PropTypes.string,
    }),
  ),
};

ResourceCard.defaultProps = {
  heading: '',
  resources: [],
};

export default ResourceCard;
