import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Select,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Alert from '@material-ui/lab/Alert';
import stateErrors from 'helpers/StateErrors';

/**
 * Component to create a SIMPLE form. Can handle one line text fields and select fields. More field types can be added as needed.
 * NOTE: This component is a WIP with new features being implemented as needed.
 * @param {array} fields Array of form fields to render. See prop types and FormFieldRenderer.stories.js for implementation help
 * @param {bool} submitting State variable for if the form is currently submitting. Disables all inputs when true
 * @param {object} errors Standard errors object, should be stateful and updated from API response
 * @returns React Node
 */
const FormFieldRenderer = ({ fields, submitting, errors }) => {
  const renderText = (field, index, machineWidth, helpText, type = 'text') => (
    <Grid item xs={12} sm={machineWidth} key={index}>
      <FormControl fullWidth>
        <TextField
          id={`custom-form-field-${field.id}`}
          disabled={submitting}
          error={stateErrors.hasError(field.id, errors)}
          helperText={stateErrors.hasError(field.id, errors) ? stateErrors.getError(field.id, errors) : helpText}
          label={field.label}
          multiline={field.multiline}
          name={field.id}
          required={field.required}
          variant={field.readOnly ? 'standard' : 'filled'}
          inputProps={{
            readOnly: field.readOnly,
          }}
          InputProps={{
            disableUnderline: field.readOnly,
          }}
          type={type}
          value={field.value}
          onChange={field.handleValueChange}
        />
      </FormControl>
    </Grid>
  );

  const renderSelect = (field, index, machineWidth) => {
    // check on options prop, since it's required for select fields
    if (typeof field.options == 'undefined' || field.options == null || field.options.length <= 0) {
      return (
        <Grid item xs={12} key={index}>
          <Alert severity="error">Options is required on select fields.</Alert>
        </Grid>
      );
    }
    return (
      <Grid item xs={12} sm={machineWidth} key={index}>
        <FormControl fullWidth variant="filled">
          <InputLabel id={`custom-form-field-label-${field.id}`}>{field.label}</InputLabel>
          <Select
            labelId={`custom-form-field-label-${field.id}`}
            id={`custom-form-field-${field.id}`}
            value={field.value}
            onChange={field.handleValueChange}
          >
            {!field.required && (
              <MenuItem value=""></MenuItem>
            )}
            {field.options.map(op => (
              <MenuItem key={op.value} value={op.value}>
                {op.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const renderDatetime = (field, index, widthValue, helpText) => (
    <Grid item xs={12} sm={widthValue} key={index}>
      <FormControl fullWidth>
        <KeyboardDateTimePicker
          id={`custom-form-field-${field.id}`}
          variant="inline"
          inputVariant="filled"
          label={field.label}
          value={field.value}
          onChange={field.handleValueChange}
          disablePast
          error={stateErrors.hasError(field.id, errors)}
          helperText={stateErrors.hasError(field.id, errors) ? stateErrors.getError(field.id, errors) : helpText}
        />
      </FormControl>
    </Grid>
  );

  const renderEmail = (field, index, widthValue, helpText) => (
    renderText(field, index, widthValue, helpText, 'email')
  );

  // can update this if we want to do some front-end validation
  const renderMultiEmail = (field, index, widthValue, helpText) => (
    renderText(field, index, widthValue, helpText)
  );

  const renderFields = () => (
    fields.map((field, index) => {
      // set width to something material ui understands
      let widthValue = 12;
      if (typeof field.width != 'undefined' && field.width && field.width === 'half') {
        widthValue = 6;
      }
      // prepare the help text, since it is optional
      const helpText = typeof field.help == 'undefined' || field.help == null ? '' : field.help;

      // switch on field type
      if (field.type === 'select') {
        return renderSelect(field, index, widthValue);
      }
      if (field.type === 'text') {
        return renderText(field, index, widthValue, helpText);
      }
      if (field.type === 'datetime') {
        return renderDatetime(field, index, widthValue, helpText);
      }
      if (field.type === 'email') {
        return renderEmail(field, index, widthValue, helpText);
      }
      if (field.type === 'multi-email') {
        return renderMultiEmail(field, index, widthValue, helpText);
      }
      return (
        <Grid item xs={12} key={index}>
          <Alert severity="error">Unsupported field type passed: {field.type}</Alert>
        </Grid>
      );
    })
  );

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container spacing={2}>
        {typeof fields != 'undefined' && fields != null && renderFields()}
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

FormFieldRenderer.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired, // one word id, used for errors and html id (ex. "name")
      value: PropTypes.oneOfType( // state variable holding current value
        [PropTypes.number, PropTypes.string, PropTypes.instanceOf(Date)],
      ),
      handleValueChange: PropTypes.func, // state setter for above value. Optional for read-only fields.
      label: PropTypes.string.isRequired, // field label
      help: PropTypes.string, // field help text (goes underneath the field)
      required: PropTypes.bool, // true if the field is required
      readOnly: PropTypes.bool, // true if the field is read-only
      type: PropTypes.oneOf( // field type, can add more options as needed
        ['select', 'text', 'datetime', 'email', 'multi-email'],
      ).isRequired,
      options: PropTypes.arrayOf( // for 'select' field type, dropdown options - see story for usage
        PropTypes.shape({
          value: PropTypes.number, // id, unique
          label: PropTypes.string, // text label
        }),
      ),
      width: PropTypes.oneOf(['half', 'full']), // width of the field - adjacent half-width fields will create two columns
    }),
  ),
  submitting: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
};

FormFieldRenderer.defaultProps = {
  fields: [],
};

export default FormFieldRenderer;
