import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import theme from 'scripts/theme';
import AddPartyForm from 'components/Parties/Partials/AddPartyForm';
import PartyRow from 'components/Parties/Partials/PartyRow';
import PageHeader from 'components/PageHeader';
import SnackbarAlert from 'components/SnackbarAlert';

const useStyles = makeStyles({
  divider: {
    margin: '2.4rem 0 2.6rem',
  },
});

const PartyContainer = ({
  breadcrumbText,
  breadcrumbUrl,
  deleteUrl,
  getUrl,
  patchUrl,
  postUrl,
  listHeading,
  heading,
  subheading,
  addFormHeading,
  addFormRequiredText,
  addFormButtonText,
  addFormErrorMessage,
  addFormSuccessMessage,
  deleteErrorMessage,
  deleteSuccessMessage,
  updateErrorMessage,
  updateSuccessMessage,
  forbidden,
}) => {
  const classes = useStyles();
  const _snackbar = useRef(null);

  const [loading, setLoading] = useState(true);
  const [parties, setParties] = useState([]);

  useEffect(() => {
    fetchParties();
  }, []);

  const updateParties = (newParties) => {
    newParties.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }

      if (a.name > b.name) {
        return 1;
      }

      return 0;
    });

    setParties(newParties);
  };

  const handleError = (action, message) => {
    let snackbarMessage;

    _snackbar.current.hide();

    if (message) {
      _snackbar.current.show('error', message);
      return;
    }

    switch (action) {
      case 'add':
        snackbarMessage = addFormErrorMessage;
        break;
      case 'update':
        snackbarMessage = updateErrorMessage;
        break;
      case 'delete':
        snackbarMessage = deleteErrorMessage;
        break;
      default:
        snackbarMessage = 'An unknown error occurred. Please contact your guide admin.';
        break;
    }

    _snackbar.current.show('error', snackbarMessage);
  };

  const handleSuccess = (action, data) => {
    let message = 'Operation completed successfully.';
    let updatedParties;

    _snackbar.current.hide();

    if (action == 'add') {
      updatedParties = parties.slice(0);
      updatedParties.push(data);
      message = addFormSuccessMessage;
    }

    if (action == 'update') {
      updatedParties = parties.slice(0);
      updatedParties.forEach(party => {
        if (party.id === data.id) {
          party.abbr = data.abbr;
          party.name = data.name;
        }
      });
      message = updateSuccessMessage;
    }

    if (action == 'delete') {
      updatedParties = parties.filter(party => party.id !== data.id);
      message = deleteSuccessMessage;
    }

    updateParties(updatedParties);

    _snackbar.current.show('success', message);
  };

  const fetchParties = () => {
    setLoading(true);

    return fetch(getUrl)
      .then(res => res.json())
      .then((response) => {
        setLoading(false);
        updateParties(response);
      })
      .catch(error => {
        console.error(error.message);
        setLoading(false);
      });
  };

  const displayPartyList = () => {
    if (loading) {
      return (
        <div>
          <CircularProgress />
        </div>
      );
    }

    return parties.map((party) => (
      <PartyRow
        abbr={party.abbr}
        key={party.id}
        id={party.id}
        name={party.name}
        isDefaultParty={party.default}
        onError={handleError}
        onSuccess={handleSuccess}
        deleteUrl={deleteUrl}
        patchUrl={patchUrl}
        forbidden={forbidden}
      />
    ));
  };

  return (
    <ThemeProvider theme={theme}>
      <SnackbarAlert ref={_snackbar} />

      <PageHeader
        breadcrumbText={breadcrumbText}
        breadcrumbUrl={breadcrumbUrl}
        heading={heading}
        subheading={subheading}
      />

      <div className="ga-container">
        <div className="mdc-layout-grid">
          <div className="mdc-layout-grid__inner">
            <div className="mdc-layout-grid__cell mdc-layout-grid__cell--align-middle mdc-layout-grid__cell--span-9">
              <AddPartyForm
                heading={addFormHeading}
                requiredText={addFormRequiredText}
                buttonText={addFormButtonText}
                validationErrorMessage={addFormErrorMessage}
                onError={handleError}
                onSuccess={handleSuccess}
                postUrl={postUrl}
              />

              <hr className={classes.divider} />

              <Typography component="h2" variant="h2" paragraph>
                {listHeading}
              </Typography>

              {displayPartyList()}
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

PartyContainer.propTypes = {
  breadcrumbText: PropTypes.string,
  breadcrumbUrl: PropTypes.string,
  deleteUrl: PropTypes.string.isRequired,
  getUrl: PropTypes.string.isRequired,
  patchUrl: PropTypes.string.isRequired,
  postUrl: PropTypes.string.isRequired,
  listHeading: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  addFormHeading: PropTypes.string,
  addFormRequiredText: PropTypes.string,
  addFormButtonText: PropTypes.string,
  addFormErrorMessage: PropTypes.string,
  addFormSuccessMessage: PropTypes.string,
  deleteErrorMessage: PropTypes.string,
  deleteSuccessMessage: PropTypes.string,
  updateErrorMessage: PropTypes.string,
  updateSuccessMessage: PropTypes.string,
  forbidden: PropTypes.string,
};

PartyContainer.defaultProps = {
  breadcrumbText: 'Races & Measures',
  breadcrumbUrl: '/races',
  listHeading: 'Existing parties',
  addFormHeading: 'Add additional parties',
  addFormRequiredText: '*Required fields needed to add a new party',
  addFormButtonText: 'Add Party',
  addFormErrorMessage: 'There was an issue adding your party. Correct any errors and try again.',
  addFormSuccessMessage: 'Your party was added successfully!',
  deleteErrorMessage: 'There was an issue deleting your party. Contact your guide admin if this issue continues.',
  deleteSuccessMessage: 'Party was successfully deleted!',
  heading: 'Parties',
  subheading: 'We\'ve got you started with a few standard parties, but you can add as many additional parties as you\'d like. Questions? Check out our help guide.',
  updateErrorMessage: 'There was an issue updating your party. Correct any errors and try again.',
  updateSuccessMessage: 'Your party was updated successfully!',
  forbidden: 'Only administrators can complete this action. Please reach out to your guide administrator.',
};

export default PartyContainer;
