import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  root: {
    '& .MuiFormHelperText-root.Mui-error': {
      position: 'absolute',
      top: '100%',
    },
  },
}));
