import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { GridFooterContainer, GridPagination } from '@mui/x-data-grid-pro';

const DataGridFooterWithSelectedCount = ({ totalSelectedCount, pageSelectedCount }) => {
  const getSelectionText = () => {
    if (totalSelectedCount === 0 && pageSelectedCount === 0) {
      return '';
    }

    if (totalSelectedCount > 0) {
      const totalSelectionText = `${totalSelectedCount} row${totalSelectedCount !== 1 ? 's' : ''} selected`;

      if (pageSelectedCount > 0) {
        return `${totalSelectionText} (${pageSelectedCount} on this page)`;
      }

      return `${totalSelectionText} (0 on this page)`;
    }

    return `${pageSelectedCount} row${pageSelectedCount !== 1 ? 's' : ''} selected`;
  };

  return (
    <GridFooterContainer>
      <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
        <Typography variant="body2">
          {getSelectionText()}
        </Typography>
        <GridPagination />
      </Box>
    </GridFooterContainer>
  );
};

DataGridFooterWithSelectedCount.propTypes = {
  totalSelectedCount: PropTypes.number.isRequired,
  pageSelectedCount: PropTypes.number.isRequired,
};

export default DataGridFooterWithSelectedCount;
