import React from 'react';
import PropTypes from 'prop-types';
import { createStyles, withStyles } from '@material-ui/styles';
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';

const styles = () => createStyles({
  actionButton: {
    minWidth: '100px',
  },
  field: {
    marginBottom: '1.2rem',
  },
});

class GISDistrictBulkNameEditTools extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      metadataValue: '',
      prefix: '',
      submitting: false,
      suffix: '',
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleReplaceName = this.handleReplaceName.bind(this);
    this.handleAddPrefix = this.handleAddPrefix.bind(this);
    this.handleAddSuffix = this.handleAddSuffix.bind(this);
  }

  handleInputChange(event) {
    const { name, value } = event.target;

    this.setState({
      [name]: value,
    });
  }

  handleReplaceName() {
    const { metadataValue } = this.state;
    const { onReplaceName } = this.props;
    onReplaceName(metadataValue);

    this.setState({
      metadataValue: '',
    });
  }

  handleAddPrefix() {
    const { prefix } = this.state;
    const { onAddPrefix } = this.props;
    onAddPrefix(prefix);

    this.setState({
      prefix: '',
    });
  }

  handleAddSuffix() {
    const { suffix } = this.state;
    const { onAddSuffix } = this.props;
    onAddSuffix(suffix);

    this.setState({
      suffix: '',
    });
  }

  hasError(fieldName) {
    const { errors } = this.props;
    return Object.prototype.hasOwnProperty.call(errors, fieldName);
  }

  render() {
    const { classes, metadataValues } = this.props;
    const {
      prefix,
      suffix,
      metadataValue,
      errors,
      submitting,
    } = this.state;

    return (
      <div>
        {/* Field to replace name with meta data value */}
        <Grid
          alignItems="center"
          container
          className={classes.field}
          justifyContent="space-between"
        >
          <Grid item xs={9}>
            <FormControl
              error={this.hasError('metadataValue')}
              disabled={submitting}
              fullWidth
              required
              variant="filled"
            >
              <InputLabel id="metadata-value-label">
                Replace name with data value
              </InputLabel>

              <Select
                id="metadata-value-select"
                error={this.hasError('metadataValue')}
                name="metadataValue"
                value={metadataValue}
                onChange={this.handleInputChange}
              >
                {metadataValues.map((metadata, index) => (
                  <MenuItem key={index} value={metadata}>
                    {metadata.toLowerCase()}
                  </MenuItem>
                ))}
              </Select>

              <FormHelperText>
                {this.hasError('metadataValue') ? errors.metadataValue : ''}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item>
            <Button
              className={classes.actionButton}
              color="secondary"
              onClick={this.handleReplaceName}
              variant="contained"
            >
              Replace
            </Button>
          </Grid>
        </Grid>

        {/* Field to add prefix to all district names */}
        <Grid
          alignItems="center"
          container
          className={classes.field}
          justifyContent="space-between"
        >
          <Grid item xs={9}>
            <FormControl fullWidth>
              <TextField
                id={prefix}
                disabled={submitting}
                error={this.hasError('prefix')}
                helperText={this.hasError('prefix') ? errors.prefix : ''}
                label="Add Prefix"
                onChange={this.handleInputChange}
                name="prefix"
                required
                value={prefix}
                variant="filled"
              />
            </FormControl>
          </Grid>

          <Grid item>
            <Button
              className={classes.actionButton}
              color="secondary"
              onClick={this.handleAddPrefix}
              variant="contained"
            >
              Add
            </Button>
          </Grid>
        </Grid>

        {/* Field to add suffix to all district names */}
        <Grid
          alignItems="center"
          container
          className={classes.field}
          justifyContent="space-between"
        >
          <Grid item xs={9}>
            <FormControl fullWidth>
              <TextField
                id={suffix}
                disabled={submitting}
                error={this.hasError('suffix')}
                helperText={this.hasError('suffix') ? errors.suffix : ''}
                label="Add Suffix"
                onChange={this.handleInputChange}
                name="suffix"
                required
                value={suffix}
                variant="filled"
              />
            </FormControl>
          </Grid>

          <Grid item>
            <Button
              className={classes.actionButton}
              color="secondary"
              onClick={this.handleAddSuffix}
              variant="contained"
            >
              Add
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}

GISDistrictBulkNameEditTools.propTypes = {
  classes: PropTypes.object,
  errors: PropTypes.object,
  metadataValues: PropTypes.array,
  onReplaceName: PropTypes.func,
  onAddPrefix: PropTypes.func,
  onAddSuffix: PropTypes.func,
};

GISDistrictBulkNameEditTools.defaultProps = {
  errors: {},
  metadataValues: [],
  onReplaceName: () => {},
  onAddPrefix: () => {},
  onAddSuffix: () => {},
};

export default withStyles(styles)(GISDistrictBulkNameEditTools);
