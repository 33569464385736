import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core';

const DistrictMetaDataCard = ({ className, metadata }) => (
  <Card className={className}>
    <CardContent>
      <Grid container spacing={2}>
        {Object.entries(metadata).map(([key, value], index) => (
          <Grid key={index} item>
            <Typography component="span" variant="body2">
              {key.toLowerCase()}: {value}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </CardContent>
  </Card>
);

DistrictMetaDataCard.propTypes = {
  className: PropTypes.string,
  metadata: PropTypes.object,
};

DistrictMetaDataCard.defaultProps = {
  className: '',
  metadata: {},
};

export default DistrictMetaDataCard;
