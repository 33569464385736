import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import theme from 'scripts/theme';

import { MessagesProvider } from './messages';
import { AppSnackbarProvider } from './AppSnackbar';

const queryClient = new QueryClient();

const ApplicationContextProvider = ({ children, messages }) => (
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={theme}>
      <MessagesProvider messages={messages}>
        <AppSnackbarProvider>
          {children}
        </AppSnackbarProvider>
      </MessagesProvider>
    </ThemeProvider>
  </QueryClientProvider>
);

ApplicationContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  messages: PropTypes.object,
};

ApplicationContextProvider.defaultProps = {
  messages: {},
};

export default ApplicationContextProvider;
