import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

function SectionHeadingWithButton(props) {
  const {
    children,
    heading,
    subheading,
  } = props;
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <div>
        <Typography variant="h2" component="h2" paragraph>
          {heading}
        </Typography>

        <Typography variant="body1" component="p" paragraph>
          {subheading}
        </Typography>
      </div>

      <div>
        {children}
      </div>
    </div>
  );
}

SectionHeadingWithButton.propTypes = {
  children: PropTypes.node,
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string,
};

export default SectionHeadingWithButton;
