import React from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

import { useStyles } from './LinkButton.styles';

const LinkButton = ({ children, buttonProps }) => {
  const classes = useStyles();

  return (
    <Button className={classes.linkButton} variant="text" color="primary" {...buttonProps}>
      {children}
    </Button>
  );
};

LinkButton.propTypes = {
  children: PropTypes.node,
  buttonProps: PropTypes.object,
};

LinkButton.defaultProps = {
  children: null,
  buttonProps: {},
};

export {
  LinkButton,
};
