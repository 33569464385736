import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ConfirmationDialog from 'components/ConfirmationDialog';
import EditQuestionForm from 'components/RaceQuestions/EditQuestionForm';

const useStyles = makeStyles({
  emptyStateContainer: {
    backgroundColor: '#ededed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.4rem',
    color: '#999',
    padding: '2.4rem 1rem',
    borderRadius: '4px',
  },
  emptyState: {
    textAlign: 'center',
  },
  disabled: {
    cursor: 'not-allowed',
  },
});

const EditQuestionFormList = ({
  languages,
  onQuestionDelete,
  onQuestionSubmit,
  questions,
  typeOptions,
  disabled,
  handleQuestionReorder,
  onFailedDrop,
  moveQuestion,
}) => {
  const classes = useStyles();
  const [deleteConfirmBody, setDeleteConfirmBody] = useState({});
  const [deleteConfirmHeading, setDeleteConfirmHeading] = useState('');
  const [deleteConfirmId, setDeleteConfirmId] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const handleShowDeleteConfirmation = (id) => {
    const dialogHeading = 'Delete question?';
    const dialogBody = (
      <Typography component="p" variant="body1" className="ga-m-bottom--small">
        Are you sure you want to delete this question? If this question has answers, it can be restored from the recycling bin. Otherwise, the question will be lost <strong>along with any translations.</strong>
      </Typography>
    );

    setShowDeleteConfirm(true);
    setDeleteConfirmBody(dialogBody);
    setDeleteConfirmHeading(dialogHeading);
    setDeleteConfirmId(id);
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmId(null);
    setShowDeleteConfirm(false);
  };

  const handleDeleteConfirm = () => {
    onQuestionDelete(deleteConfirmId);
    setShowDeleteConfirm(false);
  };

  const handleQuestionSubmit = (id, data) => {
    onQuestionSubmit(id, data);
  };

  const renderEmptyState = () => (
    <div className={classes.emptyStateContainer}>
      <div className={classes.emptyState}>
        <p className="ga-m-bottom--medium">
          There are currently no questions available for this question set.
        </p>
        <p>
          Get started by creating one now by filling out the form above.
        </p>
      </div>
    </div>
  );

  const renderQuestions = () => {
    if (questions.length > 0) {
      return (
        <DndProvider backend={HTML5Backend}>
          {questions.map((question) => (
            <EditQuestionForm
              key={question.id}
              body={question.body}
              errors={question.errors}
              id={question.id}
              index={question.index}
              languages={languages}
              maxQuestionLength={question.maxQuestionLength}
              onDelete={handleShowDeleteConfirmation}
              onSubmit={handleQuestionSubmit}
              submitting={question.submitting}
              type={question.questionFormat}
              typeOptions={typeOptions}
              translations={question.translations}
              disabled={disabled}
              moveQuestion={moveQuestion}
              onReorder={handleQuestionReorder}
              onFailedDrop={onFailedDrop}
            />
          ))}
        </DndProvider>
      );
    }

    return renderEmptyState();
  };

  return (
    <div>
      <ConfirmationDialog
        confirmButtonText="Yes, I'm sure"
        heading={deleteConfirmHeading}
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
        open={showDeleteConfirm}
      >
        {deleteConfirmBody}
      </ConfirmationDialog>

      {renderQuestions()}
    </div>
  );
};

EditQuestionFormList.propTypes = {
  languages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
  })),
  onQuestionDelete: PropTypes.func,
  onQuestionSubmit: PropTypes.func,
  questions: PropTypes.arrayOf(PropTypes.shape({
    body: PropTypes.string,
    id: PropTypes.number,
    index: PropTypes.number,
    maxQuestionLength: PropTypes.number,
    questionFormat: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      scaleType: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        prefix: PropTypes.string,
      }),
      defaultMaxLength: PropTypes.number,
      active: PropTypes.bool,
    }),
    translations: PropTypes.object,
  })).isRequired,
  typeOptions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    defaultMaxLength: PropTypes.number,
    localizable: PropTypes.bool,
    scaleType: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      prefix: PropTypes.string,
    }),
    active: PropTypes.bool,
  })).isRequired,
  disabled: PropTypes.bool.isRequired,
  handleQuestionReorder: PropTypes.func.isRequired,
  moveQuestion: PropTypes.func.isRequired,
  onFailedDrop: PropTypes.func.isRequired,
};

EditQuestionFormList.defaultProps = {
  onQuestionDelete: () => {},
  onQuestionSubmit: () => {},
};

export default EditQuestionFormList;
