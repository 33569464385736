import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';
import DistrictSelectorContainer from 'components/Districts/Partials/DistrictSelectorContainer';

const useStyles = makeStyles(() => ({
  cardContainer: {
    padding: '.938rem 1.75rem 1.875rem 1.75rem',
  },
}));

export default function RaceDistrictForm({
  district,
  errors,
  guideId,
  onChange,
  title,
}) {
  const classes = useStyles();

  return (
    <Card>
      <CardContent className={classes.cardContainer}>
        <Typography component="h2" variant="h3" color="primary" paragraph>
          {title}
        </Typography>

        <DistrictSelectorContainer
          district={district}
          error={errors && Object.prototype.hasOwnProperty.call(errors, 'district') ? 'You must select a district' : ''}
          guideId={guideId}
          onChange={onChange}
        />
      </CardContent>
    </Card>
  );
}

RaceDistrictForm.propTypes = {
  district: PropTypes.object,
  errors: PropTypes.object,
  guideId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onChange: PropTypes.func,
  title: PropTypes.string,
};

RaceDistrictForm.defaultProps = {
  district: {},
  errors: {},
  onChange: () => {},
  title: 'District',
};
