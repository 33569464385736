import PropTypes from 'prop-types';

export default PropTypes.shape(
  {
    active: PropTypes.bool,
    base64EncodedSecret: PropTypes.string.isRequired,
    created: PropTypes.string,
    description: PropTypes.string,
    email: PropTypes.string,
    expiration_date: PropTypes.string,
    fa_entity_id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    guide_id: PropTypes.number,
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    modified: PropTypes.string,
    name: PropTypes.string.isRequired,
  },
);
