import PropTypes from 'prop-types';
import { affiliatePropType } from 'propTypes';

export default PropTypes.shape({
  affiliate: affiliatePropType,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  languages: PropTypes.array,
  live: PropTypes.bool,
  metaData: PropTypes.object,
  ownerId: PropTypes.number,
  version: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  voterGuide: PropTypes.shape({
    apiKeys: PropTypes.array,
    ballotItems: PropTypes.array,
    imports: PropTypes.array,
    itemCategories: PropTypes.array,
    name: PropTypes.string.isRequired,
    questionSets: PropTypes.array,
    rlength: PropTypes.number,
    status: PropTypes.shape({
      code: PropTypes.number,
      name: PropTypes.string,
    }),
  }),
});
