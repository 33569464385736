import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  FormHelperText,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { DragHandle } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

import { useReorderChild } from 'hooks/useReorder';

import LanguageTabsInput from 'components/LanguageTabsInput';

const useStyles = makeStyles({
  actionButton: {
    marginRight: '1rem',
    minWidth: '75px',
  },
  destructiveButton: {
    minWidth: '75px',
    color: '#ac1b3d',
    '&:hover': {
      color: '#6B1126',
    },
  },
  dragIcon: {
    position: 'relative',
    top: '2.5rem',
    left: '95%',
    cursor: 'grab',
  },
});

const EditQuestionForm = ({
  body,
  errors,
  id,
  index,
  languages,
  maxQuestionLength,
  onDelete,
  onSubmit,
  submitting,
  translations,
  type,
  typeOptions,
  disabled,
  moveQuestion,
  onReorder,
  onFailedDrop,
}) => {
  const classes = useStyles();
  const [form, setForm] = useState({
    body,
    maxQuestionLength,
    type: type.id,
  });
  const [showMaxLengthField, setShowMaxLengthField] = useState(false);

  // ABSTRACTION!
  const {
    handlerId, ref, opacity,
  } = useReorderChild({
    index,
    moveFunction: moveQuestion,
    onReorder,
    id,
    disabled,
    onFailedDrop,
  });

  useEffect(() => {
    const newForm = updateMaxLengthVisibility(type.id, form, true);
    updateLanguageValues(newForm);
  }, []);

  // Add our dynamic list of language translations to state
  const updateLanguageValues = (fromForm = null) => {
    const newFormState = fromForm ? { ...fromForm } : { ...form };

    languages.forEach(lang => {
      let val = '';

      if (Object.prototype.hasOwnProperty.call(translations, lang.id)) {
        val = translations[lang.id];
      }

      newFormState[lang.id] = val;
    });

    setForm(newFormState);
  };

  const updateMaxLengthVisibility = (newType, fromForm = null, useOldMaxLength = false) => {
    const newForm = fromForm ? { ...fromForm } : { ...form };
    const matchedType = typeOptions.find(option => option.id === newType);
    let showMaxLengthFieldTemp = false;

    if (matchedType?.defaultMaxLength) {
      newForm.maxQuestionLength = matchedType.defaultMaxLength;
      showMaxLengthFieldTemp = true;
    }

    if (useOldMaxLength) {
      newForm.maxQuestionLength = fromForm.maxQuestionLength;
    }

    setForm(newForm);
    setShowMaxLengthField(showMaxLengthFieldTemp);
    return newForm;
  };

  const handleDelete = () => {
    onDelete(id);
  };

  const handleInputChange = (event) => {
    const { target } = event;
    const { name, value } = target;
    const newForm = { ...form };

    newForm[name] = value;

    setForm(newForm);
    if (name === 'type') {
      updateMaxLengthVisibility(value, newForm);
    }
  };

  const handleTranslationChange = (data) => {
    const newForm = { ...form };

    Object.keys(data).forEach(key => {
      newForm[key] = data[key];
    });

    setForm(newForm);
  };

  const handleSubmit = () => {
    onSubmit(id, form);
  };

  const hasError = (fieldName) => (
    Object.prototype.hasOwnProperty.call(errors, fieldName)
  );

  const renderTypeOptions = () => {
    if (typeOptions && typeOptions.length > 0) {
      return typeOptions.map(t => (
        <MenuItem
          key={t.id}
          value={t.id}
        >
          {t.name}
        </MenuItem>
      ));
    }
  };

  const renderMaxLengthField = () => {
    if (showMaxLengthField) {
      return (
        <FormControl fullWidth>
          <TextField
            disabled={submitting}
            error={hasError('maxQuestionLength')}
            helperText={hasError('maxQuestionLength') ? errors.maxQuestionLength : ''}
            id={`${id}-maxQuestionLength`}
            label="Answer Character Limit"
            name="maxQuestionLength"
            onChange={handleInputChange}
            required
            type="number"
            value={form.maxQuestionLength ? form.maxQuestionLength : undefined}
            variant="filled"
          />
        </FormControl>
      );
    }
  };

  const renderDeleteButton = () => {
    if (id) {
      return (
        <Button
          className={classes.destructiveButton}
          color="secondary"
          onClick={handleDelete}
          size="large"
          disabled={submitting}
        >
          Delete
        </Button>
      );
    }
  };

  return (
    <Box
      className="ga-m-bottom--xx-large"
      position={'relative'}
      ref={ref}
      data-handler-id={handlerId}
      style={{ opacity }}
    >
      <DragHandle className={classes.dragIcon} />
      <Grid container spacing={6}>
        <Grid item xs={5}>
          <div className="ga-m-bottom--large">
            <FormControl
              disabled={submitting}
              error={hasError('type')}
              fullWidth
              variant="outlined"
            >
              <Select
                displayEmpty
                inputProps={{ 'aria-label': 'Question type selector' }}
                name="type"
                onChange={handleInputChange}
                value={form.type ? form.type : undefined}
              >
                <MenuItem value="">
                  <em>Select a Question Type</em>
                </MenuItem>

                {renderTypeOptions()}
              </Select>

              <FormHelperText>
                {hasError('type') ? errors.type : ''}
              </FormHelperText>
            </FormControl>
          </div>

          {renderMaxLengthField()}
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={10}>
          <LanguageTabsInput
            body={form.body}
            errors={errors}
            languages={languages}
            onChange={handleTranslationChange}
            submitting={submitting}
            translations={form}
          />
        </Grid>
      </Grid>

      <hr />

      <div>
        <Button
          className={classes.actionButton}
          color="secondary"
          disabled={submitting}
          onClick={handleSubmit}
          size="large"
          variant="contained"
        >
          Save
        </Button>

        {renderDeleteButton()}
      </div>
    </Box>
  );
};

EditQuestionForm.propTypes = {
  body: PropTypes.string,
  errors: PropTypes.object,
  id: PropTypes.number,
  languages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
  })),
  maxQuestionLength: PropTypes.number,
  onDelete: PropTypes.func,
  onSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  translations: PropTypes.object,
  index: PropTypes.number,
  type: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    defaultMaxLength: PropTypes.number,
    localizable: PropTypes.bool,
    scaleType: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      prefix: PropTypes.string,
    }),
    active: PropTypes.bool,
  }),
  typeOptions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    defaultMaxLength: PropTypes.number,
    localizable: PropTypes.bool,
    scaleType: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      prefix: PropTypes.string,
    }),
    active: PropTypes.bool,
  })).isRequired,
  disabled: PropTypes.bool.isRequired,
  moveQuestion: PropTypes.func.isRequired,
  onReorder: PropTypes.func.isRequired,
  onFailedDrop: PropTypes.func.isRequired,
};

EditQuestionForm.defaultProps = {
  body: '',
  errors: {},
  id: null,
  index: null,
  languages: [],
  maxQuestionLength: 750,
  onDelete: () => {},
  onSubmit: () => {},
  submitting: false,
  translations: {},
  type: {},
};

export default EditQuestionForm;
