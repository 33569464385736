import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  container: {
    alignItems: 'center',
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  subheading: {
    marginBottom: '0.75rem',
  },
  break: {
    wordBreak: 'break-all',
  },
  breadcrumb: {
    marginBottom: '1.25rem',
  },
}));
