import React from 'react';
import PropTypes from 'prop-types';
import {
  FormatAlignCenter,
  FormatAlignLeft,
  FormatAlignRight,
} from '@material-ui/icons';
import { decode } from 'html-entities';

// ===================================================================================
// controls
// ===================================================================================
const TextControl = ({ children, style }) => <span style={style}>{children}</span>;
TextControl.propTypes = { children: PropTypes.node, style: PropTypes.object };

// ===================================================================================
// Custom control names
// ===================================================================================
const ControlNames = {
  leftAlign: 'LEFT_ALIGN',
  centerAlign: 'CENTER_ALIGN',
  rightAlign: 'RIGHT_ALIGN',
  lineBreak: 'LINE_BREAK',
};

// ===================================================================================
// Custom Controls
// ===================================================================================
const CustomControls = [
  {
    name: ControlNames.leftAlign,
    icon: <FormatAlignLeft />,
    type: 'block',
    blockWrapper: <TextControl style={{ textAlign: 'left' }} />,
  },
  {
    name: ControlNames.centerAlign,
    icon: <FormatAlignCenter />,
    type: 'block',
    blockWrapper: <TextControl style={{ textAlign: 'center' }} />,
  },
  {
    name: ControlNames.rightAlign,
    icon: <FormatAlignRight />,
    type: 'block',
    blockWrapper: <TextControl style={{ textAlign: 'right' }} />,
  },
];

// ===================================================================================
// Get block from HTML
// ===================================================================================
const handleHtmlToBlock = (nodeName, node) => {
  const result = { data: {} };
  if (nodeName == 'p') {
    if (node.style.textAlign == 'left') {
      result.type = ControlNames.leftAlign;
    } else if (node.style.textAlign == 'center') {
      result.type = ControlNames.centerAlign;
    } else if (node.style.textAlign == 'right') {
      result.type = ControlNames.rightAlign;
    } else {
      result.type = ControlNames.leftAlign;
    }
  } else if (nodeName === 'li') {
    if (node.dataset.type && node.dataset.type === 'ordered') {
      result.type = 'ordered-list-item';
    } else {
      result.type = 'unordered-list-item';
    }
  }
  return result;
};

const handleHtmlToEntity = (nodeName, node, createEntity) => {
  if (nodeName === 'a') {
    return createEntity(
      'LINK',
      'MUTABLE',
      { url: node.href },
    );
  }
};

// ===================================================================================
// Get Html from block
// ===================================================================================
const handleBlockToHtml = (block) => {
  let result;
  if (block.type === ControlNames.leftAlign) {
    result = <p style={{ textAlign: 'left' }} />;
  } else if (block.type === ControlNames.centerAlign) {
    result = <p style={{ textAlign: 'center' }} />;
  } else if (block.type === ControlNames.rightAlign) {
    result = <p style={{ textAlign: 'right' }} />;
  } else if (block.type === 'unordered-list-item') {
    result = <li />;
  } else if (block.type === 'ordered-list-item') {
    result = <li data-type="ordered" />;
  } else {
    result = <p style={{ textAlign: 'left' }} />;
  }
  return result;
};

// ===================================================================================
// Get Html from link entities
// ===================================================================================
const handleEntityToHtml = (entity, originalText) => {
  if (entity.type === 'LINK') {
    // this if stmt is to append https to the front of any relative urls, because draft-convert breaks them without telling us
    let { url } = entity.data;
    if (!/^https?:\/\//i.test(url)) {
      url = `https://${url}`;
    }
    // decode call below is so that &amp; and other spec chars don't get escaped over and over again
    return <a href={url}>{decode(originalText)}</a>;
  }
  return originalText;
};

// ===================================================================================
// Exports
// ===================================================================================
export {
  ControlNames,
  CustomControls,
  handleHtmlToBlock,
  handleBlockToHtml,
  handleEntityToHtml,
  handleHtmlToEntity,
};
