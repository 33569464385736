import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles({
  actions: {
    justifyContent: 'flex-end',
  },
  actionButton: {
    marginLeft: '1rem',
  },
});

const ConfirmationDialog = ({
  children,
  cancelButtonText,
  confirmButtonText,
  heading,
  onCancel,
  onConfirm,
  open,
  submitting,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      aria-labelledby="confirmation-dialog"
      maxWidth="sm"
      open={open}
      onClose={onCancel}
    >

      <DialogTitle id="confirmation-dialog" disableTypography>
        <Typography component="h1" variant="h2" color="primary">
          {heading}
        </Typography>

        <IconButton aria-label="close" onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {children}
      </DialogContent>

      <DialogActions className={classes.actions}>
        <Button onClick={onCancel}>
          {cancelButtonText}
        </Button>

        <Button
          className={classes.actionButton}
          color="secondary"
          disabled={submitting}
          onClick={onConfirm}
          variant="contained"
        >
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  cancelButtonText: PropTypes.string,
  children: PropTypes.node.isRequired,
  confirmButtonText: PropTypes.string,
  heading: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  open: PropTypes.bool,
  submitting: PropTypes.bool,
};

ConfirmationDialog.defaultProps = {
  cancelButtonText: 'Cancel',
  confirmButtonText: 'Confirm',
  heading: 'Are you sure?',
  onCancel: () => {},
  onConfirm: () => {},
  open: false,
  submitting: false,
};

export default ConfirmationDialog;
