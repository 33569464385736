import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles({
  button: {
    background: 'none',
    border: 'none',
    padding: '0',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    color: '#757575',
    marginLeft: '6px',
    fontSize: '20px',
  },
  hiddenText: {
    fontSize: '22px',
    lineHeight: '22px',
    letterSpacing: '0.2px',
  },
  text: {
    fontSize: '14px',
    lineHeight: '22px',
  },
});

const ShowHide = ({ text, maxDots, scroll }) => {
  const classes = useStyles();
  const [visibility, setVisibility] = useState(false);

  const textLength = text.length;

  const hiddenText = () => {
    let hiddenTextStr = '';
    for (let i = 0; i < (maxDots || textLength); i++) {
      hiddenTextStr += '\u2022';
    }
    return hiddenTextStr;
  };

  const manageVisibility = () => {
    if (visibility) {
      setVisibility(false);
    } else {
      setVisibility(true);
    }
  };

  return (
    <div className={classes.container} style={{ width: '100%' }}>
      <p className={visibility ? classes.text : classes.hiddenText} style={{ overflowX: scroll ? 'scroll' : 'unset' }}>{visibility ? text : hiddenText()}</p>
      <button type='button' className={classes.button} onClick={manageVisibility}>
        {visibility ? (<VisibilityIcon className={classes.icon} />) : (<VisibilityOffIcon className={classes.icon} />)}
      </button>
    </div>
  );
};

ShowHide.propTypes = {
  text: PropTypes.string.isRequired,
  maxDots: PropTypes.number,
  scroll: PropTypes.bool,
};

ShowHide.defaultProps = {
  scroll: false,
};

export default ShowHide;
