import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  ListSubheader,
} from '@material-ui/core';

/*
 * When using shouldUseGroupedOptions, data structure for options should be as follows:
options = [
  {
    title: String, optional. Leave out or blank or null for the items in this optionGroup to be at the top level.
    options: [
      { id, name, value }
    ]
  },
  ...
]
 * shouldUseGroupedOptions does not work with multiple=true
 */
const SelectControl = ({
  className,
  disabled,
  error,
  helperText,
  id,
  label,
  multiple,
  onChange,
  options,
  value,
  shouldUseGroupedOptions,
}) => {
  const [selectedDisplay, setSelectedDisplay] = useState([]);

  useEffect(() => {
    if (Array.isArray(value)) {
      setSelectedDisplay(formatMultipleDisplayValues());
      return;
    }

    setSelectedDisplay(formatSingleDisplayValue());
  }, [options, value]);

  const formatSingleDisplayValue = () => {
    if (!shouldUseGroupedOptions) {
      const match = options.find(opt => opt.id == value);

      if (match) {
        return match.name;
      }

      return '';
    }
    // for option groups
    let foundOption = null;
    options.forEach(optionGroup => {
      const search = optionGroup.options.find(o => o.id == value);
      if (search) {
        foundOption = search;
      }
    });
    if (foundOption) {
      return foundOption.name;
    }
    return '';
  };

  const formatMultipleDisplayValues = () => {
    const displayValues = [];

    value.forEach(val => {
      const match = options.find(e => e.id == val);

      if (match) {
        displayValues.push(match.name);
      }
    });

    return displayValues;
  };

  const renderValue = () => {
    if (Array.isArray(selectedDisplay)) {
      return selectedDisplay.join(', ');
    }

    return selectedDisplay;
  };

  const renderOptionGroup = (optionGroup, index) => {
    const items = optionGroup.options.map(option => (<MenuItem key={`${id}-option${option.id}`} value={option.id}>{option.name}</MenuItem>));
    if (typeof optionGroup.title == 'undefined' || optionGroup.title == null || optionGroup.title == '') {
      // return all members of optionGroup as normal menu items
      return [items];
    }
    // return a subheader followed by all of the menu items
    return [
      <ListSubheader key={`${id}-subheader-${index}`}>{optionGroup.title}</ListSubheader>,
      items,
    ];
  };

  return (
    <div className={className}>
      <FormControl
        disabled={disabled}
        error={error}
        fullWidth
        variant="filled"
      >
        <InputLabel id={`${id}-label`}>
          {label}
        </InputLabel>

        <Select
          labelId={`${id}-label`}
          id={id}
          multiple={multiple}
          onChange={onChange}
          renderValue={() => renderValue()}
          value={value}
        >
          <MenuItem value="">
            Choose an option
          </MenuItem>

          {!shouldUseGroupedOptions && options.map(option => {
            if (multiple) {
              return (
                <MenuItem key={`${id}-option${option.id}`} value={option.id}>
                  <Checkbox checked={options.indexOf(option.id) > -1} />
                  <ListItemText primary={option.name} />
                </MenuItem>
              );
            }

            return (<MenuItem key={`${id}-option${option.id}`} value={option.id}>{option.name}</MenuItem>);
          })}

          {shouldUseGroupedOptions && options.map((optionGroup, index) => renderOptionGroup(optionGroup, index))}
        </Select>

        <FormHelperText>
          {helperText}
        </FormHelperText>
      </FormControl>
    </div>
  );
};

SelectControl.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
  })).isRequired,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.number]),
  shouldUseGroupedOptions: PropTypes.bool,
};

SelectControl.defaultProps = {
  className: '',
  disabled: false,
  error: false,
  helperText: '',
  multiple: false,
  onChange: () => {},
  options: [],
  value: '',
  shouldUseGroupedOptions: false,
};

export default SelectControl;
