import React from 'react';
import PropTypes from 'prop-types';
import { StepTitle } from 'components/RaceSteps';
import { Box } from '@material-ui/core';
import WYSIWYG from 'components/WYSIWYG';

const Container = ({ raceClassId, stepState, messages }) => {
  const onChange = (htmlData) => {
    stepState.setSavedDescription(htmlData);
  };

  return (
    <div>
      <StepTitle title={raceClassId === 'R' ? messages.raceTitle : messages.measureTitle}>
        {raceClassId === 'R' ? messages.raceSubtitle : messages.measureSubtitle}
      </StepTitle>
      <Box marginBottom={5} paddingBottom={5}>
        <WYSIWYG
          onChange={onChange}
          value={stepState.initialDescription}
          label={messages.placeholder}
        />
      </Box>
    </div>
  );
};

Container.propTypes = {
  raceClassId: PropTypes.string,
  stepState: PropTypes.shape({
    savedDescription: PropTypes.string.isRequired,
    setSavedDescription: PropTypes.func.isRequired,
    initialDescription: PropTypes.string.isRequired,
  }),
  messages: PropTypes.object.isRequired,
};

Container.defaultProps = {

};

export default Container;
