import {
  useState,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import clone from 'rfdc/default';
import update from 'immutability-helper';
import { useDrag, useDrop } from 'react-dnd';
import fetchUtil from 'helpers/Fetch';

/**
 * READ THIS! PLEASE!
 * This file contains 2 hooks in one. useReorderParent and useReorderChild.
 * How to use:
 * In the container component (top level component where the data list is modified), import useReorderParent with the proper arguments.
 * Then, pass onReorder, moveItem, ReorderMiddleware and disabled down to the child components where the reordering happens.
 * Also, be sure to use the setOriginalDataListItem in the parent component when you modify the given state outside of reordering.
 * setOriginalDataListItem will be renamed based on passed in originalStateName arg
 *
 * You will still need to wrap the reorderable list with the DndProvider component.
 *
 * In the child component (furthest down, should represent a single draggable row), call useReorderChild with the proper arguments.
 * Then, attach the returned {handlerId, ref, opacity} to the rows outermost div
 */

const capitalize = (str) => `${str.charAt(0).toUpperCase()}${str.slice(1)}`;

const useReorderParent = ({
  dataList, // state version of original list
  setDataList, // setter for the above
  originalStateName, // string of what you want the name of the dataList to be in the parent component (ex. 'categories' for the categories page)
  apiEndpoint, // api endpoint
  apiParamName, // what should the PUT param be named
  showError, // function that calls the parent components error message display
}) => {
  if (originalStateName === 'disabled' || originalStateName === 'onReorder' || originalStateName === 'moveItem') {
    throw new Error('Reserved name given for original data list name');
  }

  // make a copy for failed reorders
  const [originalDataList, setOriginalDataList] = useState(clone(dataList));
  // used to prevent reordering while API call is happening
  const [disabled, setDisabled] = useState(false);

  // -----------------------
  // DRAG AND DROP FUNCTIONS
  // -----------------------

  const onReorder = useCallback(async (givenNewDataIndices = null) => {
    setDisabled(true);

    // map to db expectations
    let newDataIndices;
    if (givenNewDataIndices) {
      newDataIndices = givenNewDataIndices;
    } else {
      newDataIndices = dataList.map((d, index) => ({ id: d.id, index }));
    }

    const BODY = {};
    BODY[apiParamName] = newDataIndices;

    try {
      await fetchUtil(apiEndpoint, 'PUT', BODY);

      const dataNewIndices = dataList.map((q, index) => (
        {
          ...q,
          index,
        }
      ));

      setDataList(dataNewIndices.slice());
      setOriginalDataList(dataNewIndices.slice());

      setDisabled(false);
    } catch (e) {
      setDisabled(false);

      // Revert questions to original order
      setDataList(originalDataList.slice());

      // Bubble up the error notification
      showError();
    }
  }, [dataList]);

  const onFailedDrop = useCallback(() => {
    setDataList(originalDataList);
    setDisabled(false);
  });

  const moveItem = useCallback((dragIndex, hoverIndex) => {
    const dragItem = dataList[dragIndex];
    const newDataOrder = update(dataList, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragItem],
      ],
    });

    setDataList(newDataOrder);
  }, [dataList]);

  const returnBody = {
    onReorder,
    moveItem,
    disabled,
    setDisabled,
    onFailedDrop,
  };

  returnBody[originalStateName] = originalDataList;
  returnBody[`set${capitalize(originalStateName)}`] = setOriginalDataList;

  return returnBody;
};

const useReorderChild = ({
  index, // index of the draggable row in the list
  moveFunction, // moveItem function passed down from useReorderParent
  onReorder, // onReorder function from useReorderParent
  id, // unique id from database of the draggable row in the list
  disabled, // disabled state from useReorderParent
  onFailedDrop, // reset the state because they dropped wrong
}) => {
  const ref = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: 'card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveFunction(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: ({ id, index }),
    canDrag: () => !disabled,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end(item, monitor) {
      const dropResult = monitor.getDropResult();
      const didDrop = monitor.didDrop();
      if (item && dropResult && didDrop) {
        onReorder();
      } else {
        onFailedDrop();
      }
    },
  });

  const opacity = useMemo(() => (isDragging ? 0 : 1), [isDragging]);
  drag(drop(ref));

  return {
    handlerId,
    ref,
    opacity,
  };
};

export {
  useReorderParent,
  useReorderChild,
};
