import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import RaceQuestionSetForm from 'components/RaceQuestions/RaceQuestionSetForm';

const useStyles = makeStyles({
  buttonSpace: {
    marginRight: '1.563rem',
  },
});

function QuestionSetEditModal(props) {
  const {
    heading,
    id,
    languages,
    messages,
    name,
    onClose,
    onError,
    onSuccess,
    open,
    patchUrl,
  } = props;
  const classes = useStyles();

  return (
    <Dialog
      aria-labelledby="edit-race-question-title"
      maxWidth="md"
      open={open}
      onClose={onClose}
    >

      <DialogTitle id="edit-race-question-title" disableTypography>
        <Typography component="h1" variant="h1">
          {heading}
        </Typography>

        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <RaceQuestionSetForm
        buttonText={messages.questionSet.update.button}
        id={id}
        languages={languages}
        name={name}
        onError={onError}
        onSuccess={onSuccess}
        requiredText={messages.questionSet.update.required}
        submitMethod="PATCH"
        submitUrl={patchUrl}
        validationErrorMessage={messages.questionSet.update.error}
      >
        <Button onClick={onClose} size="large" className={classes.buttonSpace}>
          {messages.common.button.cancel}
        </Button>
      </RaceQuestionSetForm>
    </Dialog>
  );
}

QuestionSetEditModal.propTypes = {
  heading: PropTypes.string,
  id: PropTypes.string,
  languages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
  })),
  messages: PropTypes.shape({
    common: PropTypes.shape({
      button: PropTypes.shape({
        cancel: PropTypes.string,
      }),
    }),
    questionSet: PropTypes.shape({
      update: PropTypes.shape({
        button: PropTypes.string,
        error: PropTypes.string,
        required: PropTypes.string,
      }),
    }),
  }),
  name: PropTypes.string,
  onClose: PropTypes.func,
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
  open: PropTypes.bool,
  patchUrl: PropTypes.string.isRequired,
};

QuestionSetEditModal.defaultProps = {
  heading: 'Updating question set',
  id: '',
  languages: [],
  messages: {
    common: {
      button: {
        cancel: 'Cancel',
      },
    },
    questionSet: {
      update: {
        button: 'Update Question Set',
        error: 'There was an issue updating your question set. Correct any errors and try again.',
        required: '*Required fields',
      },
    },
  },
  name: '',
  onClose: () => {},
  onError: () => {},
  onSuccess: () => {},
  open: false,
};

export default QuestionSetEditModal;
