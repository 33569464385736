import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import Form from 'helpers/Form';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  heading: {
    marginBottom: '1rem',
  },
  addFormContainer: {
    flexGrow: 1,
    display: 'flex',
    margin: '1rem',
  },
  addFormActions: {
    backgroundColor: '#eef4f8',
    display: 'block',
    padding: '.8rem 2rem',
  },
  fields: {
    marginBottom: '1.875em',
  },
  toggle: {
    justifyContent: 'center',
  },
  actions: {
    textAlign: 'right',
    marginTop: '.75em',
    marginBottom: '.75em',
  },
  required: {
    marginTop: '.75em',
    marginBottom: '.75em',
  },
});

const CategoryAddForm = ({
  messages, onAdd, submitting, errors,
}) => {
  const classes = useStyles();

  const [form, setForm] = useState(new Form({
    type: '',
    name: '',
    description: '',
    editable: true,
    visible: true,
  }, {
    validationMessage: 'validationErrorMessage',
  }));

  const handleSubmit = () => {
    // reset form on callback
    onAdd(form.name, form.description, () => {
      setForm(new Form({
        type: '',
        name: '',
        description: '',
        editable: true,
        visible: true,
      }, {
        validationMessage: 'validationErrorMessage',
      }));
    });
  };

  const getFormValue = (event) => {
    if (event.target.name == 'editable' || event.target.name == 'visible') {
      return event.target.checked;
    }
    return event.target.value;
  };

  const handleInputChange = (event) => {
    const { name } = event.target;
    const value = getFormValue(event);

    setForm(new Form({
      type: form.type,
      name: name == 'name' ? value : form.name,
      description: name == 'description' ? value : form.description,
      editable: form.editable,
      visible: form.visible,
    }, {
      validationErrorMessage: 'validationErrorMessage',
    }));
  };

  const hasError = (key) => (key in errors);

  const getError = (key) => {
    if (key in errors && errors[key].length > 0) {
      return errors[key][0];
    }
    return null;
  };

  return (
    <div>
      <Box marginBottom={2}>
        <Typography component="h2" variant="h3">
          {messages.heading}
        </Typography>
      </Box>

      <Card variant="outlined">
        <CardContent className={classes.addFormContainer}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth className={classes.fields}>
                <TextField
                  id="category-add-title"
                  disabled={submitting}
                  error={hasError('name')}
                  helperText={getError('name')}
                  label={messages.title}
                  name="name"
                  onChange={handleInputChange}
                  required
                  value={form.name}
                  variant="filled"
                />
              </FormControl>
              <FormControl fullWidth className={classes.fields}>
                <TextField
                  id="category-add-description"
                  disabled={submitting}
                  error={hasError('description')}
                  helperText={getError('description')}
                  label={messages.description}
                  name="description"
                  onChange={handleInputChange}
                  type="text"
                  value={form.description}
                  variant="filled"
                />
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>

        <CardActions className={classes.addFormActions}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={12} sm={8} className={classes.required}>
              <span>{messages.required}</span>
            </Grid>

            <Grid item xs={12} sm={4} className={classes.actions}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  handleSubmit();
                }}
                size="large"
                disabled={submitting}
              >
                {messages.button}
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </div>
  );
};

CategoryAddForm.propTypes = {
  onAdd: PropTypes.func,
  submitting: PropTypes.bool.isRequired,
  messages: PropTypes.shape({
    heading: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    required: PropTypes.string,
    button: PropTypes.string,
  }),
  errors: PropTypes.object,
};

CategoryAddForm.defaultProps = {
  messages: {
    heading: 'Add additional categories',
    title: 'Race Category Title',
    description: 'Race Category Description',
    required: '*Required fields needed to add a new race category',
    button: 'Add',
  },
  onAdd: () => {},
  errors: {},
};

export default CategoryAddForm;
