import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import SnackbarAlert from 'components/SnackbarAlert';
import PageHeader from 'components/PageHeader';
import BioQuestionsList from 'components/BioQuestions/BioQuestionsList';
import BioQuestionSetListContainer from 'components/BioQuestions/BioQuestionSetListContainer';
import fetchUtil from 'helpers/Fetch';
import theme from 'scripts/theme';
import { useReorderParent } from 'hooks/useReorder';
import BioQuestionForm from './BioQuestionForm';

const TYPE_OPTIONS = [
  { value: 'Text', name: 'Text', charLimit: 750 },
  { value: 'Email', name: 'Email', charLimit: 100 },
  { value: 'URL', name: 'URL', charLimit: 200 },
  { value: 'Twitter', name: 'Twitter', charLimit: 200 },
  { value: 'Age', name: 'Age', charLimit: 10 },
  { value: 'Location', name: 'Location', charLimit: 1000 },
  { value: 'Mailing Address', name: 'Mailing Address', charLimit: 1000 },
  { value: 'US Phone Number', name: 'US Phone Number', charLimit: 14 },
  { value: 'YouTube Video', name: 'YouTube Video', charLimit: 200 },
];

const BioQuestionsContainer = ({
  breadcrumbText,
  breadcrumbUrl,
  heading,
  subheading,
  messages,
  questions,
  rootQuestionUrl,
  rootSetUrl,
  sets,
  guideId,
}) => {
  const [addQuestionErrors, setAddQuestionErrors] = useState({});
  const [addQuestionSubmitting, setAddQuestionSubmitting] = useState(false);

  const [addCharLimit, setAddCharLimit] = useState(750);
  const [addEdit, setAddEdit] = useState(true);
  const [addName, setAddName] = useState('');
  const [addType, setAddType] = useState('Text');
  const [addIsVisible, setAddIsVisible] = useState(true);

  const [editObjectHolder, setEditObjectHolder] = useState({});
  const [editObjectError, setEditObjectError] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);

  const [questionsState, setQuestionsState] = useState([...questions]);

  const _snackbar = useRef(null);

  const {
    onReorder,
    moveItem: moveQuestion,
    disabled,
    setOriginalQuestionsState,
    onFailedDrop,
  } = useReorderParent({
    dataList: questionsState,
    setDataList: setQuestionsState,
    originalStateName: 'originalQuestionsState',
    apiEndpoint: `/api/v1/guide/${guideId}/bioquestions/reorder`,
    apiParamName: 'questions',
    showError: () => _snackbar.current.show('error', 'There was an issue trying to reorder your question. Try again or contact your guide admin if this continues'),
  });

  const handleAddQuestionInputChange = (name, value) => {
    if (name === 'type') {
      setAddType(value);
      setAddCharLimit(TYPE_OPTIONS.find(option => option.value === value).charLimit);
    }
    if (name === 'name') {
      setAddName(value);
    }
    if (name === 'charLimit') {
      setAddCharLimit(value);
    }
    if (name === 'edit') {
      setAddEdit(value);
    }
    if (name === 'isVisible') {
      setAddIsVisible(value);
    }
  };

  const resetAddFormState = () => {
    setAddCharLimit(750);
    setAddEdit(true);
    setAddName('');
    setAddType('Text');
    setAddIsVisible(true);
  };

  const handleAddQuestion = async () => {
    setAddQuestionSubmitting(true);

    const BODY = {
      charLimit: addCharLimit,
      edit: addEdit,
      name: addName,
      type: addType,
      isVisible: addIsVisible,
    };

    try {
      const res = await fetchUtil(rootQuestionUrl, 'POST', BODY);
      const newQuestions = questionsState.slice(0);
      newQuestions.push(res);

      resetAddFormState();

      setAddQuestionSubmitting(false);
      setQuestionsState(newQuestions);
      setOriginalQuestionsState(newQuestions);
      setAddQuestionErrors({});

      showSuccess(messages.bioQuestions.add.success);
    } catch (error) {
      setAddQuestionSubmitting(false);
      setAddQuestionErrors(error);

      showError(messages.bioQuestions.add.error);
    }
  };

  const showError = message => {
    _snackbar.current.show('error', message);
  };

  const showSuccess = message => {
    _snackbar.current.show('success', message);
  };

  // Initialize values for form state when form modal is opened
  const handleSetEditObjectHolder = questionObjToEdit => {
    setEditObjectHolder({ ...questionObjToEdit, charLimit: questionObjToEdit.maxl });
    setShowEditModal(true);
  };

  // Clear out all values on edit object,fired when form modal is closed
  const handleClearEditObjectHolder = () => {
    setEditObjectHolder({});
    setShowEditModal(false);
  };

  const handleDeleteQuestion = async (id) => {
    const deleteUrl = `${rootQuestionUrl}/${id}`;

    try {
      const response = await fetchUtil(deleteUrl, 'DELETE', id);
      response.json().then(() => {
        const newQuestions = questionsState.filter(question => question.id !== id);
        setQuestionsState(newQuestions);
        setOriginalQuestionsState(newQuestions);
        showSuccess(messages.bioQuestions.listContainer.updateSuccess);
      });
    } catch (error) {
      console.error(error);
      if (error.status == 403) {
        showError(messages.forbidden);
      } else {
        showError(messages.bioQuestions.listContainer.deleteErrorMsg401);
      }
    }
  };

  // Update fields on Edit object holder from form component
  const handleQuestionInputChange = (name, value) => {
    const newFormValues = { ...editObjectHolder };

    if (Object.prototype.hasOwnProperty.call(newFormValues, name)) {
      newFormValues[name] = value;
      if (name === 'type') {
        newFormValues.charLimit = TYPE_OPTIONS.find(option => option.value === value).charLimit;
      }
      setEditObjectHolder(newFormValues);
    }
  };

  const handleUpdateQuestion = async () => {
    const updateEndpoint = `${rootQuestionUrl}/${editObjectHolder.id}`;

    try {
      await fetchUtil(updateEndpoint, 'PATCH', editObjectHolder);
      const newQuestions = questionsState.slice();

      // Replace updated question in state
      const targetIndex = newQuestions.findIndex(question => question.id === editObjectHolder.id);

      if (targetIndex !== -1) {
        newQuestions[targetIndex] = editObjectHolder;
        newQuestions[targetIndex].maxl = parseInt(editObjectHolder.charLimit, 10);
      }

      // update questions state
      setQuestionsState(newQuestions);
      setOriginalQuestionsState(newQuestions);
      setEditObjectError({});

      // Clear holding object and close modal
      handleClearEditObjectHolder();

      // Show snackbar message
      showSuccess(messages.bioQuestions.listContainer.updateSuccess);
    } catch (error) {
      setEditObjectError(error);
      console.error(error);
      // Show Snackbar Error message
      showError(messages.bioQuestions.edit.error);
    }
  };

  const renderAddQuestionForm = () => (
    <Grid container>
      <Grid item xs={12} sm={8}>
        <div className="ga-m-bottom--x-large">
          <Typography variant="h2" component="h2" paragraph>
            {messages.bioQuestions.add.heading}
          </Typography>

          <BioQuestionForm
            buttonText={messages.bioQuestions.add.buttonText}
            charLimit={addCharLimit}
            edit={addEdit}
            errors={addQuestionErrors}
            name={addName}
            onChange={handleAddQuestionInputChange}
            onSubmit={handleAddQuestion}
            requiredText={messages.bioQuestions.add.required}
            submitting={addQuestionSubmitting}
            type={addType}
            isVisible={addIsVisible}
            typeOptions={TYPE_OPTIONS}
          />
        </div>
      </Grid>
    </Grid>
  );

  const renderBioQuestionsList = () => (
    <div className="ga-m-bottom--x-large">
      <Typography variant="h3" component="h2">
        Biographical Questions
      </Typography>

      <BioQuestionsList
        editObjectHolder={editObjectHolder}
        editObjectError={editObjectError}
        messages={messages}
        onClearEditObjectHolder={handleClearEditObjectHolder}
        onDelete={handleDeleteQuestion}
        onEditChange={handleQuestionInputChange}
        onQuestionUpdate={handleUpdateQuestion}
        onSetEditObjectHolder={handleSetEditObjectHolder}
        rows={questionsState}
        showEditModal={showEditModal}
        typeOptions={TYPE_OPTIONS}
        reordering={{
          onReorder,
          moveQuestion,
          disabled,
        }}
        onFailedDrop={onFailedDrop}
      />
    </div>
  );

  const renderBioQuestionSetList = () => (
    <Grid container>
      <Grid item xs={12} sm={8}>
        <BioQuestionSetListContainer
          guideId={guideId}
          messages={messages}
          postUrl={rootSetUrl}
          questions={questionsState}
          sets={sets}
        />
      </Grid>
    </Grid>
  );

  return (
    <ThemeProvider theme={theme}>
      <SnackbarAlert ref={_snackbar} />

      <PageHeader
        breadcrumbText={breadcrumbText}
        breadcrumbUrl={breadcrumbUrl}
        heading={heading}
        subheading={subheading}
      />

      <div className="ga-container">
        <div className="mdc-layout-grid">
          <div className="mdc-layout-grid__inner">
            <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
              {renderAddQuestionForm()}
              {renderBioQuestionsList()}
              {renderBioQuestionSetList()}
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

BioQuestionsContainer.propTypes = {
  breadcrumbText: PropTypes.string,
  breadcrumbUrl: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  messages: PropTypes.shape({
    forbidden: PropTypes.string,
    bioQuestions: PropTypes.shape({
      add: PropTypes.shape({
        buttonText: PropTypes.string,
        error: PropTypes.string,
        heading: PropTypes.string,
        required: PropTypes.string,
        success: PropTypes.string,
      }),
      delete: PropTypes.shape({
        success: PropTypes.string,
        error: PropTypes.string,
      }),
      edit: PropTypes.shape({
        error: PropTypes.string,
      }),
      listContainer: PropTypes.shape({
        updateSuccess: PropTypes.string,
        deleteErrorMsg401: PropTypes.string,
      }),
    }),
    bioSets: PropTypes.shape({
      title: PropTypes.string,
      titleSupporting: PropTypes.string,
      edit: PropTypes.shape({
        success: PropTypes.string,
        error: PropTypes.string,
      }),
      removeQuestion: PropTypes.shape({
        button: PropTypes.string,
      }),
    }),
  }),
  questions: PropTypes.arrayOf(PropTypes.shape({
    default: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    maxl: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    type: PropTypes.string,
  })),
  rootQuestionUrl: PropTypes.string,
  rootSetUrl: PropTypes.string.isRequired,
  sets: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    questions: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      title: PropTypes.string,
      index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      isDefault: PropTypes.bool,
      handleRemoveFromSet: PropTypes.func,
    })),
  })),
  guideId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

BioQuestionsContainer.defaultProps = {
  breadcrumbText: 'Races & Measures',
  breadcrumbUrl: '/races',
  heading: 'Biographical Questions & Sets',
  subheading: 'Explains what a biographical question is and how to use it lorem ipsum describe what they are for.',
  messages: {
    forbidden: 'Only administrators can complete this action. Please reach out to your guide administrator.',
    bioQuestions: {
      add: {
        buttonText: 'Save',
        error: 'There was an issue adding your biographical question. Correct any errors and try again.',
        heading: 'Create a new biographical question',
        required: '*Required fields needed to add a new biographical question',
        success: 'Your biographical question was added successfully!',
      },
      delete: {
        error: 'Unable to delete the question from the set',
        success: 'Successfully removed the question from the set',
      },
    },
    bioSets: {
      title: 'Biographical Sets',
      titleSupporting: 'When creating or editing races, you can assign them biographical sets to assign bio questions to that specific race. This is not required if you plan to ask all candidates the same biographical questions, but if you have subsets of questions to ask the candidates, you can use biographical sets to assign those questions',
      edit: {
        success: 'Successfully saved the edits made to your biographical question set.',
        error: 'Unable to successfully save edits. Please fix any errors.',
      },
      removeQuestion: {
        button: 'Remove from set',
      },
    },
  },
  questions: [],
  rootQuestionUrl: '',
  rootSetUrl: '',
  sets: [],
  guideId: null,
};

export default BioQuestionsContainer;
