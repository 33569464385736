import React from 'react';
import PropTypes from 'prop-types';
import { StepTitle } from 'components/RaceSteps';
import { Box } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  checkIcon: {
    color: '#3fa06f',
    fontSize: '10rem',
  },
});

const Container = ({ messages, raceClassId }) => {
  const classes = useStyles();

  return (
    <div>
      <StepTitle title={messages.title}>
        {raceClassId === 'R' ? messages.raceSubtitle : messages.measureSubtitle}
      </StepTitle>
      <Box marginBottom={5} paddingBottom={5}>
        <CheckCircleOutlineIcon
          className={classes.checkIcon}
        />
      </Box>
    </div>
  );
};

Container.propTypes = {
  messages: PropTypes.object.isRequired,
  raceClassId: PropTypes.string,
};

Container.defaultProps = {

};

export default Container;
