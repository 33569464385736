import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createStyles, withStyles } from '@material-ui/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import WYSIWYG from 'components/WYSIWYG';
import DistrictSelectorContainer from 'components/Districts/Partials/DistrictSelectorContainer';

const styles = () => createStyles({
  actionButton: {
    marginLeft: '1rem',
  },
  modalActions: {
    justifyContent: 'end',
  },
  modalTitle: {
    alignItems: 'flex-start',
  },
  sectionContainer: {
    margin: '1.5rem 0rem',
  },
});

const BulkEditModal = ({
  bioSets,
  categories,
  classes,
  errors,
  guideId,
  messages,
  modalIsOpen,
  onClose,
  onSave,
  questionSets,
  selectedRaces,
  submitting,
}) => {
  const [raceType, setRaceType] = useState('');
  const [votingOption, setVotingOption] = useState('');
  const [numberOfCandidates, setNumberOfCandidates] = useState(2);
  const [raceCategory, setRaceCategory] = useState('');
  const [district, setDistrict] = useState(null);
  const [bioSet, setBioSet] = useState('');
  const [questionSet, setQuestionSet] = useState('');
  const [description, setDescription] = useState('');
  const [canSubmit, setCanSubmit] = useState(true);

  useEffect(() => {
    if ((votingOption == 2 || votingOption == 3) && numberOfCandidates < 2) {
      setCanSubmit(false);
      return;
    }

    setCanSubmit(true);
  }, [errors, numberOfCandidates]);

  const isMeasure = selectedRaces.filter(race => race.clazz.id === 'F').length > 0;

  const hasError = (fieldName) => Object.prototype.hasOwnProperty.call(errors, fieldName);

  const handleUpdateDescription = (html) => {
    setDescription(html);
  };

  const handleUpdateVotingOption = (event) => {
    const val = event.target.value;
    setVotingOption(val);

    if (val < 2) {
      setNumberOfCandidates(1);
    } else if (numberOfCandidates === 1) {
      setNumberOfCandidates(2);
    }
  };

  // We need this because Single choice and multiple choice are type 1 in backend, and ranked choice is type 2.
  // Hwoever, we need them separate on the FE because multiple choice needs to show the number of candidates field
  const getMappedOption = votingOptionValue => {
    if (votingOptionValue == '') {
      return '';
    }
    if (votingOptionValue == 1 || votingOptionValue == 2) {
      return 1;
    }
    return 2;
  };

  const handleSave = () => {
    const payload = {
      bioQuestionSet: bioSet,
      category: raceCategory,
      choose: numberOfCandidates,
      description,
      district,
      questionSet,
      state: raceType,
      votingSystem: getMappedOption(votingOption),
    };

    onSave(selectedRaces, payload);
  };

  return (
    <Dialog
      aria-labelledby="bulk-edit-modal"
      maxWidth="md"
      onClose={onClose}
      open={modalIsOpen}
    >
      <DialogTitle
        className={classes.modalTitle}
        disableTypography
        id="bulk-edit-modal-title"
        onClose={onClose}
      >
        <span>
          <Typography component="h1" variant="h1">
            {messages.title}
          </Typography>

          <Typography component="p" variant="body1">
            {messages.subtitle}
          </Typography>
        </span>

        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Typography component="p" variant="h3" paragraph>
          You&apos;ve selected <b>{selectedRaces.length} races</b> for bulk editing.
        </Typography>

        <div className={classes.sectionContainer}>
          <Typography component="h2" variant="h3">
            {messages.raceOverview.title}
          </Typography>

          <Typography component="p" variant="body2" paragraph>
            {messages.raceOverview.subtitle}
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <FormControl
                disabled={isMeasure || submitting}
                error={hasError('raceType')}
                fullWidth
                variant="filled"
              >
                <InputLabel id="race-type-label">
                  {messages.raceOverview.raceType.label}
                </InputLabel>

                <Select
                  id="race-type"
                  labelId="race-type-form-field-label"
                  onChange={(event) => setRaceType(event.target.value)}
                  value={raceType}
                >
                  <MenuItem key={'none'} value={null}>
                    {messages.raceOverview.raceType.default}
                  </MenuItem>

                  <MenuItem key={2} value={2}>
                    Open/General Election
                  </MenuItem>

                  <MenuItem key={1} value={1}>
                    Closed Primary
                  </MenuItem>
                </Select>
                {hasError('raceType') ? <FormHelperText>{errors.raceType}</FormHelperText> : isMeasure && <FormHelperText>{messages.raceOverview.hasMeasure.helpText}</FormHelperText>}
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <FormControl
                disabled={isMeasure || submitting}
                error={hasError('votingOption')}
                fullWidth
                variant="filled"
              >
                <InputLabel id="voting-options-label">
                  {messages.raceOverview.votingOptions.label}
                </InputLabel>

                <Select
                  id="voting-options"
                  labelId="voting-options-form-field-label"
                  onChange={handleUpdateVotingOption}
                  value={votingOption}
                >
                  <MenuItem key={'none'} value={null}>
                    {messages.raceOverview.votingOptions.default}
                  </MenuItem>

                  <MenuItem key={1} value={1}>
                    Single Vote
                  </MenuItem>

                  <MenuItem key={2} value={2}>
                    Multiple Selection Vote
                  </MenuItem>

                  <MenuItem key={3} value={3}>
                    Ranked Choice
                  </MenuItem>
                </Select>
                {hasError('votingOption') ? <FormHelperText>{errors.votingOption}</FormHelperText> : isMeasure && <FormHelperText>{messages.raceOverview.hasMeasure.helpText}</FormHelperText>}
              </FormControl>
            </Grid>

            {votingOption > 1
              && <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <TextField
                    id="how-many-candidates"
                    disabled={submitting}
                    error={numberOfCandidates < 2 || hasError('numOfCandidates')}
                    helperText={hasError('numOfCandidates') ? errors.numOfCandidates : messages.raceOverview.numCandidates.helpText}
                    InputProps={{ inputProps: { min: 1 } }}
                    label={votingOption === 2 ? messages.raceOverview.numCandidates.multiLabel : messages.raceOverview.numCandidates.rankedLabel}
                    name="number_of_candidates"
                    onChange={(event) => setNumberOfCandidates(event.target.value)}
                    required
                    type='number'
                    value={numberOfCandidates}
                    variant="filled"
                  />
                </FormControl>
              </Grid>
            }
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <FormControl
                disabled={submitting}
                error={hasError('raceCategory')}
                fullWidth
                variant="filled"
              >
                <InputLabel id="race-category-label">
                  {messages.raceOverview.raceCategory.label}
                </InputLabel>

                <Select
                  id="race-category"
                  labelId="race-category-form-field-label"
                  onChange={(event) => setRaceCategory(event.target.value)}
                  value={raceCategory}
                >
                  <MenuItem key={'none'} value={null}>
                    {messages.raceOverview.raceCategory.default}
                  </MenuItem>

                  {categories.map(category => (
                    <MenuItem key={category.title} value={category}>
                      {category.title}
                    </MenuItem>
                  ))}
                </Select>
                {hasError('votingOption') && <FormHelperText>{errors.raceCategory}</FormHelperText>}
              </FormControl>
            </Grid>
          </Grid>

          <WYSIWYG
            disabled={submitting}
            error={hasError('description')}
            helperText={hasError('description') ? errors.description : messages.raceOverview.description.helpText}
            label={messages.raceOverview.description.placeholderText}
            onChange={handleUpdateDescription}
            value={description}
          />
        </div>

        <div className={classes.sectionContainer}>
          <Typography component="h2" variant="h3">
            {messages.districting.title}
          </Typography>

          <Typography component="p" variant="body2" paragraph>
            {messages.districting.subtitle}
          </Typography>

          <DistrictSelectorContainer
            district={district}
            // TODO: figure out errors for form fields
            error={errors.district ? errors.district : ''}
            guideId={guideId}
            onChange={(dist) => setDistrict(dist)}
          />
        </div>

        <div className={classes.sectionContainer}>
          <Typography component="h2" variant="h3">
            {messages.bioSets.title}
          </Typography>

          <Typography component="p" variant="body2" paragraph>
            {messages.bioSets.subtitle}
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <FormControl
                disabled={submitting}
                error={hasError('bioSet')}
                fullWidth
                variant="filled"
              >
                <InputLabel id="bio-sets-label">{messages.bioSets.label}</InputLabel>
                <Select
                  id="bio-sets"
                  labelId="bio-sets-form-field-label"
                  onChange={(event) => setBioSet(event.target.value)}
                  value={bioSet}
                >
                  <MenuItem value={-1}>
                    {messages.bioSets.useAll}
                  </MenuItem>

                  {bioSets.map(set => (
                    <MenuItem key={set.name} value={set}>
                      {set.name}
                    </MenuItem>
                  ))}
                </Select>
                {hasError('bioSet') && <FormHelperText>{errors.bioSet}</FormHelperText>}
              </FormControl>
            </Grid>
          </Grid>
        </div>

        <div className={classes.sectionContainer}>
          <Typography component="h2" variant="h3">
            {messages.questionSets.title}
          </Typography>

          <Typography component="p" variant="body2" paragraph>
            {messages.questionSets.subtitle}
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <FormControl
                disabled={submitting}
                error={hasError('questionSet')}
                fullWidth
                variant="filled"
              >
                <InputLabel id="question-sets-label">
                  {messages.questionSets.label}
                </InputLabel>

                <Select
                  id="question-sets"
                  labelId="question-sets-form-field-label"
                  onChange={(event) => setQuestionSet(event.target.value)}
                  value={questionSet}
                >
                  <MenuItem key={'none'} value={null}>
                    {messages.questionSets.default}
                  </MenuItem>

                  {questionSets.map(set => (
                    <MenuItem key={set.name} value={set}>
                      {set.name}
                    </MenuItem>
                  ))}
                </Select>
                {hasError('questionSet') && <FormHelperText>{errors.questionSet}</FormHelperText>}
              </FormControl>
            </Grid>
          </Grid>
        </div>
      </DialogContent>

      <DialogActions className={classes.modalActions}>
        <Button onClick={onClose}>
          {messages.buttonText.close}
        </Button>

        <Button
          className={classes.actionButton}
          color="secondary"
          disabled={submitting || !canSubmit}
          onClick={handleSave}
          variant="contained"
        >
          {messages.buttonText.save}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

BulkEditModal.propTypes = {
  bioSets: PropTypes.array,
  categories: PropTypes.array,
  classes: PropTypes.object,
  errors: PropTypes.object,
  guideId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  messages: PropTypes.object,
  modalIsOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  questionSets: PropTypes.array,
  selectedRaces: PropTypes.array,
  submitting: PropTypes.bool,
};

BulkEditModal.defaultProps = {
  bioSets: [],
  categories: [],
  errors: {},
  messages: {
    title: 'Bulk Edit Races',
    subtitle: 'Adjust fields for multiple races at once by using our bulk edit functionality.',
    raceOverview: {
      title: 'Race Overview',
      subtitle: 'This will overwrite any of the fields individually filled out for all of your selected races.',
      hasMeasure: {
        helpText: 'In order to bulk edit this field, you cannot have any measures selected.',
      },
      raceType: {
        label: 'Type of Race',
        default: '-- Select a Race Type --',
      },
      votingOptions: {
        label: 'Voting Options',
        default: '-- Select a Voting Option --',
      },
      numCandidates: {
        multiLabel: 'Number of Candidates to Vote For',
        rankedLabel: 'Number of Candidates to Rank',
        helpText: 'Must be more than 1',
      },
      raceCategory: {
        label: 'Race Category',
        default: '-- Select a Race Category --',
      },
      description: {
        placeholderText: 'Enter a race description',
        helpText: 'This description will appear when voters compare the candidates in a race. You can include information like important election dates.',
      },
    },
    districting: {
      title: 'Districting',
      subtitle: 'This will overwrite any of the districting individually set for all of your selected races.',
    },
    bioSets: {
      title: 'Biographical Question Set',
      subtitle: 'This will overwrite any of the biographical questions individually set for all of your selected races. The set you want must already exist.',
      label: 'Biographical Set',
      default: '-- Select a Biographical Set --',
      useAll: 'Use all biographical questions',
    },
    questionSets: {
      title: 'Race Question Set',
      subtitle: 'This will overwrite any of the race questions individually set for all of your selected races. The set you want must already exist.',
      label: 'Race Question Set',
      default: '-- Select a Race Question Set --',
    },
    buttonText: {
      close: 'Close',
      save: 'Save & Update',
    },
  },
  onClose: () => {},
  onSave: () => {},
  questionSets: [],
  selectedRaces: [],
  submitting: false,
};

export default withStyles(styles)(BulkEditModal);
