import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import {
  FormControl,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  Button,
  FormHelperText,
} from '@material-ui/core';

const styles = {
  buttonContainer: {
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'flex-end',
  },
  deleteButton: {
    color: '#ac1b3d',
    '&:hover': {
      color: '#6B1126',
    },
  },
  label: {
    color: '#86898b',
    fontSize: '0.625rem',
    textTransform: 'uppercase',
    paddingBottom: '1rem',
  },
};

class UserRow extends React.Component {
  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleInputChange(event) {
    const { index, onChange } = this.props;
    const { name, value } = event.target;
    onChange(index, name, value);
  }

  hasError(fieldName) {
    const { errors } = this.props;
    return Object.prototype.hasOwnProperty.call(errors, fieldName);
  }

  handleDelete() {
    const { email, id, onDelete } = this.props;
    onDelete(id, email);
  }

  renderSetOptions() {
    const { roleList } = this.props;

    if (roleList && roleList.length > 0) {
      return (roleList.map(role => <MenuItem key={role.name} value={role.value}>{role.name}</MenuItem>));
    }
    return (<MenuItem value=''>No Roles Available</MenuItem>);
  }

  render() {
    const {
      classes,
      deleteText,
      email,
      errors,
      id,
      loginLabel,
      login,
      role,
      submitting,
    } = this.props;
    return (
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <TextField
              id={`user-email-${id}`}
              disabled={submitting}
              error={this.hasError('email')}
              helperText={this.hasError('email') ? errors.email : ''}
              label='Email Address'
              name='email'
              required
              variant='filled'
              onChange={this.handleInputChange}
              value={email}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={3}>
          <FormControl
            error={this.hasError('role')}
            fullWidth
            required
            variant='filled'
          >
            <InputLabel id='user-role-label'>
              User Role
            </InputLabel>

            <Select
              id={`user-role-${id}`}
              labelId='user-role-label'
              name='role'
              error={this.hasError('role')}
              value={role}
              onChange={this.handleInputChange}
              disabled={submitting}
            >
              {this.renderSetOptions()}
            </Select>
            <FormHelperText>{this.hasError('role') ? errors.role : ''}</FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={2}>
          <p className={classes.label}>{loginLabel}</p>
          <p>{login}</p>
        </Grid>

        <Grid item xs={12} sm={2} className={classes.buttonContainer}>
          <Button
            variant='text'
            color='secondary'
            onClick={this.handleDelete}
            size='large'
            className={classes.deleteButton}
            disabled={submitting}
          >
            {deleteText}
          </Button>
        </Grid>
      </Grid>
    );
  }
}

UserRow.propTypes = {
  classes: PropTypes.object,
  deleteText: PropTypes.string,
  email: PropTypes.string,
  errors: PropTypes.object,
  id: PropTypes.number,
  index: PropTypes.number,
  loginLabel: PropTypes.string,
  login: PropTypes.string,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  role: PropTypes.string,
  roleList: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  })),
  submitting: PropTypes.bool,
};

UserRow.defaultProps = {
  deleteText: 'Delete',
  email: '',
  errors: {},
  id: 0,
  index: 0,
  loginLabel: 'Last Login',
  login: 'MM/DD/YY 00:00 PM',
  onChange: () => {},
  onDelete: () => {},
  role: '',
  roleList: [
    {
      name: 'Guide Admin',
      value: 'admin',
    },
    {
      name: 'Guide Contributor',
      value: 'contributor',
    },
  ],
  submitting: false,
};

export default withStyles(styles)(UserRow);
