import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/styles';
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { DataGridPro, LicenseInfo } from '@mui/x-data-grid-pro';
import EmptyState from 'components/EmptyState';

// Dynamic row height style for DataGrid component
const StyledDataGrid = withStyles({
  root: {
    '& .MuiDataGrid-renderingZone': {
      maxHeight: 'none !important',
    },
    '& .MuiDataGrid-cell': {
      lineHeight: 'unset !important',
      whiteSpace: 'normal',
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiDataGrid-row': {
      maxHeight: 'none !important',
    },
  },
})(DataGridPro);

const ROWS_PER_PAGE_OPTIONS = [50, 100, 500, 1000, 5000];

LicenseInfo.setLicenseKey(
  '5354fa8b30068069673634540842cbf3T1JERVI6MzU2NzcsRVhQSVJZPTE2NzM0NzU4MDAwMDAsS0VZVkVSU0lPTj0x',
);

const styles = makeStyles({
  headingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    minHeight: '47px', // Prevents the container from jumping around when bulk actions show/hide
  },
  actionsContainer: {
    backgroundColor: '#f5f5f5',
    display: 'none', // 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  actionBlockContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  actionButton: {
    margin: '8px 20px',
    minWidth: '50px',
    textTransform: 'none',
  },
  filter: {
    backgroundColor: '#fff',
    margin: '8px 18px',
    minWidth: 120,
  },
  search: {
    backgroundColor: '#fff',
    margin: '8px 18px',
    minWidth: 275,
  },
  tableContainer: {
    '& a': {
      color: '#0a3a5a',
      fontWeight: 500,
      textDecoration: 'none',
    },
  },
  table: {
    border: 'none',
  },
  emptyStateButton: {
    margin: '0 .8rem',
  },
});

const DashboardTable = ({
  addressFilter,
  bulkActions,
  candidates,
  columns,
  emptyStateText,
  filterModel,
  filterByText,
  loadingCandidates,
  onFilterModelChange,
  onSortModelChange,
  pageSize,
  searchPlaceholder,
  selected,
  setPageSize,
  setSelected,
  sortModel,
}) => {
  const classes = styles();

  const renderBulkActions = () => {
    if (bulkActions && bulkActions.length > 0 && selected.length > 0) {
      return (
        <div>
          {bulkActions.map((action, index) => (
            <Button
              className={classes.actionButton}
              color={action.color}
              key={index}
              size="small"
              onClick={action.onClick} // eslint-disable-line react/jsx-handler-names
            >
              {action.name}
            </Button>
          ))}
        </div>
      );
    }
  };

  const renderEmptyState = () => (
    <EmptyState>
      <p className="ga-m-bottom--large">
        {emptyStateText}
      </p>
    </EmptyState>
  );

  return (
    <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12 ga-m-vertical--large">
      <div className={classes.headingContainer}>
        {renderBulkActions()}
      </div>

      {loadingCandidates
        && <Grid container direction="row" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Grid>
      }

      {/* Data Table */}
      {!loadingCandidates && candidates && candidates.length > 0 && (
        <div>
          <div className={classes.actionsContainer}>
            <div>
              <div className={classes.actionBlockContainer}>
                <span>{filterByText}:</span>
                <TextField
                  className={classes.search}
                  id="search-candidates"
                  label={searchPlaceholder}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <FormControl variant="outlined" className={classes.filter}>
                  <InputLabel id="candidate-filter-address-label">{addressFilter.default}</InputLabel>
                  <Select
                    labelId="candidate-filter-address-label"
                    id="candidate-filter-address"
                    value=""
                    // onChange={handleTypeFilterChange}
                  >
                    <MenuItem value="">{addressFilter.default}</MenuItem>
                    {addressFilter.options.map((address, index) => (
                      <MenuItem key={index} value={address}>
                        {address}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <div className={classes.tableContainer}>
            <StyledDataGrid
              autoHeight={true}
              checkboxSelection
              className={classes.table}
              columns={columns}
              filterModel={filterModel}
              onFilterModelChange={(model) => onFilterModelChange(model)}
              onSortModelChange={(model) => onSortModelChange(model)}
              onSelectionModelChange={setSelected}
              pageSize={pageSize}
              pagination={true}
              onPageSizeChange={setPageSize}
              rows={candidates}
              rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              sortModel={sortModel}
            />
          </div>
        </div>
      )}

      {/* Empty State */}
      {!loadingCandidates && (!candidates || candidates.length <= 0) && renderEmptyState()}
    </div>
  );
};

DashboardTable.propTypes = {
  addressFilter: PropTypes.shape({
    options: PropTypes.array,
    default: PropTypes.string,
  }),
  bulkActions: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.oneOf(['primary', 'secondary']),
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  candidates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      race: PropTypes.object,
      mailing: PropTypes.string,
    }),
  ),
  columns: PropTypes.array,
  emptyStateText: PropTypes.string,
  filterByText: PropTypes.string,
  filterModel: PropTypes.shape({
    items: PropTypes.array,
    linkOperator: PropTypes.string,
  }),
  loadingCandidates: PropTypes.bool,
  onFilterModelChange: PropTypes.func,
  onSortModelChange: PropTypes.func,
  pageSize: PropTypes.number.isRequired,
  searchPlaceholder: PropTypes.string,
  selected: PropTypes.array.isRequired, // state variables
  setPageSize: PropTypes.func.isRequired,
  setSelected: PropTypes.func.isRequired,
  sortModel: PropTypes.array,
};

DashboardTable.defaultProps = {
  addressFilter: {
    options: ['Yes', 'No'],
    default: 'Address',
  },
  bulkActions: [],
  candidates: [],
  columns: [],
  emptyStateText: 'There are currently no candidates who have not been invited in this guide!',
  filterByText: 'Filter By',
  filterModel: { items: [], linkOperator: 'and' },
  loadingCandidates: false,
  onFilterModelChange: () => {},
  onSortModelChange: () => {},
  searchPlaceholder: 'Search Candidates',
  sortModel: [],
};

export default DashboardTable;
