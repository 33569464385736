import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { useStyles } from './Searchfilter.styles';

export const SearchFilter = ({
  heading,
  label,
  placeholder,
  buttonText,
  searchInput,
  onChange,
  onSubmit,
  disabled,
}) => {
  const classes = useStyles();

  const handleSearchInputChange = (event) => {
    onChange(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && searchInput.trim() !== '') {
      event.preventDefault();
      onSubmit();
    }
  };

  const handleSubmit = () => {
    if (searchInput.trim() !== '') {
      onSubmit();
    }
  };

  return (
    <>
      <Box marginBottom={5}>
        <Typography variant="h3" component="h2">{heading}</Typography>
      </Box>
      <Box marginBottom={10}>
        <Grid
          container
          alignItems="center"
          spacing={3}
        >
          <Grid
            item
            xs={4}
          >
            <FormControl fullWidth className={classes.root}>
              <TextField
                data-testid="search-input"
                disabled={disabled}
                error={searchInput.trim() === ''}
                helperText={searchInput.trim() === '' && 'You cannot perform an empty search'}
                onKeyDown={handleKeyDown}
                id="search-input"
                inputProps={{ maxLength: 200 }}
                label={label}
                name="search"
                onChange={handleSearchInputChange}
                placeholder={placeholder}
                value={searchInput}
                variant="filled"
              />
            </FormControl>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              data-testid="search-button"
              color="secondary"
              onClick={handleSubmit}
              disabled={disabled || searchInput.trim() === ''}
            >
              {buttonText}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

SearchFilter.propTypes = {
  searchInput: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
  heading: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  buttonText: PropTypes.string,
};

SearchFilter.defaultProps = {
  onChange: () => {},
  onSubmit: () => {},
  disabled: false,
  heading: '',
  label: '',
  placeholder: '',
  buttonText: 'Find Matches',
};
