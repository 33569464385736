import React, {
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Checkbox,
  FormControl,
  Grid,
  TextField,
} from '@material-ui/core';
import DistrictMetaDataCard from 'components/Districts/Partials/DistrictMetaDataCard';

const useStyles = makeStyles({
  root: {
    padding: '1rem .4rem',
  },
  animate: {
    backgroundColor: 'transparent',
    transition: 'background-color .5s ease-in-out',
  },
  animateIn: {
    backgroundColor: '#FEF6DD',
  },
  metadataCard: {
    marginBottom: '1.2rem',
  },
  showDetailsButton: {
    marginBottom: '0',
    minWidth: 0,
    textTransform: 'none',
  },
});

const GISDistrictNameField = React.forwardRef(({
  errors,
  expanded,
  id,
  index,
  metadata,
  name,
  onChange,
  onSelect,
  onToggleMetadata,
  selected,
  setSize,
  style,
  submitting,
  windowWidth,
}, ref) => {
  const classes = useStyles();
  const fieldRef = useRef();
  const metadataRef = useRef();

  const [animationClass, setAnimationClass] = useState(classes.animate);
  const [localName, setLocalName] = useState('');

  // We use imperative handle here to expose an animate method to the list
  // rather than trying to handle it through props changes which might
  // trigger a re-render of the entire list of districts, which can run
  // into the thousands.
  useImperativeHandle(ref, () => ({
    animate: () => {
      setAnimationClass(`${classes.animate} ${classes.animateIn}`);

      window.setTimeout(() => {
        setAnimationClass(classes.animate);
      }, 3000);
    },
  }));

  useEffect(() => {
    setLocalName(name);
  }, [name]);

  useEffect(() => {
    const fieldSize = fieldRef.current.getBoundingClientRect().height;
    const metadataSize = metadataRef.current.getBoundingClientRect().height;
    setSize(index, fieldSize + metadataSize);
  }, [setSize, expanded, windowWidth]);

  // const hasError = fieldName => Object.prototype.hasOwnProperty.call(errors, fieldName);
  const hasError = () => Object.prototype.hasOwnProperty.call(errors, `geoDistricts[${index}].name`);
  const getError = () => errors[`geoDistricts[${index}].name`][0];

  return (
    <div
      className={`${classes.root} ${animationClass}`}
      ref={ReferenceError}
      style={style}
    >
      <Grid container alignItems="center" ref={fieldRef}>
        <Grid item xs={1}>
          <Checkbox
            checked={selected}
            inputProps={{ 'aria-label': `Select ${localName}` }}
            onChange={() => onSelect(id)}
          />
        </Grid>

        <Grid item xs={11}>
          <FormControl fullWidth>
            <TextField
              disabled={submitting}
              error={hasError('name')}
              helperText={hasError('name') ? getError() : ''}
              id={id}
              InputLabelProps={{ disableAnimation: true }}
              label="District Name"
              name="name"
              onBlur={event => onChange(id, event.target.value)}
              onChange={event => setLocalName(event.target.value)}
              required
              value={localName}
              variant="filled"
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid container alignItems="center" ref={metadataRef}>
        <Grid item xs={1}></Grid>
        <Grid item xs={11}>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button
                className={classes.showDetailsButton}
                color="primary"
                onClick={() => onToggleMetadata(id, !expanded)}
                size="medium"
              >
                {expanded ? 'Close Details' : 'View Details'}
              </Button>
            </Grid>
          </Grid>

          {expanded && (
            <DistrictMetaDataCard className={classes.metadataCard} metadata={metadata} />
          )}
        </Grid>
      </Grid>
    </div>
  );
});
GISDistrictNameField.displayName = 'GISDistrictNameField';

GISDistrictNameField.propTypes = {
  errors: PropTypes.object,
  expanded: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  index: PropTypes.number,
  metadata: PropTypes.object,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  onToggleMetadata: PropTypes.func,
  selected: PropTypes.bool,
  setSize: PropTypes.func,
  style: PropTypes.object,
  submitting: PropTypes.bool,
  windowWidth: PropTypes.number,
};

GISDistrictNameField.defaultProps = {
  errors: {},
  expanded: false,
  id: '',
  index: null,
  metadata: {},
  name: '',
  onChange: () => {},
  onSelect: () => {},
  onToggleMetadata: () => {},
  selected: false,
  setSize: () => {},
  style: {},
  submitting: false,
};

export default GISDistrictNameField;
