import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { StepTitle } from 'components/RaceSteps';
import fetchUtil from 'helpers/Fetch';
import ExistingCategories from './ExistingCategories';
import AddCategory from './AddCategory';

const Container = ({
  guideId,
  messages,
  onDeleteCategory,
  onError,
  onNewCategory,
  raceClassId,
  stepState,
}) => {
  // make fetch call here
  const onLoad = (shouldSetSelectedCategoryAfterInsert = false, index) => {
    stepState.setLoadingCategories(true);

    fetchUtil(`/api/v1/guide/${guideId}/category`, 'GET', {})
      .then(categories => {
        // getting measures category to use
        const measureCategory = categories.find(category => category.title === 'Measure');

        // remove measure as a category if it is a race
        const formattedCategories = setFormattedCategories(categories, measureCategory);
        formattedCategories.push({
          id: -1,
          title: messages.uncategorized,
          description: messages.uncategorizedDescription,
          value: formattedCategories.length,
        });

        // set category to user selected category or default
        setCategory(formattedCategories, index, shouldSetSelectedCategoryAfterInsert);
      }).catch(error => {
        console.error(error);
        onError('load', false); // action = load, message = none
        stepState.setLoadingCategories(false);
      });
  };

  const setFormattedCategories = (categories, measureCategory) => {
    // ---- for right now, keeping measure and race categories as the same list because of the indexing issue ----
    // setting measure category always to the top of the list
    const measureFirstList = categories.filter(category => category.title !== 'Measure');

    // If we have a default measure category, append it to the beginning
    // of the category array
    if (measureCategory) {
      measureFirstList.unshift(measureCategory);
    }

    return measureFirstList.map(c => ({
      id: c.id,
      title: c.title,
      description: c.description,
      value: c.index,
    }));
  };

  const setCategory = (formattedCategories, index, shouldSetSelectedCategoryAfterInsert) => {
    if (shouldSetSelectedCategoryAfterInsert) {
      stepState.setSelectedCategory(index);
    } else if (raceClassId === 'R' && stepState.selectedCategory < 0) {
      stepState.setSelectedCategory(formattedCategories.length - 1);
    } else if (raceClassId === 'F' && stepState.selectedCategory < 0) {
      // can set it to 0 since measures is set to always be the first category in the list
      stepState.setSelectedCategory(0);
    }
    stepState.setCategories(formattedCategories);
    stepState.setLoadingCategories(false);
  };

  const handleNewCategorySubmit = () => {
    onNewCategory((index) => {
      // callback on success to trigger reload
      onLoad(true, index);
    });
  };

  const handleDeleteCustomCategory = (index) => {
    const catToDelete = stepState.categories[index];
    onDeleteCategory(catToDelete, () => {
      onLoad();
    });
  };

  // Component Did Mount
  useEffect(() => {
    onLoad();
  }, []);

  return (
    <div>
      <StepTitle title={messages.title}>
        {raceClassId === 'R' ? messages.raceSubtitle : messages.measureSubtitle}
      </StepTitle>
      <Box marginBottom={5} paddingBottom={5}>
        <ExistingCategories
          list={stepState.categories}
          checked={stepState.selectedCategory}
          onChange={(newValue) => stepState.setSelectedCategory(newValue)}
          deletable={stepState.customCategory}
          onDelete={handleDeleteCustomCategory}
        />
      </Box>

      {/* Remove form when custom category is not null */}
      {stepState.customCategory == null && (
        <Box marginBottom={5} paddingBottom={5}>
          <AddCategory
            customCategoryInputs={stepState.customCategoryInputs}
            setCustomCategoryInputs={stepState.setCustomCategoryInputs}
            submittingCustomCategory={stepState.submittingCustomCategory}
            customCategoryErrors={stepState.customCategoryErrors}
            messages={messages}
            onSubmit={handleNewCategorySubmit}
          />
        </Box>
      )}
    </div>
  );
};

Container.propTypes = {
  guideId: PropTypes.string.isRequired,
  messages: PropTypes.object.isRequired,
  onDeleteCategory: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onNewCategory: PropTypes.func.isRequired,
  raceClassId: PropTypes.string,
  stepState: PropTypes.shape({
    categories: PropTypes.array.isRequired,
    setCategories: PropTypes.func.isRequired,
    selectedCategory: PropTypes.number.isRequired,
    setSelectedCategory: PropTypes.func.isRequired,
    loadingCategories: PropTypes.bool.isRequired,
    setLoadingCategories: PropTypes.func.isRequired,
    customCategory: PropTypes.number, // we are expecting null on first render
    setCustomCategory: PropTypes.func.isRequired,
    customCategoryInputs: PropTypes.object.isRequired,
    setCustomCategoryInputs: PropTypes.func.isRequired,
    submittingCustomCategory: PropTypes.bool.isRequired,
    setSubmittingCustomCategory: PropTypes.func.isRequired,
    customCategoryErrors: PropTypes.object.isRequired,
    setCustomCategoryErrors: PropTypes.func.isRequired,
  }).isRequired,
};

Container.defaultProps = {

};

export default Container;
