import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

const useFeatureFlags = () => {
  const featureFlagQuery = useQuery(
    ['feature-flag'],
    async () => {
      const res = await axios.get('/api/v1/features');
      return res.data;
    },
  );

  return ({
    query: featureFlagQuery,
    /**
     * Determine whether an item is enabled or not based on feature flags. Item must include a string[] property called featureFlags.
     * @param {*} item Object to check
     * @returns {Boolean} whether the item is enabled or not
     */
    isEnabled: item => {
      if (item.featureFlags && item.featureFlags.length > 0) {
        return item.featureFlags.every(flag => featureFlagQuery.data?.[flag] === 'true');
      }
      return false;
    },
  });
};

const useFeatureFlag = flag => {
  const fetchFeatureFlag = async () => {
    const res = await axios.get(`/api/v1/features/${flag}`);
    return res.data;
  };

  return useQuery(['feature-flag', flag], fetchFeatureFlag);
};

export { useFeatureFlags, useFeatureFlag };
