import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles({
  gridContainer: {
    marginBottom: '1.25rem',
  },

});

function RaceOverviewCard(props) {
  const classes = useStyles();
  const {
    title, onEdit, raceInfo, votingInfo, multipleChoice, districtInfo, editScreen, editButtonText, multipleChoiceLabel,
  } = props;

  const raceInfoArray = raceInfo.map((field, index) => (
    <Grid item key={index}>
      {field.value !== null ? (
        <>
          <p className="card-label">{field.label}</p>
          <p className="mdc-typography--paragraph card-value">{field.value}</p>
        </>
      ) : (
        <>
          <p className="card-label">{field.label}</p>
          <p className="mdc-typography--paragraph card-reminder-text">No answer</p>
        </>
      )}
    </Grid>
  ));

  const districtInfoArray = districtInfo.map((field, index) => (
    <Grid item key={index}>
      {field.value !== null ? (
        <>
          <p className="card-label">{field.label}</p>
          <p className="mdc-typography--paragraph card-value">{field.value}</p>
        </>
      ) : (
        <>
          <p className="card-label">{field.label}</p>
          <p className="mdc-typography--paragraph card-reminder-text">No answer</p>
        </>
      )}
    </Grid>
  ));

  const handleEditClick = () => {
    onEdit(editScreen);
  };

  return (
    <Paper elevation={3} className="card-container">
      <div className="card-header-container">
        <Typography component="h3" variant="h3" color="primary">
          {title}
        </Typography>

        <Button
          variant="outlined"
          color="secondary"
          size="large"
          onClick={handleEditClick}
        >
          {editButtonText}
        </Button>

      </div>
      <Grid container spacing={3} className={classes.gridContainer}>
        {raceInfoArray}
      </Grid>

      <Grid container spacing={3} className={classes.gridContainer}>
        {votingInfo.hasMultipleChoice ? (
          <>
            <Grid item>
              <p className="card-label">{votingInfo.label}</p>
              <p className="mdc-typography--paragraph card-value">{votingInfo.value}</p>
            </Grid>
            <Grid item>
              <p className="card-label">{multipleChoiceLabel}</p>
              <p className="mdc-typography--paragraph card-value">{multipleChoice}</p>
            </Grid>
          </>
        ) : (
          <Grid item>
            <p className="card-label">{votingInfo.label}</p>
            <p className="mdc-typography--paragraph card-value">{votingInfo.value}</p>
          </Grid>
        )}
      </Grid>

      <Grid container spacing={3}>
        {districtInfoArray}
      </Grid>
    </Paper>
  );
}

RaceOverviewCard.propTypes = {
  title: PropTypes.string,
  raceInfo: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  votingInfo: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    hasMultipleChoice: PropTypes.bool,
  }).isRequired,
  multipleChoice: PropTypes.string,
  multipleChoiceLabel: PropTypes.string,
  districtInfo: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  onEdit: PropTypes.func,
  editScreen: PropTypes.number,
  editButtonText: PropTypes.string,
};

RaceOverviewCard.defaultProps = {
  title: 'Default title',
  raceInfo: [],
  votingInfo: {},
  multipleChoice: 1,
  districtInfo: [],
  onEdit: () => {},
  editScreen: 1,
  editButtonText: 'Edit',
  multipleChoiceLabel: 'Number of Candidates',
};

export default RaceOverviewCard;
