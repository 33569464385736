import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';

/**
 * Component designed to replace the mdc-layout-grid class.
 */
const MDCGrid = ({ children }) => (
  <Grid
    container
  >
    <Grid item xs={12}>
      <Box sx={{ padding: '24px' }}>
        {children}
      </Box>
    </Grid>
  </Grid>
);

MDCGrid.propTypes = {
  children: PropTypes.node,
};

MDCGrid.defaultProps = {
  children: null,
};

export default MDCGrid;
