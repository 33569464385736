import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#f7f7f7',
    padding: '2rem 0',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '24px',
  },
});

function PageSecondaryHeader(props) {
  const { children, heading, subheading } = props;
  const classes = useStyles();

  return (
    <aside className={classes.root}>
      <div className={`${classes.content} ga-container`}>
        <div>
          <Typography component="h2" variant="h3">
            {heading}
          </Typography>

          <Typography component="p" variant="body1" color="textSecondary">
            {subheading}
          </Typography>
        </div>

        <div>
          {children}
        </div>
      </div>
    </aside>
  );
}

PageSecondaryHeader.propTypes = {
  children: PropTypes.object,
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string,
};

export default PageSecondaryHeader;
