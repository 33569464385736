import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@material-ui/core';
import EmptyState from 'components/EmptyState';
import stateErrors from 'helpers/StateErrors';

const styles = makeStyles({
  container: {
    paddingTop: '1.25rem',
  },
  headingContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  selectedContainer: {
    paddingTop: '1.875rem',
  },
  itemRows: {
    paddingBottom: '0.938rem',
    marginBottom: '0.938rem',
    borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
    '&:last-child': {
      borderBottom: 'none',
      paddingBottom: '0rem',
      marginBottom: '0rem',
    },
  },
  label: {
    paddingBottom: '0.75rem',
  },
  listContainer: {
    paddingTop: '0.5rem',
  },
  emptyStateText: {
    textAlign: 'center',
  },
});

const BioSetSelector = ({
  buttonText,
  disableEmptyState,
  emptyOptionText,
  emptyStateText,
  emptyOptionValue,
  errors,
  header,
  isLoading,
  label,
  onChange,
  onClick,
  questionListLabel,
  selected,
  sets,
  showAction,
}) => {
  const classes = styles();

  const handleInputChange = event => onChange(event.target.value);

  const renderAction = () => {
    if (showAction) {
      return (
        <Button
          color="secondary"
          disabled={isLoading}
          onClick={onClick}
          size="medium"
          variant="outlined"
        >
          {buttonText}
        </Button>
      );
    }
  };

  const renderEmptyValue = () => {
    if (emptyOptionText && emptyOptionValue) {
      return (
        <MenuItem value='-1'>
          {emptyOptionText}
        </MenuItem>
      );
    }
  };

  const renderSets = () => {
    if ((sets && sets.length > 0) || disableEmptyState) {
      return (
        <div className={classes.container}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                required
                variant="filled"
              >
                <InputLabel id="bioquestion-selected-set">
                  {label}
                </InputLabel>

                <Select
                  id="bioquestion-selected-set"
                  error={stateErrors.hasError('selectedSet', errors)}
                  name="set"
                  onChange={handleInputChange}
                  value={selected == null || selected == '' ? '' : selected}
                >
                  {renderEmptyValue()}
                  {renderSetOptions()}
                </Select>

                <FormHelperText>
                  {stateErrors.getError('selectedSet', errors)}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>

          {renderQuestions()}
        </div>
      );
    }

    return (
      <div className={classes.container}>
        <EmptyState>
          <p>{emptyStateText}</p>
        </EmptyState>
      </div>
    );
  };

  const renderSetOptions = () => {
    if (sets && sets.length > 0) {
      return sets.map(set => <MenuItem key={set.id} value={set.id}>{set.name}</MenuItem>);
    }
  };

  const renderQuestions = () => {
    let selectedSet = null;

    if (sets && sets.length > 0) {
      selectedSet = sets.find(set => set.id === selected);
    }

    if (typeof selectedSet !== 'undefined' && selectedSet) {
      if (selectedSet.bioQuestions && selectedSet.bioQuestions.length > 0) {
        return (
          <div className={classes.selectedContainer}>
            <p className={`${classes.label} card-label`}>
              {questionListLabel}
            </p>

            <div className={classes.listContainer}>
              {selectedSet.bioQuestions.map((question, index) => (
                <Grid item className={classes.itemRows} key={index}>
                  <p className="card-value">
                    {question.name}
                  </p>
                </Grid>
              ))}
            </div>
          </div>
        );
      }
    }
  };

  const renderSpinner = () => (
    <div className='spinner'>
      <CircularProgress />
    </div>
  );

  return (
    <Paper elevation={3} className="card-container">
      <div>
        <div className={classes.headingContainer}>
          <Typography component="h3" variant="h3" color="primary">
            {header}
          </Typography>

          {renderAction()}
        </div>

        {isLoading ? renderSpinner() : renderSets()}
      </div>
    </Paper>
  );
};

BioSetSelector.propTypes = {
  buttonText: PropTypes.string,
  disableEmptyState: PropTypes.bool, // Does not display empty state if there are no sets
  emptyOptionText: PropTypes.string,
  emptyOptionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  emptyStateText: PropTypes.string,
  errors: PropTypes.object,
  header: PropTypes.string,
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  questionListLabel: PropTypes.string,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      index: PropTypes.number,
      name: PropTypes.string,
      bioQuestions: PropTypes.array,
    }),
  ),
  showAction: PropTypes.bool,
};

BioSetSelector.defaultProps = {
  buttonText: 'Create a new set',
  disableEmptyState: false,
  emptyOptionText: 'Select a Question Type',
  emptyOptionValue: '',
  emptyStateText: 'You have not created any biographical sets yet. Please select one of the other options to either create your first set or use all your existing biographical questions.',
  errors: {},
  header: 'Default header',
  isLoading: false,
  label: 'Biographical Set Title',
  onChange: () => {},
  onClick: () => {},
  questionListLabel: 'Biographical questions within the selected set',
  selected: null,
  sets: [],
  showAction: false,
};

export default BioSetSelector;
