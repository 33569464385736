import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Button, Grid, Typography } from '@material-ui/core';
import StatusIndicator from 'components/StatusIndicator';

const useStyles = makeStyles({
  container: {
    color: '#333333',
    paddingTop: '36px',
    paddingBottom: '36px',
  },
  welcome: {
    color: '#838383',
    fontSize: '.625rem',
    marginBottom: '.4rem',
    textTransform: 'uppercase',
  },
  heading: {
    marginBottom: '.2rem',
  },
  subheading: {
    fontSize: '1.125rem',
    marginBottom: '0',
  },
  statusIndicator: {
    marginBottom: '1rem',
  },
});

export default function GuideHeader({
  guideName,
  guideStatus,
  onPreview,
  onSubmit,
  published,
  subheading,
}) {
  const classes = useStyles();

  const displayGuideStatus = () => {
    if (guideStatus.name === 'Subordinate') {
      return <Typography variant="body2" component="p">Ready to go live? Click “Submit Guide” below to notify LWVEF staff that your guide is ready to be merged with the master guide.</Typography>;
    }
    if (published) {
      return <StatusIndicator text="Your Guide is live" variant="green" />;
    }

    return <StatusIndicator text="Your Guide is not yet live" variant="red" />;
  };

  return (
    <div className="ga-banner">
      <div className="ga-container">
        <div className={`${classes.container} mdc-layout-grid`}>
          <div className="mdc-layout-grid__inner">
            <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
              <Grid container alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={12} sm={6}>
                  <p className={classes.welcome}>
                    Welcome back,
                  </p>

                  <Typography variant="h1" component="h1" className={classes.heading}>
                    {guideName}
                  </Typography>

                  <Typography variant="body1" component="p" className={classes.subheading}>
                    {subheading}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <div className={classes.statusIndicator}>
                        {displayGuideStatus()}
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} justifyContent="flex-end">
                    <Grid item>
                      <Button
                        color="secondary"
                        onClick={onPreview}
                        variant="outlined"
                        hidden={true}
                       >
                        Preview Guide
                      </Button>
                    </Grid>

                    <Grid item>
                      <Button
                        color="secondary"
                        id="submit-guide"
                        onClick={onSubmit}
                        variant="contained"
                      >
                        Submit Guide
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

GuideHeader.propTypes = {
  guideName: PropTypes.string.isRequired,
  guideStatus: PropTypes.shape({
    code: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
  published: PropTypes.bool,
  subheading: PropTypes.string,
  onPreview: PropTypes.func,
  onSubmit: PropTypes.func,
};

GuideHeader.defaultProps = {
  published: false,
  subheading: '',
  onPreview: () => {},
  onSubmit: () => {},
};
