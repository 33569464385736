import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TextField from '@material-ui/core/TextField';
import TabPanel from 'components/TabPanel';

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

class LanguageTabsInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      form: {
        body: props.body,
      },
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleLanguageTabChange = this.handleLanguageTabChange.bind(this);
  }

  componentDidMount() {
    this.setLanguageValues();
  }

  componentDidUpdate(prevProps) {
    this.updateFormValuesFromProps(prevProps);
  }

  reset() {
    this.setState({
      form: {
        body: '',
      },
    }, () => this.setLanguageValues());
  }

  // Add our dynamic list of language translations to state
  setLanguageValues() {
    const { languages, translations } = this.props;
    const { form } = this.state;
    const newFormState = form;

    languages.forEach(lang => {
      let val = '';

      if (Object.prototype.hasOwnProperty.call(translations, lang.id)) {
        val = translations[lang.id];
      }

      newFormState[lang.id] = val;
    });

    this.setState({
      form: newFormState,
    });
  }

  updateFormValuesFromProps(prevProps) {
    const { body, languages, translations } = this.props;
    const { form } = this.state;
    const newForm = { ...form };
    let hasFormChanges = false;

    if (body !== prevProps.body) {
      newForm.body = body;
      hasFormChanges = true;
    }

    languages.forEach(lang => {
      if ((!Object.prototype.hasOwnProperty.call(prevProps.translations, lang.id) && Object.prototype.hasOwnProperty.call(translations, lang.id)) || (Object.prototype.hasOwnProperty.call(prevProps.translations, lang.id) && Object.prototype.hasOwnProperty.call(translations, lang.id) && prevProps.translations[lang.id] !== translations[lang.id])) {
        newForm[lang.id] = translations[lang.id];
        hasFormChanges = true;
      }
    });

    if (hasFormChanges) {
      this.setState({
        form: newForm,
      });
    }
  }

  handleInputChange(event) {
    const { onChange } = this.props;
    const { form } = this.state;
    const { target } = event;
    const { name, value } = target;
    const newForm = { ...form };

    newForm[name] = value;

    this.setState({
      form: newForm,
    });

    onChange(newForm);
  }

  handleLanguageTabChange(event, newValue) {
    this.setState({
      activeTab: newValue,
    });
  }

  hasError(fieldName) {
    const { errors } = this.props;
    return Object.prototype.hasOwnProperty.call(errors, fieldName);
  }

  renderLanguageTabs() {
    const { id, languages } = this.props;
    const { activeTab } = this.state;
    let languageTabs = [];

    if (languages && languages.length > 0) {
      languageTabs = languages.map(lang => (
        <Tab
          key={`${id}-${lang.id}`}
          label={lang.label}
          {...a11yProps(lang.id)}
        />
      ));
    }

    return (
      <Tabs
        aria-label="Question translation tabs"
        indicatorColor="secondary"
        onChange={this.handleLanguageTabChange}
        scrollButtons="auto"
        textColor="secondary"
        value={activeTab}
        variant="scrollable"
      >
        <Tab label="English" {...a11yProps('en')} />
        {languageTabs}
      </Tabs>
    );
  }

  renderLanguageTabPanels() {
    const { errors, languages, submitting } = this.props;
    const { activeTab, form } = this.state;
    const helperText = 'ie: What will you do to support a vibrant economy across the U.S.?';
    let tabPanels = [];

    if (languages && languages.length > 0) {
      tabPanels = languages.map((lang, index) => (
        <TabPanel
          index={index + 1}
          key={`panel-${lang.id}`}
          value={activeTab}
        >
          <TextField
            disabled={submitting}
            error={this.hasError(lang.id)}
            fullWidth
            helperText={this.hasError(lang.id) ? errors[lang.id] : helperText}
            label="Question Text"
            multiline
            name={lang.id}
            onChange={this.handleInputChange}
            rows={4}
            value={form[lang.id]}
            variant="filled"
          />
        </TabPanel>
      ));
    }

    // Append english to the beginning
    tabPanels.unshift(
      <TabPanel
        key={'panel-en'}
        value={activeTab}
        index={0}
      >
        <TextField
          disabled={submitting}
          error={this.hasError('body')}
          fullWidth
          helperText={this.hasError('body') ? errors.body : helperText}
          label="Question Text"
          multiline
          name="body"
          onChange={this.handleInputChange}
          rows={4}
          value={form.body}
          variant="filled"
        />
      </TabPanel>,
    );

    return tabPanels;
  }

  render() {
    return (
      <div>
        {this.renderLanguageTabs()}
        {this.renderLanguageTabPanels()}
      </div>
    );
  }
}

LanguageTabsInput.propTypes = {
  body: PropTypes.string,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  errors: PropTypes.object,
  languages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
  })),
  onChange: PropTypes.func,
  submitting: PropTypes.bool,
  translations: PropTypes.object,
};

LanguageTabsInput.defaultProps = {
  body: '',
  errors: {},
  languages: [],
  onChange: () => {},
  submitting: false,
  translations: {},
};

export default LanguageTabsInput;
