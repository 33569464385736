import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent, CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import WYSIWYG from 'components/WYSIWYG';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    padding: '.938rem 1.75rem 1.875rem 1.75rem',
  },
  formControl: {
    marginRight: theme.spacing(3),
    '&:last-child': {
      marginRight: 0,
    },
  },
  fieldContainer: {
    display: 'flex',
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    marginTop: '1rem',
    textDecoration: 'none',
  },
}));

export default function RaceOverviewForm(props) {
  const {
    categoryId,
    categories,
    categoryLabel,
    editCategoryLinkText,
    description,
    descriptionPlaceholderText,
    errors,
    heading,
    isLoading,
    isMeasure,
    name,
    numberOfCandidates,
    onChangeCategory,
    onChangeDescriptionContent,
    onChangeNumberOfCandidates,
    onChangeTitle,
    onChangeType,
    onChangeVotingOption,
    titleLabel,
    titleExample,
    type,
    raceTypes,
    votingOption,
    votingSystems,
  } = props;

  const classes = useStyles();

  const categoryOptions = () => {
    const options = [];

    Object.values(categories).forEach(cat => {
      options.push(<MenuItem key={cat.title} value={cat.id}>{cat.title}</MenuItem>);
    });

    return options;
  };

  const votingSystemOptions = () => {
    const options = [];

    Object.values(votingSystems).forEach(sys => {
      options.push(<MenuItem key={sys.name} value={sys.value}>{sys.name}</MenuItem>);
    });

    return options;
  };

  const numberOfCandidatesDisabled = () => votingOption === 'single';

  const spinner = () => (
    <div className='spinner'>
      <CircularProgress />
    </div>
  );

  const getColumnWidth = () => {
    if (isMeasure) {
      return 12;
    }
    return 6;
  };

  const getRaceTypes = () => {
    const options = [];
    Object.values(raceTypes).forEach(t => {
      options.push(<MenuItem key={t.id} value={t.id}>{t.name}</MenuItem>);
    });

    return options;
  };

  const hasError = fieldName => Object.prototype.hasOwnProperty.call(errors, fieldName);

  const content = () => (
    <div>
      <Typography component="h2" variant="h3" color="primary" paragraph>
        {heading}
      </Typography>

      <Grid container spacing={3} className={classes.fieldContainer}>
        <Grid item xs={12} md={getColumnWidth()}>
          <TextField
            defaultValue={name}
            error={hasError('name')}
            fullWidth
            helperText={hasError('name') ? errors.name : titleExample}
            id="title"
            label={titleLabel}
            onChange={onChangeTitle}
            required
            variant="filled"
            inputProps={{ maxLength: 200 }}
          />
        </Grid>

        {!isMeasure
          && <Grid item xs={12} md={6}>
            <FormControl variant="filled" className={classes.formControl} fullWidth>
              <InputLabel id="race-type-field-label">Race type</InputLabel>
              <Select
                error={hasError('type')}
                id="race-type-field"
                labelId="race-type-field-label"
                onChange={onChangeType}
                required
                value={type}
              >
                {getRaceTypes()}
              </Select>
              <FormHelperText>{hasError('type') ? errors.type : ''}</FormHelperText>
            </FormControl>
          </Grid>}

        { !isMeasure
          && <Grid item xs={12} md={6}>
            <FormControl variant="filled" className={classes.formControl} fullWidth>
              <InputLabel id="race-voting-options-field-label">Voting Options</InputLabel>
              <Select
                error={hasError('votingOption')}
                labelId="race-voting-options-field-label"
                id="race-voting-options-field"
                onChange={onChangeVotingOption}
                required
                value={votingOption}
              >
                {votingSystemOptions()}
              </Select>
              <FormHelperText>{hasError('votingOption') ? errors.votingOption : ''}</FormHelperText>
            </FormControl>
          </Grid>}

        {!isMeasure
          && <Grid item xs={12} md={6}>
            <TextField
              disabled={numberOfCandidatesDisabled()}
              error={hasError('numberOfCandidates')}
              fullWidth
              helperText={hasError('numberOfCandidates') ? errors.numberOfCandidates : ''}
              id="numberOfCandidates"
              label="Number of Candidates"
              onChange={onChangeNumberOfCandidates}
              value={numberOfCandidates}
              variant="filled"
            />
          </Grid>}

        <Grid item xs={12} md={getColumnWidth()}>
          <FormControl variant="filled" className={classes.formControl} fullWidth style={{ marginBottom: '1rem' }}>
            <InputLabel id="race-category-field-label" shrink>{categoryLabel}</InputLabel>
            <Select
              displayEmpty
              error={hasError('categoryId')}
              id="race-category-field"
              inputProps={{ 'aria-label': 'Without label' }}
              labelId="race-category-field-label"
              onChange={onChangeCategory}
              required
              value={categoryId}
            >
              {categoryOptions()}
            </Select>
            <FormHelperText>{hasError('categoryId') ? errors.categoryId : ''}</FormHelperText>
          </FormControl>

          <a className={classes.link} href="/categories">
            {editCategoryLinkText}
          </a>
        </Grid>

        <Grid item xs={12}>
          <WYSIWYG
            helperText={descriptionPlaceholderText}
            label={description}
            value={description}
            onChange={onChangeDescriptionContent}
          />
        </Grid>
      </Grid>
    </div>

  );

  return (
    <Card>
      <CardContent className={classes.cardContainer}>
        {isLoading ? spinner() : content()}
      </CardContent>
    </Card>
  );
}

RaceOverviewForm.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    title: PropTypes.string,
  })).isRequired,
  categoryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  categoryLabel: PropTypes.string,
  descriptionPlaceholderText: PropTypes.string,
  description: PropTypes.string,
  editCategoryLinkText: PropTypes.string,
  errors: PropTypes.object,
  heading: PropTypes.string,
  isLoading: PropTypes.bool,
  isMeasure: PropTypes.bool,
  name: PropTypes.string.isRequired,
  numberOfCandidates: PropTypes.number,
  onChangeCategory: PropTypes.func,
  onChangeTitle: PropTypes.func,
  onChangeType: PropTypes.func,
  onChangeVotingOption: PropTypes.func,
  onChangeDescriptionContent: PropTypes.func,
  onChangeNumberOfCandidates: PropTypes.func,
  titleExample: PropTypes.string,
  titleLabel: PropTypes.string,
  type: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  raceTypes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })),
  votingOption: PropTypes.string.isRequired,
  votingSystems: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
};

RaceOverviewForm.defaultProps = {
  categoryId: '',
  categoryLabel: 'Race Category',
  descriptionPlaceholderText: 'Race Description',
  description: '',
  editCategoryLinkText: 'Manage your Race Categories',
  errors: {},
  heading: 'Race overview',
  isLoading: true,
  isMeasure: false,
  name: '',
  numberOfCandidates: 1,
  onChangeCategory: {},
  onChangeTitle: {},
  onChangeType: {},
  onChangeVotingOption: {},
  onChangeDescriptionContent: {},
  onChangeNumberOfCandidates: {},
  raceTypes: [
    {
      id: 1,
      name: 'Closed Primary',
    },
    {
      id: 2,
      name: 'Open/General Election',
    },
  ],
  titleExample: 'Ex. Virginia House District 7 (Democratic Primary)',
  titleLabel: 'Race Title',
  votingOption: '',
};
