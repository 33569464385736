import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, createStyles, withStyles } from '@material-ui/core/styles';
import ActionsDropdown from 'components/ActionsDropdown';
import PageHeader from 'components/PageHeader';
import SnackbarAlert from 'components/SnackbarAlert';
import { CandidateTable } from 'components/Candidates/Partials/CandidateTable';
import CandidateStatus from 'components/Candidates/Partials/CandidateStatus';
import fetchUtil from 'helpers/Fetch';
import GaUtils from 'helpers/GaUtils';
import useSavedTableSettings from 'hooks/useSavedTableSettings';
import theme from 'scripts/theme';

const styles = ({ spacing }) => createStyles({
  container: {
    marginBottom: spacing(5),
  },
  actionContainer: {
    '& > *': {
      marginLeft: spacing(1),
    },
  },
  actionButton: {
    margin: '8px 20px',
    minWidth: '50px',
    textTransform: 'none',
  },
});

const ArchivedCandidateDashboard = ({
  breadcrumbText,
  breadcrumbUrl,
  candidates,
  guideId,
}) => {
  const [localCandidates, setLocalCandidates] = useState([]);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [filterModel, setFilterModel, sortModel, setSortModel] = useSavedTableSettings({ key: 'archived-candidates' });
  const _snackbar = useRef();

  useEffect(() => {
    setLocalCandidates(candidates);
  }, [candidates]);

  const showSuccess = message => {
    _snackbar.current.hide();
    _snackbar.current.show('success', message);
  };

  const showError = message => {
    _snackbar.current.hide();
    _snackbar.current.show('error', message);
  };

  const setRowLoadingState = (candidateIds, loading) => {
    const candidateListCopy = localCandidates.slice().map(c => {
      if (candidateIds.includes(c.id)) {
        c.loading = loading;
        return c;
      }

      c.loading = false;
      return c;
    });

    setLocalCandidates(candidateListCopy);
  };

  const handleRestore = candidateIds => {
    setRowLoadingState(candidateIds, true);

    fetchUtil(`/api/v1/guide/${guideId}/candidate/unarchive`, 'PUT', { candidateIds })
      .then(() => {
        const newCandidates = localCandidates.slice().filter(c => !candidateIds.includes(c.id));
        const message = candidateIds.length > 1 ? 'Candidates were successfully unarchived!' : 'Candidate was successfully unarchived!';
        setLocalCandidates(newCandidates);
        showSuccess(message);
      })
      .catch(error => {
        console.error(error);
        let message = `There was an issue unarchiving your candidate${candidateIds.length > 1 ? 's' : ''}. Please try again and contact your guide admin if this issue continues.`;

        if (error.status === 403) {
          message = 'Only guide admins can restore candidates. Please contact your guide admin.';
        }

        setRowLoadingState(candidateIds, false);
        showError(message);
      });
  };

  const tableColumns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1.5,
      renderCell: (params) => (
        <a href={`/races/${params.row.race.id}/candidates/${params.id}`}>
          {params.row.name != '' ? params.row.name : 'NO NAME'}
        </a>
      ),
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 1,
      valueGetter: (params) => params.row.type,
    },
    {
      field: 'parties',
      headerName: 'Party',
      flex: 1,
      renderCell: (params) => {
        let text = '';
        if (params.row && params.row.parties && params.row.parties.length > 0) {
          text = params.row.parties
            .map(p => (GaUtils.isNullOrEmpty(p.abbr) ? p.name : p.abbr))
            .join(', ');
        }
        return text;
      },
      valueGetter: (params) => params.row.parties.map(p => p.name).join(', '),
    },
    {
      field: 'race',
      headerName: 'Race/Initiative',
      flex: 1,
      valueGetter: (params) => params.row.race.name,
    },
    {
      field: 'visible',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => <CandidateStatus status={params.row.state} />,
      valueGetter: (params) => params.row.state.name,
    },
    // {
    //   field: 'date_archived',
    //   headerName: 'Date Archived',
    //   flex: 1,
    // },
    {
      field: 'actions',
      headerName: 'Actions',
      resizable: false,
      sortable: false,
      width: 120,
      renderCell: (params) => {
        const options = [
          { label: 'Unarchive', onClick: () => handleRestore([params.id]) },
          // { label: 'Delete', onClick: () => this.handleDelete(params.id) },
          // { label: 'Export', onClick: () => this.handleExport(params.id) },
        ];

        return <ActionsDropdown disabled={params.row.loading == true} options={options} />;
      },
    },
  ];

  const tableBulkActions = [
    // {
    //   name: 'Delete',
    //   color: 'danger',
    //   onClick: () => {},
    // },
    {
      name: 'Unarchive',
      color: 'primary',
      onClick: () => handleRestore(selectedCandidates),
    },
    // {
    //   name: 'Export',
    //   color: 'primary',
    //   onClick: () => {},
    // },
  ];

  return (
    <ThemeProvider theme={theme}>
      <SnackbarAlert ref={_snackbar} />

      <PageHeader
        breadcrumbText={breadcrumbText}
        breadcrumbUrl={breadcrumbUrl}
        heading="Archived Candidates"
        subheading="These candidates still exist in your guide and are assigned to a race, but will be hidden to voters. You can either restore these candidates or delete them."
      />

      <div className="ga-container">
        <div className="mdc-layout-grid">
          <div className="mdc-layout-grid__inner">
            <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
              <CandidateTable
                bulkActions={tableBulkActions}
                candidates={localCandidates}
                columns={tableColumns}
                emptyStateText="There are currently no archived candidates for this guide."
                filterModel={filterModel}
                heading="Archived Candidates"
                onFilterModelChange={(model) => setFilterModel(model)}
                onSelectionModelChange={selected => setSelectedCandidates(selected)}
                onSortModelChange={(model) => setSortModel(model)}
                sortModel={sortModel}
              />
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

ArchivedCandidateDashboard.propTypes = {
  breadcrumbText: PropTypes.string,
  breadcrumbUrl: PropTypes.string,
  candidates: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ),
  guideId: PropTypes.string.isRequired,
};
ArchivedCandidateDashboard.defaultProps = {
  candidates: [],
};

export default withStyles(styles)(ArchivedCandidateDashboard);
