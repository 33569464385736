import PropTypes from 'prop-types';

export default PropTypes.shape(
  {
    applicationId: PropTypes.string,
    guides: PropTypes.array,
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    name: PropTypes.string.isRequired,
  },
);
