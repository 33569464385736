import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { DataGridPro, LicenseInfo } from '@mui/x-data-grid-pro';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import EmptyState from 'components/EmptyState';
import ActionsDropdown from 'components/ActionsDropdown';
import AdminCopyGuideModal from 'components/SuperAdminSettings/Partials/AdminCopyGuideModal';
import affiliate from 'propTypes/affiliate';
import { GuideStatus } from '../guideStatus';

LicenseInfo.setLicenseKey(
  '5354fa8b30068069673634540842cbf3T1JERVI6MzU2NzcsRVhQSVJZPTE2NzM0NzU4MDAwMDAsS0VZVkVSU0lPTj0x',
);

const ROWS_PER_PAGE_OPTIONS = [50, 100, 500, 1000, 5000];

const styles = ({ palette }) => createStyles({
  headingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: '47px', // Prevents the container from jumping around when bulk actions show/hide
  },
  actionsContainer: {
    backgroundColor: '#f5f5f5',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  actionBlockContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  actionButton: {
    margin: '8px 20px',
    minWidth: '50px',
    textTransform: 'none',
  },
  destructiveButton: {
    margin: '8px 20px',
    minWidth: '50px',
    textTransform: 'none',
    color: '#ac1b3d',
    '&:hover': {
      color: '#6B1126',
    },
  },
  filter: {
    backgroundColor: '#fff',
    margin: '8px 18px',
    minWidth: 120,
  },
  search: {
    backgroundColor: '#fff',
    margin: '8px 18px',
    minWidth: 275,
  },
  tableContainer: {
    '& a': {
      color: palette.primary.main,
      fontWeight: 500,
      textDecoration: 'none',
    },
  },
  table: {
    border: 'none',
  },
  emptyStateButton: {
    margin: '0 .8rem',
  },
  published: {
    color: '#3fa06f',
  },
  unpublished: {
    color: '#e0e5e8',
  },
});

// Dynamic row height style for DataGrid component.
const StyledDataGrid = withStyles({
  root: {
    '& .MuiDataGrid-renderingZone': {
      maxHeight: 'none !important',
    },
    '& .MuiDataGrid-cell': {
      lineHeight: 'unset !important',
      whiteSpace: 'normal',
      display: 'flex',
      alignItems: 'center',
      borderBottom: 'none',
    },
    '& .MuiDataGrid-row': {
      maxHeight: 'none !important',
      borderBottom: '1px solid rgba(224, 224, 224, 1)',
    },
    '& .MuiDataGrid-row .MuiDataGrid-cell': {
      maxHeight: 'none !important',
      height: 'fit-content',
    },
  },
})(DataGridPro);

const AffiliateGuideTable = ({
  affiliates,
  classes,
  onSubmitCopyGuide,
  rows,
}) => {
  const [pageSize, setPageSize] = useState(50);
  const [submitting, setSubmitting] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedGuide, setSelectedGuide] = useState({});

  const handlePageSizeUpdate = (newSize) => {
    setPageSize(newSize);
  };

  const handleCopyGuideAction = (sourceGuide) => {
    setSelectedGuide(sourceGuide);
    setModalOpen(true);
  };

  const handleCopyGuideSubmission = async (guideSubmission) => {
    setSubmitting(true);

    const newGuide = {
      copyFromGuideId: selectedGuide.id,
      affiliateId: guideSubmission.affiliateId,
      name: guideSubmission.name,
    };

    await onSubmitCopyGuide(newGuide);

    handleCancelModal();
  };

  const handleCancelModal = () => {
    setModalOpen(false);
    setSubmitting(false);
    setSelectedGuide({});
  };

  const renderCopyModal = () => {
    if (isModalOpen) {
      return (
        <AdminCopyGuideModal
          open={isModalOpen}
          onCancel={handleCancelModal}
          onSubmit={handleCopyGuideSubmission}
          submitting={submitting}
          sourceGuide={selectedGuide}
          affiliates={affiliates}
        />
      );
    }
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Guide Name',
      flex: 1,
      renderCell: (params) => (
        <a href={`/admin/guides/${params.row.id}`}>
          {params.row.voterGuide.name}
        </a>
      ),
      valueGetter: (params) => params.row.voterGuide.name,
    },
    {
      field: 'status',
      headerName: 'Guide Status',
      flex: 1,
      valueGetter: (params) => GuideStatus[params.row.voterGuide.status],
    },
    {
      field: 'live',
      headerName: 'Live',
      flex: 1,
      renderCell: (params) => {
        if (params.row.live) {
          return (
            <>
              <FiberManualRecordIcon className={classes.published} />
              {' '}
              <span>Yes</span>
            </>
          );
        }

        return (
          <>
            <FiberManualRecordIcon className={classes.unpublished} />
            {' '}
            <span>No</span>
          </>
        );
      },
      valueGetter: (params) => params.row.live,
    },
    {
      field: 'affiliate',
      headerName: 'Affiliate',
      flex: 0.75,
      valueGetter: (params) => params.row.affiliate.name,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      resizable: false,
      sortable: false,
      width: 120,
      renderCell: (params) => {
        const editOpt = { label: 'Copy', onClick: () => handleCopyGuideAction(params.row) };

        const options = [editOpt];

        return <ActionsDropdown options={options} />;
      },
    },
  ];

  const renderTable = () => (
    <div className={classes.tableContainer}>
      {renderCopyModal()}
      <StyledDataGrid
        autoHeight={true}
        className={classes.table}
        columns={columns}
        initialState={{
          sorting: {
            sortModel: [{ field: 'name', sort: 'desc' }],
          },
        }}
        onPageSizeChange={handlePageSizeUpdate}
        pageSize={pageSize}
        pagination={true}
        rows={rows}
        rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
      />
    </div>
  );

  const renderEmptyState = () => (
    <EmptyState>
      <p className="ga-m-bottom--large">
        There are no guides available
      </p>
    </EmptyState>
  );

  return (
    <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12 ga-m-vertical--large">
      {rows ? (
        renderTable()
      ) : (
        renderEmptyState()
      )}
    </div>
  );
};

AffiliateGuideTable.propTypes = {
  classes: PropTypes.object,
  rows: PropTypes.array.isRequired,
  affiliates: PropTypes.arrayOf(affiliate).isRequired,
  onSubmitCopyGuide: PropTypes.func.isRequired,
};

export default withStyles(styles)(AffiliateGuideTable);
