import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import {
  Button, CircularProgress, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Paper, Select, Typography,
} from '@material-ui/core';
import Interweave from 'interweave';
import EmptyState from 'components/EmptyState';
import LanguageTabsDisplay from 'components/RaceQuestions/LanguageTabsDisplay';
import stateErrors from 'helpers/StateErrors';

const styles = {
  container: {
    paddingTop: '1.25rem',
  },
  headingContainer: {
    display: 'flex', justifyContent: 'space-between',
  },
  selectedContainer: {
    paddingTop: '1.875rem',
  },
};

const RaceQuestionSetSelector = (props) => {
  const {
    classes,
    buttonText,
    errors,
    emptyStateText,
    header,
    languages,
    loading,
    onChange,
    onClick,
    questionSetRootUrl,
    selected,
    sets,
    selectLabel,
    showAction,
  } = props;

  const [raceSets, setRaceSets] = useState([]);

  const handleInputChange = (event) => {
    const { value } = event.target;
    if (value !== '') {
      const finder = sets.find(el => el.id === value);
      if (typeof finder != 'undefined') {
        onChange(finder);
      } else {
        onChange(null);
      }
    } else {
      onChange(null);
    }
  };

  const renderAction = () => {
    if (showAction) {
      return (<Button
        color="secondary"
        onClick={onClick}
        size="medium"
        variant="outlined"
      >
        {buttonText}
      </Button>);
    }
  };

  const renderQuestions = () => {
    const finder = sets.find(el => el.id === selected);
    const questionsSorted = typeof finder != 'undefined' ? finder.questions.sort((a, b) => a.index - b.index) : [];

    return (<LanguageTabsDisplay
      languages={languages}
      questionSetRootUrl={questionSetRootUrl}
      id={typeof finder != 'undefined' ? finder.id : 0}
      name={typeof finder != 'undefined' ? finder.name : ''}
      questions={questionsSorted}
      canAddQuestions={false}
    />);
  };

  const renderSpinner = () => (<div className='spinner'>
    <CircularProgress />
  </div>);

  const renderCardElements = () => {
    if (raceSets.length > 0) {
      return (<div className={classes.container}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <FormControl
              error={stateErrors.hasError('set', errors)}
              fullWidth
              required
              variant="filled"
            >
              <InputLabel id="racequestion-selected-set">
                {selectLabel}
              </InputLabel>

              <Select
                id="racequestion-selected-set"
                name="set"
                error={stateErrors.hasError('set', errors)}
                value={selected || ''}
                onChange={handleInputChange}
              >
                <MenuItem value={''}>
                  <em>{selectLabel}</em>
                </MenuItem>
                {raceSets}
              </Select>
              <FormHelperText>{stateErrors.getError('set', errors)}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        {selected && (<div className={classes.selectedContainer}>
          {renderQuestions()}
        </div>)}
      </div>);
    }

    return (<div className={classes.container}>
      <EmptyState>
        <Interweave content={emptyStateText} />
      </EmptyState>
    </div>);
  };

  useEffect(() => {
    if (sets && sets.length > 0) {
      setRaceSets(sets.map(set => (<MenuItem
        key={set.id}
        value={set.id}
      >
        {set.name}
      </MenuItem>)));
    }
  }, [sets]);

  return (<Paper elevation={3} className="card-container">
    <div>
      <div className={classes.headingContainer}>
        <Typography component="h3" variant="h3" color="primary">
          {header}
        </Typography>

        {renderAction()}
      </div>

      {loading ? renderSpinner() : renderCardElements()}
    </div>
  </Paper>);
};

RaceQuestionSetSelector.propTypes = {
  buttonText: PropTypes.string,
  classes: PropTypes.object.isRequired,
  header: PropTypes.string,
  questionSetRootUrl: PropTypes.string,
  languages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string, label: PropTypes.string, value: PropTypes.string,
  })),
  selected: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  sets: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number, name: PropTypes.string, questions: PropTypes.array,
  })),
  emptyStateText: PropTypes.string,
  errors: PropTypes.object,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  selectLabel: PropTypes.string,
  showAction: PropTypes.bool,
  loading: PropTypes.bool,
};

RaceQuestionSetSelector.defaultProps = {
  buttonText: 'Create a new set',
  header: 'Default header',
  languages: [],
  selected: null,
  sets: [],
  emptyStateText: 'You have not created any race question sets yet. Please select the other option to create your first set.',
  errors: {},
  onChange: () => {
  },
  onClick: () => {
  },
  selectLabel: 'Select a Race Question Set',
  showAction: false,
  loading: false,
};

export default withStyles(styles)(RaceQuestionSetSelector);
