import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';

const useStyles = makeStyles({
  label: {
    fontSize: '.9rem',
    fontWeight: 'bold',
    marginBottom: '0.625rem',
  },
});

function QuestionRadioOptions({
  id,
  errors,
  label,
  onChange,
  options,
  submitting,
  type,
  value,
}) {
  const classes = useStyles();

  // expensive computation, we only want to do this when options or type changes, so we use memory (aptly named useMemo)
  const questionType = useMemo(() => options.find(o => o.id === type), [options, type]);

  // set a default in case questionType is undefined (shouldn't happen, if it does it's an issue with the parent component)
  let answerOptions = <></>;
  try {
    answerOptions = questionType.scaleType.options.map((val, index) => (
      <FormControlLabel value={val} control={<Radio />} label={val} key={index} />
    ));
  } catch (e) {
    console.error(e);
    throw new Error('Invalid question type passed in, or question type missing fields');
  }

  const hasError = (fieldID) => Object.prototype.hasOwnProperty.call(errors, fieldID);

  const handleChange = (event, newValue) => {
    onChange(id, 'en', newValue);
  };

  return (
    <FormControl
      component="fieldset"
      disabled={submitting}
      error={hasError(questionType.scaleType.labelPrefix)}
    >
      <FormLabel component="legend" className={classes.label}>
        {label}
      </FormLabel>

      <RadioGroup
        aria-label={label}
        name={questionType.scaleType.labelPrefix}
        onChange={handleChange}
        value={value}
      >
        {answerOptions}
      </RadioGroup>

      <FormHelperText>
        {hasError(questionType.scaleType.labelPrefix) ? errors[questionType.scaleType.labelPrefix] : ''}
      </FormHelperText>
    </FormControl>
  );
}

QuestionRadioOptions.propTypes = {
  id: PropTypes.number.isRequired,
  errors: PropTypes.object,
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  submitting: PropTypes.bool,
  type: PropTypes.number.isRequired,
  value: PropTypes.string,
};

QuestionRadioOptions.defaultProps = {
  errors: {},
  label: '',
  onChange: () => {},
  options: [],
  submitting: false,
  value: '',
};

export default QuestionRadioOptions;
