import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import {
  Button,
  Typography,
} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import MultistepModal from 'components/MultistepModal';
import PageHeader from 'components/PageHeader';
import PageActionsHeader from 'components/PageActionsHeader';
import PageSecondaryHeader from 'components/PageSecondaryHeader';
import SnackbarAlert from 'components/SnackbarAlert';
import AddQuestionForm from 'components/RaceQuestions/AddQuestionForm';
import EditQuestionFormList from 'components/RaceQuestions/EditQuestionFormList';
import QuestionSetEditModal from 'components/RaceQuestions/QuestionSetEditModal';
import RacesUsingQuestionSetModal from 'components/RaceQuestions/RacesUsingQuestionSetModal';
import { Step1, Step2, Step3 } from 'components/RaceQuestions/UnbiasedQuestions';
import PageActionsFooter from 'components/PageActionsFooter';
import fetchUtil from 'helpers/Fetch';
import { useReorderParent } from 'hooks/useReorder';
import theme from 'scripts/theme';

const unbiasedQuestionModalSteps = [
  {
    title: 'How to Write Unbiased Questions',
    next: 'Up Next: Helpful Tips',
    body: Step1,
  },
  {
    title: 'How to Write Unbiased Questions',
    next: 'Up Next: Do\'s and Don\'ts',
    body: Step2,
  },
  {
    title: 'How to Write Unbiased Questions',
    next: 'Tutorial Completed!',
    body: Step3,
  },
];

const QuestionSetDetailContainer = ({
  baseQuestionApiUrl,
  id,
  languages,
  messages,
  name,
  questions,
  patchUrl,
  typeOptions,
  guideId,
}) => {
  const _addQuestionForm = useRef(null);
  const _modal = useRef(null);
  const _snackbar = useRef(null);

  const [addQuestionSubmitting, setAddQuestionSubmitting] = useState(false);
  const [addQuestionErrors, setAddQuestionErrors] = useState({});
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [racesUsingModalOpen, setRacesUsingModalOpen] = useState(false);
  const [nameState, setNameState] = useState(name);
  const [questionsState, setQuestionsState] = useState([]);

  const {
    onReorder,
    moveItem: moveQuestion,
    disabled,
    setDisabled,
    setOriginalQuestionsState,
    onFailedDrop,
  } = useReorderParent({
    dataList: questionsState,
    setDataList: setQuestionsState,
    originalStateName: 'originalQuestionsState',
    apiEndpoint: `/api/v1/guide/${guideId}/race-questions/${id}/question/reorder`,
    apiParamName: 'questions',
    showError: () => _snackbar.current.show('error', 'There was an issue trying to reorder your question. Try again or contact your guide admin if this continues'),
  });

  useEffect(() => {
    addStatePropertiesToQuestions();
    openUnbiasedQuestionsModal();
  }, []);

  // Adds a submitting property to allow the edit form for
  // each question to be enabled/disabled
  const addStatePropertiesToQuestions = () => {
    setQuestionsState(questions.map(question => {
      question.submitting = false;
      return question;
    }));
    setOriginalQuestionsState(questions.map(question => {
      question.submitting = false;
      return question;
    }));
    setDisabled(false);
  };

  // If the unbiased question cookie isn't available
  // show the modal when the user arrives on the page
  const openUnbiasedQuestionsModal = () => {
    if (!Cookies.get('LWV_VGT_UNBIASED_QUESTIONS')) {
      _modal.current.handleOpen();
      Cookies.set('LWV_VGT_UNBIASED_QUESTIONS', true);
    }
  };

  const handleEditModalOpen = () => {
    setEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setEditModalOpen(false);
  };

  const handleRacesUsingModalOpen = () => {
    setRacesUsingModalOpen(true);
  };

  const handleRacesUsingModalClose = () => {
    setRacesUsingModalOpen(false);
  };

  const handleModalOpen = () => {
    _modal.current.handleOpen();
  };

  const handleEditError = (action, message) => {
    let snackbarMessage = messages.edit.error;

    _snackbar.current.hide();

    if (message) {
      snackbarMessage = message;
    }

    _snackbar.current.show('error', snackbarMessage);
  };

  const handleEditSuccess = (action, data) => {
    const message = messages.questionSet.edit.success;

    _snackbar.current.hide();

    setEditModalOpen(false);
    setNameState(data.name);

    _snackbar.current.show('success', message);
  };

  const handleQuestionAdd = async (data) => {
    const modifiedFormData = {
      body: data.body,
      maxQuestionLength: data.maxQuestionLength,
      type: data.type,
      translations: [],
    };

    setAddQuestionErrors({});
    setAddQuestionSubmitting(true);

    Object.keys(data).forEach(property => {
      // Only include translation fields
      if (!['body', 'maxQuestionLength', 'type'].includes(property)) {
        modifiedFormData.translations.push({
          key: property,
          body: data[property],
        });
      }
    });

    try {
      const response = await makeQuestionRequest(baseQuestionApiUrl, 'POST', modifiedFormData);

      const questionsCopy = [...questionsState];

      questionsCopy.push({
        body: response.body,
        id: response.id,
        index: response.index,
        maxQuestionLength: response.maxQuestionLength,
        questionFormat: response.questionFormat,
        translations: response.translations,
      });

      setAddQuestionSubmitting(false);
      setQuestionsState(questionsCopy);
      setOriginalQuestionsState(questionsCopy);

      // Reset add question form values
      _addQuestionForm.current.reset();

      // Display success notification
      _snackbar.current.show('success', messages.raceQuestions.add.success);
    } catch (error) {
      if (error && Object.prototype.hasOwnProperty.call(error, 'status') && Object.prototype.hasOwnProperty.call(error, 'response')) {
        if (error.status === 422 && Object.prototype.hasOwnProperty.call(error.response, 'errors') && Object.prototype.hasOwnProperty.call(error.response.errors[0], 'validationErrors')) {
          setAddQuestionErrors(error.response.errors[0].validationErrors);
          setAddQuestionSubmitting(false);

          _snackbar.current.show('error', messages.raceQuestions.add.error);

          return;
        }
      }

      setAddQuestionErrors(error);
      setAddQuestionSubmitting(false);

      // Display error notification
      _snackbar.current.show('error', messages.common.error.unknown);

      console.error(error);
    }
  };

  const handleQuestionEdit = async (questionId, data) => {
    const modifiedFormData = {
      body: data.body,
      id: questionId,
      maxQuestionLength: data.maxQuestionLength,
      type: data.type,
      translations: [],
    };

    const questionPatchUrl = `${baseQuestionApiUrl}/${questionId}`;

    updateQuestionSubmittingState(questionId, true);

    Object.keys(data).forEach(property => {
      if (!['body', 'id', 'maxQuestionLength', 'type'].includes(property)) {
        modifiedFormData.translations.push({
          key: property,
          body: data[property],
        });
      }
    });

    try {
      await makeQuestionRequest(questionPatchUrl, 'PATCH', modifiedFormData);

      updateQuestionSubmittingState(questionId, false);
      updateQuestionErrors(questionId, {});

      // Display success notification
      _snackbar.current.show('success', messages.raceQuestions.update.success);
    } catch (error) {
      if (error && Object.prototype.hasOwnProperty.call(error, 'status') && Object.prototype.hasOwnProperty.call(error, 'response')) {
        if (error.status === 422 && Object.prototype.hasOwnProperty.call(error.response, 'errors') && Object.prototype.hasOwnProperty.call(error.response.errors[0], 'validationErrors')) {
          updateQuestionErrors(questionId, error.response.errors[0].validationErrors);
          updateQuestionSubmittingState(questionId, false);
          _snackbar.current.show('error', messages.raceQuestions.update.error);
          return;
        }
      }

      updateQuestionErrors(questionId, error);
      updateQuestionSubmittingState(questionId, false);

      // Display error notification
      _snackbar.current.show('error', messages.common.error.unknown);

      console.error(error);
    }
  };

  const handleQuestionDelete = async (questionId) => {
    const deleteUrl = `${baseQuestionApiUrl}/${questionId}`;

    try {
      await makeQuestionRequest(deleteUrl, 'DELETE');

      const updatedQuestions = questionsState.filter(question => question.id !== questionId);

      setQuestionsState(updatedQuestions);
      setOriginalQuestionsState(updatedQuestions);

      // Display success notification
      _snackbar.current.show('success', messages.raceQuestions.delete.success);
    } catch (error) {
      // Display error notification
      if (error.status == 403) {
        _snackbar.current.show('error', messages.forbidden);
      } else {
        _snackbar.current.show('error', messages.raceQuestions.delete.error);
      }
      console.error(error);
    }
  };

  const updateQuestionSubmittingState = (questionId, isSubmitting) => {
    const newQuestionState = questionsState.map(question => {
      if (question.id == questionId) {
        question.submitting = isSubmitting;
      }

      return question;
    });

    setQuestionsState(newQuestionState);
    setOriginalQuestionsState(newQuestionState);
  };

  const updateQuestionErrors = (questionId, errors) => {
    const newQuestionState = questionsState.map(question => {
      if (question.id == questionId) {
        question.errors = errors;
      }

      return question;
    });

    setQuestionsState(newQuestionState);
    setOriginalQuestionsState(newQuestionState);
  };

  const makeQuestionRequest = async (url, requestType, data) => {
    const response = await fetchUtil(url, requestType, data);
    return response;
  };

  return (
    <ThemeProvider theme={theme}>
      <SnackbarAlert ref={_snackbar} />

      <MultistepModal steps={unbiasedQuestionModalSteps} ref={_modal} />

      <QuestionSetEditModal
        open={editModalOpen}
        languages={languages}
        messages={messages}
        onClose={handleEditModalClose}
        onError={handleEditError}
        onSuccess={handleEditSuccess}
        patchUrl={patchUrl}
        id={id}
        name={nameState}
      />

      <RacesUsingQuestionSetModal
        open={racesUsingModalOpen}
        onClose={handleRacesUsingModalClose}
        guideId={guideId}
        questionSetId={id}
        questionSetName={name}
        messages={messages.questionSet.racesUsing}
      />

      <PageHeader
        breadcrumbText="Race Questions"
        breadcrumbUrl="/questions"
        heading={nameState}
      />

      <PageActionsHeader>
        <Button
          variant="text"
          color="primary"
          onClick={handleRacesUsingModalOpen}
          size="large"
        >
          View races using this question set
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleEditModalOpen}
          size="large"
        >
          {messages.questionSet.edit.button}
        </Button>
      </PageActionsHeader>

      <PageSecondaryHeader
        heading={messages.questionSet.unbiasedQuestions.heading}
        subheading={messages.questionSet.unbiasedQuestions.subheading}
      >
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleModalOpen}
          size="large"
        >
          {messages.questionSet.unbiasedQuestions.button}
        </Button>
      </PageSecondaryHeader>

      <div className="ga-container">
        <div className="mdc-layout-grid">
          <div className="mdc-layout-grid__inner">
            <div className="mdc-layout-grid__cell mdc-layout-grid__cell--align-middle mdc-layout-grid__cell--span-9">
              <Typography component="h2" variant="h2" paragraph>
                Add a New Question
              </Typography>

              <AddQuestionForm
                errors={addQuestionErrors}
                languages={languages}
                onSubmit={handleQuestionAdd}
                ref={_addQuestionForm}
                submitting={addQuestionSubmitting}
                typeOptions={typeOptions}
              />
            </div>

            <div className="mdc-layout-grid__cell mdc-layout-grid__cell--align-middle mdc-layout-grid__cell--span-9">
              <Typography component="h2" variant="h2">
                {messages.questionSet.list.heading}
              </Typography>

              <Typography component="p" variant="body1" paragraph>
                {messages.questionSet.list.subheading}
              </Typography>

              <EditQuestionFormList
                languages={languages}
                onQuestionDelete={handleQuestionDelete}
                onQuestionSubmit={handleQuestionEdit}
                questions={questionsState}
                typeOptions={typeOptions}
                disabled={disabled}
                handleQuestionReorder={onReorder}
                onFailedDrop={onFailedDrop}
                moveQuestion={moveQuestion}
              />
            </div>
          </div>
        </div>
      </div>

      <PageActionsFooter sticky>
        <Button
          color="primary"
          size="small"
          href="/questions"
        >
          Cancel
        </Button>

        <Button
          color="primary"
          size="small"
          href="/questions"
        >
          Back to race questions
        </Button>
      </PageActionsFooter>
    </ThemeProvider>
  );
};

QuestionSetDetailContainer.propTypes = {
  baseQuestionApiUrl: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  languages: PropTypes.array.isRequired,
  messages: PropTypes.object,
  name: PropTypes.string.isRequired,
  questions: PropTypes.array.isRequired,
  patchUrl: PropTypes.string.isRequired,
  typeOptions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    defaultMaxLength: PropTypes.number,
    localizable: PropTypes.bool,
    scaleType: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      prefix: PropTypes.string,
    }),
    active: PropTypes.bool,
  })).isRequired,
  guideId: PropTypes.string.isRequired,
};

QuestionSetDetailContainer.defaultProps = {
  messages: {
    common: {
      button: {
        cancel: 'Cancel',
      },
      error: {
        unknown: 'An unknown error occurred. Please contact your guide admin.',
      },
    },
    questionSet: {
      edit: {
        modal: {
          heading: 'Editing question set',
          button: 'Update question set',
          required: '*Required fields needed for your question set',
        },
        button: 'Edit',
        error: 'There was an issue updating your question set. Try again or contact your guide admin if the issue continues.',
        success: 'Question set updated successfully!',
      },
      list: {
        heading: 'Question list',
        subheading: 'It\'s recommended to ask candidates 3-5 questions.',
      },
      unbiasedQuestions: {
        button: 'View',
        heading: 'How to write unbiased questions',
        subheading: 'You can revisit our suggestions for how to write unbiased questions at any time.',
      },
      update: {
        button: 'Update Question Set',
        error: 'There was an issue updating your question set. Correct any errors and try again.',
        required: '*Required fields',
      },
    },
    raceQuestions: {
      add: {
        error: 'There was an issue saving your question set. Correct any errors and try again.',
        success: 'Your question was saved successfully!',
      },
      delete: {
        error: 'There was an issue deleting your question. Try again or contact your guide admin if the issue continues.',
        success: 'Your question was deleted successfully!',
      },
      update: {
        error: 'There was an issue saving your question set. Correct any errors and try again.',
        success: 'Your question was updated successfully!',
      },
    },
  },
  questions: [],
};

export default QuestionSetDetailContainer;
