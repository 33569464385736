import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  created: {
    color: '#e0e5e8',
  },
  invited: {
    color: '#fec40e',
  },
  responded: {
    color: '#3fa06f',
  },
});

export default function CandidateStatus({ status, labelStyle }) {
  const { id, name } = status;
  const classes = useStyles();
  let statusClass = classes.created;

  if (id === 2 || id === 6) {
    statusClass = classes.invited;
  } else if (id === 3 || id === 7) {
    statusClass = classes.responded;
  } else {
    statusClass = classes.created;
  }

  return (
    <span className={classes.container}>
      <FiberManualRecordIcon className={statusClass} />{' '}
      <span style={labelStyle}>{name}</span>
    </span>
  );
}

CandidateStatus.propTypes = {
  labelStyle: PropTypes.object,
  status: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
  }),
};

CandidateStatus.defaultProps = {
  labelStyle: null,
  status: { id: 1, name: 'Created' },
};
