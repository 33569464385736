import React from 'react';
import PropTypes from 'prop-types';
import UploadCard from 'components/UploadCard';
import { Grid } from '@material-ui/core';

function UploadCardList({ cards }) {
  const cardArray = cards.map((card, index) => (
    <Grid item key={index} xs={12} sm={4}>
      <UploadCard
        acceptedFileTypes={card.acceptedFileTypes}
        button={card.button}
        label={card.label}
        onChange={card.onChange} // eslint-disable-line react/jsx-handler-names
        text={card.text}
      />
    </Grid>
  ));

  return (
    <Grid container spacing={3}>
      {cardArray}
    </Grid>
  );
}

UploadCardList.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.shape({
    acceptedFileTypes: PropTypes.string.isRequired,
    buttonText: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    text: PropTypes.string,
  })),
};

UploadCardList.defaultProps = {
  cards: [],
};

export default UploadCardList;
