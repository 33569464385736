import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { DataGridPro, LicenseInfo } from '@mui/x-data-grid-pro';
import { Button, Tooltip, Typography } from '@material-ui/core';
import EmptyState from 'components/EmptyState';
import ShowHide from 'components/ShowHide';
import ActionsDropdown from 'components/ActionsDropdown';
import GaUtils from 'helpers/GaUtils';
import { guideApiKeyPropType } from 'propTypes';

LicenseInfo.setLicenseKey(
  '5354fa8b30068069673634540842cbf3T1JERVI6MzU2NzcsRVhQSVJZPTE2NzM0NzU4MDAwMDAsS0VZVkVSU0lPTj0x',
);

const ROWS_PER_PAGE_OPTIONS = [50, 100, 500, 1000, 5000];

const styles = ({ palette }) => createStyles({
  headingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: '47px', // Prevents the container from jumping around when bulk actions show/hide
  },
  actionsContainer: {
    backgroundColor: '#f5f5f5',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  actionBlockContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  actionButton: {
    margin: '8px 20px',
    minWidth: '50px',
    textTransform: 'none',
  },
  destructiveButton: {
    margin: '8px 20px',
    minWidth: '50px',
    textTransform: 'none',
    color: '#ac1b3d',
    '&:hover': {
      color: '#6B1126',
    },
  },
  filter: {
    backgroundColor: '#fff',
    margin: '8px 18px',
    minWidth: 120,
  },
  search: {
    backgroundColor: '#fff',
    margin: '8px 18px',
    minWidth: 275,
  },
  tableContainer: {
    '& a': {
      color: palette.primary.main,
      fontWeight: 500,
      textDecoration: 'none',
    },
  },
  table: {
    border: 'none',
  },
  emptyStateButton: {
    margin: '0 .8rem',
  },
});

// Dynamic row height style for DataGrid component.
const StyledDataGrid = withStyles({
  root: {
    '& .MuiDataGrid-renderingZone': {
      maxHeight: 'none !important',
    },
    '& .MuiDataGrid-cell': {
      lineHeight: 'unset !important',
      whiteSpace: 'normal',
      display: 'flex',
      alignItems: 'center',
      borderBottom: 'none',
    },
    '& .MuiDataGrid-row': {
      maxHeight: 'none !important',
      borderBottom: '1px solid rgba(224, 224, 224, 1)',
    },
    '& .MuiDataGrid-row .MuiDataGrid-cell': {
      maxHeight: 'none !important',
      height: 'fit-content',
    },
  },
})(DataGridPro);

const APIKeyTable = ({
  classes,
  keys,
  onSuccess,
  onError,
  // onDelete,
  disabled,
}) => {
  const [pageSize, setPageSize] = useState(50);

  const handlePageSizeUpdate = (newSize) => {
    setPageSize(newSize);
  };

  const handleCopyAction = (keyToCopy) => {
    try {
      if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(keyToCopy);
        onSuccess('API Key copied to clipboard.');
        return;
      }
      throw new Error('Missing navigator properties.');
    } catch (e) {
      console.error(e);
      onError('Could not copy API Key. Please try again.');
    }
  };

  // const handleDeleteAction = (id) => {
  //   onDelete([id]);
  // };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      renderCell: (params) => (
        params.row.description ? (
          <Tooltip title={params.row.description}>
            <span>{params.row.name}</span>
          </Tooltip>
        ) : (
          params.row.name
        )
      ),
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 0.75,
      valueGetter: (params) => params.row.email,
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
      hide: true,
      valueGetter: (params) => (params.row.description ? params.row.description : ''),
    },
    {
      field: 'fa-entity-id',
      headerName: 'Entity ID',
      flex: 0.6,
      hide: true,
      valueGetter: (params) => params.row.faEntityId,
    },
    {
      field: 'expiration',
      headerName: 'Exp. Date',
      flex: 0.5,
      type: 'dateTime',
      valueGetter: (params) => GaUtils.getDateFormatted(params.row.expirationDate),
      renderCell: (params) => {
        const createText = `Created on: ${GaUtils.getDateFormatted(params.row.created)}`;
        const modifyText = `Last modified: ${GaUtils.getDateFormatted(params.row.modified)}`;
        return (
          <Tooltip title={<span>{createText} <br /> {modifyText}</span>}>
            <span>{GaUtils.getDateFormatted(params.row.expirationDate)}</span>
          </Tooltip>
        );
      },
    },
    {
      field: 'apiSecret',
      headerName: 'API Secret',
      width: 200,
      valueGetter: (params) => params.row.base64EncodedSecret,
      renderCell: (params) => <ShowHide text={params.row.base64EncodedSecret} maxDots={20} scroll={true} />,
    },
    {
      field: 'active',
      headerName: 'Status',
      flex: 0.6,
      valueGetter: (params) => params.row.active,
      renderCell: (params) => <Typography variant='body2'>{params.row.active ? 'Active' : 'Inactive'}</Typography>,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      resizable: false,
      sortable: false,
      width: 110,
      renderCell: (params) => {
        const editOpt = { label: 'Edit', onClick: () => {} };
        // const deleteOpt = { label: 'Delete', onClick: () => handleDeleteAction(params.row.id) };
        const copyKey = { label: 'Copy key to clipboard', onClick: () => handleCopyAction(params.row.base64EncodedSecret) };
        const options = [editOpt];
        // options.push(deleteOpt);
        options.push(copyKey);
        return <ActionsDropdown disabled={disabled} options={options} />;
      },
    },
  ];

  if (!keys || keys.length <= 0) {
    return (
      <EmptyState>
        <p className="ga-m-bottom--large">
          There are currently no API Affiliate Keys available for this guide. Get started creating one now! [lorem ipsum insert some directions?]
        </p>
        <div>
          <Button
            color="secondary"
            variant="contained"
            className={classes.emptyStateButton}
            onClick={() => console.log('TODO: Open Add API Key modal')}
          >
            Add Key
          </Button>
        </div>
      </EmptyState>
    );
  }

  return (
    <div>
      <div className={classes.tableContainer}>
        <StyledDataGrid
          autoHeight={true}
          className={classes.table}
          columns={columns}
          disableSelectionOnClick
          onPageSizeChange={handlePageSizeUpdate}
          pageSize={pageSize}
          pagination={true}
          rows={keys}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
        />
      </div>
    </div>
  );
};

APIKeyTable.propTypes = {
  classes: PropTypes.object,
  keys: PropTypes.arrayOf(guideApiKeyPropType),
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  // onDelete: PropTypes.func,
  disabled: PropTypes.bool,
};

export default withStyles(styles)(APIKeyTable);
