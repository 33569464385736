import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  backgroundContainer: {
    backgroundColor: '#eef4f8',
  },
  isSticky: {
    position: 'sticky',
    bottom: 0,
    zIndex: 1,
  },
  grid: {
    padding: '16px 24px',
    width: '100%',
  },
  childContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > *': {
      marginLeft: '1rem',
    },
  },
});

export default function PageActionsFooter({ children, sticky }) {
  const classes = useStyles();
  const containerClasses = sticky ? `${classes.backgroundContainer} ${classes.isSticky}` : classes.backgroundContainer;

  return (
    <div className={`${containerClasses}`}>
      <div className="ga-container">
        <div className={`${classes.grid} mdc-layout-grid`}>
          <div className="mdc-layout-grid__inner">
            <div className={`mdc-layout-grid__cell mdc-layout-grid__cell--span-12 ${classes.childContainer}`}>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

PageActionsFooter.propTypes = {
  children: PropTypes.node,
  sticky: PropTypes.bool,
};

PageActionsFooter.defaultProps = {
  sticky: false,
};
