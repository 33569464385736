import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import theme from 'scripts/theme';
import {
  Button,
  ThemeProvider,
} from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import ImportModal from 'components/ImportModal';
import useCampaignFinanceStore from 'components/features/campaign-finance/stores/useCampaignFinanceStore';
import useUserRoleStore from 'components/stores/useUserRoleStore';

const useStyles = makeStyles(() => ({
  button: {
    minWidth: 0,
  },
}));

const ImportModalContainer = ({
  campaignFinanceUrl,
  gisUrl,
  messages,
  raceUrl,
}) => {
  const classes = useStyles();
  const [activeCardIndex, setActiveCardIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const { enableCampaignFinance } = useCampaignFinanceStore();
  const { isSuperAdmin } = useUserRoleStore();

  const getCards = () => {
    const cards = [
      {
        title: messages.import.raceCard.title,
        description: messages.import.raceCard.description,
        value: raceUrl,
      },
      {
        title: messages.import.gisCard.title,
        description: messages.import.gisCard.description,
        value: gisUrl,
      },
    ];

    // Check if campaignFinanceCard data is provided. If it is, add it to the cards array only if super admin and feature flag is enabled
    if (enableCampaignFinance && isSuperAdmin && messages.import?.campaignFinanceCard && campaignFinanceUrl) {
      cards.push({
        title: messages.import.campaignFinanceCard.title,
        description: messages.import.campaignFinanceCard.description,
        value: campaignFinanceUrl,
      });
    }

    return cards;
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleChange = (newCardIndex) => {
    setActiveCardIndex(newCardIndex);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleContinue = () => {
    const cards = getCards();
    if (cards[activeCardIndex].value) {
      window.location.href = cards[activeCardIndex].value;
    }
  };

  const cards = getCards();

  return (
    <ThemeProvider theme={theme}>
      <Button
        className={classes.button}
        color="secondary"
        endIcon={<PublishIcon />}
        onClick={handleShowModal}
        variant="outlined"
      >
        Import
      </Button>

      <ImportModal
        messages={messages}
        activeCardIndex={activeCardIndex}
        cards={cards}
        cardsBreakPoints={cards.length === 3 ? { lg: 4, xl: 4 } : {}}
        modalIsOpen={showModal}
        onCancel={handleCloseModal}
        onChange={handleChange}
        onContinue={handleContinue}
      />
    </ThemeProvider>
  );
};

ImportModalContainer.propTypes = {
  campaignFinanceUrl: PropTypes.string,
  gisUrl: PropTypes.string,
  messages: PropTypes.object,
  raceUrl: PropTypes.string,
};

ImportModalContainer.defaultProps = {
  messages: {
    import: {
      title: 'Import',
      description: 'There are a couple of different ways that you can import data into the Voter Guide. Select one of the options below to get started on your bulk import.',
      raceCard: {
        title: 'Races & Candidates',
        description: 'Already know the majority or all of your races and candidates? Bulk upload will help you save time!',
      },
      gisCard: {
        title: 'GIS District Sets',
        description: 'Import GIS districts from a previous year\'s guide (must export districts from previous guide first).',
      },
      campaignFinanceCard: {
        title: 'Campaign Finance',
        description: 'Import campaign finance data.',
      },
    },
    common: {
      button: {
        cancel: 'Cancel',
        continue: 'Continue',
      },
    },
  },
};

export default ImportModalContainer;
