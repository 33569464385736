import { createTheme } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const breakpoints = createBreakpoints({});

export default createTheme({
  typography: {
    allVariants: {
      color: '#333333',
    },
    fontFamily: [
      'Public Sans',
      'sans-serif',
    ].join(','),
  },
  overrides: {
    MuiContainer: {
      root: {
        [breakpoints.down('md')]: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
      maxWidthLg: {
        [breakpoints.up('lg')]: {
          maxWidth: '1366px',
        },
      },
    },
    MuiTab: {
      textColorPrimary: {
        MuiSelected: {
          color: '#212121',
        },
      },
    },
    MuiTypography: {
      h1: {
        fontSize: '35px',
        fontWeight: '400',
        lineHeight: '40px',
        marginBottom: '.6rem',
      },
      h2: {
        fontSize: '25px',
        fontWeight: '500',
        lineHeight: '32px',
      },
      h3: {
        fontSize: '21px',
        fontWeight: '500',
        lineHeight: '28px',
      },
      h4: {
        fontSize: '18px',
        lineHeight: '24px',
      },
      h5: {
        fontSize: '16px',
        lineHeight: '20px',
      },
      h6: {
        fontSize: '14px',
        lineHeight: '24px',
      },
      body1: {
        fontFamily: 'Public Sans',
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        color: '#757575',
        '&$checked': {
          color: '#018466',
          hover: {
            backgroundColor: 'rgba(1, 32, 101, .04)',
          },
          '&&:hover': {
            backgroundColor: 'rgba(1, 32, 101, .04)',
          },
        },
        '&&:hover': {
          backgroundColor: 'rgba(1, 32, 101, .04)',
        },
      },
    },
    MUIRichTextEditor: {
      root: {
        marginTop: 20,
        width: '100%',
      },
      container: {
        border: '1px solid #ccc',
        minHeight: '300px',
      },
      toolbar: {
        backgroundColor: '#f5f5f5',
        padding: '0.2rem',
      },
      editor: {
        borderTop: '1px solid #ccc',
        color: '#555',
        '& .public-DraftStyleDefault-block': {
          marginBottom: '1rem',
        },
        '& ul': {
          paddingLeft: '1rem',
        },
        '& ol': {
          paddingLeft: '1rem',
        },
      },
      editorContainer: {
        padding: '0.938rem',
      },
    },
    MuiButton: {
      root: {
        borderRadius: '20px',
        textTransform: 'none',
        // minWidth: '150px',
      },
      outlined: {
        backgroundColor: '#ffffff',
        '&&:hover': {
          backgroundColor: '#FCF7FC',
        },
        '&&:focused': {
          backgroundColor: '#F6E5F6',
        },
      },
    },
    MuiRadio: {
      colorSecondary: {
        color: '#018466',
        '&$checked': {
          color: '#018466',
        },
      },
    },
    MuiDialogTitle: {
      root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '1.6rem 2rem',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '0 2rem 1.6rem',
      },
    },
    MuiDialogActions: {
      root: {
        backgroundColor: '#eef4f8',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '1.6rem 2rem',
      },
    },
    MuiFormControlLabel: {
      root: {
        marginRight: '0px',
      },
    },
    MuiAccordion: {
      root: {
        boxShadow: 'none',
      },
    },
  },
  palette: {
    primary: {
      main: '#0a3a5a',
    },
    secondary: {
      main: '#bb29bb',
    },
    error: {
      main: '#b00020',
    },
    text: {
      primary: '#333333',
      secondary: '#555555',
    },
  },
});
