import { useMemo, useState } from 'react';

/**
 * Custom hook for managing a reactive form value. Wraps the useState hook and adds validation functionality.
 *
 * @param {string} initialValue - The initial value of the form field.
 * @param {Array<Function>} validationFunctionsList - An array of validation functions to validate the form value.
 * @returns {Object} - An object containing the form value and various utility functions.
 */
const useReactiveFormValue = (initialValue, validationFunctionsList) => {
  const [value, setValue] = useState(initialValue);

  const handleEventChange = (event) => {
    setValue(event.target.value);
  };

  const clearValue = () => {
    setValue('');
  };

  const isValid = useMemo(() => {
    if (!validationFunctionsList || validationFunctionsList.length === 0) {
      return true;
    }
    return validationFunctionsList.every((validationFunction) => validationFunction(value));
  }, [value, validationFunctionsList]);

  return {
    value,
    setValue,
    handleEventChange,
    clearValue,
    isValid,
  };
};

export default useReactiveFormValue;
