import { makeStyles, withStyles } from '@material-ui/styles';
import { DataGridPro } from '@mui/x-data-grid-pro';

export const useStyles = makeStyles((theme) => ({
  headingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: '47px', // Prevents the container from jumping around when bulk actions show/hide
  },
  actionButton: {
    margin: '8px 20px',
    minWidth: '50px',
    textTransform: 'none',
    transition: 'background-color 0.3s, color 0.3s, border-color 0.3s',
    '&.MuiButton-outlined:hover': {
      backgroundColor: '#0A3A5A',
      borderColor: '#0A3A5A',
      color: 'white',
    },
  },
  dangerButton: {
    color: '#ac1b3d',
    borderColor: '#ac1b3d',
    '&.MuiButton-outlined:hover': {
      backgroundColor: '#6B1126',
      borderColor: '#6B1126',
      color: 'white',
    },
  },
  tableContainer: {
    '& a': {
      color: theme.palette.primary.main,
      fontWeight: 500,
      textDecoration: 'none',
    },
  },
  table: {
    border: 'none',
  },
  emptyStateButton: {
    margin: '0 .8rem',
  },
  unfinished: {
    display: 'none',
  },
  grayInfoBox: {
    backgroundColor: '#ededed',
    display: 'flex',
    gap: '1rem',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '3rem 10rem',
    borderRadius: '4px',
    textAlign: 'center',
    minHeight: '200px',
    maxHeight: '200px',

    '& .MuiTypography-root': {
      color: '#999',

      '&.MuiTypography-colorError': {
        color: '#ac1b3d',
      },
    },
  },
  '@global': {
    [`.${customRowClassNames.hiddenRow}`]: {
      display: 'none !important',
    },
  },
}));

export const customRowClassNames = {
  hiddenRow: 'hidden-row',
};

// Dynamic row height style for DataGrid component
export const StyledDataGrid = withStyles({
  root: {
    '& .MuiDataGrid-renderingZone': {
      maxHeight: 'none !important',
    },
    '& .MuiDataGrid-cell': {
      lineHeight: 'unset !important',
      whiteSpace: 'normal',
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiDataGrid-row': {
      maxHeight: 'none !important',
    },
  },
})(DataGridPro);
