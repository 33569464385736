import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { customRowClassNames } from 'components/DataGrid';
import useSavedTableSettings from 'hooks/useSavedTableSettings';

const HIDDEN_ASCENDING_PLACEHOLDER_STRING = 'zzzzzzzzzzzzzzzz';
const HIDDEN_DESCENDING_PLACEHOLDER_STRING = 'aaaaaaaaaaaaaaaa';

export const useDataGridParams = ({ tableSettingsKey }) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [pageSelectedCount, setPageSelectedCount] = useState(0);
  const [totalSelectedCount, setTotalSelectedCount] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [
    filterModel,
    setFilterModel,
    sortModel,
    setSortModel,
    hiddenColumns,
    setHiddenColumns,
  ] = useSavedTableSettings({ key: tableSettingsKey });

  const onPageChange = useCallback((newPage) => {
    setPage(newPage);
  }, []);

  const onPageSizeChange = useCallback((newPageSize) => {
    setPageSize(newPageSize);
  }, []);

  /**
   * Updates the selected rows state when the selection model changes.
   * Persists selections across multiple pages while maintaining the ability to
   * select, unselect, and unselect all in the data grid.
   */
  const onSelectionModelChange = useCallback((newSelectionModel) => {
    const deselectedRows = selectedRows.filter(id => !newSelectionModel.includes(id));
    const newlySelectedRows = newSelectionModel.filter(id => !selectedRows.includes(id));

    const updatedSelectedRows = [
      ...selectedRows.filter(id => !deselectedRows.includes(id)),
      ...newlySelectedRows,
    ];

    setSelectedRows(updatedSelectedRows);
    setTotalSelectedCount(updatedSelectedRows.length);
    setPageSelectedCount(newSelectionModel.length);
  }, [selectedRows]);

  /**
   * Add hidden rows to the Data Grid for selected items not on the current page.
   *
   * These hidden rows will be excluded on data grid render as they will always be sorted to the
   * end, outside the bounds of the page size. This allows us to persist all selected rows in a
   * single state, regardless of which page they're on.
   *
   * Due to a bug in MUI v4, the onSelectionModelChange event incorrectly
   * fires on pagination events which prevents us from storing all selected rows separately.
   * https://github.com/mui/mui-x/issues/4676
   */
  const addHiddenSelectedRows = useCallback((data) => {
    // Bail if there's no data or it's not an array
    if (!data?.rows || !Array.isArray(data.rows)) {
      return [];
    }

    // If there's no selections, we don't need to add any hidden rows
    if (selectedRows.length === 0) {
      return data.rows;
    }

    // Get the IDs of the rows in the data
    const dataRowIds = data.rows.map(row => row.id);

    // Get the IDs of the selected rows that aren't in the data
    const missingRowIds = selectedRows.filter(id => !dataRowIds.includes(id));

    // Using the structure of the first data row, create a new row for each missing row with null
    // or empty values in each key. The ID is needed to match to the selected rows state. The
    // hidden property is needed to hide the row from the data grid.
    const missingRows = missingRowIds.map(id => {
      const template = data.rows[0];
      return {
        id,
        ...Object.keys(template).reduce((acc, key) => {
          if (key !== 'id') {
            acc[key] = null;
          }
          return acc;
        }, {}),
        hidden: true,
      };
    });

    setPageSelectedCount(data.rows.filter(row => !row.hidden && selectedRows.includes(row.id)).length || 0);

    setTotalSelectedCount(selectedRows.length);

    // Combine the data rows with the hidden, missing rows for selection persistance
    return [
      ...data.rows,
      ...missingRows,
    ];
  }, [selectedRows]);

  /**
   * Returns a placeholder string for hidden rows.
   * This ensures that regardless of the sort order, hidden rows are always shown as the last rows, effectively cutting them off from the data grid view.
   *
   * @returns {string} A placeholder string that will always sort last.
   */
  const getHiddenPlaceholder = () => {
    if (!sortModel[0]) {
      return '';
    }

    return sortModel[0]?.sort === 'asc' ? HIDDEN_ASCENDING_PLACEHOLDER_STRING : HIDDEN_DESCENDING_PLACEHOLDER_STRING;
  };

  /**
   * Determines the CSS class name for a data grid row based on its properties.
   *
   * @param {Object} params - The row parameters.
   * @param {Object} params.row - The row data.
   * @param {boolean} params.row.hidden - Indicates if the row is hidden.
   * @returns {string} The CSS class name for the row.
   */
  const getRowClassName = (params) => {
    if (params.row.hidden) {
      return customRowClassNames.hiddenRow;
    }
    return '';
  };

  return {
    addHiddenSelectedRows,
    filterModel,
    getHiddenPlaceholder,
    getRowClassName,
    onPageChange,
    onPageSizeChange,
    onSelectionModelChange,
    page,
    pageSelectedCount,
    pageSize,
    selectedRows,
    setFilterModel,
    setSortModel,
    sortModel,
    totalSelectedCount,
    hiddenColumns,
    setHiddenColumns,
  };
};

useDataGridParams.propTypes = {
  tableSettingsKey: PropTypes.string.isRequired,
};
