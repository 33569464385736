const TEXT_TYPE = 1;
const YOUTUBE_TYPE = 4;
const YOUTUBE_DIVIDER = '``';

class QuestionClass {
  // Field declaration
  errors = {};

  answerId = -1;

  answer = '';

  answerURL = '';

  answerText = '';

  translatedAnswers = {};

  id = -1;

  maxl = -1;

  translatedQuestions = {};

  type = -1;

  question = '';

  /**
   * Create object without answers from question JSON
   * @param {Object} question Question object from DB JSON response
   * @param {Object} answer Answer to the question, must match source id to question id
   */
  constructor(question, answer) {
    // default to populate later
    this.errors = {};

    // if the answer exists for this question
    if (typeof answer !== 'undefined') {
      if (answer.sourceId !== question.id) {
        throw new Error('Answer source does not match question id');
      }

      this.answerId = answer.id;
      this.answer = answer.translations.en;

      // handle youtube answer
      if (question.questionFormat.id == YOUTUBE_TYPE) {
        const splitAnswer = answer.translations.en.split(YOUTUBE_DIVIDER);
        if (splitAnswer.length == 2) {
          // linter is making me do this even though I think using indexes is more readable in this specific case
          // this.answerURL = splitAnswer[0]; this.answerText = splitAnswer[1];
          [this.answerURL, this.answerText] = splitAnswer;
        }
      }

      // handle text answer
      if (question.questionFormat.id == TEXT_TYPE) {
        this.translatedAnswers = answer.translations;
        // absolutely wild javascript syntax here, you learn something every day
        delete this.translatedAnswers.en;
      }
    }

    // available question values
    if (typeof question === 'undefined' || question == null) {
      throw new Error('Question is a required parameter');
    }

    try {
      this.id = question.id;
      this.maxl = question.maxQuestionLength;
      this.translatedQuestions = question.translations;
      this.type = question.questionFormat.id;
      this.question = question.body;
    } catch (e) {
      console.error(e);
      throw new Error('Question did not have all required fields');
    }
  }

  /**
   * Update the answer to this question for the given langugage ('en' for english)
   * @param {string} langugageId two char identifier for langugage that was changed
   * @param {*} newValue Updated value for this answer
   */
  updateAnswer(langugageId, newValue) {
    if (langugageId === 'en') {
      this.answer = newValue;
    } else {
      this.translatedAnswers[langugageId] = newValue;
    }
  }

  /**
   * Update the youtube url answer for this question
   * @param {string} url Youtube video url
   */
  updateYoutubeURL(url) {
    this.answerURL = url;
  }

  /**
   * Update the youtube description answer for this question
   * @param {string} description Youtube video description
   */
  updateYoutubeDescription(description) {
    this.answerText = description;
  }

  get apiBody() {
    const body = {
      questionId: this.id,
      translations: [],
      id: this.answerId,
    };

    if (this.type === TEXT_TYPE) {
      body.translations.push({
        key: 'en',
        text: this.answer,
      });
      Object.keys(this.translatedAnswers).forEach(locale => {
        body.translations.push({
          key: locale,
          text: this.translatedAnswers[locale],
        });
      });
    } else if (this.type === YOUTUBE_TYPE) {
      body.translations.push({
        key: 'en',
        text: `${this.answerURL}${YOUTUBE_DIVIDER}${this.answerText}`,
      });
    } else {
      body.translations.push({
        key: 'en',
        text: this.answer,
      });
    }

    return body;
  }

  get reviewCard() {
    if (this.type !== YOUTUBE_TYPE) {
      return { label: this.question, value: this.answer };
    }
    return { label: this.question, value: this.answerURL };
  }
}

export default QuestionClass;
