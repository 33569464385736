import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import { useReorderChild } from 'hooks/useReorder';

const useStyles = makeStyles({
  disabled: {
    cursor: 'not-allowed',
  },
  root: {
    padding: '12px 16px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  actionButtons: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  expandIcon: {
    transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
});

const Category = ({
  name,
  description,
  disabled,
  expanded,
  id,
  index,
  moveCategory,
  onClick,
  onFailedDrop,
  onReorder,
}) => {
  const classes = useStyles();

  // ABSTRACTION!
  const {
    handlerId, ref, opacity,
  } = useReorderChild({
    index,
    moveFunction: moveCategory,
    onReorder,
    id,
    disabled,
    onFailedDrop,
  });

  const getReorderIcon = () => {
    // if not uncategorized add re order icon
    if (id >= 0) {
      return (<DragHandleIcon color={disabled ? 'disabled' : 'inherit'} className={classes.iconPlacement} />);
    }
  };

  return (
    <div
      data-handler-id={handlerId}
      ref={ref}
      style={{ width: '100%' }}
      className={classes.root}
      onClick={() => onClick(id)}
    >
      <Grid
        className={disabled ? 'disabled' : ''}
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
        style={{ opacity }}
      >
        <Grid item xs={10}>
          <Grid
            container
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} md={4}>
              <Typography variant="h6">{name}</Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography variant="body1">{description}</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={2}>
          <Grid container alignItems="center" justifyContent="flex-end" className={classes.actionButtons}>
            <Grid item xs={6} style={{ textAlign: 'right' }}>
              <ExpandMoreIcon
                className={classes.expandIcon}
                color={disabled ? 'disabled' : 'inherit'}
                style={expanded ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }}
              />
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'right' }}>
              {getReorderIcon(id)}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

Category.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  expanded: PropTypes.bool,
  id: PropTypes.number,
  index: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  onReorder: PropTypes.func.isRequired,
  moveCategory: PropTypes.func.isRequired,
  onFailedDrop: PropTypes.func,
};

Category.defaultProps = {
  description: '',
  expanded: false,
  onClick: () => {},
  onFailedDrop: () => {},
};

export default Category;
