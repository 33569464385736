import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Box, Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  container: {
    minWidth: '100px',
  },
  heading: {
    fontWeight: 700,
    marginBottom: '4px',
  },
  icon: {
    color: '#94a8b2',
    fontSize: '1.375rem',
    marginLeft: '4px',
  },
  body: {
    display: 'flex',
    flexWrap: 'wrap',
  },
});

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#fff',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.2)',
    border: `1px solid ${theme.palette.primary.main}`,
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(12),
    marginTop: 5,
  },
}))(Tooltip);

function KPI({
  helpText, helpTitle, title, total, value,
}) {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography className={classes.heading} component="p" color="primary" variant="h1">
        {total == null ? value : `${value}/${total}`}
      </Typography>

      <div className={classes.body}>
        <Typography component="p" variant="body1">
          {title}
        </Typography>

        {(helpText || helpTitle)
          && <HtmlTooltip
            title={
              <React.Fragment>
                <Typography>{helpTitle}</Typography>
                {helpText}
              </React.Fragment>
            }
          >
            <InfoIcon className={classes.icon} />
          </HtmlTooltip>
        }
      </div>
    </Box>
  );
}

KPI.propTypes = {
  helpText: PropTypes.string,
  helpTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  total: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

KPI.defaultProps = {
  helpText: '',
  helpTitle: '',
  title: '',
  total: null,
  value: 0,
};

export default KPI;
