import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { DataGridPro, LicenseInfo } from '@mui/x-data-grid-pro';

// Dynamic row height style for DataGrid component
const StyledDataGrid = withStyles({
  root: {
    '& .MuiDataGrid-renderingZone': {
      maxHeight: 'none !important',
    },
    '& .MuiDataGrid-cell': {
      lineHeight: 'unset !important',
      maxHeight: 'none !important',
      whiteSpace: 'normal',
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiDataGrid-row': {
      maxHeight: 'none !important',
    },
  },
})(DataGridPro);

LicenseInfo.setLicenseKey(
  '5354fa8b30068069673634540842cbf3T1JERVI6MzU2NzcsRVhQSVJZPTE2NzM0NzU4MDAwMDAsS0VZVkVSU0lPTj0x',
);

const styles = {
  formContainer: {
    padding: '0rem 2rem 90px',
  },
  addFormActions: {
    backgroundColor: '#eef4f8',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1.5rem 2rem',
    width: '100%',
    position: 'absolute',
    bottom: '0',
  },
  cardContainerNoOutline: {
    border: 'none',
    boxShadow: 'none',
    overflow: 'hidden',
  },
  inputField: {
    margin: '1.3rem 0rem',
  },
  defaultContainer: {
    marginTop: '3rem',
  },
  defaultList: {
    marginTop: '0.5rem',
  },
  availableContainer: {
    marginTop: '3rem',
  },
  tableContainer: {
    margin: '0',
    width: '100%',
    marginTop: '0.5rem',
    marginBottom: '2rem',
  },
};

const columns = [
  { field: 'name', headerName: 'Name', width: 400 },
  { field: 'type', headerName: 'Type', width: 200 },
];

class BioQuestionSetForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: props.name,
      selectedQuestions: [],
      pageSize: 10,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSelectionModalChange = this.handleSelectionModalChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const { name, value } = event.target;

    if (name === 'name') {
      this.setState({
        name: value,
      });
    }
  }

  handleSelectionModalChange(newSelectedQuestions) {
    this.setState({
      selectedQuestions: newSelectedQuestions,
    });
  }

  handleSubmit() {
    const { id, onSubmit } = this.props;
    const { name, selectedQuestions } = this.state;
    const questions = selectedQuestions.map((questionId, index) => ({
      id: questionId,
      index,
    }));

    onSubmit({ id, name, questions });
  }

  getDefaultQuestions() {
    const { questions } = this.props;
    return questions.filter((q) => q.default === true);
  }

  getLeagueQuestions() {
    const { questions } = this.props;
    return questions.filter((q) => q.default !== true);
  }

  hasError(fieldName) {
    const { errors } = this.props;
    return Object.prototype.hasOwnProperty.call(errors, fieldName);
  }

  renderDefaultQuestions() {
    const { classes } = this.props;
    const defaultQuestions = this.getDefaultQuestions();

    return defaultQuestions.map((q, index) => (
      <Grid item xs={12} sm={6} key={index}>
        <p className={classes.displayText}> {q.name} </p>
      </Grid>
    ));
  }

  render() {
    const {
      children,
      classes,
      defaultQuestionsHeader,
      errors,
      outlined,
      questionTableHeader,
      requiredText,
      submitButtonText,
      submitting,
    } = this.props;
    const { name, pageSize } = this.state;
    const leagueQuestions = this.getLeagueQuestions();
    const cardContainerClass = outlined ? '' : classes.cardContainerNoOutline;

    return (
      <Card variant="outlined" className={cardContainerClass}>
        <CardContent className={classes.formContainer}>
          <Grid container className={classes.inputField}>
            <Grid item xs={12} sm={8}>
              <FormControl fullWidth>
                <TextField
                  id="name"
                  disabled={submitting}
                  error={this.hasError('name')}
                  helperText={this.hasError('name') ? errors.name : ''}
                  label="Bio Question Set Name"
                  name="name"
                  required
                  variant="filled"
                  onChange={this.handleInputChange}
                  value={name}
                  inputProps={{ maxLength: 60 }}
                />
              </FormControl>
            </Grid>
          </Grid>

          <div className={classes.defaultContainer}>
            <Typography component="h2" variant="h3">
              {defaultQuestionsHeader}
            </Typography>

            <div>
              <Grid container spacing={2} className={classes.defaultList}>
                {this.renderDefaultQuestions()}
              </Grid>
            </div>
          </div>

          <div className={classes.availableContainer}>
            <Typography component="h2" variant="h3">
              {questionTableHeader}
            </Typography>

            <div className={classes.tableContainer}>
              <StyledDataGrid
                autoHeight
                rows={leagueQuestions}
                columns={columns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                onSelectionModelChange={this.handleSelectionModalChange}
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                pagination
                checkboxSelection
              />
            </div>
          </div>
        </CardContent>

        <CardActions className={classes.addFormActions}>
          <Typography component="p" variant="body2">
            {requiredText}
          </Typography>

          <div>
            {children}

            <Button
              variant="contained"
              color="secondary"
              onClick={this.handleSubmit}
              size="large"
              disabled={submitting}
            >
              {submitButtonText}
            </Button>
          </div>
        </CardActions>
      </Card>
    );
  }
}

BioQuestionSetForm.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  defaultQuestionsHeader: PropTypes.string,
  errors: PropTypes.object,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  outlined: PropTypes.bool,
  questions: PropTypes.arrayOf(PropTypes.shape({
    default: PropTypes.bool,
    id: PropTypes.number,
    index: PropTypes.number,
    maxl: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.string,
  })),
  questionTableHeader: PropTypes.string,
  requiredText: PropTypes.string,
  submitButtonText: PropTypes.string,
  submitting: PropTypes.bool,
};

BioQuestionSetForm.defaultProps = {
  defaultQuestionsHeader: 'Default biographical questions',
  errors: {},
  id: '',
  name: '',
  onSubmit: () => {},
  outlined: false,
  questions: [],
  questionTableHeader: 'Available biographical questions',
  requiredText: '*Required fields needed to create a new question set',
  submitButtonText: 'Create Set',
  submitting: false,
};

export default withStyles(styles)(BioQuestionSetForm);
