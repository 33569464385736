import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles({
  setInfoContainer: {
    marginBottom: '1.25rem',
  },
  listContainer: {
    paddingTop: '0.5rem',
  },
  itemRows: {
    paddingBottom: '0.938rem',
    marginBottom: '0.938rem',
    borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
    '&:last-child': {
      borderBottom: 'none',
      paddingBottom: '0rem',
      marginBottom: '0rem',
    },
  },
});

function ListCard(props) {
  const classes = useStyles();

  const {
    title,
    setInfo,
    setItemLabel,
    setItems,
    onEdit,
    editScreen,
    editButtonText,
    emptyStateText,
  } = props;

  const itemsArray = setItems.map((item) => (
    <Grid item key={item.id} className={classes.itemRows}>
      <p className="card-value">{item.name}</p>
    </Grid>
  ));

  const handleEditClick = () => {
    onEdit(editScreen);
  };

  return (
    <Paper elevation={3} className="card-container">
      <div className="card-header-container">
        <Typography color="primary" component="h3" variant="h3">{title}</Typography>

        <Button
          variant="outlined"
          color="secondary"
          size="large"
          onClick={handleEditClick}
        >
          {editButtonText}
        </Button>
      </div>

      <div className={classes.setInfoContainer}>
        <p className="mdc-typography--paragraph card-label">
          {setInfo.label}
        </p>

        {setInfo.value === null ? (
          <p className="mdc-typography--paragraph card-reminder-text">
            {emptyStateText}
          </p>
        ) : (
          <p className="mdc-typography--paragraph card-value">
            {setInfo.value}
          </p>
        )}
      </div>

      <p className="mdc-typography--paragraph card-label">
        {setItemLabel}
      </p>

      {itemsArray === null || itemsArray.length === 0 ? (
        <p className="mdc-typography--paragraph card-reminder-text">
          {emptyStateText}
        </p>
      ) : (
        <div className={classes.listContainer}>
          {itemsArray}
        </div>
      )}
    </Paper>
  );
}

ListCard.propTypes = {
  title: PropTypes.string,
  setInfo: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  setItemLabel: PropTypes.string,
  setItems: PropTypes.array,
  onEdit: PropTypes.func,
  editScreen: PropTypes.number,
  editButtonText: PropTypes.string,
  emptyStateText: PropTypes.string,
};

ListCard.defaultProps = {
  title: 'Default title',
  setInfo: {},
  setItemLabel: 'Default label',
  setItems: [],
  onEdit: () => {},
  editScreen: 5,
  editButtonText: 'Edit',
  emptyStateText: 'No Answer',
};

export default ListCard;
