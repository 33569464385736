import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Wrapper } from '@googlemaps/react-wrapper';
import GISDistrictBulkNameEditTools from 'components/Districts/Partials/GISDistrictBulkNameEditTools';
import DistrictMap from 'components/Districts/Partials/DistrictMap';
import GISUploadedDistrictList from 'components/Districts/Partials/GISUploadedDistrictList';
import EmptyState from 'components/EmptyState';

const MAP_DISTRICT_LIMIT = 200;

const useStyles = makeStyles({
  mapContainer: {
    position: 'sticky',
    top: '150px',
    left: 0,
  },
  map: {
    marginBottom: '.4rem',
  },
});

const GISDistrictBulkEditForm = ({
  allDistrictsExpanded,
  allDistrictsSelected,
  districts,
  errors,
  gmapApiKey,
  metadataValues,
  noDistrictsSelected,
  onAddPrefix,
  onAddSuffix,
  onDistrictChange,
  onDistrictSelect,
  onDistrictSelectAll,
  onReplaceName,
  onToggleMetadata,
  onToggleAllMetadata,
  submitting,
}) => {
  const classes = useStyles();
  const _districtList = useRef();
  const [showMap, setShowMap] = useState(true);

  useEffect(() => {
    if (districts.length > MAP_DISTRICT_LIMIT) {
      setShowMap(false);
    }
  }, [districts]);

  const handleDistrictClick = (id) => {
    const matchIndex = districts.findIndex(d => d.id == id);

    if (matchIndex >= 0) {
      _districtList.current.scrollToItem(matchIndex, 'smart');
    }
  };

  const MapHiddenMessage = () => (
    <EmptyState>
      <p className="ga-m-bottom--large">
        The ShapeFile map is hidden for GIS data that contains more than <strong>{MAP_DISTRICT_LIMIT} districts</strong> to maintain better page performance.
      </p>

      <Button
        color="primary"
        onClick={() => setShowMap(true)}
        size="large"
        variant="outlined"
      >
        Show map
      </Button>
    </EmptyState>
  );

  const MemoizedMap = useMemo(
    () => (
      <div className={classes.mapContainer}>
        <DistrictMap
          className={classes.map}
          districts={districts}
          onDistrictClick={handleDistrictClick}
          toggleableDistricts
        />

        <Button size="small" color="primary" onClick={() => setShowMap(false)}>
          Hide map
        </Button>
      </div>
    ),
    [districts],
  );

  const MemoizedDistrictList = useMemo(
    () => (
      <GISUploadedDistrictList
        allDistrictsExpanded={allDistrictsExpanded}
        allDistrictsSelected={allDistrictsSelected}
        districts={districts}
        errors={errors}
        noDistrictsSelected={noDistrictsSelected}
        onInputChange={onDistrictChange}
        onSelect={onDistrictSelect}
        onSelectAll={onDistrictSelectAll}
        onToggleMetadata={onToggleMetadata}
        onToggleAllMetadata={onToggleAllMetadata}
        ref={_districtList}
        submitting={submitting}
      />
    ),
    [allDistrictsSelected, districts, errors, noDistrictsSelected],
  );

  return (
    <Wrapper apiKey={gmapApiKey}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" component="h2">
            Shapefile Map
          </Typography>

          {showMap ? MemoizedMap : MapHiddenMessage()}
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h6" component="h2">
            Bulk Edit Uploaded District Names
          </Typography>

          <GISDistrictBulkNameEditTools
            metadataValues={metadataValues}
            onAddPrefix={onAddPrefix}
            onAddSuffix={onAddSuffix}
            onReplaceName={onReplaceName}
          />

          {MemoizedDistrictList}
        </Grid>
      </Grid>
    </Wrapper>
  );
};

GISDistrictBulkEditForm.propTypes = {
  allDistrictsExpanded: PropTypes.bool,
  allDistrictsSelected: PropTypes.bool,
  districts: PropTypes.arrayOf(PropTypes.shape({
    coordinates: PropTypes.array,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
    selected: PropTypes.bool,
  })),
  errors: PropTypes.object,
  gmapApiKey: PropTypes.string.isRequired,
  metadataValues: PropTypes.array,
  noDistrictsSelected: PropTypes.bool,
  onDistrictChange: PropTypes.func,
  onDistrictSelect: PropTypes.func,
  onDistrictSelectAll: PropTypes.func,
  onAddPrefix: PropTypes.func,
  onAddSuffix: PropTypes.func,
  onReplaceName: PropTypes.func,
  onToggleMetadata: PropTypes.func,
  onToggleAllMetadata: PropTypes.func,
  submitting: PropTypes.bool,
};

GISDistrictBulkEditForm.defaultProps = {
  allDistrictsExpanded: true,
  allDistrictsSelected: true,
  districts: [],
  errors: {},
  metadataValues: [],
  noDistrictsSelected: false,
  onDistrictChange: () => {},
  onDistrictSelect: () => {},
  onDistrictSelectAll: () => {},
  onAddPrefix: () => {},
  onAddSuffix: () => {},
  onReplaceName: () => {},
  onToggleMetadata: () => {},
  onToggleAllMetadata: () => {},
  submitting: false,
};

export default React.memo(GISDistrictBulkEditForm);
