import React from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { useStyles } from './DestructiveButton.styles';

const DestructiveButton = ({ children, buttonProps }) => {
  const classes = useStyles();

  return (
    <Button className={classes.destructiveButton} {...buttonProps}>
      {children}
    </Button>
  );
};

DestructiveButton.propTypes = {
  children: PropTypes.node,
  buttonProps: PropTypes.object,
};

DestructiveButton.defaultProps = {
  children: null,
  buttonProps: {},
};

export {
  DestructiveButton,
};
