import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Box,
  FormControl,
  TextField,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import RadioCardList from 'components/RadioCardList';
import stateErrors from 'helpers/StateErrors';

const useStyles = makeStyles({
  container: {
    borderBottom: '1px solid rgba(0,0,0,0.1)',
  },
});

const VotingOptions = ({
  votingOptions: VOTING_OPTIONS,
  votingOption,
  setVotingOption,
  numberOfCandidates,
  setNumberOfCandidates,
  votingOptionsErrors,
  messages,
}) => {
  const classes = useStyles();

  const handleChange = newValue => {
    setNumberOfCandidates('');
    setVotingOption(newValue);
  };

  const getFormValue = (event) => {
    if (event.target.name == 'editable' || event.target.name == 'visible') {
      return event.target.checked;
    }
    return event.target.value;
  };

  // form state
  const handleInputChange = (event) => {
    const { name } = event.target;
    const value = getFormValue(event);
    if (name == 'number_of_candidates') {
      setNumberOfCandidates(value);
    }
  };

  return (
    <Box paddingBottom={5} marginBottom={5} className={classes.container}>
      <Typography variant="h2" component="h3" paragraph>
        {messages.title}
      </Typography>

      <Typography variant="body1" component="p" paragraph>
        {messages.supporting}
      </Typography>

      <RadioCardList
        cards={VOTING_OPTIONS}
        checked={votingOption}
        onChange={handleChange}
      />

      {/* Make sure this voting option has a follow-up question */}
      {VOTING_OPTIONS[votingOption].additionalQuestion && (
        <Box marginTop={3}>
          <Typography variant="body1" component="p">
            {VOTING_OPTIONS[votingOption].additionalQuestion}
          </Typography>
          <Grid container>
            {/* Width control */}
            <Grid item xs={12} sm={8} md={4}>
              <FormControl fullWidth>
                <TextField
                  id="how-many-candidates"
                  disabled={false}
                  error={stateErrors.hasError('numberOfCandidates', votingOptionsErrors)}
                  helperText={messages.additionalQuestion.help}
                  label={messages.additionalQuestion.label}
                  InputProps={{ inputProps: { min: 0 } }}
                  name="number_of_candidates"
                  required
                  variant="filled"
                  value={numberOfCandidates}
                  onChange={handleInputChange}
                  type='number'
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

VotingOptions.propTypes = {
  votingOptions: PropTypes.array.isRequired,
  votingOption: PropTypes.number.isRequired,
  setVotingOption: PropTypes.func.isRequired,
  messages: PropTypes.object.isRequired,
  numberOfCandidates: PropTypes.string,
  setNumberOfCandidates: PropTypes.func,
  votingOptionsErrors: PropTypes.object.isRequired,
};

VotingOptions.defaultProps = {
  setNumberOfCandidates: () => {},
};

export default VotingOptions;
