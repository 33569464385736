import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createStyles, withStyles } from '@material-ui/styles';
import {
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import PageHeader from 'components/PageHeader';
import PageActionsFooter from 'components/PageActionsFooter';
import GISDistrictBulkEditForm from 'components/Districts/Partials/GISDistrictBulkEditForm';
import fetchUtil from 'helpers/Fetch';
import GisHelper from 'helpers/GisHelper';

const styles = (theme) => createStyles({
  divider: {
    borderBottom: '1px solid #e5e5e5',
    margin: '2rem 0',
  },
  field: {
    marginBottom: '1.2rem',
  },
  submitButton: {
    minWidth: '8rem',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
});

const GISDistrictCreateContainer = ({
  classes,
  containingDistricts,
  gisFeatures,
  guideId,
  heading,
  mapApiKey,
  name,
  onError,
  onSuccess,
  stateId,
  subheading,
}) => {
  const [containingDistrict, setContainingDistrict] = useState('');
  const [districts, setDistricts] = useState([]);
  const [allDistrictsSelected, setAllDistrictsSelected] = useState(true);
  const [noDistrictsSelected, setNoDistrictsSelected] = useState(false);
  const [errors, setErrors] = useState({});
  const [districtSetName, setDistrictSetName] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [metadataOptions, setMetadataOptions] = useState([]);
  const [allDistrictsExpanded, setAllDistrictsExpanded] = useState(false);

  useEffect(() => {
    const { features, extras } = gisFeatures;

    // The application currently provides district information in two objects:
    // An object of ids + district metadata and an object of ids + an array of
    // lat/lng coordinates. We combine them into a unified array of district objects
    // to preserve some of our sanity later.
    setDistricts(GisHelper.convertRawDistrictData(features, extras));
  }, [gisFeatures]);

  useEffect(() => {
    if (districts.length > 0 && Object.prototype.hasOwnProperty.call(districts[0], 'metadata')) {
      setMetadataOptions(Object.keys(districts[0].metadata));
    }

    setAllDistrictsExpanded(allDistrictsAreExpanded(districts));
  }, [districts]);

  useEffect(() => {
    setDistrictSetName(name);
  }, [name]);

  useEffect(() => {
    setContainingDistrict(stateId);
  }, [stateId]);

  const hasError = fieldName => Object.prototype.hasOwnProperty.call(errors, fieldName);

  const allDistrictsAreExpanded = () => {
    if (districts.length < 0) {
      return false;
    }

    const collapsedDistrict = districts.find(d => (!Object.prototype.hasOwnProperty.call(d, 'expanded') || d.expanded == false));

    if (collapsedDistrict) {
      return false;
    }

    return true;
  };

  const handleSetNameChange = event => {
    const { value } = event.target;
    setDistrictSetName(value);
  };

  const handleContainingDistrictChange = event => {
    const { value } = event.target;
    setContainingDistrict(value);
  };

  const handleSaveAndExit = () => {
    const selectedDistricts = [];

    setSubmitting(true);

    districts.forEach(d => {
      if (d.selected) {
        selectedDistricts.push({
          name: d.name,
          geometry: d.polyGeometry,
        });
      }
    });

    const data = {
      containerId: containingDistrict,
      name: districtSetName,
      geoDistricts: selectedDistricts,
    };

    fetchUtil(`/api/v1/guide/${guideId}/district/gis`, 'POST', data)
      .then(() => {
        setSubmitting(false);
        onSuccess();
      })
      .catch(error => {
        let message = 'There was an issue submitting your GIS District Set. Please try again and notify your guide admin if this issue continues.';

        console.error(error);

        // If we get an error back with a bunch of object keys, we assume it's a validation error
        if (error && typeof error === 'object' && Object.keys(error).length > 0) {
          message = 'There were some issues with the values in your district set. Fix any validation errors and try again.';
        }

        setSubmitting(false);
        setErrors(error);
        onError(message);
      });
  };

  const handleAddPrefix = prefix => {
    const newDistricts = districts.map(district => {
      if (district.selected) {
        district.name = `${prefix}${district.name}`;
      }

      return district;
    });

    setDistricts(newDistricts);
  };

  const handleAddSuffix = suffix => {
    const newDistricts = districts.map(district => {
      if (district.selected) {
        district.name = `${district.name}${suffix}`;
      }

      return district;
    });

    setDistricts(newDistricts);
  };

  const handleDistrictNameChange = newName => {
    const newDistricts = districts.map(district => {
      if (district.selected && Object.prototype.hasOwnProperty.call(district, 'metadata') && Object.prototype.hasOwnProperty.call(district.metadata, newName)) {
        district.name = district.metadata[newName];
      }

      return district;
    });

    setDistricts(newDistricts);
  };

  const handleDistrictInputChange = (id, value) => {
    const matchIndex = districts.findIndex(district => district.id === id);

    if (matchIndex >= 0) {
      const newDistricts = districts.slice();
      newDistricts[matchIndex].name = value;
      setDistricts(newDistricts);
    }
  };

  const handleDistrictSelect = id => {
    const newDistricts = districts.slice();
    let newAllDistrictsSelectedState = true;
    let newNoDistrictsSelectedState = true;

    newDistricts.map(district => {
      if (district.id === id) {
        district.selected = !district.selected;
      }

      if (district.selected == true) {
        // If any districts are selected, we don't need to show a warning about
        // trying to import with no districts
        newNoDistrictsSelectedState = false;
      } else {
        // If a district is unselected and our "select all" state is set to true
        // we need to uncheck it
        newAllDistrictsSelectedState = false;
      }

      return district;
    });

    if (newAllDistrictsSelectedState !== allDistrictsSelected) {
      setAllDistrictsSelected(newAllDistrictsSelectedState);
    }

    if (newNoDistrictsSelectedState !== noDistrictsSelected) {
      setNoDistrictsSelected(newNoDistrictsSelectedState);
    }

    setDistricts(newDistricts);
  };

  const handleDistrictSelectAll = checked => {
    const newDistricts = districts.slice().map(district => {
      district.selected = checked;
      return district;
    });

    if (checked == false) {
      setNoDistrictsSelected(true);
    } else {
      setNoDistrictsSelected(false);
    }

    setAllDistrictsSelected(checked);
    setDistricts(newDistricts);
  };

  const handleToggleMetadata = (id, expanded) => {
    const districtsCopy = districts.slice();
    const matchingIndex = districtsCopy.findIndex(d => d.id == id);

    if (matchingIndex >= 0) {
      districtsCopy[matchingIndex].expanded = expanded;
    }

    setDistricts(districtsCopy);
    setAllDistrictsExpanded(allDistrictsAreExpanded(districtsCopy));
  };

  const handleToggleAllMetadata = () => {
    const districtsCopy = districts.slice();
    districtsCopy.forEach(d => {
      d.expanded = !allDistrictsExpanded;
    });

    setDistricts(districtsCopy);
    setAllDistrictsExpanded(!allDistrictsExpanded);
  };

  return (
    <div>
      <Backdrop className={classes.backdrop} open={submitting}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <PageHeader
        heading={heading}
        subheading={subheading}
      />

      <div className="ga-container">
        <div className="mdc-layout-grid">
          <div className="mdc-layout-grid__inner">
            <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">

              <Grid container>
                <Grid item xs={12} md={5}>
                  <Typography variant="h6" component="h2">
                    Set Information
                  </Typography>

                  <FormControl fullWidth className={classes.field}>
                    <TextField
                      id={name}
                      disabled={submitting}
                      error={hasError('name')}
                      helperText={hasError('name') ? errors.name : 'i.e. New Jersey school districts'}
                      label="GIS District Set Name"
                      onChange={handleSetNameChange}
                      name="name"
                      required
                      value={districtSetName}
                    />
                  </FormControl>

                  <FormControl
                    error={hasError('containingDistrict')}
                    disabled={submitting}
                    fullWidth
                    required
                    variant="filled"
                  >
                    <InputLabel id="containing-district-label">
                      Containing District
                    </InputLabel>

                    <Select
                      id="containing-district-select"
                      error={hasError('containingDistrict')}
                      name="containingDistrict"
                      value={containingDistrict}
                      onChange={handleContainingDistrictChange}
                    >
                      {containingDistricts.map(dist => (<MenuItem value={dist.id} key={dist.id}>{dist.name}</MenuItem>))}
                    </Select>

                    <FormHelperText>{hasError('containingDistrict') ? errors.containingDistrict : ''}</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>

              <div className={classes.divider}></div>

              <GISDistrictBulkEditForm
                allDistrictsExpanded={allDistrictsExpanded}
                allDistrictsSelected={allDistrictsSelected}
                districts={districts}
                errors={errors}
                gmapApiKey={mapApiKey}
                metadataValues={metadataOptions}
                noDistrictsSelected={noDistrictsSelected}
                onAddPrefix={handleAddPrefix}
                onAddSuffix={handleAddSuffix}
                onDistrictChange={handleDistrictInputChange}
                onDistrictSelect={handleDistrictSelect}
                onDistrictSelectAll={handleDistrictSelectAll}
                onReplaceName={handleDistrictNameChange}
                onToggleMetadata={handleToggleMetadata}
                onToggleAllMetadata={handleToggleAllMetadata}
                submitting={submitting}
              />
            </div>
          </div>
        </div>
      </div>

      <PageActionsFooter sticky>
        <Button
          className={classes.cancelButton}
          color="primary"
          href="/districts"
          size="small"
        >
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={submitting || noDistrictsSelected}
          onClick={handleSaveAndExit}
          size="medium"
          variant="contained"
        >
          Save & Exit
        </Button>
      </PageActionsFooter>
    </div>
  );
};

GISDistrictCreateContainer.propTypes = {
  classes: PropTypes.object,
  containingDistricts: PropTypes.array.isRequired,
  gisFeatures: PropTypes.shape({
    extras: PropTypes.object.isRequired,
    features: PropTypes.object.isRequired,
    schemaName: PropTypes.string, // source file name
  }).isRequired,
  guideId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  heading: PropTypes.string,
  mapApiKey: PropTypes.string.isRequired,
  name: PropTypes.string,
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
  stateId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  subheading: PropTypes.string,
};

GISDistrictCreateContainer.defaultProps = {
  heading: 'Create a GIS District Set',
  name: '',
  onError: () => {},
  onSuccess: () => {},
  stateId: '',
  subheading: 'Quick and easy, GIS (Geographical Information System) Data is the most efficient and straightforward approach to creating your districts.',
};

export default withStyles(styles)(GISDistrictCreateContainer);
