import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

import MDCGrid from './MDCGrid';

/**
 * Container component to replace the 4 lines of divs we have all over the place.
 */
const GAContainer = ({ children }) => (
  <Box className='ga-container'>
    <MDCGrid>
      {children}
    </MDCGrid>
  </Box>
);

GAContainer.propTypes = {
  children: PropTypes.node,
};

GAContainer.defaultProps = {
  children: null,
};

export default GAContainer;
