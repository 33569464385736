import PropTypes from 'prop-types';

export const CandidatePropTypes = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  parties: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })),
  race: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
}).isRequired;

export const campaignFinanceMessagesProps = {
  header: {
    breadcrumb: 'Campaign Finance Records',
    eyebrow: 'Match Campaign Finance Record',
    metadata: {
      party: 'Party',
      race: 'Race',
      source: 'Source',
    },
  },
  filter: {
    title: 'Search Voter Guide Candidates',
    label: 'Candidate Name',
    placeholder: 'Enter candidate name',
    submit: 'Find Matches',
  },
  unmatched: {
    title: 'Unmatched Candidates',
    table: {
      header: {
        name: 'Name',
        party: 'Party',
        race: 'Race/Initiaive',
        category: 'Category',
        guide: 'Guide',
        actions: 'Actions',
      },
      actions: {
        match: 'Match to data',
      },
    },
    empty: 'There are no unmatched candidates that match your filters.',
  },
  matched: {
    title: 'Matched Candidates',
    table: {
      header: {
        name: 'Name',
        party: 'Party',
        race: 'Race/Initiative',
        category: 'Category',
        guide: 'Guide',
        actions: 'Actions',
      },
      actions: {
        unmatch: 'Remove match',
      },
    },
    empty: 'There are no previously matched candidates that match your filters.',
  },
  matchError: 'An error occurred while matching candidates.',
  matchSuccess: 'Candidates matched successfully!',
  error: 'An error occurred while loading data.',
};

export const candidateMessagesProps = {
  header: {
    breadcrumb: 'Candidate Profile',
    match: 'Match Voter Guide Candidate',
    party: 'Party',
    race: 'Race',
  },
  heading: 'Search Campaign Finance Candidates',
  search: {
    label: 'Candidate Name',
    placeholder: 'Enter candidate name',
    find: 'Find Matches',
  },
  table: {
    heading: 'Unmatched Campaign Finance Candidates',
    column: {
      name: 'Name',
      party: 'Party',
      race: 'Race/Initiative',
      import: 'Import Date/Time',
      source: 'Source',
      sourceYear: 'Source Year',
      actions: 'Actions',
    },
    action: {
      match: 'Match',
      delete: 'Delete',
      deleteAll: 'Delete All',
    },
    empty: 'There are no unmatched candidates for your search term.',
  },
  error: 'There was an error retrieving the candidate list. Please try again.',
  matchError: 'There was an error connecting the two candidates. Please try again.',
};

export const candidateMessagesPropTypes = PropTypes.shape({
  header: PropTypes.shape({
    breadcrumb: PropTypes.string,
    match: PropTypes.string,
    party: PropTypes.string,
    race: PropTypes.string,
  }),
  heading: PropTypes.string,
  search: PropTypes.shape({
    label: PropTypes.string,
    placeholder: PropTypes.string,
    find: PropTypes.string,
  }),
  table: PropTypes.shape({
    heading: PropTypes.string,
    column: PropTypes.shape({
      name: PropTypes.string,
      party: PropTypes.string,
      race: PropTypes.string,
      import: PropTypes.string,
      source: PropTypes.string,
      sourceYear: PropTypes.string,
      actions: PropTypes.string,
    }),
    action: PropTypes.shape({
      match: PropTypes.string,
      delete: PropTypes.string,
      deleteAll: PropTypes.string,
    }),
    empty: PropTypes.string,
  }),
  error: PropTypes.string,
  matchError: PropTypes.string,
});

export const FETCH_TYPE_MATCHED = 'matched';

export const FETCH_TYPE_UNMATCHED = 'unmatched';
