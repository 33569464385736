import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@material-ui/core';
import TabPanel from 'components/TabPanel';
import a11yProps from 'helpers/A11yProps';
import stateErrors from 'helpers/StateErrors';

const useStyles = makeStyles({
  questionLabel: {
    fontSize: '.9rem',
    marginBottom: '.4rem',
  },
});

const QuestionTextField = ({
  answer,
  errors,
  id,
  label,
  languages,
  maxl,
  onChange,
  submitting,
  translatedAnswers,
  translatedQuestions,
}) => {
  const TAB_ID = `language-tabs-${id}`;
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (langId, event) => {
    onChange(id, langId, event.target.value);
  };

  const getCharCount = (enteredAnswer, max) => {
    if (enteredAnswer) {
      return `${enteredAnswer.length}/${max}`;
    }
    return `0/${max}`;
  };

  const renderTabs = () => {
    let languageTabs = [];

    if (languages && languages.length > 0) {
      languageTabs = languages.map((lang, index) => (
        <Tab
          key={`${id}-${lang.id}`}
          label={lang.label}
          {...a11yProps.tabs(index + 1, TAB_ID)}
        />
      ));
    }

    return (
      <Tabs
        aria-label="Answers by language"
        indicatorColor="secondary"
        onChange={(event, newVal) => setActiveTab(newVal)}
        scrollButtons="auto"
        textColor="secondary"
        value={activeTab}
        variant="scrollable"
      >
        <Tab label="English" {...a11yProps.tabs(0, TAB_ID)} />
        {languageTabs}
      </Tabs>
    );
  };

  const renderPanels = () => {
    let tabPanels = [];

    if (languages && languages.length > 0) {
      tabPanels = languages.map((lang, index) => (
        <TabPanel
          index={index + 1}
          key={`panel-${lang.label}`}
          value={activeTab}
          identifier={TAB_ID}
        >
          <Typography className={classes.questionLabel} component="h3" variant="body1">
            <strong>
              {translatedQuestions[lang.id] || label}
            </strong>
          </Typography>

          <TextField
            aria-label={translatedQuestions[lang.id] || label}
            disabled={submitting}
            error={stateErrors.hasError(lang.label, errors)}
            fullWidth
            helperText={stateErrors.hasError(lang.label, errors) ? stateErrors.getError(lang.label, errors) : getCharCount(translatedAnswers[lang.id], maxl)}
            label="Enter response"
            onChange={event => handleChange(lang.id, event)}
            multiline
            rows={4}
            value={translatedAnswers[lang.id]}
            variant="filled"
            inputProps={{ maxLength: maxl }}
          />
        </TabPanel>
      ));
    }

    // append english to the beginning
    tabPanels.unshift((
      <TabPanel
        key="panel-en"
        value={activeTab}
        index={0}
        identifier={TAB_ID}
      >
        <Typography className={classes.questionLabel} component="h3" variant="body1">
          <strong>
            {label}
          </strong>
        </Typography>

        <TextField
          aria-label={label}
          disabled={submitting}
          error={stateErrors.hasError('answer', errors)}
          fullWidth
          helperText={stateErrors.hasError('answer', errors) ? stateErrors.getError('answer', errors) : getCharCount(answer, maxl)}
          label="Enter response"
          onChange={event => handleChange('en', event)}
          multiline
          rows={4}
          value={answer}
          variant="filled"
          inputProps={{ maxLength: maxl }}
        />
      </TabPanel>
    ));
    return tabPanels;
  };

  return (
    <div>
      {renderTabs()}
      {renderPanels()}
    </div>
  );
};

QuestionTextField.propTypes = {
  answer: PropTypes.string,
  errors: PropTypes.object,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  label: PropTypes.string,
  languages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
  })),
  maxl: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  submitting: PropTypes.bool,
  translatedAnswers: PropTypes.object,
  translatedQuestions: PropTypes.object,
};

QuestionTextField.defaultProps = {
  answer: '',
  errors: {},
  label: '',
  languages: [],
  maxl: 1000,
  onChange: () => {},
  submitting: false,
  translatedAnswers: {},
  translatedQuestions: {},
};

export default QuestionTextField;
