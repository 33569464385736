import { useState } from 'react';

const useStepState = () => {
  const [race, setRace] = useState({});
  const [step, setStep] = useState(1); // keep track of current step
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  // Step Set up State
  const [name, setName] = useState('');
  const [submittingName, setSubmittingName] = useState(false);
  const [addNameErrors, setAddNameErrors] = useState({});
  const [typeOfRace, setTypeOfRace] = useState(0);
  const [votingOption, setVotingOption] = useState(0);
  const [numberOfCandidates, setNumberOfCandidates] = useState('');
  const [votingOptionsErrors, setVotingOptionsErrors] = useState({});
  const [district, setDistrict] = useState(null);
  const [districtErrors, setDistrictErrors] = useState({});

  // Step categories State
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(-1);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [customCategory, setCustomCategory] = useState(null);
  const [customCategoryInputs, setCustomCategoryInputs] = useState({
    name: '',
    description: '',
  });
  const [submittingCustomCategory, setSubmittingCustomCategory] = useState(
    false,
  );
  const [customCategoryErrors, setCustomCategoryErrors] = useState({});

  // Step 3 state
  const [savedDescription, setSavedDescription] = useState('<p></p>');
  const [initialDescription] = useState('<p></p>');

  // Step Candidates
  const errorsCandidateList = [];
  const [candidateList, setCandidateList] = useState([]);
  const [parties, setParties] = useState(null);
  const [partiesLoaded, setPartiesLoaded] = useState(false);
  const [checkValidations, setCheckValidations] = useState(false);

  // Step bio questions state
  const [bioDecision, setBioDecision] = useState(0);
  const [loadingBio, setLoadingBio] = useState(false);
  const [existingSets, setExistingSets] = useState([]);
  const [allQs, setAllQs] = useState([]);
  const [newSetTitle, setNewSetTitle] = useState(''); // title of our set
  const [newSetTitleErrors, setNewSetTitleErrors] = useState({});
  const [newSetQuestions, setNewSetQuestions] = useState([]); // list of questions selected for the set
  const [bioSetChoice, setBioSetChoice] = useState(null);
  const [bioSetChoiceErrors, setBioSetChoiceErrors] = useState({});

  // Step race questions state
  const [raceQuestionDecision, setRaceQuestionDecision] = useState(0);
  const [existingRQS, setExistingRQS] = useState([]);
  const [selectedRQS, setSelectedRQS] = useState(null);
  const [newRQSTitle, setNewRQSTitle] = useState('');
  const [newRQSQuestions, setNewRQSQuestions] = useState([]);
  const [loadingRQs, setLoadingRQs] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [typeQuestions, setTypeQuestions] = useState([]);
  const [selectedRQSErrors, setSelectedRQSErrors] = useState({});
  const [newRQSTitleErrors, setNewRQSTitleErrors] = useState({});
  const [addRQuestionErrors, setAddRQuestionErrors] = useState({});

  return {
    race,
    setRace,
    step,
    setStep,
    openCancelDialog,
    setOpenCancelDialog,
    stepSetUpState: {
      name,
      setName,
      submittingName,
      setSubmittingName,
      addNameErrors,
      setAddNameErrors,
      typeOfRace,
      setTypeOfRace,
      votingOption,
      setVotingOption,
      numberOfCandidates,
      setNumberOfCandidates,
      votingOptionsErrors,
      setVotingOptionsErrors,
      district,
      handleDistrictChange: setDistrict,
      districtErrors,
      setDistrictErrors,
    },
    stepCategoryState: {
      categories,
      setCategories,
      selectedCategory,
      setSelectedCategory,
      loadingCategories,
      setLoadingCategories,
      customCategory,
      setCustomCategory,
      customCategoryInputs,
      setCustomCategoryInputs,
      submittingCustomCategory,
      setSubmittingCustomCategory,
      customCategoryErrors,
      setCustomCategoryErrors,
    },
    stepDescriptionState: {
      savedDescription,
      setSavedDescription,
      initialDescription,
    },
    stepCandidatesState: {
      candidateList,
      setCandidateList,
      errorsCandidateList,
      parties,
      setParties,
      partiesLoaded,
      setPartiesLoaded,
      checkValidations,
      setCheckValidations,
    },
    stepBioQuestionsState: {
      bioDecision,
      setBioDecision,
      existingSets,
      setExistingSets,
      newSetTitle,
      setNewSetTitle,
      newSetTitleErrors,
      setNewSetTitleErrors,
      newSetQuestions,
      setNewSetQuestions,
      loadingBio,
      setLoadingBio,
      allQs,
      setAllQs,
      bioSetChoice,
      setBioSetChoice,
      bioSetChoiceErrors,
      setBioSetChoiceErrors,
    },
    stepRaceQuestionsState: {
      raceQuestionDecision,
      setRaceQuestionDecision,
      existingRQS,
      setExistingRQS,
      selectedRQS,
      setSelectedRQS,
      newRQSTitle,
      setNewRQSTitle,
      newRQSQuestions,
      setNewRQSQuestions,
      loadingRQs,
      setLoadingRQs,
      languages,
      setLanguages,
      selectedRQSErrors,
      setSelectedRQSErrors,
      newRQSTitleErrors,
      setNewRQSTitleErrors,
      addRQuestionErrors,
      setAddRQuestionErrors,
      typeQuestions,
      setTypeQuestions,
    },
  };
};

export default useStepState;
