import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
} from '@material-ui/core';
import { FixedSizeList as List } from 'react-window';
import GISDistrictListRowContainer from 'components/Districts/Partials/GISDistrictListRowContainer';

const GISDistrictList = React.forwardRef(({
  districts,
  focusId,
  guideId,
  heading,
  onDelete,
  onUpdateError,
  onUpdateSuccess,
}, ref) => (
  <div>
    <Typography component="h2" variant="h3" paragraph>
      {heading}
    </Typography>

    <List
      height={500}
      itemCount={districts.length}
      itemData={districts}
      itemSize={89}
      overscanCount={4}
      ref={ref}
    >
      {({ data, index, style }) => (
        <GISDistrictListRowContainer
          guideId={guideId}
          hasFocus={focusId == data[index].id}
          id={data[index].id}
          key={data[index].id}
          name={data[index].name}
          onDelete={onDelete}
          onUpdateError={onUpdateError}
          onUpdateSuccess={onUpdateSuccess}
          style={style}
        />
      )}
    </List>
  </div>
));
GISDistrictList.displayName = 'GISDistrictList';

GISDistrictList.propTypes = {
  districts: PropTypes.array,
  focusId: PropTypes.number,
  guideId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  heading: PropTypes.string,
  onDelete: PropTypes.func,
  onUpdateError: PropTypes.func,
  onUpdateSuccess: PropTypes.func,
};

GISDistrictList.defaultProps = {
  districts: [],
  heading: '',
  onDelete: () => {},
  onUpdateError: () => {},
  onUpdateSuccess: () => {},
};

export default GISDistrictList;
