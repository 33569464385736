import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DataGridPro, LicenseInfo } from '@mui/x-data-grid-pro';
import { makeStyles, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import GaUtils from 'helpers/GaUtils';

const styles = makeStyles({
  table: {
    border: 'none',
  },
  pageTitle: {
    color: '#757575',
    marginBottom: '0.625rem',
  },
});

// Dynamic row height style for DataGrid component
const StyledDataGrid = withStyles({
  root: {
    '& .MuiDataGrid-renderingZone': {
      maxHeight: 'none !important',
    },
    '& .MuiDataGrid-cell': {
      lineHeight: 'unset !important',
      maxHeight: 'none !important',
      whiteSpace: 'normal',
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiDataGrid-row': {
      maxHeight: 'none !important',
    },
  },
})(DataGridPro);

const ROWS_PER_PAGE_OPTIONS = [50, 100, 500, 1000, 5000];

LicenseInfo.setLicenseKey(
  '5354fa8b30068069673634540842cbf3T1JERVI6MzU2NzcsRVhQSVJZPTE2NzM0NzU4MDAwMDAsS0VZVkVSU0lPTj0x',
);

const CandidateEventLog = (props) => {
  // Variables and constants
  const { candidateFullName, candidateEventLog, messages } = props;
  const classes = styles();
  const columns = [
    {
      field: 'date',
      headerName: messages.dateColumn,
      flex: 1,
      renderCell: (params) => GaUtils.getDateFormatted(params.row.date),
    },
    {
      field: 'eventType',
      headerName: messages.eventTypeColumn,
      flex: 1,
      renderCell: (params) => params.row.eventType?.name,
    },
    {
      // TODO: This field must be updated to a real value.
      field: 'description',
      headerName: ' ',
      flex: 1,
    },
  ];
  // hooks
  const [pageSize, setPageSize] = useState(50);
  // Functions
  // Render
  return (
    <div>
      <Typography variant="h1" component="h1" className={classes.pageTitle}>
        {candidateFullName}
      </Typography>
      <StyledDataGrid
        autoHeight={true}
        className={classes.table}
        columns={columns}
        // disableColumnFilter
        // disableColumnMenu
        // disableColumnSelector
        // disableDensitySelector
        // disableSelectionOnClick
        pageSize={pageSize}
        onPageSizeChange={setPageSize}
        rows={candidateEventLog}
        rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
        pagination
      />
    </div>
  );
};

CandidateEventLog.propTypes = {
  candidateFullName: PropTypes.string,
  candidateEventLog: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    candidate: PropTypes.object,
    date: PropTypes.string,
    eventType: PropTypes.shape({
      code: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      name: PropTypes.string,
    }),
  })),
  messages: PropTypes.shape({
    dateColumn: PropTypes.string,
    eventTypeColumn: PropTypes.string,
  }),
};

CandidateEventLog.defaultProps = {
  candidateFullName: '',
  candidateEventLog: [],
  messages: {
    dateColumn: 'Date',
    eventTypeColumn: 'Event',
  },
};

export default CandidateEventLog;
