import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Chip,
  Grid,
  Typography,
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import CandidateStatus from 'components/Candidates/Partials/CandidateStatus';
import CandidateMetadataTitle from 'components/Candidates/Partials/CandidateMetadataTitle';
import CandidateMetadataValue from 'components/Candidates/Partials/CandidateMetadataValue';
import CandidateMetadata from 'components/Candidates/Partials/CandidateMetadata';
import useImageOrientation from 'hooks/useImageOrientation';
import { partyJoin } from 'helpers/party';

const useStyles = makeStyles((theme) => ({
  breadcrumb: {
    fontSize: '.8rem',
    color: '#666',
    marginBottom: '.4rem',
    marginLeft: '5px',
    '&:hover': {
      color: theme.palette.primary.main,
      '& a': {
        color: theme.palette.primary.main,
      },
    },
    '& a': {
      color: '#666',
      textDecoration: 'none',
    },
  },
  candidateProfilePic: {
    borderRadius: '50%',
    display: 'flex',
    objectFit: 'cover',
    width: '7rem',
    height: '7rem',
    backgroundColor: '#fff',
    [theme.breakpoints.down('md')]: {
      width: '6rem',
      height: '6rem',
    },
  },
  candidateDefaultIcon: {
    width: '7rem',
    height: '7rem',
    color: '#94A8B2',
  },
  candidateName: {
    wordBreak: 'break-word',
  },
  containerMobileBreakpoint: {
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
    },
  },
  metadataContainer: {
    justifyContent: 'flex-start',

    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-evenly',
    },

    [theme.breakpoints.down('sm')]: {
      marginLeft: '5px !important',
    },
  },
  textLink: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  dangerChip: {
    borderColor: '#ac1b3d',
    color: '#ac1b3d',
    marginLeft: '.6rem',
  },
}));

const CandidateDetailsPageHeader = ({
  messages,
  candidate,
}) => {
  const classes = useStyles();
  const [raceDetailsLink, setRaceDetailsLink] = useState('');
  const { handleOrientation } = useImageOrientation();

  useEffect(() => {
    if (candidate && Object.prototype.hasOwnProperty.call(candidate, 'race') && Object.prototype.hasOwnProperty.call(candidate.race, 'id')) {
      setRaceDetailsLink(`/races/${candidate.race.id}`);
    }
  }, [candidate]);

  return (
    <div className="ga-banner">
      <div className="ga-container">
        <div className="ga-w-100-p ga-p-16">
          <Grid container spacing={2} className={classes.containerMobileBreakpoint}>
            <Grid item xs={12} sm={12} md={5}>
              <div className={classes.breadcrumb}>
                &lt; <a href='/candidates'>Candidate Dashboard</a>
              </div>

              <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item xs={12} sm={2} md={3}>
                  <Avatar
                    alt={candidate.name != '' ? candidate.name : 'NO NAME'}
                    className={classes.candidateProfilePic}
                    imgProps={{ onLoad: handleOrientation }}
                    src={candidate.imageUri}
                  >
                    <PersonIcon className={classes.candidateDefaultIcon} />
                  </Avatar>
                </Grid>

                <Grid item xs={12} sm={10} md={7}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    className="ga-pt-8"
                  >
                    <CandidateMetadataTitle text={messages.candidateRaceProfile} />

                    <Typography variant="h4" component="span" className={classes.candidateName}>
                      {candidate.name != '' ? candidate.name : 'NO NAME'}

                      {candidate.deleted && (
                        <Chip
                          className={classes.dangerChip}
                          color="secondary"
                          label="Archived"
                          size="small"
                          variant="outlined"
                        />
                      )}
                    </Typography>

                    <Typography
                      variant="subtitle1"
                      component="span"
                      color="primary"
                    >
                      {messages.race}: <a href={raceDetailsLink} className={classes.textLink}>{candidate.race.name}</a>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={7}>
              <Grid
                className={classes.metadataContainer}
                container
                direction="row"
              >
                <Grid item xs={6} sm={4} md={2}>
                  <CandidateMetadata
                    text={messages.party}
                  >
                    {partyJoin(candidate.parties)}
                  </CandidateMetadata>
                </Grid>

                <Grid item xs={6} sm={4} md={2}>
                  <CandidateMetadata
                    text={messages.contactsName}
                  >
                    {candidate.contactUser?.name}
                  </CandidateMetadata>
                </Grid>

                <Grid item xs={6} sm={4} md={4}>
                  <CandidateMetadata
                    text={messages.contactsEmail}
                  >
                    {candidate.contactUser?.email}
                  </CandidateMetadata>
                </Grid>

                <Grid item xs={6} sm={3} md={2}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <CandidateMetadata text={messages.status}>
                      <CandidateStatus
                        status={candidate.state}
                        labelStyle={{ color: '#212121', fontSize: '16px' }}
                      />
                      {/* No clue what this date is. Leaving it in because i'm scared */}
                      <CandidateMetadataValue>{candidate.state.date}</CandidateMetadataValue>
                    </CandidateMetadata>
                  </Grid>
                </Grid>

                <Grid item xs={6} sm={4} md={2}>
                  <CandidateMetadata
                    text={messages.securityCode}
                    isLast
                  >
                    {candidate.contactUser?.code}
                  </CandidateMetadata>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

CandidateDetailsPageHeader.propTypes = {
  messages: PropTypes.object,
  candidate: PropTypes.object,
};

CandidateDetailsPageHeader.defaultProps = {
  candidate: {
    contactUser: {
      code: '',
      email: '',
      name: '',
    },
    deleted: false,
    id: '',
    name: '',
    parties: [],
    race: {
      name: '',
    },
    state: {
      id: '',
      name: '',
    },
  },
};

export default CandidateDetailsPageHeader;
