import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import CandidateMetadataTitle from 'components/Candidates/Partials/CandidateMetadataTitle';
import CandidateMetadataValue from 'components/Candidates/Partials/CandidateMetadataValue';

const useStyles = makeStyles(() => ({
  cardValue: {
    overflowWrap: 'anywhere',
    marginRight: '15px',
  },
  lastCardValue: {
    overflowWrap: 'anywhere',
    marginRight: '0px',
  },
}));

const CandidateMetadata = ({ text, children, isLast }) => {
  const classes = useStyles();

  return (
    <Grid item className={isLast ? classes.lastCardValue : classes.cardValue}>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <CandidateMetadataTitle text={text} />
        <CandidateMetadataValue>{children}</CandidateMetadataValue>
      </Grid>
    </Grid>
  );
};

CandidateMetadata.propTypes = {
  text: PropTypes.string,
  children: PropTypes.node,
  isLast: PropTypes.bool,
};

CandidateMetadata.defaultProps = {
  text: '',
  children: null,
  isLast: false,
};

export default CandidateMetadata;
