import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';
import GenericTabs from 'components/GenericTabs';
import Error from 'components/Error';
import {
  CampaignFinanceLandingTablesContainer,
  campaignFinanceMessagesProps,
  FETCH_TYPE_MATCHED,
  FETCH_TYPE_UNMATCHED,
} from '@/features/campaign-finance';

const UNMATCHED_TAB = 0;

export const CampaignFinanceLanding = ({
  messages,
}) => {
  // #region declarations

  // state
  const [tab, setTab] = useState(UNMATCHED_TAB);

  // consts
  const TAB_NAMES = [
    messages.tabs.unmatched,
    messages.tabs.matched,
  ];

  const handleTabChange = (event, value) => {
    setTab(value);
  };

  return (
    <ErrorBoundary
      fallback={
        <Error
          heading={messages?.errors?.errorBoundary?.heading}
          content={messages?.errors?.errorBoundary?.content}
        />
      }
    >
      <GenericTabs
        ariaLabel={messages.tabs.aria}
        identifier="contributions-tabs"
        onChange={handleTabChange}
        selected={tab}
        tabs={TAB_NAMES}
      >
        <CampaignFinanceLandingTablesContainer type={FETCH_TYPE_UNMATCHED} />

        <CampaignFinanceLandingTablesContainer type={FETCH_TYPE_MATCHED} />
      </GenericTabs>
    </ErrorBoundary>
  );
};

CampaignFinanceLanding.propTypes = {
  messages: PropTypes.object.isRequired,
};

CampaignFinanceLanding.defaultProps = {
  messages: campaignFinanceMessagesProps,
};
