import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  stepContainer: {
    margin: '5px 0px 10px 20px',
  },
  heading: {
    fontWeight: 500,
  },
  listContainer: {
    marginLeft: '10px',
  },
});

export default function NumberedStepper(props) {
  const classes = useStyles();
  const { steps } = props;

  return (
    <ol className="styled-ol">
      {steps.map((step, index) => (
        <li key={index}>
          <div className={classes.stepContainer}>
            <Typography className={classes.heading} component="h3" variant="h4" paragraph>
              {step.title}
            </Typography>

            {step.children}
          </div>
        </li>
      ))}
    </ol>
  );
}

NumberedStepper.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    children: PropTypes.node,
  })),
};

NumberedStepper.defaultProps = {
  steps: [],
};

// export default NumberedStepper;
