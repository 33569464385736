import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Button,
  Grid,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  photoSection: {
    marginTop: '1.5rem',
  },
  image: {
    maxWidth: '50%',
    maxHeight: '50%',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
      maxHeight: '100%',
    },
  },
}));

function MetadataCard({
  editScreen,
  editButtonText,
  fields,
  hasImage,
  imageURL,
  onEdit,
  title,
}) {
  const classes = useStyles();

  const fieldArray = fields.map((field, index) => (
    <Grid item key={index}>
      {field.value === null || field.value === '' ? (
        <>
          <p className="card-label">{field.label}</p>
          <p className="mdc-typography--paragraph card-reminder-text">No answer</p>
        </>
      ) : (
        <>
          <p className="card-label">{field.label}</p>
          <p className="mdc-typography--paragraph card-value">{field.value}</p>
        </>
      )}
    </Grid>
  ));

  const handleEditClick = () => {
    onEdit(editScreen);
  };

  return (
    <Paper elevation={3} className="card-container">
      <div className="card-header-container">
        <Typography component="h3" variant="h3" color="primary">
          {title}
        </Typography>

        <Button
          variant="outlined"
          color="secondary"
          size="large"
          onClick={handleEditClick}
        >
          {editButtonText}
        </Button>

      </div>
      <Grid container spacing={3}>
        {fieldArray}
      </Grid>
      {(hasImage) && (
        <div className={classes.photoSection}>
          <p className="card-label">Candidate Photo</p>
          {(imageURL || imageURL !== '') ? (
            <img src={imageURL} className={classes.image} />
          ) : (
            <p className="mdc-typography--paragraph card-reminder-text">No photo uploaded</p>
          )}
        </div>
      )}
    </Paper>
  );
}

MetadataCard.propTypes = {
  editScreen: PropTypes.number,
  editButtonText: PropTypes.string,
  hasImage: PropTypes.bool,
  imageURL: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
  })),
  onEdit: PropTypes.func,
  title: PropTypes.string,
};

MetadataCard.defaultProps = {
  editScreen: 2,
  editButtonText: 'Edit',
  hasImage: false,
  imageURL: '',
  fields: [],
  onEdit: () => {},
  title: 'Default title',
};

export default MetadataCard;
