import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  red: {
    color: '#dd3561',
  },
  orange: {
    color: '#f7650a',
  },
  yellow: {
    color: '#fec40e',
  },
  green: {
    color: '#3fa06f',
  },
});

function StatusIndicator({ fontSize, text, variant }) {
  const classes = useStyles();

  const colorClass = () => {
    switch (variant) {
      case 'red':
        return classes.red;
      case 'orange':
        return classes.orange;
      case 'yellow':
        return classes.yellow;
      case 'green':
      default:
        return classes.green;
    }
  };

  return (
    <span className={classes.container}>
      <FiberManualRecordIcon className={colorClass()} fontSize={fontSize} /> {text}
    </span>
  );
}

StatusIndicator.propTypes = {
  fontSize: PropTypes.oneOf(['default', 'inherit', 'large', 'medium', 'small']),
  text: PropTypes.string,
  variant: PropTypes.oneOf(['red', 'orange', 'yellow', 'green', 'none']),
};

StatusIndicator.defaultProps = {
  fontSize: 'medium',
  text: '',
  variant: 'green',
};

export default StatusIndicator;
