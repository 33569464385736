import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  text: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  container: {
    position: 'absolute',
    right: 0,
  },
});

const StepTracker = ({
  currentStep,
  totalSteps,
  messages,
  top = '4rem',
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.container} style={{
      top,
    }}>
      <Typography
        className={classes.text}
        component="p"
      >
        {messages.step} {currentStep} {messages.of} {totalSteps}
      </Typography>
    </Box>
  );
};

StepTracker.propTypes = {
  currentStep: PropTypes.number.isRequired,
  totalSteps: PropTypes.number.isRequired,
  messages: PropTypes.object.isRequired,
  top: PropTypes.string,
};

StepTracker.defaultProps = {
  top: '4rem',
};

export default StepTracker;
