import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/styles';
import RadioCardList from 'components/RadioCardList';

const styles = () => ({
  actionButton: {
    marginLeft: '1rem',
  },
  modalTitle: {
    alignItems: 'flex-start',
  },
  modalActions: {
    justifyContent: 'end',
  },
});

class ImportModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };
  }

  render() {
    const {
      activeCardIndex,
      cards,
      cardsBreakPoints,
      classes,
      messages,
      modalIsOpen,
      onCancel,
      onChange,
      onContinue,
    } = this.props;

    return (
      <Dialog
        aria-labelledby="bulk-import-modal-title"
        maxWidth="md"
        onClose={onCancel}
        open={modalIsOpen}
      >
        <DialogTitle
          className={classes.modalTitle}
          disableTypography
          id="bulk-import-modal-title"
          onClose={this.handleClose}
        >
          <span>
            <Typography component="h1" variant="h1">
              {messages.import.title}
            </Typography>
            <Typography component="p" variant="body1">
              {messages.import.description}
            </Typography>
          </span>
          <IconButton aria-label="close" onClick={onCancel}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <RadioCardList
            cards={cards}
            checked={activeCardIndex}
            gridBreakpoints={cardsBreakPoints}
            onChange={onChange}
          />
        </DialogContent>

        <DialogActions className={classes.modalActions}>
          <Button onClick={onCancel}>{messages.common.button.cancel}</Button>
          <Button
            className={classes.actionButton}
            color="secondary"
            onClick={onContinue}
            variant="contained"
          >
            {messages.common.button.continue}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ImportModal.propTypes = {
  activeCardIndex: PropTypes.number,
  cards: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  })),
  cardsBreakPoints: PropTypes.object,
  classes: PropTypes.object,
  messages: PropTypes.object,
  modalIsOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  onContinue: PropTypes.func,
};

ImportModal.defaultProps = {
  onCancel: () => {},
  onContinue: () => {},
  onChange: () => {},
  modalIsOpen: false,
  activeCardIndex: 0,
  cards: [],
  cardsBreakPoints: {},
  messages: {
    import: {
      title: 'Import',
      description: 'There are a couple of different ways that you can import data into the Voter Guide. Select one of the options below to get started on your bulk import.',
    },
    common: {
      button: {
        cancel: 'Cancel',
        continue: 'Continue',
      },
    },
  },
};

export default withStyles(styles)(ImportModal);
