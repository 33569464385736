import ExifReader from 'exifreader';
import modernizr from 'modernizr';

/**
 * This hook exists because some older browsers don't support image orientation
 * using the images EXIF metadata. So, for example, if a user takes a photo in
 * portrait rotation using their phone and attempts to upload it to the site, it
 * can appear sideways for users of older browsers.
 *
 * We check if the browser supports the CSS image-orientation property and, if
 * not, we handle the rotation via javascript by applying an inline style.
 *
 * Usage Example:
 * const { handleOrientation } = useImageOrientation();
 * <img src={someUrl} onLoad={handleOrientation} />
 */

const useImageOrientation = () => {
  const getImageTransform = (orientation) => {
    switch (orientation) {
      case 2: // flipped horizontally
        return 'scaleX(-1)';
      case 3: // rotated 180°
        return 'rotate(180deg)';
      case 4: // flipped vertically
        return 'scaleY(-1)';
      case 5: // rotate 90° CCW and flip vertically
        return 'rotate(90deg) scaleY(-1)';
      case 6: // rotated 90° CCW
        return 'rotate(90deg)';
      case 7: // rotate 90° CW and flip vertically
        return 'rotate(90deg) scaleX(-1)';
      case 8: // rotated 90° CW
        return 'rotate(270deg)';
      case 1: // horizontal -- do nothing
      default:
        return null;
    }
  };

  const handleOrientation = async (event) => {
    // Older browsers don't support image rotation based
    // on EXIF data, so we have to manually rotate them here
    if (!modernizr.exiforientation) {
      const tags = await ExifReader.load(event.target.src);
      const orientation = tags.Orientation.value;
      const imageTransform = getImageTransform(orientation);

      if (imageTransform) {
        event.target.style.transform = imageTransform;
      }
    }
  };

  return { handleOrientation };
};

export default useImageOrientation;
