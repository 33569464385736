import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DoDontCard from 'components/DoDontCard';

const useStyles = makeStyles({
  orderedList: {
    counterReset: 'item',
    listStyle: 'none',
    '& li': {
      counterIncrement: 'item',
      display: 'flex',
      '&:before': {
        content: 'counter(item)',
        backgroundColor: '#0a3a5a',
        borderRadius: '100%',
        color: '#ffffff',
        fontWeight: 'bold',
        height: '25px',
        width: '25px',
        flexShrink: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '15px',
        paddingTop: '2px',
      },
    },
  },
});

const Step1 = () => (
  <div>
    <Typography component="h2" variant="h2" paragraph>
      Introduction
    </Typography>

    <Typography component="p" variant="body1" paragraph>
      Biased and leading questions are those that are written in such a way that one answer appears to be favored over another. These questions often provide extra information that may influence the respondent in one way or another or the reader’s view of the respondent’s answer. It is vitally important that Leagues do not use biased questions in their voters’ guides.
    </Typography>

    <Typography component="p" variant="body1" paragraph>
      Catching biased questions can be difficult, but it is important to ensure your questions are as unbiased as possible. While not all candidates will complain about it, it could be a factor why some candidates simply don&rsquo;t answer at all.
    </Typography>

    <Typography component="p" variant="body1" paragraph>
      We&rsquo;ve created a simple 3 step tutorial to help you avoid any unintentional bias. You can come back and review our suggestions at any time.
    </Typography>
  </div>
);

const Step2 = () => {
  const classes = useStyles();

  return (
    <div>
      <Typography component="h2" variant="h2" paragraph>
        Helpful Tips
      </Typography>

      <Typography component="p" variant="body1" paragraph>
        Here are some quick and easy tips to get you started:
      </Typography>

      <ol className={classes.orderedList}>
        <li>
          <div>
            <Typography component="h3" variant="h3">
              Always make sure you&rsquo;re asking questions relevant to the voters
            </Typography>
            <Typography component="p" variant="body1" paragraph>
              When creating questions, make sure you are choosing topics that voters are concerned about and are looking to learn more about.
            </Typography>
          </div>
        </li>
        <li>
          <div>
            <Typography component="h3" variant="h3">
              Don’t provide background information or opinions
            </Typography>
            <Typography component="p" variant="body1" paragraph>
              It&rsquo;s important to stay impartial - candidates and voters are from all parties and backgrounds.
            </Typography>
          </div>
        </li>
        <li>
          <div>
            <Typography component="h3" variant="h3">
              The shorter the question the better
            </Typography>
            <Typography component="p" variant="body1" paragraph>
              Keep it concise and straight to the point - ask the question you want without adding any additional information.
            </Typography>
          </div>
        </li>
        <li>
          <div>
            <Typography component="h3" variant="h3">
              Only ask one question at time
            </Typography>
            <Typography component="p" variant="body1" paragraph>
              If you need to ask a follow up question, create a separate question field for it.
            </Typography>
          </div>
        </li>
        <li>
          <div>
            <Typography component="h3" variant="h3">
              Be aware of word choice
            </Typography>
            <Typography component="p" variant="body1" paragraph>
              Changing one word in a question can make the question drastically different. It&rsquo;s easy to accidentally make a biased or leading question.
            </Typography>
          </div>
        </li>
        <li>
          <div>
            <Typography component="h3" variant="h3">
              League priorities are not important to the questions you ask
            </Typography>
            <Typography component="p" variant="body1" paragraph>
              Remember to keep voter interests in mind when creating question topics. Even if the League has a position on a specific issue, do not include any of that information in the question to the candidates.
            </Typography>
          </div>
        </li>
      </ol>
    </div>
  );
};

const Step3 = () => (
  <div>
    <Typography component="h2" variant="h2" paragraph>
      Do&rsquo;s and Don&rsquo;ts
    </Typography>

    <Typography component="p" variant="body1" paragraph>
      We&rsquo;ve collected some examples of commonly asked questions from real League guides in the past. After reviewing them for bias, we have created suggestions for how to reduce bias. For additional questions suggestions, visit the League of Women Voter&rsquo;s help guide for more.
    </Typography>

    <div>
      <DoDontCard
        className="ga-m-bottom--large"
        dontText="What will you do to continue to support our recently thriving U.S. economy?"
        doText="What will you do to support a vibrant economy across the U.S.?"
      />

      <DoDontCard
        className="ga-m-bottom--large"
        dontText="Many people are streaming into our country illegally, taking jobs from American citizens. Do you support a pathway to citizenship for these individuals?"
        doText="What, if any, actions will you support to create a pathway to citizenship?"
      />

      <DoDontCard
        className="ga-m-bottom--large"
        dontText="Ever since the Supreme Court’s Citizens United decision, the amount of money invested in American elections has skyrocketed, ultimately reducing the influence of millions of voters across the country. Do you think it is important to reduce the role money plays in American elections?"
        doText="Please explain why you do or do not think it is important to reduce the role of money in American elections."
      />

      <DoDontCard
        className="ga-m-bottom--large"
        dontText="In light of the high gun related deaths in our state and the fact that nearly 75% of residents support common sense gun laws, would you support changing the laws to limit the availability of semi automatic assault weapons and expanding background checks?"
        doText={<><p>Please explain why you do or do not support changing the laws to limit the availability of semi automatic weapons.</p><p className="ga-m-vertical--small">or</p><p>Please explain why you do or do not support expanding background checks for gun purchases?</p><p className="ga-m-vertical--small">or</p><p>What changes to the gun laws in our state would you support or oppose?</p></>}
      />

      <DoDontCard
        className="ga-m-bottom--large"
        dontText="Recent studies of societal cost in dollars of mass incarceration has shown the likelihood of being tangled in the criminal justice system. Non violent crimes or simply the inability to pay a traffic fine can ultimately end with incarceration and a criminal record, impairing one’s ability to get or keep a job. What are ways that you would propose reforming the criminal justice system in our state? And, do you favor the use of private prisons if it can be seen as a cheaper alternative to state run prisons? Why or why not."
        doText={<><p>How would you reform the criminal justice system in our state?</p><p className="ga-m-vertical--small">or</p><p>Please explain why you do or do not favor the use of private prisons over state run prisons.</p></>}
      />

      <DoDontCard
        className="ga-m-bottom--large"
        dontText="The U.S. Department of Education plays a critical role in providing funding for quality public education and ensuring that children, no matter their socio-economic status or school district receive an equal education. Would you support getting rid of the U.S. Department of Education? Why or why not?"
        doText="Please explain the role you believe the federal government should play in public education and therefore the policies you would support or oppose."
      />
    </div>
  </div>
);

export { Step1, Step2, Step3 };
