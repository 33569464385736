export default class GisHelper {
  /**
  * Converts raw district data coming from the legacy application to an array of district objects
  * @param {object} districtNames District ids with the district name as value
  * @param {object} districtCoordinates District ids with an array of lat/lng coordinates as value
  * @returns Array of district objects
  */
  static convertRawDistrictData = (gisFeatures, gisMetadata) => {
    const districts = [];

    Object.entries(gisFeatures).forEach(([id, feature]) => {
      let metadata = {};
      let districtName = '';

      // We either didn't get an array of lat/long coordinates or the
      // array was empty. Either way, we can't draw a polygon, so let's bail.
      if (!Object.prototype.hasOwnProperty.call(feature, 'geometryCoordinates')
          || !Array.isArray(feature.geometryCoordinates)
          || !feature.geometryCoordinates
          || feature.geometryCoordinates.length <= 0) {
        console.warn(`District ${id} has invalid or missing lat/lng map coordinates`);
        return;
      }

      // If there is a name in the metadata, let's set it
      if (Object.prototype.hasOwnProperty.call(gisMetadata, id)
          && Object.prototype.hasOwnProperty.call(gisMetadata[id], 'metadata')) {
        if (Object.prototype.hasOwnProperty.call(gisMetadata[id].metadata, 'NAME')) {
          districtName = gisMetadata[id].metadata.NAME;
        } else if (Object.prototype.hasOwnProperty.call(gisMetadata[id].metadata, 'NAMELSAD')) {
          districtName = gisMetadata[id].metadata.NAMELSAD;
        } else if (Object.prototype.hasOwnProperty.call(gisMetadata[id].metadata, 'CountyName')) {
          districtName = gisMetadata[id].metadata.CountyName;
        } else if (Object.prototype.hasOwnProperty.call(gisMetadata[id].metadata, 'Label')) {
          districtName = gisMetadata[id].metadata.Label;
        } else {
          // If no name was found in the metadata, we concatenate all the metadata into
          // a long string and use that as a default name
          districtName = Object.values(gisMetadata[id].metadata).join(' ');
        }

        metadata = gisMetadata[id].metadata;
      }

      // Add the lat/lng coordinates to our coordinate array. The structure is a little
      // wonky, but it's created this way to match what the java Geometry library returns
      // from the backend when parsing data from the database
      const polygons = [];
      feature.geometryCoordinates.forEach(polygon => {
        const polyCoords = [];

        if (Array.isArray(polygon) && polygon.length > 0) {
          polygon.forEach(coords => {
            const coordsArray = [];

            coords.forEach(c => {
              coordsArray.push({ lat: c.y, lng: c.x });
            });

            polyCoords.push(coordsArray);
          });
        }

        polygons.push(polyCoords);
      });

      districts.push({
        geometry: polygons,
        polyGeometry: feature.geometry,
        expanded: false,
        id,
        metadata,
        name: districtName,
        selected: true,
      });
    });

    return districts;
  };

  static getUnionBounds = (a) => {
    let bounds = null;

    for (let i = 0; i < a.length; i++) {
      const polygon = a[i];
      const b = GisHelper.getPolyBounds(polygon);

      if (bounds == null) {
        bounds = b;
      } else {
        bounds.extend(b.getSouthWest());
        bounds.extend(b.getNorthEast());
      }
    }

    return bounds;
  };

  static getPolyBounds = (p) => {
    const path = p.getPath().getArray();
    let minLat = 90;
    let maxLat = -90;
    let minLon = 180;
    let maxLon = -180;

    for (let i = 0; i < path.length; i++) {
      const point = path[i];
      minLat = Math.min(minLat, point.lat());
      maxLat = Math.max(maxLat, point.lat());
      minLon = Math.min(minLon, point.lng());
      maxLon = Math.max(maxLon, point.lng());
    }

    return new window.google.maps.LatLngBounds(
      new window.google.maps.LatLng(minLat, minLon),
      new window.google.maps.LatLng(maxLat, maxLon),
    );
  };

  static getPolyColor = (index) => {
    const r = (0x33 + index * 149) % 255;
    const g = (0x99 + index * 89) % 255;
    const b = (0xCC + index * 181) % 255;

    // eslint-disable-next-line no-bitwise
    return `#${(0x1000000 + (r << 16) + (g << 8) + (b)).toString(16).substring(1)}`;
  };
}
