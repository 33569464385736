import { useQuery } from '@tanstack/react-query';
import { apiClient } from '@/lib/axios';
import { FETCH_TYPE_MATCHED } from '@/features/campaign-finance';

const ServerColumnMapping = {
  candidateName: 'candidate_name',
  candidateId: 'candidate_id',
  raceId: 'race_id',
  raceName: 'race_name',
  party: 'party',
};

/**
 * Processes the unmatched candidates and returns an array of processed candidates.
 *
 * @param {Object} data - The unmatched candidates.
 * @returns {Array} - An array of processed candidates.
 */
const processData = (data) => {
  // Check if data and data.data exist and are arrays
  if (!data || !Array.isArray(data.data)) {
    return [];
  }

  // Process each candidate
  return data.data.map((vgCandidate, index) => {
    // Ensure vgCandidate is an object, if its not exclude it from the list
    if (!vgCandidate || typeof vgCandidate !== 'object') {
      return null;
    }

    return {
      id: typeof vgCandidate.id === 'number' ? vgCandidate.id : 0,
      index,
      name: typeof vgCandidate.name === 'string' ? vgCandidate.name : '',
      raceId: typeof vgCandidate.race.id === 'string' ? vgCandidate.race.id : '',
      raceName: typeof vgCandidate.race.name === 'string' ? vgCandidate.race.name : '',
      parties: Array.isArray(vgCandidate.parties) ? vgCandidate.parties.map(party => ({
        id: party.id || '',
        name: party.name || '',
      })) : [],
      categories: Array.isArray(vgCandidate.categories) ? vgCandidate.categories.map(category => ({
        id: category.id || '',
        title: category.title || '',
      })) : [],
      voterGuide: typeof vgCandidate.voterGuide === 'string' ? vgCandidate.voterGuide : '',
    };
  });
};

export const getCandidateFinanceMatches = (matchType, {
  page,
  pageSize,
  search,
  sortModel,
}) => {
  const params = {
    limit: pageSize,
    offset: page * pageSize,
    search,
    sort: ServerColumnMapping[sortModel[0]?.field] || 'candidate_name',
    order: sortModel[0]?.sort || 'asc',
  };

  const getMatched = matchType == FETCH_TYPE_MATCHED;

  const queryParams = new URLSearchParams({
    matched: getMatched,
    limit: (params.limit || 5000).toString(),
    offset: (params.offset || 0).toString(),
    ...(params.sort && { sort: params.sort }),
    ...(params.order && { order: params.order }),
    ...(params.search && { search: params.search }),
    ...params,
  });

  return apiClient
    .get(`candidates?${queryParams.toString()}`)
    .then((response) => ({
      rows: processData(response.data),
      totalRows: response.data.meta?.totalRows || 0,
    }));
};

export const getCampaignFinanceRecordMatches = (cfRecordId, {
  page,
  pageSize,
  search,
  sortModel,
}) => {
  const params = {
    limit: pageSize,
    offset: page * pageSize,
    search,
    sort: ServerColumnMapping[sortModel[0]?.field] || 'candidate_name',
    order: sortModel[0]?.sort || 'asc',
  };

  const queryParams = new URLSearchParams({
    limit: (params.limit || 5000).toString(),
    offset: (params.offset || 0).toString(),
    ...(params.sort && { sort: params.sort }),
    ...(params.order && { order: params.order }),
    ...(params.search && { search: params.search }),
    ...params,
  });

  return apiClient
    .get(`campaign-finances/${cfRecordId}/candidates?${queryParams.toString()}`)
    .then((response) => ({
      rows: processData(response.data),
      totalRows: response.data.meta?.totalRows || 0,
    }));
};

export const useCandidateFinanceMatches = ({
  dataGridParams,
  type,
  cfRecordId,
}) => {
  const {
    page,
    pageSize,
    search,
    sortModel,
  } = dataGridParams;

  const queryKey = [
    `candidate_finance_${type}_${search}`,
    page,
    pageSize,
    search || '',
    sortModel,
  ];
  if (cfRecordId && type === FETCH_TYPE_MATCHED) {
    queryKey.push(cfRecordId);
  }

  const queryFn = (cfRecordId && type === FETCH_TYPE_MATCHED) ? (
    () => getCampaignFinanceRecordMatches(cfRecordId, dataGridParams)
  ) : (
    () => getCandidateFinanceMatches(type, dataGridParams)
  );

  return useQuery({
    queryKey,
    queryFn,
    keepPreviousData: true,
    enabled: page !== undefined && pageSize !== undefined && !!sortModel && search !== undefined,
  });
};
