import Axios from 'axios';

export const BASE_API_URL = '/api/v1/';

/**
 * Retrieves the CSRF token from the DOM.
 * @returns {string|null} The CSRF token if found, null otherwise.
 */
const getCsrfToken = () => {
  const queryCsrfToken = document.querySelector('input[name="csrfToken"]');
  return queryCsrfToken ? queryCsrfToken.value : null;
};

// Axios client for making authenticated calls to the API
export const apiClient = Axios.create({
  baseURL: BASE_API_URL,
  headers: {
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/json',
    'Csrf-Token': getCsrfToken(),
  },
});

// Response interceptors if we ever need them for side effects
// const onResponse = (response) => response.data;
// const onResponseError = (error) => error;

// apiClient.interceptors.response.use(onResponse, onResponseError);
