import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

export const COLOR_OPTIONS = {
  GREY: 'grey',
  YELLOW: 'yellow',
  GREEN: 'green',
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  grey: {
    color: '#e0e5e8',
  },
  yellow: {
    color: '#fec40e',
  },
  green: {
    color: '#3fa06f',
  },
});

const StatusText = ({ color, labelStyle, text }) => {
  const classes = useStyles();
  const statusClass = classes[color];

  return (
    <span className={classes.container}>
      <FiberManualRecordIcon className={statusClass} data-testid="statusText-icon" />{' '}
      <span style={labelStyle} data-testid="statusText-text">{text}</span>
    </span>
  );
};

export default StatusText;

StatusText.propTypes = {
  labelStyle: PropTypes.object,
  color: PropTypes.string,
  text: PropTypes.string,
};

StatusText.defaultProps = {
  labelStyle: null,
  color: COLOR_OPTIONS.GREY,
  text: '',
};
