import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import clone from 'rfdc/default';
import CloseIcon from '@material-ui/icons/Close';
import ConfirmationDialog from 'components/ConfirmationDialog';
import AddCandidateForm from 'components/Candidates/Partials/AddCandidateForm';
import Interweave from 'interweave';

const styles = {
  modalContainer: {
    paddingBottom: '.5rem',
    overflow: 'hidden',
  },
  modalContentContainer: {
    padding: 0,
  },
};

const defaultCandidateState = {
  inFavor: false,
  name: '',
  lastName: '',
  contactUser: {
    name: '',
    email: '',
  },
  parties: [],
  race: null,
};

const AddCandidateModal = ({
  errors,
  messages,
  onCancel,
  onSubmit,
  open,
  parties,
  races,
  racesLoaded,
  submitting,
}) => {
  const [candidate, setCandidate] = useState({
    ...clone(defaultCandidateState),
    race: races[0],
  });
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);

  const handlePartyChange = (party) => {
    const newCandidate = clone(candidate);
    const partyIndex = newCandidate.parties.findIndex(p => p.id == party.id);

    if (partyIndex >= 0) {
      // Party is already checked, so remove it from the candidate
      newCandidate.parties.splice(partyIndex, 1);
      setCandidate(newCandidate);
      return;
    }

    // Add party to the candidate
    newCandidate.parties.push(party);
    setCandidate(newCandidate);
  };

  const handleChange = (fieldName, value) => {
    const newCandidate = clone(candidate);

    if (fieldName == 'partiesIds') {
      handlePartyChange(value);
      return;
    }

    if (fieldName == 'contactName') {
      newCandidate.contactUser.name = value;
      setCandidate(newCandidate);
      return;
    }

    if (fieldName == 'contactEmail') {
      newCandidate.contactUser.email = value;
      setCandidate(newCandidate);
      return;
    }

    newCandidate[fieldName] = value;
    setCandidate(newCandidate);
  };

  const handleCancelConfirmation = () => {
    setShowCancelConfirmation(false);
    onCancel();
  };

  return (
    <div>
      <Dialog
        aria-labelledby="add-candidate-title"
        maxWidth="md"
        open={open}
        onClose={() => setShowCancelConfirmation(true)}
        style={styles.modalContainer}
      >
        <DialogTitle id="add-candidate-title" disableTypography>
          <div>
            <Typography component="h1" variant="h1">
              {messages.title}
            </Typography>

            <Typography component="p" variant="body1">
              <Interweave content={messages.subtitle} />
            </Typography>
          </div>

          <IconButton onClick={() => setShowCancelConfirmation(true)} aria-label="close">
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent style={styles.modalContentContainer}>
          <AddCandidateForm
            candidate={candidate}
            dataLoaded={racesLoaded}
            errors={errors}
            messages={messages}
            onCancel={() => setShowCancelConfirmation(true)}
            onChange={handleChange}
            onSubmit={() => onSubmit(candidate)}
            parties={parties}
            races={races}
            submitting={submitting}
          />
        </DialogContent>
      </Dialog>

      <ConfirmationDialog
        cancelButtonText={messages.pendingInfoCancel}
        confirmButtonText={messages.pendingInfoSave}
        heading={messages.pendingInfoQuestion}
        onCancel={() => setShowCancelConfirmation(false)}
        onConfirm={() => handleCancelConfirmation()}
        open={showCancelConfirmation}
        submitting={submitting}
      >
        <Typography component="p" variant="body1">
          {messages.pendingInfoBody}
        </Typography>
      </ConfirmationDialog>
    </div>
  );
};

AddCandidateModal.propTypes = {
  errors: PropTypes.object,
  messages: PropTypes.object,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  open: PropTypes.bool,
  parties: PropTypes.array,
  races: PropTypes.array,
  racesLoaded: PropTypes.bool,
  submitting: PropTypes.bool,
};

AddCandidateModal.defaultProps = {
  errors: {},
  messages: {},
  onCancel: () => {},
  onSubmit: () => {},
  open: false,
  parties: [],
  races: [],
  racesLoaded: false,
  submitting: false,
};

export default AddCandidateModal;
