import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  TextField,
  Typography,
} from '@material-ui/core';
import Form from 'helpers/Form';

const styles = {
  addFormContainer: {
    display: 'flex',
    margin: '1rem',
  },
  addFormAbbrField: {
    marginRight: '2rem',
    width: '20rem',
  },
  addFormActions: {
    backgroundColor: '#eef4f8',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '.8rem 2rem',
  },
};

class AddPartyForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: new Form({
        abbr: '',
        name: '',
      }, {
        validationMessage: props.validationErrorMessage,
      }),
      submitting: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const { name } = event.target;
    const { value } = event.target;
    const { form } = this.state;

    form[name] = value;
    this.forceUpdate();
  }

  handleSubmit() {
    const { onError, onSuccess, postUrl } = this.props;
    const { form } = this.state;

    this.setState({
      submitting: true,
    });

    form.post(postUrl)
      .then(response => {
        this.setState({
          submitting: false,
        });

        onSuccess('add', response);
      })
      .catch(error => {
        this.setState({
          submitting: false,
        });

        onError('add', error);
      });
  }

  render() {
    const {
      classes,
      buttonText,
      heading,
      requiredText,
    } = this.props;
    const {
      form,
      submitting,
    } = this.state;

    return (
      <div>
        <Typography component="h2" variant="h2" paragraph>
          {heading}
        </Typography>

        <Card variant="outlined">
          <CardContent className={classes.addFormContainer}>
            <FormControl className={classes.addFormAbbrField}>
              <TextField
                id="party-add-abbr"
                disabled={submitting}
                error={form.errors.has('abbr')}
                helperText={form.errors.get('abbr')}
                label="Party Abbreviation"
                name="abbr"
                required
                variant="filled"
                onChange={this.handleInputChange}
                value={form.abbr}
              />
            </FormControl>

            <FormControl fullWidth>
              <TextField
                id="party-add-name"
                disabled={submitting}
                error={form.errors.has('name')}
                helperText={form.errors.get('name')}
                label="Party Name"
                name="name"
                required
                variant="filled"
                onChange={this.handleInputChange}
                value={form.name}
              />
            </FormControl>
          </CardContent>

          <CardActions className={classes.addFormActions}>
            <span>{requiredText}</span>

            <Button
              variant="contained"
              color="secondary"
              onClick={this.handleSubmit}
              size="large"
              disabled={submitting}
            >
              {buttonText}
            </Button>
          </CardActions>
        </Card>
      </div>

    );
  }
}

AddPartyForm.propTypes = {
  buttonText: PropTypes.string,
  classes: PropTypes.object,
  heading: PropTypes.string,
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
  postUrl: PropTypes.string.isRequired,
  requiredText: PropTypes.string,
  validationErrorMessage: PropTypes.string,
};

AddPartyForm.defaultProps = {
  buttonText: 'Add Party',
  heading: 'Add additional parties',
  onError: () => {},
  onSuccess: () => {},
  requiredText: '*Required fields needed to add a new party',
  validationErrorMessage: 'There was an issue adding your party. Correct any errors and try again.',
};

export default withStyles(styles)(AddPartyForm);
