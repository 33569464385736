
/**
 * Joins the names of parties into a single string.
 *
 * @param {Array} parties - The array of party objects.
 * @returns {string} - The joined string of party names.
 */
export const partyJoin = (parties) => {
  if (parties && parties.length > 0) {
    return parties.map(p => p.name).join(', ');
  }
  return '';
};
