import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { DataGridPro, LicenseInfo } from '@mui/x-data-grid-pro';
import CloseIcon from '@material-ui/icons/Close';
import Interweave from 'interweave';

import EmptyState from 'components/EmptyState';
import HelpfulHint from 'components/HelpfulHint';

import fetchUtil from 'helpers/Fetch';

// Dynamic row height style for DataGrid component
const StyledDataGrid = withStyles({
  root: {
    '& .MuiDataGrid-renderingZone': {
      maxHeight: 'none !important',
    },
    '& .MuiDataGrid-cell': {
      lineHeight: 'unset !important',
      whiteSpace: 'normal',
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiDataGrid-row': {
      maxHeight: 'none !important',
    },
  },
})(DataGridPro);

LicenseInfo.setLicenseKey(
  '5354fa8b30068069673634540842cbf3T1JERVI6MzU2NzcsRVhQSVJZPTE2NzM0NzU4MDAwMDAsS0VZVkVSU0lPTj0x',
);

const ROWS_PER_PAGE_OPTIONS = [50, 100, 500, 1000, 5000];

const useStyles = makeStyles({
  buttonSpace: {
    marginRight: '1.563rem',
  },
  tableContainer: {
    '& a': {
      color: '#0a3a5a',
      fontWeight: 500,
      textDecoration: 'none',
    },
  },
  table: {
    border: 'none',
  },
});

const RacesUsingQuestionSetModal = ({
  messages,
  onClose,
  open,
  questionSetName,
  guideId,
  questionSetId,
}) => {
  const classes = useStyles();

  const [pageSize, setPageSize] = useState(50);
  const [loading, setLoading] = useState(false);
  const [races, setRaces] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    onLoad();
  }, []);

  // -------------
  // API FUNCTIONS
  // -------------

  const onLoad = async () => {
    setLoading(true);
    setError(false);
    const URL = `/api/v1/guide/${guideId}/race-questions/${questionSetId}/races`;
    const METHOD = 'GET';

    try {
      const racesResponse = await fetchUtil(URL, METHOD, {});
      setRaces(racesResponse.map(r => ({
        ...r,
        category: r.category || { title: 'Uncategorized' },
      })));

      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
      setError(true);
    }
  };

  // ----------------
  // TABLE DEFINITION
  // ----------------

  const truncate = (str, length) => {
    if (str) {
      return str.length > length ? `${str.slice(0, length)}...` : str;
    }
    return '';
  };

  const columns = [
    {
      field: 'name',
      headerName: messages.table.name,
      flex: 1.5,
      renderCell: (params) => (
        <a
          href={`/${params.row.clazz.id === 'F' ? 'measures' : 'races'}/${params.id}`}
        >
          {truncate(params.row.name, 70)}
        </a>
      ),
    },
    {
      field: 'type',
      headerName: messages.table.type,
      valueGetter: (params) => truncate(params.row.state.name, 25),
      flex: 0.5,
    },
    {
      field: 'category',
      headerName: messages.table.category,
      valueGetter: (params) => truncate(params.row.category.title, 25),
      flex: 0.5,
    },
  ];

  const renderEmptyState = () => (
    <EmptyState>
      <p className="ga-m-bottom--large">
        <Interweave content={messages.emptyStateText} />
      </p>
    </EmptyState>
  );

  const renderDataTable = () => {
    if (loading) {
      return (
        <div className='spinner'>
          <CircularProgress />
        </div>
      );
    }

    if (error) {
      return (
        <Alert severity="error">{messages.fetchError}</Alert>
      );
    }

    if (!races || races.length <= 0) {
      return renderEmptyState();
    }

    return (
      <div className={classes.tableContainer}>
        <StyledDataGrid
          autoHeight={true}
          checkboxSelection={false}
          className={classes.table}
          columns={columns}
          onPageSizeChange={size => setPageSize(size)}
          pagination={true}
          pageSize={pageSize}
          rows={races}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
        />
      </div>
    );
  };

  return (
    <Dialog
      aria-labelledby="view-races-using-set"
      maxWidth="lg"
      open={open}
      onClose={onClose}
    >
      <DialogTitle id="view-races-using-set" disableTypography>
        <div>
          <Typography component="h6" variant="h6">
            {messages.heading}:
          </Typography>
          <Typography component="h1" variant="h1">
            {questionSetName}
          </Typography>
        </div>

        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={10}>
            {renderDataTable()}
          </Grid>
          <Grid item xs={2}>
            <HelpfulHint>
              <Interweave content={messages.raceHelpText} />
            </HelpfulHint>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

RacesUsingQuestionSetModal.propTypes = {
  messages: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  questionSetName: PropTypes.string,
  guideId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  questionSetId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

RacesUsingQuestionSetModal.defaultProps = {
  messages: {
    heading: '*Races that use',
    raceHelpText: '*Visit the <a href="/races">Races & Measures Dashboard</a> and edit the races you\'d like to include in this question set, or <a href="/races/create">create</a> a new race now.',
    table: {
      name: '*Name',
      type: '*Type',
      category: '*Category',
    },
    emptyStateText: '*There are no races using this question set. You can updates races from the <a href="/races">Races & Measures</a> dashboard',
    fetchError: '*There was an error fetching races for this question. Please try again.',
  },
  open: false,
  onClose: () => {},
  questionSetName: 'Question Set Name',
};

export default RacesUsingQuestionSetModal;
