import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Link,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import Dot from '@material-ui/icons/FiberManualRecord';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  iconStyles: {
    color: '#3fa06f',
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginBottom: '1.5rem',
  },
  futureStep: {
    color: '#777777',
  },
  inactiveStep: {
    color: '#000000',
  },
  icon: {
    width: '22px',
    height: '22px',
    marginRight: '1rem',
  },
  completedStep: {
    fontWeight: 'bold',
    color: '#000000',
  },
  listBullet: {
    color: '#3fa06f',
    fontSize: '0.75rem',
  },
  list: {
    '& .MuiListItemIcon-root': {
      minWidth: '1.5rem',
    },
    '& .MuiListItem-root': {
      paddingTop: '.25rem',
      paddingBottom: '.25rem',
    },
  },
});

/**
 * Reusable sidebar for step-by-steps & sidebar nav. See StepProgressSidebar.stories.js for usage.
 * @param {array} items Array of steps to display in the sidebar. Each has a name and an optional list of subtasks (for post MVP). Index/Order is important
 * @param {int} currentIndex Current step, relating to index of steps array. 0 means first step is active, 4 means 5th step is active, and 1-4 are completed.
 *                             If isOrdered is true, then only the index given by currentIndex will be affected.
 * @param {bool} isOrdered Whether or not the sidebar should render as an ordered stepper or a normal nav menu.
 * @returns React node
 */
const StepProgressSidebar = ({
  items,
  currentIndex,
  isOrdered,
  usesRouter,
}) => {
  const classes = useStyles();

  // Includes null check
  const renderLink = (item) => {
    if (usesRouter) {
      return (
        <RouterLink to={item.link || '#'}>
          {item.name}
        </RouterLink>
      );
    }
    return (
      <Link href={item.link || '#'} color="inherit">
        {item.name}
      </Link>
    );
  };

  const renderSubitems = (subitem) => (
    <div>
      <List className={classes.list}>
        {subitem.map((task, i) => (
          <ListItem key={i}>
            <ListItemIcon>
              <Dot className={classes.listBullet} />
            </ListItemIcon>
            <ListItemText
              primary={task}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );

  const renderItems = () => (
    items.map((item, index) => {
      // set defaults, for future steps
      let icon = <></>;
      let textClass = isOrdered ? classes.futureStep : classes.inactiveStep;
      let shouldRenderSubtasks = false;
      if (index < currentIndex) {
        // completed steps (or regular inactive if isOrdered is false)
        icon = isOrdered ? <CheckCircleOutlineIcon className={classes.iconStyles} /> : <></>;
        textClass = isOrdered ? classes.completedStep : classes.inactiveStep;
        shouldRenderSubtasks = isOrdered;
      }
      if (index == currentIndex) {
        // active step
        icon = <RadioButtonCheckedIcon className={classes.iconStyles} />;
        textClass = classes.completedStep;
      }
      // render with defaults or new values
      return (
        <div className={classes.rowContainer} key={index}>
          <div className={classes.icon}>
            {icon}
          </div>
          <div>
            <Typography className={textClass}>{isOrdered ? item.name : renderLink(item)}</Typography>
            {shouldRenderSubtasks && item.subitems && (
              <div>
                {renderSubitems(item.subitems)}
              </div>
            )}
          </div>
        </div>
      );
    })
  );

  return (
    <Box padding={2}>
      {renderItems()}
    </Box>
  );
};

StepProgressSidebar.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      subitems: PropTypes.arrayOf( // subtasks are post-MVP, but might as well lay the foundation
        PropTypes.string,
      ),
      link: PropTypes.string,
    }),
  ),
  currentIndex: PropTypes.number,
  isOrdered: PropTypes.bool,
  usesRouter: PropTypes.bool,
};

StepProgressSidebar.defaultProps = {
  items: [],
  currentIndex: 0,
  isOrdered: true,
  usesRouter: false,
};

export default StepProgressSidebar;
