import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clone from 'rfdc/default';
import { ThemeProvider } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import SnackbarAlert from 'components/SnackbarAlert';
import theme from 'scripts/theme';
import {
  Box, Container, Grid, Typography,
} from '@material-ui/core';
import {
  BrowserRouter,
  Route,
  Routes,
} from 'react-router-dom';
import CandidateIntro from 'scripts/candidate/routes/CandidateIntro';
import CandidateDetails from 'scripts/candidate/routes/CandidateDetails';
import CampaignProfile from 'scripts/candidate/routes/CampaignProfile';
import CandidateQuestionList from 'scripts/candidate/routes/CandidateQuestionList';
import CandidateReview from 'scripts/candidate/routes/CandidateReview';
import CandidateThankYou from 'scripts/candidate/routes/CandidateThankYou';
import QuestionClass from 'helpers/QuestionClass';
import BioQuestionClass from 'helpers/BioQuestionClass';
import StepProgressSidebar from 'components/StepProgressSidebar';
import fetchUtil from 'helpers/Fetch';

const useStyles = makeStyles({
  root: {
    height: '100%',
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    padding: '2.4rem 0',
  },
  whiteText: {
    color: '#fff',
  },
  nav: {
    backgroundColor: '#eef4f8',
    height: '100%',
    padding: '4rem 1rem 0',
    '& > ul': {
      listStyle: 'none',
      '& > li': {
        marginBottom: '1rem',
      },
      '& a': {
        textDecoration: 'none',
      },
    },
  },
  main: {
    height: 'calc(100% - 150px)',
    marginTop: '2rem',
    [theme.breakpoints.up('md')]: {
      marginTop: '0',
    },
  },
  bodyContent: {
    padding: '0',
    [theme.breakpoints.up('md')]: {
      padding: '4rem 0 0 2rem',
    },
    marginBottom: '4rem',
  },
});

// **************
// IMPORTANT NOTE:
// DO NOT USE clone(candidateState) ANYWHERE AS IT WILL REMOVE REFERENCES TO THE QuestionClass OBJECT
// USE { ...candidateState } INSTEAD
// **************

const CandidateSPA = ({
  candidate,
  candidateCode,
  guideId,
  typeOptions,
  messages,
  languages,
}) => {
  const rootPath = `/candidates/${candidateCode}/responses`;
  const classes = useStyles();
  const _snackBarRef = useRef();
  const [loading, setLoading] = useState(false);
  const [finishedLoadingAnswers, setFinishedLoadingAnswers] = useState(false);
  // clone here to remove reference to it
  const [candidateState, setCandidateState] = useState(clone(candidate));
  const [submitting, setSubmitting] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [uploadedPhoto, setUploadedPhoto] = useState();
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [guideDefaultAnswerLength, setGuideDefaultAnswerLength] = useState(1000);

  const onLoad = async () => {
    const candidateStateCopy = { ...candidateState };

    try {
      setLoading(true);
      // bio question answer matching
      let bioSetQuestions = [...candidateState.race.bioSet.bioQuestions];
      bioSetQuestions.sort((a, b) => a.index - b.index);
      bioSetQuestions = bioSetQuestions.map(q => {
        const answer = candidateState.bioAnswers.find(a => a.sourceId === q.id);
        const bioQuestionObject = new BioQuestionClass(q, answer);
        return bioQuestionObject;
      });
      candidateStateCopy.race.bioSet.bioQuestions = bioSetQuestions;

      // set question set for step 4
      const raceQuestionsWithoutAnswers = candidateStateCopy.race?.questionSet?.questions
        ? [...candidateStateCopy.race.questionSet.questions]
        : [];
      // set new property in candidate state
      if (candidateStateCopy.race?.questionSet) {
        candidateStateCopy.race.questionSet.formattedQuestions = raceQuestionsWithoutAnswers.map(q => {
          // find answer
          const answer = candidateStateCopy.raceAnswers.find(a => a.sourceId === q.id);
          // construct & return question class object
          return new QuestionClass(q, answer);
        });
      } else {
        // set default empty state when race question set is unassigned
        candidateStateCopy.race.questionSet = {
          formattedQuestions: [],
        };
      }

      // get the default max length of answers from the guide
      const guideDefaultLength = await fetchUtil(`/api/v1/guide/${guideId}/candidateUser/${candidateCode}/defaultMaxAnswerLength`, 'GET', {});
      if (guideDefaultLength && guideDefaultLength.length) {
        setGuideDefaultAnswerLength(guideDefaultLength.length);
      }

      // all done
      setCandidateState(candidateStateCopy);
      setLoading(false);
      setFinishedLoadingAnswers(true);
    } catch (e) {
      console.error(e);
      _snackBarRef.current.show('error', 'There was an error loading this candidate. Please try again.');
    }
  };

  // Component Did Mount
  useEffect(() => {
    onLoad();
  }, []);

  // *******************
  // *******************
  // ON CHANGE FUNCTIONS
  // *******************
  // *******************

  /**
   * Update component state after the user changes one of the contact user fields on step 2
   * @param {string} field Name of the field that was changed
   * @param {string} value Updated value of the field after user change
   */
  const handleContactUserChange = (field, value) => {
    if (Object.prototype.hasOwnProperty.call(candidateState, 'contactUser') && Object.prototype.hasOwnProperty.call(candidateState.contactUser, field)) {
      const candidateCopy = { ...candidateState };
      candidateCopy.contactUser[field] = value;
      setCandidateState(candidateCopy);
    }
  };

  /**
   * Function to update the candidate state based on user input into a bio question answer field
   * @param {int} questionId id corresponding to bio question
   * @param {string} newValue Updated answer based on user input
   * @param {string} fieldName Field name of mailing address field, defaulted to empty string for other question types
   */
  const handleBioAnswerChange = (questionId, newValue, fieldName = '') => {
    const candidateStateCopy = { ...candidateState };
    // find the index of the bio question in the candidate state, so that we can edit it in place and save the headache of replacing with a copy
    const questionChanged = candidateStateCopy.race.bioSet.bioQuestions.find(q => q.id === questionId);
    if (questionChanged.type == BioQuestionClass.ADDRESS_TYPE) {
      questionChanged.updateMailingAddress(fieldName, newValue);
    } else {
      questionChanged.updateAnswer(newValue);
    }
    setCandidateState(candidateStateCopy);
  };

  /**
   * Update the component state to include the temporary URL to the updated photo
   * @param {File} newPhotoFile File that was uploaded by the user
   */
  const handleProfilePicChange = (newPhotoFile) => {
    if (newPhotoFile.length > 0) {
      setUploadedPhoto(newPhotoFile[0]);

      const photoPreviewUrl = URL.createObjectURL(newPhotoFile[0]);
      const candidateStateCopy = { ...candidateState };
      candidateStateCopy.imageUri = photoPreviewUrl;
      setCandidateState(candidateStateCopy);
    }
  };

  const handleProfilePicRemoved = () => {
    const candidateStateCopy = { ...candidateState };
    candidateStateCopy.imageUri = null;
    candidateStateCopy.candidatePhotoSourceRemoved = true;
    setUploadedPhoto(null);
    setCandidateState(candidateStateCopy);
  };

  /**
   * Update state for a change to a race answer by the user
   * @param {number} questionId Id of the race question that was changed
   * @param {string} languageId 2 char language code for text questions, unused for radio, and 'url' or 'desc' for youtube questions
   * @param {*} newValue Value after user update
   */
  const handleRaceAnswerChange = (questionId, languageId, newValue) => {
    const candidateStateCopy = { ...candidateState };
    const questionChanged = candidateStateCopy.race.questionSet.formattedQuestions.find(q => q.id === questionId);
    // call updateAnswer, defined in QuestionClass.js
    if (questionChanged.type !== 4) {
      questionChanged.updateAnswer(languageId, newValue);
    } else if (languageId === 'url') {
      // this and below are for youtube questions
      questionChanged.updateYoutubeURL(newValue);
    } else {
      questionChanged.updateYoutubeDescription(newValue);
    }
    setCandidateState(candidateStateCopy);
  };

  // *********************
  // *********************
  // STATE RESET FUNCTIONS
  // *********************
  // *********************

  // on skip on details page
  const handleResetCandidateDetailsState = () => {
    const candidateCopy = { ...candidateState };
    candidateCopy.contactUser.name = candidate.contactUser.name;
    candidateCopy.contactUser.email = candidate.contactUser.email;
    setCandidateState(candidateCopy);
  };

  // on skip on profile page
  const handleResetProfileState = () => {
    const candidateStateCopy = { ...candidateState };
    // reset photo
    candidateStateCopy.imageUri = candidate.imageUri;
    candidateStateCopy.candidatePhotoSourceRemoved = null;
    // reset bio questions by looking for the answers from the OG candidate object
    candidateStateCopy.race.bioSet.bioQuestions = candidateStateCopy.race.bioSet.bioQuestions.map(q => {
      const answer = candidate.bioAnswers.find(a => a.sourceId === q.id);
      const bioQuesitonObject = new BioQuestionClass(q, answer);
      return bioQuesitonObject;
    });
    setCandidateState(candidateStateCopy);
  };

  // on skip on questions page
  const handleResetRaceQuestionState = () => {
    const candidateStateCopy = { ...candidateState };

    // set question set for step 4
    const raceQuestionsWithoutAnswers = candidate.race && candidate.race.questionSet && candidate.race.questionSet.questions
      ? [...candidate.race.questionSet.questions]
      : [];
    // set new property in candidate state
    candidateStateCopy.race.questionSet.formattedQuestions = raceQuestionsWithoutAnswers.map(q => {
      // find answer
      const answer = candidate.raceAnswers.find(a => a.sourceId === q.id);
      // construct & return question class object
      return new QuestionClass(q, answer);
    });
    setCandidateState(candidateStateCopy);
  };

  // ********************
  // ********************
  // STATE SAVE FUNCTIONS
  // ********************
  // ********************

  /**
   * Submit the updated contact info to the database.
   * On success, go to the next step (handled in child component)
   * On failure, show toast and error
   * @param {func} callback On success callback to trigger movement to next page
   */
  const handleSaveContactInfo = async (callback) => {
    try {
      // turn on submitting indicator
      setSubmitting(true);
      const METHOD = 'PUT';
      const URL = `/api/v1/guide/${guideId}/candidateUser/${candidateCode}/contact`;
      // update candidate to be identical but with updated state
      const BODY = {
        // these are the two to update
        name: candidateState.contactUser.name,
        email: candidateState.contactUser.email,
      };
      await fetchUtil(URL, METHOD, BODY);
      setSubmitting(false);
      _snackBarRef.current.show('success', 'Contact information updated.');
      callback();
    } catch (e) {
      setSubmitting(false);
      console.error(e);
      setFieldErrors(e);
      _snackBarRef.current.show('error', 'There was an error updating the candidate. Please try again.');
    }
  };

  /**
   * Submit the updated bio question answers to the database.
   * On success, go to next step.
   * On failure, show toast and applicable errors.
   * @param {func} callback On success callback to trigger movement to next page
   */
  const handleSaveProfile = async (callback) => {
    setSubmitting(true);
    const bioQuestionErrors = candidateState.race.bioSet.bioQuestions.filter(question => Object.keys(question.errors).length > 0);
    if (bioQuestionErrors.length === 0) {
      try {
        // begin
        const candidateStateCopy = { ...candidateState };

        // save bio questions
        await Promise.all(candidateStateCopy.race.bioSet.bioQuestions.map(async (question) => {
          const METHOD = 'PUT';
          const BODY = {
            questionId: question.id,
            answer: question.apiAnswer,
          };
          if (question.answerId !== -1) {
            // answered before
            BODY.id = question.answerId;
          }
          const res = await fetchUtil(`/api/v1/guide/${guideId}/candidateUser/${candidateCode}/bioanswers`, METHOD, BODY);
          question.answerId = res.id;
        }));

        if (candidateState.candidatePhotoSourceRemoved) {
          await fetchUtil(`/api/v1/guide/${guideId}/candidateUser/${candidateCode}/removePhoto`, 'POST', {});
        }

        // save profile picture
        if (uploadedPhoto && candidate.contactUser?.code) {
          const formData = new FormData();
          formData.append(
            'photo',
            uploadedPhoto,
            uploadedPhoto.name,
          );
          await fetchUtil(`/api/v1/guide/${guideId}/candidateUser/${candidate.contactUser.code}/uploadPhoto`, 'POST', formData, false, true);
        }

        // and finally,
        setCandidateState(candidateStateCopy);
        setSubmitting(false);
        _snackBarRef.current.show('success', 'Profile information updated.');
        callback();
      } catch (e) {
        setSubmitting(false);
        console.error(e);
        setFieldErrors(e);
        if (e.data) {
          _snackBarRef.current.show('error', e.data);
        } else {
          _snackBarRef.current.show('error', 'There was an error updating the candidate. Please try again.');
        }
      }
    } else {
      setSubmitting(false);
      _snackBarRef.current.show('error', 'There was an error updating the candidate. Please try again.');
    }
  };

  /**
   * Save updated race question answers to the database
   * @param {func} callback On success callback to go to next step
   */
  const handleSaveRaceQuestions = async (callback) => {
    try {
      setSubmitting(true);
      const candidateStateCopy = { ...candidateState };

      // save each race question answer
      await Promise.all(candidateStateCopy.race.questionSet.formattedQuestions.map(async (question) => {
        const METHOD = 'PUT';
        const URL = `/api/v1/guide/${guideId}/candidateUser/${candidateCode}/raceanswers`;
        // cool es6 getter!
        const BODY = question.apiBody;

        const res = await fetchUtil(URL, METHOD, BODY);
        question.answerId = res.id;
      }));

      setCandidateState(candidateStateCopy);
      setSubmitting(false);
      _snackBarRef.current.show('success', 'Answers updated.');
      callback();
    } catch (e) {
      setSubmitting(false);
      console.error(e);
      setFieldErrors(e);
      _snackBarRef.current.show('error', 'There was an error updating the answers. Please try again.');
    }
  };

  const handleFinalReviewSubmit = async (callback) => {
    try {
      setSubmitting(true);

      const METHOD = 'PUT';
      const URL = `/api/v1/guide/${guideId}/candidateUser/${candidateCode}/submit`;
      const BODY = {};

      await fetchUtil(URL, METHOD, BODY);
      setSubmitting(false);
      callback();
    } catch (e) {
      setSubmitting(false);
      console.error(e);
      setFieldErrors(e);
      _snackBarRef.current.show('error', 'There was an error submitting your responses. Please try again.');
    }
  };

  // const visibleBioFields = candidateState.race.bioSet.bioQuestions.filter(bq => (bq.isVisible));

  const sidebarSteps = [
    { name: messages.nav.intro, link: '/' },
    { name: messages.nav.details, link: '/details' },
    { name: messages.nav.profile, link: '/profile' },
    { name: messages.nav.questions, link: '/questions' },
    { name: messages.nav.review, link: '/review' },
  ];

  // ***************
  // ***************
  // RENDER FUNCTION
  // ***************
  // ***************

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter basename={rootPath}>
        <SnackbarAlert ref={_snackBarRef} />

        <Grid container className={classes.root}>
          <Grid item xs={12}>
            {/* Page Header */}
            <div className={classes.header}>
              <Container>
                <Typography variant="h1" component="h1" className={classes.whiteText}>
                  {messages.title}
                </Typography>

                <Typography variant="body1" component="p" className={classes.whiteText}>
                  {messages.supporting}
                </Typography>
              </Container>
            </div>

            {!loading && (
              <Container className={classes.main}>
                <Grid container className="full-height">
                  {/* Side Navigation */}
                  <Box
                    component={Grid}
                    item
                    xs={3}
                    display={{ xs: 'none', md: 'block' }}
                    className={classes.nav}
                  >
                    <StepProgressSidebar
                      items={sidebarSteps}
                      currentIndex={currentPageIndex}
                      isOrdered={true}
                    />
                  </Box>

                  {/* Body Content */}
                  <Grid item xs={12} md={9} className={classes.bodyContent}>
                    <Routes>
                      <Route path={'/'} element={<CandidateIntro updatePage={setCurrentPageIndex} messages={messages.intro} />} />
                      <Route
                        path={'/details'}
                        element={
                          <CandidateDetails
                            email={candidateState.contactUser.email}
                            fields={[
                              {
                                label: 'Name',
                                values: [`${candidateState.name}`],
                              },
                              {
                                label: 'Party',
                                values: candidateState.parties.map(party => party.name),
                              },
                              {
                                label: 'Running For',
                                values: [candidateState.race.name],
                              },
                            ]}
                            name={candidateState.contactUser.name}
                            onChange={handleContactUserChange}
                            resetState={handleResetCandidateDetailsState}
                            saveChanges={handleSaveContactInfo}
                            submitting={submitting}
                            errors={fieldErrors}
                            messages={messages.details}
                            updatePage={setCurrentPageIndex}
                          />
                        }
                      />
                      <Route
                        path={'/profile'}
                        element={
                          <CampaignProfile
                            bioFields={finishedLoadingAnswers ? candidateState.race.bioSet.bioQuestions.filter(bq => (bq.edit)) : []}
                            errors={fieldErrors}
                            handleChangedFile={handleProfilePicChange}
                            handleRemovedPhoto={handleProfilePicRemoved}
                            loading={loading}
                            onChange={handleBioAnswerChange}
                            existingPhotoURL={candidateState.imageUri}
                            resetState={handleResetProfileState}
                            saveChanges={handleSaveProfile}
                            messages={messages.profile}
                            submitting={submitting}
                            updatePage={setCurrentPageIndex}
                            didRemove={candidateState.candidatePhotoSourceRemoved}
                          />
                        }
                      />
                      <Route
                        path={'/questions'}
                        element={
                          <CandidateQuestionList
                            languages={languages}
                            onChange={handleRaceAnswerChange}
                            submitting={submitting}
                            questions={finishedLoadingAnswers ? candidateState.race.questionSet.formattedQuestions : []}
                            questionTypeOptions={typeOptions}
                            resetState={handleResetRaceQuestionState}
                            saveChanges={handleSaveRaceQuestions}
                            messages={messages.questions}
                            updatePage={setCurrentPageIndex}
                            defaultAnswerLength={guideDefaultAnswerLength}
                          />
                        }
                      />
                      <Route
                        path={'/review'}
                        element={
                          <CandidateReview
                            detailsFields={[
                              { label: 'Name', value: candidateState.name },
                              { label: 'Party', value: candidateState.parties.map(party => party.name).join(', ') },
                              { label: 'Running For', value: candidateState.race.name },
                              { label: messages.contactNameLabel, value: candidateState.contactUser.name },
                              { label: messages.contactEmailLabel, value: candidateState.contactUser.email },
                            ]}
                            imageURL={candidateState.imageUri || ''}
                            profileFields={finishedLoadingAnswers ? candidateState.race.bioSet.bioQuestions.filter(bq => (bq.edit)).map(bq => (
                              { label: bq.question, value: bq.frontEndAnswer }
                            )) : []}
                            questionFields={finishedLoadingAnswers && candidateState.race.questionSet.formattedQuestions ? candidateState.race.questionSet.formattedQuestions.map(rq => rq.reviewCard) : []}
                            saveChanges={handleFinalReviewSubmit}
                            messages={messages.review}
                            updatePage={setCurrentPageIndex}
                          />
                        }
                      />
                      <Route
                        path={'/thank-you'}
                        element={<CandidateThankYou messages={messages.thankYou} />}
                      />
                    </Routes>
                  </Grid>
                </Grid>
              </Container>
            )}
          </Grid>
        </Grid>
      </BrowserRouter>
    </ThemeProvider>
  );
};

CandidateSPA.propTypes = {
  candidate: PropTypes.shape({
    id: PropTypes.number,
    race: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      bioSet: PropTypes.object,
      questionSet: PropTypes.object,
    }),
    raceAnswers: PropTypes.array,
    name: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    occupation: PropTypes.string,
    parties: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })).isRequired,
    contactUser: PropTypes.shape({
      email: PropTypes.string,
      name: PropTypes.string,
      code: PropTypes.string,
    }).isRequired,
    imageUri: PropTypes.string,
    bioAnswers: PropTypes.array.isRequired,
  }),
  candidateCode: PropTypes.string.isRequired,
  languages: PropTypes.array.isRequired,
  messages: PropTypes.object.isRequired,
  guideId: PropTypes.string.isRequired,
  typeOptions: PropTypes.array.isRequired,
};

CandidateSPA.defaultProps = {
  candidate: {},
};

export default CandidateSPA;
