import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import UserRow from 'components/UsersAndRoles/UserRow';
import { Grid, Button } from '@material-ui/core';
import FabWithText from 'components/FabWithText';

const useStyles = makeStyles({
  actionContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  listContainer: {
    paddingBottom: '2.625rem',
  },
  rowItem: {
    paddingBottom: '1.25rem',
    marginBottom: '1.25rem',
    borderBottom: '1px solid #f2f2f2',
    '&:last-child': {
      borderBottom: 'none',
      paddingBottom: '0rem',
      marginBottom: '0rem',
    },
  },
});

function UserRowList({
  addText,
  onAdd,
  onChange,
  onDelete,
  onSave,
  saveText,
  submitting,
  users,
}) {
  const classes = useStyles();

  const userArray = users.map((user, index) => (
    <Grid item key={index} sm={12} className={classes.rowItem}>
      <UserRow
        email={user.email}
        errors={user.errors}
        id={user.id}
        index={index}
        login={user.login}
        role={user.role}
        onChange={onChange}
        onDelete={onDelete}
        submitting={submitting || user.submitting}
      />
    </Grid>
  ));

  return (
    <div>
      <Grid container className={classes.listContainer}>
        {userArray}
      </Grid>
      <div className={classes.actionContainer}>
        <FabWithText
          buttonText={addText}
          onClick={onAdd}
          disabled={submitting}
        />
        <div>
          <Button
            variant='contained'
            color='secondary'
            onClick={onSave}
            size='large'
            className={classes.saveButton}
            disabled={submitting}
          >
            {saveText}
          </Button>
        </div>
      </div>
    </div>
  );
}

UserRowList.propTypes = {
  addText: PropTypes.string,
  users: PropTypes.arrayOf(PropTypes.shape({
    email: PropTypes.string,
    errors: PropTypes.object,
    id: PropTypes.number,
    login: PropTypes.string,
    role: PropTypes.string,
    submitting: PropTypes.bool,
  })),
  onAdd: PropTypes.func,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  onSave: PropTypes.func,
  saveText: PropTypes.string,
  submitting: PropTypes.bool,
};

UserRowList.defaultProps = {
  addText: 'Add another user',
  users: [],
  onAdd: () => {},
  onChange: () => {},
  onDelete: () => {},
  onSave: () => {},
  saveText: 'Save',
  submitting: false,
};

export default UserRowList;
