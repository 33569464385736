import React, {
  memo,
  useEffect,
  useMemo,
  useContext,
  useState,
} from 'react';
import propTypes from 'prop-types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Box, Button } from '@material-ui/core';

import { partyJoin } from 'helpers/party';
import truncate from 'helpers/truncate';

import { MessagesContext } from 'components/messages';
import { ServerPaginatedDataGrid, useStyles } from 'components/DataGrid';
import ConfirmationDialog from 'components/ConfirmationDialog';

import { useDataGridParams } from 'hooks/useDataGridParams';

import {
  bulkDeleteFinanceCandidateMatch,
  bulkPostCandidateFinanceMatch,
  deleteCandidateFinanceMatch,
  FETCH_TYPE_MATCHED,
  FETCH_TYPE_UNMATCHED,
  postCandidateFinanceMatch,
  useCandidateFinanceMatches,
} from '@/features/campaign-finance';

const ACTION_TYPE_MATCH = 'match';
const ACTION_TYPE_UNMATCH = 'unmatch';
const ACTION_TYPE_MATCH_ALL = 'matchAll';
const ACTION_TYPE_UNMATCH_ALL = 'unmatchAll';

export const CampaignFinanceDetailTableContainer = memo(({
  cfRecordId,
  isTest,
  onActionError,
  onActionSuccess,
  onSubmittingChange,
  searchedInput,
  tableHeading,
  type,
}) => {
  // region: State

  // Context
  const { messages } = useContext(MessagesContext);

  // State
  const [dialogConfig, setDialogConfig] = useState({
    open: false,
    action: null,
    candidateIds: [],
  });
  const [dialogSubmitting, setDialogSubmitting] = useState(false);

  // Query Client
  const queryClient = useQueryClient();

  // Styles
  const classes = useStyles();

  // Manage Data Grid Settings
  const {
    addHiddenSelectedRows,
    filterModel,
    getHiddenPlaceholder,
    getRowClassName,
    onSelectionModelChange,
    onPageChange,
    onPageSizeChange,
    page,
    pageSelectedCount,
    pageSize,
    selectedRows,
    setFilterModel,
    setSortModel,
    sortModel,
    totalSelectedCount,
    hiddenColumns,
    setHiddenColumns,
  } = useDataGridParams({ tableSettingsKey: `campaign_finance_detail_table_settings_${type}` });

  // Fetch candidates by keyword
  const {
    data,
    isError,
    isLoading,
    isFetching,
    queryKey,
  } = useCandidateFinanceMatches({
    dataGridParams: {
      page,
      pageSize,
      search: searchedInput,
      sortModel,
    },
    type,
    cfRecordId,
  });

  // region: Mutations

  // API mutation to match candidates to the campaign finance record
  const matchMutation = useMutation({
    mutationFn: (variables) => {
      if (!variables?.candidateIds || !Array.isArray(variables?.candidateIds)) {
        // If the candidate array is undefined, bulk delete will assume ALL finance/candidate joins
        // should be removed for the finance record. To avoid this, we throw an error.
        throw new Error('Invalid candidate ID(s). Unable to determine the matching action to perform.');
      }

      if (!variables?.action || (variables.action !== ACTION_TYPE_MATCH_ALL && variables.action !== ACTION_TYPE_MATCH)) {
        throw new Error('Invalid action. Unable to determine the matching action to perform.');
      }

      if (variables.action === ACTION_TYPE_MATCH_ALL) {
        return bulkPostCandidateFinanceMatch(cfRecordId, variables.candidateIds);
      }

      if (variables.candidateIds.length !== 1) {
        throw new Error('Invalid candidate ID. Did you mean to use the bulk action?');
      }

      return postCandidateFinanceMatch(variables.candidateIds[0], cfRecordId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      onActionSuccess(messages.matchSuccess);
    },
    onError: (matchingError) => {
      console.error('Match error:', matchingError);
      onActionError(messages.matchError);
    },
    onSettled: () => {
      onSubmittingChange(false);
      setDialogConfig({ open: false, action: null, candidateIds: [] });
      onSelectionModelChange([]);
    },
    retry: 2,
  });

  // API mutation to unmatch candidates from the campaign finance record
  const unmatchMutation = useMutation({
    mutationFn: (variables) => {
      if (!variables?.candidateIds || !Array.isArray(variables?.candidateIds)) {
        // If the candidate array is undefined, bulk delete will assume ALL finance/candidate joins
        // should be removed for the finance record. To avoid this, we throw an error.
        throw new Error('Invalid candidate ID(s). Unable to determine the action to perform.');
      }

      if (!variables?.action || (variables.action !== ACTION_TYPE_UNMATCH_ALL && variables.action !== ACTION_TYPE_UNMATCH)) {
        throw new Error('Invalid action. Unable to determine the action to perform.');
      }

      if (variables.action === ACTION_TYPE_UNMATCH_ALL) {
        return bulkDeleteFinanceCandidateMatch(cfRecordId, variables.candidateIds);
      }

      if (variables.candidateIds.length !== 1) {
        throw new Error('Invalid candidate ID. Did you mean to use the bulk action?');
      }

      return deleteCandidateFinanceMatch(variables.candidateIds[0], cfRecordId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      onActionSuccess(messages.unmatchSuccess);
    },
    onError: (unmatchingError) => {
      console.error('unmatch error', unmatchingError);
      onActionError(messages.unmatchError);
    },
    onSettled: () => {
      onSubmittingChange(false);
      setDialogConfig({ open: false, action: null, candidateIds: [] });
      onSelectionModelChange([]);
    },
    retry: 2,
  });

  // region: Effects

  useEffect(() => {
    onSelectionModelChange([]);
  }, [searchedInput]);

  // region: Actions

  const handleMatch = (candidateId) => {
    onSubmittingChange(true);
    matchMutation.mutate({ action: ACTION_TYPE_MATCH, candidateIds: [candidateId] });
  };

  const handleRemoveMatchClick = (candidateId) => {
    openDialog(ACTION_TYPE_UNMATCH, [candidateId]);
  };

  const handleBulkAction = (rowIds) => {
    const actionType = type === FETCH_TYPE_MATCHED ? ACTION_TYPE_UNMATCH_ALL : ACTION_TYPE_MATCH_ALL;
    openDialog(actionType, rowIds);
  };

  // region: Dialog

  const handleDialogConfirm = () => {
    // Disable search input and dialog buttons while mutating
    onSubmittingChange(true);
    setDialogSubmitting(true);

    // Perform the correct match or unmatch action
    if (dialogConfig.action === ACTION_TYPE_UNMATCH || dialogConfig.action === ACTION_TYPE_UNMATCH_ALL) {
      unmatchMutation.mutate({ candidateIds: dialogConfig.candidateIds, action: dialogConfig.action });
    } else if (dialogConfig.action === ACTION_TYPE_MATCH_ALL) {
      matchMutation.mutate({ candidateIds: dialogConfig.candidateIds, action: dialogConfig.action });
    }

    // Re-enable search input and dialog buttons
    onSubmittingChange(false);
    setDialogSubmitting(false);
    setDialogConfig({ open: false, action: null, candidateIds: [] });
  };

  const handleDialogCancel = () => {
    setDialogConfig({ open: false, action: null, candidateIds: [] });
  };

  const openDialog = (action, candidateIds) => {
    setDialogConfig({ open: true, action, candidateIds });
  };

  // region: Table def
  const tableColumns = useMemo(() => [
    {
      field: 'name',
      headerName: messages.unmatched?.table?.header?.name,
      flex: 1.2,
      filterable: false,
      hide: hiddenColumns.includes('name'),
      renderCell: (params) => {
        const truncated = truncate(params.row.name, 90);
        const displayName = truncated !== '' ? truncated : 'NO NAME';

        return (
          <Box sx={{ fontWeight: '600' }}>
            {params.row.hidden ? getHiddenPlaceholder() : displayName}
          </Box>
        );
      },
    },
    {
      field: 'parties',
      headerName: messages.unmatched?.table?.header?.party,
      flex: 0.6,
      filterable: false,
      hide: hiddenColumns.includes('parties'),
      valueGetter: (params) => {
        if (params.row.hidden) {
          return getHiddenPlaceholder();
        }
        return partyJoin(params.row.parties);
      },
    },
    {
      field: 'race',
      headerName: messages.unmatched?.table?.header?.race,
      flex: 1,
      filterable: false,
      hide: hiddenColumns.includes('race'),
      valueGetter: (params) => {
        if (params.row.hidden) {
          return getHiddenPlaceholder();
        }
        return truncate(params.row.raceName, 50);
      },
    },
    {
      field: 'category',
      headerName: 'Category',
      filterable: false,
      flex: 1,
      hide: hiddenColumns.includes('category'),
      valueGetter: (params) => {
        if (params.row.hidden) {
          return getHiddenPlaceholder();
        }
        // map to category names, or the string 'Uncategorized' if no categories are found
        if (params.row.categories && params.row.categories.length > 0) {
          const categories = (params.row.categories).map((category) => category.title ?? '');
          const truncated = truncate(categories.join(', '), 50);
          return truncated;
        }
        return 'Uncategorized';
      },
    },
    {
      field: 'voterGuide',
      headerName: messages.unmatched?.table?.header?.guide,
      flex: 0.78,
      filterable: false,
      hide: hiddenColumns.includes('voterGuide'),
      valueGetter: (params) => {
        if (params.row.hidden) {
          return getHiddenPlaceholder();
        }
        return truncate(params.row.voterGuide, 50);
      },
    },
    {
      field: 'actions',
      headerName: messages.unmatched?.table?.header?.actions,
      resizable: false,
      width: 180,
      filterable: false,
      sortable: false,
      hide: hiddenColumns.includes('actions'),
      renderCell: (params) => (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {type === FETCH_TYPE_UNMATCHED ? (
            <Button
              variant="outlined"
              color="primary"
              className={classes.actionButton}
              size="small"
              onClick={() => handleMatch(params.id)}
            >
              {messages.unmatched.table.actions.match}
            </Button>
          ) : (
            <Button
              variant="outlined"
              color="default"
              className={`${classes.actionButton} ${classes.dangerButton}`}
              size="small"
              onClick={() => handleRemoveMatchClick(params.id)}
            >
              {messages.matched.table.actions.unmatch}
            </Button>
          )}
        </Box>
      ),
    },
  ], [type, handleMatch, handleRemoveMatchClick, hiddenColumns]);

  const tableBulkActions = [
    {
      color: type === FETCH_TYPE_MATCHED ? 'danger' : 'primary',
      name:
        type === FETCH_TYPE_MATCHED
          ? messages.matched.table.actions.unmatchAll
          : messages.unmatched.table.actions.matchAll,
      onClick: () => handleBulkAction(selectedRows),
    },
  ];

  const tableCandidates = useMemo(() => addHiddenSelectedRows(data), [addHiddenSelectedRows, data]);

  // region: Render

  return (
    <>
      <ConfirmationDialog
        cancelButtonText={messages.dialogs?.cancel}
        confirmButtonText={messages.dialogs?.[dialogConfig.action]?.confirm}
        heading={messages.dialogs?.[dialogConfig.action]?.heading}
        onCancel={handleDialogCancel}
        onConfirm={handleDialogConfirm}
        open={dialogConfig.open}
        submitting={dialogSubmitting}
      >
        {messages.dialogs?.[dialogConfig.action]?.content || ''}
      </ConfirmationDialog>

      <ServerPaginatedDataGrid
        bulkActions={tableBulkActions}
        checkboxSelection={true}
        columns={tableColumns}
        emptyStateText={type === FETCH_TYPE_UNMATCHED ? messages.unmatched?.table?.empty : messages.matched?.table?.empty}
        error={messages.tableError}
        filterModel={filterModel}
        getRowClassName={getRowClassName}
        heading={tableHeading || ''}
        isError={isError}
        isFetching={isFetching}
        isLoading={isLoading}
        isTest={isTest}
        onColumnVisibilityChange={setHiddenColumns}
        onFilterModelChange={setFilterModel}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onSelectionModelChange={onSelectionModelChange}
        onSortModelChange={setSortModel}
        page={page}
        pageSelectedCount={pageSelectedCount}
        pageSize={pageSize}
        rowCount={data?.totalRows || 0}
        rows={tableCandidates}
        selectionModel={selectedRows}
        sortModel={sortModel}
        totalSelectedCount={totalSelectedCount}
      />
    </>
  );
});

CampaignFinanceDetailTableContainer.propTypes = {
  cfRecordId: propTypes.number.isRequired,
  isTest: propTypes.bool,
  searchedInput: propTypes.string,
  onSubmittingChange: propTypes.func,
  onActionSuccess: propTypes.func,
  onActionError: propTypes.func,
  tableHeading: propTypes.string,
  type: propTypes.oneOf([FETCH_TYPE_MATCHED, FETCH_TYPE_UNMATCHED]).isRequired,
};

CampaignFinanceDetailTableContainer.defaultProps = {
  isTest: false,
  searchedInput: '',
  onSubmittingChange: () => {},
  onActionSuccess: () => {},
  onActionError: () => {},
  tableHeading: 'Voter Guide Candidates',
};
