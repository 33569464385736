import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Box,
  CircularProgress,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PageActionsHeader from 'components/PageActionsHeader';
import PageActionsFooter from 'components/PageActionsFooter';
import PageHeader from 'components/PageHeader';
import SnackbarAlert from 'components/SnackbarAlert';
import ConfirmationDialog from 'components/ConfirmationDialog';
import EmailForm from 'components/MailCenter/Partials/EmailForm';
import fetchUtil from 'helpers/Fetch';
import GaValidations from 'helpers/GaValidations';
import theme from 'scripts/theme';

const useStyles = makeStyles({
  actionButton: {
    margin: '8px 20px',
    minWidth: '50px',
    textTransform: 'none',
  },
  danger: {
    color: '#ac1b3d',
    '&:hover': {
      color: '#6B1126',
    },
  },
});

const EditTemplateContainer = ({
  breadcrumbText,
  breadcrumbUrl,
  guideId,
  messages,
  templateTypes,
}) => {
  const _snackbar = useRef();
  const classes = useStyles();

  const [submitting, setSubmitting] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({}); // add setFieldErrors when API call is implemented
  const [loading, setLoading] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [templateId, setTemplateId] = useState(0);

  // Fields state
  const [templateType, setTemplateType] = useState(templateTypes[0]);
  const [templateName, setTemplateName] = useState('');

  const [senderName, setSenderName] = useState('');
  const [responder, setResponder] = useState('');
  const [bcc, setBCC] = useState('');
  const [subject, setSubject] = useState('');
  const [savedWYSIWYGValue, setSavedWYSIWYGValue] = useState('<p></p>');
  const [renderedWYSIWYGValue, setRenderedWYSIWYGValue] = useState('<p></p>');
  const responderEmailId = 'responderEmail';
  const bccId = 'bcc';

  const [templateIsDefault, setTemplateIsDefault] = useState(false);

  const handleWYSIWYGChange = (htmlState) => {
    setSavedWYSIWYGValue(htmlState);
  };

  const confirmCancelDialog = () => {
    window.location.assign('/mail');
  };

  // function to redirect to main menu on cancel, with confirm dialog
  const handleCancel = () => {
    setOpenCancelDialog(true);
  };

  const isValidEmailTemplate = () => {
    const errors = {};
    // checking responder email
    GaValidations.localValidations(
      responderEmailId,
      messages.fields.responder.label,
      [GaValidations.IsEmail],
      responder,
      errors,
    );
    // checking bcc email
    GaValidations.localValidations(
      bccId,
      messages.fields.bcc.label,
      [GaValidations.IsEmail],
      bcc,
      errors,
    );
    // Setting errors
    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      return false;
    }
    return true;
  };

  // Async function to save the template to the database, and redirect to the dashboard
  const handleSave = async () => {
    if (!isValidEmailTemplate()) {
      // Note: The errorEditTemplate message will be added in task 1112
      _snackbar.current.show('error', messages.errors.errorEditTemplate);
      return;
    }

    setSubmitting(true);
    try {
      await fetchUtil(`/api/v1/guide/${guideId}/email-template/${templateId}`, 'PUT', {
        name: templateName,
        subject,
        responderEmail: responder,
        senderName,
        bcc,
        body: savedWYSIWYGValue,
        emailTemplateTypeValue: templateType.id,
      });
      // url param to show snackbar on the dashboard
      window.location.href = '/mail?showSuccessMsgFrom=editTemplate';
    } catch (e) {
      // ERROR HANDLING HERE (isn't that so easy, nice work everyone)
      setFieldErrors(e);
      console.error(e);
      _snackbar.current.show('error', messages.errors.errorEditTemplate);
    }

    setSubmitting(false);
  };

  const handleDelete = async () => {
    try {
      await fetchUtil(`/api/v1/guide/${guideId}/email-template/${templateId}`, 'DELETE', {});
      // url param to show snackbar on the dashboard
      window.location.href = '/mail?showSuccessMsgFrom=deleteTemplate';
    } catch (e) {
      console.error(e);
      _snackbar.current.show('error', 'There was an issue deleting the template. Please try again.');
    }
    setOpenDeleteDialog(false);
  };

  const loadTemplate = async () => {
    setLoading(true);
    const url = new URL(window.location.href);
    if (url.searchParams.has('id')) {
      const templateIdFromURL = url.searchParams.get('id');
      setTemplateId(templateIdFromURL);

      try {
        const templateRes = await fetchUtil(`/api/v1/guide/${guideId}/email-template/${templateIdFromURL}`, 'GET', {});
        setTemplateIsDefault(templateRes.isDefault);
        setTemplateType(templateRes.type);
        setTemplateName(templateRes.name);
        setSenderName(templateRes.senderName);
        setResponder(templateRes.senderAddress);
        setBCC(templateRes.bcc);
        setSubject(templateRes.subject);
        setSavedWYSIWYGValue(templateRes.body);
        setRenderedWYSIWYGValue(templateRes.body);
        setLoading(false);
      } catch (e) {
        console.error(e);
        _snackbar.current.show('error', messages.errors.invalidTemplate);
      }
    } else {
      console.error('No template id passed');
      _snackbar.current.show('error', messages.errors.noTemplateId);
    }
  };

  // ON LOAD
  useEffect(() => {
    loadTemplate();
  }, []);

  // CONSTANTS
  const fields = [
    {
      id: 'type',
      value: templateType.name,
      label: messages.fields.type.label,
      readOnly: true,
      type: 'text',
      width: 'full',
      required: false,
    },
    {
      id: 'name',
      value: templateName,
      handleValueChange: (ev) => setTemplateName(ev.target.value),
      label: messages.fields.name.label,
      readOnly: false,
      type: 'text',
      width: 'full',
      required: true,
    },
    {
      id: 'senderName',
      value: senderName,
      handleValueChange: (ev) => setSenderName(ev.target.value),
      label: messages.fields.sender.label,
      help: messages.fields.sender.help,
      readOnly: false,
      type: 'text',
      width: 'full',
      required: true,
    },
    {
      id: responderEmailId,
      value: responder,
      handleValueChange: (ev) => setResponder(ev.target.value),
      label: messages.fields.responder.label,
      help: messages.fields.responder.help,
      readOnly: false,
      type: 'email',
      width: 'half',
      required: true,
    },
    {
      id: bccId,
      value: bcc,
      handleValueChange: (ev) => setBCC(ev.target.value),
      label: messages.fields.bcc.label,
      help: messages.fields.bcc.help,
      readOnly: false,
      type: 'multi-email',
      width: 'half',
      required: false,
    },
    {
      id: 'subject',
      value: subject,
      handleValueChange: (ev) => setSubject(ev.target.value),
      label: messages.fields.subject.label,
      readOnly: false,
      type: 'text',
      width: 'full',
      required: true,
    },
  ];

  // MAYBE PULL FROM API? FOR NOW LEAVING THEM AS RAW TEXT.
  const macros = [
    {
      title: '#question_list()',
      description: messages.macros.questionList,
    },
    {
      title: '#question_responses()',
      description: messages.macros.questionsResponses,
    },
    {
      title: '#mailing_address()',
      description: messages.macros.mailingAddress,
    },
    {
      title: '#today()',
      description: messages.macros.today,
    },
    {
      title: '#candidate_name()',
      description: messages.macros.candidateName,
    },
    {
      title: '#contact_name()',
      description: messages.macros.contactName,
    },
    {
      title: '#race_name()',
      description: messages.macros.raceName,
    },
    // hiding for launch
    // {
    //   title: '#race_url()',
    //   description: messages.macros.raceURL,
    // },
    // {
    //   title: '#candidate_url()',
    //   description: messages.macros.candidateURL,
    // },
    {
      title: '#response_link()',
      description: messages.macros.candidateResponseURL,
    },
    // {
    //   title: '#response_url_short()',
    //   description: messages.macros.candidateResponseURLShort,
    // },
    {
      title: '#security_code()',
      description: messages.macros.securityCode,
    },
  ];

  return (
    <div>
      <ThemeProvider theme={theme}>
        <SnackbarAlert ref={_snackbar} />
        <ConfirmationDialog
          cancelButtonText={messages.cancelDialog.cancel}
          confirmButtonText={messages.cancelDialog.confirm}
          heading={messages.cancelDialog.heading}
          onCancel={() => setOpenCancelDialog(false)}
          onConfirm={() => confirmCancelDialog()}
          open={openCancelDialog}
        >
          <Typography component="p" variant="body1">
            {messages.cancelDialog.body}
          </Typography>
        </ConfirmationDialog>

        <ConfirmationDialog
          cancelButtonText={messages.saveDialog.cancel}
          confirmButtonText={messages.saveDialog.confirm}
          heading={messages.saveDialog.heading}
          onCancel={() => setOpenSaveDialog(false)}
          onConfirm={() => handleSave()}
          open={openSaveDialog}
        >
          <Typography component="p" variant="body1">
            {messages.saveDialog.body}
          </Typography>
        </ConfirmationDialog>

        <ConfirmationDialog
          cancelButtonText={messages.saveDialog.cancel}
          confirmButtonText={'Delete'}
          heading={'Delete template?'}
          onCancel={() => setOpenDeleteDialog(false)}
          onConfirm={() => handleDelete()}
          open={openDeleteDialog}
        >
          <Typography component="p" variant="body1">
            Are you sure you want to delete this template?
          </Typography>
        </ConfirmationDialog>

        <PageHeader
          breadcrumbText={breadcrumbText}
          breadcrumbUrl={breadcrumbUrl}
          heading={messages.title}
          subheading={messages.supporting}
        />

        {loading && (
          <div className="ga-container">
            <div className="mdc-layout-grid">
              <div className="mdc-layout-grid__inner">
                <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
                  <div className="spinner">
                    <CircularProgress />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {!loading && (
          <>
            {/* Page Actions */}
            <PageActionsHeader>
              {!templateIsDefault && (
                <Button
                  onClick={() => setOpenDeleteDialog(true)}
                  className={`${classes.actionButton} ${classes.danger}`}
                  color="secondary"
                >
                  Delete
                </Button>
              )}
              <Button color="primary" onClick={handleCancel}>{messages.actions.cancel}</Button>
              <Button color="secondary" variant="contained" onClick={() => setOpenSaveDialog(true)}>{messages.actions.save}</Button>
            </PageActionsHeader>

            <div className="ga-container">
              <div className="mdc-layout-grid">
                <div className="mdc-layout-grid__inner">
                  <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
                    <Box marginTop={2} marginBottom={5}>
                      <EmailForm
                        fields={fields}
                        macros={macros}
                        errors={fieldErrors}
                        defaultWYSIWYG={renderedWYSIWYGValue}
                        onWYSIWYGChange={handleWYSIWYGChange}
                        submitting={submitting}
                        wysiwygHeading={messages.template.title}
                        macrosTitle={messages.template.help}
                      />
                    </Box>
                  </div>
                </div>
              </div>
            </div>

            {/* Page Actions Part Dos */}
            <PageActionsFooter sticky>
              <Button color="primary" onClick={handleCancel}>{messages.actions.cancel}</Button>
              <Button color="secondary" variant="contained" onClick={() => setOpenSaveDialog(true)}>{messages.actions.save}</Button>
            </PageActionsFooter>
          </>
        )}
      </ThemeProvider>
    </div>
  );
};

EditTemplateContainer.propTypes = {
  breadcrumbText: PropTypes.string,
  breadcrumbUrl: PropTypes.string,
  guideId: PropTypes.string.isRequired,
  messages: PropTypes.object.isRequired,
  templateTypes: PropTypes.array.isRequired,
};

EditTemplateContainer.defaultProps = {
  breadcrumbText: 'Mail Center',
  breadcrumbUrl: '/mail',
};

export default EditTemplateContainer;
