import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  TextField,
  Grid,
  Typography,
  Box,
  IconButton,
} from '@material-ui/core';
import Form from 'helpers/Form';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: '1rem',
  },
  addFormContainer: {
    flexGrow: 1,
    display: 'flex',
    margin: '1rem',
  },
  addFormActions: {
    backgroundColor: '#eef4f8',
    display: 'block',
    padding: '.8rem 2rem',
  },
  fields: {
    marginBottom: '1em',
    paddingTop: '1em',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '1.875em',
      paddingTop: '0',
    },
  },
  toggle: {
    justifyContent: 'center',
  },
  actions: {
    textAlign: 'right',
    marginTop: '.75em',
    marginBottom: '.75em',
  },
  required: {
    marginTop: '.75em',
    marginBottom: '.75em',
  },
  modalStyle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '98%',
    boxShadow: '0px 2px 4px rgba(0,0,0,0.18)',
    [theme.breakpoints.up('sm')]: {
      width: '70%',
    },
  },
  closeButton: {
    position: 'absolute',
    top: '0',
    right: '0',
    [theme.breakpoints.up('sm')]: {
      top: '1rem',
      right: '1rem',
    },
  },
  cancelButton: {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

// use forward ref since this goes in a MUI modal
const CategoryEditModalForm = React.forwardRef(({
  description,
  errors,
  messages,
  name,
  onClose,
  onSubmit,
  submitting,
}, ref) => {
  const classes = useStyles();

  const [form, setForm] = useState(new Form({
    type: '',
    name,
    description,
    editable: true,
    visible: true,
  }, {
    validationMessage: 'validationErrorMessage',
  }));

  const handleSumbit = () => {
    onSubmit(form.name, form.description);
  };

  const getFormValue = (event) => {
    if (event.target.name == 'editable' || event.target.name == 'visible') {
      return event.target.checked;
    }
    return event.target.value;
  };

  const handleInputChange = (event) => {
    const fieldName = event.target.name;
    const value = getFormValue(event);
    // const formData = form.data();
    setForm(new Form({
      type: form.type,
      name: fieldName == 'name' ? value : form.name,
      description: fieldName == 'description' ? value : form.description,
      editable: form.editable,
      visible: form.visible,
    }, {
      validationErrorMessage: 'validationErrorMessage',
    }));
  };

  const hasError = (fieldName) => errors && Object.prototype.hasOwnProperty.call(errors, fieldName);

  const getError = (key) => {
    if (key in errors && errors[key].length > 0) {
      return errors[key][0];
    }
    return null;
  };

  return (
    <Box className={classes.modalStyle} ref={ref}>
      <Card variant="outlined">
        <CardContent className={classes.addFormContainer}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth className={classes.fields}>
                <TextField
                  disabled={submitting}
                  error={hasError('name')}
                  helperText={getError('name')}
                  id="category-add-title"
                  label={messages.title}
                  name="name"
                  onChange={handleInputChange}
                  required
                  value={form.name}
                  variant="filled"
                />
              </FormControl>

              <FormControl fullWidth className={classes.fields}>
                <TextField
                  disabled={submitting}
                  error={hasError('description')}
                  helperText={getError('description')}
                  id="category-add-description"
                  label={messages.description}
                  name="description"
                  onChange={handleInputChange}
                  type="text"
                  value={form.description}
                  variant="filled"
                />
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>

        <CardActions className={classes.addFormActions}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={12} sm={6} className={classes.required}>
              <Typography variant="body1">
                {messages.required}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} className={classes.actions}>
              <Button
                className={classes.cancelButton}
                color="primary"
                disabled={submitting}
                onClick={() => onClose()}
                size="large"
                variant="text"
              >
                {messages.cancel}
              </Button>

              <Button
                color="secondary"
                disabled={submitting}
                onClick={() => handleSumbit()}
                size="large"
                variant="contained"
              >
                {messages.confirm}
              </Button>
            </Grid>
          </Grid>
        </CardActions>

        <IconButton
          className={classes.closeButton}
          color="primary"
          onClick={() => onClose()}
        >
          <CloseIcon />
        </IconButton>
      </Card>
    </Box>
  );
});

CategoryEditModalForm.displayName = 'CategoryEditModalForm';
CategoryEditModalForm.propTypes = {
  messages: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  submitting: PropTypes.bool.isRequired,
  errors: PropTypes.object,
};

CategoryEditModalForm.defaultProps = {
  onSubmit: () => {},
  onClose: () => {},
  description: '',
  errors: {},
};

export default CategoryEditModalForm;
