import React, { useState } from 'react';
import propTypes from 'prop-types';
import { MessagesContext } from './MessagesContext';

const MessagesProvider = ({ children, messages }) => {
  const [messagesState, setMessagesState] = useState(messages);

  return (
    <MessagesContext.Provider value={{ messages: messagesState, setMessages: setMessagesState }}>
      {children}
    </MessagesContext.Provider>
  );
};

MessagesProvider.propTypes = {
  children: propTypes.node.isRequired,
  messages: propTypes.object,
};

MessagesProvider.defaultProps = {
  messages: {},
};

export { MessagesProvider };
