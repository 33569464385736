import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import UserRowList from 'components/UsersAndRoles/UserRowList';
import theme from 'scripts/theme';
import SnackbarAlert from 'components/SnackbarAlert';
import ConfirmationDialog from 'components/ConfirmationDialog';

class UserRoleContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmationDialogBody: {},
      confirmationDialogHeading: '',
      confirmationDialogId: null,
      deleteSubmitting: false,
      showConfirmationDialog: false,
      submitting: false,
      users: props.users,
      errors: null,
    };

    this._snackbar = React.createRef();

    this.handleAdd = this.handleAdd.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  handleAdd() {
    const { users } = this.state;
    const newUserList = users.slice();

    newUserList.push({
      email: '',
      errors: {},
      id: users.length,
      login: '--',
      role: '',
    });

    this.setState({
      users: newUserList,
    });
  }

  handleChange(index, field, value) {
    const { users } = this.state;
    const newUserList = users.slice();

    // If we can't find a user matching our index or the user doesn't have
    // a matching field property, bail out
    if (!newUserList[index] || !Object.prototype.hasOwnProperty.call(newUserList[index], field)) {
      console.error('no user or no matching field for ', field);
      return;
    }

    newUserList[index][field] = value;

    this.setState({
      users: newUserList,
    });
  }

  handleDeleteConfirmation = (id, email) => {
    const dialogHeading = `Delete ${email}?`;
    const dialogBody = (
      <Typography component="p" variant="body1" className="ga-m-bottom--small">
        Are you sure you want to delete <strong>{email}</strong>? You will be removing this user from the guide, and this action cannot but undone.
      </Typography>
    );

    this.setState({
      showConfirmationDialog: true,
      confirmationDialogBody: dialogBody,
      confirmationDialogHeading: dialogHeading,
      confirmationDialogId: id,
    });
  }

  handleCancelDeleteConfirmation = () => {
    this.setState({
      confirmationDialogId: null,
      showConfirmationDialog: false,
    });
  }

  handleConfirmDeleteConfirmation = () => {
    const { confirmationDialogId } = this.state;
    this.handleDelete(confirmationDialogId);
  }

  handleDelete(id) {
    const { messages } = this.props;
    const { users } = this.state;
    const newUserList = users.filter(user => user.id !== id);
    // TODO: update to have a conditional for if delete fails for any reason, show an error message
    this.setState({
      deleteSubmitting: false,
      users: newUserList,
      showConfirmationDialog: false,
    });
    this.showSuccess(messages.userRoles.delete.success);
  }

  showError(message) {
    this._snackbar.current.show('error', message);
  }

  showSuccess(message) {
    this._snackbar.current.show('success', message);
  }

  // TODO: update submitting state so that is is actually doing something
  handleSave() {
    this.setState({
      submitting: true,
    });
    const { messages } = this.props;
    const { errors } = this.state;

    if (!errors) {
      this.showSuccess(messages.userRoles.save.success);
    } else {
      console.log(errors);
      this.showError(messages.userRoles.save.error);
    }

    this.setState({
      submitting: false,
    });
  }

  render() {
    const { messages } = this.props;
    const {
      confirmationDialogBody,
      confirmationDialogHeading,
      deleteSubmitting,
      showConfirmationDialog,
      submitting,
      users,
    } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <SnackbarAlert ref={this._snackbar} />

        <Typography component="h1" variant="h1">
          {messages.heading}
        </Typography>

        <Typography component="p" variant="body1" paragraph>
          {messages.subheading}
        </Typography>

        <ConfirmationDialog
          heading={confirmationDialogHeading}
          onCancel={this.handleCancelDeleteConfirmation}
          onConfirm={this.handleConfirmDeleteConfirmation}
          open={showConfirmationDialog}
          submitting={deleteSubmitting}
        >
          {confirmationDialogBody}
        </ConfirmationDialog>

        <UserRowList
          users={users}
          onAdd={this.handleAdd}
          onChange={this.handleChange}
          onDelete={this.handleDeleteConfirmation}
          onSave={this.handleSave}
          submitting={submitting}
        />
      </ThemeProvider>
    );
  }
}

UserRoleContainer.propTypes = {
  users: PropTypes.array,
  messages: PropTypes.shape({
    heading: PropTypes.string,
    subheading: PropTypes.string,
    userRoles: PropTypes.shape({
      save: PropTypes.shape({
        error: PropTypes.string,
        success: PropTypes.string,
      }),
      delete: PropTypes.shape({
        success: PropTypes.string,
        error: PropTypes.string,
      }),
    }),
  }),
};

UserRoleContainer.defaultProps = {
  users: [],
  messages: {
    heading: 'Users & Roles',
    subheading: 'Add league members to the voter guide and set their roles to determine their permission level (only available for admin users). Contributors do not have the ability to delete, archive, and bulk edit districts.',
    userRoles: {
      save: {
        error: 'There was an issue saving your users. Correct any errors and try again.',
        success: 'Your users were saved successfully!',
      },
      delete: {
        error: 'Unable to delete the user from your guide.',
        success: 'Successfully deleted the user from your guide.',
      },
    },
  },
};

export default UserRoleContainer;
