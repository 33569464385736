import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionDetails,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import {
  withStyles,
  makeStyles,
} from '@material-ui/core/styles';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { CategoryExpanded, Category } from 'components/Categories';

// Declarations
const AccordionNoGutters = withStyles({
  root: {
    borderBottom: '1px solid rgba(0,0,0,0.125)',
    '&:last-child': { borderBottom: 'none' },
    '&:before': { display: 'none' },
    '&$expanded': { margin: 'auto' },
  },
  expanded: {},
})(Accordion);

const useStyles = makeStyles({
  catStatus: {
    width: '100%',
  },
});

// Component
const CategoryList = ({
  clearErrors,
  disabled,
  errors,
  guideId,
  list,
  messages,
  moveCategory,
  onDelete,
  onEdit,
  onError,
  onFailedDrop,
  onLoadRaces,
  onReorder,
  submittingEdit,
}) => {
  const classes = useStyles();
  const [expandedState, setExpandedState] = useState({});

  useEffect(() => {
    setIncomingExpandedState();
  }, [list]);

  const setIncomingExpandedState = () => {
    const newExpandedState = { ...expandedState };

    list.forEach((category) => {
      if (!Object.prototype.hasOwnProperty.call(newExpandedState, category.id)) {
        newExpandedState[category.id] = false;
      }
    });

    setExpandedState(newExpandedState);
  };

  const handleChange = (category) => {
    const newExpandedState = { ...expandedState };
    newExpandedState[category.id] = !newExpandedState[category.id];

    if (newExpandedState[category.id] == true) {
      onLoadRaces(category);
    }

    setExpandedState(newExpandedState);
  };

  const handleCategoryReorder = () => {
    // check that uncategorized is still last
    const uncategorizedIsLast = list.findIndex(c => c.id == -1) == list.length - 1;
    const newCategoryIndex = list.map((c, idx) => ({ id: c.id, index: idx })).filter(c => c.id != -1);
    onReorder(uncategorizedIsLast, newCategoryIndex);
  };

  const getCatStatus = (cat, isExpanded) => {
    if (!cat.races && isExpanded && !cat.error) {
      return (<div className={classes.catStatus} align="center"><CircularProgress /></div>);
    }

    if (cat.error) {
      return (<div align="center">{cat.error}</div>);
    }
  };

  const getExpandedStatus = (categoryId) => {
    if (Object.prototype.hasOwnProperty.call(expandedState, categoryId)) {
      return expandedState[categoryId];
    }

    return false;
  };

  return (
    <div>
      <Typography component='h2' variant='h3' paragraph>
        {messages.existing.heading}
      </Typography>

      <DndProvider backend={HTML5Backend}>
        { list.map((cat, i) => (
          <AccordionNoGutters expanded={getExpandedStatus(cat.id)} key={cat.id}>
            <Category
              description={cat.description}
              disabled={disabled}
              expanded={expandedState[cat.id]}
              id={cat.id}
              index={i}
              moveCategory={moveCategory}
              name={cat.title}
              onClick={() => handleChange(cat)}
              onFailedDrop={onFailedDrop}
              onReorder={handleCategoryReorder}
            />

            <AccordionDetails>
              { getCatStatus(cat, expandedState[cat.id]) }
              {cat.details
                && <CategoryExpanded
                  category={cat}
                  clearErrors={clearErrors}
                  errors={errors}
                  guideId={guideId}
                  messages={messages}
                  onEdit={(editCat, callback) => onEdit(editCat, callback)}
                  onError={onError}
                  onDelete={onDelete}
                  submittingEdit={submittingEdit}
                />
              }
            </AccordionDetails>
          </AccordionNoGutters>
        ))}
      </DndProvider>
    </div>
  );
};

CategoryList.propTypes = {
  clearErrors: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  guideId: PropTypes.number.isRequired,
  errors: PropTypes.object,
  list: PropTypes.array.isRequired,
  messages: PropTypes.shape({
    existing: PropTypes.object,
    delete: PropTypes.object,
    expanded: PropTypes.object,
    edit: PropTypes.object,
  }).isRequired,
  moveCategory: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onFailedDrop: PropTypes.func.isRequired,
  onLoadRaces: PropTypes.func.isRequired,
  onReorder: PropTypes.func.isRequired,
  submittingEdit: PropTypes.bool.isRequired,
};

CategoryList.defaultProps = {
  errors: {},
  onEdit: () => {},
  onDelete: () => {},
  onLoadRaces: () => {},
  // Fixing a small problem in the storybook, setting a default heading text
  messages: {
    existing: {
      heading: 'Title',
    },
  },
};

export default CategoryList;
