import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/Help';

const useStyles = makeStyles(theme => ({
  boxBackground: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(2),
    borderColor: '#3fa06f',
    borderStyle: 'solid',
    border: '1px',
    background: 'rgba(63, 160, 111, 0.1)',
    borderRadius: 4,
    width: '100%',
  },
  helpIcon: {
    width: '32px',
    height: '32px',
    color: '#0a3a5a',
    marginBottom: '8px',
  },
}));

function HelpfulHint({ children }) {
  const classes = useStyles();

  return (
    <Box className={classes.boxBackground}>
      <HelpIcon className={classes.helpIcon} />
      <Typography variant="body1" component="div">
        {children}
      </Typography>
    </Box>
  );
}

HelpfulHint.propTypes = {
  children: PropTypes.node,
};

export default HelpfulHint;
