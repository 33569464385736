import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const useStyles = makeStyles({
  card: {
    borderColor: '#eef4f8',
  },
  cardContent: {
    display: 'flex',
  },
  cardIconContent: {
    display: 'flex',
    maxWidth: '50%',
    '&:first-child': {
      marginRight: '1rem',
    },
    '& > p': {
      marginTop: '1rem',
    },
  },
  doIcon: {
    color: '#3fa06f',
    fontSize: '5rem',
    marginRight: '1rem',
  },
  dontIcon: {
    color: '#af3b54',
    fontSize: '5rem',
    marginRight: '1rem',
  },
});

function DoDontCard({ className, doText, dontText }) {
  const classes = useStyles();

  return (
    <Card variant="outlined" className={className}>
      <CardContent className={classes.cardContent}>
        <div className={classes.cardIconContent}>
          <HighlightOffIcon className={classes.dontIcon} />
          <Typography component="p" variant="body1">
            {dontText}
          </Typography>
        </div>
        <div className={classes.cardIconContent}>
          <CheckCircleOutlineIcon className={classes.doIcon} />
          <Typography component="p" variant="body1">
            {doText}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
}

DoDontCard.propTypes = {
  className: PropTypes.string,
  doText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  dontText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
};

DoDontCard.defaultProps = {
  className: '',
  doText: '',
  dontText: '',
};

export default DoDontCard;
