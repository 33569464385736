import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import RadioCardList from 'components/RadioCardList';

const EXPORT_OPTIONS = [
  {
    title: 'Races (.txt)',
    description: 'Export all race and candidate data to a .txt file.',
    value: 'txt',
  },
  {
    title: 'Races (.csv)',
    description:
      'Export race and candidate data to a comma-separated (.csv) file for import into a database or spreadsheet.',
    value: 'csv',
  },
  {
    title: 'Races (.xml)',
    description: 'Export race and candidate data to an XML file.',
    value: 'xml',
  },
  {
    title: 'Races (.html)',
    description: 'Export race and candidate data to an HTML file.',
    value: 'html',
  },
  {
    title: 'Candidate images (.zip)',
    description:
      'Export candidate images for the selected races to a .zip file.',
    value: 'images',
  },
];

const useStyles = makeStyles({
  actions: {
    justifyContent: 'flex-end',
  },
  actionButton: {
    marginLeft: '1rem',
  },
});

const RaceExportModal = ({
  cancelButtonText,
  confirmButtontext,
  disabled,
  heading,
  onCancel,
  onConfirm,
  show,
}) => {
  const [activeOptionIndex, setActiveOptionIndex] = useState(0);
  const classes = useStyles();

  return (
    <Dialog
      aria-labelledby="race-export-dialog"
      maxWidth="lg"
      open={show}
      onClose={onCancel}
    >
      <DialogTitle id="race-export-dialog" disableTypography>
        <Typography component="h1" variant="h2" color="primary">
          {heading}
        </Typography>

        <IconButton aria-label="close" onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <div>
          <Typography component="p" variant="body1">
            What information would you like to export?
          </Typography>

          <RadioCardList
            cards={EXPORT_OPTIONS}
            checked={activeOptionIndex}
            disabled={disabled}
            onChange={(newIndex) => setActiveOptionIndex(newIndex)}
            spacing={1}
          />
        </div>
      </DialogContent>

      <DialogActions className={classes.actions}>
        <Button onClick={onCancel}>{cancelButtonText}</Button>

        <Button
          className={classes.actionButton}
          color="secondary"
          disabled={disabled}
          onClick={() => onConfirm(EXPORT_OPTIONS[activeOptionIndex].value)}
          variant="contained"
        >
          {confirmButtontext}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

RaceExportModal.propTypes = {
  cancelButtonText: PropTypes.string,
  confirmButtontext: PropTypes.string,
  disabled: PropTypes.bool,
  heading: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  show: PropTypes.bool,
};

RaceExportModal.defaultProps = {
  cancelButtonText: 'Cancel',
  confirmButtontext: 'Export',
  disabled: false,
  heading: 'Export selected races',
  onCancel: () => {},
  onConfirm: () => {},
  show: false,
};

export default RaceExportModal;
