import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { createStyles, withStyles } from '@material-ui/styles';
import theme from 'scripts/theme';
import PageHeader from 'components/PageHeader';
import PageActionsHeader from 'components/PageActionsHeader';
import PageActionsFooter from 'components/PageActionsFooter';
import NumberedStepper from 'components/NumberedStepper';
import UploadCardList from 'components/UploadCardList';
import SnackbarAlert from 'components/SnackbarAlert';
import ConfirmationDialog from 'components/ConfirmationDialog';
import fetchUtil from 'helpers/Fetch';
import GISDistrictCreateContainer from 'components/Districts/GISDistrictCreateContainer';

const styles = () => createStyles({
  accordionHeading: {
    fontWeight: '600',
  },
  accordionSummary: {
    paddingLeft: '0px',
  },
  formContainer: {
    marginBottom: '1.875rem',
  },
  optionTitle: {
    fontWeight: '500',
  },
  orText: {
    margin: '2.5rem 0rem',
    color: '#666',
    textTransform: 'uppercase',
  },
  sectionHeading: {
    fontWeight: 700,
  },
  sectionBlock: {
    marginBottom: '2rem',
  },
  unfinished: {
    display: 'none',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
});

const Accordion = withStyles({
  root: {
    borderWidth: '0 0 1px 0',
    borderColor: '#e5e5e5',
    borderRadius: '0',
    borderStyle: 'solid',
    boxShadow: 'none',
    '&:last-child': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const GISDistrictImportContainer = ({
  classes,
  errors,
  guideId,
  importHeading,
  createHeading,
  importSubheading,
  createSubheading,
  messages,
  name,
  containingDistricts,
  mapApiKey,
}) => {
  const _snackbar = useRef(null);
  const [districtName, setDistrictName] = useState(name);
  const [canContinue, setCanContinue] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [gisFeatures, setGisFeatures] = useState(null);
  const [gisState, setGisState] = useState('');
  const [shapeFile, setShapeFile] = useState();
  const [projectionFile, setProjectionFile] = useState();
  const [databaseFile, setDatabaseFile] = useState();
  const [csvFile, setCsvFile] = useState();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  useEffect(() => {
    checkCanContinue();
  }, [districtName, gisState, csvFile, shapeFile, projectionFile, databaseFile]);

  const handleDistrictNameChanged = (event) => {
    setDistrictName(event.target.value.trim());
  };

  const handleGisStateChanged = (event) => {
    setGisState(event.target.value);
  };

  const handleChangeShapeFile = (files) => {
    if (files && files.length > 0) {
      if (files.length === 1) {
        setShapeFile(files[0]);
      } else {
        showError('Bad Shape File', 'Please select a single Shape (*.shp) file.');
      }
    }
  };

  const handleChangeDatabaseFile = (files) => {
    if (files && files.length > 0) {
      if (files.length === 1) {
        setDatabaseFile(files[0]);
      } else {
        showError('Bad Shape File', 'Please select a single Shape (*.shp) file.');
      }
    }
  };

  const handleChangeProjectionFile = (files) => {
    if (files && files.length > 0) {
      if (files.length === 1) {
        setProjectionFile(files[0]);
      } else {
        showError('Bad Shape File', 'Please select a single Shape (*.shp) file.');
      }
    }
  };

  const handleChangeCsvFile = (files) => {
    if (files && files.length > 0) {
      if (files.length === 1) {
        setCsvFile(files[0]);
      } else {
        showError('Bad Shape File', 'Please select a single Shape (*.shp) file.');
      }
    }
  };

  /**
   * Checks whether the user can continue. There are three requirements:
   * 1. The district name must be set and not contain only whitespace
   * 2. A GIS State must be selected
   * 3. Either a CSV file must be selected, OR the Shape, Database, and Projection files must be selected
   */
  const checkCanContinue = () => {
    if (!districtName) {
      setCanContinue(false);
      return;
    }

    if (!gisState) {
      setCanContinue(false);
      return;
    }

    if (csvFile) {
      setCanContinue(true);
    } else if (shapeFile && projectionFile && databaseFile) {
      setCanContinue(true);
    } else {
      setCanContinue(false);
    }
  };

  const submitFiles = () => {
    setSubmitting(true);
    const formData = new FormData();

    if (csvFile) {
      formData.append(
        'csvFile',
        csvFile,
        csvFile.name,
      );
    } else {
      formData.append(
        'shapeFile',
        shapeFile,
        shapeFile.name,
      );
      formData.append(
        'projectionFile',
        projectionFile,
        projectionFile.name,
      );
      formData.append(
        'databaseFile',
        databaseFile,
        databaseFile.name,
      );
    }

    fetchUtil(`/api/v1/guide/${guideId}/district/gis/upload`, 'POST', formData, false, true)
      .then(response => {
        setSubmitting(false);

        if (response) {
          setGisFeatures(JSON.parse(response));
        }
      })
      .catch(error => {
        console.error(error);
        onError('Updating', error.data);
      });
  };

  const hasError = (fieldName) => Object.prototype.hasOwnProperty.call(errors, fieldName);

  const renderStateOptions = () => {
    if (containingDistricts && containingDistricts.length > 0) {
      return containingDistricts.map(dist => (<MenuItem value={dist.id} key={dist.id}>{dist.name}</MenuItem>));
    }
  };

  const showError = (action, message) => {
    if (action) {
      console.log(action);
    }

    _snackbar.current.hide();
    _snackbar.current.show('error', message);
  };

  const handleSubmitError = message => {
    showError('GIS District Set Submit', message);
  };

  const handleSubmitSuccess = () => {
    window.location.replace('/districts?showSuccessMsgFrom=creategis');
  };

  const handleCancel = () => {
    setShowConfirmationDialog(true);
  };

  const handleCancelConfirm = () => {
    window.location.replace('/districts');
  };

  const handleCancelCancel = () => {
    setShowConfirmationDialog(false);
  };

  const onError = (action, message) => {
    _snackbar.current.hide();
    _snackbar.current.show('error', message);
    setSubmitting(false);
  };

  const uploadGis = (
    <div>
      <PageHeader
        heading={importHeading}
        subheading={importSubheading}
      />

      <PageActionsHeader>
        <Button
          variant="contained"
          color="secondary"
          disabled={!canContinue}
          onClick={submitFiles}
        >
          Import & Continue
        </Button>
      </PageActionsHeader>

      <div className="ga-container">
        <div className="mdc-layout-grid">
          <div className="mdc-layout-grid__inner">
            <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-10">
              <NumberedStepper
                steps={[
                  {
                    title: 'Set Information',
                    children: <div>
                      <Grid container>
                        <Grid item xs={12} sm={4}>
                          <FormControl fullWidth className={classes.formContainer}>
                            <TextField
                              id="gis-set-name"
                              defaultValue={districtName}
                              disabled={submitting}
                              error={hasError('name')}
                              helperText={hasError('name') ? errors.name : 'ie: New Jersey school districts'}
                              label="GIS District Set Name"
                              name="name"
                              onChange={handleDistrictNameChanged}
                              required
                              variant="filled"
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12} sm={4}>
                          <FormControl
                            className={classes.fields}
                            error={hasError('gisState')}
                            disabled={submitting}
                            fullWidth
                            required
                            variant="filled"
                          >
                            <InputLabel id="gis-set-state-label">
                              State
                            </InputLabel>
                            <Select
                              id="gis-set-state"
                              error={hasError('gisState')}
                              name="gisState"
                              onChange={handleGisStateChanged}
                              value={gisState}
                            >
                              {renderStateOptions()}
                            </Select>
                            <FormHelperText>{hasError('gisState') ? errors.gisState : ''}</FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </div>,
                  },
                  {
                    title: 'Find your GIS Data',
                    children: <div>
                      <Typography component="p" variant="body1">
                        Each state, county, city, town, etc. has a different way of distributing this data. Sometimes,
                        this data doesn&apos;t exist, and then you will need to create your district using one of our
                        manual options. There are a few places where you can find GIS Data: US Census Bureau, Google,
                        Secretary of States and Board of Elections, and Universities and Colleges.
                      </Typography>

                      <Accordion defaultExpanded={false}>
                        <AccordionSummary
                          expandIcon={
                            <ExpandMoreIcon />
                          }
                          aria-controls={messages.readMore.id}
                          className={classes.accordionSummary}
                          id={messages.readMore.id}
                        >
                          <Typography className={classes.accordionHeading} component="p" variant="body1">
                            {messages.readMore.title}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container justifyContent="flex-start" alignItems="flex-start" spacing={2}>
                            <Grid item xs={12} sm={10}>
                              <Typography
                                className={classes.sectionHeading}
                                color="primary"
                                component="h3"
                                paragraph
                                variant="h5"
                              >
                                {messages.readMore.censusTitle}
                              </Typography>
                              <Typography component="p" variant="body1" paragraph>
                                The US Census Bureau has what’s called TIGER/Line Shapefiles and is a great place to
                                start looking for your data! They should have &ldquo;places&ldquo; shapefiles for the
                                cities/towns and school district shapefiles. You can learn more about how to use and
                                upload files in our <a href='#'>training documentation</a>.
                              </Typography>

                              <Typography
                                className={classes.sectionHeading}
                                color="primary"
                                component="h3"
                                paragraph
                                variant="h5"
                              >
                                {messages.readMore.googleTitle}
                              </Typography>
                              <Typography component="p" variant="body1" paragraph>
                                You can always Google the races you need to see if you can find the GIS Data that way.
                                Make sure to be specific in your search and include keywords
                                like &ldquo;GIS&ldquo; or &ldquo;Shapefiles.&ldquo; For example, &ldquo;Alpena County
                                Michigan City Council Shapefiles.&ldquo; Depending on your city and the type of race
                                you&apos;re looking for you might find the exact information you need right on a
                                website. You can learn more about how to use and upload files in our <a href='#'>training
                                  documentation</a>.
                              </Typography>

                              <Typography
                                className={classes.sectionHeading}
                                color="primary"
                                component="h3"
                                paragraph
                                variant="h5"
                              >
                                {messages.readMore.secretaryTitle}
                              </Typography>
                              <Typography component="p" variant="body1" paragraph>
                                You can try contacting your Secretary of State or local elections officials. Some local
                                elections officials (LEOs) have GIS departments that might be able to help with your
                                request. They might charge for the data, but tell them what you&apos;re using the data
                                for, that you&apos;re part of a non-profit organization, and answer any questions they
                                might have. They MAY give you the data for free.
                              </Typography>

                              <Typography
                                className={classes.sectionHeading}
                                color="primary"
                                component="h3"
                                paragraph
                                variant="h5"
                              >
                                {messages.readMore.uniTitle}
                              </Typography>
                              <Typography component="p" variant="body1" paragraph>
                                Many colleges and universities have GIS departments that could provide the information
                                you&apos;re looking for. If your local college has a GIS department, contact them and
                                see if they can be of assistance.
                              </Typography>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </div>,
                  },
                  {
                    title: 'Import GIS Data',
                    children: <div>
                      <Typography component="p" variant="body1" paragraph>
                        Remember GIS Data is already in your account for all the races down through county-wide, so
                        you&apos;ll only need to create GIS Districts for any local races you are covering.
                      </Typography>

                      <UploadCardList
                        cards={[
                          {
                            acceptedFileTypes: '.dbf',
                            label: 'Attribute File - .dbf',
                            text: 'Attach your attribute file here (.dbf)',
                            onChange: handleChangeDatabaseFile,
                          },
                          {
                            acceptedFileTypes: '.prj',
                            label: 'Projection File - .prj',
                            text: 'Attach your projection file here (.prj)',
                            onChange: handleChangeProjectionFile,
                          },
                          {
                            acceptedFileTypes: '.shp',
                            label: 'Geography File - .shp',
                            text: 'Attach your geography file here (.shp)',
                            onChange: handleChangeShapeFile,
                          },
                        ]}
                      />

                      <div className={classes.unfinished}>
                        <Typography className={classes.orText} component="p" variant="body1">
                          OR
                        </Typography>

                        <Typography className={classes.optionTitle} component="h2" variant="h4" paragraph>
                          Import a .csv file from a previously exported GIS District Set
                        </Typography>

                        <Typography component="p" variant="body1" paragraph>
                          You can import one or more files that have been created by GIS District Set exports.
                        </Typography>

                        <UploadCardList
                          cards={[
                            {
                              acceptedFileTypes: '.csv',
                              label: 'Exported GIS District File - .csv',
                              text: 'Attach your exported GIS District file here (.csv)',
                              onClick: handleChangeCsvFile,
                            },
                          ]}
                        />
                      </div>
                    </div>,
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>

      <PageActionsFooter sticky>
        <Button color="primary" variant="text" onClick={handleCancel}>
          Cancel
        </Button>

        <Button
          variant="contained"
          color="secondary"
          disabled={!canContinue}
          onClick={submitFiles}
        >
          Import & Continue
        </Button>
      </PageActionsFooter>
    </div>
  );

  const createGis = (
    <GISDistrictCreateContainer
      containingDistricts={containingDistricts}
      gisFeatures={gisFeatures}
      guideId={guideId}
      heading={createHeading}
      mapApiKey={mapApiKey}
      name={districtName}
      onError={handleSubmitError}
      onSuccess={handleSubmitSuccess}
      stateId={gisState}
      subheading={createSubheading}
    />
  );

  return (
    <ThemeProvider theme={theme}>
      <SnackbarAlert ref={_snackbar} />

      <ConfirmationDialog
        heading="Confirmation"
        onCancel={handleCancelCancel}
        onConfirm={handleCancelConfirm}
        open={showConfirmationDialog}
      >
        <Typography component="p" variant="body1">
          Are you sure you want to exit? All unsaved changes will be lost.
        </Typography>
      </ConfirmationDialog>

      <Backdrop className={classes.backdrop} open={submitting}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {gisFeatures ? createGis : uploadGis}
    </ThemeProvider>
  );
};

GISDistrictImportContainer.propTypes = {
  classes: PropTypes.object,
  errors: PropTypes.object,
  guideId: PropTypes.number.isRequired,
  importHeading: PropTypes.string,
  importSubheading: PropTypes.string,
  createHeading: PropTypes.string,
  createSubheading: PropTypes.string,
  messages: PropTypes.object,
  name: PropTypes.string,
  containingDistricts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })).isRequired,
  mapApiKey: PropTypes.string,
};

GISDistrictImportContainer.defaultProps = {
  errors: {},
  importHeading: 'Create a GIS District Set',
  importSubheading: 'Quick and easy, GIS (Geographical Information System) Data is the most efficient and straightforward approach to creating your districts.',
  messages: {
    readMore: {
      censusTitle: 'US Census Bureau',
      id: 'read-more-accordion',
      googleTitle: 'Google',
      secretaryTitle: 'Secretary of States and Board of Elections',
      title: 'Learn more about how to find your GIS Data',
      uniTitle: 'Universities and Colleges',
    },
  },
};

export default withStyles(styles)(GISDistrictImportContainer);
