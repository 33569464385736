import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';

const styles = () => ({
  cardContainerNoOutline: {
    border: 'none',
    boxShadow: 'none',
  },
  rule: {
    margin: '2rem 0',
  },
  zipWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '.25rem 0',
  },
  removeX: {
    fontSize: '2rem',
    cursor: 'pointer',
  },
  spinner: {
    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'translateX(-50%)',
  },
});

class ZipCodeDistrictForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  hasError(fieldName) {
    const { errors } = this.props;
    return Object.prototype.hasOwnProperty.call(errors, fieldName);
  }

  getErrorText(fieldName) {
    const { errors } = this.props;

    if (Object.prototype.hasOwnProperty.call(errors, fieldName)) {
      return errors[fieldName];
    }

    return '';
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    const { onChange } = this.props;

    onChange(name, value);
  }

  handleKeyDown(event) {
    const { zipCodeInput, onAdd } = this.props;
    if (event.key === 'Enter') {
      onAdd(zipCodeInput);
      event.preventDefault();
    }
  }

  render() {
    const {
      classes,
      district,
      messages,
      onAdd,
      onRemove,
      submitting,
      zipCodeInput,
    } = this.props;

    return (
      <Card
        variant="elevation"
        className={classes.cardContainerNoOutline}
      >
        <CardContent className={classes.formContainer}>
          {submitting && (
            <div className={classes.spinner}>
              <CircularProgress />
            </div>
          )}

          <Grid
            autoComplete="off"
            className="ga-px-8 ga-pt-16 ga-pb-4"
            component="form"
            container
            spacing={2}
          >
            <Grid item xs={6}>
              <Typography component="h3" variant="h4" paragraph>
                {messages.stepOneTitle}
              </Typography>

              <TextField
                disabled={submitting}
                fullWidth
                name="name"
                error={this.hasError('name')}
                inputProps={{ maxLength: 200 }}
                label={messages.nameLabel}
                helperText={this.hasError('name') ? this.getErrorText('name') : messages.nameHelperText}
                onChange={this.handleInputChange}
                required
                value={district.name}
                variant="filled"
              />
            </Grid>
          </Grid>

          <hr className={classes.rule} />

          <Grid
            autoComplete="off"
            className="ga-px-8 ga-pt-16 ga-pb-4"
            component="form"
            container
            spacing={2}
          >
            <Grid item xs={6}>
              <Typography component="h3" variant="h4">
                {messages.stepTwoTitle}
              </Typography>

              <Typography component="p" variant="body1" paragraph>
                {messages.stepTwoDescription}
              </Typography>

              <Grid
                container
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={9}>
                  <TextField
                    disabled={submitting}
                    fullWidth
                    name="zipCodeInput"
                    error={this.hasError('zipCodeInput')}
                    inputProps={{ maxLength: 5 }}
                    label={messages.addZipCodeLabel}
                    helperText={this.getErrorText('zipCodeInput')}
                    onChange={this.handleInputChange}
                    onKeyDown={this.handleKeyDown}
                    value={zipCodeInput}
                    variant="filled"
                  />
                </Grid>

                <Grid item xs={3}>
                  <Button
                    color="secondary"
                    disabled={submitting || zipCodeInput.length <= 0}
                    onClick={() => onAdd(zipCodeInput)}
                    variant="contained"
                    type="button"
                  >Add</Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <Typography component="h3" variant="h4" paragraph>
                {messages.zipCodeListHeader}
              </Typography>
              <Typography component="p" paragraph>
                {this.hasError('zipCodes')}
              </Typography>

              {district.zipCodes.map(zipCode => (
                <div className={classes.zipWrapper} key={zipCode}>
                  <Typography component="p" variant="body1">
                    {zipCode}
                  </Typography>

                  <IconButton
                    disabled={submitting}
                    onClick={() => onRemove(zipCode)}
                    color="primary"
                    aria-label="remove zipcode"
                    component="span">
                    <span className={classes.removeX}>&times;</span>
                  </IconButton>
                </div>
              ))}

              {district.zipCodes.length === 0
                && (
                  <Typography component="p" variant="body1" color="textSecondary">
                    {messages.zipCodeListEmptyText}
                  </Typography>
                )
              }
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

ZipCodeDistrictForm.propTypes = {
  onAdd: PropTypes.func,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  classes: PropTypes.object,
  district: PropTypes.object,
  errors: PropTypes.object,
  messages: PropTypes.object,
  submitting: PropTypes.bool,
  zipCodeInput: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

ZipCodeDistrictForm.defaultProps = {
  onAdd: () => {},
  onChange: () => {},
  onRemove: () => {},
  classes: {},
  district: {},
  errors: {},
  messages: {},
  submitting: false,
  zipCodeInput: '',
};

export default withStyles(styles)(ZipCodeDistrictForm);
