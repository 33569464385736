import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ConfirmationDialog from 'components/ConfirmationDialog';
import PageHeader from 'components/PageHeader';
import PageActionsHeader from 'components/PageActionsHeader';
import PageActionsFooter from 'components/PageActionsFooter';
import SnackbarAlert from 'components/SnackbarAlert';
import DistrictSetForm from 'components/Districts/Partials/DistrictSetForm';
import fetchUtil from 'helpers/Fetch';
import theme from 'scripts/theme';

const useStyles = makeStyles({
  danger: {
    color: '#ac1b3d',
    '&:hover': {
      color: '#6B1126',
    },
  },
  confirmationLoading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  raceList: {
    marginLeft: '2rem',
  },
});

const CONFIRMATION_MODAL_DEFAULTS = {
  confirmationModalHeading: '',
  confirmationModalBody: (<></>),
  confirmationModalCancelText: 'Cancel',
  confirmationModalConfirmText: 'Confirm',
  confirmationModalConfirmAction: () => () => {},
  confirmationModalSubmitting: false,
};

const DistrictSetContainer = ({
  guideId,
  messages,
  set,
}) => {
  const classes = useStyles();
  const _snackbar = useRef();

  // Variables and constants
  const msgActs = messages.pageActions;
  const locationInput = '';
  const newDistrictSet = {
    childDistrictsToAdd: [],
    childDistrictsToDelete: [],
    childDistricts: [],
    description: '',
    district: {},
    id: '',
    name: '',
  };

  // hooks
  const [containingDistrictIsLoaded, setContainingDistrictIsLoaded] = useState(false);
  const [districtSet, setDistrictSet] = useState(newDistrictSet);
  const [editMode, setEditMode] = useState(false);
  const [errors, setErrors] = useState({});
  const [renderedWYSIWYGValue, setRenderedWYSIWYGValue] = useState('<p></p>');
  const [submitting, setSubmitting] = useState(false);

  // Confirmation Modals
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmationModalCancelText, setConfirmationModalCancelText] = useState(CONFIRMATION_MODAL_DEFAULTS.confirmationModalCancelText);
  const [confirmationModalConfirmText, setConfirmationModalConfirmText] = useState(CONFIRMATION_MODAL_DEFAULTS.confirmationModalConfirmText);
  const [confirmationModalHeading, setConfirmationModalHeading] = useState(CONFIRMATION_MODAL_DEFAULTS.confirmationModalHeading);
  const [confirmationModalConfirmAction, setConfirmationModalConfirmAction] = useState(CONFIRMATION_MODAL_DEFAULTS.confirmationModalConfirmAction);
  const [confirmationModalSubmitting, setConfirmationModalSubmitting] = useState(CONFIRMATION_MODAL_DEFAULTS.confirmationModalSubmitting);
  const [confirmationModalBody, setConfirmationModalBody] = useState(CONFIRMATION_MODAL_DEFAULTS.confirmationModalBody);

  // Component Did Mount
  useEffect(() => {
    if (set && Object.prototype.hasOwnProperty.call(set, 'id')) {
      setEditMode(true);

      setDistrictSet({
        childDistricts: set.childDistricts || [],
        childDistrictsToAdd: [],
        childDistrictsToDelete: [],
        id: set.id,
        name: set.name,
        description: set.description,
        district: set.votingDistrict,
      });

      setRenderedWYSIWYGValue(set.description);

      setContainingDistrictIsLoaded(true);
    } else {
      setContainingDistrictIsLoaded(true);
    }
  }, [set]);

  // Functions
  const showError = (message) => {
    _snackbar.current.show('error', message);
  };

  const handleCancelConfirmation = () => {
    const confirmBody = (
      <Typography component="p" variant="body1">
        If you exit this page, all unsaved changes will be lost.
      </Typography>
    );
    setConfirmationModalHeading('Are you sure you want to cancel?');
    setConfirmationModalBody(confirmBody);
    setConfirmationModalCancelText('Cancel');
    setConfirmationModalConfirmText('Confirm');
    setConfirmationModalConfirmAction(() => () => handleConfirmCancel());
    setShowConfirmationModal(true);
  };

  const handleConfirmCancel = () => {
    window.location.href = '/districts';
  };

  // Convenience method for resetting the confirmation modal component
  const handleResetConfirmationModal = () => {
    setShowConfirmationModal(false);
    setConfirmationModalHeading(CONFIRMATION_MODAL_DEFAULTS.confirmationModalHeading);
    setConfirmationModalBody(CONFIRMATION_MODAL_DEFAULTS.confirmationModalBody);
    setConfirmationModalCancelText(CONFIRMATION_MODAL_DEFAULTS.confirmationModalCancelText);
    setConfirmationModalConfirmText(CONFIRMATION_MODAL_DEFAULTS.confirmationModalConfirmText);
    setConfirmationModalConfirmAction(CONFIRMATION_MODAL_DEFAULTS.confirmationModalConfirmAction);
    setConfirmationModalSubmitting(CONFIRMATION_MODAL_DEFAULTS.confirmationModalSubmitting);
  };

  // In situations where the district set doesn't include districts assigned to races we display
  // a scary confirmation message to them anyway so they're aware of the consequences of their actions
  const handleConfirmDelete = () => {
    const confirmBody = (
      <Typography component="p" variant="body1">
        Are you sure you want to delete this district set? It and all of its child districts will be <strong>permanently deleted</strong>! Any race currently using a district from this set will have no district assigned to it.
      </Typography>
    );
    setConfirmationModalHeading(`Delete ${districtSet.name}`);
    setConfirmationModalBody(confirmBody);
    setConfirmationModalCancelText('Cancel');
    setConfirmationModalConfirmText('Confirm');
    setConfirmationModalConfirmAction(() => () => handleDelete());
    setShowConfirmationModal(true);
  };

  // Display any races assigned to districts in this set so the user doesn't
  // accidentally impact published races
  const displayAssignedRaces = (races) => {
    const numberOfRacesToDisplay = 5;
    const numberOfRaces = races.length;
    let raceList = [];
    let hasHiddenRaces = false;
    let numberOfHiddenRaces = 0;

    if (numberOfRaces > numberOfRacesToDisplay) {
      hasHiddenRaces = true;
      numberOfHiddenRaces = numberOfRaces - numberOfRacesToDisplay;
      for (let i = 0; i <= (numberOfRacesToDisplay - 1); i++) {
        raceList.push(races[i]);
      }
    } else {
      raceList = races;
    }

    return (
      <ul className={classes.raceList}>
        {raceList.map(race => (
          <li key={race.id}>{race.name}</li>
        ))}
        {hasHiddenRaces && (
          <li>...and {numberOfHiddenRaces} more</li>
        )}
      </ul>
    );
  };

  // If the set has districts assigned to races, we display those races and verify the user REALLY wants to delete the set
  const handleConfirmDeleteSetWithAssignedRacesConfirmation = (races) => {
    const confirmBody = (
      <>
        <Typography component="p" variant="body1">
          The district set and all of its child districts will be <strong>permanently deleted</strong>! The following races will have their <strong>districts unassigned</strong>. If they are published, this will result in them becoming suppressed from users.
        </Typography>
        <br />

        {displayAssignedRaces(races)}
      </>
    );
    setConfirmationModalHeading('Are you sure?');
    setConfirmationModalBody(confirmBody);
    setConfirmationModalCancelText('Cancel');
    setConfirmationModalConfirmText('Yes, I\'m sure');
    setConfirmationModalConfirmAction(() => () => handleDelete());
    setConfirmationModalSubmitting(false);
  };

  // Before we delete the set, let's check if the district set has any districts that are assigned to races
  const handleCheckForAssignedRacesBeforeDelete = () => {
    setConfirmationModalSubmitting(true);
    setConfirmationModalHeading('Checking races...');
    setConfirmationModalBody(<div className={classes.confirmationLoading}><CircularProgress /></div>);
    setShowConfirmationModal(true);

    fetchUtil(`/api/v1/guide/${guideId}/district/set/${districtSet.id}/races`, 'GET')
      .then(response => {
        if (response.length > 0) {
          // The district set has districts assigned to races
          // so let's make sure the user REALLY wants to do this
          handleConfirmDeleteSetWithAssignedRacesConfirmation(response);
          return;
        }

        handleConfirmDelete();
      })
      .catch(error => {
        console.error(error);
        handleConfirmDelete();
        setConfirmationModalSubmitting(false);
      });
  };

  const handleDelete = () => {
    setConfirmationModalSubmitting(true);

    fetchUtil(`/api/v1/guide/${guideId}/district/set/${districtSet.id}`, 'DELETE')
      .then(() => {
        // Redirect user to the district dashboard and show a snackbar notification
        window.location.href = '/districts?showSuccessMsgFrom=deleteDistrictSet';
      })
      .catch(error => {
        const status = error?.status;
        console.error(error);

        setConfirmationModalSubmitting(false);

        if (status && status == 403) {
          showError('Only guide admins are allowed to delete District Sets. Contact your guide admin for assistance.');
          return;
        }

        if (error && error.data) {
          showError(error.data);
          return;
        }

        showError('There was an issue deleting your District Set. Try again or contact your guide admin if this continues.');
      });
  };

  const checkCyclical = (newContainingId, childDistricts) => (
    childDistricts.find(cd => cd.id === newContainingId)
  );

  const handleCheckAssignedRaces = () => {
    if (districtSet.childDistrictsToDelete.length > 0) {
      setConfirmationModalSubmitting(true);
      setConfirmationModalHeading('Checking races...');
      setConfirmationModalBody(<div className={classes.confirmationLoading}><CircularProgress /></div>);
      setShowConfirmationModal(true);

      fetchUtil(`/api/v1/guide/${guideId}/race?district_ids=${districtSet.childDistrictsToDelete.join(',')}`, 'GET')
        .then(response => {
          if (response.length > 0) {
            // The districts being deleted are assigned to races
            // so let's make sure the user REALLY wants to do this
            const confirmBody = (
              <>
                <Typography component="p" variant="body1">
                  The following races are assigned to districts you are deleting! If those races are currently published, they will become <strong>suppressed from users</strong>.
                </Typography>
                <br />

                {displayAssignedRaces(response)}
              </>
            );
            setConfirmationModalHeading('Are you sure?');
            setConfirmationModalBody(confirmBody);
            setConfirmationModalCancelText('Cancel');
            setConfirmationModalConfirmText('Yes, I\'m sure');
            setConfirmationModalConfirmAction(() => () => handleSave());
            setConfirmationModalSubmitting(false);
            return;
          }

          handleSave();
        })
        .catch(error => {
          console.error(error);

          // We couldn't check if the districts being deleted are assigned to races
          // so we throw a generic confirmation dialog to warn users
          const confirmBody = (
            <Typography component="p" variant="body1">
              You are deleting districts from this set. If races are currently assigned to those districts and are published, they will become <strong>suppressed from users</strong>.
            </Typography>
          );
          setConfirmationModalHeading('Are you sure?');
          setConfirmationModalBody(confirmBody);
          setConfirmationModalCancelText('Cancel');
          setConfirmationModalConfirmText('Yes, I\'m sure');
          setConfirmationModalConfirmAction(() => () => handleSave());
          setConfirmationModalSubmitting(false);
        });
      return;
    }

    handleSave();
  };

  const handleSave = () => {
    let url = `/api/v1/guide/${guideId}/district/set`;
    let httpMethod = 'POST';

    if (editMode) {
      url = `/api/v1/guide/${guideId}/district/set/${districtSet.id}`;
      httpMethod = 'PUT';
    }

    const districtId = districtSet?.district?.id ? districtSet.district.id : null;

    setSubmitting(true);

    if (set?.childDistricts && checkCyclical(districtId, set.childDistricts)) {
      showError('The containing district is invalid because it would create a circular definition.');
      setErrors({
        districtId: ['Circular definitions are forbidden'],
      });
      setSubmitting(false);
      return;
    }

    fetchUtil(url, httpMethod, {
      id: districtSet.id,
      name: districtSet.name,
      description: districtSet.description,
      districtId,
      childDistrictsToAdd: districtSet.childDistrictsToAdd,
      childDistrictsToDelete: districtSet.childDistrictsToDelete,
    })
      .then(() => {
        setSubmitting(false);
        window.location.href = '/districts?showSuccessMsgFrom=districtSet';
      }).catch(error => {
        console.error(error);
        setSubmitting(false);
        setErrors(error);

        if (error && error.data) {
          showError(error.data);
          return;
        }

        showError(msgActs.errorSavingDistrictSet);
      });
  };

  const getChildDistrictIndexByFieldValue = (field, value, districtList) => districtList.findIndex(d => d[field] == value);
  const getDistrictIndexByName = (name) => getChildDistrictIndexByFieldValue('name', name, districtSet.childDistricts);

  const handleAdd = (districtName) => {
    const districtIndex = getDistrictIndexByName(districtName);

    // If the district already exists in our district list, we'll check if it's
    // in the list of districts to remove and, if so, remove it from there
    // and then bail out
    if (districtIndex >= 0) {
      const districtToDelete = districtSet.childDistricts[districtIndex];

      // We only care about districts that have an ID (i.e. exist in the database)
      if (Object.prototype.hasOwnProperty.call(districtToDelete, 'id')) {
        const deleteDistrictIndex = getChildDistrictIndexByFieldValue('id', districtToDelete.id, districtSet.childDistrictsToDelete);

        // If the district exists in the list of districts to delete, we'll remove it from that list
        // since it appears we want it now. This should never happen, but we'll handle it just in case.
        if (deleteDistrictIndex >= 0) {
          const newDistrictsToDelete = districtSet.childDistrictsToDelete.slice(0);
          newDistrictsToDelete.splice(deleteDistrictIndex, 1);

          setDistrictSet({
            ...districtSet,
            childDistrictsToDelete: newDistrictsToDelete,
          });
        }
      }

      return;
    }

    // Add the district to our new district list
    const newDistricts = districtSet.childDistricts.slice(0);
    newDistricts.push({ name: districtName });

    // Check if the name appears in our list of districts to add
    const newDistrictsToAdd = districtSet.childDistrictsToAdd.slice(0);
    const addDistrictIndex = getChildDistrictIndexByFieldValue('name', districtName, districtSet.childDistrictsToAdd);
    if (!addDistrictIndex >= 0) {
      newDistrictsToAdd.push(districtName);
    }

    const updatedDistrictSet = {
      ...districtSet,
      childDistricts: newDistricts,
      childDistrictsToAdd: newDistrictsToAdd,
    };

    setDistrictSet(updatedDistrictSet);
  };

  const handleRemove = (districtName) => {
    const districtIndex = getDistrictIndexByName(districtName);

    // If we couldn't find a district in our district list, who knows how we got here, but we
    // can ignore the request and go home early.
    if (!(districtIndex >= 0)) {
      console.error(`Do district matching name ${districtName} exists on this set`);
      return;
    }

    // Clone our various lists of districts
    const newDistricts = districtSet.childDistricts.slice();
    const newDistrictsToAdd = districtSet.childDistrictsToAdd.slice(0);
    const newDistrictsToDelete = districtSet.childDistrictsToDelete.slice(0);

    // REMOVE the matching district from our list of districts
    newDistricts.splice(districtIndex, 1);

    // Check our list of districts to ADD and REMOVE IT IF IT EXISTS
    const addDistrictIndex = getChildDistrictIndexByFieldValue('name', districtName, districtSet.childDistrictsToAdd);
    if (addDistrictIndex >= 0) {
      // Remove the matching district from our list of districts to add
      newDistrictsToAdd.splice(addDistrictIndex, 1);
    }

    // Check if our district has an ID. If so, we need to add it to our list to remove from the database
    const districtToDelete = districtSet.childDistricts[districtIndex];
    if (Object.prototype.hasOwnProperty.call(districtToDelete, 'id')) {
      const deleteDistrictIndex = getChildDistrictIndexByFieldValue('id', districtToDelete.id, districtSet.childDistrictsToDelete);

      if (!(deleteDistrictIndex >= 0)) {
        // Add the district ID to the list to remove from the database
        newDistrictsToDelete.push(districtToDelete.id);
      }
    }

    const updatedDistrictSet = {
      ...districtSet,
      childDistricts: newDistricts,
      childDistrictsToAdd: newDistrictsToAdd,
      childDistrictsToDelete: newDistrictsToDelete,
    };

    setDistrictSet(updatedDistrictSet);
  };

  const handleChange = (field, value) => {
    const copyDistrictSet = { ...districtSet };
    copyDistrictSet[field] = value;
    setDistrictSet(copyDistrictSet);
  };

  // Render
  return (
    <ThemeProvider theme={theme}>
      <SnackbarAlert ref={_snackbar} />

      <ConfirmationDialog
        cancelButtonText={confirmationModalCancelText}
        confirmButtonText={confirmationModalConfirmText}
        heading={confirmationModalHeading}
        onCancel={() => handleResetConfirmationModal()}
        onConfirm={() => confirmationModalConfirmAction()}
        open={showConfirmationModal}
        submitting={confirmationModalSubmitting}
      >
        {confirmationModalBody}
      </ConfirmationDialog>

      <PageHeader
        breadcrumbText="District Dashboard"
        breadcrumbUrl="/districts"
        heading={messages.title}
        subheading={messages.subtitle}
      />

      <PageActionsHeader>
        <Button
          color="primary"
          onClick={() => handleCancelConfirmation(true)}
          size="small"
        >
          {msgActs.cancel}
        </Button>

        {districtSet.id && (
          <Button
            className={classes.danger}
            onClick={() => handleCheckForAssignedRacesBeforeDelete()}
            size="small"
          >
            Delete
          </Button>
        )}

        <Button
          color="secondary"
          disabled={submitting}
          onClick={handleCheckAssignedRaces}
          size="small"
          variant="contained"
        >
          {msgActs.save}
        </Button>
      </PageActionsHeader>

      <div className="ga-container">
        <div className="mdc-layout-grid">
          <div className="ga-w-100-p">
            {containingDistrictIsLoaded && (
              <DistrictSetForm
                errors={errors}
                guideId={guideId}
                locationInput={locationInput}
                messages={messages.form}
                onAdd={(district) => handleAdd(district)}
                onChange={(field, value) => handleChange(field, value)}
                onRemove={(district) => handleRemove(district)}
                set={districtSet}
                submitting={submitting}
                wysiwygDefault={renderedWYSIWYGValue}
              ></DistrictSetForm>
            )}
          </div>
        </div>
      </div>

      <PageActionsFooter sticky>
        <Button
          color="primary"
          onClick={() => handleCancelConfirmation(true)}
        >
          {msgActs.cancel}
        </Button>

        {districtSet.id && (
          <Button
            className={classes.danger}
            onClick={() => handleCheckForAssignedRacesBeforeDelete()}
          >
            Delete
          </Button>
        )}

        <Button
          color="secondary"
          disabled={submitting}
          onClick={handleCheckAssignedRaces}
          variant="contained"
        >
          {msgActs.save}
        </Button>
      </PageActionsFooter>
    </ThemeProvider>
  );
};

DistrictSetContainer.propTypes = {
  guideId: PropTypes.string,
  messages: PropTypes.object,
  set: PropTypes.object,
};

DistrictSetContainer.defaultProps = {
  guideId: '',
  messages: {},
  set: {},
};

export default DistrictSetContainer;
