import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@material-ui/core/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {
  Button,
  Card,
  CardContent,
  CardActions,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    maxWidth: '405px',
  },
  actionsContainer: {
    justifyContent: 'center',
    paddingBottom: '1.25rem',
  },
  cardContent: {
    padding: '16px 16px 0 16px',
  },
  container: {
    border: '4px solid #E2EAEE',
    borderRadius: '5px',
    color: '#666',
    marginTop: '8px',
    maxWidth: '405px',
    minHeight: '275px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .upload-icon': {
      color: '#e8e8e8',
    },
  },
  containerDanger: {
    // backgroundColor: 'rgb(253, 236, 234)',
    borderColor: '#b00020',
    color: '#b00020',
    '& .upload-icon': {
      color: '#b00020',
      opacity: '.2',
    },
    '& .upload-text': {
      opacity: '0.8',
    },
  },
  containerSuccess: {
    // backgroundColor: 'rgb(237, 247, 237)',
    borderColor: 'rgb(30, 70, 32)',
    color: 'rgb(30, 70, 32)',
    '& .upload-icon': {
      color: 'rgb(30, 70, 32)',
      opacity: '.2',
    },
    '& .upload-text': {
      opacity: '0.8',
    },
  },
  containerHover: {
    backgroundColor: 'rgb(232, 244, 253)',
    borderColor: 'rgb(13, 60, 97)',
    color: 'rgb(13, 60, 97)',
    '& .upload-icon': {
      color: 'rgb(13, 60, 97)',
    },
  },
  icon: {
    fontSize: '5.75rem',
  },
  label: {
    marginBottom: '8px',
  },
  uploadText: {
    fontSize: '1.5rem',
    lineHeight: '1.75rem',
  },
  filePath: {
    fontSize: '1rem',
    fontWeight: 600,
    margin: '1rem 0',
    wordBreak: 'break-all',
    // opacity: '0.8',
  },
});

function UploadCard({
  acceptedFileTypes,
  buttonText,
  disabled,
  errorMsgOverride,
  label,
  maxSize,
  minSize,
  onChange,
  text,
  didRemove,
}) {
  const classes = useStyles();
  const [hasRejected, setHasRejected] = useState(false);
  const [files, setFiles] = useState([]);
  const maxSizeBytes = maxSize * 1048576;
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    fileRejections,
  } = useDropzone({
    accept: acceptedFileTypes,
    disabled,
    minSize,
    maxSize: maxSizeBytes, // set maxSize based on MB. this will convert it to bytes.
    multiple: false,
    onDrop: (accepted) => {
      if (accepted && accepted.length > 0) {
        setHasRejected(false);
      } else {
        setHasRejected(true);
      }

      setFiles(accepted);
    },
  });

  useEffect(() => {
    onChange(files);
  }, [files]);

  const getContainerClasses = () => {
    if (isDragActive) {
      return `${classes.container} ${classes.containerHover}`;
    }

    if (acceptedFiles && acceptedFiles.length > 0 && !didRemove) {
      return `${classes.container} ${classes.containerSuccess}`;
    }

    if (hasRejected) {
      return `${classes.container} ${classes.containerDanger}`;
    }

    return classes.container;
  };

  const renderMessage = () => {
    if (acceptedFiles && acceptedFiles.length > 0 && !didRemove) {
      return (
        <>
          <CloudUploadIcon className={`${classes.icon} upload-icon`} />
          <p className={`${classes.uploadText} upload-text`}>File ready to upload!</p>
          <p className={classes.filePath}>{acceptedFiles[0].path}</p>
        </>
      );
    }

    // if file size is too large, display a different message than if file type is rejected (else statement)
    if (hasRejected && fileRejections.length > 0) {
      return (
        <>
          <CloudUploadIcon className={`${classes.icon} upload-icon`} />
          {fileRejections[0].file.size > maxSize ? (
            <p className={classes.uploadText}>
              {errorMsgOverride ? (
                <p className={classes.uploadText}>{errorMsgOverride}</p>
              ) : (
                <p className={classes.uploadText}>Only files smaller than {maxSize} MB can be uploaded. </p>
              )}
            </p>
          ) : (
            <p className={classes.uploadText}>Only files with a type of {acceptedFileTypes} can be uploaded. </p>
          )}
        </>
      );
    }

    return (
      <>
        <CloudUploadIcon className={`${classes.icon} upload-icon`} />
        <p className={classes.uploadText}>{text}</p>
      </>
    );
  };

  return (
    <div className={classes.root}>
      {(label || label !== '') && (
        <Typography component="p" variant="body2">
          {label}
        </Typography>
      )}
      <Card
        className={getContainerClasses()}
        variant="outlined"
        {...getRootProps()}
      >
        <input {...getInputProps()} data-testid="file-input" />

        <CardContent className={classes.cardContent}>
          {renderMessage()}
        </CardContent>

        <CardActions className={classes.actionsContainer}>
          <Button
            color='secondary'
            disabled={disabled}
            size='large'
            variant='outlined'
          >
            {buttonText}
          </Button>
        </CardActions>
      </Card>
    </div>
  );
}

UploadCard.propTypes = {
  // The value must be a comma-separated list of unique content type specifiers:
  // A file extension starting with the STOP character (U+002E). (e.g. .jpg, .png, .doc).
  // A valid MIME type with no extensions.
  // audio/* representing sound files.
  // video/* representing video files.
  // image/* representing image files.
  acceptedFileTypes: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  onChange: PropTypes.func,
  text: PropTypes.string,
  errorMsgOverride: PropTypes.string,
  didRemove: PropTypes.bool,
};

UploadCard.defaultProps = {
  acceptedFileTypes: 'image/*',
  buttonText: 'Attach',
  disabled: false,
  label: '',
  maxSize: Infinity,
  minSize: 0,
  onChange: () => {},
  text: 'default text',
  didRemove: false,
};

export default UploadCard;
