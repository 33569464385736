import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import BioQuestionSetForm from 'components/BioQuestions/BioQuestionSetForm';

const styles = {
  modalContainer: {
    paddingBottom: '.5rem',
    overflow: 'hidden',
  },
  modalContentContainer: {
    padding: 0,
  },
  modalContent: {
    padding: '0rem 2rem',
  },
};

function BioQuestionSetCreateModal(props) {
  const {
    cancelButtonText,
    classes,
    defaultQuestionsHeader,
    errors,
    heading,
    onClose,
    onSubmit,
    open,
    questions,
    questionTableHeader,
    requiredText,
    subheading,
    submitButtonText,
    submitting,
  } = props;

  return (
    <Dialog
      aria-labelledby="edit-race-question-title"
      className={classes.modalContainer}
      fullWidth={true}
      maxWidth="md"
      open={open}
      onClose={onClose}
    >
      <DialogTitle id="edit-race-question-title" disableTypography >
        <Typography component="h1" variant="h1">
          {heading}
        </Typography>

        <IconButton onClick={onClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.modalContentContainer}>
        <DialogContentText className={classes.modalContent}>
          {subheading}
        </DialogContentText>

        <BioQuestionSetForm
          defaultQuestionsHeader={defaultQuestionsHeader}
          errors={errors}
          id=""
          name=""
          onSubmit={onSubmit}
          outlined={false}
          questions={questions}
          questionTableHeader={questionTableHeader}
          requiredText={requiredText}
          submitButtonText={submitButtonText}
          submitting={submitting}
          tableHeader={questionTableHeader}
        >
          <Button onClick={onClose} size="large">
            {cancelButtonText}
          </Button>
        </BioQuestionSetForm>
      </DialogContent>
    </Dialog>
  );
}

BioQuestionSetCreateModal.propTypes = {
  cancelButtonText: PropTypes.string,
  classes: PropTypes.object,
  defaultQuestionsHeader: PropTypes.string,
  errors: PropTypes.object,
  heading: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  open: PropTypes.bool,
  questions: PropTypes.arrayOf(PropTypes.shape({
    default: PropTypes.bool,
    id: PropTypes.number,
    index: PropTypes.number,
    maxl: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.string,
  })),
  questionTableHeader: PropTypes.string,
  requiredText: PropTypes.string,
  subheading: PropTypes.string,
  submitButtonText: PropTypes.string,
  submitting: PropTypes.bool,
};

BioQuestionSetCreateModal.defaultProps = {
  cancelButtonText: 'Cancel',
  defaultQuestionsHeader: 'Default biographical questions',
  errors: {},
  heading: 'Create Biographical Set',
  onClose: () => {},
  onSubmit: () => {},
  open: false,
  questions: [],
  questionTableHeader: 'Available biographical questions',
  requiredText: '*Required fields needed to create a new question set',
  subheading: 'By default, some biographical questions are always included in every set. Use the table below to add more.',
  submitButtonText: 'Create Set',
  submitting: false,
};

export default withStyles(styles)(BioQuestionSetCreateModal);
