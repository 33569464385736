import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/styles';
import {
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import TabPanel from 'components/TabPanel';
import EmptyState from 'components/EmptyState';

const styles = {
  linkText: {
    color: '#0a3a5a',
  },
};

const useStyles = makeStyles(() => ({
  questionRows: {
    paddingBottom: '0.938rem',
    marginBottom: '0.938rem',
    borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
    '&:last-child': {
      borderBottom: 'none',
      paddingBottom: '0rem',
      marginBottom: '0rem',
    },
  },
}));

const RaceQuestion = (props) => {
  const classes = useStyles();
  const { children } = props;
  return (
    <div className={classes.questionRows}>
      <Typography component="p" variant="body2">
        {children}
      </Typography>
    </div>
  );
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

class LanguageTabsDisplay extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 0,
    };

    this.handleTabChange = this.handleTabChange.bind(this);
  }

  handleTabChange(event, newValue) {
    this.setState({
      activeTab: newValue,
    });
  }

  displayTabs() {
    const { languages, id } = this.props;
    const { activeTab } = this.state;
    let languageTabs = [];

    if (languages && languages.length > 0) {
      languageTabs = languages.map(lang => <Tab key={`${id}-${lang.id}`} label={lang.label} {...a11yProps(lang.id)} />);
    }

    return (
      <Tabs
        aria-label="Question translation tabs"
        indicatorColor="secondary"
        onChange={this.handleTabChange}
        scrollButtons="auto"
        textColor="secondary"
        value={activeTab}
        variant="scrollable"
            >
        <Tab label="English" {...a11yProps('en')} />
        {languageTabs}
      </Tabs>
    );
  }

  displayTabPanels() {
    const {
      translatedQuestionsEmptyText,
      englishQuestionsEmptyText,
      canAddQuestions,
      languages,
      questionSetRootUrl,
      id,
      questions,
      classes,
    } = this.props;
    const { activeTab } = this.state;
    let tabPanels = [];
    let englishQuestions = [];

    if (languages && languages.length > 0) {
      tabPanels = languages.map((lang, index) => {
        let tabBody = [];

        questions.forEach(question => {
          if (Object.prototype.hasOwnProperty.call(question.translations, lang.id)) {
            tabBody.push(<RaceQuestion key={`question${question.id}-${lang.id}`}>{question.translations[lang.id]}</RaceQuestion>);
          }
        });

        if (tabBody.length <= 0) {
          tabBody = <EmptyState>
            {canAddQuestions ? (
              <p>{translatedQuestionsEmptyText}<br />
                <a className={classes.linkText} href={`${questionSetRootUrl}/${id}`} >Add translations.</a>
              </p>
            ) : (
              <p>{translatedQuestionsEmptyText}</p>
            )}

          </EmptyState>;
        }

        return (
          <TabPanel index={index + 1} key={`panel-${id}-${lang.id}`} value={activeTab}>
            {tabBody}
          </TabPanel>
        );
      });
    }

    questions.forEach(question => {
      englishQuestions.push(<RaceQuestion key={`${id}-question${question.id}`}>{question.body}</RaceQuestion>);
    });

    if (englishQuestions.length <= 0) {
      englishQuestions = <EmptyState>
        {canAddQuestions ? (
          <p>{englishQuestionsEmptyText}<br />
            <a className={classes.linkText} href={`${questionSetRootUrl}/${id}`}>Add questions.</a>
          </p>
        ) : (
          <p>{englishQuestionsEmptyText}</p>
        )}

      </EmptyState>;
    }

    // Append English questions to the beginning
    tabPanels.unshift(
      <TabPanel key={`panel-${id}-en`} value={activeTab} index={0}>
        {englishQuestions}
      </TabPanel>,
    );

    return tabPanels;
  }

  render() {
    return (
      <div>
        {this.displayTabs()}
        {this.displayTabPanels()}
      </div>
    );
  }
}

RaceQuestion.propTypes = {
  children: PropTypes.node,
};

LanguageTabsDisplay.propTypes = {
  canAddQuestions: PropTypes.bool,
  children: PropTypes.node,
  classes: PropTypes.object,
  englishQuestionsEmptyText: PropTypes.string,
  id: PropTypes.number,
  languages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
  })),
  name: PropTypes.string,
  questions: PropTypes.arrayOf(PropTypes.shape({
    body: PropTypes.string,
    default: PropTypes.bool,
    id: PropTypes.number,
    index: PropTypes.number,
    maxl: PropTypes.number,
    name: PropTypes.string,
    translations: PropTypes.object,
    type: PropTypes.number,
  })),
  questionSetRootUrl: PropTypes.string.isRequired,
  translatedQuestionsEmptyText: PropTypes.string,
};

LanguageTabsDisplay.defaultProps = {
  canAddQuestions: true,
  englishQuestionsEmptyText: 'No questions have been added to this set yet.',
  id: 0,
  languages: [],
  name: '',
  questions: [],
  questionSetRootUrl: '/questions',
  translatedQuestionsEmptyText: 'There are no translated questions for this language.',
};

export default withStyles(styles)(LanguageTabsDisplay);
