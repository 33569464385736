import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  label: {
    fontSize: '0.75rem',
    color: '#666',
    letterSpacing: '1px',
    marginBottom: '6px',
  },
});

function ReadOnlyField({ className, label, value }) {
  const classes = useStyles();

  return (
    <div className={`${className}`}>
      <p className={classes.label}>
        {label}
      </p>

      <Typography variant="body1" component="p">
        {value}
      </Typography>
    </div>
  );
}

ReadOnlyField.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

ReadOnlyField.defaultProps = {
  className: '',
};

export default ReadOnlyField;
