import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles({
  listContainer: {
    borderBottom: '1px solid #e3e3e3',
    display: 'flex',
    padding: '1rem 0 1rem 2rem',
  },
  listItemName: {
    flexBasis: '25%',
    fontWeight: 600,
  },
  listItemDescription: {
    flexBasis: '75%',
  },
});

const BulkPublishResults = ({
  actionIsPublish,
  onClose,
  races,
  show,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      maxWidth="md"
      open={show}
      onClose={onClose}
    >
      <DialogTitle id="alert-dialog-title" disableTypography>
        <Typography component="h1" variant="h1">
          Some races or measures could not be {actionIsPublish ? 'published' : 'unpublished'}
        </Typography>

        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Typography component="p" variant="body1" id="alert-dialog-description">
          Your {actionIsPublish ? 'publish' : 'unpublish'} action was successful, but some races/measures
          could not successfully be {actionIsPublish ? 'published' : 'unpublished'}. The races/measures
          below <strong>were not successfully {actionIsPublish ? 'published' : 'unpublished'}</strong>:
        </Typography>

        {races.map(({ name, issue }, index) => (
          <div className={classes.listContainer} key={index}>
            <div className={classes.listItemName}>{name}</div>
            <div className={classes.listItemDescription}>{issue}</div>
          </div>
        ))}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="contained">
          Got it!
        </Button>
      </DialogActions>
    </Dialog>
  );
};

BulkPublishResults.propTypes = {
  actionIsPublish: PropTypes.bool,
  onClose: PropTypes.func,
  races: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    issue: PropTypes.string,
  })),
  show: PropTypes.bool,
};

BulkPublishResults.defaultProps = {
  actionIsPublish: false,
  onClose: () => {},
  races: [],
  show: false,
};

export default BulkPublishResults;
