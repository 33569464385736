import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Tooltip } from '@material-ui/core';

class ActionsDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      show: false,
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleOpen(event) {
    this.setState({
      anchorEl: event.currentTarget,
      show: true,
    });
  }

  handleClose() {
    this.setState({
      anchorEl: null,
      show: false,
    });
  }

  handleClick(onClick) {
    this.setState({
      anchorEl: null,
      show: false,
    });

    onClick();
  }

  renderOptions() {
    const { options } = this.props;

    return options.map((option, index) => (
      <MenuItem key={index} onClick={() => this.handleClick(option.onClick)}>
        {option.label}
      </MenuItem>
    ));
  }

  render() {
    const { anchorEl, show } = this.state;
    const { id, disabled, tooltip } = this.props;

    return (
      <div>
        <Tooltip title={tooltip}>
          <div>
            <IconButton
              aria-label="actions"
              aria-controls={id}
              aria-haspopup="true"
              onClick={this.handleOpen}
              disabled={disabled}
          >
              <MoreVertIcon />
            </IconButton>
          </div>
        </Tooltip>

        <Menu
          id={id}
          anchorEl={anchorEl}
          keepMounted
          open={show}
          onClose={this.handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {this.renderOptions()}
        </Menu>
      </div>
    );
  }
}

ActionsDropdown.propTypes = {
  id: PropTypes.string, // unique id to tie the button to the dropdown options (ex. "race-actions" )
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
  })),
  disabled: PropTypes.bool,
  tooltip: PropTypes.string,
};

ActionsDropdown.defaultProps = {
  id: 'more-options-dropdown',
  options: [],
  disabled: false,
  tooltip: '',
};

export default ActionsDropdown;
