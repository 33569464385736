import validator from 'validator';
import GaUtils from './GaUtils';

export default class GaValidations {
  /**
   *
   * @param {validation[]} validations list of validations, validation --> {fnVal: function, fnMsg: function}
   * @param {any} value Value to validate
   * @returns {resvalidation[]} resvalidation --> {msg: string}
   */
  static checkValidations = (field, fieldName, validations, value) => {
    const resValidations = [];
    if (validations && validations.length > 0) {
      validations.forEach((valid) => {
        if (
          valid.fnVal
          && typeof valid.fnVal === 'function'
          && valid.fnMsg
          && typeof valid.fnMsg === 'function'
        ) {
          const resValid = valid.fnVal(value);
          if (resValid == true) {
            let msg = null;
            if (valid.fnMsg.length == 1) {
              msg = valid.fnMsg(fieldName);
            } else if (valid.fnMsg.length == 2) {
              msg = valid.fnMsg(fieldName, value);
            } else if (valid.fnMsg.length == 0) {
              msg = valid.fnMsg();
            }
            resValidations.push({ msg });
          }
        }
      });
    }
    return resValidations;
  };

  static localValidations = (field, fieldName, validations, value, errors) => {
    const newErrors = errors || {};
    const vals = this.checkValidations(field, fieldName, validations, value);
    if (vals && vals.length > 0) {
      newErrors[field] = vals.map(v => v.msg);
    }
    return newErrors;
  };

  static setErrorValidations = (errors, id, field, validations, value) => {
    if (validations && validations.length > 0) {
      const resValList = this.checkValidations(field, field, validations, value);

      if (resValList && resValList.length > 0) {
        // there are errors
        const objError = errors.find((e) => e.id == id);
        if (objError) {
          if (!objError.fields) {
            objError.fields = [];
          }
          const objField = objError.fields.find((f) => f.id == field);
          if (objField) {
            objField.validations = resValList;
          } else {
            objError.fields.push({ id: field, validations: resValList });
          }
        } else {
          errors.push({
            id,
            fields: [{ id: field, validations: resValList }],
          });
        }
      } else {
        const objError = errors.find((e) => e.id == id);
        if (objError && objError.fields) {
          // deleting field
          objError.fields = objError.fields.filter((f) => f.id != field);

          if (objError.fields.length == 0) {
            // eslint-disable-next-line no-param-reassign
            errors = errors.filter((e) => e.id != id);
          }
        }
      }
    }
  };

  // =================================================================
  // public functions
  // =================================================================

  static hasErrors = (errors) => errors.length > 0;

  static hasErrorByItem = (errors, id) => {
    const result = errors.some((e) => e.id == id);
    return result;
  };

  static hasErrorByItemField = (errors, id, field) => {
    let result = false;
    const objError = errors.find((e) => e.id == id);
    if (objError && objError.fields) {
      result = objError.fields.some((f) => f.id == field);
    }
    return result;
  };

  static hasErrorsWithCheck = (errors, keyValue, attr, validations, value) => {
    GaValidations.setErrorValidations(errors, keyValue, attr, validations, value);
    return this.hasErrors(errors);
  };

  static hasErrorByItemWithCheck = (errors, keyValue, attr, validations, value) => {
    GaValidations.setErrorValidations(errors, keyValue, attr, validations, value);
    return this.hasErrorByItem(errors, keyValue);
  };

  static hasErrorByItemFieldWithCheck = (errors, keyValue, attr, validations, value) => {
    GaValidations.setErrorValidations(errors, keyValue, attr, validations, value);
    return this.hasErrorByItemField(errors, keyValue, attr);
  };

  static getErrorsByItemField = (errors, id, field) => {
    let result = [];
    const objError = errors.find((e) => e.id == id);
    if (objError && objError.fields) {
      const objField = objError.fields.find((f) => f.id == field);
      if (objField && objField.validations) {
        result = objField.validations;
      }
    }
    return result;
  };

  static getMsgFirstErrorByItemField = (errors, id, field) => {
    let result = null;
    const allErrors = this.getErrorsByItemField(errors, id, field);
    if (allErrors && allErrors.length > 0) {
      result = allErrors[0].msg;
    }
    return result;
  };

  // =================================================================
  // Validations
  // =================================================================
  static newValidation = (fnValidation, fnMessage) => ({ fnVal: fnValidation, fnMsg: fnMessage });

  static maxLength100 = {
    fnVal: (val) => val.length > 100,
    fnMsg: () => 'Max length is 100 characters.',
  };

  static maxLength200 = {
    fnVal: (val) => val.length > 200,
    fnMsg: () => 'Max length is 200 characters.',
  };

  static IsRequired = {
    fnVal: (val) => GaUtils.isNullOrEmpty(val),
    fnMsg: (field) => `The ${field} is required.`,
  };

  static IsEmail = {
    fnVal: (val) => !GaUtils.isNullOrEmpty(val) && !validator.isEmail(val),
    fnMsg: (field) => `The ${field} is not an E-Mail.`,
  };

  static IsUrl = {
    fnVal: (val) => !GaUtils.isNullOrEmpty(val) && !validator.isURL(val),
    fnMsg: (field) => `The ${field} is not a URL.`,
  };

  static IsUsPhone = {
    fnVal: (val) => {
      // eslint-disable-next-line no-useless-escape
      const regExpr = RegExp('^(\\([0-9]{3}\\)|[0-9]{3}-?)[0-9]{3}-?[0-9]{4}$');
      return !GaUtils.isNullOrEmpty(val) && !regExpr.test(val);
    },
    fnMsg: (field) => `The ${field} is not a US phone.`,
  };
}
