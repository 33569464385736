import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  CircularProgress,
  Grid,
  Typography,
  Box,
  Button,
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import HelpfulHint from 'components/HelpfulHint';
import UploadCard from 'components/UploadCard';
import Stepper from 'components/RaceSteps/Stepper';
import BioQuestionInput from 'components/BioQuestionInput';
import MailAddressForm from 'components/BioQuestions/MailAddressForm';
import StepTracker from 'components/RaceSteps/StepTracker';
import useImageOrientation from 'hooks/useImageOrientation';
import BioQuestionClass from 'helpers/BioQuestionClass';
import exampleHeadshot from '../../../images/headshot.png';

const useStyles = makeStyles((theme) => ({
  detailList: {
    marginTop: '1rem',
  },
  detailListItem: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
    marginBottom: '1.25rem',
    paddingBottom: '1.25rem',
    '&:last-child': {
      marginBottom: '0rem',
    },
  },
  fields: {
    marginTop: '1.563rem',
  },
  grid: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
  },
  heading: {
    color: theme.palette.primary.main,
    fontWeight: '500',
    marginBottom: '5px',
    width: '100%',
  },
  helpText: {
    marginBottom: '10px',
  },
  image: {
    width: '320px',
    height: '320px',
    [theme.breakpoints.down('sm')]: {
      width: '240px',
      height: '240px',
    },
  },
  loadingState: {
    marginTop: '0.9rem',
    marginBottom: '1.8rem',
  },
  section: {
    marginBottom: '2.5rem',
  },
  subtitle: {
    [theme.breakpoints.up('md')]: {
      width: '75%',
    },
  },
  profileImgContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  profileImg: {
    maxWidth: '320px',
    maxHeight: '320px',
  },
  container: {
    position: 'relative',
  },
  dangerButton: {
    color: '#ac1b3d',
    '&:hover': {
      color: '#6B1126',
    },
    marginTop: '0.25rem',
  },
}));

const CampaignProfile = ({
  bioFields,
  handleChangedFile,
  handleRemovedPhoto,
  loading,
  messages,
  onChange,
  existingPhotoURL,
  saveChanges,
  resetState,
  submitting,
  updatePage,
  didRemove,
}) => {
  const navigator = useNavigate();
  const classes = useStyles();
  const { handleOrientation } = useImageOrientation();

  const onBack = () => {
    updatePage(1);
    navigator('/details');
    window.scrollTo(0, 0);
  };

  const onSkip = () => {
    resetState();
    updatePage(3);
    navigator('/questions');
    window.scrollTo(0, 0);
  };

  const onSaveContinue = async () => {
    await saveChanges(() => {
      updatePage(3);
      navigator('/questions');
      window.scrollTo(0, 0);
    });
  };

  const handleFieldChange = (question, newValue, fieldName = '') => {
    if (question.type === BioQuestionClass.ADDRESS_TYPE) {
      onChange(question.id, newValue, fieldName);
    } else {
      onChange(question.id, newValue);
    }
  };

  const handleRemovePhoto = () => {
    handleRemovedPhoto();
  };

  const displayQuestion = (bioQuestion, index) => {
    if (bioQuestion.type === BioQuestionClass.ADDRESS_TYPE) {
      return (
        <Grid item xs={12} key={index}>
          <MailAddressForm
            fields={bioQuestion.mailingAnswer}
            isFullWidth={false}
            key={bioQuestion.id}
            onChange={(event) => handleFieldChange(bioQuestion, event.target.value, event.target.name)}
            submitting={submitting}
          />
        </Grid>
      );
    }

    return (
      <Grid key={index} item xs={12}>
        <BioQuestionInput
          errors={bioQuestion.errors}
          htmlId={bioQuestion.id}
          key={bioQuestion.id}
          maxLengthValue={bioQuestion.maxl}
          onChange={(newValue) => handleFieldChange(bioQuestion, newValue)}
          submitting={submitting}
          title={bioQuestion.question}
          type={bioQuestion.type}
          value={bioQuestion.answer}
          helpText={bioQuestion.type === BioQuestionClass.PHONE_NUMBER ? 'Acceptable formats: 1234567890, 123-456-7890, (123)456-7890' : ''}
      /></Grid>
    );
  };

  return (
    <div className={classes.container}>
      <StepTracker currentStep={2} totalSteps={4} top="1rem" messages={{
        step: 'Step',
        of: 'of',
      }} />
      <Box className={classes.section}>
        <Typography variant="h1" component="h1" color="textSecondary" paragraph>
          {messages.stepHeader}
        </Typography>

        <Typography variant="body1" component="p" paragraph className={classes.subtitle}>
          {messages.stepSubheader}
        </Typography>

        <Typography variant="body1" component="p" paragraph className={classes.subtitle}>
          {messages.httpInfo}
        </Typography>
      </Box>

      {loading ? (
        <CircularProgress className={classes.loadingState} />
      ) : (
        <Grid container spacing={3} className={classes.section}>
          {bioFields && bioFields.map((bioQuestion, index) => displayQuestion(bioQuestion, index))}
        </Grid>
      )}

      <Grid container spacing={5} className={classes.section}>
        <Grid item xs={12} md={6} className={classes.profileImgContainer}>
          <Typography variant="h5" component="h2" className={classes.heading}>
            {messages.photoHeader}
          </Typography>

          {existingPhotoURL && (
            <>
              <img
                alt="Candidate Profile Photo"
                className={classes.profileImg}
                onLoad={handleOrientation}
                src={existingPhotoURL}
              />

              <Button
                variant="text"
                className={classes.dangerButton}
                disabled={submitting}
                onClick={handleRemovePhoto}
              >
                Remove Photo
              </Button>
            </>
          )}

          <UploadCard
            acceptedFileTypes='.jpg, .jpeg'
            errorMsgOverride='Your image does not fit the requirements. Please make sure it is a square image (320 x 320px) with a file size smaller than 10 MB.'
            minSize={0}
            maxSize={10}
            onChange={handleChangedFile}
            disabled={submitting}
            text='Drag and drop or upload your photo here (.jpg)'
            didRemove={didRemove}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <HelpfulHint>
            <Typography variant="body1" component="p" className={classes.helpText}>
              {messages.photoHelpTextSize}
            </Typography>

            <Typography variant="body1" component="p" className={classes.helpText}>
              {messages.photoHelpTextContent}
            </Typography>

            <Typography variant="body1" component="p" className={classes.helpText}>
              {messages.photoHelpTextExample}
            </Typography>

            <img src={exampleHeadshot} className={classes.image} alt='Example Headshot Img' />
          </HelpfulHint>
        </Grid>
      </Grid>

      <Stepper
        disabled={submitting}
        messages={messages.stepper}
        onBack={onBack}
        onSaveContinue={onSaveContinue}
        onSkip={onSkip}
        visibility={['back', 'skip', 'saveContinue']}
      />
    </div>
  );
};

CampaignProfile.propTypes = {
  bioFields: PropTypes.arrayOf(PropTypes.shape({
    edit: PropTypes.bool,
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    label: PropTypes.string,
    maxl: PropTypes.number,
    name: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  handleChangedFile: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  messages: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  existingPhotoURL: PropTypes.string,
  saveChanges: PropTypes.func,
  resetState: PropTypes.func,
  submitting: PropTypes.bool,
  updatePage: PropTypes.func.isRequired,
  handleRemovedPhoto: PropTypes.func,
  didRemove: PropTypes.bool,
};

CampaignProfile.defaultProps = {
  bioFields: [],
  loading: false,
  onChange: () => {},
  resetState: () => {},
  submitting: false,
  handleRemovedPhoto: () => {},
  didRemove: false,
};

export default CampaignProfile;
