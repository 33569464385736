import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  published: {
    color: '#3fa06f',
  },
  unpublished: {
    color: '#dd3561',
  },
});

function RaceStatus({ visible }) {
  const classes = useStyles();

  if (visible) {
    return (
      <span className={classes.container}>
        <FiberManualRecordIcon className={classes.published} /> Published
      </span>
    );
  }

  return (
    <span className={classes.container}>
      <FiberManualRecordIcon className={classes.unpublished} /> Unpublished
    </span>
  );
}

RaceStatus.propTypes = {
  visible: PropTypes.bool.isRequired,
};

RaceStatus.defaultProps = {
  visible: false,
};

export default RaceStatus;
