import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Select,
  TextField,
  Typography,
  Tooltip,
} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import CreateIcon from '@material-ui/icons/Create';
import { Autocomplete } from '@material-ui/lab';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import PersonIcon from '@material-ui/icons/Person';
import Rsc from 'react-scrollbars-custom';

const useStyles = makeStyles({
  formElement: {
    marginBottom: '1.5rem',
  },
  addFormActions: {
    backgroundColor: '#eef4f8',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1.5rem 2rem',
    width: '100%',
    marginTop: '8px',
  },
  cardContainerNoOutline: {
    border: 'none',
    boxShadow: 'none',
  },
  actionButton: {
    marginRight: '1rem',
  },
  'actionButton:last-child': {
    marginRight: 0,
  },
  requiredAsterisk: {
    color: '#ac1b3d',
  },
  candidatePhoto: {
    borderRadius: '50%',
    display: 'flex',
    objectFit: 'cover',
    width: '6rem',
    height: '6rem',
    backgroundColor: '#fff',
  },
  candidatePhotoButton: {
    position: 'relative',
    textAlign: 'center',
    display: 'block',
    textDecoration: 'underline',
  },
  candidatePhotoButtonIcon: {
    position: 'absolute',
    top: '-2.5rem',
    right: '0',
    borderRadius: '50%',
    padding: '.75rem',
    backgroundColor: '#bb29bb',
    fill: '#fff',
    fontSize: '3rem',
  },
  candidateDefaultIcon: {
    width: '7rem',
    height: '7rem',
    color: '#94A8B2',
  },
  spinner: {
    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'trnaslateX(-50%)',
  },
  helpIcon: {
    width: '1rem',
    height: '1rem',
    marginLeft: '.25rem',
  },
  dangerButton: {
    color: '#ac1b3d',
    '&:hover': {
      color: '#6B1126',
    },
    width: '100%',
    marginTop: '0.1rem',
  },
});

const CandidateEditForm = ({
  candidate,
  errors,
  messages,
  onCancel,
  onChange,
  onSubmit,
  onRemovePhoto,
  parties,
  races,
  submitting,
}) => {
  const classes = useStyles();

  const getCandidatePhoto = () => (
    <Avatar
      alt={candidate.name}
      className={classes.candidatePhoto}
      src={candidate.candidatePhoto ? candidate.candidatePhoto : candidate.imageUri}
    >
      <PersonIcon className={classes.candidateDefaultIcon} />
    </Avatar>
  );

  const handleInputChange = event => {
    const {
      checked,
      files,
      name,
      value,
    } = event.target;

    if (name === 'candidatePhoto') {
      // Only one file allowed so array will only have a length os one
      onChange(name, files[0]);
      return;
    }

    if (name === 'inFavor') {
      onChange(name, checked);
      return;
    }

    onChange(name, value);
  };

  const handleRemovePhoto = () => {
    onRemovePhoto();
  };

  const handlePartyValueChane = (event) => {
    const updatedParties = [...candidate.parties];
    const changedPartyId = Number(event.target.name.split('-')[1]);

    if (event.target.checked) {
      // Find selected party in the list of available parties
      const selectedIndex = parties.map(e => e.id).indexOf(changedPartyId);

      if (selectedIndex >= 0) {
        // Add party to candidate list
        updatedParties.push(parties[selectedIndex]);
        onChange('parties', updatedParties);
      }

      return;
    }

    // Remove party from candidate list and return resulting party list
    onChange('parties', updatedParties.filter(party => party.id !== changedPartyId));
  };

  const handleSubmit = () => {
    onSubmit();
  };

  const hasError = fieldName => Object.prototype.hasOwnProperty.call(errors, fieldName);
  const getErrorText = fieldName => (Object.prototype.hasOwnProperty.call(errors, fieldName) ? errors[fieldName] : '');

  const showInFavorCheckbox = () => {
    if (candidate.race.clazz.id === 'F') {
      return (
        <Grid>
          <FormGroup className="ga-pl-12">
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!candidate.inFavor}
                  color="primary"
                  disabled={submitting}
                  onChange={handleInputChange}
                  name="inFavor"
                />
              }
              label="Is the candidate in favor of this measure?"
            />
          </FormGroup>
        </Grid>
      );
    }
  };

  const isPartySelected = (party) => {
    let result = false;

    if (candidate && candidate.parties && party) {
      result = candidate.parties.some((x) => x.id === party.id);
    }

    return result;
  };

  return (
    <Card variant="outlined" className={classes.cardContainerNoOutline}>
      <CardContent className={classes.formContainer}>
        {submitting && (
          <div className={classes.spinner}>
            <CircularProgress />
          </div>
        )}

        <Grid
          autoComplete="off"
          className="ga-px-8 ga-pt-16 ga-pb-4"
          component="form"
          container
          noValidate
          spacing={2}
        >
          <Grid item xs={2}>
            <div>
              {getCandidatePhoto()}

              <Button
                className={classes.candidatePhotoButton}
                component="label"
                disabled={submitting}
              >
                <CreateIcon className={classes.candidatePhotoButtonIcon} />
                Change Photo

                <input
                  accept="image/jpeg"
                  disabled={submitting}
                  hidden
                  name="candidatePhoto"
                  onChange={handleInputChange}
                  type="file"
                />
              </Button>

              {(candidate.candidatePhoto || candidate.imageUri) && (
                <Button
                  variant="text"
                  className={classes.dangerButton}
                  disabled={submitting}
                  onClick={handleRemovePhoto}
                >
                  Remove Photo
                </Button>
              )}
            </div>
          </Grid>

          <Grid item xs={5}>
            <TextField
              className={classes.formElement}
              disabled={submitting}
              error={hasError('name')}
              fullWidth
              helperText={getErrorText('name')}
              inputProps={{ maxLength: 200 }}
              label={messages.fullName}
              name="name"
              onChange={handleInputChange}
              required
              value={candidate.name}
              variant="filled"
            />

            <Autocomplete
              className={classes.formElement}
              disableClearable
              disabled={submitting}
              fullWidth
              getOptionLabel={(opt) => opt.name}
              getOptionSelected={(opt, val) => opt.id == val.id}
              label={candidate.race.clazz.id === 'F' ? 'Measures' : 'Races'}
              name="race"
              onChange={(event, value) => onChange('race', value)}
              options={races.filter(r => r.clazz.id === candidate.race.clazz.id)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={candidate.race.clazz.id === 'F' ? messages.measure : messages.race}
                  variant="filled"
                  error={hasError('raceId')}
                  helperText={getErrorText('raceId')}
                  required
                />
              )}
              value={candidate.race ? candidate.race : ''}
            />

            {showInFavorCheckbox()}

            <FormControl variant="filled" fullWidth className={classes.formElement}>
              <InputLabel id="demo-simple-select-filled-label">
                {messages.state}
              </InputLabel>

              <Select
                disabled={submitting}
                error={hasError('candidateState')}
                id="select"
                labelId="select"
                name="state"
                onChange={handleInputChange}
                value={candidate.state?.name}
              >
                <MenuItem value="Created">Created</MenuItem>
                <MenuItem value="Invited">Invited</MenuItem>
                <MenuItem value="Responded">Responded</MenuItem>
                <MenuItem value="Accepted">Accepted</MenuItem>
              </Select>
            </FormControl>
            <p style={{ marginBottom: '1.5rem' }}>
              <span>
                {messages.candidateType}
                <Tooltip title="Candidates cannot be moved between races and measures">
                  <HelpIcon className={classes.helpIcon} />
                </Tooltip>
              </span><br />
              <span>{candidate.race.clazz.id === 'F' ? 'Measure' : 'Race'}</span>
            </p>
            <p>
              <span>{messages.securityCode}</span><br />
              <span>{candidate.contactUser.code}</span>
            </p>
          </Grid>

          <Grid item xs={5}>
            <TextField
              className={classes.formElement}
              disabled={submitting}
              error={hasError('lastName')}
              fullWidth
              helperText={getErrorText('lastName') || messages.lastNameHelper}
              inputProps={{ maxLength: 100 }}
              label={messages.lastName}
              name="lastName"
              onChange={handleInputChange}
              required
              value={candidate.lastName}
              variant="filled"
            />

            <FormControl fullWidth className={classes.formElement}>
              <Card variant="outlined">
                <CardContent>
                  <Box className="ga-mb-12" sx={{ fontWeight: 600, fontSize: 19 }}>
                    {messages.candidatePartyHeader}
                    {candidate.race.clazz.id === 'R' && (
                      <sup className={classes.requiredAsterisk}>*</sup>
                    )}
                  </Box>

                  <Rsc id="pScrollbar" style={{ width: '100%', height: '120px' }}>
                    <FormGroup className="ga-pl-12">
                      {parties && parties.map((party, pIdx) => (
                        <FormControlLabel
                          key={pIdx}
                          control={
                            <Checkbox
                              checked={isPartySelected(party)}
                              color="primary"
                              name={`party-${party.id}`}
                              disabled={submitting}
                              onClick={handlePartyValueChane}
                            />
                          }
                          label={party.name}
                          />
                      ))}
                    </FormGroup>
                  </Rsc>
                </CardContent>
                <Divider />
                <CardActions>
                  <Typography style={{ color: '#9e9e9e' }}>
                    {candidate.parties ? candidate.parties.length : 0}{' '}
                    {candidate.parties && (candidate.parties.length > 1 || candidate.parties.length === 0)
                      ? messages.candidatePartyFooterPlural
                      : messages.candidatePartyFooter}
                  </Typography>
                </CardActions>
                {candidate.race.clazz.id === 'R' && (<p>{getErrorText('partiesIds')}</p>)}
              </Card>
            </FormControl>

            <TextField
              className={classes.formElement}
              disabled={submitting}
              error={hasError('contactName')}
              fullWidth
              helperText={getErrorText('contactName')}
              inputProps={{ maxLength: 100 }}
              label={messages.contactsName}
              name="contactUser.name"
              onChange={(event) => onChange('contactUser.name', event.target.value)}
              value={candidate.contactUser.name}
              variant="filled"
            />

            <TextField
              className={classes.formElement}
              disabled={submitting}
              error={hasError('contactEmail')}
              fullWidth
              helperText={getErrorText('contactEmail')}
              inputProps={{ maxLength: 100 }}
              label={messages.contactsEmail}
              name="contactUser.email"
              onChange={(event) => onChange('contactUser.email', event.target.value)}
              value={candidate.contactUser.email}
              variant="filled"
            />
          </Grid>
        </Grid>
      </CardContent>

      <CardActions className={classes.addFormActions}>
        <Typography component="span" variant="body2">
          {messages.requiredFields}
        </Typography>

        <div>
          <Button
            className={classes.actionButton}
            disabled={submitting}
            onClick={onCancel}
          >
            {messages.cancel}
          </Button>

          <Button
            className={classes.actionButton}
            color="secondary"
            disabled={submitting}
            onClick={handleSubmit}
            variant="contained"
          >
            {messages.save}
          </Button>
        </div>
      </CardActions>
    </Card>
  );
};

CandidateEditForm.propTypes = {
  candidate: PropTypes.shape({
    name: PropTypes.string,
    candidatePhoto: PropTypes.string,
    lastName: PropTypes.string,
    parties: PropTypes.array,
    race: PropTypes.object,
    photo: PropTypes.string,
    state: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    }),
    contactUser: PropTypes.shape({
      email: PropTypes.string,
      name: PropTypes.string,
      code: PropTypes.string,
    }),
    imageUri: PropTypes.string,
    inFavor: PropTypes.bool,
  }),
  errors: PropTypes.object,
  messages: PropTypes.object,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  parties: PropTypes.array,
  races: PropTypes.array,
  submitting: PropTypes.bool,
  onRemovePhoto: PropTypes.func,
};

CandidateEditForm.defaultProps = {
  candidate: {
    name: '',
    lastName: '',
    status: '',
    race: {},
    candidatePhoto: null,
    parties: [],
    contactUser: {
      email: '',
      name: '',
      code: '',
    },
    inFavor: null,
  },
  errors: {},
  messages: {
    firstName: 'First Name',
    lastName: 'Last Name',
    contactsName: 'Contact\'s Name',
    contactsEmail: 'Contact\'s Email',
    race: 'Race',
    state: 'Status',
    candidatePartyHeader: 'Party',
    candidatePartyFooterPlural: 'Parties',
    candidatePartyFooter: 'Party',
    securityCode: 'Security Code',
    save: 'Save & Close',
    cancel: 'Cancel',
    requiredFields: '*Required fields needed to create a new candidate',
  },
  onCancel: () => {},
  onChange: () => {},
  onSubmit: () => {},
  parties: [],
  races: [],
  submitting: false,
  onRemovePhoto: () => {},
};

export default CandidateEditForm;
