import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Grid,
  List,
  ListItem,
  Paper,
  Typography,
} from '@material-ui/core';
import {
  ChevronLeft,
  ChevronRight,
} from '@material-ui/icons';

const styles = (theme) => ({
  arrow: {
    height: '30px',
    width: '30px',
    color: '#fff',
    backgroundColor: theme.palette.secondary.main,
    padding: '2px',
    borderRadius: '100px',
  },
  root: {
    margin: 'auto',
  },
  listWrapper: {
    height: '100%',
    overflowY: 'scroll',
    position: 'relative',
  },
  paper: {
    border: '1px solid #e5e5e5',
    height: '400px',
    padding: '8px 0 0 5px',
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  selectedCountWrapper: {
    background: '#fff',
    width: '100%',
    position: 'sticky',
    bottom: '0',
  },
  selectedCount: {
    color: '#969696',
    display: 'flex',
    alignItems: 'center',
    height: '54px',
    paddingLeft: '1.1rem',
  },
});

function sortByLabel(items) {
  return items.slice(0).sort((a, b) => {
    if (a.label > b.label) {
      return 1;
    }
    if (a.label < b.label) {
      return -1;
    }
    return 0;
  });
}

function not(a, b) {
  return sortByLabel(a.filter(o => !b.some(v => v.id === o.id)));
}

function intersection(a, b) {
  return sortByLabel(a.filter(o => b.some(v => v.id === o.id)));
}

function arraysAreSame(a, b) {
  return Object.keys(a).length === Object.keys(b).length
        && Object.keys(a).every(p => a[p] === b[p]);
}

class TransferList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: [],
      left: [],
      right: [],
      leftChecked: [],
      rightChecked: [],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { leftItems, rightItems } = this.props;
    const { right } = this.state;

    if (!arraysAreSame(leftItems, prevProps.leftItems)) {
      this.setState({
        left: not(leftItems, rightItems),
      });
    }

    if (!arraysAreSame(rightItems, prevProps.rightItems)) {
      this.setState({
        right: rightItems,
      });
    }

    if (!arraysAreSame(right, prevState.right)) {
      this.emitChangeEvent();
    }
  }

  handleToggle(checkedItem) {
    const { checked, left, right } = this.state;
    const currentIndex = checked.findIndex(item => item.value === checkedItem.value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(checkedItem);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked,
      leftChecked: intersection(newChecked, left),
      rightChecked: intersection(newChecked, right),
    });
  }

  emitChangeEvent() {
    const { right } = this.state;
    const { onChange } = this.props;
    const values = right.map(item => item.value);

    onChange(values);
  }

  handleCheckedRight() {
    const {
      checked, left, leftChecked, right,
    } = this.state;

    this.setState({
      left: sortByLabel(not(left, leftChecked)),
      right: sortByLabel(not(right, leftChecked).concat(leftChecked)),
      leftChecked: [],
      checked: not(checked, leftChecked),
    });
  }

  handleCheckedLeft() {
    const {
      checked, left, right, rightChecked,
    } = this.state;

    this.setState({
      left: sortByLabel(not(left, rightChecked).concat(rightChecked)),
      right: sortByLabel(not(right, rightChecked)),
      rightChecked: [],
      checked: not(checked, rightChecked),
    });
  }

  render() {
    const {
      classes,
      disabled,
      leftHeader,
      leftCountHeader,
      loading,
      rightHeader,
      rightCountHeader,
      showCount,
    } = this.props;
    const {
      checked,
      left,
      leftChecked,
      right,
      rightChecked,
    } = this.state;

    const selectedCount = (checkedItems, heading) => {
      if (showCount && !loading) {
        const count = checkedItems.length > 0 ? checkedItems.length : 0;

        return (
          <div className={classes.selectedCountWrapper}>
            <div className={classes.selectedCount}>
              {count} {heading}
            </div>
          </div>
        );
      }
    };

    const customList = (items, checkedItems, heading, countHeading) => {
      let listBody;

      if (loading) {
        listBody = (
          <div className='spinner'>
            <CircularProgress />
          </div>
        );
      } else {
        listBody = (
          <List dense component="div" role="list">
            {items.map((item) => {
              const labelId = `transfer-list-item-${item.id}-label`;

              return (
                <ListItem key={item.id} role="listitem" button>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked.filter(e => e.id === item.id).length > 0}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                        onChange={() => this.handleToggle(item)}
                      />
                    }
                    disabled={disabled}
                    label={item.label}
                  />
                </ListItem>
              );
            })}
            <ListItem />
          </List>
        );
      }

      return (

        <Paper className={classes.paper} variant="outlined">
          <div className={classes.listWrapper}>
            <Typography component="h2" variant="h3">
              {heading}
            </Typography>

            {listBody}
          </div>

          {selectedCount(checkedItems, countHeading)}
        </Paper>
      );
    };

    return (
      <Grid container spacing={2} alignItems="center" className={classes.root}>
        <Grid item xs={5}>
          {customList(left, leftChecked, leftHeader, leftCountHeader)}
        </Grid>

        <Grid item xs={1}>
          <Grid container direction="column" alignItems="center">
            <IconButton
              aria-label="move selected right"
              onClick={() => this.handleCheckedRight()}
              disabled={leftChecked.length === 0}
            >
              <ChevronRight className={classes.arrow} />
            </IconButton>

            <IconButton
              aria-label="move selected left"
              onClick={() => this.handleCheckedLeft()}
              disabled={rightChecked.length === 0}
            >
              <ChevronLeft className={classes.arrow} />
            </IconButton>
          </Grid>
        </Grid>

        <Grid item xs={5}>
          {customList(right, rightChecked, rightHeader, rightCountHeader)}
        </Grid>
      </Grid>
    );
  }
}

TransferList.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  leftCountHeader: PropTypes.string,
  leftHeader: PropTypes.string,
  leftItems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  rightCountHeader: PropTypes.string,
  rightHeader: PropTypes.string,
  rightItems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  showCount: PropTypes.bool,
};

TransferList.defaultProps = {
  disabled: false,
  leftHeader: 'Available',
  leftCountHeader: 'Selected',
  loading: false,
  onChange: () => {},
  rightHeader: 'Selected',
  rightCountHeader: 'Selected',
  leftItems: [],
  rightItems: [],
  showCount: false,
};

export default withStyles(styles)(TransferList);
