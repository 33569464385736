import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  TextField,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  formField: {
    marginBottom: '1.25rem',
  },
  questionText: {
    fontSize: '.9rem',
    fontWeight: 'bold',
    marginBottom: '0.625rem',
  },
}));

const QuestionYouTubeField = ({
  answerText,
  answerURL,
  errors,
  id,
  onChange,
  question,
  submitting,
}) => {
  const classes = useStyles();

  const hasError = (fieldID) => Object.prototype.hasOwnProperty.call(errors, fieldID);

  const handleChange = (event) => {
    onChange(id, event.target.name.startsWith('url') ? 'url' : 'desc', event.target.value);
  };

  const getCharCount = (enteredAnswer, max) => {
    if (enteredAnswer) {
      return `${enteredAnswer.length}/${max}`;
    }

    return `0/${max}`;
  };

  return (
    <div className={classes.container}>
      <Typography variant="body1" component="p" className={classes.questionText}>
        {question}
      </Typography>

      <TextField
        className={classes.formField}
        disabled={submitting}
        error={hasError(`url_${id}`)}
        fullWidth
        helperText={hasError(`url_${id}`) ? errors[`url_${id}`] : getCharCount(answerURL, 100)}
        inputProps={{ maxLength: 100 }}
        label='YouTube URL'
        name={`url_${id}`}
        onChange={handleChange}
        value={answerURL}
        variant="filled"
      />

      <TextField
        className={classes.formField}
        disabled={submitting}
        error={hasError(`text_${id}`)}
        fullWidth
        helperText={hasError(`text_${id}`) ? errors[`url_${id}`] : getCharCount(answerText, 900)}
        inputProps={{ maxLength: 900 }}
        label='Description'
        multiline
        name={`text_${id}`}
        onChange={handleChange}
        value={answerText}
        variant="filled"
        rows={4}
      />
    </div>
  );
};

QuestionYouTubeField.propTypes = {
  answerURL: PropTypes.string,
  answerText: PropTypes.string,
  errors: PropTypes.object,
  id: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  question: PropTypes.string,
  submitting: PropTypes.bool,
};

QuestionYouTubeField.defaultProps = {
  answerURL: '',
  answerText: '',
  errors: {},
  onChange: () => {},
  question: '',
  submitting: false,
};

export default QuestionYouTubeField;
