import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Interweave from 'interweave';
import HelpfulHint from 'components/HelpfulHint';
import DistrictSelectorContainer from 'components/Districts/Partials/DistrictSelectorContainer';
// import stateErrors from 'helpers/StateErrors';

// Example GIS
// {"id":72,"type":{"key":"GIS","id":"GIS","discriminator":"G","name":"GIS District","plural":"GIS Districts"},"name":"King County Council District 6","geosetId":1,"value":72}
// Example County
// {"id":88,"type":{"key":"C","id":"C","discriminator":"C","name":"County","plural":"Counties"},"state":{"id":1,"type":{"key":"SW","id":"SW","discriminator":"S","name":"State-Wide","plural":"States"},"name":"Alabama","code":"AL"},"name":"Baldwin","value":88}
// Example DSM
// {"id":1000553,"type":{"key":"DSM","id":"DSM","discriminator":"M","name":"District Set Member","plural":"District Set Members"},"name":"Memba","dset":{"id":1000550,"version":1,"guideId":null,"index":null,"votingDistrict":{"id":3,"type":{"key":"SW","id":"SW","discriminator":"S","name":"State-Wide","plural":"States"},"name":"Arizona","code":"AZ"},"districtSubset":null,"name":"Devins Best District Set Ever","description":null,"childDistricts":null,"type":"SET"},"value":1000553}

const useStyles = makeStyles({
  container: {
    borderBottom: '1px solid rgba(0,0,0,0.1)',
  },
});

const DistrictSelect = ({
  guideId,
  heading,
  infoText,
  onChange,
  subheading,
  district,
  errors,
}) => {
  const classes = useStyles();

  return (
    <Box paddingBottom={5} marginBottom={5} className={classes.container}>
      <Grid container direction="row" justifyContent="space-between" spacing={2}>
        <Grid item xs={9}>
          <Typography variant="h2" component="h3" paragraph>
            {heading}
          </Typography>

          <Typography variant="body1" component="p" paragraph>
            {subheading}
          </Typography>

          <DistrictSelectorContainer
            district={district}
            guideId={guideId}
            onChange={onChange}
            error={errors.district ? errors.district[0] : ''}
          />
        </Grid>

        <Grid item xs={3}>
          <HelpfulHint>
            <Interweave content={infoText} />
          </HelpfulHint>
        </Grid>
      </Grid>
    </Box>
  );
};

DistrictSelect.propTypes = {
  district: PropTypes.object,
  guideId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  heading: PropTypes.string,
  infoText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onChange: PropTypes.func,
  subheading: PropTypes.string.isRequired,
  errors: PropTypes.object,
};

DistrictSelect.defaultProps = {
  district: null,
  heading: 'Districts',
  infoText: '',
  onChange: () => {},
  subheading: '',
  errors: {},
};

export default DistrictSelect;
