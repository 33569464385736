// couldn't fix this one because of some weird createStyles call in ArchivedCandidateDashboardContainer.js
import ArchivedCandidateDashboardContainer from './ArchivedCandidateDashboardContainer';
import { CandidateDashboardContainer } from './CandidateDashboardContainer';
import { CandidateDetailsProvider } from './CandidateDetails';
import { CandidateEventLogContainer } from './CandidateEventLogContainer';

export {
  ArchivedCandidateDashboardContainer,
  CandidateDashboardContainer,
  CandidateDetailsProvider,
  CandidateEventLogContainer,
};
