import { useEffect, useState } from 'react';

/**
 * Stores and retrieves Material UI DataGrid filter/store/column visibility models to/from session storage
 *
 * @param {Object} options - The options for the hook
 * @param {string} options.key - Key to use for storing table state in session
 * @param {string[]} [options.initialHiddenColumns=[]] - Initial hidden columns
 * @returns {Array} An array containing the following elements:
 *   - {Object} filterModel - The current filter model
 *   - {Function} setFilterModel - Function to update the filter model
 *   - {Array} sortModel - The current sort model
 *   - {Function} setSortModel - Function to update the sort model
 *   - {Array} hiddenColumns - The current hidden columns
 *   - {Function} setHiddenColumns - Function to update the hidden columns
 * @example
 * import useSavedTableSettings from 'hooks/useSavedTableSettings';
 * const [filterModel, setFilterModel, sortModel, setSortModel, hiddenColumns, setHiddenColumns] = useSavedTableSettings({ key: 'your-table-identifier' });
 *
 * //* In the component where you'll be passing state to the DataGrid component:
 * //* Pass the `filterModel`, `sortModel`, and `hiddenColumns` state properties to your DataGrid component.
 * //* Use the `setFilterModel`, `setSortModel`, and `setHiddenColumns` state properties to update the filter/sort/column visibility models and store the new state to the users session.
 */
const useSavedTableSettings = ({
  key, // Key to use for storing table state in session
  initialHiddenColumns = [], // Initial hidden columns
}) => {
  const [filterModel, setInternalFilterModel] = useState({ items: [], linkOperator: 'and' });
  const [sortModel, setInternalSortModel] = useState([]);
  const [hiddenColumns, setInternalHiddenColumns] = useState(initialHiddenColumns);

  useEffect(() => {
    fetchSettings();
  }, []);

  useEffect(() => {
    const parsedSettings = getParsedSettings();
    const newSettings = {
      filter: filterModel,
      sort: sortModel,
      hiddenColumns,
    };
    let settings = {};

    if (parsedSettings) {
      settings = { ...parsedSettings, [key]: newSettings };
    } else {
      settings = { [key]: newSettings };
    }

    window.sessionStorage.setItem('table-settings', JSON.stringify(settings));
  }, [filterModel, sortModel, hiddenColumns]);

  const getParsedSettings = () => {
    try {
      const settings = window.sessionStorage.getItem('table-settings');
      return JSON.parse(settings);
    } catch (e) {
      return null;
    }
  };

  const fetchSettings = () => {
    const parsedSettings = getParsedSettings();

    if (parsedSettings) {
      if (Object.prototype.hasOwnProperty.call(parsedSettings, key)) {
        if (Object.prototype.hasOwnProperty.call(parsedSettings[key], 'filter')) {
          setFilterModel(parsedSettings[key].filter);
        }

        if (Object.prototype.hasOwnProperty.call(parsedSettings[key], 'sort')) {
          setSortModel(parsedSettings[key].sort);
        }

        if (Object.prototype.hasOwnProperty.call(parsedSettings[key], 'hiddenColumns')) {
          setInternalHiddenColumns(parsedSettings[key].hiddenColumns);
        }
      }
    }
  };

  /* Conditional to prevent an infinite loop by confirming model is
    different than the current filterModel state */
  const setFilterModel = (model) => {
    if (JSON.stringify(model) !== JSON.stringify(filterModel)) {
      setInternalFilterModel(model);
    }
  };

  /* Conditional to prevent an infinite loop by confirming model is
    different than the current sortModel state */
  const setSortModel = (model) => {
    if (JSON.stringify(model) !== JSON.stringify(sortModel)) {
      setInternalSortModel(model);
    }
  };

  /**
   * Updates the hidden columns state based on the visibility of a column.
   *
   * @param {Object} column - The column object.
   * @param {boolean} column.isVisible - Indicates if the column is visible.
   * @param {string} column.field - The field name of the column.
   *
   * Note: This function does not handle the "show all" and "hide all" buttons.
   * It's a bug in MUI DataGrid v4 which was fixed in v5:
   * https://github.com/mui/mui-x/issues/2198
   */
  const setHiddenColumns = (column) => {
    if (column?.isVisible === false && !hiddenColumns.includes(column.field)) {
      // Add the column to hidden columns if it's not visible and not already included
      setInternalHiddenColumns([...hiddenColumns, column.field].sort());
    }
    if (column?.isVisible && hiddenColumns.includes(column.field)) {
      // Remove the column from hidden columns if it's visible and currently included
      const newHiddenColumns = hiddenColumns.filter((item) => item !== column.field);
      setInternalHiddenColumns(newHiddenColumns.sort());
    }
  };

  return [
    filterModel,
    setFilterModel,
    sortModel,
    setSortModel,
    hiddenColumns,
    setHiddenColumns,
  ];
};

export default useSavedTableSettings;
