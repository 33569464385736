import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  checkIcon: {
    color: '#3fa06f',
    fontSize: '10rem',
  },
  subtitle: {
    [theme.breakpoints.up('md')]: {
      width: '75%',
    },
  },
}));

const CandidateThankYou = ({ messages }) => {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h1" component="h1" color="textSecondary" paragraph>
        {messages.stepHeader}
      </Typography>
      <Typography variant="body1" component="p" paragraph className={classes.subtitle}>
        {messages.stepSubheader}
      </Typography>

      <Box marginBottom={5} paddingBottom={5}>
        <CheckCircleOutlineIcon
          className={classes.checkIcon}
        />
      </Box>
    </div>
  );
};

CandidateThankYou.propTypes = {
  messages: PropTypes.object.isRequired,
};

CandidateThankYou.defaultProps = {};

export default CandidateThankYou;
