import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@tanstack/react-query';
import { Box, Button } from '@material-ui/core';

import truncate from 'helpers/truncate';
import { convertStringToTitleCase } from 'helpers/ConvertStringToTitleCase';
import { shortMonthStandardDateFormat } from '@/helpers/Date';

import { MessagesContext } from 'components/messages';
import { AppSnackbarContext } from 'components/AppSnackbar';
import { ServerPaginatedDataGrid, useStyles } from 'components/DataGrid';

import { useDataGridParams } from 'hooks/useDataGridParams';
import useReactiveFormValue from 'hooks/useReactiveFormValue';

import {
  CandidatePropTypes,
  postCandidateFinanceMatch,
  useCampaignFinanceRecords,
  SearchFilter,
} from '@/features/campaign-finance';

export const CandidateFinanceTableContainer = memo(({
  candidate,
  isTest,
}) => {
  // Context
  const { messages } = useContext(MessagesContext);
  const { show: showSnackbar } = useContext(AppSnackbarContext);

  // State
  const [submitting, setSubmitting] = useState(false);
  const [searchedInput, setSearchedInput] = useState(candidate.name);

  // Styles
  const classes = useStyles();

  // Manage Search Input
  const {
    value: searchInput,
    setValue: setSearchInput,
  } = useReactiveFormValue(candidate.name);

  // Manage Data Grid Settings
  const {
    onPageChange,
    onPageSizeChange,
    page,
    pageSize,
    setSortModel,
    sortModel,
    hiddenColumns,
    setHiddenColumns,
  } = useDataGridParams({ tableSettingsKey: 'candidate_finance_unmatched' });

  // Fetch campaign finance records
  const {
    data,
    isError,
    isLoading,
    isFetching,
  } = useCampaignFinanceRecords({
    dataGridParams: {
      page,
      pageSize,
      search: searchedInput,
      sortModel,
    },
    queryKeyString: `candidate_finance_unmatched_${searchedInput}`,
    type: null,
  });

  // API mutation to match campaign finance records to candidates
  const mutation = useMutation({
    mutationFn: (cfRecordId) => postCandidateFinanceMatch(candidate.id, cfRecordId),
    onSuccess: () => {
      const redirectUrl = document.referrer ? document.referrer : `/races/${candidate.race.id}/candidates/${candidate.id}`;
      window.location.href = redirectUrl;
    },
    onError: (matchingError) => {
      console.error('Match error:', matchingError);
      showSnackbar(messages.matchError, 'error');
    },
    retry: 2,
  });

  // On page load, we set the search input to the candidate name by default
  useEffect(() => {
    setSearchInput(candidate.name);
  }, [candidate]);

  // Enable/Disable search input while loading
  useEffect(() => {
    if (isLoading || isFetching || mutation.isLoading || mutation.isSuccess) {
      setSubmitting(true);
    }

    if (!isLoading && !isFetching && !mutation.isLoading && !mutation.isSuccess) {
      setSubmitting(false);
    }
  }, [isLoading, isFetching, mutation.isLoading, mutation.isSuccess]);

  const handleSearchSubmit = useCallback(() => {
    setSearchedInput(searchInput);
  }, [searchInput]);

  const handleMatch = (cfRecordId) => {
    setSubmitting(true);
    mutation.mutate(cfRecordId);
  };

  const tableColumns = useMemo(() => [
    {
      field: 'candidateName',
      headerName: messages.table?.column?.name,
      flex: 1.2,
      filterable: false,
      hide: hiddenColumns.includes('candidateName'),
      renderCell: (params) => {
        const truncated = truncate(params.row.candidateName, 90);
        return (
          <Box sx={{ fontWeight: '600' }}>
            {truncated != '' ? truncated : 'NO NAME'}
          </Box>
        );
      },
    },
    {
      field: 'party',
      headerName: messages.table?.column?.party,
      flex: 0.6,
      filterable: false,
      hide: hiddenColumns.includes('party'),
    },
    {
      field: 'raceName',
      headerName: messages.table?.column?.race,
      flex: 1,
      filterable: false,
      valueGetter: (params) => truncate(params.row.raceName, 70),
      hide: hiddenColumns.includes('raceName'),
    },
    {
      field: 'updated',
      headerName: messages.table?.column?.import,
      flex: 1,
      filterable: false,
      valueGetter: (params) => {
        if (!params.row.updated || Number.isNaN(Date.parse(params.row.updated))) {
          return '';
        }
        const d = new Date(params.row.updated);
        return shortMonthStandardDateFormat.format(d);
      },
      hide: hiddenColumns.includes('updated'),
    },
    {
      field: 'dataSource',
      headerName: messages.table?.column?.source,
      flex: 0.8,
      filterable: false,
      valueGetter: (params) => convertStringToTitleCase(params.row.dataSource),
      hide: hiddenColumns.includes('dataSource'),
    },
    {
      field: 'yearRange',
      headerName: messages.table?.column?.sourceYear,
      flex: 0.78,
      filterable: false,
      hide: hiddenColumns.includes('yearRange'),
    },
    {
      field: 'actions',
      headerName: messages.table?.column?.actions,
      resizable: false,
      sortable: false,
      filterable: false,
      width: 120,
      hide: hiddenColumns.includes('actions'),
      renderCell: (params) => (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            className={classes.actionButton}
            size="small"
            onClick={() => handleMatch(params.row.id)}
          >
            {messages.table.action.match}
          </Button>
        </Box>
      ),
    },
  ], [messages, classes, handleMatch]);

  return (
    <>
      <SearchFilter
        searchInput={searchInput}
        onChange={setSearchInput}
        onSubmit={handleSearchSubmit}
        disabled={submitting}
        heading={messages.heading}
        label={messages.search?.label}
        placeholder={messages.search?.placeholder}
        buttonText={messages.search?.submit}
      />

      <ServerPaginatedDataGrid
        columns={tableColumns}
        emptyStateText={messages.table.empty}
        error={messages.error}
        isError={isError}
        isFetching={isFetching || submitting}
        isLoading={isLoading}
        isTest={isTest}
        onColumnVisibilityChange={setHiddenColumns}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onSortModelChange={setSortModel}
        page={page}
        pageSize={pageSize}
        rowCount={data?.totalRows || 0}
        rows={data?.rows || []}
        sortModel={sortModel}
      />
    </>
  );
});

CandidateFinanceTableContainer.propTypes = {
  candidate: CandidatePropTypes,
  isTest: PropTypes.bool,
};

CandidateFinanceTableContainer.defaultProps = {
  isTest: false,
};
