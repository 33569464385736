import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import PanToolIcon from '@material-ui/icons/PanTool';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PinDropIcon from '@material-ui/icons/PinDrop';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';

// HEY DEV! THIS IS A GREAT CANDIDATE FOR REFACTOR INTO A
// MORE GENERIC "SIDE NAVIGATION" COMPONENT. MAYBE TODAY
// IS YOUR TIME TO SHINE!

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function RaceDashboardNavigation(props) {
  const { links } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <List component="nav" aria-label="main mailbox folders">
        <ListItem button component="a" href={links.parties}>
          <ListItemIcon>
            <PanToolIcon />
          </ListItemIcon>
          <ListItemText primary="Parties" />
        </ListItem>

        <Divider />

        <ListItem button component="a" href={links.categories}>
          <ListItemIcon>
            <AccountBalanceIcon />
          </ListItemIcon>
          <ListItemText primary="Categories" />
        </ListItem>

        <Divider />

        <ListItem button component="a" href={links.districts}>
          <ListItemIcon>
            <PinDropIcon />
          </ListItemIcon>
          <ListItemText primary="Districts" />
        </ListItem>

        <Divider />

        <ListItem button component="a" href={links.bioQuestions}>
          <ListItemIcon>
            <FingerprintIcon />
          </ListItemIcon>
          <ListItemText primary="Biographical Questions" />
        </ListItem>

        <Divider />

        <ListItem button component="a" href={links.raceQuestions}>
          <ListItemIcon>
            <QuestionAnswerIcon />
          </ListItemIcon>
          <ListItemText primary="Race Questions" />
        </ListItem>

        <Divider />
      </List>
    </div>
  );
}

RaceDashboardNavigation.propTypes = {
  links: PropTypes.shape({
    parties: PropTypes.string.isRequired,
    categories: PropTypes.string.isRequired,
    districts: PropTypes.string.isRequired,
    bioQuestions: PropTypes.string.isRequired,
    raceQuestions: PropTypes.string.isRequired,
  }).isRequired,
};

RaceDashboardNavigation.defaultProps = {
  links: {},
};
