import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core';
import BioQuestionInput from 'components/BioQuestionInput';
import MailAddressForm from 'components/BioQuestions/MailAddressForm';
import BioQuestionClass from 'helpers/BioQuestionClass';

const CandidateBiographicalInfo = ({
  messages,
  bioQuestionSet,
  onUpdate,
  submitting,
}) => {
  const handleFieldChange = (question, newValue, fieldName = '') => {
    if (question.type === BioQuestionClass.ADDRESS_TYPE) {
      onUpdate(question.id, newValue, fieldName);
    } else {
      onUpdate(question.id, newValue);
    }
  };

  const displayQuestion = (bioQuestion) => {
    if (bioQuestion.type === BioQuestionClass.ADDRESS_TYPE) {
      return (
        <MailAddressForm
          fields={bioQuestion.mailingAnswer}
          isFullWidth={true}
          key={bioQuestion.id}
          onChange={(event) => handleFieldChange(bioQuestion, event.target.value, event.target.name)}
          submitting={submitting}
        />
      );
    }

    return (
      <BioQuestionInput
        errors={bioQuestion.errors}
        key={bioQuestion.id}
        htmlId={bioQuestion.id}
        maxLengthValue={bioQuestion.maxl || 750}
        onChange={(newValue) => handleFieldChange(bioQuestion, newValue)}
        submitting={submitting}
        title={bioQuestion.question}
        type={bioQuestion.type}
        value={bioQuestion.answer}
        helpText={bioQuestion.type === BioQuestionClass.PHONE_NUMBER ? 'Acceptable formats: 1234567890, 123-456-7890, (123)456-7890' : ''}
      />
    );
  };

  return (
    <Card>
      <CardContent>
        <Grid
          className="ga-m-bottom--large"
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Typography
            color="primary"
            component="span"
            style={{ fontWeight: 600 }}
            variant="h6"
          >
            {messages.title}
          </Typography>

          <Typography
            color="textSecondary"
            component="span"
            variant="body2"
            paragraph
          >
            {messages.subTitle}
          </Typography>

          <Typography
            color="textSecondary"
            component="span"
            variant="body2"
          >
            {messages.httpInfo}
          </Typography>
        </Grid>

        <div className="ga-w-100-p">
          {bioQuestionSet && bioQuestionSet.map((bioQuestion) => displayQuestion(bioQuestion))}
        </div>
      </CardContent>
    </Card>
  );
};

CandidateBiographicalInfo.propTypes = {
  messages: PropTypes.object.isRequired,
  bioQuestionSet: PropTypes.array,
  onUpdate: PropTypes.func,
  submitting: PropTypes.bool,
};

CandidateBiographicalInfo.defaultProps = {
  bioQuestionSet: [],
  onUpdate: () => {},
  submitting: false,
};

export default CandidateBiographicalInfo;
