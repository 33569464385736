import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clone from 'rfdc/default';
import SnackbarAlert from 'components/SnackbarAlert';
import BioQuestionSetList from 'components/BioQuestions/BioQuestionSetList';
import BioQuestionSetCreateModal from 'components/BioQuestions/BioQuestionSetCreateModal';
import BioQuestionSetEditModal from 'components/BioQuestions/BioQuestionSetEditModal';
import fetchUtil from 'helpers/Fetch';

function BioQuestionSetListContainer({
  guideId,
  messages,
  postUrl,
  questions,
  sets,
}) {
  const [createErrors, setCreateErrors] = useState({});
  const [createSubmitting, setCreateSubmitting] = useState(false);
  const [deleteSubmitting, setDeleteSubmitting] = useState(false);
  const [editErrors, setEditErrors] = useState({});
  const [editSetId, setEditSetId] = useState(null);
  const [editSetName, setEditSetName] = useState('');
  const [editSubmitting, setEditSubmitting] = useState(false);
  const [localSets, setLocalSets] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const _snackbar = useRef();

  useEffect(() => {
    setLocalSets(sets);
  }, [sets]);

  const showError = (message) => {
    if (message) {
      _snackbar.current.show('error', message);
    }
  };

  const showSuccess = (message) => {
    if (message) {
      _snackbar.current.show('success', message);
    }
  };

  const handleOpenEditBioSetModal = (id, name) => {
    setShowEditModal(true);
    setEditSetId(id);
    setEditSetName(name);
  };

  const handleCloseEditBioSetModal = () => {
    setShowEditModal(false);
    setEditSetId(null);
    setEditSetName('');
  };

  const handleEditSetSubmit = () => {
    const patchUrl = `${postUrl}/${editSetId}`;
    const data = { id: editSetId, name: editSetName };

    setEditErrors({});
    setEditSubmitting(true);

    fetchUtil(patchUrl, 'PATCH', data)
      .then(res => {
        const newSets = localSets.slice(0);

        newSets.forEach(set => {
          if (set.id === editSetId) {
            set.name = res.name;
          }
        });

        setEditSetId(null);
        setEditSetName('');
        setEditSubmitting(false);
        setLocalSets(newSets);
        setShowEditModal(false);
        showSuccess(messages.bioQuestions.listContainer.updateSuccess);
      })
      .catch(error => {
        setEditErrors(error);
        setEditSubmitting(false);
        showError(messages.bioQuestions.listContainer.errorMsg);
      });
  };

  const handleCreateSetSubmit = (data) => {
    setCreateErrors({});
    setCreateSubmitting(true);

    fetchUtil(postUrl, 'POST', data)
      .then(res => {
        const newSets = localSets.slice(0);
        newSets.push(res);

        setCreateSubmitting(false);
        setLocalSets(newSets);
        setShowCreateModal(false);
        showSuccess(messages.bioQuestions.listContainer.createSuccess);
      })
      .catch(error => {
        setCreateErrors(error);
        setCreateSubmitting(false);
        showError(messages.bioQuestions.listContainer.errorMsg);
      });
  };

  const handleDelete = (id) => {
    const deleteUrl = `${postUrl}/${id}`;

    setDeleteSubmitting(true);

    fetchUtil(deleteUrl, 'DELETE', id)
      .then(() => {
        const newSets = localSets.filter(set => set.id !== id);

        setDeleteSubmitting(false);
        setLocalSets(newSets);
        showSuccess(messages.bioQuestions.listContainer.deleteSuccess);
      })
      .catch(error => {
        const { status } = error;
        let errorMessage = messages.bioQuestions.listContainer.errorMsg;

        if (status == 401) {
          errorMessage = messages.bioQuestions.listContainer.deleteErrorMsg401;
        } else if (status == 403) {
          errorMessage = messages.forbidden;
        }

        setDeleteSubmitting(false);
        showError(errorMessage);
      });
  };

  const handleAddQuestionToSet = (bioSet, bioQuestion) => {
    const newBioSets = clone(localSets);
    const bioSetIndex = newBioSets.findIndex(s => s.id == bioSet.id);
    const url = `${postUrl}/${bioSet.id}/question`;
    const body = { id: bioQuestion.id, index: bioQuestion.index };

    if (bioSetIndex < 0) {
      // Could not find a matching bioset
      console.error(`Could not find a bioset with id ${bioSet.id}`);
      showError('There was an issue adding the question to this bioset. Please try again and notify a guide admin if this problem continues.');
      return;
    }

    fetchUtil(url, 'POST', body)
      .then(() => {
        if (!newBioSets[bioSetIndex].bioQuestions) {
          newBioSets[newBioSets].bioQuestions = [];
        }

        newBioSets[bioSetIndex].bioQuestions.push(bioQuestion);
        setLocalSets(newBioSets);

        showSuccess(messages.bioQuestions.listContainer.addBioQuestionToSetSuccess);
      }).catch(error => {
        console.error(error);
        showError(messages.bioQuestions.listContainer.addBioQuestionToSetError);
      });
  };

  const handleRemoveQuestionFromSet = (bioSet, questionId) => {
    const newBioSets = clone(localSets);
    const bioSetIndex = newBioSets.findIndex(s => s.id == bioSet.id);
    const url = `${postUrl}/${bioSet.id}/question/${questionId}`;

    if (bioSetIndex < 0) {
      // Could not find a matching bioset
      console.error(`Could not find a bioset with id ${bioSet.id}`);
      showError('There was an issue remove the question from this bioset. Please try again and notify a guide admin if this problem continues.');
      return;
    }

    fetchUtil(url, 'DELETE', {})
      .then(() => {
        newBioSets[bioSetIndex].bioQuestions = newBioSets[bioSetIndex].bioQuestions.filter((obj) => obj.id !== questionId);
        setLocalSets(newBioSets);

        showSuccess(messages.bioQuestions.listContainer.removeBioQuestionFromSetSuccess);
      }).catch(error => {
        console.error(error);
        showError(messages.bioQuestions.listContainer.addBioQuestionToSetError);
      });
  };

  return (
    <div>
      <SnackbarAlert ref={_snackbar} />

      <BioQuestionSetCreateModal
        cancelButtonText='Cancel'
        defaultQuestionsHeader='Default biographical questions'
        errors={createErrors}
        heading='Create Biographical Set'
        onClose={() => setShowCreateModal(false)}
        onSubmit={handleCreateSetSubmit}
        open={showCreateModal}
        questions={questions}
        questionTableHeader="Available biographical questions"
        requiredText='*Required fields needed to create a new question set'
        subheading='By default some biographical questions are always included in every set. Use the table below to add more.'
        submitButtonText='Add Set'
        submitting={createSubmitting}
      />

      <BioQuestionSetEditModal
        cancelButtonText="Cancel"
        errors={editErrors}
        heading="Edit Biographical Set"
        id={editSetId}
        name={editSetName}
        onClose={handleCloseEditBioSetModal}
        onChange={(event) => setEditSetName(event.target.value)}
        onSubmit={handleEditSetSubmit}
        open={showEditModal}
        requiredText="*Required fields for a biographical set"
        subheading="Looking to rearrange the order of biographical fields? You can rearrange them within the set itself."
        submitButtonText="Update"
        submitting={editSubmitting}
      />

      <BioQuestionSetList
        bioSets={localSets}
        createErrors={createErrors}
        createSubmitting={createSubmitting}
        deleteSubmitting={deleteSubmitting}
        editErrors={editErrors}
        editSubmitting={editSubmitting}
        guideId={guideId}
        messages={messages}
        questions={questions}
        onAddQuestionToSet={handleAddQuestionToSet}
        onRemoveQuestion={handleRemoveQuestionFromSet}
        onCreate={() => setShowCreateModal(true)}
        onDelete={handleDelete}
        onError={(msg) => showError(msg)}
        onUpdate={handleOpenEditBioSetModal}
        postUrl={postUrl}
      />
    </div>
  );
}

BioQuestionSetListContainer.propTypes = {
  guideId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  messages: PropTypes.object,
  postUrl: PropTypes.string.isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape({
    default: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    maxl: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    type: PropTypes.string,
  })),
  sets: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    index: PropTypes.number,
    name: PropTypes.string,
    questions: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      title: PropTypes.string,
      index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      isDefault: PropTypes.bool,
      handleRemoveFromSet: PropTypes.func,
    })),
  })),
};

BioQuestionSetListContainer.defaultProps = {
  messages: { bioSets: { title: '', titleSupporting: '' } },
  postUrl: '',
  questions: [],
  sets: [],
};

export default BioQuestionSetListContainer;
