import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Link,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DragHandle } from '@material-ui/icons';

import ActionsDropdown from 'components/ActionsDropdown';

import { useReorderChild } from 'hooks/useReorder';

const useStyles = makeStyles((theme) => ({
  actionButton: {
    minWidth: '75px',
  },
  disabled: {
    cursor: 'not-allowed',
  },
  rowContainer: {
    padding: '16px',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
  iconButton: {
    cursor: 'grab',
  },
  categoryLink: {
    color: theme.palette.primary,
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '16px',
    textDecoration: 'none',
  },
  categoryLinkDisabled: {
    color: 'rgb(0,0,0,.26)',
    cursor: 'not-allowed',
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

const RaceCategoryRow = ({
  canReorder,
  disabled,
  handlePreview,
  index,
  moveRace,
  onFailedDrop,
  onReorder,
  race,
}) => {
  const classes = useStyles();

  // ABSTRACTION!
  const {
    handlerId, ref, opacity,
  } = useReorderChild({
    index,
    moveFunction: moveRace,
    onReorder,
    id: race.id,
    disabled: canReorder ? disabled : true,
    onFailedDrop,
  });

  return (
    <div
      data-handler-id={handlerId}
      style={{ width: '100%' }}
      ref={ref}
    >
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
        className={`${classes.rowContainer} ${disabled ? classes.disabled : ''}`}
        style={{ opacity }}
      >
        <Grid item xs={10}>
          <Link
            className={disabled ? `${classes.categoryLink} ${classes.categoryLinkDisabled}` : classes.categoryLink}
            href={`/${race.clazz.id == 'F' ? 'measures' : 'races'}/${race.id}`}
            onClick={(e) => (disabled ? e.preventDefault() : '')}
          >
            {race.name}
          </Link>
        </Grid>

        <Grid item xs={2}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <ActionsDropdown
              disabled={disabled}
              options={[
                { label: 'Edit Race', onClick: () => window.location.replace(`/races/${race.id}`) },
                { label: 'Preview Race', onClick: () => handlePreview(race) },
              ]}
            />
            {canReorder && (
              <DragHandle color={disabled ? 'disabled' : 'inherit'} className={classes.iconButton} />
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

RaceCategoryRow.propTypes = {
  canReorder: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
  handlePreview: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  moveRace: PropTypes.func.isRequired,
  onReorder: PropTypes.func.isRequired,
  race: PropTypes.object.isRequired,
  onFailedDrop: PropTypes.func.isRequired,
};

RaceCategoryRow.defaultProps = {
  canReorder: true,
};

export default RaceCategoryRow;
