import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  subheading: {
    marginBottom: theme.spacing(2),
  },
}));

export const CampaignFinanceImportBody = ({ messages }) => {
  const classes = useStyles();

  return (
    <>
      <Box
        marginBottom={6}
        maxWidth={1000}
      >
        <Typography
          variant="h3"
          component="h2"
          className={classes.subheading}
        >
          {messages.intro.title}
        </Typography>
        <Typography
          variant="body1"
          component="p"
        >
          {messages.intro.description}
        </Typography>
      </Box>

      <Box
        marginBottom={8}
        maxWidth={1000}
      >
        <Typography
          variant="h3"
          component="h2"
          className={classes.subheading}
        >
          {messages.import.instructions.title}
        </Typography>
        <Typography
          variant="body1"
          component="p"
        >
          {messages.import.instructions.description}
        </Typography>
      </Box>
    </>
  );
};

CampaignFinanceImportBody.propTypes = {
  messages: PropTypes.object,
};

CampaignFinanceImportBody.defaultProps = {
  messages: {
    heading: 'Import Campaign Finance Data',
    subheading:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed posuere accumsan neque iaculis congue.',
    intro: {
      title: 'Getting started with campaign finance data',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed posuere accumsan neque iaculis congue.',
    },
    import: {
      instructions: {
        title: 'Import data',
        description:
          'Add your spreadsheet by clicking the "Attach" button to choose your file. Once it has been attached, click the "Start Import" button to add your campaign finance data.',
      },
      actions: {
        title: 'Attach your finance data here (.csv)',
        attachButton: 'Attach',
        cancelButton: 'Cancel',
        importButton: 'Start Import',
        sucessTitle: 'File ready to upload!',
      },
    },
  },
};
