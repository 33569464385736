import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/styles';
import Interweave from 'interweave';
import { DELETE_RACE as DELETE_RACE_SUCCESS_PARAM, SUCCESS_PARAM } from 'helpers/SuccessMessageParams';
import ConfirmationDialog from 'components/ConfirmationDialog';
import DataRowCard from 'components/DataRowCard';
import HelpfulHint from 'components/HelpfulHint';
import PageHeader from 'components/PageHeader';
import PageActionsHeader from 'components/PageActionsHeader';
import PageActionsFooter from 'components/PageActionsFooter';
import SnackbarAlert from 'components/SnackbarAlert';
import BioSetSelector from 'components/BioQuestions/BioSetSelector';
import AddCandidateModal from 'components/Candidates/Partials/AddCandidateModal';
import RaceDistrictForm from 'components/Races/Partials/RaceDistrictForm';
import RaceOverviewForm from 'components/Races/Partials/RaceOverviewForm';
import RaceQuestionSetSelector from 'components/RaceQuestions/RaceQuestionSetSelector';
import fetchUtil from 'helpers/Fetch';
import theme from 'scripts/theme';

const NULL_ITEM_ID = '';
const VOTING_RANKED = 'ranked';
const VOTING_MULTI = 'multiple';
const VOTING_SINGLE = 'single';
const OPEN_GENERAL = 2;

const styles = () => createStyles({
  actionButton: {
    margin: '8px 20px',
    minWidth: '50px',
    textTransform: 'none',
  },
  danger: {
    color: '#ac1b3d',
    '&:hover': {
      color: '#6B1126',
    },
  },
  unfinished: {
    display: 'none',
  },
});

const RaceDetailContainer = (props) => {
  const {
    guideId,
    breadcrumbText,
    breadcrumbUrl,
    classes,
    languages,
    race,
    categories,
    raceTypes,
    isMeasure,
    messages,
    votingSystems,
  } = props;

  const _snackbar = useRef();
  const [tempRaceObject, setTempRaceObject] = useState({ ...race });
  const [renderedWYSIWYGValue] = useState(race.description);
  const [raceObj, setRaceObj] = useState({ ...race });
  const [candidates, setCandidates] = useState([]);
  const [parties, setParties] = useState([]);
  const [partiesLoaded, setPartiesLoaded] = useState(false);
  const [bioSets, setBioSets] = useState([]);
  const [numberOfCandidates, setNumberOfCandidates] = useState(1);
  const [selectedCategoryId, setSelectedCategoryId] = useState(NULL_ITEM_ID);
  const [selectedDistrict, setSelectedDistrict] = useState({});
  const [selectedType, setSelectedType] = useState(1);
  const [selectedVotingOption, setSelectedVotingOption] = useState('');
  const [selectedCandidate, setSelectedCandidate] = useState({});
  const [selectedRaceQuestionSetId, setSelectedRaceQuestionSetId] = useState(NULL_ITEM_ID);
  const [selectedBioSetId, setSelectedBioSetId] = useState(NULL_ITEM_ID);
  const [submittingCandidate, setSubmittingCandidate] = useState(false);
  const [raceQuestionSets, setRaceQuestionSets] = useState([]);
  const [isLoadingOverview, setLoadingOverview] = useState(true);
  const [isLoadingBios, setLoadingBios] = useState(true);
  const [isLoadingRaceQuestions, setLoadingRaceQuestions] = useState(true);
  const [errors, setErrors] = useState({});
  const [isBusy, setBusy] = useState(false);
  const [modalToShow, setModalToShow] = useState('');
  const [addCandidateErrors, setAddCandidateErrors] = useState({});

  useEffect(async () => {
    await onLoad();
  }, []);

  /**
   * Runs when the page loads. This method handles the proper initialization of the data, such as the candidates and
   * the voting option which is selected.
   */
  const onLoad = async () => {
    // initialize BioSets with the selected one
    setBioSets([race.bioSet]);
    setSelectedBioSetId(race.bioSet?.id ? race.bioSet.id : NULL_ITEM_ID);

    setSelectedRaceQuestionSetId(race.questionSet?.id ? race.questionSet.id : NULL_ITEM_ID);

    // set voting option
    let option = VOTING_RANKED;
    if (race.votingSystem.id === 1) {
      if (race.choose > 1) {
        option = VOTING_MULTI;
      } else {
        option = VOTING_SINGLE;
      }
    }

    // Add uncategorized option
    categories.push({
      id: NULL_ITEM_ID,
      title: 'Uncategorized',
    });

    // set category
    if (race.category) {
      setSelectedCategoryId(race.category.id);
    } else {
      setSelectedCategoryId(NULL_ITEM_ID);
    }

    // Set selected district
    if (race.votingDistrict) {
      setSelectedDistrict(race.votingDistrict);
    } else {
      setSelectedDistrict({});
    }

    const selectedOption = votingSystems.find(sys => sys.value === option);
    setSelectedVotingOption(selectedOption.value);

    // assume open/general if it's null
    setSelectedType(race.state ? race.state.id : OPEN_GENERAL);
    setNumberOfCandidates(race.choose);
    setLoadingOverview(false);

    // Collect candidates into format used by DataRows
    const tempCandidates = [];
    Object.values(race.candidates).forEach(candidate => {
      tempCandidates.push(getCandidateDisplayFromCandidate(candidate));
    });
    setCandidates(tempCandidates);

    // fetch BioSets
    try {
      const bioSetsResponse = await fetchUtil(`/api/v1/guide/${guideId}/bioquestions/set`, 'GET');
      setBioSets(bioSetsResponse);
      setLoadingBios(false);
    } catch (error) {
      setLoadingBios(false);
      console.error(error);
      onError('Failed loading Bio Questions', 'Failed to load Bio Questions');
    }

    // fetch Race Questions and Sets
    try {
      const raceQSetsResponse = await fetchUtil(`/api/v1/guide/${guideId}/race-questions`, 'GET');
      setRaceQuestionSets(raceQSetsResponse);
      setLoadingRaceQuestions(false);
    } catch (error) {
      setLoadingRaceQuestions(false);
      console.error(error);
      onError('Failed loading Race Questions', 'Failed to load Race Questions');
    }

    // fetch parties
    try {
      const partiesResponse = await fetchUtil(`/api/v1/guide/${guideId}/parties`, 'GET');
      setParties(partiesResponse);
      setPartiesLoaded(true);
    } catch (error) {
      console.error(error);
      onError('Failed loading parties', 'Failed to load parties');
    }
  };

  const onError = (action, message) => {
    _snackbar.current.hide();
    _snackbar.current.show('error', message);
  };

  const onSuccess = (action, message) => {
    _snackbar.current.hide();
    _snackbar.current.show('success', message);
  };

  const candidateActions = [
    // {
    //   label: 'Delete',
    //   action: (id) => {
    //     setSelectedCandidate(candidates.find(c => c.id === id));
    //     setModalToShow('candidate-delete');
    //   },
    // },
    {
      label: 'Archive',
      action: (id) => {
        setSelectedCandidate(candidates.find(c => c.id === id));
        setModalToShow('candidate-archive');
      },
    },
    {
      label: 'Edit',
      action: (id) => {
        const candidate = candidates.find(c => c.id === id);
        window.location.href = `/races/${race.id}/candidates/${candidate.id}`;
      },
    },
  ];

  const handleChangeTitle = (event) => {
    tempRaceObject.name = event.target.value;
  };

  const handleChangeType = (event) => {
    if (event.target.value) {
      const newType = raceTypes.find(t => t.id === event.target.value);
      setSelectedType(newType.id);
    }
  };

  const handleChangeVotingOption = (event) => {
    if (event.target.value) {
      const selectedOption = votingSystems.find(sys => sys.value === event.target.value);
      setSelectedVotingOption(selectedOption.value);

      if (selectedOption.value === VOTING_SINGLE) {
        setNumberOfCandidates(1);
      }
    }
  };

  const handleChangeCategory = (event) => {
    const category = categories.find((cat) => cat.id === event.target.value);
    if (category && Object.prototype.hasOwnProperty.call(category, 'id')) {
      setSelectedCategoryId(category.id);
    }
  };

  const handleChangeDistrict = (newDistrict) => {
    setSelectedDistrict(newDistrict);
  };

  const handleChangeDescriptionContent = (html) => {
    tempRaceObject.description = html;
  };

  const handleChangeNumberOfCandidates = (event) => {
    if (event.target.value.length === 0) {
      setNumberOfCandidates('');
      return;
    }
    const val = parseInt(event.target.value, 10);
    if ((Number.isInteger(val) && val < Number.MAX_SAFE_INTEGER)) {
      setNumberOfCandidates(val);
    }
  };

  const getCandidateDisplayFromCandidate = (candidate) => {
    const candidateParties = [];
    Object.values(candidate.parties).forEach(party => {
      candidateParties.push(party.name);
    });

    return {
      id: candidate.id,
      values: [
        { label: messages.candidate.display.fullName, value: candidate.name },
        { label: messages.candidate.display.parties, value: candidateParties.join(', ') },
      ],
    };
  };

  const handleAddCandidate = () => {
    setModalToShow('candidate-add');
  };

  const handleArchiveCandidate = () => {
    if (selectedCandidate) {
      fetchUtil(`/api/v1/guide/${guideId}/candidate/archive`, 'PUT', { candidateIds: [selectedCandidate.id] })
        .then(() => {
          const remainingCandidates = candidates.filter(candidate => candidate.id !== selectedCandidate.id);
          setCandidates(remainingCandidates);
          handleClearState();
        }).catch((err) => {
          if (err.status === 403) {
            onError('Forbidden', messages.forbidden);
          }
        });
    }
  };

  const handleDeleteCandidate = () => {
    if (selectedCandidate) {
      fetchUtil(`/api/v1/guide/${guideId}/candidate/delete`, 'PUT', { candidateIds: [selectedCandidate.id] })
        .then(() => {
          const remainingCandidates = candidates.filter(candidate => candidate.id !== selectedCandidate.id);
          setCandidates(remainingCandidates);
          handleClearState();
        }).catch((err) => {
          if (err.status === 403) {
            onError('Forbidden', messages.forbidden);
          }
        });
    }
  };

  const handleUpdateBioSet = (id) => {
    setSelectedBioSetId(id);
    if (id === NULL_ITEM_ID) {
      tempRaceObject.bioSet = null;
    } else {
      tempRaceObject.bioSet = bioSets.find((bSet) => bSet.id === id);
    }
  };

  const handleRedirectToBioQuestionsPage = () => {
    window.location.href = '/bioquestions';
  };

  const handleUpdateRaceQuestionSet = (selectedQuestion) => {
    if (selectedQuestion && Object.prototype.hasOwnProperty.call(selectedQuestion, 'id')) {
      tempRaceObject.questionSet = raceQuestionSets.find((qSet) => qSet.id === selectedQuestion.id);
      setSelectedRaceQuestionSetId(selectedQuestion.id);
    } else {
      tempRaceObject.questionSet = null;
      setSelectedRaceQuestionSetId(NULL_ITEM_ID);
    }
  };

  const handleRedirectToRaceQuestionsPage = () => {
    window.location.href = '/questions';
  };

  const handleRaceDeleteRequest = () => {
    setModalToShow('race-delete');
  };

  const handleRaceDeleteOnConfirmation = async () => {
    setBusy(true);
    try {
      const URL = `/api/v1/guide/${guideId}/races/softdelete`;
      const METHOD = 'POST';
      const BODY = {
        ballotItemIds: [race.id],
      };

      await fetchUtil(URL, METHOD, BODY);

      window.location.href = `/races?${SUCCESS_PARAM}=${DELETE_RACE_SUCCESS_PARAM}`;
    } catch (error) {
      let errorMsg;

      if (error.status === 403) {
        errorMsg = messages.forbidden;
      } else {
        errorMsg = messages.delete.error;

        if (isMeasure) {
          errorMsg = messages.delete.measureError;
        }
      }

      onError('Failed delete ', errorMsg);
      setBusy(false);
      console.error(error);
    }
  };

  // TODO
  const handleExportRace = () => {
    console.log('handleExportRace');
  };

  // TODO
  const handleMergeRacesRequest = () => {
    console.log('handleMergeRacesRequest');
  };

  // TODO
  const handleAssignDistrictFromRaceName = () => {
    console.log('handleAssignDistrictFromRaceName');
  };

  const handleRacePublication = () => {
    const isPublish = !tempRaceObject.visible;
    const body = {
      publish: isPublish,
      ballotItemIds: [race.id],
    };
    const url = `/api/v1/guide/${guideId}/races/publish`;

    setBusy(true);

    fetchUtil(url, 'POST', body)
      .then(response => {
        let successMsg = '';

        if (Object.keys(response).length > 0) {
          // We weren't able to perform the action for some reason.
          onError('Publish/unpublish', Object.values(response)[0]);
          setBusy(false);
          return;
        }

        if (isPublish) {
          successMsg = messages.publish.singleSuccess;

          if (isMeasure) {
            successMsg = messages.publish.singleMeasureSuccess;
          }
        } else if (isMeasure) {
          successMsg = messages.unpublish.singleMeasureSuccess;
        } else {
          successMsg = messages.unpublish.singleSuccess;
        }

        onSuccess('Successful publish', successMsg);

        // Updating race
        const copyTempRaceObject = { ...tempRaceObject };
        copyTempRaceObject.visible = isPublish;
        setTempRaceObject(copyTempRaceObject);
        setBusy(false);
      })
      .catch((error) => {
        let errorMsg;

        if (error.status === 403) {
          errorMsg = messages.forbidden;
        } else if (isPublish) {
          errorMsg = messages.publish.singleError;

          if (isMeasure) {
            errorMsg = messages.publish.singleMeasureError;
          }
        } else if (isMeasure) {
          errorMsg = messages.unpublish.singleMeasureError;
        } else {
          errorMsg = messages.unpublish.singleError;
        }

        onError('Failed publish ', errorMsg);
        setBusy(false);
        console.error(error);
      });
  };

  const handleRaceSubmission = () => {
    const data = {
      id: tempRaceObject.id,
      name: tempRaceObject.name,
      raceClass: tempRaceObject.clazz.id,
      choose: numberOfCandidates,
      description: tempRaceObject.description,
      state: selectedType,
      votingSystem: selectedVotingOption === VOTING_RANKED ? 2 : 1,
      bioQuestionSet: tempRaceObject.bioSet,
      questionSet: tempRaceObject.questionSet,
      district: selectedDistrict && Object.prototype.hasOwnProperty.call(selectedDistrict, 'id') ? selectedDistrict : NULL_ITEM_ID,
      category: selectedCategoryId === NULL_ITEM_ID ? undefined : categories.find(c => c.id === selectedCategoryId),
    };

    setBusy(true);
    setErrors({});

    fetchUtil(`/api/v1/guide/${guideId}/race/${race.id}`, 'PUT', data)
      .then(() => {
        setBusy(false);
        setRaceObj({ ...tempRaceObject });
        // window.location.reload();
        if (isMeasure) {
          onSuccess('saveMeasure', 'Successfully updated the measure!');
        } else {
          onSuccess('saveRace', 'Successfully updated the race!');
        }
      })
      .catch(error => {
        onError('Update race failed', 'There was an issue updating your race. Review any errors below and contact your guide admin if this issue continues.');
        if (error.errors && error.errors[0] && error.errors[0].validationErrors) {
          setErrors(error.errors[0].validationErrors);
        } else {
          setErrors(error);
        }
        setBusy(false);
      });
  };

  const handlePreview = () => {
    fetchUtil(`/api/v1/guide/${guideId}/race/${race.id}/preview`, 'GET')
      .then((data) => {
        if (data.link && data.loginCode) {
          const form = document.createElement('form');
          form.method = 'POST';
          form.target = '_blank';
          form.action = data.link;

          const usernameInput = document.createElement('input');
          usernameInput.name = 'username';
          usernameInput.value = data.username;
          form.appendChild(usernameInput);

          const loginCode = document.createElement('input');
          loginCode.name = 'loginCode';
          loginCode.value = data.loginCode;
          form.appendChild(loginCode);

          const ivSpec = document.createElement('input');
          ivSpec.name = 'ivSpec';
          ivSpec.value = data.ivSpec;
          form.appendChild(ivSpec);

          document.body.append(form);
          form.submit();
          form.parentNode.removeChild(form);
        }
      }).catch(() => {
        const err = 'The system encountered an issue loading the race preview.';
        console.error(err);
        onError('redirect', err);
      });
  };

  const showModal = () => {
    switch (modalToShow) {
      case 'candidate-add':
        return showAddCandidateModal();
      case 'candidate-archive':
        return showCandidateArchiveConfirmationModal();
      case 'candidate-delete':
        return showCandidateDeleteConfirmationModal();
      case 'race-delete':
        return showRaceDeleteConfirmationModal();
      default:
        return false;
    }
  };

  const handleClearState = () => {
    setSelectedCandidate(null);
    setModalToShow(null);
    setSubmittingCandidate(false);
    setLoadingRaceQuestions(false);
    setLoadingBios(false);
    setAddCandidateErrors({});
  };

  const handleSubmitCandidate = (newCandidate) => {
    setSubmittingCandidate(true);

    const candidateClass = raceObj.clazz.id === 'R' ? 'C' : 'R';

    const body = {
      candidateClass,
      candidateState: 1,
      contactName: newCandidate.contactUser.name,
      contactEmail: newCandidate.contactUser.email,
      inFavor: candidateClass == 'R' ? newCandidate.inFavor : null,
      lastName: newCandidate.lastName,
      name: newCandidate.name,
      partiesIds: newCandidate.parties.map((x) => x.id),
      raceId: newCandidate.race.id,
    };

    fetchUtil(`/api/v1/guide/${guideId}/race/${body.raceId}/candidate`, 'POST', body)
      .then(data => {
        setCandidates([...candidates, getCandidateDisplayFromCandidate(data)]);
        onSuccess('add candidate succeeded', messages.candidate.add.success);
        handleClearState();
      })
      .catch((error) => {
        console.error(error);
        onError('add candidate failed', messages.candidate.add.error);
        setAddCandidateErrors(error);
        setSubmittingCandidate(false);
      });
  };

  const showAddCandidateModal = () => (
    <AddCandidateModal
      errors={addCandidateErrors}
      guideId={guideId}
      messages={messages.candidate.add}
      open={modalToShow != null}
      onCancel={handleClearState}
      onSubmit={handleSubmitCandidate}
      parties={parties}
      races={[race]}
      racesLoaded={true}
      submitting={submittingCandidate}
    />
  );

  const showCandidateArchiveConfirmationModal = () => (
    <ConfirmationDialog
      cancelButtonText="Cancel"
      confirmButtonText="Archive"
      heading="Archive candidate"
      onCancel={handleClearState}
      onConfirm={handleArchiveCandidate}
      open={true}
    >
      <Typography component="p" variant="body1">
        Are you sure you want to archive this candidate? They will still be accessible via the archived candidates dashboard, but will not appear in the guide.
      </Typography>
    </ConfirmationDialog>
  );

  const showCandidateDeleteConfirmationModal = () => (
    <ConfirmationDialog
      cancelButtonText={messages.candidate.delete.cancel}
      confirmButtonText={messages.candidate.delete.confirm}
      heading={messages.candidate.delete.heading}
      onCancel={handleClearState}
      onConfirm={handleDeleteCandidate}
      open={true}
    >
      <Typography component="p" variant="body1">
        {messages.candidate.delete.body}
      </Typography>
    </ConfirmationDialog>
  );

  const showRaceDeleteConfirmationModal = () => (
    <ConfirmationDialog
      cancelButtonText={messages.race.delete.cancel}
      confirmButtonText={messages.race.delete.confirm}
      heading={isMeasure ? messages.race.delete.measureHeading : messages.race.delete.heading}
      onCancel={handleClearState}
      onConfirm={handleRaceDeleteOnConfirmation}
      open={true}
    >
      <Typography component="p" variant="body1">
        {isMeasure ? messages.race.delete.measureBody : messages.race.delete.body}?
      </Typography>
    </ConfirmationDialog>
  );

  const getPageActions = () => {
    if (isMeasure) {
      return (
        <>
          <Button
            disabled={isBusy}
            onClick={handleRaceDeleteRequest}
            className={`${classes.actionButton} ${classes.danger} ${classes.unfinished}`}
            color="secondary"
            size="small"
          >Delete</Button>
          <Button
            disabled={isBusy}
            onClick={handlePreview}
            className={classes.actionButton}
            color="primary"
            size="small"
          >
            Preview Measure
          </Button>
          <Button disabled={isBusy} onClick={handleExportRace} className={`${classes.actionButton} ${classes.unfinished}`} color="primary"
            size="small">Export</Button>
          {publishBtn()}
          <Button disabled={isBusy} onClick={handleRaceSubmission} color="secondary" variant="contained">Save</Button>
        </>
      );
    }

    return (
      <>
        <Button
          disabled={isBusy}
          onClick={handleRaceDeleteRequest}
          className={`${classes.actionButton} ${classes.danger}`}
          color="secondary"
          size="small"
        >
          Delete
        </Button>

        <Button
          disabled={isBusy}
          onClick={handlePreview}
          className={classes.actionButton}
          color="primary"
          size="small"
        >
          Preview Race
        </Button>

        <Button
          disabled={isBusy}
          onClick={handleExportRace}
          className={`${classes.actionButton} ${classes.unfinished}`}
          color="primary"
          size="small"
        >
          Export
        </Button>

        <Button
          disabled={isBusy}
          onClick={handleMergeRacesRequest}
          className={`${classes.actionButton} ${classes.unfinished}`}
          color="primary"
          size="small"
        >
          Merge Races
        </Button>

        <Button
          disabled={isBusy}
          onClick={handleAssignDistrictFromRaceName}
          className={`${classes.actionButton} ${classes.unfinished}`}
          color="primary"
          size="small"
        >
          Assign District from Race Name
        </Button>

        {publishBtn()}

        <Button
          disabled={isBusy}
          onClick={handleRaceSubmission}
          color="secondary"
          variant="contained"
        >
          Save
        </Button>
      </>
    );
  };

  const publishBtn = () => {
    let btnText;

    if (tempRaceObject.visible) {
      btnText = isMeasure ? messages.heading.measureUnpublishButtonText : messages.heading.unpublishButtonText;
    } else if (isMeasure) {
      btnText = messages.heading.measurePublishButtonText;
    } else {
      btnText = messages.heading.publishButtonText;
    }

    return <Button disabled={isBusy} onClick={() => handleRacePublication()} color="secondary" variant="outlined">{btnText}</Button>;
  };

  return (
    <ThemeProvider theme={theme}>
      {showModal()}

      <SnackbarAlert ref={_snackbar} />

      <PageHeader
        breadcrumbText={breadcrumbText}
        breadcrumbUrl={breadcrumbUrl}
        heading={raceObj.name}
        subheading={isMeasure ? messages.heading.measureSubheading : messages.heading.raceSubheading}
      />

      <PageActionsHeader className="ga-mb-36">
        {getPageActions()}
      </PageActionsHeader>

      <div className="ga-container ga-mb-60">
        <Grid container spacing={3}>
          <Grid item xs={12} md={9}>
            <RaceOverviewForm
              categories={categories}
              categoryId={selectedCategoryId}
              categoryLabel={isMeasure ? messages.overview.measureCategoryLabel : messages.overview.raceCategoryLabel}
              description={renderedWYSIWYGValue}
              descriptionPlaceholderText={isMeasure ? messages.overview.measureDescriptionPlaceholderText : messages.overview.raceDescriptionPlaceholderText}
              editCategoryLinkText={isMeasure ? messages.overview.editMeasureCategoryLinkText : messages.overview.editRaceCategoryLinkText}
              errors={errors}
              heading={isMeasure ? messages.overview.measureTitle : messages.overview.raceTitle}
              isLoading={isLoadingOverview}
              isMeasure={isMeasure}
              onChangeCategory={handleChangeCategory}
              onChangeTitle={handleChangeTitle}
              onChangeType={handleChangeType}
              onChangeVotingOption={handleChangeVotingOption}
              onChangeDescriptionContent={handleChangeDescriptionContent}
              onChangeNumberOfCandidates={handleChangeNumberOfCandidates}
              name={tempRaceObject.name}
              numberOfCandidates={numberOfCandidates}
              titleLabel={isMeasure ? messages.overview.measureTitleLabel : messages.overview.raceTitleLabel}
              titleExample={isMeasure ? messages.overview.measureTitleExample : messages.overview.raceTitleExample}
              type={selectedType}
              raceTypes={raceTypes}
              votingOption={selectedVotingOption}
              votingSystems={votingSystems}
            />
          </Grid>
          <Grid item xs={12} md={3}></Grid>

          <Grid item xs={12} md={9}>
            <RaceDistrictForm
              district={raceObj.votingDistrict}
              errors={errors}
              guideId={guideId}
              onChange={handleChangeDistrict}
              title="District"
            />
          </Grid>
          <Grid item xs={12} md={3}></Grid>

          <Grid item xs={12} md={9}>
            <DataRowCard
              buttonText="Add"
              disabled={!partiesLoaded}
              emptyStateText="No candidates assigned to race"
              rowActions={candidateActions}
              rows={candidates}
              onClick={handleAddCandidate}
              title="Candidates"
            />
          </Grid>
          <Grid item xs={12} md={3}></Grid>

          <Grid item xs={12} md={9}>
            <BioSetSelector
              buttonText="Create new biographical question set"
              disableEmptyState
              emptyOptionText="Use all biographical questions"
              emptyOptionValue="all"
              emptyStateText="You have not created any biographical sets yet. <a href=''>Create your first set question set</a> or select All Biographical Questions."
              header="Biographical question set"
              isLoading={isLoadingBios}
              label="Biographical Set Title"
              onChange={handleUpdateBioSet}
              onClick={handleRedirectToBioQuestionsPage}
              questionListLabel="Biographical Questions"
              selected={selectedBioSetId}
              sets={bioSets}
              showAction
            />
          </Grid>
          <Grid item xs={12} md={3}></Grid>

          <Grid item xs={12} md={9}>
            <RaceQuestionSetSelector
              buttonText={isMeasure ? messages.question.measureButtonText : messages.question.raceButtonText}
              header={isMeasure ? messages.question.measureHeader : messages.question.raceHeader}
              emptyStateText={isMeasure ? messages.question.measureEmptyState : messages.question.raceEmptyState}
              languages={languages} // huh?
              selected={selectedRaceQuestionSetId}
              sets={raceQuestionSets}
              onChange={handleUpdateRaceQuestionSet}
              onClick={handleRedirectToRaceQuestionsPage}
              selectLabel={isMeasure ? messages.question.measureSelectLabel : messages.question.raceSelectLabel}
              showAction
              loading={isLoadingRaceQuestions}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <HelpfulHint>
              <Interweave content={isMeasure ? messages.question.measureHelpfulHint : messages.question.raceHelpfulHint} />
            </HelpfulHint>
          </Grid>
        </Grid>
      </div>

      <PageActionsFooter sticky>
        <Button
          disabled={isBusy}
          color="primary"
          variant="text"
          href={breadcrumbUrl}
        >
          Cancel
        </Button>

        <Button
          disabled={isBusy}
          onClick={handlePreview}
          color="primary"
        >
          Preview Race
        </Button>

        {publishBtn()}

        <Button
          disabled={isBusy}
          onClick={handleRaceSubmission}
          color="secondary"
          variant="contained"
        >
          Save
        </Button>
      </PageActionsFooter>
    </ThemeProvider>
  );
};

RaceDetailContainer.propTypes = {
  guideId: PropTypes.string.isRequired,
  breadcrumbText: PropTypes.string,
  breadcrumbUrl: PropTypes.string,
  classes: PropTypes.object,
  languages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
  })),
  race: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    clazz: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    description: PropTypes.string,
    name: PropTypes.string,
    state: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    choose: PropTypes.number.isRequired,
    votingSystem: PropTypes.shape({
      id: PropTypes.number,
    }).isRequired,
    votingDistrict: PropTypes.object,
    questionSet: PropTypes.object,
    bioSet: PropTypes.object, // May be null if race uses all bio questions rather than a set
    category: PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    }),
    candidates: PropTypes.arrayOf(PropTypes.object),
  }),
  votingSystems: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })),
  categories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
  })).isRequired,
  raceTypes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })).isRequired,
  isMeasure: PropTypes.bool,
  messages: PropTypes.object.isRequired,
};

RaceDetailContainer.defaultProps = {
  breadcrumbText: 'Races & Measures',
  breadcrumbUrl: '/races',
  race: {},
  votingSystems: [
    {
      value: VOTING_SINGLE,
      name: 'Single Vote',
    },
    {
      value: VOTING_RANKED,
      name: 'Ranked Choice',
    },
    {
      value: VOTING_MULTI,
      name: 'Multiple Selection Vote',
    },
  ],
  isMeasure: false,
};

export default withStyles(styles)(RaceDetailContainer);
