import React, { useEffect, useRef, useState } from 'react';
import { ThemeProvider } from '@material-ui/core';
import PropTypes from 'prop-types';
import theme from 'scripts/theme';
import { affiliatePropType } from 'propTypes';
import AffiliateGuideTable from 'components/SuperAdminSettings/Partials/AffiliateGuideTable';
import fetchUtil from 'helpers/Fetch';
import SnackbarAlert from 'components/SnackbarAlert';

const GuideListContainer = ({ affiliates }) => {
  const _snackbar = useRef(null);

  const [guides, setGuides] = useState([]);
  const [affiliatesList, setAffiliatesList] = useState([]);

  const handleCreateGuide = async (newGuide) => {
    try {
      const result = await fetchUtil('/api/v1/admin/guide', 'POST', newGuide);
      if (result) {
        setGuides([
          reshuffleGuide(result.affiliate, result),
          ...guides,
        ]);
        showSuccess(`Successfully created new guide named ${result.voterGuide.name}`);
      }
    } catch (error) {
      console.log('Something went wrong:');
      console.log(error);
      if (error.errors && error.errors[0].detail) {
        showError(error.errors && error.errors[0].detail);
      } else {
        showError('An unexpected error occurred when trying to copy the guide.');
      }
    }
  };

  const reshuffleGuide = (affiliate, guide) => ({
    id: guide.id,
    live: guide.live,
    ownerId: affiliate.id,
    voterGuide: {
      name: guide.voterGuide.name,
      rlength: guide.voterGuide.rlength,
      status: guide.voterGuide.status,
    },
    affiliate: {
      id: affiliate.id,
      name: affiliate.name,
      applicationId: affiliate.applicationId,
    },
  });

  const showSuccess = (message) => {
    _snackbar.current.show('success', message);
  };

  const showError = message => {
    _snackbar.current.show('error', message);
  };

  useEffect(() => {
    // This runs exclusively on component mount
    setAffiliatesList(affiliates);
  });

  useEffect(() => {
    console.log('reshuffling guides');

    // Restructure the affiliate lis, so it's a flat list of guides that we can pass to the data grid
    const newGuideList = [];

    affiliatesList.forEach(affiliate => {
      // Create a flat map for the guides to use in the data grid
      if (Object.prototype.hasOwnProperty.call(affiliate, 'guides') && affiliate.guides.length > 0) {
        affiliate.guides.forEach(guide => {
          if (Object.prototype.hasOwnProperty.call(guide, 'voterGuide')) {
            if (guide.voterGuide.name) {
              const g = reshuffleGuide(affiliate, guide);
              newGuideList.push(g);
            }
          }
        });
      }
    });

    setGuides(newGuideList);
  }, [affiliatesList]);

  return (
    <ThemeProvider theme={theme}>
      <SnackbarAlert ref={_snackbar} />
      <h1 style={{ marginBottom: '10px' }}>
        Affiliates & Guides
      </h1>

      <AffiliateGuideTable
        rows={guides}
        affiliates={affiliatesList}
        onSubmitCopyGuide={handleCreateGuide}
      />
    </ThemeProvider>
  );
};

GuideListContainer.propTypes = {
  affiliates: PropTypes.arrayOf(affiliatePropType),
};

export default GuideListContainer;
