import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import MUIRichTextEditor from 'mui-rte';
import {
  convertToRaw,
  EditorState,
  RichUtils,
} from 'draft-js';
import { convertToHTML, convertFromHTML } from 'draft-convert';
import { Typography } from '@material-ui/core';
import { KeyboardReturn } from '@material-ui/icons';
import {
  ControlNames,
  CustomControls,
  handleHtmlToBlock,
  handleBlockToHtml,
  handleEntityToHtml,
  handleHtmlToEntity,
} from 'components/WYSIWYGCustomControls';

// constants and variables
const AVAILABLE_CONTROLS = [
  'bold',
  'italic',
  'underline',
  'undo',
  'redo',
  'link',
  'numberList',
  'bulletList',
  'quote',
  'clear',
  ControlNames.lineBreak,
  ControlNames.leftAlign,
  ControlNames.centerAlign,
  ControlNames.rightAlign,
];

// Main Component
const WYSIWYG = ({
  disabled,
  error,
  helperText,
  label,
  onChange,
  value,
}) => {
  const [content, setContent] = useState();
  const [newValue, setNewValue] = useState();

  useEffect(() => {
    // good line for testing, so leaving it commented out instead of removing
    // console.log('value: ', value);
    // 1. Convert the HTML
    const contentHTML = convertFromHTML({ htmlToBlock: handleHtmlToBlock, htmlToEntity: handleHtmlToEntity })(value);

    // ######
    // HEY!!! GOOD AFTERNOON!
    // We got this component working by not treating it like normal react controlled input.
    // Instead of the normal state > onChange(set state) pattern, we do something like this:
    // Input: default value of the wysiwyg
    // OnChange: pass up the new value
    // In the parent: save the changed value, but don't change the default value passed into this component
    // But, in the parent we are still saving the updated value, we just keep it for later instead of passing it
    // right back in as a prop
    // ######

    // 2. Create the ContentState object
    const state = EditorState.createWithContent(contentHTML);

    // 3. Stringify `state` object from a Draft.Model.Encoding.RawDraftContentState object
    const raw = JSON.stringify(convertToRaw(state.getCurrentContent()));

    setContent(raw);
  }, [value]);

  const ref = useRef(null);

  // Functions
  const handleBlur = () => {
    // call the onSave callback (handleSave)
    // Yes, this is gross. Please do not change it.
    ref.current.save();
  };

  const handleSave = () => {
    onChange(newValue);
  };

  const handleChange = (v) => {
    const raw = convertToHTML({ blockToHTML: handleBlockToHtml, entityToHTML: handleEntityToHtml })(v.getCurrentContent());
    setNewValue(raw);
  };

  // complex custom control - had to move it to this file due to needing to edit the component state
  const lineBreakControl = {
    name: ControlNames.lineBreak, // self-assigned name "LINE_BREAK"
    icon: <KeyboardReturn />, // icon, duh
    type: 'callback', // this tells mui-rte that it should execute the onClick method
    onClick: (editorState) => ( // also has name and target parameters that we ignore
      RichUtils.insertSoftNewline(editorState)
    ),
  };

  return (
    <div>
      <MUIRichTextEditor
        controls={AVAILABLE_CONTROLS}
        customControls={[
          ...CustomControls,
          lineBreakControl,
        ]}
        defaultValue={content}
        error={error}
        label={label}
        onChange={handleChange}
        onBlur={handleBlur}
        onSave={handleSave}
        readOnly={disabled}
        ref={ref}
      />

      {helperText && (
        <Typography component="p" variant="caption" color={error ? 'error' : 'textSecondary'} paragraph >
          {helperText}
        </Typography>
      )}
    </div>
  );
};

WYSIWYG.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

WYSIWYG.defaultProps = {
  disabled: false,
  error: false,
  helperText: '',
  onChange: () => {},
  value: '',
};

export default WYSIWYG;
