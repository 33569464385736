import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import theme from 'scripts/theme';

const styles = () => ({
  actionButton: {
    marginLeft: '1rem',
  },
});

class MultistepModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: 0,
      open: false,
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleNextStep = this.handleNextStep.bind(this);
    this.handlePrevStep = this.handlePrevStep.bind(this);
  }

  handleOpen() {
    this.setState({
      currentStep: 0,
      open: true,
    });
  }

  handleClose() {
    this.setState({
      open: false,
    });
  }

  handleNextStep() {
    const { steps } = this.props;
    const { currentStep } = this.state;
    const nextStep = currentStep + 1;

    if (nextStep > (steps.length - 1)) {
      return;
    }

    this.setState({
      currentStep: nextStep,
    });
  }

  handlePrevStep() {
    const { currentStep } = this.state;
    const prevStep = currentStep - 1;

    if (prevStep < 0) {
      return;
    }

    this.setState({
      currentStep: prevStep,
    });
  }

  nextStepDescription() {
    const { steps } = this.props;
    const { currentStep } = this.state;

    return steps[currentStep].next;
  }

  displayActions() {
    const { classes, steps } = this.props;
    const { currentStep } = this.state;
    let prevButton;
    let nextButton = (
      <Button
        className={classes.actionButton}
        color="secondary"
        onClick={this.handleNextStep}
        variant="contained"
      >
        Next
      </Button>
    );

    if (currentStep > 0) {
      prevButton = (
        <Button
          className={classes.actionButton}
          color="secondary"
          onClick={this.handlePrevStep}
          variant="outlined"
        >
          Previous
        </Button>
      );
    }

    if (currentStep == (steps.length - 1)) {
      nextButton = (
        <Button
          className={classes.actionButton}
          color="secondary"
          onClick={this.handleClose}
          variant="contained"
        >
          Finish
        </Button>
      );
    }

    return (
      <DialogActions>
        <div>
          {this.nextStepDescription()}
        </div>

        <div>
          <Button onClick={this.handleClose}>
            Cancel
          </Button>

          {prevButton}
          {nextButton}
        </div>
      </DialogActions>
    );
  }

  render() {
    const { steps } = this.props;
    const { currentStep, open } = this.state;
    const step = steps[currentStep];
    const ModalBody = step.body;

    return (
      <ThemeProvider theme={theme}>
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          maxWidth="md"
        >
          <DialogTitle id="customized-dialog-title" disableTypography onClose={this.handleClose}>
            <Typography component="h1" variant="h1">
              {step.title}
            </Typography>

            <IconButton aria-label="close" onClick={this.handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <ModalBody />
          </DialogContent>

          {this.displayActions()}
        </Dialog>
      </ThemeProvider>
    );
  }
}

MultistepModal.propTypes = {
  classes: PropTypes.object,
  steps: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    next: PropTypes.string,
    body: PropTypes.func,
  })).isRequired,
};

MultistepModal.defaultProps = {
  steps: [],
};

export default withStyles(styles)(MultistepModal);
