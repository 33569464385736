import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  Button,
  Grid,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: '1rem',
  },
  contentContainer: {
    alignItems: 'center',
    color: '#333333',
    '&:last-child': {
      paddingBottom: '16px',
    },
  },
  detailContainer: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: '1rem',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '0',
    },
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
    },
  },
  icon: {
    color: '#94a8b2',
    height: '37px',
    marginRight: '1rem',
  },
  button: {
    minWidth: 0,
  },
}));

export default function ViewAllCard({
  icon,
  subtitle,
  title,
  url,
}) {
  const classes = useStyles();

  return (
    <Card className={classes.container}>
      <CardContent className={classes.contentContainer}>
        <Grid container>
          <Grid item xs={12} sm={9}>
            <div className={classes.detailContainer}>
              <div className={classes.icon}>
                {icon}
              </div>

              <div>
                <Typography variant="h3" component="h2">
                  {title}
                </Typography>

                <Typography color="textSecondary" component="p" variant="body2">
                  {subtitle}
                </Typography>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} sm={3} className={classes.actionContainer}>
            <Button
              className={classes.button}
              color="secondary"
              href={url}
              size="medium"
              variant="outlined"
            >
              View All
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

ViewAllCard.propTypes = {
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};
