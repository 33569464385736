import { useState, useEffect } from 'react';

export function useQueryParam(param) {
  const [value, setValue] = useState(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    setValue(searchParams.get(param));
  }, [param]);

  return value;
}
