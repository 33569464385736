const a11yProps = {
  tabs: (index, identifier) => (
    {
      id: `${identifier}-tab-${index}`,
      'aria-controls': `${identifier}-tabpanel-${index}`,
    }
  ),
  tabPanels: (index, identifier) => (
    {
      id: `${identifier}-tabpanel-${index}`,
      'aria-labelledby': `${identifier}-tab-${index}`,
    }
  ),
};

export default a11yProps;
