import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { StepTitle } from 'components/RaceSteps';
import RadioCardList from 'components/RadioCardList';
import RaceQuestionSetSelector from 'components/RaceQuestions/RaceQuestionSetSelector';
import { Box } from '@material-ui/core';
import fetchUtil from 'helpers/Fetch';
import NewRaceQuestionSetCard from './NewRaceQuestionSetCard';

const Container = ({
  raceClassId,
  stepState,
  messages,
  initialOptions: INITIAL_OPTIONS,
  guideId,
  typeOptions: TYPE_OPTIONS,
  raceQuestionValidations,
}) => {
  const onLoad = async () => {
    stepState.setLoadingRQs(true);

    try {
      // use the future of coding for multiple api calls
      const sets = await fetchUtil(`/api/v1/guide/${guideId}/race-questions`, 'GET', {});
      stepState.setExistingRQS(sets);

      const languages = await fetchUtil('/api/v1/guide/languages', 'GET', {});
      stepState.setLanguages(languages);

      stepState.setLoadingRQs(false);
    } catch (error) {
      stepState.setExistingRQS([]);
      console.error(error);
      stepState.setLoadingRQs(false);
    }
  };

  // Component Did Mount
  useEffect(() => {
    onLoad();
  }, []);

  const handleSelectedRQSChange = (set) => {
    console.log('selected set: ', set);
    stepState.setSelectedRQSErrors({});
    stepState.setSelectedRQS(set);
  };

  const handleQuestionSubmit = (question, callback) => {
    const modifiedFormData = {
      body: question.body,
      maxQuestionLength: question.maxQuestionLength,
      type: question.type,
      translations: {},
      id: stepState.newRQSQuestions.length,
    };

    Object.keys(question).forEach(property => {
      // Exclude non-translation fields
      if (!['body', 'maxQuestionLength', 'type'].includes(property)) {
        if (question[property] != '') {
          modifiedFormData.translations[property] = question[property];
        }
      }
    });

    // validate question here rather than on continue
    const validQuestion = raceQuestionValidations(modifiedFormData, TYPE_OPTIONS);
    if (validQuestion.success) {
      stepState.setAddRQuestionErrors({});
      console.log('Questions: ', [...stepState.newRQSQuestions, modifiedFormData]);
      stepState.setNewRQSQuestions([...stepState.newRQSQuestions, modifiedFormData]);
      callback();
    } else {
      stepState.setAddRQuestionErrors(validQuestion.error);
    }
  };

  const handleTitleChange = (newTitle) => {
    stepState.setNewRQSTitle(newTitle);
  };

  const questionListSubtext = (
    <>
      {messages.createNew.list.supporting.prelink}&nbsp;<a href='#' className="link-text">{messages.createNew.list.supporting.link}</a>&nbsp;{messages.createNew.list.supporting.postlink}
    </>
  );

  return (
    <div>
      <StepTitle title={raceClassId === 'R' ? messages.raceTitle : messages.measureTitle}>
        {raceClassId === 'R' ? messages.raceSubtitle : messages.measureSubtitle}
      </StepTitle>

      <Box marginBottom={5} paddingBottom={5}>
        <RadioCardList
          cards={INITIAL_OPTIONS}
          checked={stepState.raceQuestionDecision}
          onChange={id => stepState.setRaceQuestionDecision(id)}
        />
      </Box>

      {stepState.raceQuestionDecision === 0 && (
        <Box marginBottom={5} paddingBottom={5}>
          <RaceQuestionSetSelector
            header={messages.useExisting.title}
            emptyStateText={messages.useExisting.empty}
            languages={stepState.languages}
            selected={stepState.selectedRQS ? stepState.selectedRQS.id : null}
            sets={stepState.existingRQS}
            errors={stepState.selectedRQSErrors}
            onChange={handleSelectedRQSChange}
            questionSetRootUrl={'/'} // comes from API?
            selectLabel={messages.useExisting.select}
          />
        </Box>
      )}

      {stepState.raceQuestionDecision === 1 && (
        <Box marginBottom={5} paddingBottom={5}>
          <NewRaceQuestionSetCard
            header={messages.createNew.title}
            subtext={messages.createNew.supporting}
            inputLabel={messages.createNew.label}
            questionListLabel={messages.createNew.list.title}
            questionListSubtext={questionListSubtext}
            emptyStateText={messages.createNew.list.empty}
            id={0}
            name={stepState.newRQSTitle}
            questions={stepState.newRQSQuestions}
            titleErrors={stepState.newRQSTitleErrors}
            addQuestionErrors={stepState.addRQuestionErrors}
            languages={stepState.languages}
            typeOptions={TYPE_OPTIONS}
            onSubmit={handleQuestionSubmit}
            onTitleChange={handleTitleChange}
          />
        </Box>
      )}
    </div>
  );
};

Container.propTypes = {
  guideId: PropTypes.string.isRequired,
  initialOptions: PropTypes.array.isRequired,
  messages: PropTypes.object.isRequired,
  raceClassId: PropTypes.string,
  raceQuestionValidations: PropTypes.func.isRequired,
  stepState: PropTypes.shape({
    raceQuestionDecision: PropTypes.number.isRequired,
    setRaceQuestionDecision: PropTypes.func.isRequired,
    existingRQS: PropTypes.array.isRequired,
    setExistingRQS: PropTypes.func.isRequired,
    selectedRQS: PropTypes.object, // expecting null
    setSelectedRQS: PropTypes.func.isRequired,
    newRQSTitle: PropTypes.string.isRequired,
    setNewRQSTitle: PropTypes.func.isRequired,
    newRQSQuestions: PropTypes.array.isRequired,
    setNewRQSQuestions: PropTypes.func.isRequired,
    loadingRQs: PropTypes.bool.isRequired,
    setLoadingRQs: PropTypes.func.isRequired,
    languages: PropTypes.array.isRequired,
    setLanguages: PropTypes.func.isRequired,
    selectedRQSErrors: PropTypes.object.isRequired,
    setSelectedRQSErrors: PropTypes.func.isRequired,
    newRQSTitleErrors: PropTypes.object.isRequired,
    setNewRQSTitleErrors: PropTypes.func.isRequired,
    addRQuestionErrors: PropTypes.object.isRequired,
    setAddRQuestionErrors: PropTypes.func.isRequired,
    typeQuestions: PropTypes.array.isRequired,
    setTypeQuestions: PropTypes.func.isRequired,
  }),
  typeOptions: PropTypes.array,
};

Container.defaultProps = {
  typeOptions: [],
};

export default Container;
