const stateErrors = {
  hasError: (key, state) => (key in state),
  getError: (key, state) => {
    if (key in state && state[key].length > 0) {
      return state[key][0];
    }
    return null;
  },
};

export default stateErrors;
