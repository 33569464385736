/**
 * Having this entry file allows for organized importing
 * between CSS and JS.
 * For additional importing / entries, consider updating
 * webpack.config.js.
 *
 * Note: Main in this context is similar to establishing
 * the site's 'global' files.
 * Page/component specific files should be contained to that file
 * and thus webpack.config.js should be updated accordingly to
 * have multiple output files/bundles per page/component.
 *
 */
import './styles/main.scss';
import './scripts/main.js';
