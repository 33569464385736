import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Typography, Button } from '@material-ui/core';
import SnackbarAlert from 'components/SnackbarAlert';
import PageHeader from 'components/PageHeader';
import PageActionsHeader from 'components/PageActionsHeader';
import GAContainer from 'components/Layout/GAContainer';
import { CampaignFinanceLanding } from '@/features/campaign-finance';

const CampaignFinanceLandingContainer = ({ messages, lastImport }) => {
  // TODO: When available, remove the snackbar and ref as it's already available in the ApplicationContextProvider. Then update bulkimport accordingly.
  const _snackbar = useRef();

  // multiply by 1000 because JS expects milliseconds but we have seconds
  const lastImportDate = lastImport ? new Date(lastImport * 1000).toLocaleString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  }) : '';

  return (
    <>
      <SnackbarAlert ref={_snackbar} />

      <PageHeader
        heading={messages.header.heading}
        subheading={messages.header.subheading}
      >
        <div>
          {lastImport && (
            <>
              <Typography
                component="p"
                variant="body2"
                color="textSecondary"
              >
                {messages.header.lastImported}
              </Typography>
              <Typography
                variant="body1"
                component="p"
              >
                {lastImportDate}
              </Typography>
            </>
          )}
        </div>
      </PageHeader>

      <PageActionsHeader>
        <Button
          color="secondary"
          variant="contained"
          href="/campaign-finance/import"
        >
          {messages.links.import}
        </Button>
      </PageActionsHeader>

      <GAContainer>
        <CampaignFinanceLanding
          messages={messages}
        />
      </GAContainer>
    </>
  );
};

CampaignFinanceLandingContainer.propTypes = {
  messages: PropTypes.object,
  lastImport: PropTypes.number,
};

export { CampaignFinanceLandingContainer };
