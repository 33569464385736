import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import {
  Button,
  FormControl,
  Grid,
  TextField,
} from '@material-ui/core';
import Form from 'helpers/Form';

const styles = {
  actionContainer: {
    marginTop: '.4rem',
  },
  partyContainer: {
    marginBottom: '1.4rem',
  },
  destructiveButton: {
    color: '#ac1b3d',
    marginRight: '1rem',
    minWidth: '75px',
    '&:hover': {
      color: '#6B1126',
    },
  },
};

class PartyRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: new Form({
        abbr: props.abbr,
        name: props.name,
      }, {
        validationMessage: props.validationErrorMessage,
      }),
      submitting: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { abbr, name } = this.props;
    const { form } = this.state;

    // Update the form fields if we receive new values via props
    if (abbr !== prevProps.abbr || name !== prevProps.name) {
      const newForm = { ...form };
      newForm.abbr = abbr;
      newForm.name = name;

      this.setState({
        form: newForm,
      });
    }
  }

  handleInputChange(event) {
    const { name } = event.target;
    const { value } = event.target;
    const { form } = this.state;

    form[name] = value;
    this.forceUpdate();
  }

  handleDelete() {
    const {
      id,
      deleteUrl,
      onError,
      onSuccess,
      forbidden,
    } = this.props;
    const { form } = this.state;

    this.setState({
      submitting: true,
    });

    form.delete(`${deleteUrl}/${id}`)
      .then(() => {
        this.setState({
          submitting: false,
        });

        onSuccess('delete', { id });
      })
      .catch(error => {
        this.setState({
          submitting: false,
        });

        if (error.status == 403) {
          onError('Forbidden', forbidden);
        } else {
          onError('delete', error);
        }
      });
  }

  handleSubmit() {
    const {
      id,
      onError,
      onSuccess,
      patchUrl,
    } = this.props;
    const { form } = this.state;

    this.setState({
      submitting: true,
    });

    form.patch(`${patchUrl}/${id}`)
      .then(response => {
        this.setState({
          submitting: false,
        });

        onSuccess('update', response);
      })
      .catch(error => {
        this.setState({
          submitting: false,
        });

        onError('update', error);
      });
  }

  displayActions() {
    const { submitting } = this.state;
    const {
      classes, deleteButtonText, isDefaultParty, updateButtonText,
    } = this.props;

    if (!isDefaultParty) {
      return (
        <Grid className={classes.actionContainer} item xs={6}>
          <Button
            className={classes.destructiveButton}
            color="secondary"
            disabled={submitting}
            onClick={this.handleDelete}
            size="large"
          >
            {deleteButtonText}
          </Button>

          <Button
            color="secondary"
            disabled={submitting}
            onClick={this.handleSubmit}
            size="large"
            variant="contained"
          >
            {updateButtonText}
          </Button>
        </Grid>
      );
    }
  }

  render() {
    const { isDefaultParty, classes } = this.props;
    const { form, submitting } = this.state;

    return (
      <div className={classes.partyContainer}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <TextField
                id={form.abbr}
                disabled={submitting}
                error={form.errors.has('abbr')}
                helperText={form.errors.get('abbr')}
                InputProps={{
                  disableUnderline: isDefaultParty,
                  readOnly: isDefaultParty,
                }}
                label="Party Abbreviation"
                onChange={this.handleInputChange}
                name="abbr"
                required
                variant={isDefaultParty ? 'standard' : 'filled'}
                value={form.abbr}
              />
            </FormControl>
          </Grid>

          <Grid item xs={3}>
            <FormControl fullWidth>
              <TextField
                id={form.name}
                disabled={submitting}
                error={form.errors.has('name')}
                helperText={form.errors.get('name')}
                InputProps={{
                  disableUnderline: isDefaultParty,
                  readOnly: isDefaultParty,
                }}
                label="Party Name"
                name="name"
                required
                variant={isDefaultParty ? 'standard' : 'filled'}
                onChange={this.handleInputChange}
                value={form.name}
              />
            </FormControl>
          </Grid>

          {this.displayActions()}
        </Grid>
      </div>
    );
  }
}

PartyRow.propTypes = {
  abbr: PropTypes.string.isRequired,
  classes: PropTypes.object,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  isDefaultParty: PropTypes.bool,
  deleteButtonText: PropTypes.string,
  deleteUrl: PropTypes.string.isRequired,
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
  patchUrl: PropTypes.string.isRequired,
  updateButtonText: PropTypes.string,
  validationErrorMessage: PropTypes.string,
  forbidden: PropTypes.string,
};

PartyRow.defaultProps = {
  isDefaultParty: false,
  onError: () => {},
  onSuccess: () => {},
  deleteButtonText: 'Delete',
  updateButtonText: 'Update party',
  validationErrorMessage: 'There was an issue updating your party. Correct any errors and try again.',
  forbidden: 'Only administrators can complete this action. Please reach out to your guide administrator.',
};

export default withStyles(styles)(PartyRow);
