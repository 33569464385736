import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import {
  Grid,
} from '@material-ui/core';

import SelectControl from 'components/SelectControl';

import DistrictSelectorTracker from 'helpers/DistrictSelectorTracker';
import fetchUtil from 'helpers/Fetch';

/**
 * This component is a modified version of the DistrictSelectorContainer that allows user to choose a state and then a GIS district set.
 */
const GISDistrictSetSelector = ({
  onChange,
}) => {
  // The selections state uses the DistrictSelectionsTracker object (DistrictSelectorTracker.js).
  // This class is basically a glorified map, that allows us to track all selections without doing crazy object.prototype.has everywhere.
  // Under the hood, it is just an array of objects, but this will help us to keep those objects consistent while adding helpful utilities.
  const [selectorsState, setSelectorsState] = useState(new DistrictSelectorTracker());
  const [loading, setLoading] = useState(false);

  // Set up the selectors on component mount
  // Grab the states from the DB, and set up the first selector
  useEffect(async () => {
    // first, load the states from the database for the first picklist
    const statesList = await fetchUtil('/api/v1/district/states', 'GET', {});
    const formattedStatesList = statesList.map(s => ({ ...s, value: s.id }));
    const newSelectorTracker = new DistrictSelectorTracker();
    newSelectorTracker.addStatic('state', formattedStatesList, event => handleStateSelectorChangeRef.current(event), true, 'Choose a state*');
    setSelectorsState(newSelectorTracker);
  }, []);

  /**
   * Function that handles the first selector, state, being changed by the user. Includes a useRef below call in order to keep the state variables
   * up to date.
   * @param {*} event Event emmitted by the HTML element. Use event.target.value to access the id of the selected element.
   */
  const handleStateSelectorChange = async event => {
    const newSelectors = selectorsState.copy();
    if (newSelectors.checkSelection('state', event.target.value)) {
      // valid selection, reset to just state being selected
      newSelectors.reset();
      newSelectors.setSelection('state', event.target.value);
      newSelectors.show('state');

      if (newSelectors.isSelected('state')) {
        // office type is always next
        newSelectors.addDynamic(
          'district-type', // key
          changeEvent => handleOfficeTypeChangeRef.current(changeEvent), // onChange
          true, // isInScope
          { // dependency details
            key: 'district-type',
            label: 'Select a district type*',
            url: '/api/v1/district/gis-type-options',
            additionalContext: [{ selectorKey: 'state', urlParam: 'state_id' }],
          },
          true, // shouldUseGroupedOptions
        );

        setLoading(true);
        // fetch dynamic options immediately
        await newSelectors.fetchDynamicOptions('district-type');
        onChange({});
        setLoading(false);
      }

      setSelectorsState(newSelectors);
      onChange({});
      return;
    }

    // invalid selection, reset everything and just show blank state selector
    newSelectors.reset();
    newSelectors.show('state');
    setSelectorsState(newSelectors);
  };
  // keep function up to date everywhere we use it
  const handleStateSelectorChangeRef = useRef(handleStateSelectorChange);
  useEffect(() => {
    handleStateSelectorChangeRef.current = handleStateSelectorChange;
  });

  /**
   * Function that handles the second selector, office type, being changed by the user. Includes a useRef below call in order to keep the state variables
   * up to date.
   * @param {*} event Event emmitted by the HTML element. Use event.target.value to access the id of the selected element.
   */
  const handleOfficeTypeChange = async event => {
    const newSelectors = selectorsState.copy();
    if (newSelectors.checkSelection('district-type', event.target.value)) {
      // valid selection, first set it in our tracker
      newSelectors.setSelection('district-type', event.target.value);

      const fullDistrictSelectionObject = newSelectors.getSelectionFullObject('district-type');
      fullDistrictSelectionObject.state = newSelectors.getSelectionValue('state');

      onChange(fullDistrictSelectionObject);

      setSelectorsState(newSelectors);
      return;
    }

    // invalid selection
    newSelectors.setSelection('district-type', '');
    // hide all dependent selectors
    setSelectorsState(newSelectors);
  };

  const handleOfficeTypeChangeRef = useRef(handleOfficeTypeChange);
  useEffect(() => {
    handleOfficeTypeChangeRef.current = handleOfficeTypeChange;
  });

  /**
   * Function responsible for rendering all selectors found in the selectorsState state variable
   * @returns React component
   */
  const renderSelectors = () => (
    selectorsState.getVisible().map(selector => (
      <Grid item xs={6} key={selector.key}>
        <SelectControl
          className="ga-m-bottom--small"
          disabled={loading}
          key={selector.key}
          id={selector.key}
          label={selector.label}
          onChange={event => selector.onChange(event)}
          options={selector.options}
          shouldUseGroupedOptions={selector.shouldUseGroupedOptions}
          value={selector.value}
        />
      </Grid>
    ))
  );

  return (
    <div>
      <Grid container spacing={3}>
        {renderSelectors()}
      </Grid>
    </div>
  );
};

GISDistrictSetSelector.propTypes = {
  onChange: PropTypes.func, // Function called when a GIS set is selected by the user. Also called with an empty object when selection is cleared.
};

GISDistrictSetSelector.defaultProps = {
  onChange: () => {},
};

export default GISDistrictSetSelector;

