import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  linkButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
}));

export {
  useStyles,
};
