
import React from 'react';
import PropTypes from 'prop-types';
import PageHeader from 'components/PageHeader';
import { ThemeProvider } from '@material-ui/core';
import theme from 'scripts/theme';
import CandidateEventLog from 'components/Candidates/Partials/CandidateEventLog';
import GaUtils from 'helpers/GaUtils';

const CandidateEventLogContainer = (props) => {
  // Variables and constants
  const {
    candidate,
    candidateEventLog,
    candidateEmailsLog,
    messages,
    breadcrumbUrl,
  } = props;

  const getDescription = (logItem) => {
    let description = `Sent to ${logItem.email}`;
    if (logItem.read) {
      description += `, Read on ${GaUtils.getDateFormatted(logItem.read)}`;
    }
    return description;
  };

  const getLogEvents = () => {
    const formattedEmailLog = candidateEmailsLog.map((item) => (
      {
        id: item.id,
        date: item.sent,
        eventType: { code: item.type.id, name: item.type.name },
        description: getDescription(item),
      }));

    return candidateEventLog.concat(formattedEmailLog);
  };

  // hooks
  // Functions
  // Render
  return (
    <ThemeProvider theme={theme}>
      <PageHeader
        heading={messages.title}
        breadcrumbText={messages.breadcrumbText}
        breadcrumbUrl={breadcrumbUrl}
      />

      <div className="ga-container">
        <div className="mdc-layout-grid">
          <CandidateEventLog
            candidateFullName={candidate.name != '' ? candidate.name : 'NO NAME'}
            // candidateEventLog={candidateEventLog}
            candidateEventLog={getLogEvents()}
            messages={messages.eventLogGrid}
          ></CandidateEventLog>
        </div>
      </div>
    </ThemeProvider>
  );
};

CandidateEventLogContainer.propTypes = {
  candidate: PropTypes.object,
  candidateEventLog: PropTypes.array,
  candidateEmailsLog: PropTypes.array,
  messages: PropTypes.object,
  breadcrumbUrl: PropTypes.string,
};

CandidateEventLogContainer.defaultProps = {
  candidate: {},
  candidateEventLog: [],
  candidateEmailsLog: [],
  messages: {},
  breadcrumbUrl: '',
};

export { CandidateEventLogContainer };
