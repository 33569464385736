import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles, ThemeProvider } from '@material-ui/styles';
import theme from 'scripts/theme';
import PageHeader from 'components/PageHeader';
import PageActionsHeader from 'components/PageActionsHeader';
import FormFieldRenderer from 'components/FormFieldRenderer';
import DistrictSelectorContainer from 'components/Districts/Partials/DistrictSelectorContainer';
import ConfirmationDialog from 'components/ConfirmationDialog';
import SnackbarAlert from 'components/SnackbarAlert';
import DistrictSelectorTracker from 'helpers/DistrictSelectorTracker';
import fetchUtil from 'helpers/Fetch';

const styles = makeStyles({
  spinner: {
    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  rule: {
    margin: '2rem 0',
  },
  formStepTitle: {
    margin: '2rem 0 1rem 0',
  },
  removeButton: {
    marginRight: '1rem',
    flexShrink: 0,
  },
  danger: {
    color: '#ac1b3d',
    '&:hover': {
      color: '#6B1126',
    },
  },
  selectedFlex: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: '.75rem',
  },
  breadcrumbs: {
    paddingTop: '0.45rem',
  },
});

const CompositeDistrictContainer = ({
  breadcrumbUrl,
  messages,
  guideId,
  district,
}) => {
  const classes = styles();
  const _snackbar = useRef();

  const [districtName, setDistrictName] = useState('');
  const [currentDistrict, setCurrentDistrict] = useState(null);
  const [allDistricts, setAllDistricts] = useState([]);
  // below: add set methods when applicable
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteSubmitting, setDeleteSubmitting] = useState(false);

  // Remember if we are on the edit page or the create page
  // useMemo(() => computedValue to store in isCreate, [array, of, dependencies])
  const isCreate = useMemo(() => ((district == null || typeof district == 'undefined')), [district]);

  const nameField = [{
    id: 'name',
    value: districtName,
    handleValueChange: (ev) => setDistrictName(ev.target.value),
    label: messages.nameSection.label,
    help: messages.nameSection.help,
    required: true,
    type: 'text',
    width: 'half',
  }];

  const handleConfirmDelete = () => {
    setDeleteSubmitting(true);

    fetchUtil(`/api/v1/guide/${guideId}/district/composite/${district.id}`, 'DELETE')
      .then(() => {
        // Redirect user to the district dashboard and show a snackbar notification
        window.location.href = '/districts?showSuccessMsgFrom=deleteCompositeDistrict';
      })
      .catch(error => {
        const status = error?.status;
        console.error(error);

        setDeleteSubmitting(false);

        if (status && status == 403) {
          _snackbar.current.show('error', 'Only guide admins are allowed to delete Composite Districts. Contact your guide admin for assistance.');
          return;
        }

        if (error && error.data) {
          _snackbar.current.show('error', error.data);
          return;
        }

        _snackbar.current.show('error', 'There was an issue deleting your Composite Districts. Try again or contact your guide admin if this continues.');
      });
  };

  const handleAddDistrict = () => {
    const existingInList = allDistricts.find(d => d.id === currentDistrict.id);
    if (typeof existingInList === 'undefined') {
      const allDistrictsClone = [...allDistricts];
      allDistrictsClone.push(currentDistrict);
      setAllDistricts(allDistrictsClone);
      return;
    }

    _snackbar.current.show('error', messages.duplicateError);
  };

  const handleRemoveDistrict = (id) => {
    const allDistrictsClone = [...allDistricts].filter(d => d.id !== id);
    setAllDistricts(allDistrictsClone);
  };

  // on component mount
  useEffect(async () => {
    if (!isCreate) {
      // means we are editing the district, so set the district name and the children (with breadcrumbs)
      setDistrictName(district.name);

      if (typeof district.children != 'undefined') {
        // we have children, so fetch the full object for each (needed for breadcrumbs)
        // await all async calls that are in the map
        const childrenWithBreadcrumbs = await Promise.all(district.children.map(async childDistrict => {
          let fullDistrictFromDefault;
          if (childDistrict.type.id == 'GIS') {
            fullDistrictFromDefault = await fetchUtil(`/api/v1/guide/${guideId}/district/gis/district/${childDistrict.id}`, 'GET', {});
          } else if (childDistrict.type.id == 'DSM') {
            fullDistrictFromDefault = await fetchUtil(`/api/v1/guide/${guideId}/district/set/district/${childDistrict.id}`, 'GET', {});
          } else {
            fullDistrictFromDefault = await fetchUtil(`/api/v1/guide/${guideId}/district/${childDistrict.id}`, 'GET');
          }
          return DistrictSelectorTracker.createBreadcrumbsFromDistrictObject(fullDistrictFromDefault);
        }));
        setAllDistricts(childrenWithBreadcrumbs);
        // setAllDistricts(district.children.map(childDistrict => DistrictSelectorTracker.createBreadcrumbsFromDistrictObject(childDistrict)));
      }
    }
  }, []);

  const handleCurrentDistrictChange = (newDistrict) => {
    // make sure we are only doing the breadcrumbs when the district object is actually populated
    if (newDistrict != null && newDistrict.id) {
      const districtWithBreadcrumbs = DistrictSelectorTracker.createBreadcrumbsFromDistrictObject(newDistrict);
      setCurrentDistrict(districtWithBreadcrumbs);
    } else {
      setCurrentDistrict(newDistrict);
    }
  };

  const handleCancel = () => {
    setOpenCancelDialog(true);
  };

  const confirmCancelDialog = () => {
    window.location.replace('/districts');
  };

  // save updates or create new, depending on whether this is create or edit
  const handleSave = async () => {
    setSubmitting(true);
    if (isCreate) {
      // create
      try {
        const URL = `/api/v1/guide/${guideId}/district/composite`;
        const METHOD = 'POST';
        const BODY = {
          name: districtName,
          children: allDistricts,
        };

        await fetchUtil(URL, METHOD, BODY);

        setSubmitting(false);
        window.location.replace('/districts?showSuccessMsgFrom=createcomposite');
      } catch (e) {
        console.error(e);
        _snackbar.current.show('error', messages.saveError);
        setErrors(e);
        setSubmitting(false);
      }
    } else {
      // edit
      try {
        const URL = `/api/v1/guide/${guideId}/district/composite/${district.id}`;
        const METHOD = 'PUT';
        const BODY = {
          name: districtName,
          children: allDistricts,
        };

        await fetchUtil(URL, METHOD, BODY);

        setSubmitting(false);
        window.location.replace('/districts?showSuccessMsgFrom=editcomposite');
      } catch (e) {
        console.error(e);
        _snackbar.current.show('error', messages.saveError);
        setErrors(e);
        setSubmitting(false);
      }
    }
  };

  const isCircular = () => (
    currentDistrict && currentDistrict.id && district && district.id && currentDistrict.id === district.id
  );

  return (
    <ThemeProvider theme={theme}>
      <ConfirmationDialog
        cancelButtonText={messages.cancelDialog.cancel}
        confirmButtonText={messages.cancelDialog.confirm}
        heading={messages.cancelDialog.heading}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => confirmCancelDialog()}
        open={openCancelDialog}
      >
        <Typography component="p" variant="body1">
          {messages.cancelDialog.body}
        </Typography>
      </ConfirmationDialog>

      <ConfirmationDialog
        heading={`Delete ${district?.name}`}
        onCancel={() => setShowDeleteModal(false)}
        onConfirm={handleConfirmDelete}
        open={showDeleteModal}
        submitting={deleteSubmitting}
      >
        <Typography component="p" variant="body1">
          Are you sure you want to <strong>permanently delete</strong> this district? Any race currently using this district will have no district assigned to it.
        </Typography>
      </ConfirmationDialog>

      <SnackbarAlert ref={_snackbar} />

      <PageHeader
        breadcrumbText={messages.breadcrumb}
        breadcrumbUrl={breadcrumbUrl}
        heading={messages.title}
        subheading={messages.supporting}
      />

      <PageActionsHeader>
        <Button
          color="primary"
          variant="text"
          onClick={handleCancel}
        >
          {messages.cancel}
        </Button>

        {!isCreate && (
          <Button
            className={classes.danger}
            onClick={() => setShowDeleteModal(true)}
            size="small"
          >
            Delete
          </Button>
        )}

        <Button
          color="secondary"
          variant="contained"
          onClick={handleSave}
        >
          {messages.save}
        </Button>
      </PageActionsHeader>

      <div className="ga-container">
        <div className="mdc-layout-grid">
          <div className="mdc-layout-grid__inner">
            <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
              <Typography variant="h3" component="h2" paragraph>
                {messages.nameSection.title}
              </Typography>

              <FormFieldRenderer
                submitting={submitting}
                errors={errors}
                fields={nameField}
              />

              <hr className={classes.rule} />

              <Typography variant="h3" component="h2" paragraph>
                {messages.districtSection.title}
              </Typography>

              <Grid container>
                <Grid item xs={12} sm={6}>
                  <DistrictSelectorContainer
                    guideId={guideId}
                    onChange={handleCurrentDistrictChange}
                    district={currentDistrict}
                  />

                  {isCircular() && (
                    <Typography variant="body1" color="error" component="p">
                      Composite districts cannot contain themselves
                    </Typography>
                  )}

                  <Box marginTop={1} marginBottom={2}>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={handleAddDistrict}
                      disabled={currentDistrict == null || !currentDistrict.id || isCircular() || submitting}
                    >
                      {messages.districtSection.add}
                    </Button>
                  </Box>

                  <Box>
                    <Typography variant="h4" component="h3" paragraph>
                      {messages.districtSection.list}
                    </Typography>

                    {allDistricts.map(d => (
                      <div key={d.id} className={classes.selectedFlex}>
                        <Button
                          color="primary"
                          variant="outlined"
                          onClick={() => handleRemoveDistrict(d.id)}
                          className={classes.removeButton}
                          disabled={submitting}
                        >
                          {messages.districtSection.remove}
                        </Button>
                        <Typography variant="h5" component="p" className={classes.breadcrumbs}>
                          {d.breadcrumbs}
                        </Typography>
                      </div>
                    ))}
                  </Box>
                </Grid>
              </Grid>

            </div>
          </div>
        </div>
      </div>

      <PageActionsHeader>
        <Button
          color="primary"
          variant="text"
          onClick={handleCancel}
        >
          {messages.cancel}
        </Button>

        {!isCreate && (
          <Button
            className={classes.danger}
            onClick={() => setShowDeleteModal(true)}
          >
            Delete
          </Button>
        )}

        <Button
          color="secondary"
          variant="contained"
          onClick={handleSave}
        >
          {messages.save}
        </Button>
      </PageActionsHeader>
    </ThemeProvider>
  );
};

CompositeDistrictContainer.propTypes = {
  breadcrumbUrl: PropTypes.string.isRequired,
  district: PropTypes.object, // we will explicitly check for null or undefined, so no default
  guideId: PropTypes.string.isRequired,
  messages: PropTypes.shape({
    title: PropTypes.string,
    supporting: PropTypes.string,
    save: PropTypes.string,
    cancel: PropTypes.string,
    breadcrumb: PropTypes.string,
    saveError: PropTypes.string,
    duplicateError: PropTypes.string,
    nameSection: PropTypes.shape({
      title: PropTypes.string,
      label: PropTypes.string,
      help: PropTypes.string,
    }),
    districtSection: PropTypes.shape({
      title: PropTypes.string,
      state: PropTypes.string,
      containing: PropTypes.string,
      add: PropTypes.string,
      list: PropTypes.string,
      remove: PropTypes.string,
    }),
    cancelDialog: PropTypes.shape({
      cancel: PropTypes.string,
      confirm: PropTypes.string,
      body: PropTypes.string,
      heading: PropTypes.string,
    }),
  }).isRequired,
};

CompositeDistrictContainer.defaultProps = {
  messages: {
    title: 'Creating a Composite District*',
    supporting: 'Combine your GIS Data and Districts to create a larger, custom district*',
    save: 'Save*',
    cancel: 'Cancel*',
    breadcrumb: 'District Dashboard*',
    nameSection: {
      title: '1. Set Information*',
      label: 'Composite District Name*',
      help: 'ie: New Jersey school districts*',
    },
    districtSection: {
      title: '2. Add your districts*',
      leftCountLabel: 'Districts Selected*',
      leftHeader: 'Available districts*',
      rightCountLabel: 'Districts Selected*',
      rightHeader: 'Selected districts*',
      state: 'Choose a state*',
      containing: 'Choose a containing district*',
    },
  },
};

export default CompositeDistrictContainer;
