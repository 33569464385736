import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import {
  Button,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Card,
  CardContent,
  Divider,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BioQuestionSetAccordionRow from 'components/BioQuestions/BioQuestionSetAccordionRow';
import theme from 'scripts/theme';
import { useReorderParent } from 'hooks/useReorder';

const useStyles = makeStyles(() => ({
  accordionHeading: {
    fontWeight: 'bold',
  },
  accordionContent: {
    overflow: 'hidden',
  },
  actionBar: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: theme.spacing(2),
  },
  accordionRow: {
    borderBottom: '1px solid #e5e5e5',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  questionEmptyStateContainer: {
    backgroundColor: '#ededed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.4rem',
    color: '#999',
    padding: '2.4rem 1rem',
  },
  questionEmptyState: {
    textAlign: 'center',
  },
  actionButton: {
    minWidth: '75px',
  },
  destructiveButton: {
    marginRight: '1rem',
    minWidth: '75px',
    color: '#ac1b3d',
    '&:hover': {
      color: '#6B1126',
    },
  },
}));

const Accordion = withStyles({
  root: {
    borderWidth: '0 0 1px 0',
    borderColor: '#e5e5e5',
    borderRadius: '0',
    borderStyle: 'solid',
    boxShadow: 'none',
    '&:last-child': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const BioQuestionSetAccordion = ({
  addBioQuestionsPlaceholder,
  addButtonText,
  bioSet,
  deleteButtonText,
  editButtonText,
  guideId,
  id,
  name,
  onAddQuestionToSet,
  onDelete,
  onEdit,
  onError,
  onRemoveQuestion,
  questions,
  removeFromSetButtonText,
}) => {
  const classes = useStyles();
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [availableQuestions, setAvailableQuestions] = useState([]);
  const [assignedQuestions, setAssignedQuestions] = useState([]);

  const {
    onReorder,
    moveItem: moveQuestion,
    disabled,
    setOriginalQuestions,
    onFailedDrop,
  } = useReorderParent({
    dataList: assignedQuestions,
    setDataList: setAssignedQuestions,
    originalStateName: 'originalQuestions',
    apiEndpoint: `/api/v1/guide/${guideId}/bioquestions/set/${bioSet.id}/reorder`,
    apiParamName: 'questions',
    showError: () => onError('There was an issue trying to reorder your question. Try again or contact your guide admin if this continues'),
  });

  useEffect(() => {
    let newQuestions = [];

    // Sort bio questions by index
    if (bioSet && Object.prototype.hasOwnProperty.call(bioSet, 'bioQuestions')) {
      newQuestions = bioSet.bioQuestions.slice().sort((a, b) => a.index - b.index);
    }

    setAssignedQuestions(newQuestions);
    setOriginalQuestions(newQuestions);
  }, [bioSet]);

  useEffect(() => {
    const available = questions.slice().filter(question => {
      const questionIndex = bioSet.bioQuestions.findIndex(assigned => assigned.id === question.id);
      return questionIndex < 0;
    });

    setAvailableQuestions(available);
  }, [bioSet, questions]);

  const handleAddQuestion = () => {
    onAddQuestionToSet(bioSet, selectedQuestion);
    setSelectedQuestion(null);
  };

  return (
    <Accordion>
      <AccordionSummary
        aria-controls={id}
        expandIcon={
          <ExpandMoreIcon />
        }
        id={id}
      >
        <Typography className={classes.accordionHeading} component="h3" variant="h4">
          {name}
        </Typography>
      </AccordionSummary>

      <AccordionDetails>
        <Grid container justifyContent="flex-start" alignItems="flex-start" spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                  <Grid item xs={10}>
                    <Autocomplete
                      disablePortal
                      disabled={disabled}
                      getOptionLabel={(opt) => opt.name || ''}
                      getOptionSelected={(opt, val) => opt.id === val.id}
                      onChange={(event, newValue) => setSelectedQuestion(newValue)}
                      options={availableQuestions}
                      renderInput={(params) => <TextField {...params} label={addBioQuestionsPlaceholder} variant="filled" />}
                      value={selectedQuestion}
                    />
                  </Grid>

                  <Grid item xs={2}>
                    {selectedQuestion && <Button
                      color="secondary"
                      disabled={disabled}
                      onClick={handleAddQuestion}
                      size="large"
                      variant="outlined"
                    >
                      {addButtonText}
                    </Button>}
                  </Grid>
                  <Grid item></Grid>
                </Grid>

                <Grid container justifyContent="flex-start" className="ga-mt-16">
                  <Grid item xs={12}>
                    {assignedQuestions && assignedQuestions.map((row, idx) => (
                      <BioQuestionSetAccordionRow
                        key={row.id}
                        className={classes.accordionRow}
                        disabled={disabled}
                        isDefault={row.default}
                        id={row.id}
                        index={idx}
                        moveQuestion={moveQuestion}
                        name={row.name}
                        onDelete={() => onRemoveQuestion(bioSet, row.id)}
                        onReorder={onReorder}
                        onFailedDrop={onFailedDrop}
                        removeText={removeFromSetButtonText}
                      />
                    ))}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid container justifyContent="flex-end" alignItems="center">
            <Grid item>
              <Button
                className={classes.destructiveButton}
                color="secondary"
                size="medium"
                onClick={() => onDelete(id, name)}
                variant="text"
              >
                {deleteButtonText}
              </Button>

              <Button
                className={classes.actionButton}
                color="primary"
                size="medium"
                onClick={() => onEdit(id, name)}
                variant="text"
              >
                {editButtonText}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

BioQuestionSetAccordion.propTypes = {
  addBioQuestionsPlaceholder: PropTypes.string,
  addButtonText: PropTypes.string,
  bioSet: PropTypes.object,
  deleteButtonText: PropTypes.string,
  editButtonText: PropTypes.string,
  guideId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  onAddQuestionToSet: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onError: PropTypes.func,
  onRemoveQuestion: PropTypes.func,
  questions: PropTypes.arrayOf(PropTypes.shape({
    default: PropTypes.bool,
    id: PropTypes.number,
    index: PropTypes.number,
    maxl: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.string,
  })),
  removeFromSetButtonText: PropTypes.string,
};

BioQuestionSetAccordion.defaultProps = {
  addBioQuestionsPlaceholder: 'Add more existing biographical questions',
  addButtonText: 'Add',
  bioSet: {},
  deleteButtonText: 'Delete',
  editButtonText: 'Update',
  onAddQuestionToSet: () => {},
  onDelete: () => {},
  onEdit: () => {},
  onError: () => {},
  onRemoveQuestion: () => {},
  questions: [],
  removeFromSetButtonText: 'Remove from set',
};

export default BioQuestionSetAccordion;
