import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    color: '#212121',
    fontSize: '16px',
  },
}));

const CandidateMetadataValue = ({ children }) => {
  const classes = useStyles();

  return (
    <Typography component="span" className={classes.root}>
      {children}
    </Typography>
  );
};

CandidateMetadataValue.propTypes = {
  children: PropTypes.node,
};

CandidateMetadataValue.defaultProps = {
  children: null,
};

export default CandidateMetadataValue;
