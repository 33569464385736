import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  Grid,
} from '@material-ui/core';

const styles = () => ({
  addFormContainer: {
    flexGrow: 1,
    display: 'flex',
    margin: '1rem',
  },
  addFormActions: {
    backgroundColor: '#eef4f8',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '.8rem 2rem',
  },
  fields: {
    marginBottom: '1.875em',
  },
  toggle: {
    justifyContent: 'center',
  },
});

class BioQuestionForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  getFormValue(event) {
    if (event.target.name === 'edit' || event.target.name === 'isVisible') {
      return event.target.checked;
    }

    return event.target.value;
  }

  handleInputChange(event) {
    const { name } = event.target;
    const value = this.getFormValue(event);
    const { onChange } = this.props;
    onChange(name, value);
  }

  handleSubmit() {
    const {
      id,
      charLimit,
      edit,
      name,
      type,
      onSubmit,
      isVisible,
    } = this.props;

    onSubmit({
      charLimit,
      edit,
      id,
      name,
      type,
      isVisible,
    });
  }

  hasError(fieldName) {
    const { errors } = this.props;
    return Object.prototype.hasOwnProperty.call(errors, fieldName);
  }

  render() {
    const {
      buttonText,
      classes,
      charLimit,
      edit,
      errors,
      name,
      isVisible,
      requiredText,
      submitting,
      type,
      typeOptions,
    } = this.props;

    return (
      <Card variant="outlined">
        <CardContent className={classes.addFormContainer}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl
                className={classes.fields}
                error={this.hasError('type')}
                disabled={submitting}
                fullWidth
                required
                variant="filled"
              >
                <InputLabel id="bioquestion-add-type-label">
                  Biographical Question Type
                </InputLabel>

                <Select
                  id="bioquestion-add-type-select"
                  error={this.hasError('type')}
                  name="type"
                  value={type}
                  onChange={this.handleInputChange}
                >
                  {typeOptions.map((option, index) => (
                    <MenuItem key={index} value={option.value}>{option.name}</MenuItem>
                  ))}
                </Select>
                <FormHelperText>{this.hasError('type') ? errors.type : ''}</FormHelperText>
              </FormControl>
              <FormControl fullWidth className={classes.fields}>
                <TextField
                  id="bioquestion-add-name"
                  disabled={submitting}
                  error={this.hasError('name')}
                  helperText={this.hasError('name') ? errors.name : ''}
                  label="Biographical Question Title"
                  name="name"
                  required
                  variant="filled"
                  onChange={this.handleInputChange}
                  value={name}
                />
              </FormControl>
              <FormControl fullWidth className={classes.fields}>
                <TextField
                  id="bioquestion-add-charLimit"
                  disabled={submitting}
                  error={this.hasError('charLimit')}
                  helperText={this.hasError('charLimit') ? errors.charLimit : ''}
                  label="Answer Character Limits"
                  type="number"
                  required
                  name="charLimit"
                  variant="filled"
                  onChange={this.handleInputChange}
                  value={charLimit}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormGroup className={classes.fields}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={edit}
                      color="secondary"
                      disabled={submitting}
                      name="edit"
                      onChange={this.handleInputChange}
                    />
                  }
                  label="Allow the candidate to edit their bio question answer"
                />
                <FormHelperText>(Right for yes, Left for no)</FormHelperText>
              </FormGroup>

              <FormGroup className={classes.fields}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isVisible}
                      color="secondary"
                      disabled={submitting}
                      name="isVisible"
                      onChange={this.handleInputChange}
                    />
                  }
                  label="Show this bio question to the public"
                />
                <FormHelperText>(Right for yes, Left for no)</FormHelperText>
              </FormGroup>
            </Grid>
          </Grid>
        </CardContent>

        <CardActions className={classes.addFormActions}>
          <span>{requiredText}</span>

          <Button
            color="secondary"
            disabled={submitting}
            onClick={this.handleSubmit}
            size="large"
            variant="contained"
          >
            {buttonText}
          </Button>
        </CardActions>
      </Card>
    );
  }
}

BioQuestionForm.propTypes = {
  buttonText: PropTypes.string,
  charLimit: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  classes: PropTypes.object,
  edit: PropTypes.bool,
  errors: PropTypes.object,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  requiredText: PropTypes.string,
  submitting: PropTypes.bool,
  type: PropTypes.string,
  isVisible: PropTypes.bool,
  typeOptions: PropTypes.array,
};

BioQuestionForm.defaultProps = {
  buttonText: 'Add Biographical Questions',
  charLimit: 750,
  edit: true,
  errors: {},
  id: '',
  name: '',
  onChange: () => {},
  onSubmit: () => {},
  requiredText: '*Required fields needed to add a new biographical question',
  submitting: false,
  type: 'Text',
  isVisible: true,
  typeOptions: [],
};

export default withStyles(styles)(BioQuestionForm);
