import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import RadioCardList from 'components/RadioCardList';

const useStyles = makeStyles({
  container: {
    borderBottom: '1px solid rgba(0,0,0,0.1)',
  },
});

const TypeOfRace = ({
  messages,
  raceTypes,
  setTypeOfRace,
  typeOfRace,
}) => {
  const classes = useStyles();

  const handleChange = newValue => {
    setTypeOfRace(newValue);
  };

  return (
    <Box paddingBottom={5} marginBottom={5} className={classes.container}>
      <Typography variant="h2" component="h3" paragraph>
        {messages.title}
      </Typography>

      <RadioCardList
        cards={raceTypes}
        checked={typeOfRace}
        onChange={handleChange}
      />
    </Box>
  );
};

TypeOfRace.propTypes = {
  typeOfRace: PropTypes.number.isRequired,
  setTypeOfRace: PropTypes.func.isRequired,
  raceTypes: PropTypes.array.isRequired,
  messages: PropTypes.object.isRequired,
};

export default TypeOfRace;
