import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardActionArea,
  CardContent,
  IconButton,
  Radio,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles({
  card: {
    height: '100%',
    backgroundColor: '#fafafa',
    borderColor: 'transparent',
  },
  cardDeletable: {
    position: 'relative',
  },
  cardChecked: {
    borderColor: '#018466',
  },
  cardActionArea: {
    height: '100%',
  },
  cardContent: {
    position: 'relative',
    height: 'inherit',
  },
  heading: {
    margin: '0 0 8px',
    width: '90%',
    wordBreak: 'break-word',
  },
  radio: {
    position: 'absolute',
    top: '0',
    right: '0',
  },
  delete: {
    position: 'absolute',
    bottom: '0',
    right: '0',
    color: 'red',
    padding: '9px',
  },
});

function RadioCard({
  checked,
  deletable,
  description,
  disabled,
  id,
  onClick,
  onDelete,
  title,
}) {
  const classes = useStyles();

  const handleClick = () => {
    if (!disabled) {
      onClick(id);
    }
  };

  const handleDelete = (e) => {
    if (!disabled) {
      e.stopPropagation();
      onDelete(id);
    }
  };

  const getCardClasses = () => {
    const cardClasses = [classes.card];

    if (checked) {
      cardClasses.push(classes.cardChecked);
      cardClasses.push('radio-card-checked');
    }

    if (deletable) {
      cardClasses.push(classes.cardDeletable);
    }

    return cardClasses.join(' ');
  };

  return (
    <Card
      className={getCardClasses()}
      onClick={handleClick}
      variant="outlined"
      data-testid="radio-card"
    >
      <CardActionArea disableRipple className={classes.cardActionArea}>
        <CardContent className={classes.cardContent}>
          <Typography className={classes.heading} component="h4" variant="h4">
            {title}
          </Typography>

          <Radio
            checked={checked}
            classes={{ root: classes.radio }}
            disabled={disabled}
            disableRipple
          />

          <Typography color="textSecondary" component="p" variant="body2">
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>

      {deletable && (
        <IconButton
          className={classes.delete}
          onClick={handleDelete}
          aria-label="delete"
          size="medium"
        >
          <CloseIcon />
        </IconButton>
      )}
    </Card>
  );
}

RadioCard.propTypes = {
  checked: PropTypes.bool.isRequired,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  deletable: PropTypes.bool,
  onDelete: PropTypes.func,
};

RadioCard.defaultProps = {
  deletable: false,
  disabled: false,
  onClick: () => {},
  onDelete: () => {},
};

export default RadioCard;
