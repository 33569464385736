import React, { useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Grid,
  ThemeProvider,
} from '@material-ui/core';

import fetchUtil from 'helpers/Fetch';
import { CAMPAIGN_FINANCE as CAMPAIGN_FINANCE_SUCCESS_PARAM, SUCCESS_PARAM } from 'helpers/SuccessMessageParams';
import SnackbarAlert from 'components/SnackbarAlert';
import PageHeader from 'components/PageHeader';
import GAContainer from 'components/Layout/GAContainer';
import UploadCard from 'components/UploadCard';
import PageActionsHeader from 'components/PageActionsHeader';
import PageActionsFooter from 'components/PageActionsFooter';

import theme from 'scripts/theme';

import { CampaignFinanceImportBody } from '@/features/campaign-finance';

export const CampaignFinanceImportContainer = ({ messages, isTest }) => {
  const _snackbarRef = useRef(null);

  const [file, setFile] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  /** @var {boolean} enableImport */
  const enableImport = useMemo(() => file && file.length > 0, [file]);

  const showError = (message) => {
    _snackbarRef.current.show('error', message);
  };

  const handleCsvFile = (csvFile) => {
    if (csvFile.length > 0) {
      setFile(csvFile);
    }
  };

  /**
   * Reroute to the previous GA page, or /campaign-finance if there is no referrer (aka came from outside GA)
   */
  const handleRerouteToPrevious = (showSuccess = true) => {
    if (isTest) {
      return;
    }

    if (document.referrer) {
      const referrerURL = new URL(document.referrer);
      if (showSuccess) {
        referrerURL.searchParams.append(SUCCESS_PARAM, CAMPAIGN_FINANCE_SUCCESS_PARAM);
      }
      window.location.href = referrerURL.toString();
    } else {
      if (showSuccess) {
        window.location.href = `/campaign-finance?${SUCCESS_PARAM}=${CAMPAIGN_FINANCE_SUCCESS_PARAM}`;
      }
      window.location.href = '/campaign-finance';
    }
  };

  const handleSave = () => {
    const urlRequest = '/api/v1/campaign-finances/import';
    const data = new FormData();
    data.append('file', file[0], file[0].name);

    setSubmitting(true);
    fetchUtil(urlRequest, 'POST', data, false, true)
      .then(() => {
        setSubmitting(false);
        // navigation is not implemented by jest
        handleRerouteToPrevious();
      })
      .catch((error) => {
        setSubmitting(false);
        console.error(error);
        showError(messages.import.results.error);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <SnackbarAlert ref={_snackbarRef} />

      <PageHeader
        heading={messages.heading}
        subheading={messages.subheading}
      />

      <PageActionsHeader>
        <Button
          color="secondary"
          variant="contained"
          onClick={handleSave}
          disabled={!enableImport}
        >
          {messages.import.actions.importButton}
        </Button>
      </PageActionsHeader>

      <GAContainer>
        <CampaignFinanceImportBody messages={messages} />

        <Box marginBottom={4}>
          <Grid
            container
            justifyContent="center"
          >
            <Grid item>
              <UploadCard
                acceptedFileTypes=".csv"
                buttonText={messages.import.actions.attachButton}
                disabled={submitting}
                onChange={handleCsvFile}
                text={messages.import.actions.title}
              />
            </Grid>
          </Grid>
        </Box>
      </GAContainer>

      <PageActionsFooter>
        {/* TODO: make this go to previous page */}
        <Button
          color="primary"
          variant="text"
          onClick={() => handleRerouteToPrevious(false)}
        >
          {messages.import.actions.cancelButton}
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={handleSave}
          disabled={!enableImport}
        >
          {messages.import.actions.importButton}
        </Button>
      </PageActionsFooter>
    </ThemeProvider>
  );
};

CampaignFinanceImportContainer.propTypes = {
  messages: PropTypes.object,
  isTest: PropTypes.bool,
};

CampaignFinanceImportContainer.defaultProps = {
  messages: {
    heading: 'Import Campaign Finance Data',
    subheading:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed posuere accumsan neque iaculis congue.',
    intro: {
      title: 'Getting started with campaign finance data',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed posuere accumsan neque iaculis congue.',
    },
    import: {
      instructions: {
        title: 'Import data',
        description:
          'Add your spreadsheet by clicking the "Attach" button to choose your file. Once it has been attached, click the "Start Import" button to add your campaign finance data.',
      },
      actions: {
        title: 'Attach your finance data here (.csv)',
        attachButton: 'Attach',
        cancelButton: 'Cancel',
        importButton: 'Start Import',
        sucessTitle: 'File ready to upload!',
      },
      results: {
        error: 'There was an error uploading the file. Please try again.',
      },
    },
  },
  isTest: false,
};
