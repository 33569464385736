import fetchUtil from 'helpers/Fetch';

const SELECTOR_OPTIONS_TYPE = {
  STATIC: 1,
  DYNAMIC: 2,
};

// empty string represents district.name
// all other strings represent district.string.name
// 2-d arrays represent district.string[0].string[1].<etc>.name
// used for breadcrumb generation
const DISCRIMINATOR_TO_ORDER_MAP = {
  S: ['', 'type'],
  U: ['state', 'type', ''],
  C: ['state', 'type', ''],
  L: ['state', 'type', ''],
  H: ['state', 'type', ''],
  Z: ['type', ''],
  O: ['type', ''],
  G: [['geoset', 'containingDistrict'], 'geoset', ''],
  M: [['dset', 'votingDistrict'], 'dset', ''],
};

// Class to manage district selector dependent picklists
export default class DistrictSelectorTracker {
  selectors = new Map();

  /**
   * Copy the current instance so that it can be modified separate from react state
   * @returns {DistrictSelectorTracker} Copy of the current instance.
   */
  copy() {
    const newObject = new DistrictSelectorTracker();
    this.selectors.forEach((value, key) => {
      newObject.addFromPrevious(key, { ...value });
    });
    return newObject;
  }

  /**
   * Helper method for copy() to add a new record to the map without needed to destructure
   * @param {String} key Map key
   * @param {*} obj Map value, see addStatic and addDynamic for what that might look like
   */
  addFromPrevious(key, obj) {
    this.selectors.set(key, obj);
  }

  /**
   * Add a static selector, meaning we don't ever re-fetch the options
   * @param {String} key Map key
   * @param {Array} options Array of options for the selector, needs to have id, value, and name properties for each record
   * @param {func} onChange Function that will be called onChange of the selector
   * @param {bool} isInScope Controls whether or not this selector will be displayed to the user. Mostly used for the office types.
   * @param {String} label Label for the selector that the user sees
   * @param {bool} shouldUseGroupedOptions Controls whether or not the selector will use a data structure that contains option groups or not
   */
  addStatic(key, options, onChange, isInScope, label, shouldUseGroupedOptions = false) {
    this.selectors.set(key, {
      key,
      options,
      onChange,
      isInScope,
      label,
      value: '',
      fullValue: {},
      shouldUseGroupedOptions,
      type: SELECTOR_OPTIONS_TYPE.STATIC,
    });
  }

  /**
   * Add a dynamic selector. This is one that will have the options fetched when it is displayed to the user.
   * @param {Array} options Array of options for the selector, needs to have id, value, and name properties for each record
   * @param {func} onChange Function that will be called onChange of the selector
   * @param {bool} isInScope Controls whether or not this selector will be displayed to the user. Mostly used for the office types.
   * @param {Object} dependency Dependent picklist details, needs url, label, additionalContext, and type properties.
   * @param {bool} shouldUseGroupedOptions Controls whether or not the selector will use a data structure that contains option groups or not
   */
  addDynamic(key, onChange, isInScope, dependency, shouldUseGroupedOptions = false) {
    this.selectors.set(key, {
      key,
      onChange,
      isInScope,
      options: [],
      value: '',
      fullValue: {},
      label: dependency.label,
      type: SELECTOR_OPTIONS_TYPE.DYNAMIC,
      url: dependency.url,
      additionalContext: dependency.additionalContext,
      districtTypeChar: dependency.type,
      shouldUseGroupedOptions,
    });
  }

  // Remove the selector with the given key
  remove(key) {
    this.selectors.delete(key);
  }

  // Show the selector with the given key by moving it in scope
  show(key) {
    if (this.selectors.has(key)) {
      this.selectors.get(key).isInScope = true;
    }
  }

  // Hide the selector with the given key
  hide(key) {
    if (this.selectors.has(key)) {
      this.selectors.get(key).isInScope = false;
    }
  }

  // Remove all keys in the given array
  hideAllKeys(keyArr) {
    keyArr.forEach(key => {
      this.remove(key);
    });
  }

  // Hide and clear the values from all selectors. Used when the state is changed.
  reset() {
    this.selectors.forEach((selector, key) => {
      selector.isInScope = false;
      selector.value = '';
      selector.fullValue = {};
      this.selectors.set(key, selector);
    });
  }

  // Update the right selector with the users selection
  // value should be an id
  setSelection(key, value) {
    if (this.checkSelection(key, value)) {
      const oldSelector = this.selectors.get(key);
      if (oldSelector) {
        oldSelector.value = value;
        if (this.selectors.get(key).shouldUseGroupedOptions) {
          // grouped options
          let foundOption = null;
          // yes this isn't perfectly efficient because it doesn't break, but we have a very set number of district types so I'm not
          // worried about it getting out of control unless something big changes
          oldSelector.options.forEach(optionGroup => {
            const search = optionGroup.options.find(o => o.id === value);
            if (typeof search != 'undefined') {
              foundOption = search;
            }
          });
          oldSelector.fullValue = foundOption;
        } else {
          // simple options
          oldSelector.fullValue = oldSelector.options.find(o => o.id === value);
        }
        this.selectors.set(key, oldSelector);
      }
    } else {
      // invalid value, so clear this selectors value
      // this is the same for grouped and simple options
      const oldSelector = this.selectors.get(key);
      if (oldSelector) {
        oldSelector.value = '';
        oldSelector.fullValue = {};
        this.selectors.set(key, oldSelector);
      }
    }
  }

  // boolean for if the selector <key> has its value set
  isSelected(key) {
    if (this.selectors.has(key)) {
      return this.selectors.get(key).value !== '';
    }
  }

  // get options for key
  getSelectionOptions(key) {
    if (this.selectors.get(key)) {
      return this.selectors.get(key).options;
    }
    return [];
  }

  // get value for key (id)
  getSelectionValue(key) {
    if (this.selectors.get(key)) {
      return this.selectors.get(key).value;
    }
    return '';
  }

  getSelectionName(key) {
    if (this.selectors.has(key)) {
      const selector = this.selectors.get(key);
      const selectedOption = selector.options.find(o => o.id === selector.value);
      if (typeof selectedOption !== 'undefined') {
        return selectedOption.name;
      }
    }
    return '';
  }

  // get value for key (full object)
  getSelectionFullObject(key) {
    if (this.selectors.get(key)) {
      return this.selectors.get(key).fullValue;
    }
    return {};
  }

  // check that the user made a selection that was in the options array for the given key
  checkSelection(key, value) {
    const chosenSelector = this.selectors.get(key);
    if (chosenSelector) {
      if (chosenSelector.shouldUseGroupedOptions) {
        // if we are using grouped options (district types), we need to do a bit more
        const optionGroups = chosenSelector.options;
        let foundOption = null;
        optionGroups.forEach(group => {
          const optionGroupSearch = group.options.find(o => o.id === value);
          if (typeof optionGroupSearch != 'undefined') {
            foundOption = optionGroupSearch;
          }
        });
        return foundOption != null && typeof foundOption != 'undefined';
      }
      // simple options, just use find
      const { options } = chosenSelector;
      const chosenOption = options.find(o => o.id === value);
      return typeof chosenOption !== 'undefined';
    }
    return false;
  }

  // get all selectors that are in scope
  getVisible() {
    const visibleSelectors = [];
    this.selectors.forEach((selector) => {
      if (selector.isInScope) {
        visibleSelectors.push(selector);
      }
    });
    return visibleSelectors;
  }

  // util method for fetching dynamic selectors
  /**
   * Create a url param string from the given additional context object
   * Standard - use selector key to find user selected value
   * Valued - empty string for selector key, means use the value passed in
   * URL append - empty string for URL param, means add selector key value to end of URL (before params)
   * @param {Array} context List of url context params to add
   * @param {Char} dType District type corresponding to back-end enum
   * @returns Url param string '?param1=value&param2=value2...'
   */
  convertContextToUrlParams(context, dType) {
    let paramString = '';

    if (context && context.length > 0) {
      context.forEach((contextObj) => {
        const { selectorKey, urlParam } = contextObj;
        if (selectorKey === '') {
          // this means we had a value passed in to use instead
          const { value } = contextObj;
          if (paramString.length > 0) {
            paramString += `&${urlParam}=${value}`;
          } else {
            paramString += `${urlParam}=${value}`;
          }
        } else if (this.selectors.has(selectorKey)) {
          // If we already have params in the string, add an
          // ampersand between each parameter
          if (paramString.length > 0) {
            paramString += `&${urlParam}=${this.selectors.get(selectorKey).value}`;
          } else {
            paramString += `${urlParam}=${this.selectors.get(selectorKey).value}`;
          }
        }
      });
    }

    if (paramString.length > 0) {
      paramString += `&type=${dType}`;
    } else {
      paramString += `&type=${dType}`;
    }

    // If we have params, add a question mark to the beginning of the whole
    // url param string
    if (paramString.length > 0) {
      paramString = `?${paramString}`;
    }

    return paramString;
  }

  // fetch the options for a dynamic selector
  async fetchDynamicOptions(key) {
    if (this.selectors.has(key) && this.selectors.get(key).type === SELECTOR_OPTIONS_TYPE.DYNAMIC) {
      const sel = this.selectors.get(key);
      if (sel.url) {
        try {
          const districts = await fetchUtil(`${sel.url}${this.convertContextToUrlParams(sel.additionalContext, sel.districtTypeChar)}`, 'GET');

          if (!sel.shouldUseGroupedOptions) {
            // for normal options, just add the value
            sel.options = districts.map(d => ({ ...d, value: d.id }));
          } else {
            // for grouped options, need to double iterate
            sel.options = districts.map(optionGroup => {
              const optionsWithValues = optionGroup.options.map(o => ({ ...o, value: o.id }));
              return {
                ...optionGroup,
                options: optionsWithValues,
              };
            });
          }
        } catch (e) {
          console.error(e);
        }
      }
    }
  }

  // fetch the options for a complex dynamic selector, either GIS Sets or District Sets
  async fetchComplexOptions(key) {
    if (this.selectors.has(key)
        && this.selectors.get(key).type === SELECTOR_OPTIONS_TYPE.DYNAMIC
        && (this.selectors.get(key).districtTypeChar === 'G' || this.selectors.get(key).districtTypeChar === 'M')) {
      const sel = this.selectors.get(key);
      if (sel.url) {
        try {
          const urlAppendStrings = [];
          let paramString = '';
          sel.additionalContext.forEach(c => {
            if (c.urlParam === '') {
              urlAppendStrings.push(this.selectors.get(c.selectorKey).value);
            } else {
              const { selectorKey, urlParam } = c;
              if (this.selectors.has(selectorKey)) {
                // If we already have params in the string, add an
                // ampersand between each parameter
                if (paramString.length > 0) {
                  paramString += `&${urlParam}=${this.selectors.get(selectorKey).value}`;
                } else {
                  paramString += `${urlParam}=${this.selectors.get(selectorKey).value}`;
                }
              }
            }
          });

          // If we have params, add a question mark to the beginning of the whole
          // url param string
          if (paramString.length > 0) {
            paramString = `?${paramString}`;
          }
          const urlAppendString = urlAppendStrings.join('/');

          const URL = `${sel.url}${urlAppendString}${paramString}`;

          const districts = await fetchUtil(URL, 'GET');

          if (!sel.shouldUseGroupedOptions) {
            // for normal options, just add the value
            sel.options = districts.map(d => ({ ...d, value: d.id }));
          } else {
            // for grouped options, need to double iterate
            sel.options = districts.map(optionGroup => {
              const optionsWithValues = optionGroup.options.map(o => ({ ...o, value: o.id }));
              return {
                ...optionGroup,
                options: optionsWithValues,
              };
            });
          }
        } catch (e) {
          console.error(e);
        }
      }
    }
  }

  /**
   * Create a breadcrumb string from a district of the form State > Office Type > District.
   * @param {VGT_Voting_District} district District object passed to parent by DistrictSelectorContainer.js onChange prop
   * @return {VGT_Voting_District} District object with breadcrumbs string added as a prop called 'breadcrumbs'.
   */
  static createBreadcrumbsFromDistrictObject(district) {
    const districtToUpdate = { ...district };
    // use the correct breadcrumbs map (defined at top of file)
    const ORDERING_ARRAY = DISCRIMINATOR_TO_ORDER_MAP[district.type.discriminator];

    const breadcrumbArray = ORDERING_ARRAY.map(field => {
      if (Array.isArray(field)) {
        let currentObj = district;
        field.forEach(f => {
          if (Object.prototype.hasOwnProperty.call(currentObj, f)) {
            currentObj = currentObj[f];
          }
        });
        return currentObj.name;
      }

      // empty string means take the district name
      if (field.length === 0) {
        return district.name;
      }

      // non-empty string means follow the given path
      try {
        if (Object.prototype.hasOwnProperty.call(district, field) && Object.prototype.hasOwnProperty.call(district[field], 'name')) {
          return district[field].name;
        }

        return '';
      } catch (e) {
        // this probably means that the district didn't have a field needed for breadcrumb generation
        console.error(e);
        return '';
      }
    });

    districtToUpdate.breadcrumbs = breadcrumbArray.join(' > ');
    return districtToUpdate;
  }
}
