import React, { Children } from 'react';
import PropTypes from 'prop-types';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from 'components/TabPanel';
import a11yProps from 'helpers/A11yProps';

/**
 *
 * @param {[string]} tabs Array of strings for tab titles
 * @param {integer} selected Index of selected tab. Should be stateful.
 * @param {func} onChange Callback for tab change. Should change selected prop.
 * @param {string} identifier HTML ID string {ex. 'mail-center-tabs'} for a11y props
 * @param {string} ariaLabel ARIA label for the tabs nav
 * @param {node} children React nodes representing each tab's content. See Tabs.stories.js for usage.
 * @returns React Component
 */
const GenericTabs = ({
  tabs,
  selected,
  onChange,
  identifier,
  ariaLabel,
  children,
}) => {
  // Let us interate over children so that we can wrap them in TabPanels
  const childrenArray = Children.toArray(children);

  return (
    <div>
      <Tabs value={selected} onChange={onChange} aria-label={ariaLabel}>
        {tabs.map((tab, index) => (
          <Tab key={index} label={tab} {...a11yProps.tabs(index, identifier)} />
        ))}
      </Tabs>
      {Children.map(childrenArray, (child, index) => (
        <TabPanel value={selected} index={index} key={index} identifier={identifier}>
          {child}
        </TabPanel>
      ))}
    </div>
  );
};

GenericTabs.propTypes = {
  tabs: PropTypes.array,
  selected: PropTypes.number,
  onChange: PropTypes.func,
  identifier: PropTypes.string,
  ariaLabel: PropTypes.string,
  children: PropTypes.node.isRequired,
};

GenericTabs.defaultProps = {
  tabs: ['Not Invited Yet', 'Invited', 'Responded'],
  selected: 0,
  onChange: () => console.log('onChange called'),
  identifier: 'tabs-component',
  ariaLabel: 'Tabs Component',
};

export default GenericTabs;
