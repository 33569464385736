import { create } from 'zustand';

/**
 * Creates a store for managing campaign finance feature flag state.
 * @function useCampaignFinanceStore
 * @returns {Object} An object containing the store's state and actions.
 * @property {boolean} enableCampaignFinance - Indicates whether campaign finance feature is enabled.
 * @property {function} setCampaignFinanceStatus - Sets the campaign finance feature status.
 * @property {boolean} campaignFinanceInitialized - Indicates whether the campaign finance state has been initialized.
 * @property {function} setCampaignFinanceInitialized - Sets the initialization status of campaign finance.
 */
const useCampaignFinanceStore = create((set) => ({
  enableCampaignFinance: false,
  setCampaignFinanceStatus: (status) => set({ enableCampaignFinance: status }),
  campaignFinanceInitialized: false,
  setCampaignFinanceInitialized: (status) => set({ campaignFinanceInitialized: status }),
}));

export default useCampaignFinanceStore;
