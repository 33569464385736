import React, { useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Box,
  ThemeProvider,
  CircularProgress,
} from '@material-ui/core';
import PageActionsHeader from 'components/PageActionsHeader';
import PageActionsFooter from 'components/PageActionsFooter';
import PageHeader from 'components/PageHeader';
import SnackbarAlert from 'components/SnackbarAlert';
import EmailForm from 'components/MailCenter/Partials/EmailForm';
import fetchUtil from 'helpers/Fetch';
import theme from 'scripts/theme';

const SendEmailContainer = ({
  breadcrumbText,
  breadcrumbUrl,
  guideId,
  messages,
  candidates,
  emailTemplate,
}) => {
  let candidateIds = [];
  let sendToContacts = '';
  if (candidates && candidates.length > 0) {
    candidateIds = candidates.map(({ id }) => id);
    sendToContacts = candidates.map(({ name }) => name).join(', ');
  }

  const _snackbar = useRef();

  const [submitting, setSubmitting] = useState(false);
  const [fieldErrors] = useState({}); // add setFieldErrors when API call is implemented
  const [savedWYSIWYGValue, setSavedWYSIWYGValue] = useState(emailTemplate.body);
  const renderedWYSIWYGValue = useMemo(() => emailTemplate.body, [emailTemplate]);

  // Fields state
  const [senderName, setSenderName] = useState(emailTemplate.senderName);
  const [responder, setResponder] = useState(emailTemplate.senderAddress);
  const [bcc, setBCC] = useState(emailTemplate.bcc);
  const [subject, setSubject] = useState(emailTemplate.subject);
  // See fields array for why commented out
  // const [reminder, setReminder] = useState(new Date());

  const showError = (message) => {
    _snackbar.current.show('error', message);
  };

  const handleWYSIWYGChange = (htmlState) => {
    setSavedWYSIWYGValue(htmlState);
  };

  const handleCancel = () => {
    window.location.replace('/mail');
  };

  const handlePreview = () => {
    sendEmailRequest(true)
      .then(() => {
        setSubmitting(false);
        _snackbar.current.show('success', 'Email preview sent');
      }).catch(error => {
        console.error(error);
        setSubmitting(false);
        showError(messages.errorSendEmail);
      });
  };

  const handleSend = () => {
    sendEmailRequest(false)
      .then(() => {
        setSubmitting(false);
        window.location.href = '/mail?showSuccessMsgFrom=sendEmail';
      }).catch(error => {
        console.error(error);
        setSubmitting(false);
        showError(messages.errorSendEmail);
      });
  };

  const sendEmailRequest = (isPreview) => {
    const body = {
      name: emailTemplate.name,
      subject,
      responderEmail: responder,
      senderName,
      bcc,
      body: savedWYSIWYGValue,
      emailTemplateTypeValue: emailTemplate.type.id,
      candidatesId: candidateIds,
      isPreview,
    };

    setSubmitting(true);
    return fetchUtil(`/api/v1/guide/${guideId}/sendEmail`, 'POST', body);
  };

  // CONSTANTS
  const fields = [
    {
      id: 'type',
      value: emailTemplate.type.name,
      label: messages.fields.type.label,
      readOnly: true,
      type: 'text',
      width: 'full',
      required: false,
    },
    {
      id: 'sendto',
      value: sendToContacts,
      label: messages.fields.send.label,
      multiline: true,
      readOnly: true,
      type: 'text',
      width: 'full',
      required: false,
    },
    {
      id: 'sender',
      value: senderName,
      handleValueChange: (ev) => setSenderName(ev.target.value),
      label: messages.fields.sender.label,
      help: messages.fields.sender.help,
      readOnly: false,
      type: 'text',
      width: 'full',
      required: true,
    },
    {
      id: 'responder',
      value: responder,
      handleValueChange: (ev) => setResponder(ev.target.value),
      label: messages.fields.responder.label,
      help: messages.fields.responder.help,
      readOnly: false,
      type: 'email',
      width: 'half',
      required: true,
    },
    {
      id: 'bcc',
      value: bcc,
      handleValueChange: (ev) => setBCC(ev.target.value),
      label: messages.fields.bcc.label,
      help: messages.fields.bcc.help,
      readOnly: false,
      type: 'multi-email',
      width: 'half',
    },
    {
      id: 'subject',
      value: subject,
      handleValueChange: (ev) => setSubject(ev.target.value),
      label: messages.fields.subject.label,
      readOnly: false,
      type: 'text',
      width: 'full',
      required: true,
    },
    // Leave commented out in case they want to put it back eventually
    // {
    //   id: 'reminder',
    //   value: reminder,
    //   handleValueChange: (date) => setReminder(date),
    //   label: messages.fields.reminder.label,
    //   help: messages.fields.reminder.help,
    //   readOnly: false,
    //   type: 'datetime',
    //   width: 'half',
    //   required: false,
    // },
  ];

  // MAYBE PULL FROM API? FOR NOW LEAVING THEM AS RAW TEXT.
  const macros = [
    {
      title: '#question_list()',
      description: "Displays the questions for the candidates's race.",
    },
    {
      title: '#question_responses()',
      description: 'Displays the answer responses submitted by the candidate.',
    },
    {
      title: '#mailing_address()',
      description: "Displays the candidate's mailing address.",
    },
    {
      title: '#today()',
      description: "Displays today's date.",
    },
    {
      title: '#candidate_name()',
      description: "Displays the candidate's full name.",
    },
    {
      title: '#contact_name()',
      description: "Displays the contact name for the candidates's race.",
    },
    {
      title: '#race_name()',
      description: 'Displays the name of the race.',
    },
    // hiding for launch
    // {
    //   title: '#race_url()',
    //   description: "Displays the URL to the candidate's race page.",
    // },
    // {
    //   title: '#candidate_url()',
    //   description: "Displays the URL to the candidate's detail page.",
    // },
    {
      title: '#response_link()',
      description: 'Displays the URL where the candidate can input his/her response.',
    },
    // {
    //   title: '#response_url_short()',
    //   description: 'Displays the URL to the candidate login page.',
    // },
    {
      title: '#security_code()',
      description: "Displays the candidate's 8-digit password.",
    },
  ];

  return (
    <div>
      <ThemeProvider theme={theme}>
        <SnackbarAlert ref={_snackbar} />

        <PageHeader
          breadcrumbText={breadcrumbText}
          breadcrumbUrl={breadcrumbUrl}
          heading={messages.title}
          subheading={messages.supporting}
        />

        <PageActionsHeader>
          <Button
            color="primary"
            onClick={handleCancel}
          >
            {messages.actions.cancel}
          </Button>

          <Button
            color="secondary"
            disabled={submitting}
            onClick={handlePreview}
            variant="outlined"
          >
            {messages.actions.preview}
          </Button>

          <Button
            color="secondary"
            disabled={submitting}
            onClick={handleSend}
            variant="contained"
          >
            {messages.actions.send}
          </Button>
        </PageActionsHeader>

        <div className="ga-container">
          <div className="mdc-layout-grid">
            <div className="mdc-layout-grid__inner">
              <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
                <Box marginTop={2} marginBottom={5}>
                  <EmailForm
                    defaultWYSIWYG={renderedWYSIWYGValue}
                    errors={fieldErrors}
                    fields={fields}
                    macros={macros}
                    macrosTitle={messages.template.help}
                    onWYSIWYGChange={handleWYSIWYGChange}
                    submitting={submitting}
                    wysiwygHeading={messages.template.title}
                  />
                  {submitting && (
                    <Box style={{
                      position: 'fixed',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}>
                      <div className='spinner'>
                        <CircularProgress />
                      </div>
                    </Box>
                  )}
                </Box>
              </div>
            </div>
          </div>
        </div>

        <PageActionsFooter sticky>
          <Button
            color="primary"
            onClick={handleCancel}
          >
            {messages.actions.cancel}
          </Button>

          <Button
            color="secondary"
            disabled={submitting}
            onClick={handlePreview}
            variant="outlined"
          >
            {messages.actions.preview}
          </Button>

          <Button
            color="secondary"
            disabled={submitting}
            onClick={handleSend}
            variant="contained"
          >
            {messages.actions.send}
          </Button>
        </PageActionsFooter>
      </ThemeProvider>
    </div>
  );
};

SendEmailContainer.propTypes = {
  breadcrumbText: PropTypes.string,
  breadcrumbUrl: PropTypes.string,
  candidates: PropTypes.array,
  emailTemplate: PropTypes.object.isRequired,
  guideId: PropTypes.string,
  messages: PropTypes.object.isRequired,
};

SendEmailContainer.defaultProps = {
  breadcrumbText: 'Mail Center',
  breadcrumbUrl: '/mail',
  candidates: [],
  guideId: '',
};

export default SendEmailContainer;
