import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import CloseIcon from '@material-ui/icons/Close';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import ActionsDropdown from 'components/ActionsDropdown';
import BioQuestionForm from 'components/BioQuestions/BioQuestionForm';
import BioQuestionsListRow from 'components/BioQuestions/BioQuestionsListRow';

const useStyles = makeStyles({
  iconStyles: {
    fill: '#999',
  },
  emptyContainer: {
    textAlign: 'center',
    padding: '15px',
    color: '#333',
  },
  dialogActions: {
    justifyContent: 'flex-end',
  },
  editModal: {
    padding: 0,
  },
  colHeader: {
    fontWeight: 600,
    fontSize: '0.875rem',
    padding: '16px',
  },
  colHeaderCentered: {
    fontWeight: 600,
    textAlign: 'center',
    fontSize: '0.875rem',
    padding: '16px',
  },
  headerRow: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
  bodyRow: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    height: '81px',
  },
  rowCell: {
    fontSize: '0.875rem',
    padding: '16px',
  },
  rowCellCentered: {
    fontSize: '0.875rem',
    padding: '16px',
    textAlign: 'center',
  },
  disabled: {
    cursor: 'not-allowed',
  },
  iconButton: {
    cursor: 'grab',
    fill: '#999',
  },
});

const BioQuestionsList = ({
  messages,
  rows,
  onEditChange,
  onClearEditObjectHolder,
  onDelete,
  onQuestionUpdate,
  onSetEditObjectHolder,
  editObjectHolder,
  showEditModal,
  editObjectError,
  typeOptions,
  reordering,
  onFailedDrop,
}) => {
  const classes = useStyles();

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [activeQuestion, setActiveQuestion] = useState(null);

  const UNLOCK_DEFAULT_QUESTIONS_FLAG = true; // remove this or change to false to re-lock default bio questions

  const getRowActions = (isDefault, id) => {
    if (!isDefault || UNLOCK_DEFAULT_QUESTIONS_FLAG) {
      const options = [
        { label: 'Edit', onClick: () => handleOpenEditModal(id) },
        { label: 'Delete', onClick: () => handleOpenDeleteConfirmation(id) },
      ];

      return (
        <span>
          <ActionsDropdown options={options} />
        </span>
      );
    }

    return (
      <LockOutlinedIcon className={classes.iconStyles} />
    );
  };

  // Handle Delete question
  const handleDeleteQuestion = () => {
    onDelete(activeQuestion);

    setShowDeleteConfirmation(false);
    setActiveQuestion(null);
  };

  // Manage visibility of delete confirmation
  const handleOpenDeleteConfirmation = id => {
    setShowDeleteConfirmation(true);
    setActiveQuestion(id);
  };

  const handleCloseDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
    setActiveQuestion(null);
  };

  const handleOpenEditModal = id => {
    const selectedQuestion = rows.find(question => question.id === id);

    if (typeof selectedQuestion !== 'undefined') {
      onSetEditObjectHolder(selectedQuestion);
    }
  };

  const handleCloseEditModal = () => {
    onClearEditObjectHolder();
  };

  const renderTableBody = () => {
    if (rows.length > 0) {
      return (
        <>
          {/* Table header */}
          <Grid container className={classes.headerRow} alignItems="center">
            <Grid item className={classes.colHeader} xs={2}>{messages.bioQuestions.list.table.colHeader.name}</Grid>
            <Grid item className={classes.colHeader} xs={2}>{messages.bioQuestions.list.table.colHeader.type}</Grid>
            <Grid item className={classes.colHeader} xs={2}>{messages.bioQuestions.list.table.colHeader.charLimit}</Grid>
            <Grid item className={classes.colHeader} xs={2}>{messages.bioQuestions.list.table.colHeader.candidateActions}</Grid>
            <Grid item className={classes.colHeader} xs={2}>{messages.bioQuestions.list.table.colHeader.visibility}</Grid>
            <Grid item className={classes.colHeaderCentered} xs={1}>{messages.bioQuestions.list.table.colHeader.actions}</Grid>
            <Grid item className={classes.colHeaderCentered} xs={1}>{messages.bioQuestions.list.table.colHeader.reorder}</Grid>
          </Grid>
          {/* Table Body */}
          <DndProvider backend={HTML5Backend}>
            {rows.map((row, index) => (
              <BioQuestionsListRow
                row={row}
                key={row.id}
                classes={classes}
                getRowActions={getRowActions}
                messages={messages}
                reordering={reordering}
                onFailedDrop={onFailedDrop}
                index={index}
              />
            ))}
          </DndProvider>
        </>
      );
    }

    return (
      <div className={classes.emptyContainer}>
        <p>{messages.bioQuestions.list.table.colHeader.empty}</p>
      </div>
    );
  };

  return (
    <div>

      {/* Render table or empty text */}
      {renderTableBody()}

      {/* Delete Confirmation Modal */}
      <Dialog
        aria-labelledby="delete-confirmation-modal"
        maxWidth="sm"
        open={showDeleteConfirmation}
        onClose={handleCloseDeleteConfirmation}
      >

        <DialogTitle id="delete-confirmation-modal" disableTypography>
          <Typography component="h1" variant="h2">
            {messages.bioQuestions.list.delete.title}
          </Typography>

          <IconButton aria-label="close" onClick={handleCloseDeleteConfirmation}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Typography component="p" variant="body1">
            If this question has candidate answers, it can be restored along with those answers from the Recycling Bin. Otherwise, <strong>it will be deleted permanently</strong>.
          </Typography>
        </DialogContent>

        <DialogActions className={classes.dialogActions}>
          <Button onClick={handleCloseDeleteConfirmation}>
            {messages.bioQuestions.list.delete.cancel}
          </Button>&nbsp;&nbsp;

          <Button
            color="secondary"
            onClick={handleDeleteQuestion}
            variant="contained"
          >
            {messages.bioQuestions.list.delete.confirm}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Question Modal */}
      <Dialog
        aria-labelledby="edit-question-modal"
        maxWidth="md"
        open={showEditModal}
        onClose={handleCloseEditModal}
      >
        <DialogTitle id="question-edit-modal" disableTypography>
          <Typography component="h1" variant="h2">
            {messages.bioQuestions.edit.heading}
          </Typography>

          <IconButton aria-label="close" onClick={handleCloseEditModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent className={classes.editModal}>
          <BioQuestionForm
            id={editObjectHolder?.id}
            buttonText={messages.bioQuestions.edit.buttonText}
            charLimit={editObjectHolder?.charLimit}
            edit={editObjectHolder?.edit}
            name={editObjectHolder?.name}
            type={editObjectHolder?.type}
            isVisible={editObjectHolder?.isVisible}
            requiredText={messages.bioQuestions.edit.requiredText}
            onChange={onEditChange}
            onSubmit={onQuestionUpdate}
            errors={editObjectError}
            typeOptions={typeOptions}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

BioQuestionsList.propTypes = {
  messages: PropTypes.object,
  rows: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    default: PropTypes.bool,
    deleted: PropTypes.bool,
    name: PropTypes.string,
    index: PropTypes.number,
    title: PropTypes.bool,
    version: PropTypes.number,
    type: PropTypes.string,
    maxl: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    candidateActions: PropTypes.string,
    isVisible: PropTypes.bool,
    edit: PropTypes.bool,
  })).isRequired,
  onEditChange: PropTypes.func,
  onClearEditObjectHolder: PropTypes.func,
  onDelete: PropTypes.func,
  onQuestionUpdate: PropTypes.func,
  onSetEditObjectHolder: PropTypes.func,
  editObjectHolder: PropTypes.object,
  showEditModal: PropTypes.bool,
  editObjectError: PropTypes.object,
  typeOptions: PropTypes.array,
  reordering: PropTypes.shape({
    onReorder: PropTypes.func.isRequired,
    moveQuestion: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
  }).isRequired,
  onFailedDrop: PropTypes.func,
};

BioQuestionsList.defaultProps = {
  messages: {
    bioQuestions: {
      edit: {
        buttonText: 'Save',
        heading: 'Editing your Biographical Question',
        requiredText: '*Required fields needed for your race category',
      },
      list: {
        delete: {
          cancel: 'Cancel',
          confirm: 'Remove',
          title: 'Confirm Deletion of this Biographical question',
        },
        table: {
          colHeader: {
            actions: 'Actions',
            candidateActions: 'Candidate Actions',
            charLimit: 'Answer Char Limit',
            empty: 'No biographical questions found',
            name: 'Name',
            reorder: 'Reorder',
            type: 'Type',
            visibility: 'Visibility to Voters',
          },
          editableText: 'Editable',
          notEditableText: 'View Only',
          notVisibleText: 'Invisible',
          visibility: 'Visible',
        },
      },
    },
  },
  rows: [],
  editObjectHolder: {},
  showEditModal: false,
  onEditChange: () => {},
  onClearEditObjectHolder: () => {},
  onDelete: () => {},
  onQuestionUpdate: () => {},
  onSetEditObjectHolder: () => {},
  editObjectError: {},
  typeOptions: [],
  onFailedDrop: () => {},
};

export default BioQuestionsList;
