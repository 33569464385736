import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
} from '@material-ui/core';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import { useReorderChild } from 'hooks/useReorder';

const BioQuestionsListRow = ({
  classes,
  row,
  getRowActions,
  messages,
  reordering,
  index,
  onFailedDrop,
}) => {
  const getRowVisibleText = (isVisible) => (
    isVisible ? messages.bioQuestions.list.table.visibleText : messages.bioQuestions.list.table.notVisibleText
  );

  const getRowEditableText = (isEditable) => (
    isEditable ? messages.bioQuestions.list.table.editableText : messages.bioQuestions.list.table.notEditableText
  );

  const { onReorder, moveQuestion, disabled } = reordering;

  // ABSTRACTION!
  const {
    handlerId, ref, opacity,
  } = useReorderChild({
    index,
    moveFunction: moveQuestion,
    onReorder,
    id: row.id,
    disabled,
    onFailedDrop,
  });

  return (
    <Grid
      container
      alignItems="center"
      data-handler-id={handlerId}
      ref={ref}
      className={`${classes.bodyRow} ${disabled ? classes.disabled : ''}`}
      style={{ opacity }}
    >
      <Grid xs={2} item className={classes.rowCell}>{row.name}</Grid>
      <Grid xs={2} item className={classes.rowCell}>{row.type}</Grid>
      <Grid xs={2} item className={classes.rowCell}>{row.maxl}</Grid>
      <Grid xs={2} item className={classes.rowCell}>{getRowEditableText(row.edit)}</Grid>
      <Grid xs={2} item className={classes.rowCell}>{getRowVisibleText(row.isVisible)}</Grid>
      <Grid xs={1} item className={classes.rowCellCentered} align="center">{getRowActions(row.default, row.id)}</Grid>
      <Grid xs={1} item className={classes.rowCellCentered} align="center"><DragHandleIcon className={classes.iconButton} /></Grid>
    </Grid>
  );
};

BioQuestionsListRow.propTypes = {
  classes: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  getRowActions: PropTypes.func.isRequired,
  messages: PropTypes.object.isRequired,
  reordering: PropTypes.shape({
    onReorder: PropTypes.func.isRequired,
    moveQuestion: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  onFailedDrop: PropTypes.func.isRequired,
};

BioQuestionsListRow.defaultProps = {};

export default BioQuestionsListRow;
