import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import {
  FormControl,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import stateErrors from 'helpers/StateErrors';
import AddQuestionForm from 'components/RaceQuestions/AddQuestionForm';
import EmptyState from 'components/EmptyState';
import LanguageTabsDisplay from 'components/RaceQuestions/LanguageTabsDisplay';

const styles = {
  formContainer: {
    paddingTop: '1.75rem',
    paddingBottom: '1.25rem',
  },
  sectionContainer: {
    padding: '1.25rem 0rem',
  },
};

class NewRaceQuestionSetCard extends React.Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleAddQuestion = this.handleAddQuestion.bind(this);

    this._addQuestionForm = React.createRef();
  }

  handleInputChange(event) {
    const { value } = event.target;
    const { onTitleChange } = this.props;
    onTitleChange(value);
  }

  handleAddQuestion(question) {
    const { onSubmit } = this.props;
    onSubmit(question, () => this._addQuestionForm.current.reset());
  }

  render() {
    const {
      classes,
      header,
      subtext,
      inputLabel,
      id,
      name,
      questions,
      titleErrors,
      addQuestionErrors,
      languages,
      typeOptions,
      questionListLabel,
      questionListSubtext,
      emptyStateText,
    } = this.props;

    return (
      <Paper elevation={3} className="card-container">
        <div>
          <Grid container>
            <Typography component="h3" variant="h3" color="primary">
              {header}
            </Typography>
            <Typography component="p" variant="body1" paragraph>
              {subtext}
            </Typography>
          </Grid>
        </div>

        <Grid container className={classes.formContainer}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth xs={3}>
              <TextField
                id="new-race-question-set"
                error={stateErrors.hasError('newSetTitle', titleErrors)}
                helperText={stateErrors.getError('newSetTitle', titleErrors)}
                label={inputLabel}
                name="title"
                required
                variant="filled"
                onChange={this.handleInputChange}
                value={name}
                            />
            </FormControl>
          </Grid>
        </Grid>

        <div className={classes.sectionContainer}>
          <AddQuestionForm
            errors={addQuestionErrors}
            languages={languages}
            onSubmit={this.handleAddQuestion}
            typeOptions={typeOptions}
            ref={this._addQuestionForm}
          />
        </div>

        <div className={classes.sectionContainer}>
          <Typography component="h3" variant="h3" color="primary">
            {questionListLabel}
          </Typography>

          <Typography component="p" variant="body1" paragraph>
            {questionListSubtext}
          </Typography>
        </div>

        {questions ? (
          <div>
            <LanguageTabsDisplay
              languages={languages}
              id={id}
              name={name}
              questions={questions}
              canAddQuestions={false}
            />
          </div>
        ) : (
          <EmptyState>
            <p>{emptyStateText}</p>
          </EmptyState>
        )}
      </Paper>
    );
  }
}

NewRaceQuestionSetCard.propTypes = {
  header: PropTypes.string,
  subtext: PropTypes.string,
  inputLabel: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
  onTitleChange: PropTypes.func,
  questions: PropTypes.arrayOf(PropTypes.shape({
    default: PropTypes.bool,
    id: PropTypes.number,
    index: PropTypes.number,
    maxl: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.number,
  })),
  titleErrors: PropTypes.object,
  addQuestionErrors: PropTypes.object,
  languages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
  })),
  typeOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    hasDefaultMaxLength: PropTypes.bool,
    defaultMaxLength: PropTypes.number,
  })).isRequired,
  questionListLabel: PropTypes.string,
  questionListSubtext: PropTypes.object,
  emptyStateText: PropTypes.string,
  onSubmit: PropTypes.func,
  classes: PropTypes.object.isRequired,
};

NewRaceQuestionSetCard.defaultProps = {
  header: 'Default header',
  subtext: 'Default subtext',
  inputLabel: 'Race question set title',
  id: null,
  name: '',
  onTitleChange: () => {},
  questions: [],
  titleErrors: {},
  addQuestionErrors: {},
  languages: {},
  questionListLabel: 'Questions in the set',
  questionListSubtext: (<>Link Text</>),
  emptyStateText: 'Add a question by filling out the form above. You can also add questions later by editing this set in the Race Question Sets section of your guide.',
  onSubmit: () => {},
};

export default withStyles(styles)(NewRaceQuestionSetCard);
