import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import {
  CircularProgress,
  TextField,
  Typography,
  IconButton,
  Button,
} from '@material-ui/core';
import WYSIWYG from 'components/WYSIWYG';
import DistrictSelectorContainer from 'components/Districts/Partials/DistrictSelectorContainer';
import GaUtils from 'helpers/GaUtils';
import districtsPickListImage from '../../../../images/districtsPickListHelper.png';

const styles = () => ({
  spinner: {
    position: 'absolute',
    zIndex: '10',
    top: '45%',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  rule: {
    margin: '2rem 0',
  },
  formStepTitle: {
    margin: '2rem 0 1rem 0',
  },
  districtWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '.25rem 0',
  },
  removeX: {
    fontSize: '2rem',
    cursor: 'pointer',
    height: '2.25rem',
    width: '2.25rem',
  },
  addDistrictWrapper: {
    display: 'flex',
    margin: '1.75rem 0',
    alignItems: 'center',
  },
  descriptionHelpText: {
    color: 'rgba(0, 0, 0, 0.5)',
    fontSize: '.75rem',
  },
  addBtn: {
    marginLeft: '1rem',
    padding: '5px 15px',
  },
});

class DistrictSetForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      locationText: props.locationInput,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleDistrictChange = this.handleDistrictChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  hasError(fieldName) {
    const { errors } = this.props;
    return Object.prototype.hasOwnProperty.call(errors, fieldName);
  }

  getErrorText(fieldName) {
    const { errors } = this.props;

    if (Object.prototype.hasOwnProperty.call(errors, fieldName)) {
      return errors[fieldName][0];
    }

    return '';
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    const { onChange } = this.props;

    onChange(name, value);
  }

  // Note: This method was created t update the value of WYSWYG component
  handleInputChangeWysiwyg(value) {
    const { onChange } = this.props;
    onChange('description', value);
  }

  handleDistrictChange(district) {
    const { onChange } = this.props;
    onChange('district', district);
  }

  handleAddLocation = () => {
    const { onAdd } = this.props;
    const { locationText } = this.state;

    if (!GaUtils.isNullOrEmpty(locationText)) {
      onAdd(locationText);
      this.setState({ locationText: '' });
    }
  }

  handleLocationText = (event) => {
    this.setState({
      locationText: event.target.value,
    });
  }

  handleKeyDown(event) {
    if (event.key === 'Enter') {
      this.handleAddLocation();
      event.preventDefault();
    }
  }

  render() {
    const {
      classes,
      guideId,
      messages,
      submitting,
      onRemove,
      set,
      wysiwygDefault,
    } = this.props;
    const {
      locationText,
    } = this.state;

    return (
      <div>
        {submitting && (
          <div className={classes.spinner}>
            <CircularProgress />
          </div>
        )}

        <div className="ga-container">
          <div className="mdc-layout-grid">
            <div className="mdc-layout-grid__inner">
              <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-6">
                <Typography className={classes.formStepTitle} component="h3" variant="h4" paragraph>
                  {messages.stepOneTitle}
                </Typography>

                <TextField
                  disabled={submitting}
                  name="name"
                  error={this.hasError('name')}
                  inputProps={{ maxLength: 200 }}
                  label={messages.nameLabel}
                  helperText={this.hasError('name') ? this.getErrorText('name') : messages.nameHelperText}
                  onChange={this.handleInputChange}
                  required
                  fullWidth
                  value={set.name}
                  variant="filled"
                />
              </div>
            </div>

            <div className="mdc-layout-grid__inner">
              <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-9">
                <WYSIWYG
                  disabled={submitting}
                  error={this.hasError('description')}
                  helperText={this.hasError('description') ? this.getErrorText('description') : messages.descriptionHelpText}
                  label={messages.descriptionLabel}
                  onChange={(value) => this.handleInputChangeWysiwyg(value)}
                  value={wysiwygDefault}
                />
              </div>
            </div>

            <hr className={classes.rule} />

            <Typography className={classes.formStepTitle} component="h3" variant="h4">
              {messages.stepTwoTitle}
            </Typography>

            <div className="mdc-layout-grid__inner">
              <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-6">
                <DistrictSelectorContainer
                  district={Object.prototype.hasOwnProperty.call(set, 'district') && set.district !== null && Object.keys(set.district).length > 0 ? set.district : null}
                  error={this.hasError('districtId') ? this.getErrorText('districtId') : ''}
                  guideId={guideId}
                  onChange={this.handleDistrictChange}
                />
              </div>

              <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-6">
                <img src={districtsPickListImage} width="100%" />
              </div>
            </div>

            <hr className={classes.rule} />

            <div className="mdc-layout-grid__inner">
              <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-6">
                <Typography className={classes.formStepTitle} component="h3" variant="h4">
                  {messages.stepThreeTitle}
                </Typography>

                <Typography component="p" variant="body1" paragraph>
                  {messages.stepThreeDescription}
                </Typography>

                <div className={classes.addDistrictWrapper}>
                  <TextField
                    disabled={submitting}
                    error={this.hasError('locationInput')}
                    fullWidth
                    helperText={this.getErrorText('locationInput')}
                    inputProps={{ maxLength: 60 }}
                    label={messages.locationInputLabel}
                    name="location"
                    onChange={this.handleLocationText}
                    onKeyDown={this.handleKeyDown}
                    value={locationText}
                    variant="filled"
                  />

                  <div>
                    <Button
                      className={classes.addBtn}
                      color="secondary"
                      disabled={submitting || locationText.length <= 0}
                      onClick={() => this.handleAddLocation()}
                      variant="contained"
                    >
                      {messages.add}
                    </Button>
                  </div>
                </div>

                {set.childDistricts && set.childDistricts.map((district, index) => (
                  <div className={classes.districtWrapper} key={index}>
                    <Typography component="p" variant="body1">
                      {district.name}
                    </Typography>

                    <IconButton
                      disabled={submitting}
                      onClick={() => onRemove(district.name)}
                      color="primary"
                      aria-label="remove location"
                      component="span">
                      <span className={classes.removeX}>&times;</span>
                    </IconButton>
                  </div>
                ))}

                {(!set.childDistricts || set.childDistricts.length === 0) && (
                  <Typography component="p" variant="body1" color="textSecondary">
                    {messages.districtsEmptyText}
                  </Typography>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DistrictSetForm.propTypes = {
  classes: PropTypes.object,
  errors: PropTypes.object,
  guideId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onAdd: PropTypes.func,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  messages: PropTypes.object,
  set: PropTypes.shape({
    description: PropTypes.string,
    district: PropTypes.object,
    childDistricts: PropTypes.array,
    name: PropTypes.string,
  }),
  submitting: PropTypes.bool,
  locationInput: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  wysiwygDefault: PropTypes.string,
};

DistrictSetForm.defaultProps = {
  classes: {},
  errors: {},
  onAdd: () => {},
  onChange: () => {},
  onRemove: () => {},
  messages: {},
  set: {
    description: '',
    district: {},
    childDistricts: [],
    name: '',
  },
  submitting: false,
  locationInput: '',
  wysiwygDefault: '<p></p>',
};

export default withStyles(styles)(DistrictSetForm);
