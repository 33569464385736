import React from 'react';
import PropTypes from 'prop-types';
import { createStyles, withStyles } from '@material-ui/styles';
import { Button, Typography } from '@material-ui/core';
// import SearchIcon from '@material-ui/icons/Search';
import { DataGridPro, LicenseInfo } from '@mui/x-data-grid-pro';
import ActionsDropdown from 'components/ActionsDropdown';
import EmptyState from 'components/EmptyState';
import RaceStatus from 'components/Races/Partials/RaceStatus';

LicenseInfo.setLicenseKey(
  '5354fa8b30068069673634540842cbf3T1JERVI6MzU2NzcsRVhQSVJZPTE2NzM0NzU4MDAwMDAsS0VZVkVSU0lPTj0x',
);

const ROWS_PER_PAGE_OPTIONS = [50, 100, 500, 1000, 5000];

const styles = ({ palette }) => createStyles({
  headingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: '47px', // Prevents the container from jumping around when bulk actions show/hide
  },
  actionsContainer: {
    backgroundColor: '#f5f5f5',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  actionBlockContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  actionButton: {
    margin: '8px 20px',
    minWidth: '50px',
    textTransform: 'none',
  },
  destructiveButton: {
    margin: '8px 20px',
    minWidth: '50px',
    textTransform: 'none',
    color: '#ac1b3d',
    '&:hover': {
      color: '#6B1126',
    },
  },
  filter: {
    backgroundColor: '#fff',
    margin: '8px 18px',
    minWidth: 120,
  },
  search: {
    backgroundColor: '#fff',
    margin: '8px 18px',
    minWidth: 275,
  },
  tableContainer: {
    '& a': {
      color: palette.primary.main,
      fontWeight: 500,
      textDecoration: 'none',
    },
  },
  table: {
    border: 'none',
  },
  emptyStateButton: {
    margin: '0 .8rem',
  },
});

// Dynamic row height style for DataGrid component
const StyledDataGrid = withStyles({
  root: {
    '& .MuiDataGrid-renderingZone': {
      maxHeight: 'none !important',
    },
    '& .MuiDataGrid-cell': {
      lineHeight: 'unset !important',
      whiteSpace: 'normal',
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiDataGrid-row': {
      maxHeight: 'none !important',
    },
  },
})(DataGridPro);

class RaceTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 50,
      selected: [],
      showBulkEdit: false,
    };

    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleExport = this.handleExport.bind(this);
    this.handleSelectionModelChange = this.handleSelectionModelChange.bind(this);
    this.handlePageSizeUpdate = this.handlePageSizeUpdate.bind(this);
    this.handleEmptyStateLink = this.handleEmptyStateLink.bind(this);
  }

  handleEdit(id) {
    const { races } = this.props;
    const selectedRace = races.find(r => r.id === id);
    if (typeof selectedRace != 'undefined') {
      if (selectedRace.clazz && selectedRace.clazz.id && selectedRace.clazz.id === 'F') {
        window.location.href = `/measures/${id}`;
      } else {
        window.location.href = `/races/${id}`;
      }
    }
  }

  handleDelete(ids) {
    const { onDelete } = this.props;
    onDelete(ids);
  }

  handleExport(ids) {
    const { onExport } = this.props;
    onExport(ids);
  }

  handleSelectionModelChange(selectionModel) {
    const { onSelectionModelChange } = this.props;
    onSelectionModelChange(selectionModel);
  }

  handlePageSizeUpdate(newSize) {
    this.setState({
      pageSize: newSize,
    });
  }

  handlePublish = (isPublish) => {
    const { onPublish, selectedRaces } = this.props;

    onPublish(isPublish, selectedRaces);
  }

  handleBulkEdit = (isBulkEdit) => {
    const { races, onBulkEdit, selectedRaces } = this.props;

    const fullSelectedRaces = races.filter(race => selectedRaces.includes(race.id));

    this.setState({
      showBulkEdit: isBulkEdit,
    });

    onBulkEdit(fullSelectedRaces);
  }

  handleEmptyStateLink = (link) => {
    window.location.href = link;
  }

  handleSetDistricts(ids) {
    const { onSetDistrictsFromRaceName } = this.props;
    onSetDistrictsFromRaceName(ids);
  }

  renderBulkActions() {
    const {
      classes,
      publishSubmitting,
      messages,
      selectedRaces,
    } = this.props;

    if (selectedRaces.length > 0) {
      return (
        <div>
          <Button className={classes.destructiveButton} color="secondary" size="small" onClick={() => this.handleDelete(selectedRaces)}>Delete</Button>
          <Button className={classes.actionButton} color="primary" size="small" onClick={() => this.handleExport(selectedRaces)}>Export</Button>
          <Button className={classes.actionButton} color="primary" size="small"
            onClick={() => this.handlePublish(true)}
            disabled={publishSubmitting}>{messages.publish.title}</Button>
          <Button className={classes.actionButton} color="primary" size="small"
            onClick={() => this.handlePublish(false)}
            disabled={publishSubmitting}>{messages.unpublish.title}</Button>
          <Button className={classes.actionButton} color="primary" size="small" onClick={() => this.handleSetDistricts(selectedRaces)}>Update District with Race Name</Button>
          <Button
            className={classes.actionButton}
            color="primary"
            size="small"
            onClick={() => this.handleBulkEdit(true)}
          >Bulk Edit</Button>
        </div>
      );
    }
  }

  renderEmptyState() {
    const { classes, messages, navigation } = this.props;

    return (
      <EmptyState>
        <p className="ga-m-bottom--large">
          There are currently no races or measures available for this guide.
          Create your first race or measure now!
        </p>
        <div>
          <Button
            color="secondary"
            variant="contained"
            className={classes.emptyStateButton}
            onClick={() => this.handleEmptyStateLink(navigation.bulkImportPage)}
          >
            {messages.bulkImport}
          </Button>
          <Button
            color="secondary"
            variant="contained"
            className={classes.emptyStateButton}
            onClick={() => this.handleEmptyStateLink(navigation.createRace)}
          >
            {messages.createRace}
          </Button>
          <Button
            color="secondary"
            variant="contained"
            className={classes.emptyStateButton}
            onClick={() => this.handleEmptyStateLink(navigation.createMeasure)}
          >
            {messages.createMeasure}
          </Button>
        </div>
      </EmptyState>
    );
  }

  renderDataTable() {
    const {
      classes,
      filterModel,
      messages,
      onFilterModelChange,
      onSortModelChange,
      onPublish,
      races,
      sortModel,
      selectedRaces,
      onColumnVisibilityChange,
      hiddenColumns,
    } = this.props;
    const { pageSize } = this.state;

    const truncate = (str, length) => {
      if (str) {
        return str.length > length ? `${str.slice(0, length)}...` : str;
      }
      return '';
    };

    const columns = [
      {
        field: 'name',
        headerName: 'Name',
        flex: 2,
        renderCell: (params) => <a
          href={`/${params.row.clazz.id === 'F' ? 'measures' : 'races'}/${params.id}`}>{truncate(params.row.name, 90)}</a>,
        hide: hiddenColumns.includes('name'),
      },
      {
        field: 'type',
        headerName: 'Type',
        flex: 0.75,
        valueGetter: (params) => params.row?.clazz?.name,
        hide: hiddenColumns.includes('type'),
      },
      {
        field: 'raceType',
        headerName: 'Race Type',
        flex: 0.75,
        valueGetter: (params) => params.row?.state?.name,
        hide: hiddenColumns.includes('raceType'),
      },
      {
        field: 'category',
        headerName: 'Category',
        flex: 1,
        valueGetter: (params) => truncate(params.row?.category?.title, 40),
        hide: hiddenColumns.includes('category'),
      },
      {
        field: 'candidateCount',
        headerName: 'Candidates',
        flex: 0.75,
        type: 'number',
        headerAlign: 'left',
        hide: hiddenColumns.includes('candidateCount'),
      },
      {
        field: 'district',
        headerName: 'District',
        flex: 1,
        valueGetter: (params) => params.row?.votingDistrict?.name,
        hide: hiddenColumns.includes('district'),
      },
      {
        field: 'BioQ Set',
        headerName: 'Bio Set',
        flex: 1,
        valueGetter: (params) => params.row?.bioSet?.name,
        renderCell: (params) => <span>{params.row?.bioSet?.name || '-'}</span>,
        hide: hiddenColumns.includes('BioQ Set'),
      },
      {
        field: 'Question Set',
        headerName: 'Question Set',
        flex: 1,
        valueGetter: (params) => params.row?.questionSet?.name,
        renderCell: (params) => <span>{params.row?.questionSet?.name || '-'}</span>,
        hide: hiddenColumns.includes('Question Set'),
      },
      {
        field: 'visible',
        headerName: 'Status',
        flex: 1,
        renderCell: (params) => <RaceStatus visible={params.row?.visible} />,
        valueGetter: (params) => (params.row?.visible ? 'Published' : 'Unpublished'),
        hide: hiddenColumns.includes('visible'),
      },
      {
        field: 'actions',
        headerName: 'Actions',
        resizable: false,
        sortable: false,
        width: 120,
        renderCell: (params) => {
          const editOpt = { label: 'Edit', onClick: () => this.handleEdit(params.id) };
          const publishOpt = { label: messages.publish.title, onClick: () => onPublish(true, [params.id]) };
          const unpublishOpt = { label: messages.unpublish.title, onClick: () => onPublish(false, [params.id]) };
          const deleteOpt = { label: 'Delete', onClick: () => this.handleDelete([params.id]) };
          const exportOpt = { label: 'Export', onClick: () => this.handleExport([params.id]) };

          const options = [editOpt];
          if (params.row && params.row.visible === true) {
            options.push(unpublishOpt);
          } else {
            options.push(publishOpt);
          }
          options.push(deleteOpt);
          options.push(exportOpt);

          return <ActionsDropdown options={options} />;
        },
      },
    ];

    if (!races || races.length <= 0) {
      return this.renderEmptyState();
    }

    return (
      <div>
        {/* <div className={classes.actionsContainer}>
          <div>
            <div className={classes.actionBlockContainer}>
              <TextField
                className={classes.search}
                id="search-races"
                label="Search Races or Measures"
                variant="outlined"
                InputProps={{
                  endAdornment: <InputAdornment position="end"><SearchIcon /></InputAdornment>,
                }}
              />
            </div>
          </div>
          <div>
            <div className={classes.actionBlockContainer}>
              <span>Filter By:</span>
              <FormControl variant="outlined" className={classes.filter}>
                <InputLabel id="race-filter-type-label">Type</InputLabel>
                <Select
                  labelId="race-filter-type-label"
                  id="race-filter-type"
                  value=""
                  // onChange={handleTypeFilterChange}
                >
                  <MenuItem value="">
                    Type
                  </MenuItem>
                  {types.map(type => <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>)}
                </Select>
              </FormControl>

              <FormControl variant="outlined" className={classes.filter}>
                <InputLabel id="race-filter-category-label">Category</InputLabel>
                <Select
                  labelId="race-filter-category-label"
                  id="race-filter-category"
                  value=""
                  // onChange={handleCategoryFilterChange}
                >
                  <MenuItem value="">
                    Category
                  </MenuItem>
                  {categories.map(category => <MenuItem key={category.value} value={category.value}>{category.label}</MenuItem>)}
                </Select>
              </FormControl>

              <FormControl variant="outlined" className={classes.filter}>
                <InputLabel id="race-filter-status-label">Status</InputLabel>
                <Select
                  labelId="race-filter-status-label"
                  id="race-filter-status"
                  value=""
                  // onChange={handleStatusFilterChange}
                >
                  <MenuItem value="">
                    Status
                  </MenuItem>
                  <MenuItem value="published">Published</MenuItem>
                  <MenuItem value="unpublished">Unpublished</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </div> */}
        <div className={classes.tableContainer}>
          <StyledDataGrid
            autoHeight={true}
            checkboxSelection
            className={classes.table}
            columns={columns}
            filterModel={filterModel}
            onPageSizeChange={this.handlePageSizeUpdate}
            onSelectionModelChange={this.handleSelectionModelChange}
            onFilterModelChange={onFilterModelChange}
            onSortModelChange={onSortModelChange}
            pageSize={pageSize}
            pagination={true}
            rows={races}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            sortModel={sortModel}
            selectionModel={selectedRaces}
            onColumnVisibilityChange={onColumnVisibilityChange}
          />
        </div>
      </div>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12 ga-m-vertical--large">
        <div className={classes.headingContainer}>
          <Typography component="h2" variant="h3">Election Races & Measures</Typography>
          {this.renderBulkActions()}
        </div>

        {this.renderDataTable()}
      </div>
    );
  }
}

RaceTable.propTypes = {
  classes: PropTypes.object,
  filterModel: PropTypes.shape({
    items: PropTypes.array,
    linkOperator: PropTypes.string,
  }),
  messages: PropTypes.object,
  navigation: PropTypes.object,
  onBulkEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onExport: PropTypes.func,
  onFilterModelChange: PropTypes.func,
  onPublish: PropTypes.func,
  onSetDistrictsFromRaceName: PropTypes.func,
  onSortModelChange: PropTypes.func,
  onSelectionModelChange: PropTypes.func,
  publishSubmitting: PropTypes.bool,
  races: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    name: PropTypes.string,
    type: PropTypes.string,
    category: PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      name: PropTypes.string,
    }),
    votingDistrict: PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      name: PropTypes.string,
    }),
    status: PropTypes.string,
  })),
  sortModel: PropTypes.array,
  selectedRaces: PropTypes.array,
  hiddenColumns: PropTypes.array,
  onColumnVisibilityChange: PropTypes.func,
};

RaceTable.defaultProps = {
  filterModel: { items: [], linkOperator: 'and' },
  messages: {},
  navigation: {},
  onDelete: () => {},
  publishSubmitting: false,
  races: [],
  sortModel: [],
  selectedRaces: [],
  columnVisibility: {},
  onColumnVisibilityChange: () => {},
};

export default withStyles(styles)(RaceTable);
