import React from 'react';
import PropTypes from 'prop-types';
import { Button, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

class ButtonMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      show: false,
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleOpen(event) {
    this.setState({
      anchorEl: event.currentTarget,
      show: true,
    });
  }

  handleClose() {
    this.setState({
      anchorEl: null,
      show: false,
    });
  }

  handleClick(onClick) {
    this.setState({
      anchorEl: null,
      show: false,
    });

    onClick();
  }

  renderOptions() {
    const { options } = this.props;

    return options.map((option, index) => (
      <MenuItem key={index} onClick={() => this.handleClick(option.onClick)}>
        {option.label}
      </MenuItem>
    ));
  }

  render() {
    const { anchorEl, show } = this.state;
    const {
      children,
      color,
      id,
      size,
      variant,
    } = this.props;

    return (
      <div>
        <Button
          aria-label="actions"
          aria-controls={id}
          aria-haspopup="true"
          color={color}
          endIcon={<MoreVertIcon />}
          onClick={this.handleOpen}
          size={size}
          variant={variant}
        >
          {children}
        </Button>

        <Menu
          id={id}
          anchorEl={anchorEl}
          keepMounted
          open={show}
          onClose={this.handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {this.renderOptions()}
        </Menu>
      </div>
    );
  }
}

ButtonMenu.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary']),
  id: PropTypes.string, // unique id to tie the button to the dropdown options (ex. "race-actions" )
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
  })),
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
};

ButtonMenu.defaultProps = {
  color: 'primary',
  id: 'more-options-dropdown',
  options: [],
  size: 'medium',
  variant: 'contained',
};

export default ButtonMenu;
