import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, DialogContent } from '@material-ui/core';
import { StepTitle } from 'components/RaceSteps';
import DistrictSelect from 'components/RaceSteps/StepSetUp/DistrictSelect';
import RaceName from 'components/RaceSteps/StepSetUp/RaceName';
import TypeOfRace from 'components/RaceSteps/StepSetUp/TypeOfRace';
import VotingOptions from 'components/RaceSteps/StepSetUp/VotingOptions';
import HowToModal from 'components/RaceSteps/StepSetUp/HowToModal';

const Container = ({
  guideId,
  messages,
  raceClassId,
  raceTypes: RACE_TYPES, // make sure we know it's constant
  stepState,
  votingOptions: VOTING_OPTIONS,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (document.cookie.indexOf('howToOpen') < 0) {
      setModalOpen(true);
    }
  }, []);

  const handleClose = () => {
    document.cookie = 'howToOpen=true; Secure';
    setModalOpen(false);
  };

  return (
    <div>
      {raceClassId === 'R' ? (
        <div>
          <StepTitle title={messages.raceTitle}>
            {messages.raceSubtitle}
          </StepTitle>

          <TypeOfRace
            messages={messages.type}
            raceTypes={RACE_TYPES}
            setTypeOfRace={stepState.setTypeOfRace}
            typeOfRace={stepState.typeOfRace}
          />

          <VotingOptions
            votingOptions={VOTING_OPTIONS}
            setVotingOption={stepState.setVotingOption}
            votingOption={stepState.votingOption}
            numberOfCandidates={stepState.numberOfCandidates}
            setNumberOfCandidates={stepState.setNumberOfCandidates}
            messages={messages.voting}
            votingOptionsErrors={stepState.votingOptionsErrors}
          />

          <DistrictSelect
            guideId={guideId}
            heading={messages.district.title}
            infoText={messages.district.raceHelp}
            onChange={stepState.handleDistrictChange}
            district={stepState.district}
            subheading={messages.district.raceSupporting}
            errors={stepState.districtErrors}
          />

          <RaceName
            name={stepState.name}
            setName={stepState.setName}
            submitting={stepState.submittingName}
            messages={messages.raceName}
            addNameErrors={stepState.addNameErrors}
          />
        </div>
      ) : (
        <div>
          <StepTitle title={messages.measureTitle}>
            {messages.measureSubtitle}
          </StepTitle>

          <DistrictSelect
            guideId={guideId}
            heading={messages.district.title}
            infoText={messages.district.measureHelp}
            onChange={stepState.handleDistrictChange}
            district={stepState.district}
            subheading={messages.district.measureSupporting}
          />

          <RaceName
            name={stepState.name}
            setName={stepState.setName}
            submitting={stepState.submittingName}
            messages={messages.measureName}
            addNameErrors={stepState.addNameErrors}
          />
        </div>
      )}
      <Modal
        aria-labelledby="modal-how-to-create-a-race"
        aria-describedby="modal-description"
        onClose={handleClose}
        open={modalOpen}
      >
        <DialogContent>
          <HowToModal
            messages={messages.howTo}
            onClose={handleClose}
          />
        </DialogContent>
      </Modal>
    </div>

  );
};

Container.propTypes = {
  guideId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  messages: PropTypes.object,
  raceClassId: PropTypes.string,
  raceTypes: PropTypes.array,
  stepState: PropTypes.shape({
    name: PropTypes.string,
    setName: PropTypes.func.isRequired,
    submittingName: PropTypes.bool.isRequired,
    setSubmittingName: PropTypes.func.isRequired,
    addNameErrors: PropTypes.object.isRequired,
    setAddNameErrors: PropTypes.func.isRequired,
    typeOfRace: PropTypes.number,
    setTypeOfRace: PropTypes.func,
    votingOption: PropTypes.number,
    setVotingOption: PropTypes.func,
    numberOfCandidates: PropTypes.string,
    setNumberOfCandidates: PropTypes.func,
    votingOptionsErrors: PropTypes.object,
    setVotingOptionsErrors: PropTypes.func,
    district: PropTypes.object, // we explicitly check null
    handleDistrictChange: PropTypes.func.isRequired,
    districtErrors: PropTypes.object.isRequired,
  }),
  votingOptions: PropTypes.array,
};

Container.defaultProps = {

};

export default Container;
