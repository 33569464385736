import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';

import { VGTContributionCandidatePropTypes } from 'components/features/campaign-finance/types';
import ConfirmationDialog from 'components/ConfirmationDialog';

import { useStyles } from './CandidateFinanceCard.styles';
import { CandidateMatch } from './CandidateMatch';
import { CandidateNoMatch } from './CandidateNoMatch';

const CandidateFinanceCard = ({
  messages,
  isSuperAdmin,
  candidateFinanceData,
  error,
  matchesUrl,
  onRemoveMatch,
  removeMatchSubmitting,
  removeConfirmationOpen,
  setRemoveConfirmationOpen,
}) => {
  const classes = useStyles();

  return (
    <Box marginBottom={2}>
      <Card>
        <CardContent>
          <Typography
            className={classes.title}
            color="primary"
            component="p"
            style={{ fontWeight: 600 }}
            variant="h6"
          >
            {messages.finance?.title}
          </Typography>

          {error && (
            <Typography color="error" component="p">
              {messages.finance?.error}
            </Typography>
          )}

          {!error && (
            <>
              {candidateFinanceData && candidateFinanceData.length > 0 && (
                <CandidateMatch
                  messages={messages}
                  candidateMatch={candidateFinanceData[0]}
                  isSuperAdmin={isSuperAdmin}
                  onRemoveMatch={() => setRemoveConfirmationOpen(true)}
                />
              )}

              {(!candidateFinanceData || candidateFinanceData.length === 0) && isSuperAdmin && (
                <CandidateNoMatch
                  messages={messages}
                  matchesUrl={matchesUrl}
                />
              )}
            </>
          )}
        </CardContent>
      </Card>

      <ConfirmationDialog
        heading={messages.finance?.modal?.heading}
        onCancel={() => setRemoveConfirmationOpen(false)}
        onConfirm={() => onRemoveMatch()}
        open={removeConfirmationOpen}
        submitting={removeMatchSubmitting}
        confirmButtonText={messages.finance?.modal?.confirm}
      >
        <Typography component="p" variant="body1">
          {messages.finance?.modal?.body}
        </Typography>
      </ConfirmationDialog>
    </Box>
  );
};

CandidateFinanceCard.propTypes = {
  messages: PropTypes.shape({
    finance: PropTypes.shape({
      title: PropTypes.string,
      labels: PropTypes.shape({
        match: PropTypes.string,
        source: PropTypes.string,
      }),
      empty: PropTypes.string,
      error: PropTypes.string,
      actions: PropTypes.shape({
        remove: PropTypes.string,
        find: PropTypes.string,
      }),
      modal: PropTypes.shape({
        heading: PropTypes.string,
        body: PropTypes.string,
        confirm: PropTypes.string,
      }),
    }),
  }),
  isSuperAdmin: PropTypes.bool,
  candidateFinanceData: PropTypes.arrayOf(PropTypes.shape(VGTContributionCandidatePropTypes)),
  error: PropTypes.bool,
  matchesUrl: PropTypes.string,
  onRemoveMatch: PropTypes.func,
  removeMatchSubmitting: PropTypes.bool,
  removeConfirmationOpen: PropTypes.bool,
  setRemoveConfirmationOpen: PropTypes.func,
};

CandidateFinanceCard.defaultProps = {
  messages: {
    finance: {
      title: 'Campaign Finance',
      labels: {
        match: 'Campaign Finance Match',
        source: 'Source',
      },
      empty: 'No campaign finance data exists for this candidate.',
      error: 'An error occurred while fetching campaign finance data.',
      actions: {
        remove: 'Remove Match',
        find: 'Find Match',
      },
      modal: {
        heading: 'Remove match?',
        body: 'Are you sure you want to remove the campaign finance match? You can always re-match the candidate later.',
        confirm: 'Yes, remove match',
      },
    },
  },
  isSuperAdmin: false,
  error: false,
  matchesUrl: '#',
  onRemoveMatch: () => {},
  removeMatchSubmitting: false,
  removeConfirmationOpen: false,
  setRemoveConfirmationOpen: () => {},
};

export {
  CandidateFinanceCard,
};
