import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import {
  Visibility,
  VisibilityOff,
} from '@material-ui/icons';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';

const BioQuestionInput = ({
  errors,
  htmlId,
  isPrivate,
  maxLengthValue,
  onChange,
  submitting,
  title,
  type,
  value,
  helpText,
}) => {
  const htmlForId = `bioQuestion_${htmlId}`;
  const arialLabel = `toggle ${title} visibility`;
  let useShow = isPrivate || false;
  if (type == 7) {
    useShow = true;
  }

  const [show, setShow] = useState(false);
  const isFacebook = useMemo(() => type === 'Facebook' || type === 10, [type]);
  const isTwitter = useMemo(() => type === 'Twitter' || type === 11, [type]);
  const getStartAdornment = () => {
    if (isFacebook) {
      return (
        <InputAdornment position="start" style={{
          marginRight: 0,
          color: 'dimgray',
        }}>
          <FacebookIcon style={{ marginRight: '6px' }} /> https://facebook.com/
        </InputAdornment>
      );
    }
    if (isTwitter) {
      return (
        <InputAdornment position="start" style={{
          marginRight: 0,
          color: 'dimgray',
        }}>
          <TwitterIcon style={{ marginRight: '6px' }} /> https://twitter.com/
        </InputAdornment>
      );
    }
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    onChange(newValue);
  };

  const hasErrors = () => {
    const keys = Object.keys(errors);
    return keys.length >= 1;
  };

  const getErrors = () => {
    const keys = Object.keys(errors);
    let errorString = '';
    keys.forEach(key => {
      errorString += errors[key];
    });

    return errorString;
  };

  return (
    <FormControl
      className='ga-my-8'
      fullWidth
      variant="filled"
    >
      <TextField
        disabled={submitting}
        InputProps={{
          endAdornment: useShow && (
            <InputAdornment position="end">
              <IconButton
                aria-label={arialLabel}
                edge="end"
                onClick={() => setShow(!show)}
                onMouseDown={(event) => event.preventDefault()}
              >
                {show ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
          startAdornment: getStartAdornment(),
        }}
        error={hasErrors()}
        id={htmlForId}
        inputProps={{ maxLength: maxLengthValue }}
        label={title}
        maxLength={maxLengthValue}
        onChange={handleChange}
        type={useShow && !show ? 'password' : 'text'}
        value={value}
        variant="filled"
        helperText={helpText}
      />

      {hasErrors() && (
        <FormHelperText error={true}>{ getErrors() } </FormHelperText>
      )}
    </FormControl>
  );
};

BioQuestionInput.propTypes = {
  errors: PropTypes.object,
  htmlId: PropTypes.number,
  isPrivate: PropTypes.bool,
  maxLengthValue: PropTypes.number,
  onChange: PropTypes.func,
  submitting: PropTypes.bool,
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.any,
  helpText: PropTypes.string,
};

BioQuestionInput.defaultProps = {
  errors: {},
  htmlId: 1,
  isPrivate: false,
  maxLengthValue: 750,
  onChange: () => {},
  submitting: false,
  type: 'Text',
  value: 'Default Value',
  helpText: '',
};

export default BioQuestionInput;
