import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/styles';
import { DataGridPro, LicenseInfo } from '@mui/x-data-grid-pro';

import theme from 'scripts/theme';

import SnackbarAlert from 'components/SnackbarAlert';
import EmptyState from 'components/EmptyState';
import ActionsDropdown from 'components/ActionsDropdown';
import ConfirmationDialog from 'components/ConfirmationDialog';
import PageHeader from 'components/PageHeader';

import fetchUtil from 'helpers/Fetch';
import useSavedTableSettings from 'hooks/useSavedTableSettings';

// Dynamic row height style for DataGrid component
const StyledDataGrid = withStyles({
  root: {
    '& .MuiDataGrid-renderingZone': {
      maxHeight: 'none !important',
    },
    '& .MuiDataGrid-cell': {
      lineHeight: 'unset !important',
      whiteSpace: 'normal',
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiDataGrid-row': {
      maxHeight: 'none !important',
    },
  },
})(DataGridPro);

LicenseInfo.setLicenseKey(
  '5354fa8b30068069673634540842cbf3T1JERVI6MzU2NzcsRVhQSVJZPTE2NzM0NzU4MDAwMDAsS0VZVkVSU0lPTj0x',
);

const ROWS_PER_PAGE_OPTIONS = [50, 100, 500, 1000, 5000];

const useStyles = makeStyles({
  headingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: '47px', // Prevents the container from jumping around when bulk actions show/hide
  },
  actionButton: {
    margin: '8px 20px',
    minWidth: '50px',
    textTransform: 'none',
  },
  dangerButton: {
    color: '#ac1b3d',
    '&:hover': {
      color: '#6B1126',
    },
  },
  tableContainer: {
    '& a': {
      color: '#0a3a5a',
      fontWeight: 500,
      textDecoration: 'none',
    },
  },
  table: {
    border: 'none',
  },
  emptyStateButton: {
    margin: '0 .8rem',
  },
});

const RecyclingBinContainer = ({ guideId, messages }) => {
  // state
  const [pageSize, setPageSize] = useState(50);
  const [showBulkActions, setShowBulkActions] = useState(false);
  const [showRestoreModal, setShowRestoreModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [deletedItems, setDeletedItems] = useState([]);

  const [filterModel, setFilterModel, sortModel, setSortModel] = useSavedTableSettings({ key: 'recyling-bin' });

  // other hooks
  const _snackbar = useRef(null);
  const classes = useStyles();

  useEffect(() => {
    onLoad();
  }, []);

  // -------------
  // API FUNCTIONS
  // -------------

  const onLoad = async () => {
    setLoading(true);
    const URL = `/api/v1/guide/${guideId}/recycling-bin`;
    const METHOD = 'GET';

    try {
      const deletedItemsResponse = await fetchUtil(URL, METHOD, {});
      setDeletedItems(deletedItemsResponse);

      setLoading(false);
    } catch (e) {
      console.error(e);
      _snackbar.current.show('error', messages.loadError);
    }
  };

  // ---------------------
  // TABLE ACTION HANDLERS
  // ---------------------

  const handlePermanentDelete = () => {
    setSubmitting(true);
    console.log('TODO: Handle Permanent Delete');
    console.log(selectedItems);
    setSubmitting(false);
  };

  // const handleShowPermanentDelete = (ids) => {
  //   setSelectedItems(ids);
  //   setShowDeleteModal(true);
  // };

  const handleRestore = async () => {
    setSubmitting(true);

    const URL = `/api/v1/guide/${guideId}/recycling-bin`;
    const METHOD = 'PUT';
    const BODY = {
      items: selectedItems.map(selectedId => ({
        id: selectedId,
        type: deletedItems.find(di => di.id === selectedId).type.discriminator,
      })),
    };

    try {
      setSubmitting(false);

      await fetchUtil(URL, METHOD, BODY);

      _snackbar.current.show('success', messages.restoreSuccess);
      setDeletedItems(deletedItems.filter(di => selectedItems.indexOf(di.id) < 0));
      setSelectedItems([]);
    } catch (e) {
      setSubmitting(false);
      console.error(e);
      if (e.status === 403) {
        _snackbar.current.show('error', messages.forbidden);
      } else {
        _snackbar.current.show('error', messages.restoreError);
      }
    }

    setShowRestoreModal(false);
  };

  const handleShowRestore = (ids) => {
    setSelectedItems(ids);
    setShowRestoreModal(true);
  };

  const handleSelectionModelChange = (selectionModel) => {
    setShowBulkActions(selectionModel.length > 0);
    setSelectedItems(selectionModel);
  };

  // ----------------
  // TABLE DEFINITION
  // ----------------

  const bulkActions = [
    // {
    //   name: messages.actions.permanent,
    //   color: 'danger',
    //   onClick: () => handleShowPermanentDelete(selectedItems),
    // },
    {
      name: messages.actions.restore,
      color: 'primary',
      onClick: () => handleShowRestore(selectedItems),
    },
  ];
  // const bulkActions = [];

  const truncate = (str, length) => {
    if (str) {
      return str.length > length ? `${str.slice(0, length)}...` : str;
    }
    return '';
  };

  const columns = [
    {
      field: 'name',
      headerName: messages.table.name,
      flex: 1.5,
      valueGetter: (params) => truncate(params.row.name, 90),
    },
    {
      field: 'type',
      headerName: messages.table.type,
      flex: 0.5,
      valueGetter: (params) => params.row.type.displayName,
    },
    {
      field: 'actions',
      headerName: messages.table.actions,
      resizable: false,
      sortable: false,
      width: 120,
      renderCell: (params) => {
        const options = [
          // { label: messages.actions.permanent, onClick: () => handleShowPermanentDelete([params.id]) },
          { label: messages.actions.restore, onClick: () => handleShowRestore([params.id]) },
        ];

        return <ActionsDropdown disabled={params.row.loading == true} options={options} />;
      },
    },
  ];

  // --------------
  // RENDER METHODS
  // --------------

  const renderEmptyState = () => (
    <EmptyState>
      <p className="ga-m-bottom--large">
        {messages.emptyStateText}
      </p>
    </EmptyState>
  );

  const renderDataTable = () => {
    if (loading) {
      return (
        <div className='spinner'>
          <CircularProgress />
        </div>
      );
    }

    if (!deletedItems || deletedItems <= 0) {
      return renderEmptyState();
    }

    return (
      <div className={classes.tableContainer}>
        <StyledDataGrid
          autoHeight={true}
          checkboxSelection
          className={classes.table}
          columns={columns}
          filterModel={filterModel}
          isRowSelectable={(params) => params.row.loading != true}
          onFilterModelChange={(model) => setFilterModel(model)}
          onPageSizeChange={size => setPageSize(size)}
          onSelectionModelChange={(selectionModel) => handleSelectionModelChange(selectionModel)}
          onSortModelChange={(model) => setSortModel(model)}
          pagination={true}
          pageSize={pageSize}
          rows={deletedItems}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          sortModel={sortModel}
        />
      </div>
    );
  };

  const renderBulkActions = () => {
    if (bulkActions && bulkActions.length > 0 && showBulkActions) {
      return (
        <div>
          {bulkActions.map((action, index) => (
            <Button
              className={action.color == 'danger' ? `${classes.actionButton} ${classes.dangerButton}` : classes.actionButton}
              color={action.color == 'danger' ? 'primary' : action.color}
              key={index}
              size="small"
              onClick={action.onClick} // eslint-disable-line react/jsx-handler-names
            >
              {action.name}
            </Button>
          ))}
        </div>
      );
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <SnackbarAlert ref={_snackbar} />

      <PageHeader
        heading={messages.title}
        subheading={messages.subheading}
      />

      {/* Restore */}
      <ConfirmationDialog
        heading={messages.restore.header}
        cancelButtonText={messages.actions.cancel}
        confirmtButtonText={messages.actions.confirm}
        onCancel={() => setShowRestoreModal(false)}
        onConfirm={() => handleRestore()}
        open={showRestoreModal}
        submitting={submitting}
      >
        <Typography component="p" variant="body1">
          {messages.restore.body}
        </Typography>
      </ConfirmationDialog>

      {/* Perm Delete */}
      <ConfirmationDialog
        heading={messages.permanent.header}
        cancelButtonText={messages.actions.cancel}
        confirmtButtonText={messages.actions.confirm}
        onCancel={() => setShowDeleteModal(false)}
        onConfirm={() => handlePermanentDelete()}
        open={showDeleteModal}
        submitting={submitting}
      >
        <Typography component="p" variant="body1">
          {messages.permanent.body}
        </Typography>
      </ConfirmationDialog>

      <div className="ga-container">
        <div className="mdc-layout-grid">
          <div className="mdc-layout-grid__inner">
            <div className="mdc-layout-grid__cell mdc-layout-grid__cell--align-middle mdc-layout-grid__cell--span-12">
              <div className={classes.headingContainer}>
                <Typography component="h2" variant="h3">{messages.header}</Typography>
                {renderBulkActions()}
              </div>

              {renderDataTable()}
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

RecyclingBinContainer.propTypes = {
  guideId: PropTypes.string.isRequired,
  messages: PropTypes.object.isRequired,
};

RecyclingBinContainer.defaultProps = {};

export default RecyclingBinContainer;
