import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ConfirmationDialog from 'components/ConfirmationDialog';
import CandidateInfoForm from 'components/Candidates/Partials/CandidateInfoForm';

const useStyles = makeStyles({
  destructiveButton: {
    minWidth: '75px',
    color: '#ac1b3d',
    '&:hover': {
      color: '#6B1126',
    },
  },
});

const CandidateCard = ({
  messages,
  cand,
  errorsCandidateList,
  idx,
  candidateList,
  setCandidateList,
  parties,
  removingCandidate,
  checkValidations,
}) => {
  const classes = useStyles();

  const [openRemoveCandidate, setOpenRemoveCandidate] = useState(false);

  const removeCandidate = (candidate) => {
    removingCandidate(candidate);
    setOpenRemoveCandidate(false);
  };

  return (
    <div>
      <Typography className="ga-my-12" color="primary" component="h3" variant="h3">
        {messages.headerPanel} {idx + 1}
      </Typography>

      <Card>
        <CardContent>
          <CandidateInfoForm
            messages={messages.form}
            candidate={cand}
            errors={errorsCandidateList}
            candidateList={candidateList}
            onSetCandidateList={setCandidateList}
            parties={parties}
            racesLoaded={false}
            checkValidations={checkValidations}
            hasActions={false}
          />
        </CardContent>
      </Card>

      <Grid item xs={12} className="ga-my-12">
        <Divider />
      </Grid>

      <Grid container justifyContent="flex-end" alignItems="center">
        <Grid item>
          <Button
            className={classes.destructiveButton}
            color="secondary"
            onClick={() => setOpenRemoveCandidate(true)}
            size="medium"
            variant="text"
          >
            {messages.removeBtn}
          </Button>

          <ConfirmationDialog
            cancelButtonText={messages.delete.cancel}
            confirmButtonText={messages.delete.confirm}
            heading={messages.delete.heading}
            onCancel={() => setOpenRemoveCandidate(false)}
            onConfirm={() => removeCandidate(cand)}
            open={openRemoveCandidate}
          >
            <Typography component="p" variant="body1">
              {messages.delete.body}
            </Typography>
          </ConfirmationDialog>
        </Grid>
      </Grid>
    </div>
  );
};

CandidateCard.propTypes = {
  messages: PropTypes.object,
  cand: PropTypes.object,
  errorsCandidateList: PropTypes.array,
  idx: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  candidateList: PropTypes.array,
  setCandidateList: PropTypes.func,
  parties: PropTypes.array,
  removingCandidate: PropTypes.func,
  checkValidations: PropTypes.bool,
};

CandidateCard.defaultProps = {
  messages: {},
  cand: {},
  errorsCandidateList: [],
  candidateList: [],
  setCandidateList: () => {},
  parties: [],
  removingCandidate: () => {},
};

export default CandidateCard;
