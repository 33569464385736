import { apiClient } from '@/lib/axios';

export const deleteCandidateFinanceMatch = (candidateId, financeId) => apiClient
  .delete(`candidates/${candidateId}/campaign-finances/${financeId}`)
  .then((response) => response.data);

export const deleteAllFinanceCandidateMatch = (financeId) => apiClient
  .delete(`campaign-finances/${financeId}/candidates`)
  .then((response) => response.data);

export const bulkDeleteFinanceCandidateMatch = (financeId, candidateIds) => apiClient
  .delete(`campaign-finances/${financeId}/candidates`, { data: { candidateIds } })
  .then((response) => response.data);
