import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Interweave from 'interweave';
import {
  // hiding until we get FAQ content
  // AccordionSummary,
  // AccordionDetails,
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
// hiding until we get FAQ content
// import MuiAccordion from '@material-ui/core/Accordion';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import vote411Screenshot from '../../../images/response-form-intro.png';

const useStyles = makeStyles((theme) => ({
  accordionContainer: {
    marginTop: '1.25rem',
  },
  accordionText: {
    paddingLeft: '0px',
  },
  button: {
    marginTop: '2.813rem',
  },
  buttonLink: {
    textDecoration: 'none',
  },
  expandedText: {
    color: theme.palette.primary.main,
  },
  image: {
    width: '100%',
    boxShadow: '0px 10px 17px rgba(0,0,0,0.2)',
  },
  faqTitle: {
    marginBottom: '0.625rem',
  },
  faqSubtext: {
    marginBottom: '2.188rem',
  },
  introContainer: {
    // commenting out until we unhide the FAQ section
    // borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
    marginBottom: '2.813rem',
  },
  affiliateName: {
    color: theme.palette.primary.main,
    fontWeight: '700',
  },
  responsiveHide: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  section: {
    margin: '1.5rem 0rem',
  },
}));

// hiding until we get FAQ content
// const Accordion = withStyles({
//   root: {
//     borderWidth: '0 0 1px 0',
//     borderColor: '#e5e5e5',
//     borderRadius: '0',
//     borderStyle: 'solid',
//     boxShadow: 'none',
//     '&:last-child': {
//       borderBottom: 0,
//     },
//     '&:before': {
//       display: 'none',
//     },
//     '&$expanded': {
//       margin: 'auto',
//     },
//   },
//   expanded: {},
// })(MuiAccordion);

const CandidateIntro = ({
  // faqs,
  affiliateName,
  messages,
  updatePage,
}) => {
  const classes = useStyles();
  const navigator = useNavigate();

  // hiding until we get FAQ content
  // const getFAQs = faqs.map((faq, index) => (
  //   <Accordion defaultExpanded={false} key={index}>
  //     <AccordionSummary
  //       expandIcon={
  //         <ExpandMoreIcon />
  //       }
  //       aria-controls={faq.id}
  //       id={faq.id}
  //       className={classes.accordionText}
  //       classes={{ expanded: classes.expandedText }}
  //     >
  //       <Typography variant="h6" component="h3">
  //         {faq.question}
  //       </Typography>
  //     </AccordionSummary>
  //     <AccordionDetails className={classes.accordionText}>
  //       <Typography variant="body1" component="p">
  //         {faq.answer}
  //       </Typography>
  //     </AccordionDetails>
  //   </Accordion>
  // ));

  const onContinue = () => {
    updatePage(1);
    navigator('/details');
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12} sm={12} md={6}>
          <Typography variant="h1" component="h1" color="textSecondary" paragraph>
            {messages.stepHeader}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={10} className={classes.introContainer}>
        <Grid item xs={12} sm={12} md={6}>
          <Typography variant="body1" component="p" className={classes.subtext}>
            <Interweave content={messages.vote411Intro} />
          </Typography>
          <div className={classes.section}>
            <Typography variant="body1" component="span" className={classes.subtext}>
              {messages.leagueInfoPreName} {' '}
            </Typography>
            <Typography variant="body1" component="span" className={classes.affiliateName}>
              {affiliateName}
            </Typography>
            <Typography variant="body1" component="span" className={classes.subtext}>
              {' '} {messages.leagueInfoPostName}
            </Typography>
          </div>
          <Typography variant="body1" component="p" className={classes.subtext}>
            <Interweave content={messages.ballotInfo} />
          </Typography>
          <Button
            color="secondary"
            variant="contained"
            className={classes.button}
            onClick={onContinue}
          >
            {messages.buttonText}
          </Button>
        </Grid>
        <Grid item xs={12} md={6} className={classes.responsiveHide}>
          <img src={vote411Screenshot} className={classes.image} />
        </Grid>
      </Grid>
      {/* hiding FAQ section for now until we get the content for it */}
      {/* <Grid container>
        <Grid item xs={12} sm={10} md={7}>
          <Typography variant="h5" component="h2" className={classes.faqTitle}>
            {messages.faqTitle}
          </Typography>
          <Typography variant="body2" component="p">
            <Interweave content={messages.faqSubtext} />
          </Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.accordionContainer}>
        <Grid item xs={12} sm={12} md={9}>
          {getFAQs}
        </Grid>
      </Grid> */}
    </div>
  );
};

CandidateIntro.propTypes = {
  // hiding until we get FAQ content
  // faqs: PropTypes.arrayOf(PropTypes.shape({
  //   question: PropTypes.string,
  //   answer: PropTypes.string,
  //   id: PropTypes.string,
  // })),
  affiliateName: PropTypes.string,
  messages: PropTypes.object.isRequired,
  updatePage: PropTypes.func.isRequired,
};

CandidateIntro.defaultProps = {
  faqs: [],
  affiliateName: '',
};

export default CandidateIntro;
