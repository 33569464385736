import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Grid,
  ThemeProvider,
  Typography,
  FormControlLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import CloseIcon from '@material-ui/icons/Close';
import EmailIcon from '@material-ui/icons/Email';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import PanToolIcon from '@material-ui/icons/PanTool';
import PinDropIcon from '@material-ui/icons/PinDrop';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import ResourceCard from 'components/ResourceCard.js';
import SnackbarAlert from 'components/SnackbarAlert';
import DashboardKpiCard from 'components/Guide/Partials/DashboardKpiCard.js';
import ViewAllCard from 'components/Guide/Partials/ViewAllCard.js';
import GuideHeader from 'components/Guide/Partials/GuideHeader.js';
import fetchUtil from 'helpers/Fetch';
import theme from 'scripts/theme';

const useStyles = makeStyles({
  dialogContent: {
    marginBottom: '1rem',
  },
  dialogActions: {
    justifyContent: 'flex-end',
  },
  submitCard: {
    backgroundColor: '#f7f7f7',
    border: 'none',
  },
});

const GuideDashboardContainer = ({
  guideName,
  guideStatus,
  published,
  races,
  language,
  resources,
  viewAllCards,
  messages,
}) => {
  // Variables and constants
  const classes = useStyles();
  const _snackbar = useRef();
  const emailRef = `mailto: ${messages.submitGuide.body.email}`;

  // hooks
  const [submitting, setSubmitting] = useState(false);
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [submitModalChecked, setSubmitModalChecked] = useState(false);

  const viewAllCardsArray = viewAllCards.map((cardData, index) => (
    <ViewAllCard
      key={index}
      icon={cardData.icon}
      subtitle={cardData.subtitle}
      title={cardData.title}
      url={cardData.url}
    />
  ));

  // Functions
  const handlePreviewGuide = () => {
    fetchUtil('/api/v1/guide/preview', 'GET')
      .then((data) => {
        if (data.link && data.loginCode) {
          console.log(data);
          const form = document.createElement('form');
          form.method = 'POST';
          form.target = '_blank';
          form.action = data.link;

          const usernameInput = document.createElement('input');
          usernameInput.name = 'username';
          usernameInput.value = data.username;
          form.appendChild(usernameInput);

          const loginCode = document.createElement('input');
          loginCode.name = 'loginCode';
          loginCode.value = data.loginCode;
          form.appendChild(loginCode);

          const ivSpec = document.createElement('input');
          ivSpec.name = 'ivSpec';
          ivSpec.value = data.ivSpec;
          form.appendChild(ivSpec);

          document.body.append(form);
          form.submit();
          form.parentNode.removeChild(form);
        }
      }).catch(() => {
        const err = 'The system encountered an issue loading the guide preview.';
        console.error(err);
        _snackbar.current.show('error', err);
      });
  };

  const handleOpenSubmitModal = () => {
    setShowSubmitModal(true);
  };

  const handleCloseSubmitModal = () => {
    setShowSubmitModal(false);
  };

  const handleSubmitGuide = () => {
    const urlRequest = '/api/v1/guide/submitGuide';
    const body = { guideName };
    setSubmitting(true);

    fetchUtil(urlRequest, 'POST', body)
      .then(() => {
        setSubmitting(false);
        setShowSubmitModal(false);
        _snackbar.current.show('success', messages.submitGuide.successSubmitGuide);
      }).catch(error => {
        console.error(error);
        setSubmitting(false);
        setShowSubmitModal(false);
        if (error.status == 403) {
          _snackbar.current.show('error', messages.forbidden);
        } else {
          _snackbar.current.show('error', messages.submitGuide.errorSubmitGuide);
        }
      });
  };

  // Render
  return (
    <ThemeProvider theme={theme}>
      <SnackbarAlert ref={_snackbar} />

      <GuideHeader
        guideName={guideName}
        guideStatus={guideStatus}
        published={published}
        subheading="You are viewing your League's current guide."
        onPreview={handlePreviewGuide}
        onSubmit={handleOpenSubmitModal}
      />

      <div className="ga-container">
        <div className="mdc-layout-grid">
          <div className="mdc-layout-grid__inner">
            <div className="mdc-layout-grid__cell mdc-layout-grid__cell--align-middle mdc-layout-grid__cell--span-12">
              <Grid container wrap="wrap-reverse" spacing={3}>
                <Grid item xs={12} sm={4} lg={3}>
                  <DashboardKpiCard
                    title={races.title}
                    subtitle={races.subtitle}
                    showIcon={races.showIcon}
                    // when kpi functionality is ready, pull in races.kpis
                    kpis={[]}
                    buttons={races.buttons}
                  />

                  <DashboardKpiCard
                    title={language.title}
                    subtitle={language.subtitle}
                    showIcon={language.showIcon}
                    // when kpi functionality is ready, pull in language.kpis
                    kpis={[]}
                    buttons={language.buttons}
                    // when kpi functionality is ready, pull in language.moretext
                    moretext={''}
                  />

                  <ResourceCard
                    heading="Helpful Resources"
                    resources={resources}
                  />
                </Grid>

                <Grid item xs={12} sm={8} lg={9}>
                  {viewAllCardsArray}
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>

      {/* Guide Submission Modal */}
      <Dialog
        onClose={handleCloseSubmitModal}
        aria-labelledby="submit-guide"
        open={showSubmitModal}
        maxWidth="md"
      >
        <DialogTitle disableTypography>
          <Typography variant="h1" component="h1">
            Submitting your guide
          </Typography>

          <IconButton aria-label="close" onClick={handleCloseSubmitModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Typography variant="body1" component="p" className={classes.dialogContent}>
            {messages.submitGuide.body.firstParagraph}
          </Typography>

          <Typography variant="body1" component="p" className={classes.dialogContent}>
            {messages.submitGuide.body.secondParagraph}
            <a className="ga-ml-4" href={emailRef}>{messages.submitGuide.body.email}</a>
          </Typography>

          <Card className={classes.submitCard} variant="outlined">
            <CardContent>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs={12} sm={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={submitModalChecked}
                        onChange={() => setSubmitModalChecked(!submitModalChecked)}
                        name="submitCheck"
                      />
                    }
                    label={guideName}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </DialogContent>

        <DialogActions className={classes.dialogActions}>
          <Button onClick={handleCloseSubmitModal}>
            Cancel
          </Button>

          <Button
            color="secondary"
            disabled={!submitModalChecked || submitting}
            onClick={handleSubmitGuide}
            variant="contained"
          >
            Submit Guide
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

GuideDashboardContainer.propTypes = {
  guideName: PropTypes.string.isRequired,
  guideStatus: PropTypes.shape({
    code: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  published: PropTypes.bool,
  races: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    showIcon: PropTypes.bool,
    kpis: PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.number.isRequired,
          title: PropTypes.string.isRequired,
        }),
      ),
    ),
    buttons: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        action: PropTypes.string,
      }),
    ),
  }),
  language: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    showIcon: PropTypes.bool,
    moretext: PropTypes.string,
    kpis: PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.number.isRequired,
          title: PropTypes.string.isRequired,
        }),
      ),
    ),
    buttons: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        action: PropTypes.string,
      }),
    ),
  }),
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
      ]),
      url: PropTypes.string,
    }),
  ),
  viewAllCards: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string,
      icon: PropTypes.element,
      link: PropTypes.string,
    }),
  ),
  messages: PropTypes.object,
};

GuideDashboardContainer.defaultProps = {
  published: false,
  races: {
    title: 'Races and Measures',
    subtitle: 'Guide Quick View',
    showIcon: true,
    kpis: [
      [
        { value: 60, title: 'Total' },
      ],
      [
        { value: 23, title: 'Unpublished' },
        { value: 24, title: 'Need questions' },
        { value: 23, title: 'Need districting' },
        { value: 40, title: 'Need candidates' },
      ],
    ],
    buttons: [
      { title: 'New Race', action: '/races/create' },
      { title: 'New Measure', action: '/measures/create' },
    ],
  },
  language: {
    title: 'Languages',
    subtitle: 'Create your voter guide in multiple languages! Set the languages you want, then use "Race Questions" to create the questions in all languages.',
    showIcon: false,
    kpis: [[{ value: 3, title: 'Total' }]],
    buttons: [{ title: 'Add More Languages', action: '/settings/languages' }],
    moretext: 'English, Spanish, French',
  },
  resources: [
    // hiding for launch
    {
      title: 'VOTE411 Admin Listserv',
      description: <>Request to subscribe:<br /><a href="mailto:VOTE411+subscribe@groups.io">VOTE411+subscribe@groups.io</a></>,
    },
    {
      title: 'League Management Site',
      description: 'Find videos and other resources to help you build your guide.',
      url: 'https://www.lwv.org/league-management/vote411-resources-toolkit',
    },
  ],
  viewAllCards: [
    {
      title: 'Candidates',
      subtitle: 'View and manage all candidates in your guide.',
      icon: <AccountBoxIcon fontSize="large" />,
      url: '/candidates',
    },
    {
      title: 'Mail Center',
      subtitle:
        'Contact candidates. Email invitations and reminders or export letters for snail mail.',
      icon: <EmailIcon fontSize="large" />,
      url: '/mail',
    },
    {
      title: 'Districts',
      subtitle:
        'See all district information in your guide and add new data if needed.',
      icon: <PinDropIcon fontSize="large" />,
      url: '/districts',
    },
    {
      title: 'Biographical Information',
      subtitle:
        'View and create biographical questions to assign to races.',
      icon: <FingerprintIcon fontSize="large" />,
      url: '/bioquestions',
    },
    {
      title: 'Race Questions',
      subtitle: 'Add or edit questions to ask candidates.',
      icon: <QuestionAnswerIcon fontSize="large" />,
      url: '/questions',
    },
    {
      title: 'Parties',
      subtitle: 'Manage parties in your guide. Add, edit, merge, delete.',
      icon: <PanToolIcon fontSize="large" />,
      url: '/parties',
    },
    {
      title: 'Categories',
      subtitle: 'Add or edit race categories in your guide.',
      icon: <AccountBalanceIcon fontSize="large" />,
      url: '/categories',
    },
  ],
  messages: {
    submitGuide: {
      body: {},
    },
  },
};
export default GuideDashboardContainer;
