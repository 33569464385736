import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Grid,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import clone from 'rfdc/default';

import SnackbarAlert from 'components/SnackbarAlert';
import PageActionsFooter from 'components/PageActionsFooter';
import PageActionsHeader from 'components/PageActionsHeader';
import CandidateEditForm from 'components/Candidates/Partials/CandidateEditForm';
import CandidateDetailsPageHeader from 'components/Candidates/Partials/CandidateDetailsPageHeader';
import CandidateBiographicalInfo from 'components/Candidates/Partials/CandidateBiographicalInfo';
import CandidateQuestionResponses from 'components/Candidates/Partials/CandidateQuestionResponses';
import { CandidateFinanceCard } from 'components/Candidates/Partials/CandidateFinanceCard';
import MailCenterModal from 'components/MailCenter/Partials/MailCenterModal';
import GAContainer from 'components/Layout/GAContainer';

import useCampaignFinanceStore from 'components/features/campaign-finance/stores/useCampaignFinanceStore';
import useUserRoleStore from 'components/stores/useUserRoleStore';

import fetchUtil, { VERBS } from 'helpers/Fetch';
import QuestionClass from 'helpers/QuestionClass';
import BioQuestionClass from 'helpers/BioQuestionClass';

import { useCandidateCampaignFinanceRecords } from '@/features/campaign-finance';

const styles = makeStyles({
  dangerButton: {
    color: '#ac1b3d',
    '&:hover': {
      color: '#6B1126',
    },
  },
  saveButton: {
    minWidth: '100px',
  },
  spinner: {
    position: 'absolute',
    top: '45%',
    left: '45%',
  },
  modalContent: {
    padding: '0',
  },
});

const CandidateDetailsContainer = ({
  navigation,
  messages,
  languages,
  candidate,
  guideId,
  typeOptions,
}) => {
  const classes = styles();
  const _snackbar = useRef();
  const msgActs = messages.pageActions || {};
  const { enableCampaignFinance } = useCampaignFinanceStore();
  const { isSuperAdmin } = useUserRoleStore();

  // hooks
  const [tempCandidate, setTempCandidate] = useState(undefined);
  const [submitting, setSubmitting] = useState(false);
  const [bioQuestionSetState, setBioQuestionSetState] = useState([]);
  const [raceQuestionSetState, setRaceQuestionSetState] = useState({});
  const [loading, setLoading] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [races, setRaces] = useState([]);
  const [parties, setParties] = useState([]);
  const [editCandidateHolder, setEditCandidateHolder] = useState(candidate);
  const [editCandidateErrors, setEditCandidateErrors] = useState({});
  const [editCandidateSubmitting, setEditCandidateSubmitting] = useState(false);
  const [editCandidatePartiesLoading, setEditCandidatePartiesLoading] = useState(false);
  const [editCandidateRacesLoading, setEditCandidateRacesLoading] = useState(false);
  const [sendEmailState, setSendEmailState] = useState({
    open: false,
    candidateId: '',
  });
  const [template, setTemplate] = useState(-1);
  const [templates, setTemplates] = useState([]);
  const [templatesLoaded, setTemplatesLoaded] = useState(false);
  const [guideDefaultAnswerLength, setGuideDefaultAnswerLength] = useState(1000);
  const [removeConfirmationOpen, setRemoveConfirmationOpen] = useState(false);

  const {
    isLoading: cfLoading,
    isError: cfError,
    data: campaignFinanceData,
    refetch: refetchCampaignFinance,
  } = useCandidateCampaignFinanceRecords({ candidateId: candidate.id });

  const campaignFinanceDataExists = useMemo(() => {
    if (cfLoading || cfError) {
      return false;
    }
    return campaignFinanceData && campaignFinanceData.data?.length > 0;
  }, [campaignFinanceData, cfLoading, cfError]);

  useEffect(() => {
    loadAndSetQuestionsAndAnswers();
    setTempCandidate({ ...candidate });
  }, [candidate]);

  // Functions
  const handleSave = async () => {
    setSubmitting(true);

    const bioQuestionErrors = bioQuestionSetState.filter(
      (question) => Object.keys(question.errors).length > 0,
    );
    if (bioQuestionErrors.length === 0) {
      try {
        // save bio questions
        const updatedBioQuestions = [...bioQuestionSetState];
        await Promise.all(
          updatedBioQuestions.map(async (question) => {
            let httpMethod = 'POST';
            const httpBody = {
              questionId: question.id,
              answer: question.apiAnswer,
            };
            if (question.answerId !== -1) {
              // answered before
              httpMethod = 'PUT';
              httpBody.id = question.answerId;
            }
            const res = await fetchUtil(
              `/api/v1/guide/${guideId}/candidate/${candidate.id}/bioanswer`,
              httpMethod,
              httpBody,
            );
            console.log('res: ', res);
            question.answerId = res.id;
          }),
        );

        // update state with resulting ids (created on new answer)
        setBioQuestionSetState(updatedBioQuestions);

        // save race questions
        const updatedRaceQuestions = [];
        await Promise.all(
          raceQuestionSetState.questions.map(async (question) => {
            const HTTP_METHOD = 'PUT';
            // cool es6 getter!
            const HTTP_BODY = question.apiBody;

            const res = await fetchUtil(
              `/api/v1/guide/${guideId}/candidate/${candidate.id}/raceanswer`,
              HTTP_METHOD,
              HTTP_BODY,
            );
            updatedRaceQuestions.push(res);
          }),
        );

        const raceQuestionSetStateCopy = { ...raceQuestionSetState };
        raceQuestionSetStateCopy.questions.forEach((question) => {
          const updatedQuestion = updatedRaceQuestions.find(
            (q) => q.sourceId === question.id,
          );
          question.answerid = updatedQuestion.id;
        });
        setRaceQuestionSetState(raceQuestionSetStateCopy);
        _snackbar.current.show('success', msgActs.successSave);
      } catch (e) {
        console.error(e);
        _snackbar.current.show('error', msgActs.failedSave);
      }
    } else {
      _snackbar.current.show('error', msgActs.failedSave);
    }

    setSubmitting(false);
  };

  const loadAndSetQuestionsAndAnswers = async () => {
    setTemplatesLoaded(false);

    fetchUtil(`/api/v1/guide/${guideId}/email-template`, 'GET', {})
      .then((response) => {
        setTemplates(response);
        if (response && response.length > 0) {
          setTemplate(response[0].id);
        }
        setTemplatesLoaded(true);
      })
      .catch((error) => {
        _snackbar.current.show(
          'error',
          'There was an error retrieving email templates for this guide. Please try again.',
        );
        console.error(error);
        setTemplatesLoaded(true);
      });

    try {
      // bioSet was null, which means use all questions.
      if (candidate.race.bioSet == null) {
        setLoading(true);
        let allQuestions = await fetchUtil(
          `/api/v1/guide/${guideId}/bioquestions`,
          'GET',
          {},
        );
        allQuestions.sort((a, b) => a.index - b.index);
        allQuestions = allQuestions.map((q) => {
          const answer = candidate.bioAnswers.find((a) => a.sourceId === q.id);
          const bioQuestionObject = new BioQuestionClass(q, answer);
          return bioQuestionObject;
        });
        setBioQuestionSetState(allQuestions);
        setLoading(false);
      } else {
        let bioSetQuestions = [...candidate.race.bioSet.bioQuestions];
        bioSetQuestions = bioSetQuestions.map((q) => {
          const answer = candidate.bioAnswers.find((a) => a.sourceId === q.id);
          const bioQuestionObject = new BioQuestionClass(q, answer);
          return bioQuestionObject;
        });
        setBioQuestionSetState(bioSetQuestions);
      }

      // set race question state with answers added
      // Note: Fixing issue when the questionSet is null
      const raceQuestionsWithoutAnswers = candidate.race
        && candidate.race.questionSet
        && candidate.race.questionSet.questions
        ? [...candidate.race.questionSet.questions]
        : [];
      const formattedQuestions = raceQuestionsWithoutAnswers.map((q) => {
        // find answer
        const answer = candidate.raceAnswers.find((a) => a.sourceId == q.id);
        // construct question class object
        const questionObject = new QuestionClass(q, answer);
        // return question class object
        return questionObject;
      });
      setRaceQuestionSetState({
        ...candidate.race.questionSet,
        questions: formattedQuestions,
      });

      // get the default max length of answers from the guide
      const guideDefaultLength = await fetchUtil(
        `/api/v1/guide/${guideId}/race-questions/defaultMaxAnswerLength`,
        'GET',
        {},
      );
      if (guideDefaultLength && guideDefaultLength.length) {
        setGuideDefaultAnswerLength(guideDefaultLength.length);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      showError('load', false); // action = load, message = none
    }
  };

  const showError = (action, message) => {
    const snackbarMessage = message || getSnackbarMessage(action);
    _snackbar.current.hide();
    _snackbar.current.show('error', snackbarMessage);
  };

  const showSuccess = (message) => {
    _snackbar.current.hide();
    _snackbar.current.show('success', message);
  };

  const handleArchive = () => {
    const archive = !tempCandidate.deleted == true;
    const url = archive
      ? `/api/v1/guide/${guideId}/candidate/archive`
      : `/api/v1/guide/${guideId}/candidate/unarchive`;

    fetchUtil(url, 'PUT', { candidateIds: [candidate.id] })
      .then(() => {
        const tempCandidateCopy = { ...tempCandidate };
        const snackbarMessage = archive
          ? 'Candidate successfully archived!'
          : 'Candidate successfully unarchived!';
        tempCandidateCopy.deleted = !tempCandidate.deleted;
        setTempCandidate(tempCandidateCopy);
        showSuccess(snackbarMessage);
      })
      .catch((error) => {
        console.error(error);
        const action = archive ? 'archive' : 'unarchive';
        let message = `There was an issue attempting to ${action} this candidate. Try again and contact your guide admin if this issue continues.`;

        if (error.status === 403) {
          message = `Only guide admins can ${action} candidates. Please contact your guide admin.`;
        }

        showError('archive/unarchive candidate', message);
      });
  };

  const handleBioQuestionAnswerUpdate = (
    questionId,
    newValue,
    fieldName = '',
  ) => {
    const bioQuestionSetStateCopy = [...bioQuestionSetState];
    const questionChanged = bioQuestionSetStateCopy.find(
      (q) => q.id === questionId,
    );
    if (questionChanged.type == BioQuestionClass.ADDRESS_TYPE) {
      questionChanged.updateMailingAddress(fieldName, newValue);
    } else {
      questionChanged.updateAnswer(newValue);
    }
    setBioQuestionSetState(bioQuestionSetStateCopy);
  };

  const handleRaceQuestionAnswerUpdate = (questionId, languageId, newValue) => {
    const raceQuestionSetStateCopy = { ...raceQuestionSetState };
    const questionChanged = raceQuestionSetStateCopy.questions.find(
      (q) => q.id === questionId,
    );
    // call updateAnswer, defined in QuestionClass.js
    if (questionChanged.type !== 4) {
      questionChanged.updateAnswer(languageId, newValue);
    } else if (languageId === 'url') {
      // this and below are for youtube questions
      questionChanged.updateYoutubeURL(newValue);
    } else {
      questionChanged.updateYoutubeDescription(newValue);
    }
    setRaceQuestionSetState(raceQuestionSetStateCopy);
  };

  const handleViewActivityLog = () => {
    window.location.href = `/races/${candidate.race.id}/candidates/${candidate.id}/eventLog`;
  };

  const handleSetOpenSendEmail = (open) => {
    const copyState = { ...sendEmailState };
    copyState.open = open;
    copyState.candidateId = open ? [candidate.id] : null;
    setSendEmailState(copyState);
  };

  const handleConfirmSendEmail = () => {
    handleSetOpenSendEmail(false, null);
    window.location.href = `${navigation.sendEmail}?candidateIds=${JSON.stringify(sendEmailState.candidateId)}&templateId=${template}`;
  };

  const handleSendEmail = () => {
    let canSend = true;
    // check to see if candidate has an email address to send to
    if (
      !(candidate?.contactUser?.email && candidate?.contactUser?.email?.trim() != '')
    ) {
      canSend = false;
    }
    if (canSend) {
      handleSetOpenSendEmail(true, candidate.id);
    } else {
      showError(messages.sendEmail.invalid);
    }
  };

  // --------------
  // #region Edit Candidate Modal
  // --------------
  const onEditModalOpen = () => {
    // check if parties and races are empty before reloading them
    if (parties.length <= 0) {
      loadAndSetParties();
    }
    if (races.length <= 0) {
      loadAndSetRaces();
    }
    setShowEditModal(true);
  };

  const loadAndSetRaces = async () => {
    // query races
    try {
      setEditCandidateRacesLoading(true);
      const racesList = await fetchUtil(
        `/api/v1/guide/${guideId}/race`,
        'GET',
        {},
      );
      setRaces(racesList);
      setEditCandidateRacesLoading(false);
    } catch (error) {
      console.error(error);
      setEditCandidateRacesLoading(false);
      showError('loadRaces', false);
    }
  };

  const loadAndSetParties = async () => {
    // query parties
    try {
      setEditCandidatePartiesLoading(true);
      const partiesList = await fetchUtil(
        `/api/v1/guide/${guideId}/parties`,
        'GET',
        {},
      );
      setParties(partiesList);
      setEditCandidatePartiesLoading(false);
    } catch (error) {
      setEditCandidatePartiesLoading(false);
      showError('loadParties', false);
    }
  };

  const onEditModalClose = () => {
    setShowEditModal(false);
    // Reset holder to remove any updates made before cancel
    setEditCandidateHolder(clone(tempCandidate));
  };

  const getCandidateState = (stateName) => {
    switch (stateName) {
      case 'Created':
        return 1;
      case 'Invited':
        return 2;
      case 'Responded':
        return 3;
      case 'Accepted':
        return 4;
      default:
        return 0;
    }
  };

  // Format object to allow the backend to accept it
  const formatCandidateObjectForUpdate = (candidateObject) => ({
    name: candidateObject.name,
    lastName: candidateObject.lastName,
    raceId: candidateObject.race.id,
    inFavor: candidateObject.inFavor,
    partiesIds: candidateObject.parties.map((party) => party.id),
    candidateState: getCandidateState(candidateObject.state.name),
    contactName: candidateObject.contactUser.name,
    contactEmail: candidateObject.contactUser.email,
    candidateClass: candidateObject.race.clazz.id == 'R' ? 'C' : 'R',
  });

  const onEditModalSubmit = async () => {
    try {
      setEditCandidateSubmitting(true);

      if (editCandidateHolder.candidatePhotoSourceRemoved) {
        await fetchUtil(
          `/api/v1/guide/${guideId}/race/${candidate.race.id}/candidate/${candidate.id}/removePhoto`,
          'POST',
          {},
        );
      }

      // update the photo, if it has been changed:
      if (editCandidateHolder.candidatePhotoSource) {
        const formData = new FormData();
        formData.append(
          'photo',
          editCandidateHolder.candidatePhotoSource,
          editCandidateHolder.candidatePhotoSource.name,
        );
        await fetchUtil(
          `/api/v1/guide/${guideId}/race/${candidate.race.id}/candidate/${candidate.id}/uploadPhoto`,
          'POST',
          formData,
          false,
          true,
        );
      }

      // This is to allow the backend to potentially update the race id if the race changes
      const formattedCandidateHolder = formatCandidateObjectForUpdate(editCandidateHolder);
      const updatedCandidate = await fetchUtil(
        `/api/v1/guide/${guideId}/race/${candidate.race.id}/candidate/${candidate.id}`,
        'PUT',
        formattedCandidateHolder,
      );

      // Redirecting with the new race ID in the url string is required to avoid scala error
      if (candidate.race.id !== updatedCandidate.race.id) {
        window.location.href = `/races/${updatedCandidate.race.id}/candidates/${updatedCandidate.id}`;
      } else if (editCandidateHolder.candidatePhotoSource) {
        // meaning the photo has been changed
        updatedCandidate.imageUri = editCandidateHolder.candidatePhoto;
        setEditCandidateHolder(updatedCandidate);
      } else {
        updatedCandidate.imageUri = editCandidateHolder.candidatePhoto
          ? editCandidateHolder.candidatePhoto
          : editCandidateHolder.imageUri;
      }
      setTempCandidate(updatedCandidate);
      setEditCandidateSubmitting(false);
      setShowEditModal(false);
    } catch (error) {
      console.error(error);
      setEditCandidateSubmitting(false);
      setEditCandidateErrors(error);

      if (Object.prototype.hasOwnProperty.call(error, 'data')) {
        // Generic error unknown error
        showError(false, error.data);
        return;
      }

      // Validation error
      showError('candidateSave', false);
    }
  };

  const onEditModalChange = (name, value) => {
    const newFormValues = clone(editCandidateHolder);

    if (name.startsWith('contactUser')) {
      // Is contact user update
      const subFieldName = name.split('.')[1];
      if (
        Object.prototype.hasOwnProperty.call(
          newFormValues.contactUser,
          subFieldName,
        )
      ) {
        newFormValues.contactUser[subFieldName] = value;
      }
    } else if (name.startsWith('candidatePhoto')) {
      const photoPreviewUrl = URL.createObjectURL(value);
      newFormValues[name] = photoPreviewUrl;
      newFormValues.candidatePhotoSource = value;
    } else if (name === 'state') {
      newFormValues.state.name = value;
    } else if (Object.prototype.hasOwnProperty.call(newFormValues, name)) {
      newFormValues[name] = value;
    }

    setEditCandidateHolder(newFormValues);
  };

  const onEditModalRemovePhoto = () => {
    const newFormValues = clone(editCandidateHolder);
    newFormValues.candidatePhotoSourceRemoved = true;
    newFormValues.candidatePhotoSource = null;
    newFormValues.candidatePhoto = '';
    newFormValues.imageUri = null;
    setEditCandidateHolder(newFormValues);
  };

  const handleRemoveCFMatch = async () => {
    setSubmitting(true);
    try {
      const cfCandidateId = campaignFinanceData.data && campaignFinanceData.data.length > 0 ? campaignFinanceData.data[0].id : '';
      await fetchUtil(
        `/api/v1/candidates/${candidate.id}/campaign-finances/${cfCandidateId}`,
        VERBS.DELETE,
      );
      showSuccess(messages.finance?.successRemove);
      setRemoveConfirmationOpen(false);
      refetchCampaignFinance();
    } catch (error) {
      console.error(error);
      showError(null, messages.finance?.errorRemove);
    } finally {
      setSubmitting(false);
    }
  };

  const getSnackbarMessage = (action) => {
    switch (action) {
      case 'candidateSave':
        return messages.editModal.errorMessageFailedSave;
      case 'loadParties':
        return messages.editModal.errorMessageFailedLoadParties;
      case 'loadRaces':
        return messages.editModal.errorMessageFailedLoadRaces;
      case 'load':
        return 'There was an error loading the bio questions or race questions. Please try again.';
      default:
        return 'An unknown error occurred. Please contact your guide admin.';
    }
  };

  // #region render

  return (
    <>
      <SnackbarAlert ref={_snackbar} />

      <CandidateDetailsPageHeader
        candidate={tempCandidate}
        messages={messages.header || {}}
      />

      <PageActionsHeader>
        {/* <Button
          className={classes.dangerButton}
          color="secondary"
          disabled={submitting}
          size="small"
        >
          {msgActs.delete}
        </Button> */}

        <Button
          color="primary"
          disabled={submitting}
          onClick={handleArchive}
          size="small"
        >
          {tempCandidate && tempCandidate?.deleted
            ? msgActs.unarchive
            : msgActs.archive}
        </Button>

        <Button
          color="primary"
          disabled={submitting}
          onClick={handleViewActivityLog}
          size="small"
        >
          {msgActs.viewActivityLog}
        </Button>

        <Button
          color="primary"
          disabled={submitting}
          onClick={handleSendEmail}
          size="small"
        >
          {msgActs.sendAnEmail}
        </Button>

        <Button
          color="primary"
          disabled={submitting}
          onClick={onEditModalOpen}
          size="small"
        >
          {msgActs.editCandidateInfo}
        </Button>

        <Button
          className={classes.saveButton}
          color="secondary"
          disabled={submitting}
          onClick={handleSave}
          size="small"
          variant="contained"
        >
          {msgActs.save}
        </Button>
      </PageActionsHeader>

      <GAContainer>
        {/* Bio and Race Questions */}
        {!loading && (
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={4}
              className="ga-p-8"
            >
              <CandidateBiographicalInfo
                bioQuestionSet={bioQuestionSetState}
                messages={messages.candidateBioInfo || {}}
                onUpdate={handleBioQuestionAnswerUpdate}
                submitting={submitting}
              />
            </Grid>

            <Grid
              item
              xs={8}
              className="ga-p-8"
            >
              {(!cfLoading) && enableCampaignFinance && (campaignFinanceDataExists || isSuperAdmin) && (
                <CandidateFinanceCard
                  messages={messages}
                  candidateFinanceData={campaignFinanceData.data}
                  isSuperAdmin={isSuperAdmin}
                  error={cfError}
                  matchesUrl={`/races/${candidate.race.id}/candidates/${candidate.id}/campaign-finance`}
                  onRemoveMatch={handleRemoveCFMatch}
                  removeConfirmationOpen={removeConfirmationOpen}
                  setRemoveConfirmationOpen={setRemoveConfirmationOpen}
                />
              )}
              <CandidateQuestionResponses
                languages={languages}
                messages={messages.candidateRaceQuestion || {}}
                onChange={handleRaceQuestionAnswerUpdate}
                questionSet={raceQuestionSetState}
                questionTypeOptions={typeOptions}
                submitting={submitting}
                defaultAnswerLength={guideDefaultAnswerLength}
              />
            </Grid>
          </Grid>
        )}

        {loading && (
          <div className="spinner">
            <CircularProgress />
          </div>
        )}
      </GAContainer>

      {/* Edit Modal */}
      <Dialog
        aria-labelledby="edit-race-question-title"
        maxWidth="md"
        open={showEditModal}
        onClose={onEditModalClose}
        className={classes.modalContainer}
      >
        <DialogTitle
          id="edit-race-question-title"
          disableTypography
        >
          <h1 className="mdc-typography--headline1">Edit Candidate Info</h1>

          <IconButton
            onClick={onEditModalClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent className={classes.modalContent}>
          <CandidateEditForm
            candidate={editCandidateHolder}
            errors={editCandidateErrors}
            messages={messages.editModal}
            onCancel={onEditModalClose}
            onSubmit={onEditModalSubmit}
            onChange={onEditModalChange}
            onRemovePhoto={onEditModalRemovePhoto}
            parties={parties}
            races={races}
            submitting={
              editCandidateSubmitting || editCandidatePartiesLoading || editCandidateRacesLoading
            }
          />
        </DialogContent>
      </Dialog>

      {/* Send Email Modal */}
      {templatesLoaded && (
        <>
          <MailCenterModal
            messages={messages.sendEmail}
            template={templates.find((t) => t.id === template)}
            onSetTemplate={setTemplate}
            openEditTemplates={sendEmailState.open}
            templates={templates}
            templatesLoaded={templatesLoaded}
            onCancel={() => handleSetOpenSendEmail(false, null)}
            onConfirm={handleConfirmSendEmail}
            submitting={sendEmailState.submitting}
          />
        </>
      )}

      <PageActionsFooter sticky>
        {/* <Button
          className={classes.dangerButton}
          color="secondary"
          disabled={submitting}
          size="small"
        >
          {msgActs.delete}
        </Button> */}

        <Button
          color="primary"
          disabled={submitting}
          onClick={handleArchive}
          size="small"
        >
          {tempCandidate && tempCandidate?.deleted
            ? msgActs.unarchive
            : msgActs.archive}
        </Button>

        <Button
          color="primary"
          disabled={submitting}
          onClick={handleViewActivityLog}
          size="small"
        >
          {msgActs.viewActivityLog}
        </Button>

        <Button
          color="primary"
          disabled={submitting}
          onClick={handleSendEmail}
          size="small"
        >
          {msgActs.sendAnEmail}
        </Button>

        <Button
          color="primary"
          disabled={submitting}
          onClick={onEditModalOpen}
          size="small"
        >
          {msgActs.editCandidateInfo}
        </Button>

        <Button
          className={classes.saveButton}
          color="secondary"
          disabled={submitting}
          onClick={handleSave}
          size="small"
          variant="contained"
        >
          {msgActs.save}
        </Button>
      </PageActionsFooter>
    </>
  );
};

CandidateDetailsContainer.propTypes = {
  languages: PropTypes.array.isRequired,
  navigation: PropTypes.object,
  messages: PropTypes.object.isRequired,
  candidate: PropTypes.object.isRequired,
  guideId: PropTypes.string.isRequired,
  typeOptions: PropTypes.array.isRequired,
};

CandidateDetailsContainer.defaultProps = {
  navigation: {},
  // adding default for unit tests
  candidate: {},
};

export { CandidateDetailsContainer };
