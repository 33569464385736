import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    paddingTop: '1.25rem',
  },
  selectedContainer: {
    paddingTop: '1rem',
  },
  itemRows: {
    paddingBottom: '0.938rem',
    marginBottom: '0.938rem',
    borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
    '&:last-child': {
      borderBottom: 'none',
      paddingBottom: '0rem',
      marginBottom: '0rem',
    },
  },
  label: {
    paddingBottom: '0.75rem',
  },
  listContainer: {
    paddingTop: '0.5rem',
  },
  emptyStateText: {
    textAlign: 'center',
  },

});

const AllBioSetQuestions = ({
  emptyStateText,
  header,
  questionListLabel,
  questions,
}) => {
  const classes = useStyles();

  return (
    <Paper elevation={3} className="card-container">
      <div>
        <Typography color="primary" component="h3" variant="h3">
          {header}
        </Typography>

        <div className={classes.container}>
          <div className={classes.selectedContainer}>
            {questions.length > 0 ? (
              <>
                <p className={`${classes.label} card-label`}>{questionListLabel}</p>
                <div className={classes.listContainer}>
                  {questions.map(q => (
                    <Grid item className={classes.itemRows} key={q.id}>
                      <p className="card-value">{q.name}</p>
                    </Grid>
                  ))}
                </div>
              </>
            ) : (
              <div className={classes.container}>
                <div className="empty-state-container">
                  <div className={classes.emptyStateText}>
                    <p className="ga-m-bottom--medium">{emptyStateText}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Paper>
  );
};

AllBioSetQuestions.propTypes = {
  header: PropTypes.string,
  questionListLabel: PropTypes.string,
  questions: PropTypes.array,
  emptyStateText: PropTypes.string,
};

AllBioSetQuestions.defaultProps = {
  header: 'Using all the biographical questions within your guide',
  questionListLabel: 'All biographical questions',
  emptyStateText: 'You have not created any biographical questions yet.',
  questions: [],
};

export default AllBioSetQuestions;
