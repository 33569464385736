import React from 'react';
import PropTypes, { arrayOf } from 'prop-types';
import { Grid, Button } from '@material-ui/core';

const Stepper = ({
  disabled,
  messages,
  onBack,
  onCancel,
  onContinue,
  onCreateNew,
  onCreateNewMeasure,
  onSkip,
  onSaveExit,
  onSaveContinue,
  onSaveRace,
  onViewEdit,
  raceClassId,
  visibility,
}) => (
  <Grid container alignItems="center" justifyContent="space-between">
    {/* Left Buttons */}
    <Grid item>
      {visibility.indexOf('back') >= 0 && (
        <Button
          color="secondary"
          disabled={disabled}
          onClick={onBack}
          variant="contained"
        >{messages.back}</Button>
      )}
    </Grid>

    {/* Right Buttons */}
    <Grid item>
      <Grid container spacing={2}>
        {visibility.indexOf('cancel') >= 0 && (
          <Grid item>
            <Button
              color="primary"
              disabled={disabled}
              onClick={onCancel}
              variant="text"
            >{messages.cancel}</Button>
          </Grid>
        )}
        {visibility.indexOf('skip') >= 0 && (
          <Grid item>
            <Button
              color="secondary"
              disabled={disabled}
              onClick={onSkip}
              variant="outlined"
            >{messages.skip}</Button>
          </Grid>
        )}
        {visibility.indexOf('continue') >= 0 && (
          <Grid item>
            <Button
              color="secondary"
              disabled={disabled}
              onClick={onContinue}
              variant="contained"
            >{messages.continue}</Button>
          </Grid>
        )}
        {visibility.indexOf('saveExit') >= 0 && (
          <Grid item>
            <Button
              color="secondary"
              disabled={disabled}
              onClick={onSaveExit}
              variant="outlined"
            >{messages.saveExit}</Button>
          </Grid>
        )}
        {visibility.indexOf('saveContinue') >= 0 && (
          <Grid item>
            <Button
              color="secondary"
              disabled={disabled}
              onClick={onSaveContinue}
              variant="contained"
            >{messages.saveContinue}</Button>
          </Grid>
        )}
        {visibility.indexOf('saveRace') >= 0 && (
          <Grid item>
            <Button
              color="secondary"
              disabled={disabled}
              onClick={onSaveRace}
              variant="contained"
            >{raceClassId === 'R' ? messages.saveRace : messages.saveMeasure}</Button>
          </Grid>
        )}
        {visibility.indexOf('viewEdit') >= 0 && (
          <Grid item>
            <Button
              color="secondary"
              disabled={disabled}
              onClick={onViewEdit}
              variant="contained"
            >{raceClassId === 'R' ? messages.raceViewEdit : messages.measureViewEdit}</Button>
          </Grid>
        )}
        {visibility.indexOf('createNew') >= 0 && (
          <Grid item>
            <Button
              color="secondary"
              disabled={disabled}
              onClick={onCreateNew}
              variant="contained"
            >{messages.createNew}</Button>
          </Grid>
        )}
        {visibility.indexOf('createNew') >= 0 && (
          <Grid item>
            <Button
              color="secondary"
              disabled={disabled}
              onClick={onCreateNewMeasure}
              variant="contained"
            >{messages.createNewMeasure}</Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  </Grid>
);

Stepper.propTypes = {
  disabled: PropTypes.bool,
  messages: PropTypes.object.isRequired,
  onBack: PropTypes.func,
  onCancel: PropTypes.func,
  onContinue: PropTypes.func,
  onCreateNew: PropTypes.func,
  onCreateNewMeasure: PropTypes.func,
  onSkip: PropTypes.func,
  onSaveExit: PropTypes.func,
  onSaveContinue: PropTypes.func,
  onSaveRace: PropTypes.func,
  onViewEdit: PropTypes.func,
  raceClassId: PropTypes.string,
  visibility: arrayOf(PropTypes.string).isRequired,
};

Stepper.defaultProps = {
  disabled: false,
  onBack: () => {},
  onCancel: () => {},
  onContinue: () => {},
  onCreateNew: () => {},
  onCreateNewMeasure: () => {},
  onSkip: () => {},
  onSaveExit: () => {},
  onSaveContinue: () => {},
  onSaveRace: () => {},
  onViewEdit: () => {},
};

export default Stepper;
