import moment from 'moment';

export default class GaUtils {
  /**
   * Check if the object is null or undefined
   *
   * @param {any} val Value to check
   * @returns {boolean}
   */
  static isNullOrUndefined = (val) => val == null || val == undefined;

  /**
   * Check if the object is null or undefined or empty
   *
   * @param {any} val Value to check
   * @param {boolean} ignoreSpaces ignore whitespace, default = false
   * @returns {boolean}
   */
  static isNullOrEmpty = (val, ignoreSpaces = false) => {
    let result = this.isNullOrUndefined(val);
    if (!result && typeof val === 'string') {
      if (ignoreSpaces) {
        result = val.trim() == '';
      } else {
        result = val == '';
      }
    }
    return result;
  };

  /**
   * generate a unique GUID
   *
   * @returns {string}
   */
  static generateGUID = () => {
    // ---------------------------------------------------
    // help functions
    // ---------------------------------------------------
    const s4 = () => Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);

    // ***************************************************
    // Body function
    // ***************************************************
    return `${s4()}-${s4()}-${s4()}-${s4()}`;
  };

  /**
   * Return the object cloned
   *
   * @param {any} value value to clone
   * @returns any
   */
  static clone = (value) => JSON.parse(JSON.stringify(value));

  /**
   * Return if the first item is different from the second item
   *
   * @param {string} field field verified
   * @param {any} fItem first item
   * @param {any} sItem second item
   * @param {string[]} excludeFields list of attribute to exclude
   * @returns {boolean}
   */
  static isDiffItem = (field, fItem, sItem, excludeFields) => {
    let result = false;
    const isObj1 = fItem instanceof Object;
    const isObj2 = sItem instanceof Object;
    if (isObj1 && Array.isArray(isObj1)) {
      excludeFields.push('length');
    }

    if (isObj1 && isObj2) {
      const pdFields = Object.getOwnPropertyNames(fItem);
      pdFields.forEach((f1) => {
        const isExclude = excludeFields.some((ef) => ef == f1);
        if (!result && !isExclude) {
          const newField = field == '' ? f1 : `${field}.${f1}`;
          result = this.isDiffItem(newField, fItem[f1], sItem[f1], []);
        }
      });
    } else if (!isObj1 && !isObj2) {
      const isDiff = fItem != sItem;
      if (isDiff) {
        console.log(`Diff: ${field} --> ${fItem} != ${sItem}`);
        result = true;
      }
    } else {
      console.log(`Diff: ${field} --> ${fItem} != ${sItem}`);
      result = true;
    }
    return result;
  };

  static checkItemInList = (all, choice, keyAttribute) => all.some((e) => e[keyAttribute] === choice[keyAttribute]);

  static sortByAttr = (a, b, attr, reverse) => {
    let result = 0;
    if (a[attr] > b[attr]) {
      result = 1 * (reverse ? 1 : -1);
    } else if (b[attr] > a[attr]) {
      result = -1 * (reverse ? 1 : -1);
    }
    return result;
  };

  /**
   * Go to Link
   * @param {string} route
   */
  static goToLink = (route) => {
    window.location.href = route;
  };

  /**
   * Check edge navigator
   * @returns {boolean}
   */
  static isEdgeNavigator = () => /Edge\/\d./i.test(navigator.userAgent);

  /**
   * download file
   */
  static downloadFile = (blob, fileName) => {
    // Create blob link to download
    const url = window.URL.createObjectURL(new Blob([blob]));
    if (GaUtils.isEdgeNavigator()) {
      window.open(url);
    } else {
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      // Append to html link element page
      document.body.appendChild(link);
      // Start download
      link.click();
      // Clean up and remove the link
      link.parentNode.removeChild(link);
    }
  }

  static isToday(momentDate) {
    const currentDate = new Date();
    const today = moment(currentDate).clone().startOf('day');
    return momentDate.isSame(today, 'd');
  }

  static isYesterday(momentDate) {
    const currentDate = new Date();
    const yesterday = moment(currentDate).clone().subtract(1, 'days').startOf('day');
    return momentDate.isSame(yesterday, 'd');
  }

  static getMomentDateFormatted(momentDate) {
    let dateFormated = '';
    if (this.isToday(momentDate)) {
      dateFormated = momentDate.format('[Today] h:mm A');
    } else if (this.isYesterday(momentDate)) {
      dateFormated = momentDate.format('[Yesterday] h:mm A');
    } else {
      dateFormated = momentDate.format('M/DD/YYYY h:mm A');
    }
    return dateFormated;
  }

  static getDateFormatted(date) {
    let result = '';
    if (date) {
      const momentDate = moment(date);
      result = GaUtils.getMomentDateFormatted(momentDate);
    }
    return result;
  }

  static addItemToAttrList = (obj, field, value) => {
    let result = true;
    if (obj[field]) {
      if (obj[field].some(l => l == value)) {
        result = false;
      } else {
        obj[field].push(value);
      }
    } else {
      obj[field] = [value];
    }
    return result;
  };

  static delItemToAttrList = (obj, field, value) => {
    let result = false;
    if (obj[field]) {
      const dsIdx = obj[field].indexOf(value);
      if (dsIdx > -1) {
        obj[field].splice(dsIdx, 1);
        result = true;
      }
    }
    return result;
  };
}
