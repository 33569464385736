import { PropTypes } from 'prop-types';

const candidateTablePropTypes = {
  bulkActions: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.oneOf(['primary', 'secondary', 'danger']),
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  candidates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      race: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        name: PropTypes.string,
      }),
      party: PropTypes.shape({
        abbrev: PropTypes.string,
        name: PropTypes.string,
      }),
      state: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        name: PropTypes.string,
      }),
    }),
  ),
  columns: PropTypes.array,
  emptyStateText: PropTypes.string,
  filterModel: PropTypes.shape({
    items: PropTypes.array,
    linkOperator: PropTypes.string,
  }),
  heading: PropTypes.string,
  onFilterModelChange: PropTypes.func,
  onSelectionModelChange: PropTypes.func,
  onSortModelChange: PropTypes.func,
  sortModel: PropTypes.array,
};

const candidateTableDefaultProps = {
  bulkActions: [],
  categories: [],
  emptyStateText: 'There are currently no candidates available for this guide. Create your first candidate!',
  filterModel: { items: [], linkOperator: 'and' },
  heading: 'Candidates',
  onFilterModelChange: () => {},
  onSelectionModelChange: () => {},
  onSortModelChange: () => {},
  sortModel: [],
};

export { candidateTablePropTypes, candidateTableDefaultProps };
