import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { partyJoin } from 'helpers/party';
import { MessagesContext } from 'components/messages';
import GAContainer from 'components/Layout/GAContainer';
import CandidateMetadata from 'components/Candidates/Partials/CandidateMetadata';
import {
  CandidateFinanceTableContainer,
  CandidatePropTypes,
  PageHeader,
} from '@/features/campaign-finance';

const CandidateFinanceContainer = ({
  candidate,
  breadcrumbUrl,
  isTest,
}) => {
  const { messages } = useContext(MessagesContext);

  return (
    <>
      <PageHeader
        heading={candidate?.name}
        subheading={messages.header?.match}
        breadcrumbText={messages.header?.breadcrumb}
        breadcrumbUrl={breadcrumbUrl}
      >
        <CandidateMetadata text={messages.header?.party}>
          {partyJoin(candidate?.parties)}
        </CandidateMetadata>
        <CandidateMetadata text={messages.header?.race} isLast>
          {candidate?.race?.name}
        </CandidateMetadata>
      </PageHeader>

      <GAContainer>
        <CandidateFinanceTableContainer
          candidate={candidate}
          isTest={isTest}
        />
      </GAContainer>
    </>
  );
};

CandidateFinanceContainer.propTypes = {
  candidate: CandidatePropTypes,
  breadcrumbUrl: PropTypes.string,
  isTest: PropTypes.bool,
};

CandidateFinanceContainer.defaultProps = {
  breadcrumbUrl: '',
  isTest: false,
};

export { CandidateFinanceContainer };
