import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import {
  Button,
  Typography,
} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import TransferList from 'components/TransferList';
import theme from 'scripts/theme';
import SnackbarAlert from 'components/SnackbarAlert';

const styles = () => ({
  buttonContainer: {
    width: '100%',
    marginTop: '3rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

class LanguageSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      availableLanguages: [],
      guideLanguages: [],
      loading: true,
      selected: [],
      submitting: false,
    };

    this._snackbar = React.createRef();

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { loadingErrorText } = this.props;

    Promise.all([this.getAvailableLanguages(), this.getGuideLanguages()])
      .then(([availableLanguages, guideLanguages]) => {
        this.setState({
          loading: false,
          availableLanguages,
          guideLanguages,
        });
      })
      .catch(error => {
        console.error(error.message);
        this.setState({
          loading: false,
          error,
        });
        this.showError(loadingErrorText);
      });
  }

  showError(message) {
    return this._snackbar.current.show('error', message);
  }

  showSuccess(message) {
    return this._snackbar.current.show('success', message);
  }

  getGuideLanguages() {
    return fetch('/api/v1/guide/languages')
      .then(res => res.json());
  }

  getAvailableLanguages() {
    return fetch('/api/v1/languages')
      .then(res => res.json());
  }

  handleChange(values) {
    this.setState({
      error: null,
      selected: values,
      success: null,
    });
  }

  handleSubmit() {
    const { successText, errorText } = this.props;
    const { selected } = this.state;
    const csrfToken = document.querySelector('input[name="csrfToken"]').value;

    this.setState({
      error: null,
      submitting: true,
      success: null,
    });

    return fetch('/api/v1/guide/languages', {
      method: 'post',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'Csrf-Token': csrfToken,
      },
      body: JSON.stringify(selected),
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        return Promise.reject(response);
      })
      .then(body => {
        // display a success message
        this.setState({
          success: body.data,
          submitting: false,
        });
        this.showSuccess(successText);
      })
      .catch(error => {
        error.json().then(err => {
          // display an error message
          this.setState({
            error: err.data,
            submitting: false,
          });
        });
        this.showError(errorText);
      });
  }

  render() {
    const {
      classes,
      heading,
      subheading,
      leftHeader,
      leftCountHeader,
      rightHeader,
      rightCountHeader,
      submit,
    } = this.props;
    const {
      availableLanguages,
      guideLanguages,
      loading,
      submitting,
    } = this.state;
    const buttonText = submitting ? 'Saving' : submit.text;

    return (
      <ThemeProvider theme={theme}>

        <SnackbarAlert ref={this._snackbar} />

        <Typography component="h1" variant="h1">
          {heading}
        </Typography>

        <Typography component="p" variant="body1" paragraph>
          {subheading}
        </Typography>

        <TransferList
          disabled={submitting}
          onChange={this.handleChange}
          leftCountHeader={leftCountHeader}
          leftHeader={leftHeader}
          leftItems={availableLanguages}
          loading={loading}
          rightCountHeader={rightCountHeader}
          rightHeader={rightHeader}
          rightItems={guideLanguages}
          showCount
        />

        <div className={classes.buttonContainer}>
          <Button
            color="secondary"
            disabled={submitting || loading}
            onClick={this.handleSubmit}
            size="large"
            variant="contained"
          >
            {buttonText}
          </Button>
        </div>

      </ThemeProvider>
    );
  }
}

LanguageSelector.propTypes = {
  classes: PropTypes.object,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  leftHeader: PropTypes.string,
  leftCountHeader: PropTypes.string,
  rightHeader: PropTypes.string,
  rightCountHeader: PropTypes.string,
  submit: PropTypes.shape({
    text: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  successText: PropTypes.string,
  errorText: PropTypes.string,
  loadingErrorText: PropTypes.string,
};

LanguageSelector.defaultProps = {
  heading: 'Languages',
  subheading: 'Allows users to add and respond to questions in another language in the voter guide, candidate form, and voter ballot. By default, every guide will contain English.',
  leftHeader: 'Available',
  leftCountHeader: 'Selected',
  rightHeader: 'Selected',
  rightCountHeader: 'Selected',
  submit: {
    text: 'Save',
    url: '',
  },
  successText: 'Languages updated successfully!',
  errorText: 'Languages did not update successfully. Please try again.',
  loadingErrorText: 'There was an issue loading your guide\'s languages. Please try again',
};

export default withStyles(styles)(LanguageSelector);
