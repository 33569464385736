import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createStyles, withStyles } from '@material-ui/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import GISDistrictSetSelector from 'components/Districts/Partials/GISDistrictSetSelector';

const styles = () => createStyles({
  actionButton: {
    marginLeft: '1rem',
  },
  modalActions: {
    justifyContent: 'end',
  },
  modalTitle: {
    alignItems: 'flex-start',
  },
  sectionContainer: {
    margin: '1.5rem 0rem',
  },
});

const SetDistrictFromNameModal = ({
  onClose,
  onSave,
  submitting,
  modalIsOpen,
  selectedRaces,
  classes,
  messages,
  status,
  failedRaces,
}) => {
  const [canSubmit, setCanSubmit] = useState(false);
  const [selectedDistrictSet, setSelectedDistrictSet] = useState({});

  const handleUpdateSelectedDistrictSet = (dist) => {
    setSelectedDistrictSet(dist);
    if (dist && Object.keys(dist).length > 0) {
      setCanSubmit(true);
      return;
    }
    setCanSubmit(false);
  };

  const handleSave = () => {
    onSave(selectedDistrictSet);
  };

  return (
    <Dialog
      aria-labelledby="bulk-edit-modal"
      maxWidth="md"
      onClose={onClose}
      open={modalIsOpen}
    >
      <DialogTitle
        className={classes.modalTitle}
        disableTypography
        id="bulk-edit-modal-title"
        onClose={onClose}
      >
        <span>
          <Typography component="h1" variant="h1">
            {messages.title}
          </Typography>

          <Typography component="p" variant="body1">
            {status ? messages.completedSubtitle : messages.subtitle}
          </Typography>
        </span>

        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      {!status && (
        <DialogContent>
          <Typography component="p" variant="h3" paragraph>
            You&apos;ve selected <b>{selectedRaces.length} race(s)</b> for district setting.
          </Typography>

          <div className={classes.sectionContainer}>
            <GISDistrictSetSelector
              onChange={handleUpdateSelectedDistrictSet}
            />
          </div>
        </DialogContent>
      )}
      {status && (
        <DialogContent>
          <Typography component="p" variant="h3" paragraph>
            Successfully updated <b>{selectedRaces.length - Object.keys(failedRaces).length} race(s)</b>.
          </Typography>

          {Object.keys(failedRaces).length > 0 && (
            <div className={classes.sectionContainer}>
              <Typography component="p" variant="body1" paragraph>
                <b>{messages.content.someFailed}</b>
              </Typography>
              {Object.keys(failedRaces).map(r => (
                <Typography component="p" key={r}>
                  {failedRaces[r]}
                </Typography>
              ))}
            </div>
          )}
          {Object.keys(failedRaces).length == 0 && (
            <div className={classes.sectionContainer}>
              <Typography component="p" variant="body1" paragraph>
                <b>{messages.content.noFailed}</b>
              </Typography>
            </div>
          )}
        </DialogContent>
      )}

      <DialogActions className={classes.modalActions}>
        <Button onClick={onClose}>
          {messages.buttonText.close}
        </Button>

        {!status && (
          <Button
            className={classes.actionButton}
            color="secondary"
            disabled={submitting || !canSubmit}
            onClick={handleSave}
            variant="contained"
          >
            {messages.buttonText.save}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

SetDistrictFromNameModal.propTypes = {
  modalIsOpen: PropTypes.bool.isRequired,
  selectedRaces: PropTypes.array,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  submitting: PropTypes.bool,
  messages: PropTypes.object,
  classes: PropTypes.object,
  status: PropTypes.bool.isRequired,
  failedRaces: PropTypes.object,
};

SetDistrictFromNameModal.defaultProps = {
  messages: {
    title: '*Assign District Based on Race Name*',
    subtitle: '*Sample help text*',
    completedSubtitle: '*Failed races either did not meet the naming criteria or did not have a corresponding district with the same number in the set.*',
    buttonText: {
      close: 'Close',
      save: 'Save',
    },
    content: {
      noFailed: '*No races failed to update.*',
      someFailed: '*The following races failed to update:*',
    },
  },
  onClose: () => {},
  onSave: () => {},
  selectedRaces: [],
  submitting: false,
  failedRaces: {},
};

export default withStyles(styles)(SetDistrictFromNameModal);
