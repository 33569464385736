import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import theme from '../theme';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'transparent',
    border: 'none',
    '&:hover > div': {
      color: 'rgb(130, 28, 130)',
      cursor: 'pointer',
    },
  },
  linkText: {
    color: theme.palette.secondary.main,
    fontWeight: '500',
    alignSelf: 'center',
    marginLeft: '10px',
    transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  disabled: {
    color: '#bdbdbd',
    fontWeight: '500',
    alignSelf: 'center',
    marginLeft: '10px',
  },
});

function FabWithText({
  buttonText,
  disabled,
  icon,
  onClick,
}) {
  const classes = useStyles();

  const handleClick = () => {
    if (disabled) {
      return;
    }
    onClick();
  };

  return (
    <a
      className={classes.container}
      onClick={handleClick}
      disabled={disabled}
    >
      <Fab
        size="medium"
        color="secondary"
        aria-label="add"
        disabled={disabled}
      >
        {icon}
      </Fab>

      <div className={disabled ? classes.disabled : classes.linkText}>
        {buttonText}
      </div>
    </a>
  );
}

FabWithText.propTypes = {
  buttonText: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  icon: PropTypes.element,
  onClick: PropTypes.func,
};

FabWithText.defaultProps = {
  buttonText: 'add [item]',
  disabled: false,
  icon: <AddIcon />,
  onClick: () => {},
};

export default FabWithText;
