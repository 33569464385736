import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { StepTitle } from 'components/RaceSteps';
import {
  Box, Fab, Link,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import GaUtils from 'helpers/GaUtils';
import fetchUtil from 'helpers/Fetch';
import CandidateCard from './CandidateCard';

const Container = (props) => {
  // variables
  const {
    messages,
    guideId,
    raceClassId,
    stepState,
  } = props;

  let { candidateList, errorsCandidateList } = stepState;
  const {
    setCandidateList,
    parties,
    setParties,
    checkValidations,
    setCheckValidations,
    partiesLoaded,
    setPartiesLoaded,
  } = stepState;

  // functions
  const loadingData = () => {
    setCheckValidations(false);

    if (!partiesLoaded) {
      const url = `/api/v1/guide/${guideId}/parties`;
      fetchUtil(url, 'GET', {})
        .then((resp) => {
          setParties(resp);
          setPartiesLoaded(true);
          const noParty = resp.find(party => party.name === 'No Party');
          if (raceClassId === 'R') {
            if (candidateList && candidateList.length == 0) {
              // Adding two candidates
              addCandidate();
              addCandidate();
            }
          } else {
            // Adding default ballot options
            addForMeasure(noParty);
            addAgainstMeasure(noParty);
          }
        })
        .catch((error) => console.error(error));
    }
  };

  const addCandidate = () => {
    const newCandidate = {
      id: GaUtils.generateGUID(),
      inFavor: raceClassId === 'R' ? null : false,
      name: '',
      lastName: '',
      contactUser: { name: '', email: '' },
      parties: [],
      race: {
        clazz: {
          id: raceClassId,
        },
      },
    };
    if (candidateList) {
      candidateList.push(newCandidate);
    } else {
      candidateList = [newCandidate];
    }
    setCandidateList([...candidateList]);
  };

  const addForMeasure = (noParty) => {
    const newCandidate = {
      id: GaUtils.generateGUID(),
      name: 'Yes - For the Measure',
      lastName: 'A-First',
      contactUser: { name: '', email: '' },
      parties: noParty ? [noParty] : [],
      race: {
        clazz: {
          id: raceClassId,
        },
      },
      inFavor: true,
    };
    if (candidateList) {
      candidateList.push(newCandidate);
    } else {
      candidateList = [newCandidate];
    }
    setCandidateList([...candidateList]);
  };

  const addAgainstMeasure = (noParty) => {
    const newCandidate = {
      id: GaUtils.generateGUID(),
      name: 'No - Against the Measure',
      lastName: 'B-Second',
      contactUser: { name: '', email: '' },
      parties: noParty ? [noParty] : [],
      race: {
        clazz: {
          id: raceClassId,
        },
      },
      inFavor: false,
    };
    if (candidateList) {
      candidateList.push(newCandidate);
    } else {
      candidateList = [newCandidate];
    }
    setCandidateList([...candidateList]);
  };

  const removingCandidate = (objCand) => {
    if (objCand) {
      if (candidateList) {
        let copyCandList = [...candidateList];
        copyCandList = copyCandList.filter((c) => c.id != objCand.id);
        setCandidateList(copyCandList);
      }
      if (errorsCandidateList) {
        errorsCandidateList = errorsCandidateList.filter(
          (c) => c.id != objCand.id,
        );
      }
    }
  };

  // Component Did Mount
  useEffect(() => {
    loadingData();
  }, []);

  // render
  return (
    <div className="ga-mb-32">
      <StepTitle title={raceClassId === 'R' ? messages.raceTitle : messages.measureTitle}>
        {raceClassId === 'R' ? messages.raceSubtitle : messages.measureSubtitle}
      </StepTitle>
      {partiesLoaded && candidateList
        && candidateList.map((cand, idx) => (
          <CandidateCard
            key={idx}
            messages={messages}
            cand={cand}
            idx={idx}
            candidateList={candidateList}
            setCandidateList={setCandidateList}
            errorsCandidateList={errorsCandidateList}
            parties={parties}
            removingCandidate={removingCandidate}
            checkValidations={checkValidations}
          />
        ))}
      <Box
        className="ga-mt-32"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <Fab
          color="secondary"
          aria-label="add"
          size="large"
          className="ga-mr-12"
          disabled={!partiesLoaded}
          onClick={() => addCandidate()}
        >
          <Add />
        </Fab>
        <Link
          component="button"
          color='secondary'
          underline="none"
          className='ga-ml-4'
          disabled={!partiesLoaded}
          onClick={() => addCandidate()}
        >
          {candidateList && candidateList.length > 0
            ? messages.addBtn
            : messages.addFirstBtn}
        </Link>
      </Box>
    </div>
  );
};

Container.propTypes = {
  guideId: PropTypes.string,
  messages: PropTypes.object.isRequired,
  raceClassId: PropTypes.string,
  stepState: PropTypes.object,
};

Container.defaultProps = {};

export default Container;
