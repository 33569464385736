import React, {
  memo,
  useMemo,
  useContext,
  useState,
} from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { Box, Button } from '@material-ui/core';
import { MessagesContext } from 'components/messages';
import { ServerPaginatedDataGrid, useStyles } from 'components/DataGrid';
import { AppSnackbarContext } from 'components/AppSnackbar';
import ConfirmationDialog from 'components/ConfirmationDialog';
import truncate from 'helpers/truncate';
import { useDataGridParams } from 'hooks/useDataGridParams';
import { shortMonthStandardDateFormat } from 'helpers/Date';
import {
  useCampaignFinanceRecords,
  FETCH_TYPE_MATCHED,
  FETCH_TYPE_UNMATCHED,
  deleteAllFinanceCandidateMatch,
} from '@/features/campaign-finance';

const BASE_URL = '/campaign-finance';

export const CampaignFinanceLandingTablesContainer = memo(({
  type,
}) => {
  const queryClient = useQueryClient();
  const { messages } = useContext(MessagesContext);
  const appSnackbar = useContext(AppSnackbarContext);
  const [removeConfirmationOpen, setRemoveConfirmationOpen] = useState(false);
  const [removeCandidateId, setRemoveCandidateId] = useState(null);
  const [removeSubmitting, setRemoveSubmitting] = useState(false);

  const classes = useStyles();

  const {
    onPageChange,
    onPageSizeChange,
    page,
    pageSize,
    setSortModel,
    sortModel,
    hiddenColumns,
    setHiddenColumns,
  } = useDataGridParams({ tableSettingsKey: `campaign_finance_landing_table_settings${type}` });

  const {
    data,
    error,
    isError,
    isFetching,
    isLoading,
    refetch,
  } = useCampaignFinanceRecords({
    dataGridParams: {
      page,
      pageSize,
      sortModel,
    },
    queryKeyString: `campaign_finance_records_${type}`,
    type,
  });

  const unmatchMutation = useMutation({
    mutationFn: (cfRecordId) => deleteAllFinanceCandidateMatch(cfRecordId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [`candidate_finance_${FETCH_TYPE_MATCHED}`] });
      queryClient.invalidateQueries({ queryKey: [`candidate_finance_${FETCH_TYPE_UNMATCHED}`] });
      refetch();
      appSnackbar.show(messages.dialogs?.success, 'success');
    },
    onError: (unmatchingError) => {
      console.error('Unmatch error:', unmatchingError);
      appSnackbar.show(messages.dialogs?.error, 'error');
    },
    onSettled: () => {
      setRemoveSubmitting(false);
      setRemoveConfirmationOpen(false);
    },
    retry: 2,
  });

  const handleRemoveMatchClick = (cfRecordId) => {
    setRemoveCandidateId(cfRecordId);
    setRemoveConfirmationOpen(true);
  };

  const handleRemoveCancel = () => {
    setRemoveConfirmationOpen(false);
    setRemoveCandidateId(null);
  };

  const handleRemoveMatch = () => {
    setRemoveSubmitting(true);
    unmatchMutation.mutate(removeCandidateId);
  };

  // #region table def

  const tableColumns = useMemo(() => [
    {
      field: 'candidateName',
      headerName: messages.tables?.header?.name,
      flex: 1.2,
      hide: hiddenColumns.includes('candidateName'),
      renderCell: (params) => {
        const truncated = truncate(params.row.candidateName, 90);
        const displayName = truncated !== '' ? truncated : 'NO NAME';

        return (
          <Box sx={{ fontWeight: '600' }}>
            {type === FETCH_TYPE_UNMATCHED ? (
              <a href={`${BASE_URL}/${params.id}`}>
                {displayName}
              </a>
            ) : (
              displayName
            )}
          </Box>
        );
      },
    },
    {
      field: 'party',
      headerName: messages.tables?.header?.party,
      flex: 0.6,
      hide: hiddenColumns.includes('party'),
    },
    {
      field: 'raceName',
      headerName: messages.tables?.header?.race,
      flex: 1,
      valueGetter: (params) => truncate(params.row.raceName, 70),
      hide: hiddenColumns.includes('raceName'),
    },
    {
      field: 'updated',
      headerName: messages.tables?.header?.import,
      flex: 1,
      valueGetter: (params) => {
        if (!params.row.updated || Number.isNaN(Date.parse(params.row.updated))) {
          return '';
        }
        const d = new Date(params.row.updated);
        return shortMonthStandardDateFormat.format(d);
      },
      hide: hiddenColumns.includes('updated'),
    },
    {
      field: 'dataSource',
      headerName: messages.tables?.header?.source,
      flex: 0.8,
      hide: hiddenColumns.includes('dataSource'),
    },
    {
      field: 'yearRange',
      headerName: messages.tables?.header?.sourceYear,
      flex: 0.78,
      hide: hiddenColumns.includes('yearRange'),
    },
    {
      field: 'actions',
      headerName: messages.tables?.header?.actions,
      resizable: false,
      sortable: false,
      width: 210,
      hide: hiddenColumns.includes('actions'),
      renderCell: (params) => <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {type === FETCH_TYPE_UNMATCHED ? (
          <Button
            variant="outlined"
            color="primary"
            className={classes.actionButton}
            size="small"
            href={`${BASE_URL}/${params.id}`}
          >
            {messages.tables.actions.match}
          </Button>
        ) : (
          <Button
            variant="outlined"
            color="default"
            className={`${classes.actionButton} ${classes.dangerButton}`}
            size="small"
            onClick={() => handleRemoveMatchClick(params.id)}
          >
            {messages.tables.actions.unmatch}
          </Button>
        )}
      </Box>,
    },
  ], [type, handleRemoveMatchClick]);

  return (
    <>
      <ConfirmationDialog
        cancelButtonText={messages.dialogs?.cancel || 'TODO: cancel'}
        confirmButtonText={messages.dialogs?.confirm || 'TODO: confirm'}
        heading={messages.dialogs?.heading || 'TODO: heading'}
        onCancel={() => handleRemoveCancel()}
        onConfirm={() => handleRemoveMatch()}
        open={removeConfirmationOpen}
        submitting={removeSubmitting}
      >
        {messages.dialogs?.content || 'TODO: content'}
      </ConfirmationDialog>
      <ServerPaginatedDataGrid
        columns={tableColumns}
        emptyStateText={type === FETCH_TYPE_UNMATCHED ? messages.tables.unmatched.empty : messages.tables.matched.empty}
        error={error}
        isError={isError}
        isFetching={isFetching}
        isLoading={isLoading}
        onColumnVisibilityChange={setHiddenColumns}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onSortModelChange={setSortModel}
        page={page}
        pageSize={pageSize}
        rowCount={data?.totalRows || 0}
        rows={data?.rows || []}
        sortModel={sortModel}
      />
    </>
  );
});

CampaignFinanceLandingTablesContainer.propTypes = {
  type: PropTypes.oneOf([FETCH_TYPE_MATCHED, FETCH_TYPE_UNMATCHED]).isRequired,
};

CampaignFinanceLandingTablesContainer.defaultProps = {
  type: FETCH_TYPE_UNMATCHED,
};
