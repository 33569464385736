import React, { useState } from 'react';
import { withStyles } from '@material-ui/styles';
import {
  Button,
  Typography,
} from '@material-ui/core';
import { DataGridPro, LicenseInfo } from '@mui/x-data-grid-pro';
import EmptyState from 'components/EmptyState';
import { MUI_LICENSE_KEY, ROWS_PER_PAGE_OPTIONS } from 'config/data-grid';
import { useStyles } from './CandidateTable.styles';
import { candidateTableDefaultProps, candidateTablePropTypes } from './CandidateTable.types';

LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);

// Dynamic row height style for DataGrid component
const StyledDataGrid = withStyles({
  root: {
    '& .MuiDataGrid-renderingZone': {
      maxHeight: 'none !important',
    },
    '& .MuiDataGrid-cell': {
      lineHeight: 'unset !important',
      whiteSpace: 'normal',
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiDataGrid-row': {
      maxHeight: 'none !important',
    },
  },
})(DataGridPro);

const CandidateTable = ({
  bulkActions,
  candidates,
  columns,
  emptyStateText,
  filterModel,
  heading,
  onFilterModelChange,
  onSelectionModelChange,
  onSortModelChange,
  sortModel,
}) => {
  const classes = useStyles();
  const [pageSize, setPageSize] = useState(50);
  const [showBulkActions, setShowBulkActions] = useState(false);

  const handleSelectionModelChange = (selectionModel) => {
    setShowBulkActions(selectionModel.length > 0);
    onSelectionModelChange(selectionModel);
  };

  const renderBulkActions = () => {
    if (bulkActions && bulkActions.length > 0 && showBulkActions) {
      return (
        <div>
          {bulkActions.map((action, index) => (
            <Button
              className={action.color == 'danger' ? `${classes.actionButton} ${classes.dangerButton}` : classes.actionButton}
              color={action.color == 'danger' ? 'primary' : action.color}
              key={index}
              size="small"
              onClick={action.onClick} // eslint-disable-line react/jsx-handler-names
            >
              {action.name}
            </Button>
          ))}
        </div>
      );
    }
  };

  const renderEmptyState = () => (
    <EmptyState>
      <p className="ga-m-bottom--large">
        {emptyStateText}
      </p>
    </EmptyState>
  );

  const renderDataTable = () => {
    if (!candidates || candidates.length <= 0) {
      return renderEmptyState();
    }

    return (
      <div className={classes.tableContainer}>
        <StyledDataGrid
          autoHeight={true}
          checkboxSelection
          className={classes.table}
          columns={columns}
          filterModel={filterModel}
          isRowSelectable={(params) => params.row.loading != true}
          onFilterModelChange={(model) => onFilterModelChange(model)}
          onPageSizeChange={size => setPageSize(size)}
          onSelectionModelChange={(selectionModel) => handleSelectionModelChange(selectionModel)}
          onSortModelChange={(model) => onSortModelChange(model)}
          pagination={true}
          pageSize={pageSize}
          rows={candidates}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          sortModel={sortModel}
        />
      </div>
    );
  };

  return (
    <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12 ga-m-vertical--large">
      <div className={classes.headingContainer}>
        <Typography component="h2" variant="h3">{heading}</Typography>
        {renderBulkActions()}
      </div>

      {renderDataTable()}
    </div>
  );
};

CandidateTable.propTypes = candidateTablePropTypes;

CandidateTable.defaultProps = candidateTableDefaultProps;

export { CandidateTable };
