import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import ConfirmationDialog from 'components/ConfirmationDialog';
import SectionHeadingWithButton from 'components/SectionHeadingWithButton';
import BioQuestionSetsEmptyState from 'components/BioQuestions/BioQuestionSetsEmptyState';
import BioQuestionSetAccordion from 'components/BioQuestions/BioQuestionSetAccordion';

const useStyles = makeStyles({
  tabBody: {
    margin: '1rem 0',
  },
  linkText: {
    color: '#0a3a5a',
    textDecoration: 'underline',
  },
  createSetBtn: {
    whiteSpace: 'nowrap',
    marginLeft: '.75rem',
  },
});

const BioQuestionSetList = ({
  bioSets,
  deleteSubmitting,
  guideId,
  messages,
  onAddQuestionToSet,
  onCreate,
  onDelete,
  onError,
  onUpdate,
  onRemoveQuestion,
  questions,
}) => {
  const [confirmationDialogBody, setConfirmationDialogBody] = useState({});
  const [confirmationDialogHeading, setConfirmationDialogHeading] = useState('');
  const [confirmationDialogId, setConfirmationDialogId] = useState(null);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const classes = useStyles();

  const handleDeleteBioSet = (id, name) => {
    const dialogHeading = `Delete ${name}?`;
    const dialogBody = (
      <div>
        <Typography component="p" variant="body1" className="ga-m-bottom--small">
          Are you sure you want to delete the <strong>{name}</strong> biographical question set? Individual answers
          to questions in this set <strong>will be unaffected</strong> by this action.
        </Typography>

        <Typography component="p" variant="body1">
          <em>
            <strong>NOTE:</strong> If candidate answers have already been received for questions in this set,
            the set can be restored from the recycling bin.
          </em>
        </Typography>
      </div>
    );

    setShowConfirmationDialog(true);
    setConfirmationDialogBody(dialogBody);
    setConfirmationDialogHeading(dialogHeading);
    setConfirmationDialogId(id);
  };

  const handleCancelDeleteConfirmation = () => {
    setConfirmationDialogId(null);
    setShowConfirmationDialog(false);
  };

  const renderSets = () => {
    if (bioSets && bioSets.length > 0) {
      return (
        <div className={classes.tabBody}>
          {bioSets.map(set => (
            <DndProvider backend={HTML5Backend} key={set.index}>
              <BioQuestionSetAccordion
                addBioQuestionsPlaceholder={messages.bioSets.listContainer.setList.accordion.addBioQuestionsPlaceholder}
                addButtonText="Add"
                bioSet={set}
                deleteButtonText={messages.bioSets.listContainer.setList.accordion.deleteButtonText}
                editButtonText={messages.bioSets.listContainer.setList.accordion.editButtonText}
                guideId={guideId}
                id={set.id}
                name={set.name}
                onAddQuestionToSet={onAddQuestionToSet}
                onDelete={handleDeleteBioSet}
                onEdit={(id, name) => onUpdate(id, name)}
                onError={onError}
                onRemoveQuestion={onRemoveQuestion}
                questions={questions}
                removeFromSetButtonText={messages.bioSets.removeQuestion.button}
              />
            </DndProvider>
          ))}
        </div>
      );
    }

    return (
      <div className={classes.tabBody}>
        <BioQuestionSetsEmptyState onCreate={onCreate}>
          There are currently no biographical sets available for this guide. Not sure what biographical sets are for? Read more about them in our
          <a href="https://ethepeople.org/help/bio-fields-bio-field-sets/"> help guide.</a>
        </BioQuestionSetsEmptyState>
      </div>
    );
  };

  return (
    <div>
      <ConfirmationDialog
        heading={confirmationDialogHeading}
        onCancel={handleCancelDeleteConfirmation}
        onConfirm={() => onDelete(confirmationDialogId)}
        open={showConfirmationDialog}
        submitting={deleteSubmitting}
      >
        {confirmationDialogBody}
      </ConfirmationDialog>

      <SectionHeadingWithButton
        heading={messages.bioSets.title}
        subheading={messages.bioSets.titleSupporting}
      >
        <Button
          className={classes.createSetBtn}
          variant="contained"
          color="secondary"
          onClick={onCreate}
          size="large"
        >
          Create Set
        </Button>
      </SectionHeadingWithButton>

      {renderSets()}
    </div>
  );
};

BioQuestionSetList.propTypes = {
  bioSets: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    index: PropTypes.number,
    name: PropTypes.string,
    questions: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      title: PropTypes.string,
      index: PropTypes.number,
      isDefault: PropTypes.bool,
      handleRemoveFromSet: PropTypes.func,
    })),
  })),
  deleteSubmitting: PropTypes.bool,
  guideId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  messages: PropTypes.object,
  onAddQuestionToSet: PropTypes.func,
  onCreate: PropTypes.func,
  onDelete: PropTypes.func,
  onError: PropTypes.func,
  onUpdate: PropTypes.func,
  onRemoveQuestion: PropTypes.func,
  questions: PropTypes.arrayOf(PropTypes.shape({
    default: PropTypes.bool,
    id: PropTypes.number,
    index: PropTypes.number,
    maxl: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.string,
  })),
};

BioQuestionSetList.defaultProps = {
  bioSets: [],
  deleteSubmitting: false,
  messages: {
    bioSets: {
      title: '',
      titleSupporting: '',
    },
  },
  onAddQuestionToSet: () => {},
  onCreate: () => {},
  onDelete: () => {},
  onError: () => {},
  onUpdate: () => {},
  questions: [],
};

export default BioQuestionSetList;
