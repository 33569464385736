import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Box,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import HelpfulHint from 'components/HelpfulHint';
import WYSIWYG from 'components/WYSIWYG';
import FormFieldRenderer from 'components/FormFieldRenderer';

const useStyles = makeStyles(theme => ({
  macroTitle: {
    fontWeight: 'bold',
  },
  emailTemplateLabel: {
    color: theme.palette.primary.main,
  },
  fullWidthWYSIWYG: {
    '& #mui-rte-root': {
      width: '100%',
    },
  },
}));

/**
 * Component for use by the 3 email template related pages - Create, Edit, and Send
 * @param {array} fields Fields to display above the WYSIWYG, see PropTypes for properties to send, and EmailForm.stories.js for usage
 * @param {string} defaultWYSIWYG Stringified WYSIWYG content state object, retrieved from an EditorState
 *                 object as follows - JSON.stringify(convertToRaw(EDITOR_STATE.getCurrentContent()))
 * @param {func} onWYSIWYGChange Callback method for changes to WYSIWYG content
 * @param {bool} submitting State variable for if the form is currently submitting. Disables all inputs when true
 * @param {object} errors Standard errors object, should be stateful and updated from API response
 * @param {array} macros List of available macros for the help text component
 * @param {string} wysiwygHeading Text that is displayed above the WYSIWYG
 * @param {string} macrosTitle Text that is displayed above the macros list in the help box
 * @returns React Node
 */
const EmailForm = ({
  fields,
  defaultWYSIWYG,
  onWYSIWYGChange,
  submitting,
  errors,
  macros,
  wysiwygHeading,
  macrosTitle,
}) => {
  const classes = useStyles();

  const renderMacros = () => (
    macros.map((macro, index) => (
      <Box key={index} paddingY={1} sx={{
        borderBottom: '1px solid',
        borderBottomColor: index != macros.length - 1 ? '#dddddd' : 'transparent',
      }}>
        <Typography className={classes.macroTitle}>
          {macro.title}
        </Typography>
        <Typography>
          {macro.description}
        </Typography>
      </Box>
    ))
  );

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={8}>
          <Grid container spacing={2}>
            {/* Form fields get rendered here */}
            <FormFieldRenderer
              fields={fields}
              submitting={submitting}
              errors={errors}
            />
          </Grid>
        </Grid>
      </Grid>
      {/* WYSIWYG and Help Text */}
      <Box marginTop={5}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={8} className={classes.fullWidthWYSIWYG}>
            <Typography paragraph variant="h5" className={classes.emailTemplateLabel}>
              {wysiwygHeading}
            </Typography>
            <WYSIWYG
              value={defaultWYSIWYG}
              onChange={onWYSIWYGChange}
              label={wysiwygHeading}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <div>
              <HelpfulHint>
                <Box marginBottom={3}>
                  <Typography>
                    {macrosTitle}
                  </Typography>
                </Box>
                {renderMacros()}
              </HelpfulHint>
            </div>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

EmailForm.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired, // one word id, used for errors and html id (ex. "name")
      value: PropTypes.oneOfType( // state variable holding current value
        [PropTypes.number, PropTypes.string, PropTypes.instanceOf(Date)],
      ),
      handleValueChange: PropTypes.func, // state setter for above value. Optional for read-only fields.
      label: PropTypes.string.isRequired, // field label
      help: PropTypes.string, // field help text (goes underneath the field)
      required: PropTypes.bool, // true if the field is required
      readOnly: PropTypes.bool, // true if the field is read-only
      type: PropTypes.oneOf( // field type, can add more options as needed
        ['select', 'text', 'datetime', 'email', 'multi-email'],
      ).isRequired,
      options: PropTypes.arrayOf( // for 'select' field type, dropdown options - see story for usage
        PropTypes.shape({
          value: PropTypes.number, // id, unique
          label: PropTypes.string, // text label
        }),
      ),
      width: PropTypes.oneOf(['half', 'full']), // width of the field - adjacent half-width fields will create two columns
    }),
  ),
  defaultWYSIWYG: PropTypes.string.isRequired,
  onWYSIWYGChange: PropTypes.func,
  submitting: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired, // keys should correspond with field ids in fields prop
  macros: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }),
  ),
  wysiwygHeading: PropTypes.string,
  macrosTitle: PropTypes.string,
};

EmailForm.defaultProps = {
  fields: [],
  onWYSIWYGChange: () => console.log('You should implement this function (onWYSIWYGChange)!'),
  wysiwygHeading: 'Email Template',
  macrosTitle: 'You can use the following macros in the body of an email',
};

export default EmailForm;
