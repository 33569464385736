import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import RadioCard from 'components/RadioCard';

const useStyles = makeStyles({
  listContainer: {
    justifyContent: 'flex-start',
    alignItems: 'stretch',
  },
});

const RadioCardList = ({
  cards,
  checked,
  deletableIndex,
  disabled,
  onChange,
  onDelete,
  spacing,
  gridBreakpoints,
}) => {
  const classes = useStyles();

  // Get the gridsizes from the gridBreakpoints prop, if not provided, use the default values
  const getGridSize = (breakpoint) => gridBreakpoints[breakpoint] || RadioCardList.defaultProps.gridBreakpoints[breakpoint];

  const renderRadioCards = () => {
    if (cards && cards.length > 0) {
      return cards.map((card, index) => (
        <Grid
          item
          xs={getGridSize('xs')}
          sm={getGridSize('sm')}
          md={getGridSize('md')}
          lg={getGridSize('lg')}
          xl={getGridSize('xl')}
          key={index}
        >
          <RadioCard
            checked={checked === index}
            deletable={deletableIndex !== null && deletableIndex === index}
            description={card.description}
            disabled={disabled}
            id={index}
            onClick={(id) => onChange(id)}
            onDelete={onDelete}
            title={card.title}
          />
        </Grid>
      ));
    }
  };

  return (
    <Grid
      className={classes.listContainer}
      // columns={{ xs: 4, sm: 8, md: 12 }}
      container
      spacing={spacing}
    >
      {renderRadioCards()}
    </Grid>
  );
};

RadioCardList.propTypes = {
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  checked: PropTypes.number,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  deletableIndex: PropTypes.number, // expecting null
  onDelete: PropTypes.func,
  spacing: PropTypes.number,
  gridBreakpoints: PropTypes.shape({
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
  }),
};

RadioCardList.defaultProps = {
  cards: [],
  checked: 0,
  disabled: false,
  onChange: () => {},
  onDelete: () => {},
  spacing: 4,
  gridBreakpoints: {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 3,
    xl: 3,
  },
};

export default RadioCardList;
