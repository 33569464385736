import validator from 'validator';

const ADDRESS_DIVIDER = '``';

// dangit javascript, why must you hurt me in this way
const isNumber = (value) => (
  Number.isFinite(value) || (Number.isFinite(Number(value)) && typeof value === 'string')
);

class BioQuestionClass {
  // Field declaration
  errors = {};

  answerId = -1;

  answer = '';

  mailingAnswer = {
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
  };

  id = -1;

  maxl = -1;

  type = -1;

  question = '';

  isVisible = true;

  edit = true;

  static ADDRESS_TYPE = 'Mailing Address';

  static PHONE_NUMBER = 'US Phone Number';

  /**
   * Create object without answers from question JSON
   * @param {Object} question Question object from DB JSON response
   * @param {Object} answer Answer to the question, must match source id to question id
   */
  constructor(question, answer) {
    // default to populate later
    this.errors = {};

    // if the answer exists for this question
    if (typeof answer !== 'undefined') {
      if (answer.sourceId !== question.id) {
        throw new Error('Answer source does not match question id');
      }

      this.answerId = answer.id;
      this.answer = answer.translations.en;

      // handle address answer
      if (question.type == BioQuestionClass.ADDRESS_TYPE) {
        if (answer.translations.en != null && answer.translations.en != '') {
          const splitAnswer = answer.translations.en.split(ADDRESS_DIVIDER);
          if (splitAnswer.length != 5) {
            this.mailingAnswer = {
              address1: '',
              address2: '',
              city: '',
              state: '',
              zip: '',
            };
          } else {
            this.mailingAnswer = {
              address1: splitAnswer[0],
              address2: splitAnswer[1],
              city: splitAnswer[2],
              state: splitAnswer[3],
              zip: splitAnswer[4],
            };
          }
        }
      }
    }

    // available question values
    if (typeof question === 'undefined' || question == null) {
      throw new Error('Question is a required parameter');
    }

    try {
      this.id = question.id;
      this.maxl = question.maxl;
      this.type = question.type;
      // added || for state reset case
      this.question = question.name || question.question;
      this.isVisible = question.isVisible;
      this.edit = question.edit;
    } catch (e) {
      console.error(e);
      throw new Error('Question did not have all required fields');
    }
  }

  validateAnswer(answerVal) {
    let hasError = false;
    let errorString = null;
    if (this.type === 'Email') {
      if (answerVal != null && answerVal != '' && !validator.isEmail(answerVal)) {
        hasError = true;
      }
    }
    if (['URL', 'twitter', 'youtube'].some(n => n == this.type)) {
      if (answerVal != null && answerVal != '' && !validator.isURL(answerVal)) {
        hasError = true;
      } else if (/^(http:|https:|ftp:|sftp:|file:|ssh:)/.test(answerVal.toLowerCase())) {
        hasError = true;
        errorString = 'Please remove http:// or https:// from the start of the URL';
      }
    }
    if (this.type === 'US Phone Number') {
      const regExpr = RegExp('^(\\([0-9]{3}\\)|[0-9]{3}-?)[0-9]{3}-?[0-9]{4}$');
      if (answerVal != null && answerVal != '' && !regExpr.test(answerVal)) {
        hasError = true;
      }
    }
    if (this.type === 'Age') {
      if (answerVal != null && answerVal != '' && !isNumber(answerVal)) {
        hasError = true;
      }
    }

    if (hasError) {
      this.errors = { format: errorString || 'Invalid format for this question type' };
    } else {
      this.errors = {};
    }
  }

  /**
   * Update the answer to this question
   * @param {*} newValue Updated value for this answer
   */
  updateAnswer(newValue) {
    this.validateAnswer(newValue);
    this.answer = newValue;
  }

  /**
   * Update the mailing address
   * @param {String} field Name of the field that was updated (see MailAddressForm.js for name declarations)
   * @param {String} newValue Answer to the mailing address question
   */
  updateMailingAddress(field, newValue) {
    let newValueTrunc = newValue;
    if (field === 'zip') {
      newValueTrunc = newValue.substring(0, 5);
    }

    this.mailingAnswer[field] = newValueTrunc;
  }

  get apiAnswer() {
    if (this.type == BioQuestionClass.ADDRESS_TYPE) {
      return this.mailingConcat;
    }
    return this.answer;
  }

  get reviewCard() {
    return {};
  }

  // don't look at me I'm ugly
  get mailingConcat() {
    return `${this.mailingAnswer.address1}${ADDRESS_DIVIDER}${this.mailingAnswer.address2}${ADDRESS_DIVIDER}${this.mailingAnswer.city}${ADDRESS_DIVIDER}${this.mailingAnswer.state}${ADDRESS_DIVIDER}${this.mailingAnswer.zip}`;
  }

  get mailingSpaced() {
    return `${this.mailingAnswer.address1} ${this.mailingAnswer.address2} ${this.mailingAnswer.city}, ${this.mailingAnswer.state} ${this.mailingAnswer.zip}`;
  }

  get frontEndAnswer() {
    if (this.type == BioQuestionClass.ADDRESS_TYPE) {
      return this.mailingSpaced.trim();
    }
    return this.answer || '';
  }

  static mailingAddressIsPresent(mailingAddressString) {
    if (mailingAddressString == null || typeof mailingAddressString == 'undefined' || mailingAddressString == '') {
      return false;
    }
    const splitAnswer = mailingAddressString.split(ADDRESS_DIVIDER);
    if (splitAnswer.length != 5) {
      return false; // malformatted
    }
    const splitMailingAnswer = {
      address1: splitAnswer[0],
      address2: splitAnswer[1],
      city: splitAnswer[2],
      state: splitAnswer[3],
      zip: splitAnswer[4],
    };
    if (splitMailingAnswer.address1 == '') {
      return false; // empty address 1
    }

    // could add more here if need be

    return true;
  }
}

export default BioQuestionClass;
