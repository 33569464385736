import React from 'react';
import PropTypes from 'prop-types';
import { StepTitle } from 'components/RaceSteps';
import { Box } from '@material-ui/core';
import { convertToRaw, EditorState } from 'draft-js';
import { convertFromHTML } from 'draft-convert';
import { handleHtmlToBlock, handleHtmlToEntity } from 'components/WYSIWYGCustomControls';
import DistrictSelectorTracker from 'helpers/DistrictSelectorTracker';
import {
  ListCard,
  MetadataCard,
  RaceOverviewCard,
  TextAreaCard,
  DataRowCard,
} from '.';

const Container = ({
  messages,
  raceClassId,
  state,
  stepEnum: STEP_NAMES,
  goToStep,
  raceTypes: RACE_TYPES,
  votingOptions: VOTING_OPTIONS,
}) => {
  const onEdit = (step) => {
    goToStep(step);
  };

  // helper variables
  const raceTitle = state.stepSetUpState.name;
  const raceType = RACE_TYPES[state.stepSetUpState.typeOfRace].title;
  const votingOption = VOTING_OPTIONS[state.stepSetUpState.votingOption].title;
  const hasMultipleChoice = !!VOTING_OPTIONS[state.stepSetUpState.votingOption].additionalQuestion;
  const district = (state.stepSetUpState.district && Object.keys(state.stepSetUpState.district).length > 0)
    ? DistrictSelectorTracker.createBreadcrumbsFromDistrictObject(state.stepSetUpState.district) : null;
  const multpleChoice = state.stepSetUpState.numberOfCandidates;
  const selectedCategory = state.stepCategoryState.categories[state.stepCategoryState.selectedCategory];

  const contentHTML = convertFromHTML({ htmlToBlock: handleHtmlToBlock, htmlToEntity: handleHtmlToEntity })(state.stepDescriptionState.savedDescription);
  const editorState = EditorState.createWithContent(contentHTML);
  const description = JSON.stringify(convertToRaw(editorState.getCurrentContent()));

  let bioSetTitle = '';
  let bioSetItems = [];
  if (state.stepBioQuestionsState.bioDecision === 0) {
    // existing bio set chosen
    bioSetTitle = state.stepBioQuestionsState.bioSetChoice.name;
    bioSetItems = state.stepBioQuestionsState.bioSetChoice.bioQuestions;
  } else if (state.stepBioQuestionsState.bioDecision === 1) {
    // new bio set created
    bioSetTitle = state.stepBioQuestionsState.newSetTitle;
    bioSetItems = state.stepBioQuestionsState.newSetQuestions;
  } else if (state.stepBioQuestionsState.bioDecision === 2) {
    // all questions used
    bioSetTitle = 'All Biographical Questions';
    bioSetItems = state.stepBioQuestionsState.allQs;
  }

  const removeEmptyCandidates = c => (
    typeof c.name != 'undefined' && c.name != null && c.name != ''
  );

  const candidatesList = state.stepCandidatesState.candidateList.filter(removeEmptyCandidates).map(candidate => ({
    id: candidate.id,
    values: [
      { label: messages.candidates.fullName, value: candidate.name },
      { label: messages.candidates.party, value: candidate.parties.map(p => p.name).join(', ') },
      { label: messages.candidates.contactName, value: candidate.contactUser.name },
      { label: messages.candidates.contactEmail, value: candidate.contactUser.email },
    ],
  }));

  let raceQuestionSetTitle = '';
  let raceQuestionSetItems = [];
  if (state.stepRaceQuestionsState.raceQuestionDecision === 0) {
    // existing set chosen
    raceQuestionSetTitle = state.stepRaceQuestionsState.selectedRQS.name;
    raceQuestionSetItems = state.stepRaceQuestionsState.selectedRQS.questions.map(q => (
      {
        ...q,
        name: q.body,
      }
    ));
  } else if (state.stepRaceQuestionsState.raceQuestionDecision === 1) {
    // new set created
    raceQuestionSetTitle = state.stepRaceQuestionsState.newRQSTitle;
    raceQuestionSetItems = state.stepRaceQuestionsState.newRQSQuestions.map(q => (
      {
        ...q,
        name: q.body,
      }
    ));
  }

  return (
    <div>
      <StepTitle title={messages.title}>
        {raceClassId === 'R' ? messages.raceSubtitle : messages.measureSubtitle}
      </StepTitle>
      <Box marginBottom={5} paddingBottom={5}>
        {raceClassId === 'R' ? (
          // Race Card
          <RaceOverviewCard
            title={messages.raceSetUp.title}
            raceInfo={[
              { label: messages.raceSetUp.raceTitle, value: raceTitle },
              { label: messages.raceSetUp.raceType, value: raceType },
            ]}
            votingInfo={{
              label: messages.raceSetUp.votingSystem,
              value: votingOption,
              hasMultipleChoice,
            }}
            multipleChoice={multpleChoice}
            multipleChoiceLabel={messages.raceSetUp.numberOfCandidates}
            districtInfo={[
              // This will be mock data until districting is done
              { label: messages.raceSetUp.officeType, value: district ? district.breadcrumbs : null },
            ]}
            onEdit={onEdit}
            editScreen={STEP_NAMES.STEP_SET_UP}
            editButtonText={messages.edit}
          />
        ) : (
          // Measure Card
          <MetadataCard
            title={messages.measureSetUp.title}
            fields={[
              { label: messages.measureSetUp.measureTitle, value: raceTitle },
              { label: messages.raceSetUp.officeType, value: district.breadcrumbs },
            ]}
            onEdit={onEdit}
            editScreen={STEP_NAMES.STEP_SET_UP}
            editButtonText={messages.edit}
          />
        )}
      </Box>

      <Box marginBottom={5} paddingBottom={5}>
        <MetadataCard
          title={raceClassId === 'R' ? messages.raceCategory.title : messages.measureCategory.title}
          fields={[
            { label: messages.raceCategory.category, value: selectedCategory.title },
          ]}
          onEdit={onEdit}
          editScreen={STEP_NAMES.STEP_CATEGORY}
          editButtonText={messages.edit}
        />
      </Box>

      <Box marginBottom={5} paddingBottom={5}>
        <TextAreaCard
          title={messages.raceDescription.title}
          label={raceClassId === 'R' ? messages.raceDescription.label : messages.measureDescription.label}
          onEdit={onEdit}
          editScreen={STEP_NAMES.STEP_DESCRIPTION}
          editButtonText={messages.edit}
          rawEditorState={description}
        >
        </TextAreaCard>
      </Box>

      <Box marginBottom={5} paddingBottom={5}>
        <DataRowCard
          buttonText={messages.edit}
          emptyStateText={messages.candidates.empty}
          onClick={() => onEdit(STEP_NAMES.STEP_CANDIDATES)}
          rowActions={[]}
          rows={candidatesList}
          title={messages.candidates.title}
          emptyColumnText={messages.candidates.emptyColumn}
        />
      </Box>

      <Box marginBottom={5} paddingBottom={5}>
        <ListCard
          title={messages.bio.title}
          setInfo={{
            label: messages.bio.setTitle,
            value: bioSetTitle,
          }}
          setItemLabel={messages.bio.setLabel}
          setItems={bioSetItems}
          onEdit={onEdit}
          editScreen={STEP_NAMES.STEP_BIO_QUESTIONS}
          editButtonText={messages.edit}
          emptyStateText={messages.bio.empty}
        />
      </Box>

      <Box marginBottom={5} paddingBottom={5}>
        <ListCard
          title={raceClassId === 'R' ? messages.raceQuestions.title : messages.measureQuestions.title}
          setInfo={{
            label: messages.raceQuestions.setTitle,
            value: raceQuestionSetTitle,
          }}
          setItemLabel={messages.raceQuestions.setLabel}
          setItems={raceQuestionSetItems}
          onEdit={onEdit}
          editScreen={STEP_NAMES.STEP_RACE_QUESTIONS}
          editButtonText={messages.edit}
          emptyStateText={messages.raceQuestions.empty}
        />
      </Box>
    </div>
  );
};

Container.propTypes = {
  messages: PropTypes.object.isRequired,
  raceClassId: PropTypes.string,
  state: PropTypes.shape({
    race: PropTypes.object.isRequired,
    setRace: PropTypes.func.isRequired,
    step: PropTypes.number.isRequired,
    setStep: PropTypes.func.isRequired,
    openCancelDialog: PropTypes.bool.isRequired,
    setOpenCancelDialog: PropTypes.func.isRequired,
    stepSetUpState: PropTypes.object.isRequired,
    stepCategoryState: PropTypes.object.isRequired,
    stepDescriptionState: PropTypes.object.isRequired,
    stepCandidatesState: PropTypes.object.isRequired,
    stepBioQuestionsState: PropTypes.object.isRequired,
    stepRaceQuestionsState: PropTypes.object.isRequired,
  }),
  stepEnum: PropTypes.object.isRequired,
  goToStep: PropTypes.func.isRequired,
  raceTypes: PropTypes.array.isRequired,
  votingOptions: PropTypes.array.isRequired,
};

Container.defaultProps = {

};

export default Container;
