import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import EmptyState from 'components/EmptyState';

const styles = makeStyles({
  formContainer: {
    padding: '0rem 2rem 1rem 2rem',
  },
  addFormActions: {
    backgroundColor: '#eef4f8',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1.5rem 2rem',
    width: '100%',
    marginTop: '8px',
    minWidth: '600px',
  },
  cardContainerNoOutline: {
    border: 'none',
    boxShadow: 'none',
  },
});

const EditTemplatesForm = ({
  messages,
  template,
  onSetTemplate,
  templates,
  templatesLoaded,
  onCancel,
  onConfirm,
  hasActions,
  submitting,
}) => {
  const classes = styles();

  // Functions
  const handleChange = (event) => {
    onSetTemplate(event.target.value);
  };

  // Render
  return (
    <Card variant="outlined" className={classes.cardContainerNoOutline}>
      <CardContent className={classes.formContainer}>
        {templatesLoaded && templates.length > 0 ? (
          <FormControl variant="filled" className="ga-w-100-p">
            <InputLabel id="template-id">
              {messages.templatePlaceHolder}
            </InputLabel>

            <Select
              labelId="template-id"
              id="template-id-filled"
              value={template.id}
              onChange={handleChange}
            >
              {templates
                && templates.map((t, tIdx) => <MenuItem key={tIdx} value={t.id}>{t.name}</MenuItem>)
              }
            </Select>
          </FormControl>
        ) : (
          <EmptyState>
            You do not have any email templates. <a href="/mail/create-template">Create</a> your first one now!
          </EmptyState>
        )}
      </CardContent>

      {hasActions && (
        <CardActions className={classes.addFormActions}>
          <Typography component="span" variant="body2">
            {messages.requiredFields}
          </Typography>

          <div>
            <Button
              className="ga-mr-8"
              disabled={submitting}
              onClick={onCancel}
            >
              {messages.cancel}
            </Button>

            <Button
              color="secondary"
              disabled={!template || submitting || !templatesLoaded}
              onClick={onConfirm}
              variant="contained"
            >
              {messages.save}
            </Button>
          </div>
        </CardActions>
      )}
    </Card>
  );
};

EditTemplatesForm.propTypes = {
  messages: PropTypes.object.isRequired,
  template: PropTypes.object,
  onSetTemplate: PropTypes.func.isRequired,
  templates: PropTypes.array.isRequired,
  templatesLoaded: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  hasActions: PropTypes.bool.isRequired,
  submitting: PropTypes.bool,
};

EditTemplatesForm.defaultProps = {
  template: null,
  submitting: false,
};

export default EditTemplatesForm;
