import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, TextField, Typography, Button, FormControl, Box,
} from '@material-ui/core';
import stateErrors from 'helpers/StateErrors';

function AddCategory({
  customCategoryInputs,
  setCustomCategoryInputs,
  submittingCustomCategory,
  customCategoryErrors,
  messages,
  onSubmit,
}) {
  const getFormValue = (event) => {
    if (event.target.name == 'editable' || event.target.name == 'visible') {
      return event.target.checked;
    }
    return event.target.value;
  };

  // form state
  const handleInputChange = (event) => {
    const { name } = event.target;
    const value = getFormValue(event);
    if (name == 'name') {
      setCustomCategoryInputs({
        name: value,
        description: customCategoryInputs.description,
      });
    } else if (name == 'description') {
      setCustomCategoryInputs({
        name: customCategoryInputs.name,
        description: value,
      });
    }
  };

  return (
    <>
      <Box marginBottom={1}>
        <Typography
          component="h3"
          variant="h3"
          color="primary"
        >
          {messages.add.title}
        </Typography>
      </Box>
      <Grid container justifyContent="flex-start" alignItems="center" spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth>
            <TextField
              id="category-add-title"
              disabled={submittingCustomCategory}
              error={stateErrors.hasError('name', customCategoryErrors)}
              helperText={stateErrors.getError('name', customCategoryErrors)}
              label={'Category Name'}
              name="name"
              required
              variant="filled"
              value={customCategoryInputs.name}
              onChange={handleInputChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              id="category-add-description"
              disabled={submittingCustomCategory}
              error={stateErrors.hasError('description', customCategoryErrors)}
              helperText={stateErrors.getError('description', customCategoryErrors)}
              label={'Category Description'}
              name="description"
              required
              variant="filled"
              value={customCategoryInputs.description}
              onChange={handleInputChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button
            color="secondary"
            variant="contained"
            onClick={onSubmit}
          >
            {messages.add.text}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

AddCategory.propTypes = {
  customCategoryInputs: PropTypes.object.isRequired,
  setCustomCategoryInputs: PropTypes.func.isRequired,
  submittingCustomCategory: PropTypes.bool.isRequired,
  customCategoryErrors: PropTypes.object.isRequired,
  messages: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

AddCategory.defaultProps = {

};

export default AddCategory;
