import React, {
  useState,
  useEffect,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/styles';
import { Divider, Typography } from '@material-ui/core';
import theme from 'scripts/theme';
import {
  CategoryList,
  CategoryAddForm,
} from 'components/Categories';
import ConfirmationDialog from 'components/ConfirmationDialog';
import PageHeader from 'components/PageHeader';
import SnackbarAlert from 'components/SnackbarAlert';
import fetchUtil from 'helpers/Fetch';
import { useReorderParent } from 'hooks/useReorder';

const CategoryManagementContainer = ({
  breadcrumbText,
  breadcrumbUrl,
  heading,
  subheading,
  guideId,
  messages,
}) => {
  const [categories, setCategories] = useState([]);
  const [submittingAdd, setSubmittingAdd] = useState(false);
  const [submittingEdit, setSubmittingEdit] = useState(false);
  const [submittingDelete, setSubmittingDelete] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState({});

  // errors state
  const [addErrors, setAddErrors] = useState({});
  const [editErrors, setEditErrors] = useState({});

  const _snackbar = useRef();

  const {
    onReorder,
    moveItem: moveCategory,
    disabled,
    setOriginalCatList,
    originalCatList,
    setDisabled,
    onFailedDrop,
  } = useReorderParent({
    dataList: categories,
    setDataList: setCategories,
    originalStateName: 'originalCatList',
    apiEndpoint: `/api/v1/guide/${guideId}/category/reorder`,
    apiParamName: 'categories',
    showError: () => _snackbar.current.show('error', 'There was an issue trying to reorder your category. Try again or contact your guide admin if this continues'),
  });

  const onLoad = async () => {
    fetchUtil(`/api/v1/guide/${guideId}/category`, 'GET', {})
      .then(pCategories => {
        // Add uncateogrized category
        pCategories.push({
          title: messages.existing.uncategorizedTitle,
          description: '',
          id: -1,
        });
        setCategories(pCategories);
        setOriginalCatList(pCategories);
      }).catch(error => {
        console.error(error);
        onError('load', false); // action = load, message = none
      });
  };

  // load races for a given cateogry and update category object state
  const onLoadRaces = async (objCat) => {
    if (!objCat.details) {
      const copyCatList = [...categories];
      const objCatidx = copyCatList.findIndex(cl => cl.id == objCat.id);
      if (objCat.id !== -1) {
        fetchUtil(`/api/v1/guide/${guideId}/category/${objCat.id}`, 'GET', {})
          .then(cat => {
            if (objCatidx >= 0) {
              cat.details = { sortByAz: true, searchValue: '' };
              cat.isLoading = false;
              copyCatList[objCatidx] = cat;
              setCategories(copyCatList);
            }
          }).catch(error => {
            console.error(error);
            copyCatList[objCatidx].isLoading = false;
            copyCatList[objCatidx].error = messages.expanded.error;
            setCategories(copyCatList);
          });
      } else {
        // IF category is uncategorized
        fetchUtil(`/api/v1/guide/${guideId}/race`, 'GET', {})
          .then(races => {
            // Get uncategorized cat index
            if (objCatidx >= 0) {
              // Get all races where cateogy is null
              const uncategorizedRaces = races.filter(race => race.category === null);
              copyCatList[objCatidx].details = { sortByAz: true, searchValue: '' };
              copyCatList[objCatidx].races = uncategorizedRaces;
              setCategories(copyCatList);
            }
          }).catch(error => {
            console.error(error);
            copyCatList[objCatidx].isLoading = false;
            copyCatList[objCatidx].error = messages.expanded.error;
            setCategories(copyCatList);
          });
      }
    }
  };

  // second param used to determine correct error message
  const handleReorder = (uncategorizedIsLast, newCategoryIndex) => {
    if (uncategorizedIsLast) {
      onReorder(newCategoryIndex);
    } else {
      setDisabled(false);
      setCategories(originalCatList.slice());
      onError('reorder', 'This category cannot be reordered.');
    }
  };

  const onEdit = (cat, callback) => {
    setSubmittingEdit(true);
    fetchUtil(`/api/v1/guide/${guideId}/category/${cat.id}`, 'PATCH', {
      name: cat.title,
      description: cat.description,
      index: cat.index,
    })
      .then(response => {
        console.log('Success: ', response);
        onSuccess('update'); // action = update
        setSubmittingEdit(false);
        callback();
        const catListHolder = [...categories];
        // Get updated cat from catList
        const targetIndex = catListHolder.findIndex(catObj => catObj.id === response.id);

        if (targetIndex !== -1) {
          // Patch updated categoy with details
          catListHolder[targetIndex] = Object.assign(catListHolder[targetIndex], response);
          // Reset State
          setCategories(catListHolder);
        }
      }).catch(error => {
        console.error(error);
        setEditErrors(error);
        onError('update', false);
        setSubmittingEdit(false);
      });
  };

  const handleDeleteConfirm = (category) => {
    setCategoryToDelete(category);
    setShowDeleteConfirmation(true);
  };

  const handleDeleteCancel = () => {
    setCategoryToDelete({});
    setShowDeleteConfirmation(false);
  };

  const handleDeleteSubmit = () => {
    handleDelete(categoryToDelete);
  };

  const handleDelete = (cat) => {
    console.log('DELETE CATEGORY: ', cat.title);

    setSubmittingDelete(true);

    fetchUtil(`/api/v1/guide/${guideId}/category/${cat.id}`, 'DELETE', {})
      .then(response => {
        console.log('Success: ', response);
        onSuccess('delete');
        onLoad();
        setSubmittingDelete(false);
        setShowDeleteConfirmation(false);
      }).catch(error => {
        console.error(error);
        onError('delete', false);
        setSubmittingDelete(false);
        setShowDeleteConfirmation(false);
      });
  };

  const onAdd = (name, description, callback) => {
    setSubmittingAdd(true);
    console.log('Adding ', name);
    fetchUtil(`/api/v1/guide/${guideId}/category`, 'POST', {
      name,
      description,
    }).then(response => {
      console.log('Success: ', response);
      setSubmittingAdd(false);
      onSuccess('add');
      callback();
      onLoad();
    }).catch(error => {
      console.error(error);
      setAddErrors(error);
      onError('add', false);
      setSubmittingAdd(false);
    });
  };

  const onError = (action, message) => {
    const addFormErrorMessage = messages.add.error;
    const deleteErrorMessage = messages.delete.error;
    const editErrorMessage = messages.edit.error;

    let snackbarMessage;
    _snackbar.current.hide();

    if (message) {
      _snackbar.current.show('error', message);
      return;
    }

    switch (action) {
      case 'add':
        snackbarMessage = addFormErrorMessage;
        break;
      case 'update':
        snackbarMessage = editErrorMessage;
        break;
      case 'delete':
        snackbarMessage = deleteErrorMessage;
        break;
      default:
        snackbarMessage = 'An unknown error occurred. Please contact your guide admin.';
        break;
    }

    _snackbar.current.show('error', snackbarMessage);
  };

  const onSuccess = (action) => {
    const addFormSuccessMessage = messages.add.success;
    const deleteSuccessMessage = messages.delete.success;
    const editSuccessMessage = messages.edit.success;
    let message = 'Operation completed successfully.';

    _snackbar.current.hide();

    switch (action) {
      case 'add':
        message = addFormSuccessMessage;
        setAddErrors({});
        break;
      case 'update':
        message = editSuccessMessage;
        setEditErrors({});
        break;
      case 'delete':
        message = deleteSuccessMessage;
        break;
      default:
        message = '';
        break;
    }

    _snackbar.current.show('success', message);
  };

  const clearEditErrors = () => {
    setEditErrors({});
  };

  // fetch data on load
  useEffect(() => {
    onLoad();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <SnackbarAlert ref={_snackbar} />

      <PageHeader
        breadcrumbText={breadcrumbText}
        breadcrumbUrl={breadcrumbUrl}
        heading={heading}
        subheading={subheading}
      />

      <div className="ga-container">
        <div className="mdc-layout-grid">
          <div className="mdc-layout-grid__inner">
            <div className="mdc-layout-grid__cell mdc-layout-grid__cell--align-middle mdc-layout-grid__cell--span-9">
              <CategoryAddForm
                onAdd={onAdd}
                errors={addErrors}
                submitting={submittingAdd}
                messages={messages.add}
              />

              <Divider className="ga-my-40 ga-w-100-p" />

              <CategoryList
                clearErrors={clearEditErrors}
                disabled={disabled}
                errors={editErrors}
                guideId= {+guideId}
                list={categories}
                messages={messages}
                moveCategory={moveCategory}
                onDelete={handleDeleteConfirm}
                onEdit={onEdit}
                onError={onError}
                onFailedDrop={onFailedDrop}
                onLoadRaces={onLoadRaces}
                onReorder={handleReorder}
                submittingDelete={submittingDelete}
                submittingEdit={submittingEdit}
              />
            </div>
          </div>
        </div>
      </div>

      <ConfirmationDialog
        cancelButtonText={messages.delete.cancel}
        confirmButtonText={messages.delete.confirm}
        heading={messages.delete.heading}
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteSubmit}
        open={showDeleteConfirmation}
        submitting={submittingDelete}
      >
        <Typography component="p" variant="body1">
          {messages.delete.body} {categoryToDelete.title}?
        </Typography>
      </ConfirmationDialog>
    </ThemeProvider>
  );
};

CategoryManagementContainer.propTypes = {
  breadcrumbText: PropTypes.string,
  breadcrumbUrl: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  guideId: PropTypes.string,
  messages: PropTypes.object.isRequired,
};

CategoryManagementContainer.defaultProps = {
  breadcrumbText: 'Races & Measures',
  breadcrumbUrl: '/races',
  heading: 'Categories',
  subheading: 'Assigning your races to a category is a way to help keep your guide internally organized and set the order for your the ballot. Learn more about how to best use race categories in our help guide.',
  guideId: '-1',
};

export default CategoryManagementContainer;
