import React from 'react';
import ApplicationContextProvider from 'components/ApplicationContextProvider';
import { CandidateDetailsContainer } from './CandidateDetailsContainer';

const CandidateDetailsProvider = (props) => (
  <ApplicationContextProvider>
    <CandidateDetailsContainer {...props} />
  </ApplicationContextProvider>
);

export { CandidateDetailsProvider };
