import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Paper,
  Typography,
} from '@material-ui/core';
import { Editor, EditorState, convertFromRaw } from 'draft-js';

function TextAreaCard(props) {
  const {
    title, label, rawEditorState, onEdit, editScreen, editButtonText,
  } = props;

  const handleEditClick = () => {
    onEdit(editScreen);
  };

  const contentState = convertFromRaw(JSON.parse(rawEditorState));
  const editorState = EditorState.createWithContent(contentState);

  return (
    <Paper elevation={3} className="card-container">
      <div className="card-header-container">
        <Typography component="h3" variant="h3" color="primary">
          {title}
        </Typography>

        <Button
          variant="outlined"
          color="secondary"
          size="large"
          onClick={handleEditClick}
        >
          {editButtonText}
        </Button>
      </div>

      <p className="card-label">
        {label}
      </p>

      {!editorState.getCurrentContent().hasText() ? (
        <p className="mdc-typography--paragraph card-reminder-text">
          No answer
        </p>
      ) : (
        <Editor editorState={editorState} readOnly={true} />
      )}
    </Paper>
  );
}

TextAreaCard.propTypes = {
  title: PropTypes.string,
  label: PropTypes.string,
  rawEditorState: PropTypes.string,
  onEdit: PropTypes.func,
  editScreen: PropTypes.number,
  editButtonText: PropTypes.string,
};

TextAreaCard.defaultProps = {
  title: 'Default title',
  label: 'Default label',
  onEdit: () => {},
  editScreen: 3,
  editButtonText: 'Edit',
  rawEditorState: '',
};

export default TextAreaCard;
