import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Box,
} from '@material-ui/core';
import Interweave from 'interweave';
import { useNavigate } from 'react-router-dom';
import MetadataCard from 'components/RaceSteps/StepReview/MetadataCard.js';
import QuestionListCard from 'components/RaceSteps/StepReview/QuestionListCard.js';
import Stepper from 'components/RaceSteps/Stepper';
import StepTracker from 'components/RaceSteps/StepTracker';

const useStyles = makeStyles((theme) => ({
  cardSection: {
    marginBottom: '2rem',
  },
  subtitle: {
    marginBottom: '2rem',
    [theme.breakpoints.up('md')]: {
      width: '75%',
    },
  },
  container: {
    position: 'relative',
  },
}));

const CandidateReview = ({
  detailsFields,
  imageURL,
  profileFields,
  messages,
  questionFields,
  saveChanges,
  updatePage,
}) => {
  const navigator = useNavigate();
  const classes = useStyles();

  const handleEditProfile = () => {
    updatePage(3);
    navigator('/profile');
    window.scrollTo(0, 0);
  };

  const handleEditDetails = () => {
    navigator('/details');
    window.scrollTo(0, 0);
  };

  const handleEditQuestions = () => {
    navigator('/questions');
    window.scrollTo(0, 0);
  };

  const onSubmitResponse = async () => {
    await saveChanges(() => {
      updatePage(5);
      navigator('/thank-you');
      window.scrollTo(0, 0);
    });
  };

  return (
    <div className={classes.container}>
      <StepTracker currentStep={4} totalSteps={4} top="1rem" messages={{
        step: 'Step',
        of: 'of',
      }} />
      <Typography variant="h1" component="h1" color="textSecondary" paragraph>
        {messages.stepHeader}
      </Typography>
      <Typography variant="body1" component="p" paragraph className={classes.subtitle}>
        <Interweave content={messages.stepSubheader} />
      </Typography>
      <Box marginBottom={3}>
        <Stepper
          messages={messages.stepper}
          visibility={['saveContinue']}
          onSaveContinue={onSubmitResponse}
        />
      </Box>
      <div className={classes.cardSection}>
        <MetadataCard
          fields={detailsFields}
          onEdit={handleEditDetails}
          title={messages.detailsTitle}
          editButtonText={messages.edit}
        />
      </div>
      <div className={classes.cardSection}>
        <MetadataCard
          fields={profileFields}
          hasImage={true}
          imageURL={imageURL}
          onEdit={handleEditProfile}
          title={messages.profileTitle}
          editButtonText={messages.edit}
        />
      </div>
      <div className={classes.cardSection}>
        <QuestionListCard
          questions={questionFields}
          onEdit={handleEditQuestions}
          title={messages.questionTitle}
          editButtonText={messages.edit}
        />
      </div>
      <Stepper
        messages={messages.stepper}
        visibility={['back', 'saveContinue']}
        onBack={handleEditQuestions}
        onSaveContinue={onSubmitResponse}
      />
    </div>
  );
};

CandidateReview.propTypes = {
  detailsFields: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
  imageURL: PropTypes.string,
  messages: PropTypes.object,
  profileFields: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
  questionFields: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
  saveChanges: PropTypes.func,
  updatePage: PropTypes.func.isRequired,
};

CandidateReview.defaultProps = {
  detailsFields: [],
  imageURL: null,
  messages: {
    detailsTitle: 'Candidate Details',
    profileTitle: 'Campaign Profile',
    stepHeader: 'Review',
    stepSubheader: '<b>You must click submit for your answers to be published.</b> Please make sure to review your answers thoroughly. You can always come back and edit them using the instructions sent in the invite email.',
    questionTitle: 'Questions',
    edit: 'Edit',
    stepper: {
      back: 'Back',
      saveContinue: 'Submit Response',
    },
  },
  name: '',
  profileFields: [],
  questionFields: [],
  saveChanges: () => {},
};

export default CandidateReview;
