import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import BioSetSelector from 'components/BioQuestions/BioSetSelector';
import RadioCardList from 'components/RadioCardList';
import { StepTitle } from 'components/RaceSteps';
import fetchUtil from 'helpers/Fetch';
import NewBioSetCard from './NewBioSetCard';
import AllBioSetQuestions from './AllBioSetQuestions';

const Container = ({
  stepState,
  messages,
  initialOptions: INITIAL_OPTIONS,
  guideId,
  onError,
}) => {
  const onLoad = () => {
    stepState.setLoadingBio(true);
    fetchUtil(`/api/v1/guide/${guideId}/bioquestions/set`, 'GET', {})
      .then(sets => {
        stepState.setExistingSets(sets);

        fetchUtil(`/api/v1/guide/${guideId}/bioquestions`, 'GET', {})
          .then(qs => {
            stepState.setAllQs(qs);

            stepState.setLoadingBio(false);
          }).catch(error => {
            console.error(error);
            onError('load', false); // action = load, message = none
            stepState.setLoadingBio(false);
          });
      }).catch(error => {
        console.error(error);
        onError('load', false); // action = load, message = none
        stepState.setLoadingBio(false);
      });
  };

  // Component Did Mount
  useEffect(() => {
    onLoad();
  }, []);

  const handleBioSetChoice = set => {
    console.log('Bio Set Chosen: ', set);
    stepState.setBioSetChoiceErrors({});
    if (typeof set !== 'undefined' && set != '' && set != null) {
      const finder = stepState.existingSets.find(el => el.id === set);
      if (typeof finder !== 'undefined') {
        stepState.setBioSetChoice(finder);
      } else {
        stepState.setBioSetChoice(null);
      }
    } else {
      stepState.setBioSetChoice(null);
    }
  };

  const handleNewSetTitleChange = title => {
    stepState.setNewSetTitleErrors({});
    stepState.setNewSetTitle(title);
  };

  const handleSelectedQuestionsChange = newlySelected => {
    const indexOfNewlySelected = stepState.newSetQuestions.findIndex(el => el.id === newlySelected.id);
    if (indexOfNewlySelected >= 0) {
      stepState.setNewSetQuestions(stepState.newSetQuestions.filter(i => i.id !== newlySelected.id));
    } else {
      stepState.setNewSetQuestions([...stepState.newSetQuestions, newlySelected]);
    }
  };

  return (
    <div>
      <StepTitle title={messages.title}>
        {messages.subtitle}
      </StepTitle>

      {/* Initial Decision */}
      <Box marginBottom={5} paddingBottom={5}>
        <RadioCardList
          cards={INITIAL_OPTIONS}
          checked={stepState.bioDecision}
          onChange={id => stepState.setBioDecision(id)}
        />
      </Box>

      {stepState.bioDecision === 0 && (
        <Box marginBottom={5} paddingBottom={5}>
          <BioSetSelector
            emptyStateText='You have not created any biographical sets yet. Please select one of the other options to either create your first set or use all your exisiting biographical questions.'
            header={messages.useExisting.title}
            label={messages.useExisting.select}
            onChange={handleBioSetChoice}
            errors={stepState.bioSetChoiceErrors}
            selected={stepState.bioSetChoice ? stepState.bioSetChoice.id : ''}
            questionListLabel={messages.useExisting.questions}
            sets={stepState.existingSets}
          />
        </Box>
      )}

      {stepState.bioDecision === 1 && (
        <Box marginBottom={5} paddingBottom={5}>
          <NewBioSetCard
            header={messages.createNew.title}
            subtext={messages.createNew.supporting}
            questions={stepState.allQs}
            questionListLabel={messages.createNew.list}
            inputLabel={messages.createNew.label}
            title={stepState.newSetTitle}
            onTitleChange={handleNewSetTitleChange}
            selectedQuestions={stepState.newSetQuestions}
            onSelectedQuestionsChange={handleSelectedQuestionsChange}
            errors={stepState.newSetTitleErrors}
            defaultQuestionListLabel={messages.createNew.default}
            emptyStateText={messages.createNew.empty}
          />
        </Box>
      )}

      {stepState.bioDecision === 2 && (
        <Box marginBottom={5} paddingBottom={5}>
          <AllBioSetQuestions
            header={messages.useAll.title}
            questionListLabel={messages.useAll.list}
            questions={stepState.allQs}
            emptyStateText={messages.useAll.empty}
          />
        </Box>
      )}
    </div>
  );
};

Container.propTypes = {
  stepState: PropTypes.shape({
    bioDecision: PropTypes.number.isRequired,
    setBioDecision: PropTypes.func.isRequired,
    existingSets: PropTypes.array.isRequired,
    setExistingSets: PropTypes.func.isRequired,
    newSetTitle: PropTypes.string.isRequired,
    setNewSetTitle: PropTypes.func.isRequired,
    newSetTitleErrors: PropTypes.object.isRequired,
    setNewSetTitleErrors: PropTypes.func.isRequired,
    newSetQuestions: PropTypes.array.isRequired,
    setNewSetQuestions: PropTypes.func.isRequired,
    loadingBio: PropTypes.bool.isRequired,
    setLoadingBio: PropTypes.func.isRequired,
    allQs: PropTypes.array.isRequired,
    setAllQs: PropTypes.func.isRequired,
    bioSetChoice: PropTypes.object,
    setBioSetChoice: PropTypes.func.isRequired,
    bioSetChoiceErrors: PropTypes.object.isRequired,
    setBioSetChoiceErrors: PropTypes.func.isRequired,
  }),
  messages: PropTypes.object.isRequired,
  initialOptions: PropTypes.array.isRequired,
  guideId: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
};

Container.defaultProps = {

};

export default Container;
