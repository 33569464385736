import React from 'react';
import PropTypes from 'prop-types';
import { Button, ThemeProvider } from '@material-ui/core';
import theme from 'scripts/theme';
import ButtonMenu from 'components/ButtonMenu';
import PageHeader from 'components/PageHeader';
import PageActionsHeader from 'components/PageActionsHeader';
import SnackbarAlert from 'components/SnackbarAlert';
import DistrictTable from 'components/Districts/Partials/DistrictTable';
import fetchUtil from 'helpers/Fetch';

class DistrictDashboardContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      districts: [],
      loading: false,
    };
    this._snackbar = React.createRef();

    this.handleMenuClick = this.handleMenuClick.bind(this);
  }

  componentDidMount() {
    const { guideId } = this.props;
    this.setState({
      loading: true,
    });

    this.checkSuccessMsg();

    fetchUtil(`/api/v1/guide/${guideId}/district?districtFormat=normal`, 'GET', {})
      .then((response) => {
        this.setState({
          districts: response,
          loading: false,
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          loading: false,
        });
      });

    // Checking messages
    this.checkSuccessMsg();
  }

  showSuccessSnackbar(message) {
    this._snackbar.current.hide();
    this._snackbar.current.show('success', message);
  }

  checkSuccessMsg() {
    const { messages, showSuccessMsgFrom } = this.props;

    switch (showSuccessMsgFrom) {
      case 'createDistrictSet': {
        return this.showSuccessSnackbar('Your district set was created successfully!');
      }
      case 'deleteDistrictSet': {
        return this.showSuccessSnackbar('Your district set was deleted successfully!');
      }
      case 'deleteCompositeDistrict': {
        return this.showSuccessSnackbar('Your composite district was deleted successfully!');
      }
      case 'deleteZipDistrict': {
        return this.showSuccessSnackbar('Your zip district was deleted successfully!');
      }
      case 'newDistrict': {
        return this.showSuccessSnackbar(messages.successSavingDistrictSet);
      }
      case 'editedDistrict': {
        return this.showSuccessSnackbar(messages.zipCodeUpdateSuccess);
      }
      case 'createcomposite': {
        return this.showSuccessSnackbar(messages.successCreateComposite);
      }
      case 'editcomposite': {
        return this.showSuccessSnackbar(messages.successEditComposite);
      }
      case 'creategis': {
        return this.showSuccessSnackbar('Your GIS District Set was created successfully!');
      }
      case 'deleteGisDistrictSet': {
        return this.showSuccessSnackbar('Your GIS District Set was deleted successfully!');
      }
      default: {
        // do nothing
      }
    }
  }

  handleMenuClick(path) {
    window.location.href = path;
  }

  handleDistrictSet = () => {
    const { navigation } = this.props;
    window.location.href = navigation.districtSet;
  }

  render() {
    const {
      breadcrumbText,
      breadcrumbUrl,
      heading,
      subheading,
      types,
    } = this.props;

    const {
      districts,
      loading,
    } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <SnackbarAlert ref={this._snackbar} />
        <PageHeader
          breadcrumbText={breadcrumbText}
          breadcrumbUrl={breadcrumbUrl}
          heading={heading}
          subheading={subheading}
        />

        <PageActionsHeader>
          <ButtonMenu
            color="primary"
            id="create-manual-district"
            options={[
              { label: 'Composite District', onClick: () => this.handleMenuClick('/districts/composite') },
              { label: 'District Set', onClick: () => this.handleDistrictSet() },
              { label: 'Zip Code District', onClick: () => this.handleMenuClick('/districts/zip-code') },
            ]}
            size="small"
            variant="text"
          >
            Create Manual District
          </ButtonMenu>

          <Button
            color="secondary"
            variant="contained"
            href="/districts/import"
            size="small"
          >
            Upload GIS District
          </Button>
        </PageActionsHeader>

        <div className="ga-container">
          <div className="mdc-layout-grid">
            <div className="mdc-layout-grid__inner">
              <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
                <DistrictTable
                  districts={districts}
                  types={Object.values(types)}
                  typesMap={types}
                  loading={loading}
                />
              </div>
            </div>
          </div>
        </div>
      </ThemeProvider>
    );
  }
}

DistrictDashboardContainer.propTypes = {
  breadcrumbText: PropTypes.string,
  breadcrumbUrl: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  guideId: PropTypes.string.isRequired,
  types: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired),
  navigation: PropTypes.object,
  messages: PropTypes.object,
  showSuccessMsgFrom: PropTypes.string,
};

DistrictDashboardContainer.defaultProps = {
  breadcrumbText: 'Races & Measures',
  breadcrumbUrl: '/races',
  heading: 'Districts',
  subheading: 'Adding districts to races enable voters to view only the races on their specific ballot. There are different ways to set up the districting - review our quick guide to find the districting strategy that is right for you.',
  navigation: {},
  showSuccessMsgFrom: '',
};

export default DistrictDashboardContainer;
