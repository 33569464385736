import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogTitle,
  IconButton,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  field: {
    marginBottom: '1.2rem',
  },
  formActions: {
    backgroundColor: '#eef4f8',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '.8rem 2rem',
  },
});

export default function GISDistrictSetEditForm({
  containingDistricts,
  containingDistrictId,
  errors,
  name,
  onClose,
  onInputChange,
  onSubmit,
  show,
  submitting,
}) {
  const classes = useStyles();

  const hasError = (key) => (key in errors);

  const getError = (key) => {
    if (hasError(key) && errors[key].length > 0) {
      return errors[key][0];
    }
    return null;
  };

  return (
    <Dialog
      aria-labelledby="update-gis-district"
      fullWidth
      maxWidth="sm"
      open={show}
      onClose={onClose}
    >

      <DialogTitle id="update-gis-district" disableTypography>
        <Typography component="h1" variant="h1">
          Update GIS District
        </Typography>

        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <Card variant="outlined">
        <CardContent>
          <Grid container>
            <Grid item xs={12}>
              <FormControl fullWidth className={classes.field}>
                <TextField
                  id={name}
                  disabled={submitting}
                  error={hasError('name')}
                  helperText={hasError('name') ? getError('name') : 'i.e. New Jersey school districts'}
                  label="GIS District Set Name"
                  onChange={onInputChange}
                  name="name"
                  required
                  value={name}
                />
              </FormControl>

              <FormControl
                error={hasError('containingDistrict')}
                disabled={submitting}
                fullWidth
                required
                variant="filled"
              >
                <InputLabel id="containing-district-label">
                  Containing District
                </InputLabel>

                <Select
                  id="containing-district-select"
                  error={hasError('containingDistrict')}
                  name="containingDistrict"
                  value={containingDistrictId}
                  onChange={onInputChange}
                >
                  {containingDistricts.map(dist => (<MenuItem value={dist.id} key={dist.id}>{dist.name}</MenuItem>))}
                </Select>
                <FormHelperText>{hasError('containingDistrict') ? getError('containingDistrict') : ''}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>

        <CardActions className={classes.formActions}>
          <Button onClick={onClose} size="large">
            Cancel
          </Button>

          <Button
            variant="contained"
            color="secondary"
            onClick={onSubmit}
            size="large"
            disabled={submitting}
          >
            Update
          </Button>
        </CardActions>
      </Card>
    </Dialog>
  );
}

GISDistrictSetEditForm.propTypes = {
  containingDistricts: PropTypes.array,
  containingDistrictId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errors: PropTypes.object,
  name: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  onInputChange: PropTypes.func,
  onSubmit: PropTypes.func,
  show: PropTypes.bool,
  submitting: PropTypes.bool,
};

GISDistrictSetEditForm.defaultProps = {
  containingDistrictId: null,
  containingDistricts: [],
  errors: {},
  name: '',
  onClose: () => {},
  onInputChange: () => {},
  onSubmit: () => {},
  show: false,
  submitting: false,
};
