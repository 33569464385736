import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  text: {
    fontSize: '18px',
    color: '#333333',
  },
  container: {
    marginBottom: '2rem',
    maxWidth: '660px',
  },
});

const StepTitle = ({ title, children }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography
        color="primary"
        component="h2"
        variant="h1"
      >
        {title}
      </Typography>

      <Typography
        className={classes.text}
        component="p"
        variant="body1"
      >
        {children}
      </Typography>
    </Box>
  );
};

StepTitle.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

StepTitle.defaultProps = {
  title: '',
};

export default StepTitle;
