import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CTA from 'components/CTA';
import RaceChecklistModal from 'components/Races/Partials/RaceChecklistModal';
import HowToPublishModal from 'components/Races/Partials/HowToPublishModal';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonContainer: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

function RacePublishingCta({ messagesChecklist, messagesHowTo }) {
  const classes = useStyles();
  const [showChecklistModal, setShowChecklistModal] = useState(false);
  const [showHowTotModal, setShowHowToModal] = useState(false);

  const handleCloseModal = () => {
    setShowChecklistModal(false);
    setShowHowToModal(false);
  };

  const handleOpenChecklistModal = () => {
    setShowChecklistModal(true);
  };

  const handleOpenHowToModal = () => {
    setShowHowToModal(true);
  };

  return (
    <CTA>
      <>
        <div className={classes.container}>
          <div>
            <Typography component="h3" variant="h3" color="primary">
              Are you ready to publish a race?
            </Typography>
            <Typography component="p" variant="body1">
              Review our race checklist to make sure your race is truly ready to go!
            </Typography>
          </div>

          <div className={classes.buttonContainer}>
            <Button
              size="large"
              variant="outlined"
              color="secondary"
              onClick={handleOpenChecklistModal}
            >
              View Checklist
            </Button>

            <Button
              size="large"
              variant="outlined"
              color="secondary"
              onClick={handleOpenHowToModal}
            >
              How to Publish
            </Button>

            <RaceChecklistModal
              modalIsOpen={showChecklistModal}
              onClose={handleCloseModal}
              messages={messagesChecklist}
            />

            <HowToPublishModal
              modalIsOpen={showHowTotModal}
              onClose={handleCloseModal}
              messages={messagesHowTo}
            />
          </div>
        </div>
      </>
    </CTA>
  );
}

RacePublishingCta.propTypes = {
  messagesChecklist: PropTypes.object,
  messagesHowTo: PropTypes.object,
};

RacePublishingCta.defaultProps = {
  messagesChecklist: {},
  messagesHowTo: {},
};

export default RacePublishingCta;
