import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import BioQuestionSetEditForm from 'components/BioQuestions/BioQuestionSetEditForm';

const useStyles = makeStyles({
  modalContainer: {
    paddingBottom: '.5rem',
    overflow: 'hidden',
  },
  modalContentContainer: {
    padding: 0,
  },
  modalContent: {
    padding: '0rem 2rem',
  },
});

function BioQuestionSetEditModal({
  cancelButtonText,
  errors,
  heading,
  id,
  name,
  onChange,
  onClose,
  onSubmit,
  open,
  requiredText,
  subheading,
  submitButtonText,
  submitting,
}) {
  const classes = useStyles();

  return (
    <Dialog
      aria-labelledby="edit-race-question-title"
      maxWidth="md"
      open={open}
      onClose={onClose}
      className={classes.modalContainer}
    >
      <DialogTitle id="edit-race-question-title" disableTypography >
        <Typography component="h1" variant="h1">
          {heading}
        </Typography>

        <IconButton onClick={onClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.modalContentContainer}>
        <DialogContentText className={classes.modalContent}>
          {subheading}
        </DialogContentText>

        <BioQuestionSetEditForm
          buttonText={submitButtonText}
          errors={errors}
          id={id}
          name={name}
          onChange={onChange}
          onSubmit={onSubmit}
          outlined={false}
          requiredText={requiredText}
          submitting={submitting}
        >
          <Button onClick={onClose} size="large">
            {cancelButtonText}
          </Button>
        </BioQuestionSetEditForm>
      </DialogContent>
    </Dialog>
  );
}

BioQuestionSetEditModal.propTypes = {
  cancelButtonText: PropTypes.string,
  errors: PropTypes.object,
  heading: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  open: PropTypes.bool,
  requiredText: PropTypes.string,
  subheading: PropTypes.string,
  submitButtonText: PropTypes.string,
  submitting: PropTypes.bool,
};

BioQuestionSetEditModal.defaultProps = {
  cancelButtonText: 'Cancel',
  errors: {},
  heading: 'Edit Biographical Set',
  onChange: () => {},
  onClose: () => {},
  onSubmit: () => {},
  open: false,
  requiredText: '*Required fields for a biographical set',
  submitButtonText: 'Update',
  subheading: 'Looking to rearrange the order of biographical fields? You can rearrange them within the set itself.',
  submitting: false,
};

export default BioQuestionSetEditModal;
