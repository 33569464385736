import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';

class SnackbarAlert extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: false,
      message: '',
      variant: 'success',
    };
  }

  show(variant, message) {
    this.setState({
      isVisible: true,
      message,
      variant,
    });
  }

  hide() {
    this.setState({
      isVisible: false,
    });
  }

  render() {
    const { isVisible, message, variant } = this.state;

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={isVisible}
        autoHideDuration={6000}
        onClose={() => this.hide()}
      >
        <Alert
          severity={variant}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => this.hide()}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >{message}</Alert>
      </Snackbar>
    );
  }
}

export default SnackbarAlert;
