import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import Interweave from 'interweave';
import ReadOnlyField from 'components/ReadOnlyField';
import HelpfulHint from 'components/HelpfulHint';
import Stepper from 'components/RaceSteps/Stepper';
import StepTracker from 'components/RaceSteps/StepTracker';

const useStyles = makeStyles((theme) => ({
  detailList: {
    marginTop: '1rem',
  },
  detailListItem: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
    marginBottom: '1.25rem',
    paddingBottom: '1.25rem',
    '&:last-child': {
      marginBottom: '0rem',
    },
  },
  fields: {
    marginTop: '1.563rem',
  },
  heading: {
    color: theme.palette.primary.main,
    fontWeight: '500',
    marginBottom: '5px',
  },
  pageTitle: {
    color: '#757575',
    marginBottom: '1.625rem',
  },
  section: {
    marginBottom: '2.5rem',
  },
  container: {
    position: 'relative',
  },
}));

const CandidateDetails = ({
  email,
  errors,
  fields,
  messages,
  name,
  onChange,
  resetState,
  saveChanges,
  submitting,
  updatePage,
}) => {
  const navigator = useNavigate();
  const classes = useStyles();

  const onBack = () => {
    updatePage(0);
    navigator('/');
    window.scrollTo(0, 0);
  };

  const onSkip = () => {
    resetState();
    updatePage(2);
    navigator('/profile');
    window.scrollTo(0, 0);
  };

  const onSaveContinue = async () => {
    await saveChanges(() => {
      updatePage(2);
      navigator('/profile');
      window.scrollTo(0, 0);
    });
  };

  const hasError = (fieldName) => Object.prototype.hasOwnProperty.call(errors, fieldName);

  return (
    <div className={classes.container}>
      <StepTracker currentStep={1} totalSteps={4} top="1rem" messages={{
        step: 'Step',
        of: 'of',
      }} />
      <Typography variant="h1" component="h1" color="textSecondary" paragraph>
        {messages.stepHeader}
      </Typography>

      <Grid container spacing={5} className={classes.section}>
        <Grid item xs={12} md={8}>
          <Typography variant="h5" component="h2" className={classes.heading}>
            {messages.detailsHeader}
          </Typography>

          <div className={classes.detailList}>
            {fields.map((field, index) => (
              <ReadOnlyField
                key={index}
                className={classes.detailListItem}
                label={field.label}
                value={field.values.join(', ')}
              />
            ))}
          </div>
        </Grid>

        <Grid item xs={12} md={4}>
          <HelpfulHint>
            <Interweave content={messages.incorrectInfoText} />
          </HelpfulHint>
        </Grid>
      </Grid>

      <Grid container spacing={5} className={classes.section}>
        <Grid item xs={12} md={8}>
          <Typography variant="h5" component="h3" className={classes.heading}>
            {messages.contactHeader}
          </Typography>

          <Typography variant="body1" component="p" className={classes.subtext}>
            {messages.contactSubtext}
          </Typography>

          <FormControl fullWidth className={classes.fields}>
            <TextField
              id="contact-name"
              disabled={submitting}
              error={hasError('name')}
              helperText={hasError('name') ? errors.name : ''}
              label="Contact Name"
              name="name"
              variant="filled"
              onChange={(event) => onChange('name', event.target.value)}
              value={name}
            />
          </FormControl>

          <FormControl fullWidth className={classes.fields}>
            <TextField
              id="contact-email"
              disabled={submitting}
              error={hasError('email')}
              helperText={hasError('email') ? errors.email : ''}
              label="Contact Email"
              name="email"
              variant="filled"
              onChange={(event) => onChange('email', event.target.value)}
              value={email}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <HelpfulHint>
            {messages.hintText}
          </HelpfulHint>
        </Grid>
      </Grid>
      <Stepper
        messages={messages.stepper}
        visibility={['back', 'skip', 'saveContinue']}
        onBack={onBack}
        onSkip={onSkip}
        onSaveContinue={onSaveContinue}
      />
    </div>
  );
};

CandidateDetails.propTypes = {
  email: PropTypes.string,
  errors: PropTypes.object,
  fields: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    values: PropTypes.array.isRequired,
  })),
  messages: PropTypes.object.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func,
  resetState: PropTypes.func,
  saveChanges: PropTypes.func,
  submitting: PropTypes.bool.isRequired,
  updatePage: PropTypes.func.isRequired,
};

CandidateDetails.defaultProps = {
  email: '',
  errors: {},
  fields: [],
  name: '',
  onChange: () => {},
  resetState: () => {},
  saveChanges: () => {},
};

export default CandidateDetails;
