import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/core/styles';
import {
  Button,
  Typography,
} from '@material-ui/core';
import BulkEditModal from 'components/Races/Partials/BulkEditModal';
import SetDistrictFromNameModal from 'components/Races/Partials/SetDistrictFromNameModal';
import BulkPublishResults from 'components/Races/Partials/BulkPublishResults';
import RaceDashboardNavigation from 'components/Races/Partials/RaceDashboardNavigation';
import RaceExportModal from 'components/Races/Partials/RaceExportModal';
import RacePublishingCta from 'components/Races/Partials/RacePublishingCta';
import RaceTable from 'components/Races/Partials/RaceTable';
import ConfirmationDialog from 'components/ConfirmationDialog';
// delete once we bring kpis back
import HelpfulHint from 'components/HelpfulHint';
// import KPICard from 'components/KPICard';
import PageActionsHeader from 'components/PageActionsHeader';
import PageHeader from 'components/PageHeader';
import SnackbarAlert from 'components/SnackbarAlert';
import fetchUtil from 'helpers/Fetch';
import GaUtils from 'helpers/GaUtils';
import useSavedTableSettings from 'hooks/useSavedTableSettings';
import theme from 'scripts/theme';

const RaceDashboardContainer = ({
  bioSets,
  categories,
  guideId,
  guideName,
  helpfulHintText1,
  helpfulHintText2,
  helpfulHintText3,
  messages,
  navigation,
  questionSets,
  races,
  showSuccessMsgFrom,
}) => {
  const [bulkEditSubmitting, setBulkEditSubmitting] = useState(false);
  const [bulkEditErrors, setBulkEditErrors] = useState({});
  const [publishSubmitting, setPublishSubmitting] = useState(false);
  const [openPublishDialog, setOpenPublishDialog] = useState(false);
  const [openBulkEdit, setOpenBulkEdit] = useState(false);
  const [publish, setPublish] = useState(false);
  const [racesToDelete, setRacesToDelete] = useState([]);
  const [tableSelectedRaces, setTableSelectedRaces] = useState([]); // controls the selected state in RaceTable
  const [bulkEditRaces, setBulkEditRaces] = useState([]); // holds the FULL race objects that were selected in tableSelectedRaces
  const [racesToExport, setRacesToExport] = useState([]);
  const [racesToPublish, setRacesToPublish] = useState([]);
  const [localRaces, setLocalRaces] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [exportSubmitting, setExportSubmitting] = useState(false);
  const [showPublishResults, setShowPublishResults] = useState(false);
  const [publishExceptions, setPublishExceptions] = useState([]);

  const [racesForDistrictSetting, setRacesForDistrictSetting] = useState([]);
  const [showSetDistrictModal, setShowDistrictModal] = useState(false);
  const [submittingSetDistrict, setSubmittingSetDistrict] = useState(false);
  const [statusSetDistrict, setStatusSetDistrict] = useState(false); // false means pre-action, true means action completed and show results
  const [failedSetDistricts, setFailedSetDistricts] = useState({});

  const [
    tableFilterModel,
    setTableFilterModel,
    tableSortModel,
    setTableSortModel,
    hiddenColumns,
    setHiddenColumns,
  ] = useSavedTableSettings({ key: 'races', initialHiddenColumns: ['raceType', 'candidateCount', 'BioQ Set', 'Question Set'] });

  const categoriesWithUncategorized = useMemo(() => {
    const copied = [...categories];
    const maxIndex = Math.max(...copied.map(c => c.index), 0); // get max index in categories array
    copied.push({
      id: -1,
      index: maxIndex + 1,
      title: 'Uncategorized',
    });
    return copied;
  }, [categories]);

  const _snackbar = useRef();

  useEffect(() => {
    setLocalRaces(races);
  }, [races]);

  useEffect(() => {
    if (showSuccessMsgFrom && showSuccessMsgFrom.length > 0) {
      showFlashMessage();
    }
  }, [showSuccessMsgFrom]);

  const goToLink = (url) => {
    window.location.href = url;
  };

  const showFlashMessage = () => {
    // Note: This method was updated to check for a generic parameter that indicates which page the success message comes from.
    switch (showSuccessMsgFrom) {
      case 'bulkRaceImport':
        _snackbar.current.show('success', messages.successUploadCsvFile);
        break;
      case 'saveMeasure':
        _snackbar.current.show('success', messages.successSaveMeasure);
        break;
      case 'saveRace':
        _snackbar.current.show('success', messages.successSaveRace);
        break;
      case 'deleteRace':
        _snackbar.current.show('success', messages.successDelete);
        break;
      default:
        break;
    }
  };

  const handleShowPublish = (isPublish, selected) => {
    setOpenPublishDialog(true);
    setPublish(isPublish);
    setRacesToPublish(selected);
  };

  const handleSavePublish = () => {
    const url = `/api/v1/guide/${guideId}/races/publish`;
    const body = { publish, ballotItemIds: racesToPublish };

    setPublishSubmitting(true);

    fetchUtil(url, 'POST', body)
      .then(response => {
        setPublishSubmitting(false);
        setOpenPublishDialog(false);
        setRacesToPublish([]);

        // Check if all races were published/unpublished successfully
        if (!response || (response && Object.keys(response).length <= 0)) {
          const successMsg = publish ? messages.publish.successPublish : messages.unpublish.successPublish;
          _snackbar.current.show('success', successMsg);

          // Updating races
          const copyRaces = [...localRaces];
          copyRaces.forEach(race => {
            if (racesToPublish.some(bi => bi === race.id)) {
              race.visible = publish;
            }
          });

          setLocalRaces(copyRaces);
          setPublish(false);
          return;
        }

        // There was an issue publishing one or more races, so we need to get details
        const copyRaces = [...localRaces];
        const exceptions = [];
        copyRaces.forEach(race => {
          if (Object.prototype.hasOwnProperty.call(response, race.id)) {
            exceptions.push({ name: race.name, issue: response[race.id] });
          } else if (racesToPublish.some(bi => bi === race.id)) {
            race.visible = publish;
          }
        });

        setPublishExceptions(exceptions);
        setShowPublishResults(true);
        setLocalRaces(copyRaces);
      })
      .catch((error) => {
        let errorMsg = publish ? messages.publish.errorPublish : messages.unpublish.errorPublish;

        if (error.status === 403) {
          errorMsg = messages.forbidden;
        }

        _snackbar.current.show('error', errorMsg);

        setPublishSubmitting(false);
        setOpenPublishDialog(false);
        setPublish(false);
        setRacesToPublish([]);

        console.error(error);
      });
  };

  const handleClosePublishResults = () => {
    setShowPublishResults(false);
    setPublishExceptions([]);
    setPublish(false);
  };

  const handleCloseDelete = () => {
    setRacesToDelete([]);
    setShowDeleteModal(false);
  };

  const handleShowDelete = (selected) => {
    setRacesToDelete(selected);
    setShowDeleteModal(true);
  };

  const handleDelete = async () => {
    const URL = `/api/v1/guide/${guideId}/races/softdelete`;
    const METHOD = 'POST';
    const BODY = {
      ballotItemIds: racesToDelete,
    };

    setPublishSubmitting(true);

    try {
      await fetchUtil(URL, METHOD, BODY);

      const successMsg = messages.delete.successDelete;
      _snackbar.current.show('success', successMsg);

      // updating races
      const copyRaces = [...localRaces].filter(r => racesToDelete.indexOf(r.id) < 0);

      // Updating state
      setPublishSubmitting(false);
      setShowDeleteModal(false);
      setRacesToDelete([]);
      setLocalRaces(copyRaces);
    } catch (error) {
      const errorMsg = error.status === 403 ? messages.forbidden : messages.delete.errorDelete;

      console.error(error);

      _snackbar.current.show('error', errorMsg);

      setPublishSubmitting(false);
      setShowDeleteModal(false);
      setRacesToDelete([]);
    }
  };

  const handleCloseExport = () => {
    setRacesToExport([]);
    setShowExportModal(false);
  };

  const handleShowExport = (selected) => {
    setRacesToExport(selected);
    setShowExportModal(true);
  };

  // Utility method for generating a timestamp for use when naming files for exports
  const getTimestamp = () => {
    const dateObj = new Date();
    const year = dateObj.getUTCFullYear().toString().substr(-2);
    const month = `0${dateObj.getUTCMonth() + 1}`.slice(-2);
    const day = `0${dateObj.getUTCDate()}`.slice(-2);
    const hours = `0${dateObj.getUTCHours()}`.slice(-2);
    const mins = `0${dateObj.getUTCMinutes()}`.slice(-2);
    const secs = `0${dateObj.getUTCSeconds()}`.slice(-2);
    return `${year}${month}${day}${hours}${mins}${secs}`;
  };

  const getExportFilenamePrefix = (suffix) => {
    // Replace spaces in guide name with dashes and convert to lower case
    const convertedGuideName = guideName.replace(/\s+/g, '-').toLowerCase();
    return `${getTimestamp()}-${convertedGuideName}${suffix}`;
  };

  const handleExport = async (exportType) => {
    let apiEndpoint = '';
    let filenameSuffix = '';

    switch (exportType) {
      case 'txt':
        apiEndpoint = `/api/v1/guide/${guideId}/races/export/txt`;
        filenameSuffix = '-races.txt';
        break;
      case 'csv':
        apiEndpoint = `/api/v1/guide/${guideId}/races/export/csv`;
        filenameSuffix = '-races.csv';
        break;
      case 'xml':
        apiEndpoint = `/api/v1/guide/${guideId}/races/export/xml`;
        filenameSuffix = '-races.xml';
        break;
      case 'html':
        apiEndpoint = `/api/v1/guide/${guideId}/races/export/html`;
        filenameSuffix = '-races.html';
        break;
      case 'images':
        apiEndpoint = `/api/v1/guide/${guideId}/races/export/images`;
        filenameSuffix = '-images.zip';
        break;
      default:
        // Unrecognized export type - bail out
        console.log(`${exportType} is not a supported export type`);
        handleCloseExport();
        return;
    }

    setExportSubmitting(true);

    try {
      const blob = await fetchUtil(apiEndpoint, 'POST', { ballotItemIds: racesToExport }, true);
      GaUtils.downloadFile(blob, getExportFilenamePrefix(filenameSuffix));
      handleCloseExport();
      _snackbar.current.show('success', 'Races successfully exported');
      setExportSubmitting(false);
    } catch (error) {
      console.error(error);

      setExportSubmitting(false);

      if (error && error.data) {
        _snackbar.current.show('error', error.data);
        return;
      }

      _snackbar.current.show('error', 'There was an issue exporting the selected races. Try again and contact a guide admin if this continues.');
    }
  };

  const handleShowBulkEdit = (selected) => {
    setOpenBulkEdit(true);
    setBulkEditRaces(selected);
  };

  const handleCloseBulkEdit = () => {
    setOpenBulkEdit(false);
    setBulkEditRaces([]);
  };

  const handleSaveBulkEdit = (selectedRaces, updatedData) => {
    // saving updates to all selected races
    // if one of these values is undefined, '', or null, that field should not be updated

    setBulkEditSubmitting(true);

    const selectedRaceIds = selectedRaces.map(race => race.id);
    const bioQuestionSet = updatedData.bioQuestionSet === -1 ? null : updatedData.bioQuestionSet;
    const clearBioQuestionSet = bioQuestionSet === null;
    const clearCategory = updatedData.category?.id === -1;
    const description = updatedData.description?.length > 0 ? updatedData.description : null;
    let choose = null;
    let votingSystem = null;

    if (updatedData.votingSystem) {
      votingSystem = updatedData.votingSystem;
      choose = updatedData.choose;
    }

    const updatedRaces = {
      clearBioQuestionSet: clearBioQuestionSet || null,
      clearCategory: clearCategory || null,
      race: {
        bioQuestionSet: bioQuestionSet || null,
        category: updatedData.category ? updatedData.category : null,
        choose: choose || null,
        description: description || null,
        district: updatedData.district ? updatedData.district : null,
        questionSet: updatedData.questionSet ? updatedData.questionSet : null,
        state: updatedData.state ? updatedData.state : null,
        votingSystem: votingSystem || null,
      },
      raceIds: selectedRaceIds,
    };

    fetchUtil(`/api/v1/guide/${guideId}/races/bulkedit`, 'PATCH', updatedRaces)
      .then(() => {
        // right now, table columns are hard-coded + category is the only one we care to update here
        // in the future, we will want this to search through the column list + the updated values
        if (updatedRaces.race.category) {
          const racesCopy = [];
          localRaces.forEach(race => {
            if (updatedRaces.raceIds.includes(race.id)) {
              if (updatedRaces.race.category.id === -1) {
                // set empty string for uncategorized
                race.category = {
                  ...updatedRaces.race.category,
                  title: '',
                };
              } else {
                race.category = updatedRaces.race.category;
              }
            }
            racesCopy.push(race);
          });

          setLocalRaces(racesCopy);
        }

        _snackbar.current.show('success', messages.bulkEdit.success);

        setBulkEditSubmitting(false);
        setOpenBulkEdit(false);
        setTableSelectedRaces([]);
        setBulkEditRaces([]);
      })
      .catch(error => {
        console.error(error);

        setBulkEditSubmitting(false);
        setBulkEditErrors(error);

        _snackbar.current.show('error', messages.bulkEdit.error);
      });
  };

  const handleSetDistrictsFromRaceName = (raceIds) => {
    setRacesForDistrictSetting(raceIds);
    handleOpenSetDistrictModal();
  };

  const handleCloseSetDistrictModal = () => {
    setShowDistrictModal(false);
    setStatusSetDistrict(false);
    setRacesForDistrictSetting([]); // clear ids from state
  };

  const handleOpenSetDistrictModal = () => {
    setShowDistrictModal(true);
  };

  const handleSaveSetDistrictModal = async (districtSet) => {
    setSubmittingSetDistrict(true);

    let BODY = {};
    if (districtSet.id == 'SUH' || districtSet.id == 'USH' || districtSet.id == 'SLH') {
      BODY = {
        raceIds: racesForDistrictSetting,
        districtType: districtSet.discriminator,
        state: districtSet.state,
      };
    } else {
      BODY = {
        raceIds: racesForDistrictSetting,
        districtSetId: districtSet.id,
      };
    }
    try {
      const failedRaces = await fetchUtil(`/api/v1/guide/${guideId}/races/redistrictOnName`, 'POST', BODY);

      setStatusSetDistrict(true);
      setFailedSetDistricts(failedRaces);
      setSubmittingSetDistrict(false);
    } catch (e) {
      setSubmittingSetDistrict(false);
      console.error(e);
      _snackbar.current.show('error', messages.setDistrict.error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <SnackbarAlert ref={_snackbar} />

      <BulkPublishResults
        actionIsPublish={publish}
        onClose={handleClosePublishResults}
        races={publishExceptions}
        show={showPublishResults}
      />

      <ConfirmationDialog
        cancelButtonText={publish ? messages.publish.cancelBtn : messages.unpublish.cancelBtn}
        confirmButtonText={publish ? messages.publish.confirm : messages.unpublish.confirm}
        heading={publish ? messages.publish.heading : messages.unpublish.heading}
        onCancel={() => setOpenPublishDialog(false)}
        onConfirm={handleSavePublish}
        open={openPublishDialog}
      >
        <Typography component="p" variant="body1">
          {publish ? messages.publish.body : messages.unpublish.body}
        </Typography>
      </ConfirmationDialog>

      <ConfirmationDialog
        cancelButtonText={messages.delete.cancelBtn}
        confirmButtonText={messages.delete.confirm}
        heading={messages.delete.heading}
        onCancel={handleCloseDelete}
        onConfirm={handleDelete}
        open={showDeleteModal}
      >
        <Typography component="p" variant="body1">
          {messages.delete.body}
        </Typography>
      </ConfirmationDialog>

      <RaceExportModal
        cancelButtonText={messages.export.cancelBtn}
        confirmButtonText={messages.export.confirm}
        disabled={exportSubmitting}
        heading={messages.export.heading}
        onCancel={handleCloseExport}
        onConfirm={handleExport}
        show={showExportModal}
      />

      <SetDistrictFromNameModal
        onClose={handleCloseSetDistrictModal}
        onSave={handleSaveSetDistrictModal}
        modalIsOpen={showSetDistrictModal}
        selectedRaces={racesForDistrictSetting}
        submitting={submittingSetDistrict}
        status={statusSetDistrict}
        failedRaces={failedSetDistricts}
        messages={messages.setDistrict}
      />

      {openBulkEdit && (
        <BulkEditModal
          bioSets={bioSets}
          categories={categoriesWithUncategorized}
          errors={bulkEditErrors}
          guideId={guideId}
          modalIsOpen={openBulkEdit}
          selectedRaces={bulkEditRaces}
          questionSets={questionSets}
          onClose={handleCloseBulkEdit}
          onSave={handleSaveBulkEdit}
          submitting={bulkEditSubmitting}
        />
      )}

      <PageHeader
        heading={messages.heading}
        subheading={messages.subheading}
      />

      <PageActionsHeader>
        <Button
          className="ga-ml-8"
          color="primary"
          onClick={() => goToLink(navigation.bulkImportPage)}
          size="small"
          variant="text"
        >
          {messages.bulkImport}
        </Button>

        <Button
          className="ga-ml-8"
          color="secondary"
          onClick={() => goToLink(navigation.createRace)}
          size="small"
          variant="contained"
        >
          {messages.createRace}
        </Button>

        <Button
          className="ga-ml-8"
          color="secondary"
          onClick={() => goToLink(navigation.createMeasure)}
          size="small"
          variant="contained"
        >
          {messages.createMeasure}
        </Button>
      </PageActionsHeader>

      <div className="ga-container">
        <div className="mdc-layout-grid">
          <div className="mdc-layout-grid__inner">
            <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-3">
              <Typography component="h2" variant="h3">
                Race Setup Menu
              </Typography>

              <hr />

              <Typography component="p" variant="body2" color="textSecondary">
                Get started filling in the race setup items below before you create a race!
              </Typography>

              <RaceDashboardNavigation links={navigation} />
            </div>

            {/* eventually bring back kpis and remove the added column below */}
            {/* <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-9">
              <Typography component="h2" variant="h3">At a Glance</Typography>
              <KPICard {...mockKpiRaceArray} />
              <KPICard {...mockKpiSetupArray} />
            </div> */}

            <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-6">
              <Typography component="h2" variant="h3" paragraph>
                Getting Started
              </Typography>

              <Typography variant="body1" component="p" paragraph>
                {helpfulHintText1}
              </Typography>

              <Typography variant="body1" component="p" paragraph>
                {helpfulHintText2}
              </Typography>

              <HelpfulHint>
                {helpfulHintText3}
              </HelpfulHint>
            </div>

            <RaceTable
              filterModel={tableFilterModel}
              messages={messages}
              navigation={navigation}
              onBulkEdit={(selectedRaces) => handleShowBulkEdit(selectedRaces)}
              onDelete={(selectedRaces) => handleShowDelete(selectedRaces)}
              onExport={(selectedRaces) => handleShowExport(selectedRaces)}
              onFilterModelChange={(model) => setTableFilterModel(model)}
              onPublish={(isPublish, selectedRaces) => handleShowPublish(isPublish, selectedRaces)}
              onSetDistrictsFromRaceName={(selectedRaces) => handleSetDistrictsFromRaceName(selectedRaces)}
              onSortModelChange={(model) => setTableSortModel(model)}
              publishSubmitting={publishSubmitting}
              races={localRaces}
              sortModel={tableSortModel}
              selectedRaces={tableSelectedRaces}
              onSelectionModelChange={(selectedRaces) => setTableSelectedRaces(selectedRaces)}
              hiddenColumns={hiddenColumns}
              onColumnVisibilityChange={setHiddenColumns}
            />
          </div>
        </div>
      </div>

      <RacePublishingCta
        messagesChecklist={messages.raceChecklist}
        messagesHowTo={messages.howTo}
      />

    </ThemeProvider>
  );
};

RaceDashboardContainer.propTypes = {
  bioSets: PropTypes.array,
  categories: PropTypes.array,
  guideId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  guideName: PropTypes.string,
  helpfulHintText1: PropTypes.string,
  helpfulHintText2: PropTypes.string,
  helpfulHintText3: PropTypes.string,
  messages: PropTypes.object.isRequired,
  navigation: PropTypes.shape({
    parties: PropTypes.string.isRequired,
    categories: PropTypes.string.isRequired,
    districts: PropTypes.string.isRequired,
    bioQuestions: PropTypes.string.isRequired,
    raceQuestions: PropTypes.string.isRequired,
    createRace: PropTypes.string.isRequired,
    bulkImportPage: PropTypes.string.isRequired,
    createMeasure: PropTypes.string.isRequired,
  }).isRequired,
  questionSets: PropTypes.array,
  races: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ),
  showSuccessMsgFrom: PropTypes.string,
};

RaceDashboardContainer.defaultProps = {
  bioSets: [],
  categories: [],
  guideId: null,
  guideName: '',
  helpfulHintText1: 'It\'s easiest if before you create a race, get started by adding parties, categories, districts, biographical questions, and race questions to your guide. When you go to create a race everything you have created will be available for you to use.',
  helpfulHintText2: 'If you need to get your races created first, that\'s okay too! You can always add what you need back in later.',
  helpfulHintText3: 'If you have more than 25 races, using bulk import can save you a lot of time! You just need to know the race name and at least 1 candidate per race.',
  language: {
    title: 'Languages',
    subtitle: 'Create your voter guide in multiple languages! Set the languages you want, then use "Race Questions" to create the questions in all languages.',
    showIcon: false,
    kpis: [[{ value: 3, title: 'Total' }]],
    buttons: [{ title: 'Add More Languages', action: '/settings/languages' }],
    moretext: 'English, Spanish, French',
  },
  messages: {},
  questionSets: [],
  races: [],
  showSuccessMsgFrom: '',
};

export default RaceDashboardContainer;
