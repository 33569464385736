import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/styles';
import NumberedStepper from 'components/NumberedStepper';
import step1Image from 'images/Step1.png';
import step2Image from 'images/Step2.png';
import step3Image from 'images/Step3.png';

const styles = () => ({
  actionButton: {
    marginLeft: '1rem',
  },
  image: {
    width: '100%',
  },
  modalTitle: {
    alignItems: 'flex-start',
  },
  modalActions: {
    justifyContent: 'end',
  },
});

class HowToPublishModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };
  }

  render() {
    const {
      onClose,
      messages,
      modalIsOpen,
      classes,
    } = this.props;

    return (
      <Dialog
        aria-labelledby="how-to-publish-modal"
        maxWidth="md"
        onClose={onClose}
        open={modalIsOpen}
      >
        <DialogTitle
          className={classes.modalTitle}
          disableTypography
          id="how-to-publish-modal-title"
          onClose={onClose}
        >
          <span>
            <Typography component="h1" variant="h1">
              {messages.title}
            </Typography>
            <Typography component="p" variant="body1">
              {messages.subtitle}
            </Typography>
          </span>
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <NumberedStepper
            steps={[
              {
                title: messages.step1,
                children: (
                  <div>
                    <img src={step1Image} className={classes.image} />
                  </div>
                ),
              },
              {
                title: messages.step2,
                children: (
                  <div>
                    <img src={step2Image} className={classes.image} />
                  </div>
                ),
              },
              {
                title: messages.step3,
                children: (
                  <div>
                    <img src={step3Image} className={classes.image} />
                  </div>
                ),
              },
            ]}
          />
        </DialogContent>

        <DialogActions className={classes.modalActions}>
          <Button
            className={classes.actionButton}
            color="secondary"
            onClick={onClose}
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

HowToPublishModal.propTypes = {
  classes: PropTypes.object,
  modalIsOpen: PropTypes.bool.isRequired,
  messages: PropTypes.object,
  onClose: PropTypes.func,
};

HowToPublishModal.defaultProps = {
  onClose: () => {},
  messages: {},
};

export default withStyles(styles)(HowToPublishModal);
