import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette }) => ({
  headingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: '47px', // Prevents the container from jumping around when bulk actions show/hide
  },
  actionButton: {
    margin: '8px 20px',
    minWidth: '50px',
    textTransform: 'none',
  },
  dangerButton: {
    color: '#ac1b3d',
    '&:hover': {
      color: '#6B1126',
    },
  },
  tableContainer: {
    '& a': {
      color: palette.primary.main,
      fontWeight: 500,
      textDecoration: 'none',
    },
  },
  table: {
    border: 'none',
  },
  emptyStateButton: {
    margin: '0 .8rem',
  },
  unfinished: {
    display: 'none',
  },
}));

export { useStyles };
