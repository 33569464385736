import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Box,
} from '@material-ui/core';
import Interweave from 'interweave';
import { useNavigate } from 'react-router-dom';
import HelpfulHint from 'components/HelpfulHint';
import QuestionTextField from 'components/QuestionAnswerFields/QuestionTextField';
import QuestionRadioOptions from 'components/QuestionAnswerFields/QuestionRadioOptions';
import QuestionYouTubeField from 'components/QuestionAnswerFields/QuestionYouTubeField';
import Stepper from 'components/RaceSteps/Stepper';
import StepTracker from 'components/RaceSteps/StepTracker';
import QuestionClass from 'helpers/QuestionClass';
import EmptyState from 'components/EmptyState';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    color: '#757575',
    marginBottom: '0.625rem',
  },
  subtitle: {
    [theme.breakpoints.up('md')]: {
      width: '75%',
    },
  },
  question: {
    marginBottom: '2.5rem',
  },
  container: {
    position: 'relative',
  },
}));

const CandidateQuestionList = ({
  languages,
  messages,
  onChange,
  submitting,
  questions,
  questionTypeOptions,
  resetState,
  saveChanges,
  updatePage,
  defaultAnswerLength,
}) => {
  const navigator = useNavigate();
  const classes = useStyles();

  const onBack = () => {
    updatePage(2);
    navigator('/profile');
    window.scrollTo(0, 0);
  };

  const onSkip = () => {
    resetState();
    updatePage(4);
    navigator('/review');
    window.scrollTo(0, 0);
  };

  const onSaveContinue = async () => {
    await saveChanges(() => {
      updatePage(4);
      navigator('/review');
      window.scrollTo(0, 0);
    });
  };

  const renderQuestionField = (question) => {
    switch (question.type) {
      default:
        return 'Question type not correctly selected';
      case 1:
        return (
          <div className={classes.question}>
            <QuestionTextField
              answer={question.answer}
              id={question.id}
              errors={question.errors}
              languages={languages}
              translatedQuestions={question.translatedQuestions}
              translatedAnswers={question.translatedAnswers}
              label={question.question}
              maxl={question.maxl || defaultAnswerLength}
              onChange={onChange}
            />
          </div>
        );
      case 2:
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
        return (
          <div className={classes.question}>
            <QuestionRadioOptions
              id={question.id}
              value={question.answer}
              onChange={onChange}
              type={question.type}
              label={question.question}
              options={questionTypeOptions}
            />
          </div>
        );
      case 4:
        return (
          <div className={classes.question}>
            <QuestionYouTubeField
              answerURL={question.answerURL}
              answerText={question.answerText}
              errors={question.errors}
              id={question.id}
              onChange={onChange}
              submitting={submitting}
              question={question.question}
            />
          </div>
        );
    }
  };

  return (
    <div className={classes.container}>
      <StepTracker currentStep={3} totalSteps={4} top="1rem" messages={{
        step: 'Step',
        of: 'of',
      }} />
      <Typography variant="h1" component="h1" color="textSecondary" paragraph>
        {messages.stepHeader}
      </Typography>
      <Typography variant="body1" component="p" paragraph className={classes.subtitle}>
        {messages.stepSubheader}
      </Typography>
      <Box marginBottom={3}>
        <Grid container spacing={10}>
          <Grid item xs={12} sm={12} md={7}>
            {questions.map(question => (
              <div key={question.id}>
                {renderQuestionField(question)}
              </div>
            ))}
            {questions.length == 0 && (
              <Box marginBottom={2}>
                <EmptyState>
                  There are no questions at this time.
                </EmptyState>
              </Box>
            )}
            <Interweave content={messages.biasText} />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <HelpfulHint>
              {messages.questionHelpText}
            </HelpfulHint>
          </Grid>
        </Grid>
      </Box>
      <Stepper
        messages={messages.stepper}
        visibility={['back', 'skip', 'saveContinue']}
        onBack={onBack}
        onSkip={onSkip}
        onSaveContinue={onSaveContinue}
      />
    </div>
  );
};

CandidateQuestionList.propTypes = {
  messages: PropTypes.object.isRequired,
  languages: PropTypes.array,
  onChange: PropTypes.func,
  submitting: PropTypes.bool,
  questions: PropTypes.arrayOf(PropTypes.instanceOf(QuestionClass)),
  questionTypeOptions: PropTypes.array,
  resetState: PropTypes.func,
  saveChanges: PropTypes.func,
  updatePage: PropTypes.func.isRequired,
  defaultAnswerLength: PropTypes.number,
};

CandidateQuestionList.defaultProps = {
  languages: [],
  onChange: () => {},
  submitting: false,
  questions: [],
  questionTypeOptions: [],
  resetState: () => {},
  saveChanges: () => {},
  defaultAnswerLength: 1000,
};

export default CandidateQuestionList;
