import propTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';

import { convertStringToTitleCase } from 'helpers/ConvertStringToTitleCase';
import { apiClient } from '@/lib/axios';
import { FETCH_TYPE_MATCHED, FETCH_TYPE_UNMATCHED } from '@/features/campaign-finance';

const ServerColumnMapping = {
  candidateName: 'candidate_name',
  candidateId: 'candidate_id',
  raceId: 'race_id',
  raceName: 'race_name',
  dataSource: 'data_source',
  party: 'party',
  sourceURL: 'source_url',
};

/**
 * Processes the unmatched campaign finance records and returns an array of processed records.
 *
 * @param {Object} data - The unmatched campaign finance records.
 * @returns {Array} - An array of processed records.
 */
const processData = (data) => {
  // Check if data and data.data exist and are arrays
  if (!data || !Array.isArray(data.data)) {
    return [];
  }

  // Process each campaign finance records
  return data.data.map((cfRecord, index) => {
    // Ensure campaign finance record is an object, if its not exclude it from the list
    if (!cfRecord || typeof cfRecord !== 'object') {
      return null;
    }

    return {
      ...cfRecord,
      id: typeof cfRecord.id === 'number' ? cfRecord.id : 0,
      index,
      candidateName: typeof cfRecord.candidateName === 'string' ? cfRecord.candidateName : '',
      candidateId: typeof cfRecord.candidateId === 'string' ? cfRecord.candidateId : '',
      raceId: typeof cfRecord.raceId === 'string' ? cfRecord.raceId : '',
      raceName: typeof cfRecord.raceName === 'string' ? cfRecord.raceName : '',
      party: typeof cfRecord.party === 'string' ? cfRecord.party : '',
      sourceURL: typeof cfRecord.sourceURL === 'string' ? cfRecord.sourceURL : '',
      dataSource: typeof cfRecord.dataSource === 'string' ? convertStringToTitleCase(cfRecord.dataSource) : '',
    };
  });
};

export const getCampaignFinanceRecords = (matchType, {
  page,
  pageSize,
  search,
  sortModel,
}) => {
  const params = {
    limit: pageSize,
    offset: page * pageSize,
    ...(search && { search }),
    sort: ServerColumnMapping[sortModel[0]?.field] || 'candidate_name',
    order: sortModel[0]?.sort || 'asc',
  };

  const getMatched = matchType ? matchType === FETCH_TYPE_MATCHED : null;

  const queryParams = new URLSearchParams({
    ...(getMatched !== null && { matched: getMatched }),
    limit: (params.limit || 5000).toString(),
    offset: (params.offset || 0).toString(),
    ...(params.sort && { sort: params.sort }),
    ...(params.order && { order: params.order }),
    ...(params.search && { search: params.search || '' }),
    ...params,
  });

  return apiClient
    .get(`campaign-finances?${queryParams.toString()}`)
    .then((response) => ({
      rows: processData(response.data),
      totalRows: response.data.meta?.totalRows || 0,
    }));
};

export const useCampaignFinanceRecords = ({
  dataGridParams,
  queryKeyString,
  type,
}) => {
  const {
    page,
    pageSize,
    sortModel,
  } = dataGridParams;

  return useQuery({
    queryKey: [
      queryKeyString,
      page,
      pageSize,
      sortModel,
    ],
    queryFn: () => getCampaignFinanceRecords(type, dataGridParams),
    keepPreviousData: true,
    enabled: page !== undefined && pageSize !== undefined && !!sortModel,
  });
};

useCampaignFinanceRecords.propTypes = {
  type: propTypes.oneOf([FETCH_TYPE_MATCHED, FETCH_TYPE_UNMATCHED]),
  dataGridParams: propTypes.shape({
    page: propTypes.number,
    pageSize: propTypes.number,
    sortModel: propTypes.arrayOf(
      propTypes.shape({
        field: propTypes.string,
        sort: propTypes.oneOf(['asc', 'desc']),
      }),
    ),
  }),
};
