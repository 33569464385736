import GaValidations from 'helpers/GaValidations';
import GaUtils from 'helpers/GaUtils';

/**
 * Hook for front end validation on the race step-by-step. Called from any and all of the stepper buttons.
 * @param {Object} useStepState State of the step-by-step
 * @param {Object} msgs Validation strings from messages.en
 */
const useValidation = (useStepState, msgs) => {
  const messages = msgs.validation;

  // ================================================================================
  // skipValidations
  // ================================================================================
  const skipValidations = (step, MAX_STEP) => {
    const stepSetUp = () => true;
    const stepCategory = () => true;
    const stepDescription = () => true;
    const stepCandidates = (state) => {
      state.setCandidateList([]);
      return true;
    };
    const stepBioQuestions = () => true;
    const stepRaceQuestions = () => true;

    // --------------------------------------
    // Execute validations
    // --------------------------------------
    let result = true;
    if (step == 1) {
      result = stepSetUp(useStepState.stepSetUpState);
    } else if (step == 2) {
      result = stepCategory(useStepState.stepCategoryState);
    } else if (step == 3) {
      result = stepDescription(useStepState.stepDescriptionState);
    } else if (step == 4) {
      result = stepCandidates(useStepState.stepCandidatesState);
    } else if (step == 5) {
      result = stepBioQuestions(useStepState.stepBioQuestionsState);
    } else if (step == 6) {
      result = stepRaceQuestions(useStepState.stepRaceQuestionsState);
    }

    if (result) {
      result = step < MAX_STEP;
    }
    return result;
  };

  // ================================================================================
  // backValidations
  // ================================================================================
  const backValidations = (step, MIN_STEP) => {
    const result = step > MIN_STEP;
    return result;
  };

  // ================================================================================
  // continueValidations
  // == Called on continue button press. The function corresponding to the current
  // == step is used to validate user inputs.
  // ================================================================================
  const continueValidations = (step) => {
    // ********************************************************
    const stepSetUp = (state) => {
      let result = true;
      const errors = {};
      let message = '';

      // Type of race must be one of the 3 options (not sure how it wouldn't be, but let's cover all our bases)
      if (state.typeOfRace < 0 || state.typeOfRace > 2) {
        result = false;
        errors.raceType = [messages.raceType.error];
        message = messages.raceType.message;
      }

      // Same thing with voting option
      if (state.votingOption < 0 || state.votingOption > 2) {
        result = false;
        errors.votingOption = [messages.votingOption.error];
        message = messages.votingOption.message;
      } else if (state.votingOption == 1 || state.votingOption == 2) {
        // Number of candidates must be a number! Who would've guessed based on the name!
        // It also must be between 1 and 100
        if (Number.isNaN(state.numberOfCandidates)) {
          result = false;
          errors.numberOfCandidates = [messages.numberOfCandidates.number];
        } else if (state.numberOfCandidates <= 1) {
          result = false;
          errors.numberOfCandidates = [
            messages.numberOfCandidates.greaterThanOne,
          ];
        } else if (state.numberOfCandidates > 100) {
          result = false;
          errors.numberOfCandidates = [
            messages.numberOfCandidates.lessThan100,
          ];
        }
      }

      // District is required
      if (state.district == null || typeof state.district == 'undefined' || Object.keys(state.district).length == 0) {
        result = false;
        message = messages.district.required;
        errors.district = [
          messages.district.required,
        ];
      }

      // race name is handled on back end since it is sumbitted right away

      // set error state variables where applicable
      if (errors.numberOfCandidates) {
        state.setVotingOptionsErrors(errors);
      }

      if (errors.district) {
        state.setDistrictErrors(errors);
      }

      return { success: result, error: errors, message };
    };

    // ********************************************************
    const stepCategory = (state) => {
      let result = true;
      const errors = {};
      let message = '';

      // user MUST select a category
      // and it also must be one in the list
      if (
        typeof state.selectedCategory === 'undefined'
        || state.selectedCategory == null
      ) {
        result = false;
        errors.selectedCategory = [messages.selectedCategory.mandatory];
        message = messages.selectedCategory.mandatory;
      } else if (
        state.selectedCategory < 0
        || state.selectedCategory > state.categories.length - 1
      ) {
        result = false;
        errors.selectedCategory = [messages.selectedCategory.error];
        message = messages.selectedCategory.message;
      }

      return { success: result, error: errors, message };
    };

    // ********************************************************
    // No validation needed, only one field and they can do whatever they want with it
    const stepDescription = () => ({ success: true, error: {}, message: '' });

    // ********************************************************
    const stepCandidates = (state) => {
      let result = true;
      const errorCandList = state.errorsCandidateList;
      state.setCheckValidations(true);
      if (GaValidations.hasErrors(errorCandList)) {
        result = false;
      }
      state.candidateList.forEach(c => {
        if (c.parties.length == 0) {
          result = false;
        }
      });
      // if all the error'd candidates are completely empty, allow for continue
      let allAreEmpty = true;
      state.candidateList.forEach(c => {
        const errors = errorCandList.find(e => e.id === c.id);
        if (typeof errors !== 'undefined') {
          // we have an error for this candidate
          if (c.name != '' || c.lastName != '' || c.contactUser.name != '' || c.contactUser.email != '' || c.parties.length != 0) {
            allAreEmpty = false;
          }
        }
      });
      return {
        // if result is true, then we are good. If result is false, then we need to check that all candidates are empty.
        success: result || allAreEmpty,
        error: {},
        message: msgs.stepCandidates.continueBtnError,
      };
    };
    // ********************************************************
    const stepBioQuestions = (state) => {
      let result = true;
      const errors = {};
      let message = '';

      // The user must select one of the three options for bio sets (existing, new, all)
      if (state.bioDecision < 0 || state.bioDecision > 2) {
        result = false;
        errors.bioDecision = [messages.bioDecision.error];
        message = messages.bioDecision.message;
      }

      // use existing
      if (state.bioDecision == 0) {
        // must make a selection from the dropdown
        // that selection must be a valid choice from the options
        if (state.bioSetChoice == null) {
          result = false;
          errors.selectedSet = [messages.existingBio.required];
        } else if (
          !GaUtils.checkItemInList(state.existingSets, state.bioSetChoice, 'id')
        ) {
          result = false;
          errors.selectedSet = [messages.existingBio.invalid];
        }
      }

      // create new
      if (state.bioDecision == 1) {
        // title is required, and max chars is 60
        if (
          typeof state.newSetTitle === 'undefined'
          || state.newSetTitle == null
          || state.newSetTitle == ''
        ) {
          result = false;
          errors.newSetTitle = [messages.newBio.required];
        } else if (state.newSetTitle.length > 60) {
          result = false;
          errors.newSetTitle = [messages.newBio.maxLength];
        }
      }

      if (errors.selectedSet) {
        state.setBioSetChoiceErrors(errors);
      }

      if (errors.newSetTitle) {
        state.setNewSetTitleErrors(errors);
      }

      return { success: result, error: errors, message };
    };
    // ********************************************************
    const stepRaceQuestions = (state) => {
      let result = true;
      const errors = {};
      let message = '';

      // must select one of the two initial options
      if (state.raceQuestionDecision < 0 || state.raceQuestionDecision > 1) {
        result = false;
        errors.raceDecision = [messages.raceDecision.error];
        message = messages.raceDecision.message;
      }

      // use existing
      if (state.raceQuestionDecision == 0) {
        // must select a race q set and it must be a valid choice
        if (state.selectedRQS === null) {
          result = false;
          errors.set = [messages.existingRace.required];
          message = messages.raceDecision.message;
        } else if (!GaUtils.checkItemInList(state.existingRQS, state.selectedRQS, 'id')) {
          result = false;
          errors.set = [messages.existingRace.invalid];
        }
      }

      // create new
      if (state.raceQuestionDecision == 1) {
        if (typeof state.newRQSTitle == 'undefined' || state.newRQSTitle == null || state.newRQSTitle == '') {
          result = false;
          errors.newSetTitle = [messages.newRace.required];
        } else if (state.newRQSTitle.length > 60) {
          result = false;
          errors.newSetTitle = [messages.newRace.maxLength];
        }
      }

      if (errors.set) {
        state.setSelectedRQSErrors(errors);
      }

      if (errors.newSetTitle) {
        state.setNewRQSTitleErrors(errors);
      }

      return { success: result, error: errors, message };
    };

    // --------------------------------------
    // Execute validations
    // --------------------------------------
    let resultStep = {};
    if (step == 1) {
      resultStep = stepSetUp(useStepState.stepSetUpState);
    } else if (step == 2) {
      resultStep = stepCategory(useStepState.stepCategoryState);
    } else if (step == 3) {
      resultStep = stepDescription(useStepState.stepDescriptionState);
    } else if (step == 4) {
      resultStep = stepCandidates(useStepState.stepCandidatesState);
    } else if (step == 5) {
      resultStep = stepBioQuestions(useStepState.stepBioQuestionsState);
    } else if (step == 6) {
      resultStep = stepRaceQuestions(useStepState.stepRaceQuestionsState);
    }

    return resultStep;
  };

  /**
   * Validate a race question being added to a new race question set.
   * @param {*} formattedQuestion
   * @param {*} TYPE_OPTIONS
   * @returns { success: bool, error: {} }
   */
  const raceQuestionValidations = (formattedQuestion, TYPE_OPTIONS) => {
    let result = true;
    const errors = {};

    const matchedType = TYPE_OPTIONS.find(t => t.id === formattedQuestion.type);

    // Type is required, and it must match a defined type.
    // Max question length is also required for text questions.
    if (formattedQuestion.type == '') {
      result = false;
      errors.type = [messages.newRaceQuestion.required];
    } else if (typeof matchedType == 'undefined') {
      result = false;
      errors.type = [messages.newRaceQuestion.invalid];
    } else if (matchedType.defaultMaxLength && (formattedQuestion.maxQuestionLength == '' || formattedQuestion.maxQuestionLength <= 0)) {
      result = false;
      errors.maxQuestionLength = [messages.newRaceQuestion.maxLength];
    } else if (formattedQuestion.body == '') {
      // The question must have an english body at the very least
      result = false;
      errors.body = [messages.newRaceQuestion.languageRequired];
    }

    return { success: result, error: errors };
  };

  return {
    skipValidations,
    backValidations,
    continueValidations,
    raceQuestionValidations,
  };
};

export default useValidation;
