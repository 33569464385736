import React from 'react';
import PropTypes from 'prop-types';
import GisHelper from 'helpers/GisHelper';

class DistrictMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      prevDistricts: [],
    };

    this._ref = React.createRef();
  }

  componentDidMount() {
    const { districts } = this.props;

    this.renderMap();
    this.setState({
      prevDistricts: JSON.parse(JSON.stringify(districts)),
    });
  }

  componentDidUpdate() {
    this.renderMap();
  }

  shouldComponentUpdate(nextProps) {
    if (this.shouldMapRerender(nextProps)) {
      // Store the districts in state so we only rerender the map on
      // important changes to districts (i.e. add/remove or changes to selected
      // state vs. input changes for district names)
      this.setState({
        prevDistricts: JSON.parse(JSON.stringify(nextProps.districts)),
      });

      return true;
    }

    return false;
  }

  // Because rerendering the map is an expensive operation, we want to restrict rendering
  // only when it's necessary. In this case, only when districts are added/removed or
  // they are selected/deselected from the GIS district set.
  shouldMapRerender(nextProps) {
    const { prevDistricts } = this.state;

    if ((nextProps.districts && !prevDistricts) || (!nextProps.districts && prevDistricts) || nextProps.districts.length !== prevDistricts.length) {
      return true;
    }

    const previousSelected = prevDistricts.map(district => district.selected);
    const newSelected = nextProps.districts.map(newDist => newDist.selected);
    const equals = JSON.stringify(previousSelected) === JSON.stringify(newSelected);

    if (!equals) {
      return true;
    }

    return false;
  }

  renderMap() {
    const {
      districts,
      onDistrictClick,
      toggleableDistricts,
    } = this.props;

    if (districts && districts.length > 0) {
      // Create our google map
      const map = new window.google.maps.Map(this._ref.current, {
        disableDefaultUI: true,
        zoom: 8,
      });
      const polygons = [];

      // Create polygons for each of our districts
      districts.forEach((district, index) => {
        const features = district.geometry;
        features.forEach(f => {
          const poly = new window.google.maps.Polygon({
            paths: f,
            strokeColor: '#000000',
            strokeOpacity: 1,
            strokeWeight: 1,
            fillColor: GisHelper.getPolyColor(index), // Randomizes color based on index
            fillOpacity: 0.5,
          });

          polygons.push(poly);

          // If district toggling is enabled, we only add polys to the map if the district is
          // selected, but we keep it in the polygon array so we can determine the map bounds,
          // even if no districts are visible at the moment
          if (!toggleableDistricts || (toggleableDistricts && district.selected)) {
            // Add polygon to the map and set up our click listener
            poly.setMap(map);
            window.google.maps.event.addListener(poly, 'click', () => onDistrictClick(district.id));
          }
        });
      });

      // Determine the boundaries and center point for our map based off
      // the district polygons we just generated
      const bounds = GisHelper.getUnionBounds(polygons);
      map.setCenter(bounds.getCenter());
      map.fitBounds(bounds);
    }
  }

  render() {
    const {
      className,
      height,
    } = this.props;

    return (
      <div className={className}>
        <div ref={this._ref} id="map" style={{ height: `${height}px` }} />
      </div>
    );
  }
}

DistrictMap.propTypes = {
  className: PropTypes.string,
  districts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
    geometry: PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.arrayOf(PropTypes.shape({
          lat: PropTypes.number,
          lng: PropTypes.number,
        })),
      ),
    ),
  })),
  height: PropTypes.number,
  onDistrictClick: PropTypes.func,
  toggleableDistricts: PropTypes.bool,
};

DistrictMap.defaultProps = {
  className: '',
  districts: [],
  height: 500,
  onDistrictClick: () => {},
  toggleableDistricts: false,
};

export default DistrictMap;
