import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  backgroundContainer: {
    backgroundColor: '#e0e5e8',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 0',
  },
  grid: {
    padding: '16px 24px',
    width: '100%',
  },
  label: {
    color: '#86898b',
    fontSize: '0.625rem',
    textTransform: 'uppercase',
    padding: '0px 24px',
  },
  childContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > *': {
      marginLeft: '1rem',
    },
  },
});

export default function PageActionsHeader(props) {
  const { children, className } = props;
  const classes = useStyles();

  return (
    <div className={`${classes.backgroundContainer} ${className}`}>
      <div className={`ga-container ${classes.container}`}>
        <div className={`${classes.grid} mdc-layout-grid`}>
          <div className="mdc-layout-grid__inner">
            <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                  <div className={classes.label}>Page Actions</div>
                </Grid>
                <Grid item>
                  <div className={classes.childContainer}>
                    {children}
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

PageActionsHeader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

PageActionsHeader.defaultProps = {};
