import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles({
  gridContainer: {
    marginTop: '0.75rem',
    marginBottom: '1.25rem',
  },
  row: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1.875rem',
    paddingBottom: '1.875rem',
    '&:last-child': {
      borderBottom: 'none',
      paddingBottom: '0rem',
      marginBottom: '0rem',
    },
  },
  valueContainer: {
    display: 'flex',
    '& > *': {
      marginLeft: '1rem',
    },
  },
  actionContainer: {
    '& > *': {
      marginLeft: '1rem',
    },
  },
  actionButton: {
    margin: '8px 20px',
    minWidth: '50px',
    textTransform: 'none',
  },
});

const DataRowCard = ({
  buttonText,
  disabled,
  emptyColumnText,
  emptyStateText,
  onClick,
  rowActions,
  rows,
  title,
}) => {
  const classes = useStyles();

  const renderRowActions = (id) => {
    if (rowActions && rowActions.length > 0 && id) {
      return (
        <div className={classes.actionContainer}>
          {rowActions.map(action => (
            <Button
              className={classes.actionButton}
              color="primary"
              key={action.label}
              /* eslint-disable-next-line react/jsx-handler-names */
              onClick={() => action.action(id)}
            >
              {action.label}
            </Button>
          ))}
        </div>
      );
    }
  };

  const renderRowValues = (row) => {
    if (Object.prototype.hasOwnProperty.call(row, 'id') && Object.prototype.hasOwnProperty.call(row, 'values')) {
      return (
        <div className={classes.valueContainer}>
          {row.values.map((field, index) => (
            <Grid item key={index}>
              {field.value === null || field.value === '' ? (
                <>
                  <p className="card-label">{field.label}</p>
                  <p className="mdc-typography--paragraph card-reminder-text">{emptyColumnText}</p>
                </>
              ) : (
                <>
                  <p className="card-label">{field.label}</p>
                  <p className="mdc-typography--paragraph card-value">{field.value}</p>
                </>
              )}
            </Grid>
          ))}
        </div>
      );
    }
  };

  const renderRows = () => {
    if (!rows || rows.length <= 0) {
      return (
        <div>
          <p className="mdc-typography--paragraph card-reminder-text">{emptyStateText}</p>
        </div>
      );
    }

    return rows.map((row) => (
      <Grid key={row.id} container spacing={3} className={classes.row}>
        {renderRowValues(row)}
        {renderRowActions(row.id)}
      </Grid>
    ));
  };

  return (
    <Paper elevation={3} className="card-container">
      <div className="card-header-container">
        <Typography component="h3" variant="h3" color="primary">
          {title}
        </Typography>

        <Button
          color="secondary"
          disabled={disabled}
          onClick={onClick}
          size="large"
          variant="outlined"
        >
          {buttonText}
        </Button>
      </div>

      <div className={classes.gridContainer}>
        {renderRows()}
      </div>
    </Paper>
  );
};

DataRowCard.propTypes = {
  buttonText: PropTypes.string,
  disabled: PropTypes.bool,
  emptyStateText: PropTypes.string,
  onClick: PropTypes.func,
  rowActions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      action: PropTypes.func,
    }),
  ),
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      values: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.string,
        }),
      ),
    }),
  ),
  title: PropTypes.string,
  emptyColumnText: PropTypes.string,
};

DataRowCard.defaultProps = {
  buttonText: 'Edit',
  disabled: false,
  emptyStateText: 'No results',
  onClick: () => {},
  rowActions: [],
  rows: [],
  title: 'Default title',
  emptyColumnText: 'No answer',
};

export default DataRowCard;
