import React, {
  useCallback,
  useContext,
  useState,
} from 'react';
import propTypes from 'prop-types';

import { VGTContributionCandidateDefaultProps, VGTContributionCandidatePropTypes } from 'components/features/campaign-finance/types';
import useReactiveFormValue from 'hooks/useReactiveFormValue';
import { convertStringToTitleCase } from 'helpers/ConvertStringToTitleCase';

import GAContainer from 'components/Layout/GAContainer';
import CandidateMetadata from 'components/Candidates/Partials/CandidateMetadata';
import { AppSnackbarContext } from 'components/AppSnackbar';
import { MessagesContext } from 'components/messages';

import {
  CampaignFinanceDetailTableContainer,
  FETCH_TYPE_MATCHED,
  FETCH_TYPE_UNMATCHED,
  PageHeader,
  SearchFilter,
} from '@/features/campaign-finance';

export const CampaignFinanceDetailContainer = ({
  cfRecord,
  breadcrumbUrl,
  isTest,
}) => {
  // #region State

  const { messages } = useContext(MessagesContext);

  const {
    value: searchInput,
    setValue: setSearchInput,
  } = useReactiveFormValue(cfRecord.candidateName);

  const [searchedInput, setSearchedInput] = useState(cfRecord.candidateName);

  const { show: showSnackbar } = useContext(AppSnackbarContext);

  const [submitting, setSubmitting] = useState(false);

  // #region Handlers

  const handleSearchSubmit = useCallback(() => {
    setSearchedInput(searchInput);
  }, [searchInput]);

  const handleSubmittingChange = useCallback((isSubmitting) => {
    setSubmitting(isSubmitting);
  }, []);

  const handleActionSuccess = useCallback((message) => {
    showSnackbar(message, 'success');
  }, [showSnackbar]);

  const handleActionError = useCallback((message) => {
    showSnackbar(message, 'error');
  }, [showSnackbar]);

  // #region Render

  return (
    <>
      <PageHeader
        breadcrumbText={messages.header?.breadcrumb}
        breadcrumbUrl={breadcrumbUrl}
        heading={cfRecord.candidateName}
        subheading={messages.header?.eyebrow}
      >
        <CandidateMetadata text={messages.header?.metadata?.party}>
          {cfRecord.party}
        </CandidateMetadata>
        <CandidateMetadata text={messages.header?.metadata?.race}>
          {cfRecord.raceName}
        </CandidateMetadata>
        <CandidateMetadata text={messages.header?.metadata?.source}>
          {convertStringToTitleCase(cfRecord.dataSource)}
        </CandidateMetadata>
        <CandidateMetadata text={messages.header?.metadata?.sourceYear}>
          {cfRecord.yearRange}
        </CandidateMetadata>
      </PageHeader>

      <GAContainer>
        <SearchFilter
          searchInput={searchInput}
          onChange={setSearchInput}
          onSubmit={handleSearchSubmit}
          disabled={submitting}
          heading={messages.filter?.title}
          label={messages.filter?.label}
          placeholder={messages.filter?.placeholder}
          buttonText={messages.filter?.submit}
        />

        <CampaignFinanceDetailTableContainer
          cfRecordId={cfRecord.id}
          isTest={isTest}
          onActionError={handleActionError}
          onActionSuccess={handleActionSuccess}
          onSubmittingChange={handleSubmittingChange}
          searchedInput={searchedInput}
          tableHeading='Unmatched Voter Guide Candidates'
          type={FETCH_TYPE_UNMATCHED}
        />

        <CampaignFinanceDetailTableContainer
          cfRecordId={cfRecord.id}
          isTest={isTest}
          onActionError={handleActionError}
          onActionSuccess={handleActionSuccess}
          onSubmittingChange={handleSubmittingChange}
          searchedInput=""
          tableHeading='Matched Voter Guide Candidates'
          type={FETCH_TYPE_MATCHED}
        />
      </GAContainer>
    </>
  );
};

CampaignFinanceDetailContainer.propTypes = {
  cfRecord: propTypes.shape(VGTContributionCandidatePropTypes),
  breadcrumbUrl: propTypes.string,
  isTest: propTypes.bool,
};

CampaignFinanceDetailContainer.defaultProps = {
  cfRecord: VGTContributionCandidateDefaultProps,
  breadcrumbUrl: '/campaign-finance',
  isTest: false,
};
