import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Checkbox,
  Grid,
  Typography,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { VariableSizeList as List } from 'react-window';
import GISDistrictNameField from 'components/Districts/Partials/GISDistrictNameField';

const useStyles = makeStyles({
  expandAllButton: {
    margin: '1rem 0',
    textTransform: 'none',
  },
});

const GISUploadedDistrictList = React.forwardRef(({
  allDistrictsExpanded,
  allDistrictsSelected,
  districts,
  errors,
  noDistrictsSelected,
  onInputChange,
  onSelect,
  onSelectAll,
  onToggleMetadata,
  onToggleAllMetadata,
  submitting,
}, ref) => {
  const classes = useStyles();
  const sizeMap = useRef({});
  const listRef = useRef();
  const inputRefs = useRef([]);

  // We have to use imperative handle here to trigger the
  // field animations when the user clicks a district
  // in the map. Generally we want to avoid this, but in
  // this case the other option is re-rendering on a list
  // that could be thousands of districts long.
  useImperativeHandle(ref, () => ({
    resetAfterIndex: (index) => {
      listRef.current.resetAfterIndex(index);
    },
    scrollToItem: (index) => {
      listRef.current.scrollToItem(index);
      inputRefs.current[index].animate();
    },
  }));

  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, districts.length);
  }, [districts]);

  const setSize = useCallback((index, size) => {
    sizeMap.current = { ...sizeMap.current, [index]: size };
    listRef.current.resetAfterIndex(index);
  }, []);

  const getItemSize = index => sizeMap.current[index] || 93;

  return (
    <div>
      {noDistrictsSelected && (
        <Grid container>
          <Grid item xs={12}>
            <Alert severity="warning" style={{ marginBottom: '1rem' }}>
              A district set must include at least one district
            </Alert>
          </Grid>
        </Grid>
      )}

      <Grid container alignItems="flex-start">
        <Grid item xs={1}>
          <Checkbox
            checked={allDistrictsSelected}
            onChange={() => onSelectAll(!allDistrictsSelected)}
            inputProps={{ 'aria-label': 'Select all uploaded districts' }}
          />
        </Grid>

        <Grid item xs={11}>
          <Typography variant="h6" component="h4">
            Select all Uploaded Districts
          </Typography>

          <Typography variant="body2" component="p">
            Only the selected districts will be saved to your set or affected by bulk edits
          </Typography>

          <Button
            className={classes.expandAllButton}
            color="primary"
            onClick={onToggleAllMetadata}
            size="medium"
          >
            {allDistrictsExpanded ? 'Collapse all district details' : 'Expand all district details'}
          </Button>
        </Grid>
      </Grid>

      <List
        height={500}
        itemCount={districts.length}
        itemData={districts}
        itemSize={getItemSize}
        overscanCount={10}
        ref={listRef}
      >
        {({ data, index, style }) => (
          <GISDistrictNameField
            errors={errors}
            expanded={data[index].expanded}
            id={data[index].id}
            index={index}
            key={data[index].id}
            metadata={data[index].metadata}
            name={data[index].name}
            onChange={onInputChange}
            onSelect={onSelect}
            onToggleMetadata={onToggleMetadata}
            ref={el => {
              inputRefs.current[index] = el;
            }}
            selected={data[index].selected}
            setSize={setSize}
            style={style}
            submitting={submitting}
          />
        )}
      </List>
    </div>
  );
});
GISUploadedDistrictList.displayName = 'GISUploadedDistrictList';

GISUploadedDistrictList.propTypes = {
  allDistrictsExpanded: PropTypes.bool,
  allDistrictsSelected: PropTypes.bool,
  districts: PropTypes.arrayOf(PropTypes.shape({
    coordinates: PropTypes.array,
    expanded: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    metadata: PropTypes.object,
    name: PropTypes.string,
    selected: PropTypes.bool,
  })),
  errors: PropTypes.object,
  noDistrictsSelected: PropTypes.bool,
  onInputChange: PropTypes.func,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
  onToggleMetadata: PropTypes.func,
  onToggleAllMetadata: PropTypes.func,
  submitting: PropTypes.bool,
};

GISUploadedDistrictList.defaultProps = {
  allDistrictsExpanded: true,
  allDistrictsSelected: true,
  districts: [],
  errors: {},
  noDistrictsSelected: false,
  onInputChange: () => {},
  onSelect: () => {},
  onSelectAll: () => {},
  onToggleMetadata: () => {},
  onToggleAllMetadata: () => {},
  submitting: false,
};

export default GISUploadedDistrictList;
