import React from 'react';
import PropTypes from 'prop-types';
import RadioCardList from 'components/RadioCardList';

function ExistingCategories(props) {
  const {
    list,
    onChange,
    checked,
    deletable,
    onDelete,
  } = props;

  return (
    <RadioCardList
      cards={list}
      onChange={onChange}
      checked={checked}
      deletableIndex={deletable}
      onDelete={onDelete}
    />
  );
}

ExistingCategories.propTypes = {
  checked: PropTypes.number,
  deletable: PropTypes.number, // expecting null, don't need default value
  list: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
};

ExistingCategories.defaultProps = {
  checked: 0,
  list: [],
  onChange: () => {},
  onDelete: () => {},
};

export default ExistingCategories;
