import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
import KPI from 'components/KPI.js';
import FlagIcon from '@material-ui/icons/Flag';

const useStyles = makeStyles({
  cardContainer: {
    marginBottom: '1.6rem',
  },
  headingContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginBottom: '1rem',
  },
  divider: {
    borderBottom: '1px solid #e5e5e5',
    margin: '.8rem auto 1.8rem',
    width: '90%',
  },
  flagIcon: {
    color: '#94a8b2',
  },
  moretext: {
    fontSize: '1.2rem',
    marginBottom: '1.6rem',
    fontWeight: '600',
  },
  kpi: {
    marginBottom: '.8rem',
  },
  actionContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  actionButton: {
    minWidth: 0,
    margin: '0 .4rem',
  },
});

function DashboardKpiCard({
  buttons,
  kpis,
  moretext,
  subtitle,
  showIcon,
  title,
}) {
  const classes = useStyles();

  const kpiComponentArray = kpis.map((kpiGroup, groupIndex) => (
    <Grid container spacing={1} key={groupIndex}>
      {kpiGroup.map((kpiData, dataIndex) => (
        <Grid item xs={6} key={`${groupIndex}-${dataIndex}`} className={classes.kpi}>
          <KPI
            title={kpiData.title}
            value={kpiData.value}
            helpTitle={kpiData.helpTitle}
            helpText={kpiData.helpText}
            total={kpiData.total}
          />
        </Grid>
      ))}
    </Grid>
  ));

  const getButtons = buttons.map((buttonsData, index) => (
    <Button
      key={`btn${index}`}
      variant="outlined"
      color="secondary"
      size="medium"
      className={classes.actionButton}
      onClick={() => window.location.replace(buttonsData.action)}
    >
      {buttonsData.title}
    </Button>
  ));

  return (
    <Card className={classes.cardContainer}>
      <CardContent>
        <div className={classes.headingContainer}>
          <div>
            <Typography component="h2" variant="h3">
              {title}
            </Typography>

            <Typography color="textSecondary" component="p" variant="body2">
              {subtitle}
            </Typography>
          </div>

          {showIcon && (
            <FlagIcon className={classes.flagIcon} fontSize="large" />
          )}
        </div>

        {kpiComponentArray}

        <div className={classes.moretext}>
          {moretext}
        </div>

        <div className={classes.divider}></div>

        <div className={classes.actionContainer}>
          {getButtons}
        </div>
      </CardContent>
    </Card>
  );
}

DashboardKpiCard.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      action: PropTypes.string,
    }),
  ),
  showIcon: PropTypes.bool,
  kpis: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
      }),
    ),
  ).isRequired,
  moretext: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

DashboardKpiCard.defaultProps = {
  buttons: [],
  kpis: [
    [{ value: 60, title: 'Total' }],
    [{ value: 23, title: 'Unpublished' }, { value: 23, title: 'Need districting' }],
  ],
  showIcon: false,
  subtitle: 'Guide Quick View',
  title: 'Races and Measures',
};

export default DashboardKpiCard;
