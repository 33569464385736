import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles({
  label: {
    color: '#555',
    fontSize: '0.75rem',
  },
});

const MailAddressForm = ({
  errors,
  fields,
  isFullWidth,
  onChange,
  submitting,
}) => {
  const classes = useStyles();

  const isRequired = () => {
    if (fields.address1 && fields.address1 !== '') {
      return true;
    }
    if (fields.address2 && fields.address2 !== '') {
      return true;
    }
    if (fields.city && fields.city !== '') {
      return true;
    }
    if (fields.state && fields.state !== '') {
      return true;
    }
    if (fields.zip && fields.zip !== '') {
      return true;
    }
    return false;
  };

  return (
    <div>
      <Typography variant="body2" component="p" className={classes.label} paragraph>
        Mailing Address
      </Typography>

      <Grid container spacing={3}>
        <Grid item md={isFullWidth ? 12 : 6} xs={12}>
          <FormControl fullWidth>
            <TextField
              id="mailing-address-line-1"
              disabled={submitting}
              required={isRequired()}
              error={errors.address1}
              helperText={errors.address1 ? errors.address1 : 'Street Address, P.O. box, c/o'}
              label='Address Line 1'
              name="address1"
              variant="filled"
              onChange={onChange}
              value={fields.address1}
              inputProps={{ maxLength: 500 }}
            />
          </FormControl>
        </Grid>

        <Grid item md={isFullWidth ? 12 : 6} xs={12}>
          <FormControl fullWidth>
            <TextField
              id="mailing-address-line-2"
              disabled={submitting}
              required={false}
              error={errors.address2}
              helperText={errors.address2 ? errors.address2 : 'Apartment, suite, unit, building, floor, etc.'}
              label='Address Line 2'
              name="address2"
              variant="filled"
              onChange={onChange}
              value={fields.address2}
              inputProps={{ maxLength: 300 }}
            />
          </FormControl>
        </Grid>
        <Grid item md={isFullWidth ? 12 : 6} xs={12}>
          <FormControl fullWidth>
            <TextField
              id="mailing-address-city"
              disabled={submitting}
              required={isRequired()}
              error={errors.city}
              helperText={errors.city ? errors.city : ''}
              label='City'
              name="city"
              variant="filled"
              onChange={onChange}
              value={fields.city}
              inputProps={{ maxLength: 100 }}
            />
          </FormControl>
        </Grid>
        <Grid item md={isFullWidth ? 12 : 6} xs={12}>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <TextField
                  id="mailing-address-state"
                  disabled={submitting}
                  required={isRequired()}
                  error={errors.state}
                  helperText={errors.state ? errors.state : ''}
                  label='State Abbreviation'
                  name="state"
                  variant="filled"
                  onChange={onChange}
                  value={fields.state}
                  inputProps={{ maxLength: 2 }}
                />
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <TextField
                  id="mailing-address-zip"
                  disabled={submitting}
                  required={isRequired()}
                  error={errors.zip}
                  helperText={errors.zip ? errors.zip : ''}
                  label='ZIP Code'
                  name="zip"
                  variant="filled"
                  onChange={onChange}
                  value={fields.zip}
                  pattern="/^-?\d+\.?\d*$/"
                  inputProps={{ maxLength: 20 }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

MailAddressForm.propTypes = {
  errors: PropTypes.object,
  fields: PropTypes.shape({
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
  }),
  isFullWidth: PropTypes.bool,
  onChange: PropTypes.func,
  submitting: PropTypes.bool,
};

MailAddressForm.defaultProps = {
  errors: {},
  fields: {
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: undefined,
  },
  isFullWidth: false,
  onChange: () => {},
  submitting: false,
};

export default MailAddressForm;
