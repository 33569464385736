import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import LanguagesAppliedToGuide from 'components/LanguagesAppliedToGuide';
import fetchUtil from 'helpers/Fetch';

const styles = {
  addFormContainer: {
    display: 'flex',
    margin: '1rem',
  },
  addFormAbbrField: {
    marginRight: '2rem',
    width: '20rem',
  },
  addFormActions: {
    backgroundColor: '#eef4f8',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '.8rem 2rem',
  },
  cardContainerNoOutline: {
    border: 'none',
    boxShadow: 'none',
  },
};

const RaceQuestionSetForm = ({
  buttonText,
  classes,
  children,
  languages,
  onError,
  onSuccess,
  name,
  requiredText,
  outlined,
  submitMethod,
  submitUrl,
  validationErrorMessage,
}) => {
  const [canSubmit, setCanSubmit] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [value, setValue] = React.useState(name);
  const [validationError, setValidationError] = React.useState('');

  React.useEffect(() => {
    if (!value) {
      setCanSubmit(false);
      return;
    }

    setCanSubmit(true);
  }, [value]);

  const handleInputChange = (event) => {
    setValue(event.target.value);
  };

  const handleSubmit = () => {
    setSubmitting(true);

    fetchUtil(submitUrl, submitMethod, { name: value })
      .then((response) => {
        setSubmitting(false);
        onSuccess(submitMethod, response);
      })
      .catch(error => {
        setSubmitting(false);

        // TODO: From what I can tell, there's no way to differentiate
        // validation errors from all other errors in fetchUtil. As a result
        // I suspect we're not properly handling validation errors coming
        // back from the server in the application.
        //
        // The validation error coming back here doesn't appear to include
        // the field names in the validation errors either, so there is
        // probably some work to be done improving backend validation error
        // responses. But, for now, enjoy this gross hack.
        if (error && error.data) {
          setValidationError(error.data);
          return;
        }

        onError(validationErrorMessage);
      });
  };

  const cardContainerClass = React.useMemo(() => (outlined ? '' : classes.cardContainerNoOutline), [classes, outlined]);

  return (
    <Card variant="outlined" className={cardContainerClass}>
      <CardContent className={classes.addFormContainer}>
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                id="name"
                disabled={submitting}
                error={!!validationError}
                helperText={validationError}
                label="Race Question Set Name"
                name="name"
                required
                variant="filled"
                onChange={handleInputChange}
                value={value}
              />
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <LanguagesAppliedToGuide languages={languages} />
          </Grid>
        </Grid>
      </CardContent>

      <CardActions className={classes.addFormActions}>
        <Typography component="span" variant="body2">
          {requiredText}
        </Typography>

        <div>
          {children}

          <Button
            variant="contained"
            color="secondary"
            onClick={handleSubmit}
            size="large"
            disabled={!canSubmit || submitting}
          >
            {buttonText}
          </Button>
        </div>

      </CardActions>
    </Card>
  );
};

RaceQuestionSetForm.propTypes = {
  buttonText: PropTypes.string,
  classes: PropTypes.object,
  children: PropTypes.node,
  languages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
  })),
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
  name: PropTypes.string,
  requiredText: PropTypes.string,
  outlined: PropTypes.bool,
  submitMethod: PropTypes.oneOf(['POST', 'PATCH']),
  submitUrl: PropTypes.string.isRequired,
  validationErrorMessage: PropTypes.string,
};

RaceQuestionSetForm.defaultProps = {
  buttonText: 'Add Question Set',
  languages: [],
  name: '',
  onError: () => {},
  onSuccess: () => {},
  requiredText: '*Required fields needed to add a new question set',
  outlined: false,
  submitMethod: 'POST',
  validationErrorMessage: 'There was an issue adding your question set. Correct any errors and try again.',
};

export default withStyles(styles)(RaceQuestionSetForm);
