import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Button,
  Typography,
} from '@material-ui/core';
import EmptyState from 'components/EmptyState';

const useStyles = makeStyles({
  question: {
    flexDirection: 'column',
    paddingBottom: '0.938rem',
    marginBottom: '0.938rem',
    borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
    '&:last-child': {
      borderBottom: 'none',
      paddingBotom: '0rem',
      marginBottom: '0rem',
    },
  },
});

function QuestionListCard({
  editScreen,
  editButtonText,
  emptyStateText,
  questions,
  onEdit,
  title,
}) {
  const classes = useStyles();

  const questionArray = questions.map((question, index) => (
    <div key={index} className={classes.question}>
      <p className="card-label">{question.label}</p>
      {question.value === null || question.value === '' ? (
        <>
          <p className="mdc-typography--paragraph card-reminder-text">No answer</p>
        </>
      ) : (
        <>
          <p className="mdc-typography--paragraph card-value">{question.value}</p>
        </>
      )}
    </div>
  ));

  const handleEditClick = () => {
    onEdit(editScreen);
  };

  return (
    <Paper elevation={3} className="card-container">
      <div className="card-header-container">
        <Typography component="h3" variant="h3" color="primary">
          {title}
        </Typography>

        <Button
          variant="outlined"
          color="secondary"
          size="large"
          onClick={handleEditClick}
        >
          {editButtonText}
        </Button>

      </div>
      {(questions.length > 0) ? (
        <div>
          {questionArray}
        </div>
      ) : (
        <div>
          <EmptyState>
            <p>{emptyStateText}</p>
          </EmptyState>
        </div>
      )}
    </Paper>
  );
}

QuestionListCard.propTypes = {
  editScreen: PropTypes.number,
  editButtonText: PropTypes.string,
  emptyStateText: PropTypes.string,
  questions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
  })),
  onEdit: PropTypes.func,
  title: PropTypes.string,
};

QuestionListCard.defaultProps = {
  editScreen: 2,
  editButtonText: 'Edit',
  emptyStateText: 'No questions have been asked at this time.',
  questions: [],
  onEdit: () => {},
  title: 'Default title',
};

export default QuestionListCard;
