import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Typography } from '@material-ui/core';

import GAContainer from 'components/Layout/GAContainer';
import Breadcrumb from 'components/Breadcrumb';

import { useStyles } from './PageHeader.styles';

const PageHeader = ({
  breadcrumbText,
  breadcrumbUrl,
  children,
  heading,
  subheading,
}) => {
  const classes = useStyles();

  return (
    <div className="ga-banner" data-testid="page-header-cf">
      <GAContainer>
        <Grid container className={classes.container}>
          <Grid
            item
            xs={6}
          >
            {breadcrumbText && breadcrumbUrl && (
              <Breadcrumb text={breadcrumbText} url={breadcrumbUrl} otherClasses={classes.breadcrumb} />
            )}

            <Typography
              component="p"
              variant="body1"
              color="textSecondary"
              className={classes.subheading}
            >
              {subheading}
            </Typography>
            <Typography
              component="h1"
              variant="h1"
              className={classes.break}
            >
              {heading}
            </Typography>
          </Grid>

          <Grid
            item
            xs={6}
            className={classes.actionContainer}
          >
            {children}
          </Grid>
        </Grid>
      </GAContainer>
    </div>
  );
};

PageHeader.propTypes = {
  breadcrumbText: PropTypes.string,
  breadcrumbUrl: PropTypes.string,
  children: PropTypes.node,
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string,
};

PageHeader.defaultProps = {
  breadcrumbText: '',
  breadcrumbUrl: '',
  subheading: '',
};

export { PageHeader };
