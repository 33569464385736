import React, { useState } from 'react';
import PropTypes from 'prop-types';
import GISDistrictListRow from 'components/Districts/Partials/GISDistrictListRow';
import fetchUtil from 'helpers/Fetch';

export default function GISDistrictListRowContainer({
  guideId,
  hasFocus,
  id,
  name,
  onDelete,
  onUpdateError,
  onUpdateSuccess,
  style,
}) {
  const [tempName, setTempName] = useState(name);
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const handleInputChange = (event) => {
    const { value } = event.target;
    setTempName(value);
  };

  const handleSubmit = () => {
    setSubmitting(true);

    fetchUtil(`/api/v1/guide/${guideId}/district/gis/district/${id}`, 'PATCH', {
      id,
      name: tempName,
    })
      .then(() => {
        setSubmitting(false);
        onUpdateSuccess();
      })
      .catch(error => {
        console.error(error);

        setSubmitting(false);
        setErrors(error);
        onUpdateError();
      });
  };

  return (
    <GISDistrictListRow
      disabled={submitting}
      errors={errors}
      hasFocus={hasFocus}
      id={id}
      name={tempName}
      onDelete={onDelete}
      onChange={handleInputChange}
      onSubmit={handleSubmit}
      style={style}
    />
  );
}

GISDistrictListRowContainer.propTypes = {
  guideId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  hasFocus: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  name: PropTypes.string,
  onDelete: PropTypes.func,
  onUpdateError: PropTypes.func,
  onUpdateSuccess: PropTypes.func,
  style: PropTypes.object,
};

GISDistrictListRowContainer.defaultProps = {
  hasFocus: false,
  name: '',
  onDelete: () => {},
  onUpdateError: () => {},
  onUpdateSuccess: () => {},
  style: {},
};
