import React, { useMemo, useRef, useState } from 'react';
import { ThemeProvider } from '@material-ui/core';
import PropTypes from 'prop-types';
import theme from 'scripts/theme';
import Typography from '@material-ui/core/Typography';
import { guideApiKeyPropType, voterGuidePropType } from 'propTypes';
import Breadcrumb from 'components/Breadcrumb';
import APIKeyTable from 'components/SuperAdminSettings/Partials/APIKeyTable';
import SnackbarAlert from 'components/SnackbarAlert';
import ConfirmationDialog from 'components/ConfirmationDialog';

/*
insert into vgt_guide_api_key values (nextval('hibernate_sequence'), 'test key to delete', 'this will get deleted', '6c67f6f8-f169-4c8b-86a0-b8a79947caa5', 2, 'devin.r@lookthink.com',  CURRENT_DATE + interval '1 year', true, CURRENT_DATE, CURRENT_DATE);
 */

// import fetchUtil from 'helpers/Fetch';

const GuideDetailContainer = ({ apiKeys, guide }) => {
  const [deletedKeys, setDeletedKeys] = useState([]);
  const [keysToDelete, setKeysToDelete] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const _snackbar = useRef(null);

  const onSuccess = (msg) => {
    _snackbar.current.show('success', msg);
  };

  const onError = (msg) => {
    _snackbar.current.show('error', msg);
  };

  const handleCloseDelete = () => {
    setKeysToDelete([]);
    setShowDeleteModal(false);
  };

  const handleShowDelete = (ids) => {
    setKeysToDelete(ids);
    setShowDeleteModal(true);
  };

  const handleAPIKeyDelete = async () => {
    // TODO: update this
    // const URL = '#';
    // const METHOD = 'DELETE';
    // const BODY = {
    //   ids: keysToDelete,
    // };

    setShowDeleteModal(false);

    try {
      setSubmitting(true);

      // TODO: add this back
      // await fetchUtil(URL, METHOD, BODY);

      // add key id to deleted keys list
      setDeletedKeys([...deletedKeys, ...keysToDelete]);

      setSubmitting(false);
      onSuccess('API Key successfully deleted.');
    } catch (e) {
      console.error(e);
      setSubmitting(false);
      onError('Could not delete API key. Please try again.');
    }
  };

  // don't show api keys we've deleted (needed pre-refresh)
  const filteredAPIKeys = useMemo(() => (
    apiKeys.filter(apiKey => !deletedKeys.includes(apiKey.id))
  ), [apiKeys, deletedKeys]);

  return (
    <ThemeProvider theme={theme}>
      <SnackbarAlert ref={_snackbar} />

      <ConfirmationDialog
        cancelButtonText={'Cancel'}
        confirmButtonText={'Delete'}
        heading={'Delete API Key?'}
        onCancel={handleCloseDelete}
        onConfirm={handleAPIKeyDelete}
        open={showDeleteModal}
      >
        <Typography component="p" variant="body1">
          Are you sure you want to delete this API key? This action is irreversible.
        </Typography>
      </ConfirmationDialog>

      <Breadcrumb text="Affiliates & Guides" url="/admin/guides" />

      <Typography component="h1" variant="h1">
        {guide.voterGuide.name}
      </Typography>

      <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12 ga-m-vertical--large">
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          minHeight: '47px', // Prevents the container from jumping around when bulk actions show/hide
        }}>
          <Typography component="h2" variant="h3">
            API Keys
          </Typography>
        </div>

        <APIKeyTable
          keys={filteredAPIKeys}
          onSuccess={onSuccess}
          onError={onError}
          onDelete={handleShowDelete}
          disabled={submitting}
        />
      </div>
    </ThemeProvider>
  );
};

GuideDetailContainer.propTypes = {
  apiKeys: PropTypes.arrayOf(guideApiKeyPropType),
  guide: voterGuidePropType,
};

export default GuideDetailContainer;
