import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Snackbar, IconButton } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { AppSnackbarContext } from './AppSnackbarContext';

const AppSnackbarProvider = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [message, setMessage] = useState('');
  const [variant, setVariant] = useState('success');

  const show = (newMessage, newVariant = 'success') => {
    setIsVisible(true);
    setMessage(newMessage);
    setVariant(newVariant);
  };

  const hide = () => {
    setIsVisible(false);
    setMessage('');
    setVariant('success');
  };

  return (
    <AppSnackbarContext.Provider value={{ show, hide }}>
      {children}
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={isVisible}
        autoHideDuration={6000}
        onClose={() => hide()}
      >
        <Alert
          severity={variant}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => hide()}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >{message}</Alert>
      </Snackbar>
    </AppSnackbarContext.Provider>
  );
};

AppSnackbarProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AppSnackbarProvider };
