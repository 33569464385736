import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import clone from 'rfdc/default';
import {
  Button,
  Typography,
} from '@material-ui/core';
import { makeStyles, ThemeProvider } from '@material-ui/styles';
import PageHeader from 'components/PageHeader';
import PageActionsHeader from 'components/PageActionsHeader';
import PageActionsFooter from 'components/PageActionsFooter';
import ConfirmationDialog from 'components/ConfirmationDialog';
import SnackbarAlert from 'components/SnackbarAlert';
import ZipCodeDistrictForm from 'components/Districts/Partials/ZipCodeDistrictForm';
import fetchUtil from 'helpers/Fetch';
import GaUtils from 'helpers/GaUtils';
import theme from 'scripts/theme';

const useStyles = makeStyles({
  danger: {
    color: '#ac1b3d',
    '&:hover': {
      color: '#6B1126',
    },
  },
});

const formatDistrictForDisplay = (districtObj) => {
  const holder = {};
  holder.name = districtObj.name;
  holder.id = districtObj.id;

  // Zip Codes
  if (!districtObj.zipCodes) {
    if (districtObj.values) {
      holder.zipCodes = districtObj.values.map(val => val.low);
    } else {
      holder.zipCodes = [];
    }
  } else {
    holder.zipCodes = districtObj.zipCodes;
  }

  // Zip codes to add
  if (!districtObj.zipCodesToAdd) {
    holder.zipCodesToAdd = [];
  } else {
    holder.zipCodesToAdd = districtObj.zipCodesToAdd;
  }

  // Zip Codes to delete
  if (!districtObj.zipCodesToDelete) {
    holder.zipCodesToDelete = [];
  } else {
    holder.zipCodesToDelete = districtObj.zipCodesToDelete;
  }

  return holder;
};

const ZipCodeDistrictFormContainer = (props) => {
  // Variables and Constants
  const {
    district,
    guideId,
    messages,
  } = props;
  const classes = useStyles();
  const _snackbar = useRef();

  // hooks
  const [districtHolder, setDistrictHolder] = useState(formatDistrictForDisplay(district));
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteSubmitting, setDeleteSubmitting] = useState(false);
  const [zipCodeInputVal, setZipCodeInputVal] = useState('');
  const [showCancelModal, setShowCancelModal] = useState(false);

  // Functions
  const handleChange = (name, value) => {
    const tempDistrictHolder = clone(districtHolder);
    if (name === 'zipCodeInput') {
      setZipCodeInputVal(value);
    } else if (Object.prototype.hasOwnProperty.call(tempDistrictHolder, name)) {
      tempDistrictHolder[name] = value;

      if (Object.prototype.hasOwnProperty.call(errors, name)) {
        console.log('error for ', name);
        const newErrors = { ...errors };
        delete newErrors[name];
        setErrors(newErrors);
      }

      setDistrictHolder(tempDistrictHolder);
    }
  };

  const handleConfirmDelete = () => {
    setDeleteSubmitting(true);

    fetchUtil(`/api/v1/guide/${guideId}/district/zip/${district.id}`, 'DELETE')
      .then(() => {
        // Redirect user to the district dashboard and show a snackbar notification
        window.location.href = '/districts?showSuccessMsgFrom=deleteZipDistrict';
      })
      .catch(error => {
        const status = error?.status;
        console.error(error);

        setDeleteSubmitting(false);

        if (status && status == 403) {
          _snackbar.current.show('error', 'Only guide admins are allowed to delete Zip Districts. Contact your guide admin for assistance.');
          return;
        }

        if (error && error.data) {
          _snackbar.current.show('error', error.data);
          return;
        }

        _snackbar.current.show('error', 'There was an issue deleting your Zip District. Try again or contact your guide admin if this continues.');
      });
  };

  const handleZipCodeAdd = (zipCode) => {
    const tempDistrictHolder = clone(districtHolder);
    const onlyContainsNumbers = /^\d+$/.test(zipCode);

    if (!onlyContainsNumbers) {
      const error = {
        zipCodeInput: 'A zip code can only contain numbers',
      };
      setErrors(error);
      return;
    }

    if (zipCode.length < 5 || zipCode.length > 5) {
      setErrors({ zipCodeInput: 'Zip codes must be 5 characters in length' });
      return;
    }

    if (zipCode.length > 0 && typeof tempDistrictHolder.zipCodes.find(z => z === zipCode) == 'undefined') {
      tempDistrictHolder.zipCodes.push(zipCode);

      if (typeof tempDistrictHolder.zipCodesToDelete.find(z => z === zipCode) == 'undefined') {
        // if we delete and re-add the same one, we don't want to show the database we did that, because that's embarassing
        GaUtils.addItemToAttrList(tempDistrictHolder, 'zipCodesToAdd', zipCode);
      }

      GaUtils.delItemToAttrList(tempDistrictHolder, 'zipCodesToDelete', zipCode);

      // update holder
      setDistrictHolder(tempDistrictHolder);
      // Clear zip code input
      setZipCodeInputVal('');
    } else if (zipCode.length != 0) {
      // display duplicate error
      const error = {
        zipCodeInput: messages.zipCodeDuplicateError,
      };

      setErrors(error);
    }
  };

  const handleZipCodeRemove = (zipCode) => {
    const tempDistrictHolder = clone(districtHolder);
    // Note: This method was updated for fixing error when the user delete items
    const validDel = GaUtils.delItemToAttrList(tempDistrictHolder, 'zipCodes', zipCode);
    if (validDel) {
      GaUtils.delItemToAttrList(tempDistrictHolder, 'zipCodesToAdd', zipCode);
      GaUtils.addItemToAttrList(tempDistrictHolder, 'zipCodesToDelete', zipCode);
      // update holder
      setDistrictHolder(tempDistrictHolder);
    } else {
      const error = {
        zipCodes: messages.zipCodeNotFoundError,
      };
      setErrors(error);
    }
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      let apiAction = 'POST';
      let url = `/api/v1/guide/${guideId}/district/zip`;
      if (districtHolder.id) {
        // Updating zip district
        apiAction = 'PUT';
        url = `${url}/${districtHolder.id}`;
      }

      // Object to send
      const objSave = {
        name: districtHolder.name,
        zipCodes: districtHolder.zipCodesToAdd,
        zipCodesToDelete: districtHolder.zipCodesToDelete,
      };

      // fetch
      const returnValue = await fetchUtil(url, apiAction, objSave);

      if (returnValue.id === district.id) {
        window.location.href = '/districts?showSuccessMsgFrom=editedDistrict';
      } else {
        window.location.href = '/districts?showSuccessMsgFrom=newDistrict';
      }
    } catch (error) {
      console.error(error);
      setErrors(error);
      if (districtHolder.id) {
        _snackbar.current.show('error', messages.zipCodeUpdateError);
      } else {
        _snackbar.current.show('error', messages.zipCodeSaveError);
      }
      setIsSubmitting(false);
    }
  };

  const handleConfirmCancel = () => {
    window.location.href = '/districts';
  };

  return (
    <ThemeProvider theme={theme}>
      <SnackbarAlert ref={_snackbar} />

      <ConfirmationDialog
        cancelButtonText="Cancel"
        confirmButtonText="Confirm"
        heading="Are you sure you want to cancel?"
        onCancel={() => setShowCancelModal(false)}
        onConfirm={handleConfirmCancel}
        open={showCancelModal}
      >
        <Typography component="p" variant="body1">
          If you exit this page, all unsaved changes will be lost.
        </Typography>
      </ConfirmationDialog>

      <ConfirmationDialog
        heading={`Delete ${district.name}`}
        onCancel={() => setShowDeleteModal(false)}
        onConfirm={handleConfirmDelete}
        open={showDeleteModal}
        submitting={deleteSubmitting}
      >
        <Typography component="p" variant="body1">
          Are you sure you want to <strong>permanently delete</strong> this district? Any race currently using this district will have no district assigned to it.
        </Typography>
      </ConfirmationDialog>

      <PageHeader
        breadcrumbText="District Dashboard"
        breadcrumbUrl="/districts"
        heading={messages.title}
        subheading={messages.subtitle}
      />

      <PageActionsHeader>
        <Button
          disabled={isSubmitting}
          onClick={() => setShowCancelModal(true)}
        >
          {messages.cancel}
        </Button>

        {district.id && (
          <Button
            className={classes.danger}
            onClick={() => setShowDeleteModal(true)}
            size="small"
          >
            Delete
          </Button>
        )}

        <Button
          color="secondary"
          disabled={isSubmitting}
          onClick={handleSubmit}
          variant="contained"
        >
          {messages.save}
        </Button>
      </PageActionsHeader>

      <div className="ga-container">
        <div className="mdc-layout-grid">
          <div className="mdc-layout-grid__inner">
            <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
              <ZipCodeDistrictForm
                district={formatDistrictForDisplay(districtHolder)}
                errors={errors}
                messages={messages}
                onAdd={handleZipCodeAdd}
                onChange={handleChange}
                onRemove={handleZipCodeRemove}
                submitting={isSubmitting}
                zipCodeInput={zipCodeInputVal}
              />
            </div>
          </div>
        </div>
      </div>

      <PageActionsFooter sticky>
        <Button
          disabled={isSubmitting}
          onClick={() => setShowCancelModal(true)}
        >
          {messages.cancel}
        </Button>

        {district.id && (
          <Button
            className={classes.danger}
            onClick={() => setShowDeleteModal(true)}
          >
            Delete
          </Button>
        )}

        <Button
          color="secondary"
          disabled={isSubmitting}
          onClick={handleSubmit}
          variant="contained"
        >
          {messages.save}
        </Button>
      </PageActionsFooter>
    </ThemeProvider>
  );
};

ZipCodeDistrictFormContainer.propTypes = {
  district: PropTypes.object,
  guideId: PropTypes.string,
  messages: PropTypes.object.isRequired,
};

ZipCodeDistrictFormContainer.defaultProps = {
  district: {
    id: '',
    name: '',
    zipCodes: [],
    values: [],
  },
};

export default ZipCodeDistrictFormContainer;
