import React from 'react';
import PropTypes from 'prop-types';
import clone from 'rfdc/default';
import {
  Card,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import QuestionTextField from 'components/QuestionAnswerFields/QuestionTextField';
import QuestionRadioOptions from 'components/QuestionAnswerFields/QuestionRadioOptions';
import QuestionYouTubeField from 'components/QuestionAnswerFields/QuestionYouTubeField';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    color: '#757575',
    marginBottom: '0.625rem',
  },
  subtitle: {
    [theme.breakpoints.up('md')]: {
      width: '75%',
    },
  },
  question: {
    marginBottom: '2.5rem',
  },
}));

const CandidateQuestionResponses = ({
  languages,
  messages,
  onChange,
  questionSet,
  questionTypeOptions,
  submitting,
  defaultAnswerLength,
}) => {
  const classes = useStyles();

  const renderQuestionField = (question) => {
    switch (question.type) {
      default:
        return 'Question type not correctly selected';
      case 1:
        return (
          <div className={classes.question}>
            <QuestionTextField
              answer={question.answer}
              id={question.id}
              errors={question.errors}
              languages={languages}
              translatedQuestions={question.translatedQuestions}
              translatedAnswers={question.translatedAnswers}
              label={question.question}
              maxl={question.maxl || defaultAnswerLength}
              onChange={onChange}
            />
          </div>
        );
      case 2:
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
        return (
          <div className={classes.question}>
            <QuestionRadioOptions
              id={question.id}
              value={question.answer}
              onChange={onChange}
              type={question.type}
              label={question.question}
              options={questionTypeOptions}
            />
          </div>
        );
      case 4:
        return (
          <div className={classes.question}>
            <QuestionYouTubeField
              answerURL={question.answerURL}
              answerText={question.answerText}
              errors={question.errors}
              id={question.id}
              onChange={onChange}
              submitting={submitting}
              question={question.question}
            />
          </div>
        );
    }
  };

  const questionsSorted = questionSet && questionSet.questions ? clone(questionSet.questions) : [];
  questionsSorted.sort((a, b) => a.index - b.index);

  // Render
  return (
    <Card>
      <CardContent>
        <Grid container className="ga-m-bottom--medium">
          <Grid item xs={11}>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Typography
                color="primary"
                component="span"
                style={{ fontWeight: 600 }}
                variant="h6"
              >
                {messages.title}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                component="span"
              >
                {messages.subTitle}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <div className="ga-w-100-p">
          <div className="ga-m-bottom--medium">
            <Typography
              component="span"
              style={{ fontWeight: 600 }}
              variant="subtitle1"
            >
              {questionSet.name}
            </Typography>
          </div>

          {
            questionsSorted.map(question => (
              <div key={question.id}>
                {renderQuestionField(question)}
              </div>
            ))
          }

        </div>

      </CardContent>
    </Card>
  );
};

CandidateQuestionResponses.propTypes = {
  messages: PropTypes.object.isRequired,
  questionSet: PropTypes.object,
  languages: PropTypes.arrayOf(PropTypes.object),
  submitting: PropTypes.bool,
  onChange: PropTypes.func,
  questionTypeOptions: PropTypes.array,
  defaultAnswerLength: PropTypes.number,
};

CandidateQuestionResponses.defaultProps = {
  questionSet: {},
  languages: [],
  submitting: false,
  onChange: () => {},
  questionTypeOptions: [],
  defaultAnswerLength: 1000,
};

export default CandidateQuestionResponses;
