import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { MUI_LICENSE_KEY, ROWS_PER_PAGE_OPTIONS } from 'config/data-grid';
import DataGridFooterWithSelectedCount from 'components/DataGridFooterWithSelectedCount';
import { useStyles, StyledDataGrid } from './ServerPaginatedDataGrid.styles';

LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);

const TableHeader = ({ heading }) => (
  <Typography component="h2" variant="h3">
    {heading}
  </Typography>
);

const BulkActions = ({ actions, classes, totalSelected }) => {
  if (!actions || actions.length <= 0 || totalSelected <= 0) {
    return null;
  }

  return (
    <div>
      {actions.map((action, index) => (
        <Button
          className={action.color == 'danger' ? `${classes.actionButton} ${classes.dangerButton}` : classes.actionButton}
          color={action.color == 'danger' ? 'primary' : action.color}
          key={index}
          size="small"
          onClick={action.onClick} // eslint-disable-line react/jsx-handler-names
        >
          {action.name}
        </Button>
      ))}
    </div>
  );
};

const Error = ({ classes, error, heading }) => (
  <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12 ga-m-vertical--large">
    {heading && (
      <div className={classes.headingContainer}>
        <TableHeader heading={heading} />
      </div>
    )}

    <Box className={classes.grayInfoBox}>
      <Typography color="error" variant="h3">{error}</Typography>
    </Box>
  </div>
);

const Loading = ({ classes, heading }) => (
  <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12 ga-m-vertical--large">
    {heading && (
      <div className={classes.headingContainer}>
        <TableHeader heading={heading} />
      </div>
    )}

    <Box className={classes.grayInfoBox}>
      <CircularProgress />
    </Box>
  </div>
);

export const ServerPaginatedDataGrid = ({
  bulkActions,
  checkboxSelection,
  columns,
  emptyStateText,
  error,
  filterModel,
  getRowClassName,
  heading,
  isError,
  isFetching,
  isLoading,
  isTest,
  onColumnVisibilityChange,
  onFilterModelChange,
  onPageChange,
  onPageSizeChange,
  onSelectionModelChange,
  onSortModelChange,
  page,
  pageSelectedCount,
  pageSize,
  rowCount,
  rows,
  selectionModel,
  sortModel,
  totalSelectedCount,
}) => {
  const classes = useStyles();

  if (isLoading) {
    return <Loading classes={classes} heading={heading} />;
  }

  if (isError) {
    return <Error classes={classes} error={error} heading={heading} />;
  }

  return (
    <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12 ga-m-vertical--large">
      {(heading || (bulkActions && bulkActions.length > 0)) && (
        <div className={classes.headingContainer}>
          {heading && <TableHeader heading={heading} />}

          {bulkActions && bulkActions.length > 0 && (
            <BulkActions
              actions={bulkActions}
              classes={classes}
              totalSelected={totalSelectedCount}
            />
          )}
        </div>
      )}
      {/* Show fetching or empty state when the data grid hasn't been loaded yet */}
      {(!rows || rows.length <= 0) ? (
        <Box className={classes.grayInfoBox}>
          {isFetching ? (
            <CircularProgress />
          ) : (
            <Typography variant="h3">
              {emptyStateText}
            </Typography>
          )}
        </Box>
      ) : (
        <div className={classes.tableContainer}>
          <StyledDataGrid
            autoHeight={true}
            checkboxSelection={checkboxSelection}
            className={classes.table}
            columns={columns}
            components={{
              Footer: DataGridFooterWithSelectedCount,
            }}
            componentsProps={{
              footer: {
                totalSelectedCount,
                pageSelectedCount,
              },
            }}
            disableColumnFilter
            disableSelectionOnClick
            disableVirtualization={isTest}
            filterModel={filterModel}
            getRowClassName={getRowClassName}
            loading={isFetching}
            onFilterModelChange={(model) => onFilterModelChange(model)}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            onSelectionModelChange={onSelectionModelChange}
            onSortModelChange={(model) => onSortModelChange(model)}
            page={page}
            pageSize={pageSize}
            pagination
            paginationMode="server"
            rowCount={rowCount}
            rows={rows}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            selectionModel={selectionModel}
            sortModel={sortModel}
            onColumnVisibilityChange={onColumnVisibilityChange}
          />
        </div>
      )}
    </div>
  );
};

TableHeader.propTypes = {
  heading: PropTypes.string,
};

BulkActions.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.oneOf(['primary', 'secondary', 'danger']),
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  classes: PropTypes.object,
  totalSelected: PropTypes.number,
};

Error.propTypes = {
  classes: PropTypes.object,
  error: PropTypes.string,
  heading: PropTypes.string,
};

Loading.propTypes = {
  classes: PropTypes.object,
  heading: PropTypes.string,
};

ServerPaginatedDataGrid.propTypes = {
  bulkActions: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.oneOf(['primary', 'secondary', 'danger']),
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  checkboxSelection: PropTypes.bool,
  columns: PropTypes.array,
  emptyStateText: PropTypes.string,
  error: PropTypes.string,
  filterModel: PropTypes.shape({
    items: PropTypes.array,
    linkOperator: PropTypes.string,
  }),
  getRowClassName: PropTypes.func,
  heading: PropTypes.string,
  isError: PropTypes.bool,
  isFetching: PropTypes.bool,
  isLoading: PropTypes.bool,
  isTest: PropTypes.bool,
  onFilterModelChange: PropTypes.func,
  onPageChange: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  onSelectionModelChange: PropTypes.func,
  onSortModelChange: PropTypes.func,
  page: PropTypes.number,
  pageSelectedCount: PropTypes.number,
  pageSize: PropTypes.number,
  rowCount: PropTypes.number,
  rows: PropTypes.arrayOf(PropTypes.object),
  selectionModel: PropTypes.array,
  sortModel: PropTypes.array,
  totalSelectedCount: PropTypes.number,
  onColumnVisibilityChange: PropTypes.func,
};

ServerPaginatedDataGrid.defaultProps = {
  bulkActions: [],
  checkboxSelection: false,
  emptyStateText: 'There are no matched rows in the system!',
  error: 'An error occurred while fetching data.',
  filterModel: { items: [], linkOperator: 'and' },
  getRowClassName: () => '',
  heading: '',
  isError: false,
  isFetching: false,
  isLoading: false,
  isTest: false,
  onFilterModelChange: () => {},
  onPageChange: () => {},
  onPageSizeChange: () => {},
  onSelectionModelChange: () => {},
  onSortModelChange: () => {},
  page: 0,
  pageSelectedCount: 0,
  pageSize: 50,
  rowCount: 0,
  selectionModel: [],
  sortModel: [],
  totalSelectedCount: 0,
  onColumnVisibilityChange: () => {},
};
