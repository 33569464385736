import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, Typography } from '@material-ui/core';
import { useStyles } from './CandidateNoMatch.styles';

const CandidateNoMatch = ({ messages, matchesUrl }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item>
        <Typography
          variant="body2"
          color="textSecondary"
          component="span"
        >
          {messages.finance?.empty}
        </Typography>
      </Grid>

      <Grid item className={classes.buttonContainer}>
        {/* We don't check for super admin here because the parent will not render this component unless the user is a super admin */}
        <Button
          variant="contained"
          color="secondary"
          href={matchesUrl}
        >
          {messages.finance?.actions?.find}
        </Button>
      </Grid>
    </Grid>
  );
};

CandidateNoMatch.propTypes = {
  messages: PropTypes.object.isRequired,
  matchesUrl: PropTypes.string,
};

CandidateNoMatch.defaultProps = {
  matchesUrl: '#',
};

export {
  CandidateNoMatch,
};
