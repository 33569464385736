import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';

const styles = {
  questionEmptyStateContainer: {
    backgroundColor: '#ededed',
    display: 'flex',
    gap: '1rem',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.4rem',
    color: '#999',
    padding: '3rem 10rem',
    borderRadius: '4px',
  },
  questionEmptyState: {
    textAlign: 'center',
  },
};

class BioQuestionSetsEmptyState extends React.Component {
  constructor(props) {
    super(props);

    this.handleCreate = this.handleCreate.bind(this);
  }

  handleCreate() {
    const { onCreate } = this.props;
    onCreate();
  }

  render() {
    const { classes, children, buttonText } = this.props;

    return (
      <div className={classes.questionEmptyStateContainer}>
        <div className={classes.questionEmptyState}>
          <p className="ga-m-bottom--medium"> {children} </p>
        </div>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={this.handleCreate}
        >
          {buttonText}
        </Button>
      </div>
    );
  }
}

BioQuestionSetsEmptyState.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object,
  buttonText: PropTypes.string,
  onCreate: PropTypes.func.isRequired,
};

BioQuestionSetsEmptyState.defaultProps = {
  buttonText: 'Create',
};

export default withStyles(styles)(BioQuestionSetsEmptyState);
