import { useEffect } from 'react';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import useCampaignFinanceStore from 'components/features/campaign-finance/stores/useCampaignFinanceStore';
import useUserRoleStore from 'components/stores/useUserRoleStore';

/**
 * Initializes the store with the provided values for enableCampaignFinance and isSuperAdmin.
 *
 * @param {boolean} props.isSuperAdmin - Indicates whether the user is a super admin. Delivered from the server.
 * @returns {null}
 */
const GlobalStateInitializer = ({ isSuperAdmin }) => {
  const { setCampaignFinanceStatus, campaignFinanceInitialized, setCampaignFinanceInitialized } = useCampaignFinanceStore();
  const { setSuperAdmin } = useUserRoleStore();

  const cfFeatureFlagQuery = useFeatureFlag('campaign-finance');

  useEffect(() => {
    setSuperAdmin(isSuperAdmin);
  }, [isSuperAdmin]);

  useEffect(() => {
    if (cfFeatureFlagQuery.isSuccess && cfFeatureFlagQuery.data && !campaignFinanceInitialized) {
      setCampaignFinanceInitialized(true);
      setCampaignFinanceStatus(cfFeatureFlagQuery.data?.data === 'true' || cfFeatureFlagQuery.data?.data === true);
    }
  }, [cfFeatureFlagQuery]);

  return null;
};

export default GlobalStateInitializer;
