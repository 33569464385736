import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#f7f7f7',
  },
  container: {
    padding: '.4rem 0',
  },
}));

export default function CTA(props) {
  const { children } = props;
  const classes = useStyles();

  return (
    <aside className={`${classes.root} full-width`}>
      <div className="ga-container">
        <div className="mdc-layout-grid">
          <div className={`${classes.container} mdc-layout-grid__cell mdc-layout-grid__cell--span-12`}>
            {children}
          </div>
        </div>
      </div>
    </aside>
  );
}

CTA.propTypes = {
  children: PropTypes.node,
};

CTA.defaultProps = {
  children: {},
};
