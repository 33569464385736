import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  districtRow: {
    borderBottom: '1px solid #e5e5e5',
    padding: '1rem 0',
  },
  actions: {
    paddingRight: '1rem',
    '& > *': {
      marginLeft: '1rem',
    },
  },
  danger: {
    color: '#ac1b3d',
    '&:hover': {
      color: '#6B1126',
    },
  },
});

export default function GISDistrictListRow({
  disabled,
  errors,
  hasFocus,
  id,
  name,
  onChange,
  onDelete,
  onSubmit,
  style,
}) {
  const classes = useStyles();
  const hasError = errors && Object.prototype.hasOwnProperty.call(errors, 'name');
  const getError = hasError ? errors.name : '';

  return (
    <Grid
      className={classes.districtRow}
      container
      alignItems="center"
      justifyContent="space-between"
      style={style}
    >
      <Grid item xs={8}>
        <TextField
          disabled={disabled}
          error={hasError}
          fullWidth
          helperText={getError}
          inputProps={{ 'aria-label': 'District Name' }}
          inputRef={(input) => {
            if (input != null && hasFocus) {
              input.focus();
            }
          }}
          required
          value={name}
          onChange={onChange}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={4}>
        <Grid
          className={classes.actions}
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button
            className={classes.danger}
            disabled={disabled}
            onClick={() => onDelete(id)}
          >
            Delete
          </Button>

          <Button
            color="secondary"
            disabled={disabled}
            onClick={onSubmit}
            variant="outlined"
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

GISDistrictListRow.propTypes = {
  disabled: PropTypes.bool,
  errors: PropTypes.object,
  hasFocus: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  onSubmit: PropTypes.func,
  style: PropTypes.object,
};

GISDistrictListRow.defaultProps = {
  disabled: false,
  errors: {},
  hasFocus: false,
  name: '',
  onChange: () => {},
  onDelete: () => {},
  onSubmit: () => {},
  style: {},
};
