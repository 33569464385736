import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import theme from 'scripts/theme';
import fetchUtil from 'helpers/Fetch';
import SnackbarAlert from 'components/SnackbarAlert';
import ConfirmationDialog from 'components/ConfirmationDialog';
import StepProgressSidebar from 'components/StepProgressSidebar';
import {
  StepSetUp,
  StepCategory,
  StepDescription,
  StepCandidates,
  StepBioQuestions,
  StepRaceQuestions,
  StepReview,
  StepSubmit,
  Stepper,
  StepTracker,
} from '.';
import { useStepState, useValidation } from './hooks';

const StepManagement = ({
  guideId,
  messages,
  raceClass,
  typeOptions,
}) => {
  const MIN_STEP = 1;
  const MAX_STEP = 8;
  // numeric value for each step
  const STEP_SET_UP = 1;
  const STEP_CATEGORY = 2;
  const STEP_DESCRIPTION = 3;
  const STEP_CANDIDATES = 4;
  const STEP_BIO_QUESTIONS = 5;
  const STEP_RACE_QUESTIONS = 6;
  const STEP_REVIEW = 7;
  const STEP_SUBMIT = 8;
  const _snackbar = useRef();

  // -----
  // STATE
  // -----
  const useStepStateValues = useStepState();
  const {
    race,
    setRace,
    step,
    setStep,
    openCancelDialog,
    setOpenCancelDialog,
    stepSetUpState,
    stepCategoryState,
    stepDescriptionState,
    stepCandidatesState,
    stepBioQuestionsState,
    stepRaceQuestionsState,
  } = useStepStateValues;

  const {
    skipValidations,
    backValidations,
    continueValidations,
    raceQuestionValidations,
  } = useValidation(useStepStateValues, messages);

  // ---------
  // END STATE
  // ---------

  // -------------
  // API FUNCTIONS
  // -------------

  // for first step - submits race name to the database
  // parameter determines what to do on success - true = exit stepper, false = go to next step
  const handleRaceNameSubmit = (shouldExit) => {
    stepSetUpState.setSubmittingName(true);
    stepSetUpState.setVotingOptionsErrors({});

    console.log('Submit race: ', stepSetUpState.name);

    const postBody = {
      name: stepSetUpState.name,
      raceClass: raceClass.id,
      choose: stepSetUpState.votingOption === 0 ? null : stepSetUpState.numberOfCandidates,
      state: RACE_TYPES[stepSetUpState.typeOfRace].id,
      votingSystem: VOTING_OPTIONS[stepSetUpState.votingOption].id,
      district: (stepSetUpState.district && Object.keys(stepSetUpState.district).length > 0) ? {
        id: stepSetUpState.district.id,
      } : {},
    };

    const doResultingAction = () => {
      if (shouldExit) {
        window.location.assign('/races');
      } else {
        setStep(step + 1);
      }
    };

    const handleError = error => {
      console.error(error);
      stepSetUpState.setAddNameErrors(error);
      onError('add', false);
      stepSetUpState.setSubmittingName(false);
    };

    const handleSuccess = response => {
      console.log(race);
      console.log('Success: ', response);
      setRace(response);
      stepSetUpState.setSubmittingName(false);
      doResultingAction();
    };

    if (!race.id) {
      fetchUtil(`/api/v1/guide/${guideId}/race`, 'POST', postBody)
        .then((response) => {
          handleSuccess(response);
        })
        .catch((error) => {
          if (error.errors && error.errors[0] && error.errors[0].validationErrors) {
            handleError(error.errors[0].validationErrors);
          } else {
            handleError(error);
          }
        });
    } else if (race.name && (race.name !== stepSetUpState.name)) {
      postBody.id = race.id;
      // this means the race name already exists, but it was edited after going back in
      fetchUtil(`/api/v1/guide/${guideId}/race/${race.id}`, 'PUT', postBody)
        .then((response) => {
          handleSuccess(response);
        })
        .catch((error) => {
          handleError(error);
        });
    } else {
      stepSetUpState.setSubmittingName(false);
      doResultingAction();
    }
  };

  // step 2 - add new category from that form
  const handleNewCategorySubmit = (callback) => {
    console.log(
      `Sumbitted category: ${stepCategoryState.customCategoryInputs.name} - ${stepCategoryState.customCategoryInputs.description}`,
    );
    stepCategoryState.setSubmittingCustomCategory(true);
    fetchUtil(`/api/v1/guide/${guideId}/category`, 'POST', {
      name: stepCategoryState.customCategoryInputs.name,
      description: stepCategoryState.customCategoryInputs.description,
    })
      .then((response) => {
        console.log('Success: ', response);
        stepCategoryState.setSubmittingCustomCategory(false);
        stepCategoryState.setCustomCategory(response.index);
        callback(response.index);
      })
      .catch((error) => {
        console.error(error);
        stepCategoryState.setCustomCategoryErrors(error);
        onError(
          '',
          'Category could not be submitted. Please correct any errors and try again.',
        ); // <-- slap a message from the messages file in there
        stepCategoryState.setSubmittingCustomCategory(false);
      });
  };

  // step 2 - able to delete category that was just added
  const handleNewCategoryDelete = (category, callback) => {
    console.log('Cat to delete: ', category);
    fetchUtil(`/api/v1/guide/${guideId}/category/${category.id}`, 'DELETE', {})
      .then((response) => {
        console.log('Success: ', response);
        stepCategoryState.setCustomCategory(null);
        stepCategoryState.setCustomCategoryInputs({
          name: '',
          description: '',
        });
        callback();
      })
      .catch((error) => {
        console.error(error);
        onError('delete', false);
      });
  };

  const getQuestionSetFromState = () => {
    if (stepRaceQuestionsState.raceQuestionDecision == 0) {
      return {
        id: stepRaceQuestionsState.selectedRQS.id,
        name: stepRaceQuestionsState.selectedRQS.name,
      };
    }
    console.log('New set questions: ', stepRaceQuestionsState.newRQSQuestions);
    return {
      name: stepRaceQuestionsState.newRQSTitle,
      raceQuestions: stepRaceQuestionsState.newRQSQuestions.map(q => (
        {
          body: q.body,
          maxQuestionLength: q.maxQuestionLength,
          type: q.type,
          translations: Object.keys(q.translations).map(t => (
            { key: t, body: q.translations[t] }
          )),
        }
      )),
    };
  };

  // we do not need to check for value == 2, as this function will not be called in that case
  const getBioQuestionSetFromState = () => {
    if (stepBioQuestionsState.bioDecision == 0) {
      return {
        id: stepBioQuestionsState.bioSetChoice.id,
        name: stepBioQuestionsState.bioSetChoice.name,
      };
    }
    return {
      name: stepBioQuestionsState.newSetTitle,
      questions: stepBioQuestionsState.newSetQuestions,
    };
  };

  // mapping function for turning candidates list into backend style
  const formatCandidate = c => (
    {
      name: c.name,
      lastName: c.lastName,
      contactName: c.contactUser.name,
      contactEmail: c.contactUser.email,
      partiesIds: c.parties.map(p => p.id),
      candidateClass: raceClass.id === 'R' ? 'C' : 'R',
      inFavor: raceClass.id === 'R' ? null : c.inFavor,
    }
  );

  const removeEmptyCandidates = c => (
    typeof c.name != 'undefined' && c.name != null && c.name != ''
  );

  const getCategoryFromState = () => (
    stepCategoryState.categories[stepCategoryState.selectedCategory].id == -1 ? null : stepCategoryState.categories[stepCategoryState.selectedCategory].id
  );

  // submit full race on step 7. use onSuccess callback to go to step 8, otherwise set errors here
  const handleRaceSubmit = onSuccess => {
    console.log('Description: ', stepDescriptionState.savedDescription);
    const data = {
      id: race.id,
      name: stepSetUpState.name,
      raceClass: raceClass.id,
      choose: stepSetUpState.votingOption === 0 ? null : stepSetUpState.numberOfCandidates,
      description: stepDescriptionState.savedDescription,
      state: RACE_TYPES[stepSetUpState.typeOfRace].id,
      votingSystem: VOTING_OPTIONS[stepSetUpState.votingOption].id,
      questionSet: getQuestionSetFromState(),
      district: (stepSetUpState.district && Object.keys(stepSetUpState.district).length > 0) ? {
        id: stepSetUpState.district.id,
      } : {},
      category: {
        id: getCategoryFromState(),
      },
      candidates: stepCandidatesState.candidateList.filter(removeEmptyCandidates).map(formatCandidate),
    };
    if (stepBioQuestionsState.bioDecision != 2) {
      data.bioQuestionSet = getBioQuestionSetFromState();
    }

    console.log('Data to submit: ', data);
    fetchUtil(`/api/v1/guide/${guideId}/race/${race.id}`, 'PUT', data)
      .then((response) => {
        console.log('Race added successfully: ', response);
        onSuccess();
      })
      .catch((error) => {
        console.error(error);
        onError('none', error.data);
      });
  };

  // -----------------
  // END API FUNCTIONS
  // -----------------

  // --------------
  // ERROR HANDLING
  // --------------

  const onError = (action, message) => {
    const addErrorMessage = raceClass.id === 'R' ? messages.stepSetUp.raceName.add.error : messages.stepSetUp.measureName.add.error;

    let snackbarMessage;
    _snackbar.current.hide();

    if (message) {
      _snackbar.current.show('error', message);
      return;
    }

    switch (action) {
      case 'add':
        snackbarMessage = addErrorMessage;
        break;
      default:
        snackbarMessage = 'An unknown error occurred. Please contact your guide admin.';
        break;
    }

    _snackbar.current.show('error', snackbarMessage);
  };

  // ------------------
  // END ERROR HANDLING
  // ------------------

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  // ---------
  // CONSTANTS
  // ---------

  const RACE_TYPES = [
    {
      id: 2,
      title: messages.stepSetUp.type.options.generalElection.title,
      description: messages.stepSetUp.type.options.generalElection.supporting,
      value: 0,
    },
    {
      id: 2,
      title: messages.stepSetUp.type.options.openPrimary.title,
      description: messages.stepSetUp.type.options.openPrimary.supporting,
      value: 1,
    },
    {
      id: 1,
      title: messages.stepSetUp.type.options.closedPrimary.title,
      description: messages.stepSetUp.type.options.closedPrimary.supporting,
      value: 2,
    },
  ];

  const VOTING_OPTIONS = [
    {
      id: 1,
      title: messages.stepSetUp.voting.options.singleVote.title,
      description: messages.stepSetUp.voting.options.singleVote.supporting,
      value: 0,
      additionalQuestion: false,
    },
    {
      id: 1,
      title: messages.stepSetUp.voting.options.multipleSelection.title,
      description:
        messages.stepSetUp.voting.options.multipleSelection.supporting,
      value: 1,
      additionalQuestion:
        messages.stepSetUp.voting.additionalQuestion.multipleSelection, // key in the messages file for label changing
    },
    {
      id: 2,
      title: messages.stepSetUp.voting.options.rankedChoice.title,
      description: messages.stepSetUp.voting.options.rankedChoice.supporting,
      value: 2,
      additionalQuestion:
        messages.stepSetUp.voting.additionalQuestion.rankedChoice, // key in the messages file for label changing
    },
  ];

  const BIO_SET_OPTIONS = [
    {
      title: messages.stepBioQuestions.options.useExisting.title,
      value: 0,
    },
    {
      title: messages.stepBioQuestions.options.createNew.title,
      value: 1,
    },
    {
      title: messages.stepBioQuestions.options.allBioQuestions.title,
      value: 2,
    },
  ];

  const RACE_QUESTION_SET_OPTIONS = [
    {
      title: messages.stepRaceQuestions.options.useExisting.title,
      description: messages.stepRaceQuestions.options.useExisting.description,
      value: 0,
    },
    {
      title: messages.stepRaceQuestions.options.createNew.title,
      description: messages.stepRaceQuestions.options.createNew.description,
      value: 1,
    },
  ];

  // -------------
  // END CONSTANTS
  // -------------

  // --------------
  // STEPPER CONFIG
  // --------------

  const stepperVisibility = {
    1: ['cancel', 'saveExit', 'saveContinue'],
    2: ['back', 'cancel', 'continue'],
    3: ['back', 'cancel', 'continue'],
    4: ['back', 'cancel', 'skip', 'continue'],
    5: ['back', 'cancel', 'continue'],
    6: ['back', 'cancel', 'continue'],
    7: ['back', 'cancel', 'saveRace'],
    8: ['viewEdit', 'createNew'],
  };

  const confirmCancelDialog = () => {
    window.location.assign('/races');
  };

  const handleStepperBack = () => {
    const canBack = backValidations(step, MIN_STEP);
    if (canBack) {
      setStep(step - 1);
    }
  };

  const handleStepperCancel = () => {
    if (step == 1) {
      confirmCancelDialog();
    } else {
      setOpenCancelDialog(true);
    }
  };

  const handleStepperSkip = () => {
    const canSkip = skipValidations(step, MAX_STEP);
    if (canSkip) {
      setStep(step + 1);
    }
  };

  const handleStepperContinue = () => {
    if (step < MAX_STEP) {
      const validationResult = continueValidations(step);
      if (validationResult.success) {
        setStep(step + 1);
      } else {
        // handle error
        console.log(validationResult.error);
        if (validationResult.message !== '') {
          onError('', validationResult.message);
        }
      }
    }
  };

  const handleStepperSaveExit = () => {
    if (step === STEP_SET_UP) {
      const validationResult = continueValidations(step);
      if (validationResult.success) {
        handleRaceNameSubmit(true);
      }
    }
  };

  const handleStepperSaveContinue = () => {
    if (step === STEP_SET_UP) {
      const validationResult = continueValidations(step);
      if (validationResult.success) {
        handleRaceNameSubmit(false);
      } else {
        // handle error
        console.log(validationResult.error);
        if (validationResult.message !== '') {
          onError('', validationResult.message);
        }
      }
    }
  };

  const handleStepperSaveRace = () => {
    if (step === STEP_REVIEW) {
      handleRaceSubmit(() => {
        setStep(8);
      });
    }
  };

  const handleStepperViewEdit = () => {
    window.location.replace(`/races/${race.id}`);
  };

  const handleStepperCreateNew = () => {
    window.location.assign('/races/create');
  };

  const handleStepperCreateNewMeasure = () => {
    window.location.assign('/measures/create');
  };

  const goToStep = (stepTarget) => {
    if (stepTarget >= MIN_STEP && stepTarget <= MAX_STEP) {
      setStep(stepTarget);
    } else {
      console.error('Invalid step given: ', stepTarget);
    }
  };

  // ------------------
  // END STEPPER CONFIG
  // ------------------

  // --------------------
  // START SIDEBAR CONFIG
  // --------------------

  const sidebarSteps = [
    { name: raceClass.id === 'R' ? 'Race Overview' : 'Measure Overview' },
    { name: 'Category' },
    { name: 'Description' },
    { name: 'Candidates' },
    { name: 'Biographical Questions' },
    { name: raceClass.id === 'R' ? 'Race Questions' : 'Measure Questions' },
    { name: 'Review' },
    { name: 'Submit' },
  ];

  // ------------------
  // END SIDEBAR CONFIG
  // ------------------

  return (
    <ThemeProvider theme={theme}>
      <SnackbarAlert ref={_snackbar} />

      <ConfirmationDialog
        cancelButtonText={messages.stepper.cancelDialog.cancel}
        confirmButtonText={messages.stepper.cancelDialog.confirm}
        heading={messages.stepper.cancelDialog.heading}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => confirmCancelDialog()}
        open={openCancelDialog}
      >
        <Typography component="p" variant="body1">
          {messages.stepper.cancelDialog.body}
        </Typography>
      </ConfirmationDialog>

      <Box>
        <Grid container spacing={3} alignItems="stretch">
          <Grid item xs={3}>
            <Box sx={{
              backgroundColor: '#f7f7f7',
              paddingTop: '3rem',
              height: '100%',
            }}>
              <StepProgressSidebar
                items={sidebarSteps}
                currentIndex={step - 1}
                isOrdered={true}
              />
            </Box>
          </Grid>

          <Grid item xs={9}>
            <Box sx={{ position: 'relative', paddingTop: '3rem' }}>
              {step === STEP_SET_UP && (
                <StepSetUp
                  guideId={guideId}
                  messages={messages.stepSetUp}
                  raceClassId={raceClass.id}
                  raceTypes={RACE_TYPES}
                  stepState={stepSetUpState}
                  votingOptions={VOTING_OPTIONS}
                />
              )}
              {step === STEP_CATEGORY && (
                <StepCategory
                  stepState={stepCategoryState}
                  raceClassId={raceClass.id}
                  messages={messages.stepCategory}
                  onError={onError}
                  guideId={guideId}
                  onNewCategory={handleNewCategorySubmit}
                  onDeleteCategory={handleNewCategoryDelete}
                />
              )}
              {step === STEP_DESCRIPTION && (
                <StepDescription
                  raceClassId={raceClass.id}
                  stepState={stepDescriptionState}
                  messages={messages.stepDescription}
                />
              )}
              {step === STEP_CANDIDATES && (
                <StepCandidates
                  raceClassId={raceClass.id}
                  stepState={stepCandidatesState}
                  messages={messages.stepCandidates}
                  guideId={guideId}
                />
              )}
              {step === STEP_BIO_QUESTIONS && (
                <StepBioQuestions
                  stepState={stepBioQuestionsState}
                  messages={messages.stepBioQuestions}
                  initialOptions={BIO_SET_OPTIONS}
                  guideId={guideId}
                  onError={onError}
                />
              )}
              {step === STEP_RACE_QUESTIONS && (
                <StepRaceQuestions
                  raceClassId={raceClass.id}
                  stepState={stepRaceQuestionsState}
                  messages={messages.stepRaceQuestions}
                  initialOptions={RACE_QUESTION_SET_OPTIONS}
                  guideId={guideId}
                  onError={onError}
                  typeOptions={typeOptions}
                  raceQuestionValidations={raceQuestionValidations}
                />
              )}
              {step === STEP_REVIEW && (
                <StepReview
                  bioSetOptions={BIO_SET_OPTIONS}
                  goToStep={goToStep}
                  messages={messages.review}
                  raceClassId={raceClass.id}
                  raceSetOptions={RACE_QUESTION_SET_OPTIONS}
                  raceTypes={RACE_TYPES}
                  state={useStepStateValues}
                  stepEnum={{
                    STEP_SET_UP,
                    STEP_CATEGORY,
                    STEP_DESCRIPTION,
                    STEP_CANDIDATES,
                    STEP_BIO_QUESTIONS,
                    STEP_RACE_QUESTIONS,
                    STEP_REVIEW,
                    STEP_SUBMIT,
                  }}
                  votingOptions={VOTING_OPTIONS}
                />
              )}
              {step === STEP_SUBMIT && (
                <StepSubmit
                  messages={messages.submit}
                  raceClassId={raceClass.id}
                />
              )}
              <Stepper
                messages={messages.stepper}
                onBack={handleStepperBack}
                onCancel={handleStepperCancel}
                onSkip={handleStepperSkip}
                onContinue={handleStepperContinue}
                onSaveExit={handleStepperSaveExit}
                onSaveContinue={handleStepperSaveContinue}
                onSaveRace={handleStepperSaveRace}
                onViewEdit={handleStepperViewEdit}
                onCreateNew={handleStepperCreateNew}
                onCreateNewMeasure={handleStepperCreateNewMeasure}
                raceClassId={raceClass.id}
                visibility={stepperVisibility[step]}
              />
              <StepTracker
                currentStep={step}
                totalSteps={MAX_STEP}
                messages={messages.tracker}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

StepManagement.propTypes = {
  guideId: PropTypes.string,
  messages: PropTypes.object.isRequired,
  typeOptions: PropTypes.array.isRequired,
  raceClass: PropTypes.shape({ id: PropTypes.string, name: PropTypes.string }),
};

StepManagement.defaultProps = {
  guideId: '-1',
};

export default StepManagement;
