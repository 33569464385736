export const SUCCESS_PARAM = 'showSuccessMsgFrom';

export const CAMPAIGN_FINANCE = 'campaign-finance-import';
export const DELETE_RACE = 'deleteRace';

export const MAIL_CENTER = {
  SEND_EMAIL: 'sendEmail',
  EDIT_TEMPLATE: 'editTemplate',
  DELETE_TEMPLATE: 'deleteTemplate',
  CREATE_TEMPLATE: 'createTemplate',
};
