import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { voterGuidePropType } from 'propTypes';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import affiliate from 'propTypes/affiliate';

const AdminCopyGuideModal = ({
  errors,
  open,
  onCancel,
  onSubmit,
  submitting,
  sourceGuide,
  affiliates,
}) => {
  const styles = {
    spinner: {
      position: 'absolute',
      top: '45%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
    actions: {
      justifyContent: 'flex-end',
    },
    actionButton: {
      marginLeft: '1rem',
    },
    modalTitle: {
      alignItems: 'flex-start',
    },
    sectionContainer: {
      margin: '1.5rem 0rem',
    },
  };

  const [canSubmit, setCanSubmit] = useState(false);
  const [selectedAffiliate, setSelectedAffiliate] = useState({});
  const [copyGuideForm, setCopyGuideForm] = useState({ affiliateId: undefined, name: '' });

  const hasError = (fieldName) => Object.prototype.hasOwnProperty.call(errors, fieldName);

  const listAffiliateMenuItems = () => {
    const options = [];

    Object.values(affiliates).forEach(aff => {
      options.push(<MenuItem key={aff.id} value={aff}>{aff.name}</MenuItem>);
    });

    return options;
  };

  const handleAffiliateChanged = (val) => {
    setSelectedAffiliate(val);
    setCopyGuideForm({
      ...copyGuideForm,
      affiliateId: val.id,
    });
  };

  const handleNewGuideNameChanged = (val) => {
    setCopyGuideForm({
      ...copyGuideForm,
      name: val,
    });
  };

  const handleSave = () => {
    onSubmit(copyGuideForm);
  };

  const renderSpinner = () => {
    if (submitting) {
      return (
        <div style={styles.spinner}>
          <CircularProgress />
        </div>
      );
    }
  };

  useEffect(() => {
    setCanSubmit(true);

    if (!copyGuideForm) {
      setCanSubmit(false);
    }

    if (!copyGuideForm.affiliateId) {
      setCanSubmit(false);
    }

    if (!copyGuideForm.name) {
      setCanSubmit(false);
    }
  }, [errors, copyGuideForm]);

  return (
    <Dialog
      aria-labelledby="copy-guide-dialog"
      maxWidth="md"
      onClose={onCancel}
      open={open}
    >
      <DialogTitle
        style={styles.modalTitle}
        disableTypography
        id="copy-guide-dialog"
      >
        <span>
          <Typography component="h1" variant="h1">
            Guide Copy
          </Typography>

          <Typography component="p" variant="body1">
            Create a copy of a guide
          </Typography>
        </span>

        <IconButton aria-label="close" onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      { renderSpinner() }
      <DialogContent>
        <Typography component="p" variant="h3" paragraph>
          Source guide is: {sourceGuide?.voterGuide?.name}
        </Typography>

        <div style={styles.sectionContainer}>

          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <FormControl
                disabled={submitting}
                error={hasError('name')}
                fullWidth
                variant="filled"
              >
                <InputLabel id="race-type-label">
                  Select the owning affiliate:
                </InputLabel>

                <Select
                  id="affiliate-selection"
                  labelId="affiliate-selection-form-field-label"
                  onChange={(event) => handleAffiliateChanged(event.target.value)}
                  value={selectedAffiliate}
                >
                  {listAffiliateMenuItems()}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <TextField
                  id="copy-guide-new-name"
                  disabled={submitting}
                  error={hasError('name')}
                  InputProps={{ inputProps: { min: 2, max: 30 } }}
                  label="New Guide Name"
                  onChange={(event) => handleNewGuideNameChanged(event.target.value)}
                  required
                  value={copyGuideForm?.name}
                  variant="filled"
                />
              </FormControl>
            </Grid>
          </Grid>
        </div>
      </DialogContent>

      <DialogActions style={styles.modalActions}>
        <Button onClick={onCancel}>
          Cancel
        </Button>

        <Button
          style={styles.actionButton}
          color="secondary"
          disabled={submitting || !canSubmit}
          onClick={handleSave}
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AdminCopyGuideModal.propTypes = {
  errors: PropTypes.object,
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  sourceGuide: voterGuidePropType.isRequired,
  affiliates: PropTypes.arrayOf(affiliate),
};

AdminCopyGuideModal.defaultProps = {
  errors: {},
  open: false,
  onCancel: () => {
  },
  onSubmit: () => {
  },
  submitting: false,
};

export default AdminCopyGuideModal;
