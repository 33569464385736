import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  TextField,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles({
  formContainer: {
    padding: '0rem 2rem',
  },
  addFormActions: {
    backgroundColor: '#eef4f8',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1.5rem 2rem',
    width: '100%',
  },
  cardContainerNoOutline: {
    border: 'none',
    boxShadow: 'none',
  },
  inputField: {
    width: '50%',
    margin: '1.3rem 0rem',
  },
  createButton: {
    whiteSpace: 'nowrap',
  },
});

const BioQuestionSetEditForm = ({
  buttonText,
  children,
  errors,
  id,
  name,
  onChange,
  onSubmit,
  outlined,
  requiredText,
  submitting,
}) => {
  const classes = useStyles();

  const hasError = (fieldName) => errors && Object.prototype.hasOwnProperty.call(errors, fieldName);

  const cardContainerClass = outlined ? '' : classes.cardContainerNoOutline;

  return (
    <Card variant="outlined" className={cardContainerClass}>
      <CardContent className={classes.formContainer}>
        <div className={classes.inputField}>
          <FormControl fullWidth>
            <TextField
              id="id"
              disabled={submitting}
              error={hasError('name')}
              helperText={hasError('name') ? errors.name : ''}
              label="Bio Question Set Name"
              name="name"
              required
              variant="filled"
              onChange={onChange}
              value={name}
              inputProps={{
                maxLength: 60,
              }}
            />
          </FormControl>
        </div>
      </CardContent>

      <CardActions className={classes.addFormActions}>
        <Typography component="p" variant="body2">
          {requiredText}
        </Typography>

        <div>
          {children}

          <Button
            className={classes.createButton}
            variant="contained"
            color="secondary"
            onClick={() => onSubmit(id, name)}
            size="large"
            disabled={submitting}
          >
            {buttonText}
          </Button>
        </div>
      </CardActions>
    </Card>
  );
};

BioQuestionSetEditForm.propTypes = {
  buttonText: PropTypes.string,
  children: PropTypes.node,
  errors: PropTypes.object,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  outlined: PropTypes.bool,
  requiredText: PropTypes.string,
  submitting: PropTypes.bool,
};

BioQuestionSetEditForm.defaultProps = {
  buttonText: 'Add Set',
  errors: {},
  name: '',
  onChange: () => {},
  onSubmit: () => {},
  outlined: false,
  requiredText: '*Required fields needed to add a new question set',
  submitting: false,
};

export default BioQuestionSetEditForm;
