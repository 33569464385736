import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import LanguageTabsInput from 'components/LanguageTabsInput';

const styles = {
  addFormContainer: {
    display: 'flex',
    margin: '1rem',
  },
  addFormAbbrField: {
    marginRight: '2rem',
    width: '20rem',
  },
  addFormActions: {
    backgroundColor: '#eef4f8',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '.8rem 2rem',
  },
  cardContainerNoOutline: {
    border: 'none',
    boxShadow: 'none',
  },
};

const defaultFormValues = {
  body: '',
  type: '',
  maxQuestionLength: '',
};

class AddQuestionForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: defaultFormValues,
      showMaxLengthField: false,
      submitting: false,
    };

    this._languageTabsInput = React.createRef();

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTranslationChange = this.handleTranslationChange.bind(this);
  }

  // Public method that resets form values (for example, after successful submit)
  reset() {
    this.setState({
      form: defaultFormValues,
    }, () => this._languageTabsInput.current.reset());
  }

  setMaxLengthVisibility() {
    const { typeOptions } = this.props;
    const { form } = this.state;
    const { type } = form;
    const newForm = { ...form };
    const matchedType = typeOptions.find(option => option.id === type);
    let showMaxLengthField = false;

    if (matchedType && matchedType.defaultMaxLength) {
      newForm.maxQuestionLength = matchedType.defaultMaxLength;
      showMaxLengthField = true;
    }

    this.setState({
      form: newForm,
      showMaxLengthField,
    });
  }

  handleInputChange(event) {
    const { name } = event.target;
    const { value } = event.target;
    const { form } = this.state;
    const newForm = { ...form };

    newForm[name] = value;

    this.setState({
      form: newForm,
    }, () => {
      if (name === 'type') {
        this.setMaxLengthVisibility();
      }
    });
  }

  handleTranslationChange(data) {
    const { form } = this.state;
    const newForm = { ...form };

    Object.keys(data).forEach(key => {
      newForm[key] = data[key];
    });

    this.setState({
      form: newForm,
    });
  }

  handleSubmit(event) {
    const { onSubmit } = this.props;
    const { form } = this.state;
    onSubmit(form);
    console.log('handle submit', event);
  }

  hasError(fieldName) {
    const { errors } = this.props;
    return Object.prototype.hasOwnProperty.call(errors, fieldName);
  }

  renderTypeOptions() {
    const { typeOptions } = this.props;

    if (typeOptions && typeOptions.length > 0) {
      return typeOptions.map(type => (
        <MenuItem
          key={type.id}
          value={type.id}
        >
          {type.name}
        </MenuItem>
      ));
    }
  }

  renderMaxLengthField() {
    const { form, showMaxLengthField } = this.state;
    const { errors, submitting } = this.props;

    if (showMaxLengthField) {
      return (
        <FormControl fullWidth>
          <TextField
            disabled={submitting}
            error={this.hasError('maxQuestionLength')}
            helperText={this.hasError('maxQuestionLength') ? errors.maxQuestionLength : ''}
            id="maxQuestionLength"
            label="Answer Character Limit"
            name="maxQuestionLength"
            onChange={this.handleInputChange}
            required
            type="number"
            value={form.maxQuestionLength}
            variant="filled"
          />
        </FormControl>
      );
    }
  }

  render() {
    const {
      classes, errors, languages, submitting,
    } = this.props;
    const { form } = this.state;

    return (
      <Card variant="outlined">
        <CardContent className={classes.addFormContainer}>
          <Grid container>
            <Grid item xs={5}>
              <div className="ga-m-bottom--large">
                <FormControl
                  disabled={submitting}
                  error={this.hasError('type')}
                  fullWidth
                  variant="outlined"
                >
                  <Select
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    name="type"
                    onChange={this.handleInputChange}
                    value={form.type}
                  >
                    <MenuItem value="">
                      <em>Select a Question Type</em>
                    </MenuItem>

                    {this.renderTypeOptions()}
                  </Select>

                  <FormHelperText>
                    {this.hasError('type') ? errors.type : ''}
                  </FormHelperText>
                </FormControl>
              </div>

              {this.renderMaxLengthField()}
            </Grid>
            <Grid item xs={12}>
              <LanguageTabsInput
                ref={this._languageTabsInput}
                body={form.body}
                errors={errors}
                languages={languages}
                onChange={this.handleTranslationChange}
                submitting={submitting}
                translations={form}
              />
            </Grid>
          </Grid>
        </CardContent>

        <CardActions className={classes.addFormActions}>
          <div>
            <Button
              variant="contained"
              color="secondary"
              onClick={this.handleSubmit}
              size="large"
              disabled={submitting}
            >
              Add Question
            </Button>
          </div>

        </CardActions>
      </Card>
    );
  }
}

AddQuestionForm.propTypes = {
  classes: PropTypes.object,
  errors: PropTypes.object,
  languages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
  })),
  onSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  typeOptions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    defaultMaxLength: PropTypes.number,
    localizable: PropTypes.bool,
    scaleType: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      prefix: PropTypes.string,
    }),
    active: PropTypes.bool,
  })).isRequired,
};

AddQuestionForm.defaultProps = {
  errors: {},
  languages: [],
  onSubmit: () => {},
  submitting: false,
};

export default withStyles(styles)(AddQuestionForm);
