import Errors from 'helpers/Errors';

export default class Form {
  constructor(data, options = {}) {
    this.originalData = data;
    this.errors = new Errors();

    // this.resetOnSubmit = resetOnSubmit;
    this.resetOnSubmit = true;
    this.validationMessage = 'There was an issue submitting the form. Fix all errors and try submitting again.';

    if (options) {
      if (Object.prototype.hasOwnProperty.call(options, 'resetOnSubmit')) {
        this.resetOnSubmit = options.resetOnSubmit;
      }

      if (Object.prototype.hasOwnProperty.call(options, 'validationMessage')) {
        this.validationMessage = options.validationMessage;
      }
    }

    // Convert data fields into properties of the form
    Object.keys(data).forEach((key) => {
      this[key] = data[key];
    });
  }

  reset() {
    Object.keys(this.originalData).forEach((key) => {
      this[key] = this.originalData[key];
    });

    this.errors.clear();
  }

  data() {
    const data = {};

    Object.keys(this.originalData).forEach((key) => {
      data[key] = this[key];
    });

    return data;
  }

  empty() {
    const data = this.data();
    let isEmpty = true;

    Object.values(data).forEach((value) => {
      if (value) {
        isEmpty = false;
      }
    });

    return isEmpty;
  }

  submit(requestType, url, data) {
    // Allow form data to be overridden if manual manipulation is needed before submission
    const formData = data || this.data();

    return new Promise((resolve, reject) => {
      const csrfToken = document.querySelector('input[name="csrfToken"]').value;

      if (!csrfToken) {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject('Invalid or missing CSRF token');
      }

      fetch(url, {
        method: requestType,
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
          'Csrf-Token': csrfToken,
        },
        body: JSON.stringify(formData),
      })
        .then(response => {
          const { status } = response;

          // If we get a 403, we assume user has been logged out and
          // redirect them to the login page.
          if (status === 403) {
            window.location.replace('/login');
          }

          if (response.redirected == true) {
            window.location.replace(response.url);
          }

          if (response.ok) {
            console.log('SUCCESS: ', response);

            response.json().then(res => {
              this.onSuccess();
              resolve(res.data);
            });
          } else {
            response.json().then(res => {
              if (status === 422 && Object.prototype.hasOwnProperty.call(res, 'errors') && Object.prototype.hasOwnProperty.call(res.errors[0], 'validationErrors')) {
                this.onFail(res.errors[0].validationErrors);
                return reject(this.validationMessage);
              }

              // TODO: Pass along actual error message from backend
              reject();
            });
          }
        })
        .catch(error => {
          console.log('CATCH', error);
          reject(error);
        });
    });
  }

  onSuccess() {
    if (this.resetOnSubmit) {
      this.reset();
    }
  }

  onFail(errors) {
    this.errors.set(errors);
  }

  post(url) {
    return this.submit('POST', url);
  }

  delete(url) {
    return this.submit('DELETE', url);
  }

  patch(url) {
    return this.submit('PATCH', url);
  }
}
