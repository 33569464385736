import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const Error = ({ heading, content }) => <div>
  <Typography
    component="h2"
    variant="h1"
    style={{ wordBreak: 'break-all' }}
  >
    {heading}
  </Typography>
  <Typography
    component="p"
    variant="body1"
    color="textSecondary"
  >
    {content}
  </Typography>
</div>;

Error.propTypes = {
  content: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
};

Error.defaultProps = {
  heading: 'Uh oh!',
  content: 'An unknown error occurred. Please contact your guide admin.',
};

export default Error;
