import { apiClient } from '@/lib/axios';
import { useQuery } from '@tanstack/react-query';
import useCampaignFinanceStore from 'components/features/campaign-finance/stores/useCampaignFinanceStore';

export const getCampaignFinanceRecordsByCandidateId = (candidateId) => apiClient
  .get(`candidates/${candidateId}/campaign-finances`)
  .then((response) => response.data);

export const useCandidateCampaignFinanceRecords = ({ candidateId }) => {
  const { enableCampaignFinance } = useCampaignFinanceStore();
  return useQuery({
    queryKey: ['candidateCampaignFinance', candidateId],
    queryFn: () => getCampaignFinanceRecordsByCandidateId(candidateId),
    enabled: !!candidateId && enableCampaignFinance,
  });
};
