import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Button,
  Container,
  Grid,
  Menu,
  MenuItem,
  ThemeProvider,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import BugReportIcon from '@material-ui/icons/BugReport';
import HelpIcon from '@material-ui/icons/Help';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ConfirmationDialog from 'components/ConfirmationDialog';
import ImportModalContainer from 'components/ImportModalContainer';
import { useFeatureFlags } from '@/hooks/useFeatureFlag';
import { useQueryParam } from '@/hooks/useQueryParam';
import { CAMPAIGN_FINANCE, SUCCESS_PARAM } from 'helpers/SuccessMessageParams';
import { AppSnackbarContext } from 'components/AppSnackbar';
import theme from 'scripts/theme';

const useStyles = makeStyles(({ breakpoints }) => ({
  appName: {
    color: '#FFF',
    fontWeight: 700,
  },
  navContainer: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    [breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  secondaryNav: {
    backgroundColor: '#07263b',
  },
  secondaryNavButton: {
    color: '#FFF',
    letterSpacing: '1.25px',
    opacity: 0.87,
    textTransform: 'uppercase',
    '&:hover': {
      opacity: 1,
    },
  },
  primaryNavItem: {
    color: '#FFF',
    fontSize: '14px',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
    '&.active': {
      fontWeight: 800,
    },
  },
  bugSubmitList: {
    marginLeft: '3rem',
  },
  bugSubmitHelpButton: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1rem',
    textDecoration: 'none',
  },
}));

function GlobalNavigation({
  campaignFinanceImportUrl,
  gisImportUrl,
  logoPath,
  messages,
  navItems,
  raceImportUrl,
  rootUrl,
  settings,
}) {
  const [settingsMenuEl, setSettingsMenuEl] = useState(null);
  const [showBugSubmitModal, setShowBugSubmitModal] = useState(false);
  const classes = useStyles();

  const handleBugSubmit = () => {
    setShowBugSubmitModal(false);
    window.open('mailto:VOTE411help@lwv.org?subject=Bug Report - Vote411 Guide Admin', '_blank');
    console.log('redirect to mailto link');
  };

  const {
    query: featureFlagQuery,
    isEnabled: itemIsEnabled,
  } = useFeatureFlags();

  // The following code is to simplify the existing success message handling so that it's handled
  // globally rather than in specific page files. Currently only implemented for Campaign Finance.
  const successParam = useQueryParam(SUCCESS_PARAM);

  const { show: showSnackbar } = useContext(AppSnackbarContext);

  useEffect(() => {
    if (successParam && successParam === CAMPAIGN_FINANCE) {
      // Show success message
      showSnackbar(messages.successMessages.campaignFinance.import, 'success');
    }
  }, [successParam]);

  const renderSettingsMenu = () => {
    if (settings && settings.length > 0) {
      return (
        <>
          <Button
            aria-controls="guide-settings"
            aria-haspopup="true"
            className={classes.secondaryNavButton}
            endIcon={<ArrowDropDownIcon />}
            onClick={event => setSettingsMenuEl(event.currentTarget)}
          >
            Settings
          </Button>

          <Menu
            id="guide-settings"
            anchorEl={settingsMenuEl}
            disableScrollLock={true}
            keepMounted
            open={Boolean(settingsMenuEl)}
            onClose={() => setSettingsMenuEl(null)}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {settings.map(settingItem => (
              <MenuItem
                key={settingItem.title}
                onClick={() => {
                  window.location.href = settingItem.href;
                }}
              >{settingItem.title}</MenuItem>
            ))}
          </Menu>
        </>
      );
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <ConfirmationDialog
        cancelButtonText="Cancel"
        confirmButtonText="Report bug"
        heading="Report a bug"
        onCancel={() => setShowBugSubmitModal(false)}
        onConfirm={handleBugSubmit}
        open={showBugSubmitModal}
      >
        <div>
          <Typography component="p" variant="body1">
            <strong>Before you report a bug</strong>, have you checked the <a href="https://www.lwv.org/league-management/vote411-resources-toolkit" target="_blank" rel="noreferrer">LWVEF Resource Toolkit</a>? It includes:
          </Typography>
          <br />

          <ul className={classes.bugSubmitList}>
            <li>Voter Guide training manual</li>
            <li>Training videos for common processes</li>
            <li>Race naming guidelines</li>
            <li>Sample candidate questions</li>
            <li>...and more!</li>
          </ul>

          <a
            className={classes.bugSubmitHelpButton}
            href="https://www.lwv.org/league-management/vote411-resources-toolkit"
            onClick={() => setShowBugSubmitModal(false)}
            rel="noreferrer"
            target="_blank"
          >
            <Button
              color="primary"
              variant="outlined"
              endIcon={<OpenInNewIcon />}
            >
              Go to the LWVEF Resource Toolkit
            </Button>
          </a>
        </div>
      </ConfirmationDialog>

      <AppBar position="fixed" elevation={0}>
        <Toolbar className={classes.secondaryNav}>
          <Container maxWidth="lg">
            <Grid container className={classes.navContainer} alignItems="center">
              <Grid item sm={12} md={4}>
                <a href={rootUrl} className="nav-bar-logos">
                  <img src={logoPath} height="30" className="vote411-logo" />
                  <Typography component="span" variant="h4" className={classes.appName}>
                    {messages.nav.app}
                  </Typography>
                </a>
              </Grid>

              <Grid item sm={12} md={8}>
                <Grid
                  container
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={4}
                >
                  <Grid item>
                    <Button
                      className={classes.secondaryNavButton}
                      onClick={() => setShowBugSubmitModal(true)}
                      startIcon={<BugReportIcon />}
                    >
                      Report a Bug
                    </Button>
                  </Grid>
                  <Grid item>
                    <a href="https://www.lwv.org/league-management/vote411-resources-toolkit" target="_blank" rel="noreferrer">
                      <Button
                        className={classes.secondaryNavButton}
                        startIcon={<HelpIcon />}
                      >
                        Help
                      </Button>
                    </a>
                  </Grid>
                  <Grid item>
                    {renderSettingsMenu()}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Toolbar>

        <Toolbar>
          <Container maxWidth="lg">
            <Grid container className={classes.navContainer} alignItems="center">
              <Grid item>
                <Grid container spacing={6}>
                  {featureFlagQuery.isSuccess && featureFlagQuery.data && navItems.map(item => {
                    // don't render if isSuperAdmin exists and is falsy
                    if ('isSuperAdmin' in item && !item.isSuperAdmin) {
                      return;
                    }
                    if (item.featureFlags && item.featureFlags.length > 0 && !itemIsEnabled(item)) {
                      return;
                    }
                    return (
                      <Grid item key={item.title}>
                        <a href={item.href} className={`${classes.primaryNavItem} ${item.className}`}>
                          {item.title}
                        </a>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
              <Grid item>
                <ImportModalContainer
                  campaignFinanceUrl={campaignFinanceImportUrl}
                  gisUrl={gisImportUrl}
                  messages={messages}
                  raceUrl={raceImportUrl}
                />
              </Grid>
            </Grid>
          </Container>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}

GlobalNavigation.propTypes = {
  campaignFinanceImportUrl: PropTypes.string,
  gisImportUrl: PropTypes.string,
  logoPath: PropTypes.string.isRequired,
  messages: PropTypes.object,
  navItems: PropTypes.arrayOf(PropTypes.shape({
    className: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  })).isRequired,
  raceImportUrl: PropTypes.string,
  rootUrl: PropTypes.string.isRequired,
  settings: PropTypes.arrayOf(PropTypes.shape({
    href: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  })).isRequired,
};

GlobalNavigation.defaultProps = {
  messages: {
    import: {
      title: 'import',
      description: 'There are a couple of different ways that you can import data into the Voter Guide. Select one of the options below to get started on your bulk import.',
      raceCard: {
        title: 'Races & Candidates',
        description: 'Already know the majority or all of your races and candidates? Bulk import will help you save time!',
      },
      gisCard: {
        title: 'GIS District Sets',
        description: 'Import GIS districts from a previous year&lsquo;s guide (must export districts from previous guide first).',
      },
    },
    nav: {
      app: 'Voter Guide',
    },
    common: {
      button: {
        cancel: 'Cancel',
        continue: 'Continue',
      },
    },
    successMessages: {
      campaignFinance: {
        import: 'The CSV file is being processed, you will receive an email with the result of the process.',
      },
    },
  },
  raceImportUrl: '/races/import',
  gisImportUrl: '/districts/import',
  campaignFinanceImportUrl: '/campaign-finance/import',
};

export default GlobalNavigation;
