import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    marginLeft: theme.spacing(2),
  },
}));

export {
  useStyles,
};
