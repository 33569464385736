import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
// import { makeStyles } from '@material-ui/styles';
import {
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import SelectControl from 'components/SelectControl';
import DistrictSelectorTracker from 'helpers/DistrictSelectorTracker';
import fetchUtil from 'helpers/Fetch';

// Map of selector types to the string value that we are using to represent them in other maps
const SELECTOR_TYPES = new Map([
  ['S', 'SW'],
  ['H', 'USH'],
  ['U', 'SUH'],
  ['L', 'SLH'],
  ['C', 'C'],
  ['G', 'GIS_SET'],
  ['M', 'SET'],
  ['Z', 'zip'],
  ['O', 'composite'],
]);

// Map of selector types to the keys that would appear in the DistrictSelectorTracker object
const DEPENDENT_PICKLIST_KEYS = {
  USH: 'ushouse',
  C: 'county',
  SUH: 'stateupperhouse',
  SLH: 'statelowerhouse',
  zip: 'zip',
  composite: 'composite',
  GIS_SET: 'GIS_SET',
  SET: 'SET',
};

// const useStyles = makeStyles({
//   flexSelectors: {
//     display: 'flex',
//   },
// });

/**
 * This component adds the dependent district selector picklists to the page.
 * It handles all fetching and setting of state, and will pass a district object
 * up the chain upon a final selection being made or updated.
 */
const DistrictSelectorContainer = ({
  district,
  error,
  // flex,
  guideId,
  onChange,
}) => {
  const [loading, setLoading] = useState(false);
  // The selections state uses the DistrictSelectionsTracker object (DistrictSelectorTracker.js).
  // This class is basically a glorified map, that allows us to track all selections without doing crazy object.prototype.has everywhere.
  // Under the hood, it is just an array of objects, but this will help us to keep those objects consistent while adding helpful utilities.
  const [selectorsState, setSelectorsState] = useState(new DistrictSelectorTracker());

  // This object is a map of office types to their dependent selectors
  // Each has a key for the DistrictSelectorTracker object, a type for the URL params, a label, a URL for fetching, and additional URL params
  const OFFICE_TYPES_DEPENDENCIES = {
    // State-wide district
    SW: [],
    // County-wide District
    C: [{
      key: DEPENDENT_PICKLIST_KEYS.C,
      type: 'C',
      label: 'Select a County*',
      url: '/api/v1/district',
      additionalContext: [{ selectorKey: 'state', urlParam: 'state_id' }],
    }],
    // US House
    USH: [{
      key: DEPENDENT_PICKLIST_KEYS.USH,
      type: 'H',
      label: 'Select a District*',
      url: '/api/v1/district',
      additionalContext: [{ selectorKey: 'state', urlParam: 'state_id' }],
    }],
    // State Upper House
    SUH: [{
      key: DEPENDENT_PICKLIST_KEYS.SUH,
      type: 'U',
      label: 'Select a District*',
      url: '/api/v1/district',
      additionalContext: [{ selectorKey: 'state', urlParam: 'state_id' }],
    }],
    // State Lower House
    SLH: [{
      key: DEPENDENT_PICKLIST_KEYS.SLH,
      type: 'L',
      label: 'Select a District*',
      url: '/api/v1/district',
      additionalContext: [{ selectorKey: 'state', urlParam: 'state_id' }],
    }],
    zip: [{
      key: DEPENDENT_PICKLIST_KEYS.zip,
      type: 'Z',
      label: 'Select a District*',
      url: '/api/v1/district',
      additionalContext: [{ selectorKey: '', urlParam: 'guide_id', value: guideId }],
    }],
    composite: [{
      key: DEPENDENT_PICKLIST_KEYS.zip,
      type: 'O',
      label: 'Select a District*',
      url: '/api/v1/district',
      additionalContext: [{ selectorKey: '', urlParam: 'guide_id', value: guideId }],
    }],
    // GIS and District Sets are going to need their own fetchDynamicOptions conditional for type working slightly differently
    GIS_SET: [{
      key: DEPENDENT_PICKLIST_KEYS.GIS_SET,
      type: 'G',
      label: 'Select a District*',
      url: `/api/v1/guide/${guideId}/district/gis/`,
      additionalContext: [{ selectorKey: 'district-type', urlParam: '' }],
    }],
    SET: [{
      key: DEPENDENT_PICKLIST_KEYS.SET,
      type: 'M',
      label: 'Select a District*',
      url: `/api/v1/guide/${guideId}/district/set/`,
      additionalContext: [{ selectorKey: 'district-type', urlParam: '' }],
    }],
  };

  /**
   * Function that handles the first selector, state, being changed by the user. Includes a useRef below call in order to keep the state variables
   * up to date.
   * @param {*} event Event emmitted by the HTML element. Use event.target.value to access the id of the selected element.
   */
  const handleStateSelectorChange = async event => {
    const newSelectors = selectorsState.copy();
    if (newSelectors.checkSelection('state', event.target.value)) {
      // valid selection, reset to just state being selected
      newSelectors.reset();
      newSelectors.setSelection('state', event.target.value);
      newSelectors.show('state');

      if (newSelectors.isSelected('state')) {
        // office type is always next
        newSelectors.addDynamic(
          'district-type', // key
          changeEvent => handleOfficeTypeChangeRef.current(changeEvent), // onChange
          true, // isInScope
          { // dependency details
            key: 'district-type',
            label: 'Select a district type*',
            url: '/api/v1/district/type-options',
            additionalContext: [{ selectorKey: 'state', urlParam: 'state_id' }],
          },
          true, // shouldUseGroupedOptions
        );

        setLoading(true);
        // fetch dynamic options immediately
        await newSelectors.fetchDynamicOptions('district-type');
        onChange({});
        setLoading(false);
      }

      setSelectorsState(newSelectors);
      onChange({});
      return;
    }

    // invalid selection, reset everything and just show blank state selector
    newSelectors.reset();
    newSelectors.show('state');
    setSelectorsState(newSelectors);
  };
  // keep function up to date everywhere we use it
  const handleStateSelectorChangeRef = useRef(handleStateSelectorChange);
  useEffect(() => {
    handleStateSelectorChangeRef.current = handleStateSelectorChange;
  });

  /**
   * Function that handles the second selector, office type, being changed by the user. Includes a useRef below call in order to keep the state variables
   * up to date.
   * @param {*} event Event emmitted by the HTML element. Use event.target.value to access the id of the selected element.
   */
  const handleOfficeTypeChange = async event => {
    const newSelectors = selectorsState.copy();
    if (newSelectors.checkSelection('district-type', event.target.value)) {
      // valid selection, first set it in our tracker
      newSelectors.setSelection('district-type', event.target.value);

      if (typeof event.target.value == 'string') {
        // this means it is a simple type
        if (OFFICE_TYPES_DEPENDENCIES[event.target.value].length > 0) {
          // if the office type has a dependent picklist, show it by adding it to the tracker
          const dependentSelectorKey = DEPENDENT_PICKLIST_KEYS[event.target.value];
          // clear out this dependent picklist if it exists
          newSelectors.hideAllKeys(Object.values(DEPENDENT_PICKLIST_KEYS));

          const dependentSelectorDetails = OFFICE_TYPES_DEPENDENCIES[event.target.value][0];
          newSelectors.addDynamic(
            dependentSelectorKey,
            changeEvent => handleDependencySelectorChangeRef.current(changeEvent, dependentSelectorKey),
            true,
            dependentSelectorDetails,
          );

          setLoading(true);
          // fetch dynamic options immediately
          await newSelectors.fetchDynamicOptions(dependentSelectorKey);
          onChange({});
          setLoading(false);
        } else {
          // there are no dependent selectors, so output the state
          newSelectors.hideAllKeys(Object.values(DEPENDENT_PICKLIST_KEYS));
          onChange(newSelectors.getSelectionFullObject('state'), newSelectors.breadcrumbs);
        }
      } else {
        // integer id, so we know it's a complex type, either GIS or DSet
        // 1. Check type, either 'GIS_SET' or 'SET'
        const fullDistrictSelectionObject = newSelectors.getSelectionFullObject('district-type');
        const districtType = fullDistrictSelectionObject.type;

        // 2. Use OFFICE_TYPES_DEPENDENCIES to get the propery dependency object. Use it on type, not event.target.value
        if (OFFICE_TYPES_DEPENDENCIES[districtType].length > 0) {
          const dependentSelectorKey = DEPENDENT_PICKLIST_KEYS[districtType];
          // hide all dependent selectors
          newSelectors.hideAllKeys(Object.values(DEPENDENT_PICKLIST_KEYS));

          const dependentSelectorDetails = OFFICE_TYPES_DEPENDENCIES[districtType][0];
          newSelectors.addDynamic(
            dependentSelectorKey,
            changeEvent => handleDependencySelectorChangeRef.current(changeEvent, dependentSelectorKey),
            true,
            dependentSelectorDetails,
          );

          setLoading(true);
          // fetch dynamic options immediately
          await newSelectors.fetchComplexOptions(dependentSelectorKey);
          onChange({});
          setLoading(false);
        } else {
          // TODO: remove after dev, this case should never happen
          console.error('Something went wrong');
        }

        // 3. Follow the same process as above, replacing event.target.value for type everywhere it's used.
      }

      setSelectorsState(newSelectors);
      return;
    }

    // invalid selection
    newSelectors.setSelection('district-type', '');
    // hide all dependent selectors
    newSelectors.hideAllKeys(Object.values(DEPENDENT_PICKLIST_KEYS));
    setSelectorsState(newSelectors);
  };

  const handleOfficeTypeChangeRef = useRef(handleOfficeTypeChange);
  useEffect(() => {
    handleOfficeTypeChangeRef.current = handleOfficeTypeChange;
  });

  /**
   * Function that handles any dependent selector being changed by the user. Includes a useRef below call in order to keep the state variables
   * up to date.
   * @param {*} event Event emmitted by the HTML element. Use event.target.value to access the id of the selected element.
   */
  const handleDependencySelectorChange = (event, selectorKey) => {
    const newSelectors = selectorsState.copy();
    if (newSelectors.checkSelection(selectorKey, event.target.value)) {
      newSelectors.setSelection(selectorKey, event.target.value);

      onChange(newSelectors.getSelectionFullObject(selectorKey));

      setSelectorsState(newSelectors);
      return;
    }

    // invalid selection
    newSelectors.setSelection(selectorKey, '');
    onChange({});
    setSelectorsState(newSelectors);
  };

  const handleDependencySelectorChangeRef = useRef(handleDependencySelectorChange);
  useEffect(() => {
    handleDependencySelectorChangeRef.current = handleDependencySelectorChange;
  });

  // on component mount
  useEffect(async () => {
    // first, load the states from the database for the first picklist
    const statesList = await fetchUtil('/api/v1/district/states', 'GET', {});
    const formattedStatesList = statesList.map(s => ({ ...s, value: s.id }));
    const newSelectorTracker = new DistrictSelectorTracker();
    newSelectorTracker.addStatic('state', formattedStatesList, event => handleStateSelectorChangeRef.current(event), true, 'Choose a state*');

    // This code handles setting the picklists initial values when a district is passed in as a prop.
    // The passed in district must have a type.discriminator property.
    if (district && Object.prototype.hasOwnProperty.call(district, 'type')) {
      if (SELECTOR_TYPES.has(district.type.discriminator)) {
        // setSelection('officeType', SELECTOR_TYPES.get(district.type.discriminator));
        await setInitialSelection(district, newSelectorTracker);
      } else {
        setSelectorsState(newSelectorTracker);
      }
    } else {
      setSelectorsState(newSelectorTracker);
    }
  }, []);

  // This function handles logic for setting initial state if a district is passed in as a prop.
  const setInitialSelection = async (initDistrict, tracker) => {
    // this will include the parent state
    const fullDistrictFromDefault = await fetchUtil(`/api/v1/guide/${guideId}/district/${initDistrict.id}`, 'GET');

    // get initial values for district type
    const DISTRICT_TYPE_MAPPER = SELECTOR_TYPES.get(initDistrict.type.discriminator);
    const DISTRICT_TYPE_DEPENDENCIES = OFFICE_TYPES_DEPENDENCIES[DISTRICT_TYPE_MAPPER];

    // add dynamic selector for district type
    tracker.addDynamic(
      'district-type', // key
      changeEvent => handleOfficeTypeChangeRef.current(changeEvent), // onChange
      true, // isInScope
      { // dependency details
        key: 'district-type',
        label: 'Select a district type*',
        url: '/api/v1/district/type-options',
        additionalContext: [{ selectorKey: 'state', urlParam: 'state_id' }],
      },
      true, // shouldUseGroupedOptions
    );

    setLoading(true);

    // this means it's some sort of state-wide district, so we know we can just brute force the values in
    if (DISTRICT_TYPE_DEPENDENCIES.length === 0) {
      tracker.setSelection('state', fullDistrictFromDefault.id);
      // fetch dynamic options now that state is set
      await tracker.fetchDynamicOptions('district-type');
      tracker.setSelection('district-type', DISTRICT_TYPE_MAPPER);
      tracker.show('district-type');
    } else {
      if (fullDistrictFromDefault.type.id === 'GIS') {
        const gisDistrict = await fetchUtil(`/api/v1/guide/${guideId}/district/gis/district/${fullDistrictFromDefault.id}`, 'GET', {});
        // Normalize GIS District after fetching
        tracker.setSelection('state', gisDistrict.geoset.containingDistrict.id);
        // fetch dynamic options now that state is set
        await tracker.fetchDynamicOptions('district-type');
        tracker.setSelection('district-type', gisDistrict.geoset.id);
      } else if (fullDistrictFromDefault.type.id === 'DSM') {
        const setDistrict = await fetchUtil(`/api/v1/guide/${guideId}/district/set/district/${fullDistrictFromDefault.id}`, 'GET', {});

        let stateIdForTracker = setDistrict.dset.votingDistrict.id;

        // this means we didn't find the state, we need to go up one more layer
        if (['C', 'USH', 'SUH', 'SLH'].indexOf(setDistrict.dset.votingDistrict.type.id) >= 0) {
          const fullContainer = await fetchUtil(`/api/v1/guide/${guideId}/district/${setDistrict.dset.votingDistrict.id}`, 'GET');
          stateIdForTracker = fullContainer.state.id;
        }

        // Normalize district set member record
        tracker.setSelection('state', stateIdForTracker);
        // fetch dynamic options now that state is set
        await tracker.fetchDynamicOptions('district-type');
        tracker.setSelection('district-type', setDistrict.dset.id);
      } else if (fullDistrictFromDefault.type.id === 'CPD' || fullDistrictFromDefault.type.id === 'ZIP') {
        tracker.setSelection('state', '');

        // fetch dynamic options now that state is set
        await tracker.fetchDynamicOptions('district-type');
        tracker.setSelection('district-type', DISTRICT_TYPE_MAPPER);
      } else {
        tracker.setSelection('state', fullDistrictFromDefault.state.id);
        // fetch dynamic options now that state is set
        await tracker.fetchDynamicOptions('district-type');
        tracker.setSelection('district-type', DISTRICT_TYPE_MAPPER);
      }

      // we've set the value for any district type option
      tracker.show('district-type');

      // now do the final selector
      const dependentSelectorKey = DEPENDENT_PICKLIST_KEYS[DISTRICT_TYPE_MAPPER];

      const dependentSelectorDetails = OFFICE_TYPES_DEPENDENCIES[DISTRICT_TYPE_MAPPER][0];
      tracker.addDynamic(
        dependentSelectorKey,
        changeEvent => handleDependencySelectorChangeRef.current(changeEvent, dependentSelectorKey),
        true,
        dependentSelectorDetails,
      );

      // fetch dynamic options immediately
      if (fullDistrictFromDefault.type.id === 'GIS' || fullDistrictFromDefault.type.id === 'DSM') {
        await tracker.fetchComplexOptions(dependentSelectorKey);
      } else {
        await tracker.fetchDynamicOptions(dependentSelectorKey);
      }
      tracker.setSelection(dependentSelectorKey, fullDistrictFromDefault.id);
    }

    setSelectorsState(tracker);
    setLoading(false);
  };

  const hasError = !!(error && error.length > 0);

  /**
   * Function responsible for rendering all selectors found in the selectorsState state variable
   * @returns React component
   */
  const renderSelectors = () => (
    selectorsState.getVisible().map(selector => (
      <SelectControl
        className="ga-m-bottom--small"
        disabled={loading}
        error={hasError}
        key={selector.key}
        id={selector.key}
        label={selector.label}
        onChange={event => selector.onChange(event)}
        options={selector.options}
        shouldUseGroupedOptions={selector.shouldUseGroupedOptions}
        value={selector.value}
      />
    ))
  );

  return (
    <div>
      {renderSelectors()}

      <FormControl error={hasError}>
        <FormHelperText>
          {error || ''}
        </FormHelperText>
      </FormControl>
    </div>
  );
};

DistrictSelectorContainer.propTypes = {
  district: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    type: PropTypes.object,
    state: PropTypes.object,
    name: PropTypes.string,
    code: PropTypes.string,
    guide_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    dset_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    geoset_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  error: PropTypes.string,
  // flex: PropTypes.bool,
  guideId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onChange: PropTypes.func,
};

DistrictSelectorContainer.defaultProps = {
  district: null,
  error: '',
  // flex: false,
  onChange: () => {},
};

export default DistrictSelectorContainer;
