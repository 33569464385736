import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';

const LanguagesAppliedToGuide = ({
  languages,
}) => {
  const languageLabels = React.useMemo(() => {
    const labels = languages.map(lang => lang.label);
    labels.unshift('English (default)');
    return labels.join(', ');
  }, [languages]);

  return (
    <div>
      <div>
        <Typography component="h3" variant="h3" paragraph>
          Languages currently applied to the guide
        </Typography>

        <Typography component="p" variant="body2" paragraph>
          {languageLabels}
        </Typography>
      </div>

      <div style={{ display: 'flex' }}>
        <EmojiObjectsOutlinedIcon fontSize="large" style={{ marginRight: '.4rem' }} />

        <Typography component="p" variant="body1">
          To learn more about additional languages or to add more options, please adjust your <a href="/settings/languages">guide settings</a>.
        </Typography>
      </div>
    </div>
  );
};

LanguagesAppliedToGuide.propTypes = {
  languages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
  })),
};

LanguagesAppliedToGuide.defaultProps = {
  languages: [],
};

export default LanguagesAppliedToGuide;
