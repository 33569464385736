/**
 * Util method to fetch and handle errors properly. Should be called from container/management components.
 * @param {string} url - The API URL to fetch data from.
 * @param {string} verb - The HTTP method to use. One of 'GET', 'POST', 'PATCH', 'DELETE', 'PUT'.
 * @param {Object} body - The request body. Not stringified.
 * @param {boolean} [isFile=false] - Indicates whether the response is a file.
 * @param {boolean} [directBody=false] - Indicates whether the body should be sent directly without JSON stringification.
 * @returns {Promise} A promise that resolves with the fetched data or rejects with an error.
 */
const fetchUtil = (url, verb, body, isFile = false, directBody = false) => new Promise((resolve, reject) => {
  const queryCsrfToken = document.querySelector('input[name="csrfToken"]');
  const csrfToken = queryCsrfToken ? queryCsrfToken.value : null;

  if (!csrfToken) {
    // eslint-disable-next-line prefer-promise-reject-errors
    return reject('Invalid or missing CSRF token');
  }

  const tmpHeader = {
    Accept: 'application/json, text/plain, */*',
    'Csrf-Token': csrfToken,
  };

  if (!directBody) {
    tmpHeader['Content-Type'] = 'application/json';
  }

  const fetchBody = {
    method: verb,
    headers: tmpHeader,
  };
  if (!(verb.toUpperCase() === 'GET' || verb.toUpperCase() === 'DELETE')) {
    fetchBody.body = directBody ? body : JSON.stringify(body);
  }

  fetch(url, fetchBody)
    .then(response => {
      if (!response.ok) {
        // handle status codes
        const { status } = response;

        // Check if user is unauthenticated
        if (status === 401) {
          window.location.replace('/login');
          return;
        }

        // Check if user is unauthorized
        if (status === 403) {
          return reject(response);
        }

        response.json()
          .then(res => {
            if (status === 422) {
              if ('errors' in res && res.errors.length > 0 && res.errors[0].validationErrors) {
                const { validationErrors } = res.errors[0];
                return reject(validationErrors);
              }
            }
            return reject(res);
          })
          .catch(e => {
            // We couldn't parse the json, but no big deal.
            // We'll just return the error message
            console.error(e);
            reject(e);
          });
      } else {
        // redirection
        if (response.redirected) {
          window.location.replace(response.url);
          return;
        }

        // checking file response
        if (isFile) {
          resolve(response.blob());
          return;
        }

        if (verb.toUpperCase() === 'DELETE') {
          resolve(response);
        } else {
          response.json()
            .then(res => {
              if (res.data) {
                resolve(res.data);
              } else {
                resolve(res);
              }
            })
            .catch(() => {
              // We couldn't parse the json, but no big deal.
              // We'll just return an empty response body
              resolve({});
            });
        }
      }
    })
    .catch(error => {
      reject(error);
    });
});

const VERBS = {
  GET: 'GET',
  POST: 'POST',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
  PUT: 'PUT',
};

export { VERBS };

export default fetchUtil;
