import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import theme from 'scripts/theme';
import { Wrapper } from '@googlemaps/react-wrapper';
import EmptyState from 'components/EmptyState';
import PageHeader from 'components/PageHeader';
import PageActionsHeader from 'components/PageActionsHeader';
import PageActionsFooter from 'components/PageActionsFooter';
import SnackbarAlert from 'components/SnackbarAlert';
import ConfirmationDialog from 'components/ConfirmationDialog';
import GISDistrictSetEditForm from 'components/Districts/Partials/GISDistrictSetEditForm';
import GISDistrictList from 'components/Districts/Partials/GISDistrictList';
import DistrictMap from 'components/Districts/Partials/DistrictMap';
import fetchUtil from 'helpers/Fetch';

const MAP_DISTRICT_LIMIT = 200;

const useStyles = makeStyles({
  mapContainer: {
    position: 'sticky',
    top: '150px',
    left: 0,
  },
  map: {
    marginBottom: '.4rem',
  },
  danger: {
    color: '#ac1b3d',
    '&:hover': {
      color: '#6B1126',
    },
  },
});

const GISDistrictDetailContainer = ({
  containingDistricts,
  districtSet,
  guideId,
  mapApiKey,
}) => {
  const classes = useStyles();
  const _snackbar = useRef(null);
  const _listRef = useRef();

  const [containingDistrict, setContainingDistrict] = useState({});
  const [deletingDistrict, setDeletingDistrict] = useState(null);
  const [deleteDistrictSubmitting, setDeleteDistrictSubmitting] = useState(false);
  const [deleteSetSubmitting, setDeleteSetSubmitting] = useState(false);
  const [districtFieldFocusId, setDistrictFieldFocusId] = useState(null);
  const [districts, setDistricts] = useState([]);
  const [editContainingDistrictId, setEditContainingDistrictId] = useState(null);
  const [editErrors, setEditErrors] = useState({});
  const [editName, setEditName] = useState('');
  const [name, setName] = useState('');
  const [showDeleteDistrictModal, setShowDeleteDistrictModal] = useState(false);
  const [showDeleteSetModal, setShowDeleteSetModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [showMap, setShowMap] = useState(true);
  const [showCancelModal, setShowCancelModal] = useState(false);

  useEffect(() => {
    setContainingDistrict(districtSet.containingDistrict);
    setDistricts(districtSet.districts);
    setEditContainingDistrictId(districtSet.containingDistrict.id);
    setEditName(districtSet.name);
    setName(districtSet.name);
  }, [districtSet]);

  useEffect(() => {
    if (districts.length > MAP_DISTRICT_LIMIT) {
      setShowMap(false);
    }
  }, [districts]);

  const showError = (message) => {
    _snackbar.current.show('error', message);
  };

  const showSuccess = (message) => {
    _snackbar.current.show('success', message);
  };

  const handleConfirmCancel = () => {
    window.location.href = '/districts';
  };

  const handleConfirmDeleteSetModal = () => {
    setDeleteSetSubmitting(true);

    fetchUtil(`/api/v1/guide/${guideId}/district/gis/${districtSet.id}`, 'DELETE')
      .then(() => {
        // Redirect user to the district dashboard and show a snackbar notification
        window.location.href = '/districts?showSuccessMsgFrom=deleteGisDistrictSet';
      })
      .catch(error => {
        const status = error?.status;
        console.error(error);

        setDeleteSetSubmitting(false);

        if (status && status == 403) {
          showError('Only guide admins are allowed to delete GIS District Sets. Contact your guide admin for assistance.');
          return;
        }

        if (error && error.data) {
          showError(error.data);
          return;
        }

        showError('There was an issue deleting your GIS District Set. Try again or contact your guide admin if this continues.');
      });
  };

  const handleHideDeleteDistrictModal = () => {
    setDeletingDistrict(null);
    setShowDeleteDistrictModal(false);
  };

  const handleShowDeleteDistrictModal = (districtId) => {
    const districtMatch = districts.find(d => d.id == districtId);

    if (districtMatch) {
      setDeletingDistrict(districtMatch);
      setShowDeleteDistrictModal(true);
    }
  };

  const handleConfirmDeleteDistrictModal = () => {
    _snackbar.current.hide();

    setDeleteDistrictSubmitting(true);

    fetchUtil(`/api/v1/guide/${guideId}/district/gis/${districtSet.id}/district/${deletingDistrict.id}`, 'DELETE')
      .then(() => {
        const newDistricts = districts.filter(dist => dist.id !== deletingDistrict.id);

        setDeletingDistrict(null);
        setDeleteDistrictSubmitting(false);
        setDistricts(newDistricts);
        setShowDeleteDistrictModal(false);

        showSuccess(`${deletingDistrict.name} successfully deleted`);
      })
      .catch(error => {
        console.error(error);

        setDeletingDistrict(null);
        setDeleteDistrictSubmitting(false);
        setShowDeleteDistrictModal(false);

        if (error && error.data) {
          showError(error.data);
          return;
        }

        showError('There was an issue deleting your GIS District. Try again or contact your guide admin if this continues.');
      });
  };

  const handleCloseEditModal = () => {
    setEditContainingDistrictId(containingDistrict.id);
    setEditName(name);
    setEditErrors({});
    setShowEditModal(false);
  };

  const handleInputChange = (event) => {
    if (event.target.name == 'name') {
      setEditName(event.target.value);
    }

    if (event.target.name == 'containingDistrict') {
      setEditContainingDistrictId(event.target.value);
    }
  };

  const handleEditSubmit = () => {
    setSubmitting(true);

    fetchUtil(`/api/v1/guide/${guideId}/district/gis/${districtSet.id}`, 'PATCH', {
      containerId: editContainingDistrictId,
      geoDistricts: [],
      id: districtSet.id,
      name: editName,
    })
      .then(() => {
        const newContainingDistrict = containingDistricts.find(dist => dist.id == editContainingDistrictId);

        setContainingDistrict(newContainingDistrict);
        setName(editName);
        setShowEditModal(false);
        setSubmitting(false);

        showSuccess('Your GIS District Set was updated successfully!');
      })
      .catch(error => {
        console.error(error);

        setEditContainingDistrictId(containingDistrict.id);
        setEditName(name);
        setEditErrors(error);
        setSubmitting(false);

        showError('There was an issue updating your GIS District Set. Try again or contact your guide admin if this continues.');
      });
  };

  const handleDistrictClick = id => {
    const matchIndex = districts.findIndex(d => d.id == id);

    if (matchIndex >= 0) {
      _listRef.current.scrollToItem(matchIndex, 'start');
      setDistrictFieldFocusId(id);
    }
  };

  const MapHiddenMessage = () => (
    <EmptyState>
      <p className="ga-m-bottom--large">
        The district map is hidden for district sets that contain more than <strong>{MAP_DISTRICT_LIMIT} districts</strong> to maintain better page performance.
      </p>

      <Button
        color="primary"
        onClick={() => setShowMap(true)}
        size="large"
        variant="outlined"
      >
        Show map
      </Button>
    </EmptyState>
  );

  const MemoizedMap = useMemo(
    () => (
      <div className={classes.mapContainer}>
        <DistrictMap
          className={classes.map}
          districts={districts}
          onDistrictClick={id => handleDistrictClick(id)}
        />

        <Button size="small" color="primary" onClick={() => setShowMap(false)}>
          Hide map
        </Button>
      </div>
    ),
    [districts],
  );

  return (
    <ThemeProvider theme={theme}>
      <Wrapper apiKey={mapApiKey}>
        <SnackbarAlert ref={_snackbar} />

        <ConfirmationDialog
          cancelButtonText="Cancel"
          confirmButtonText="Confirm"
          heading="Are you sure you want to cancel?"
          onCancel={() => setShowCancelModal(false)}
          onConfirm={handleConfirmCancel}
          open={showCancelModal}
        >
          <Typography component="p" variant="body1">
            If you exit this page, all unsaved changes will be lost.
          </Typography>
        </ConfirmationDialog>

        <ConfirmationDialog
          heading={`Delete ${deletingDistrict?.name}?`}
          onCancel={handleHideDeleteDistrictModal}
          onConfirm={handleConfirmDeleteDistrictModal}
          open={showDeleteDistrictModal}
          submitting={deleteDistrictSubmitting}
        >
          <Typography component="p" variant="body1">
            Are you sure you want to delete <strong>GIS District {deletingDistrict?.name}</strong>? It will be <strong>permanently removed</strong> from this GIS district!
          </Typography>
        </ConfirmationDialog>

        <ConfirmationDialog
          heading={`Delete ${districtSet.name}`}
          onCancel={() => setShowDeleteSetModal(false)}
          onConfirm={handleConfirmDeleteSetModal}
          open={showDeleteSetModal}
          submitting={deleteSetSubmitting}
        >
          <Typography component="p" variant="body1">
            Are you sure you want to delete this district set? It and all of its child districts will be <strong>permanently deleted</strong>!
          </Typography>
        </ConfirmationDialog>

        <PageHeader
          breadcrumbText="District Dashboard"
          breadcrumbUrl="/districts"
          heading={name}
          subheading={`Containing District: ${containingDistrict.name}`}
        />

        <PageActionsHeader>
          <Button
            color="primary"
            onClick={() => setShowCancelModal(true)}
            size="small"
          >
            Cancel
          </Button>

          <Button
            className={classes.danger}
            size="small"
            onClick={() => setShowDeleteSetModal(true)}
          >
            Delete
          </Button>

          <Button
            color="secondary"
            size="small"
            variant="outlined"
            onClick={() => setShowEditModal(true)}
          >
            Edit
          </Button>
        </PageActionsHeader>

        <div className="ga-container">
          <div className="mdc-layout-grid">
            <div className="mdc-layout-grid__inner">
              <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">

                <GISDistrictSetEditForm
                  containingDistricts={containingDistricts}
                  containingDistrictId={editContainingDistrictId}
                  errors={editErrors}
                  name={editName}
                  onClose={handleCloseEditModal}
                  onInputChange={handleInputChange}
                  onSubmit={handleEditSubmit}
                  show={showEditModal}
                  submitting={submitting}
                />

                <Grid container spacing={3}>
                  {districts && districts.length > 0 && (
                    <>
                      <Grid item xs={12} sm={6}>
                        {showMap ? MemoizedMap : MapHiddenMessage()}
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <GISDistrictList
                          districts={districts}
                          focusId={districtFieldFocusId}
                          guideId={guideId}
                          heading="Districts"
                          onDelete={handleShowDeleteDistrictModal}
                          onUpdateError={() => showError('There was an issue updating your GIS District. Try again or contact your guide admin if this continues.')}
                          onUpdateSuccess={() => showSuccess('District was successfully updated!')}
                          ref={_listRef}
                        />
                      </Grid>
                    </>
                  )}

                  {(!districts || districts.length <= 0) && (
                    <Grid item xs={12}>
                      <EmptyState>
                        This GIS District Set has no districts. They were either not imported or were removed some time after import. The GIS data set should either be re-uploaded or this district set should be removed.
                      </EmptyState>
                    </Grid>
                  )}
                </Grid>
              </div>
            </div>
          </div>
        </div>

        <PageActionsFooter sticky>
          <Button
            color="primary"
            onClick={() => setShowCancelModal(true)}
            size="small"
          >
            Cancel
          </Button>

          <Button
            className={classes.danger}
            size="small"
            onClick={() => setShowDeleteSetModal(true)}
          >
            Delete
          </Button>

          <Button
            color="secondary"
            size="small"
            variant="outlined"
            onClick={() => setShowEditModal(true)}
          >
            Edit
          </Button>
        </PageActionsFooter>
      </Wrapper>
    </ThemeProvider>
  );
};

GISDistrictDetailContainer.propTypes = {
  containingDistricts: PropTypes.array,
  districtSet: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    containingDistrict: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string,
    }).isRequired,
    districts: PropTypes.array.isRequired,
  }).isRequired,
  guideId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  mapApiKey: PropTypes.string.isRequired,
};

GISDistrictDetailContainer.defaultProps = {
  containingDistricts: [],
};

export default GISDistrictDetailContainer;
