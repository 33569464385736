import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import { CircularProgress, Typography } from '@material-ui/core';
import theme from 'scripts/theme';
import ConfirmationDialog from 'components/ConfirmationDialog';
import EmptyState from 'components/EmptyState';
import PageHeader from 'components/PageHeader';
import SnackbarAlert from 'components/SnackbarAlert';
import RaceQuestionSetForm from 'components/RaceQuestions/RaceQuestionSetForm';
import RaceQuestionSetAccordion from 'components/RaceQuestions/RaceQuestionSetAccordion';

const styles = {
  divider: {
    margin: '2.4rem 0 2.6rem',
  },
  listHeading: {
    marginBottom: '2.4rem',
  },
};

const RaceQuestionLandingContainer = ({
  breadcrumbText,
  breadcrumbUrl,
  heading,
  subheading,
  classes,
  deleteUrl,
  getUrl,
  postUrl,
  languages,
  messages,
  questionSetRootUrl,
}) => {
  const [confirmationDialogBody, setConfirmationDialogBody] = React.useState({});
  const [confirmationDialogHeading, setConfirmationDialogHeading] = React.useState('');
  const [confirmationDialogId, setConfirmationDialogId] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [submitting, setSubmitting] = React.useState(false);
  const [questionSets, setQuestionSets] = React.useState([]);
  const [showConfirmationDialog, setShowConfirmationDialog] = React.useState(false);

  const _snackbar = React.useRef();

  React.useEffect(() => {
    setLoading(true);

    fetch(getUrl)
      .then(res => res.json())
      .then(res => {
        setQuestionSets(res);
        setLoading(false);
      })
      .catch(error => {
        console.error(error.message);

        setLoading(false);

        handleError('GET');
      });
  }, [getUrl]);

  const handleError = (action, message) => {
    let snackbarMessage;

    _snackbar.current.hide();

    if (message) {
      _snackbar.current.show('error', message);
      return;
    }

    switch (action) {
      case 'POST':
        snackbarMessage = messages.questionSet.add.error;
        break;
      case 'PATCH':
        snackbarMessage = messages.questionSet.edit.error;
        break;
      case 'DELETE':
        snackbarMessage = messages.questionSet.delete.error;
        break;
      case 'FORBIDDEN':
        snackbarMessage = messages.forbidden;
        break;
      default:
        snackbarMessage = messages.common.error.unknown;
        break;
    }

    _snackbar.current.show('error', snackbarMessage);
  };

  const handleSuccess = (action, data) => {
    let message = messages.common.success;
    let updatedQuestionSets;

    _snackbar.current.hide();

    switch (action) {
      case 'PATCH':
        updatedQuestionSets = questionSets.slice(0);
        updatedQuestionSets.forEach(record => {
          if (record.id === data.id) {
            record.name = data.name;
          }
        });
        message = messages.questionSet.edit.success;

        setQuestionSets(updatedQuestionSets);

        _snackbar.current.show('success', message);
        break;
      case 'DELETE':
        updatedQuestionSets = questionSets.filter(record => record.id !== data.id);
        message = messages.questionSet.delete.success;

        setQuestionSets(updatedQuestionSets);

        _snackbar.current.show('success', message);
        break;
      case 'POST':
      default:
        window.location.href = `/questions/${data.id}`;
        break;
    }
  };

  const handleQuestionSetDelete = (id, name) => {
    setShowConfirmationDialog(true);
    setConfirmationDialogBody((
      <div>
        <Typography component="p" variant="body1" paragraph>
          Are you sure you want to delete the <strong>{name}</strong> question set? Individual answers to questions in this set <strong>will be unaffected</strong> by this action.
        </Typography>

        <Typography component="p" variant="body1">
          <em><strong>NOTE:</strong> If candidate answers have already been received for questions in this set, the set can be restored from the recycling bin.</em>
        </Typography>
      </div>
    ));
    setConfirmationDialogHeading(`Delete ${name}?`);
    setConfirmationDialogId(id);
  };

  const handleCancelDeleteConfirmation = () => {
    setConfirmationDialogId(null);
    setShowConfirmationDialog(false);
  };

  const handleConfirmDeleteConfirmation = () => {
    const csrfToken = document.querySelector('input[name="csrfToken"]').value;

    setSubmitting(true);

    fetch(`${deleteUrl}/${confirmationDialogId}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'Csrf-Token': csrfToken,
      },
    })
      .then(response => {
        const { status } = response;

        // If we get a 403, we assume user has been logged out and
        // redirect them to the login page.
        if (status === 403) {
          handleError('FORBIDDEN');
        }

        if (response.redirected == true) {
          window.location.replace(response.url);
        }

        if (response.ok) {
          handleSuccess('DELETE', { id: confirmationDialogId });

          setShowConfirmationDialog(false);
          setSubmitting(false);
        } else {
          throw new Error(response);
        }
      })
      .catch(error => {
        console.error(error);

        setShowConfirmationDialog(false);
        setSubmitting(false);

        handleError('DELETE');
      });
  };

  const renderQuestionSetList = () => {
    if (loading) {
      return (
        <div>
          <CircularProgress />
        </div>
      );
    }

    if (questionSets.length <= 0) {
      return (
        <EmptyState>
          <p className="ga-m-bottom--large">
            {messages.questionSet.emptyState}
          </p>
        </EmptyState>
      );
    }

    return questionSets.map((set) => (
      <RaceQuestionSetAccordion
        key={set.id}
        messages={messages}
        languages={languages}
        onDelete={handleQuestionSetDelete}
        questionSetRootUrl={questionSetRootUrl}
        set={set}
      />
    ));
  };

  return (
    <ThemeProvider theme={theme}>
      <SnackbarAlert ref={_snackbar} />

      <PageHeader
        breadcrumbText={breadcrumbText}
        breadcrumbUrl={breadcrumbUrl}
        heading={heading}
        subheading={subheading}
      />

      <div className="ga-container">
        <div className="mdc-layout-grid">
          <div className="mdc-layout-grid__inner">
            <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">

              <ConfirmationDialog
                cancelButtonText={messages.common.button.cancel}
                confirmButtonText={messages.questionSet.delete.confirm.button}
                heading={confirmationDialogHeading}
                onCancel={handleCancelDeleteConfirmation}
                onConfirm={handleConfirmDeleteConfirmation}
                open={showConfirmationDialog}
                submitting={submitting}
              >
                {confirmationDialogBody}
              </ConfirmationDialog>

              <Typography component="h2" variant="h2" paragraph>
                {messages.questionSet.add.heading}
              </Typography>

              <RaceQuestionSetForm
                buttonText={messages.questionSet.add.button}
                languages={languages}
                onError={handleError}
                onSuccess={handleSuccess}
                outlined
                requiredText={messages.questionSet.add.required}
                submitMethod="POST"
                submitUrl={postUrl}
                validationErrorMessage={messages.questionSet.add.error}
              />

              <hr className={classes.divider} />

              <div className={classes.listHeading}>
                <Typography component="h2" variant="h2">
                  {messages.questionSet.heading}
                </Typography>

                <Typography component="p" variant="body1">
                  {messages.questionSet.subheading}
                </Typography>
              </div>

              {renderQuestionSetList()}
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

RaceQuestionLandingContainer.propTypes = {
  breadcrumbText: PropTypes.string,
  breadcrumbUrl: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  classes: PropTypes.object,
  deleteUrl: PropTypes.string.isRequired,
  getUrl: PropTypes.string.isRequired,
  languages: PropTypes.array.isRequired,
  messages: PropTypes.object,
  postUrl: PropTypes.string.isRequired,
  questionSetRootUrl: PropTypes.string.isRequired,
};

RaceQuestionLandingContainer.defaultProps = {
  breadcrumbText: 'Races & Measures',
  breadcrumbUrl: '/races',
  heading: 'Race Questions',
  subheading: 'Explains what a race question is and how to use it. Check out our help guide.',
  messages: {
    common: {
      button: {
        cancel: 'Cancel',
        delete: 'Delete',
      },
      error: {
        unknown: 'An unknown error occurred. Please contact your guide admin.',
      },
    },
    questionSet: {
      heading: 'Existing race question sets',
      subheading: 'Manage your existing race question sets',
      add: {
        heading: 'Add additional race question sets',
        required: '*Required fields needed to add a new question set',
        button: 'Add Question Set',
        error: 'There was an issue adding your question set. Correct any errors and try again.',
        success: 'Your question set was added successfully!',
      },
      delete: {
        button: 'Delete',
        confirm: {
          button: 'Yes, I\'m sure',
        },
        error: 'There was an issue deleting your question set. Contact your guide admin if this issue continues.',
        success: 'Question set was successfully deleted!',
      },
      edit: {
        button: 'Edit',
        error: 'There was an issue updating your question set. Try again or contact your guide admin if the issue continues.',
        modal: {
          button: 'Update question set',
          heading: 'Editing question set',
          required: '*Required fields needed for your question set',
        },
        success: 'Question set updated successfully!',
      },
      list: {
        heading: 'Question list',
        subheading: 'It\'s recommended to ask candidates 3-5 questions.',
      },
      unbiasedQuestions: {
        button: 'View',
        heading: 'How to write unbiased questions',
        subheading: 'You can revisit our suggestions for how to write unbiased questions at any time.',
      },
      update: {
        button: 'Update',
      },
    },
    raceQuestions: {
      translation: {
        empty: {
          heading: 'There are no translated questions for this language.',
          add: 'Add translations',
        },
      },
    },
  },
};

export default withStyles(styles)(RaceQuestionLandingContainer);
