import PropTypes from 'prop-types';

// uses the VGTContributionCandidate.java class
const VGTContributionCandidatePropTypes = {
  id: PropTypes.number.isRequired,
  index: PropTypes.number,
  candidateName: PropTypes.string,
  candidateId: PropTypes.string.isRequired,
  raceId: PropTypes.string.isRequired,
  raceName: PropTypes.string,
  party: PropTypes.string,
  sourceURL: PropTypes.string,
  dataSource: PropTypes.string,
};

const VGTContributionCandidateDefaultProps = {
  index: 0,
  candidateName: '',
  raceName: '',
  party: '',
  sourceURL: '',
  dataSource: '',
};

export {
  VGTContributionCandidateDefaultProps,
  VGTContributionCandidatePropTypes,
};

export default VGTContributionCandidatePropTypes;
