import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import a11yProps from 'helpers/A11yProps';

function TabPanel(props) {
  const {
    children, value, index, identifier, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...a11yProps.tabPanels(index, identifier)}
      {...other}
    >
      {value === index && (
        <Box mt={2} mb={2}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  identifier: PropTypes.string,
};

TabPanel.defaultProps = {
  identifier: 'scrollable-auto',
};

export default TabPanel;
