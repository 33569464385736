import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import { DestructiveButton, LinkButton } from 'components/buttons';

import CandidateMetadata from 'components/Candidates/Partials/CandidateMetadata';
import { VGTContributionCandidatePropTypes } from 'components/features/campaign-finance/types';
import { convertStringToTitleCase } from 'helpers/ConvertStringToTitleCase';

const CandidateMatch = ({
  messages,
  candidateMatch,
  isSuperAdmin,
  onRemoveMatch,
}) => (
  <Grid
    container
    justifyContent="space-between"
    alignItems="flex-start"
  >
    <Grid
      item
      xs={3}
    >
      <CandidateMetadata text={messages.finance?.labels?.match}>
        {candidateMatch.candidateName}
      </CandidateMetadata>
    </Grid>

    <Grid
      item
      xs={6}
    >
      <CandidateMetadata text={messages.finance?.labels?.source}>
        <LinkButton
          buttonProps={{
            href: candidateMatch.sourceURL,
            target: '_blank',
            rel: 'noreferrer',
            variant: 'text',
            color: 'primary',
            endIcon: <OpenInNewIcon />,
          }}
        >
          {convertStringToTitleCase(candidateMatch.dataSource)}
        </LinkButton>
      </CandidateMetadata>
    </Grid>

    {isSuperAdmin && (
      <Grid
        item
      >
        <DestructiveButton
          buttonProps={{
            variant: 'text',
            onClick: onRemoveMatch,
          }}
        >
          {messages.finance?.actions?.remove}
        </DestructiveButton>
      </Grid>
    )}
  </Grid>
);

CandidateMatch.propTypes = {
  messages: PropTypes.shape({
    finance: PropTypes.shape({
      title: PropTypes.string,
      labels: PropTypes.shape({
        match: PropTypes.string,
        source: PropTypes.string,
      }),
      actions: PropTypes.shape({
        remove: PropTypes.string,
      }),
    }),
  }).isRequired,
  isSuperAdmin: PropTypes.bool,
  candidateMatch: PropTypes.shape(VGTContributionCandidatePropTypes),
  onRemoveMatch: PropTypes.func,
};

CandidateMatch.defaultProps = {
  isSuperAdmin: false,
  candidateMatch: {
    name: 'Test match',
    source: {
      name: 'Fake Source',
      url: 'https://www.example.com',
    },
  },
  onRemoveMatch: () => {},
  // don't define messages defaults here because we want them to be same as CandidateFinanceCard
};

export {
  CandidateMatch,
};
