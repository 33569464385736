import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import {
  Checkbox,
  FormControl,
  FormGroup,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import stateErrors from 'helpers/StateErrors';
import EmptyState from 'components/EmptyState';

const styles = {
  formContainer: {
    paddingTop: '1.75rem',
    paddingBottom: '1.25rem',
  },
  sectionContainer: {
    padding: '1.25rem 0rem',
  },
  itemRows: {
    paddingBottom: '0.625rem',
    marginBottom: '0.625rem',
    borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
    fontFamily: 'Public Sans',
    color: '#212121',
    '&:last-child': {
      borderBottom: 'none',
      paddingBottom: '0rem',
      marginBottom: '0rem',
    },
  },
  label: {
    paddingBottom: '0.75rem',
  },
  defaultList: {
    paddingTop: '0.5rem',
  },
  emptyStateText: {
    textAlign: 'center',
  },

};

class NewBioSetCard extends React.Component {
  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.renderQuestions = this.renderQuestions.bind(this);
    this.getLeagueQuestions = this.getLeagueQuestions.bind(this);
  }

  handleInputChange(event) {
    const { onTitleChange } = this.props;
    const { value } = event.target;
    onTitleChange(value);
  }

  isChecked(question) {
    const { selectedQuestions } = this.props;
    return selectedQuestions.some(sq => sq.id == question.id);
  }

  renderDefaultQuestions() {
    const { questions } = this.props;
    const defaultQuestions = questions.filter((q) => q.default === true);

    return defaultQuestions.map((q, index) => (
      <Grid item xs={12} sm={6} key={index}>
        <p> {q.name} </p>
      </Grid>
    ));
  }

  getLeagueQuestions() {
    const { questions } = this.props;
    return questions.filter((q) => q.default !== true);
  }

  renderQuestions() {
    const { classes, onSelectedQuestionsChange } = this.props;
    const listQuestions = this.getLeagueQuestions();

    if (listQuestions) {
      return listQuestions.map((question, index) => (
        <FormControlLabel
          key={index}
          className={classes.itemRows}
          control={
            <Checkbox
              checked={this.isChecked(question)}
              onChange={() => {
                onSelectedQuestionsChange(question);
              }}
            />
          }
          label={question.name}
        />
      ));
    }
  }

  render() {
    const {
      classes,
      defaultQuestionListLabel,
      emptyStateText,
      errors,
      header,
      inputLabel,
      questionListLabel,
      subtext,
      title,
    } = this.props;

    const leagueQuestions = this.getLeagueQuestions();

    return (
      <Paper elevation={3} className="card-container">
        <div>
          <Typography color="primary" component="h3" variant="h3">
            {header}
          </Typography>

          <Typography component="p" variant="body1" paragraph>
            {subtext}
          </Typography>

          <Grid container className={classes.formContainer}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth xs={3}>
                <TextField
                  id="new-bio-set"
                  error={stateErrors.hasError('newSetTitle', errors)} // update with front-end validation
                  helperText={stateErrors.getError('newSetTitle', errors)} // update with front-end validation
                  label={inputLabel}
                  name="title"
                  required
                  variant="filled"
                  onChange={this.handleInputChange}
                  value={title}
                                />
              </FormControl>
            </Grid>
          </Grid>

          <div className={classes.sectionContainer}>
            <p className={`${classes.label} card-label`}>{defaultQuestionListLabel}</p>
            <Grid container spacing={2} className={classes.defaultList}>
              {this.renderDefaultQuestions()}
            </Grid>
          </div>

          <div className={classes.sectionContainer}>
            <p className={`${classes.label} card-label`}>{questionListLabel}</p>
            {(leagueQuestions.length > 0) ? (
              <FormGroup>
                {this.renderQuestions()}
              </FormGroup>
            ) : (
              <EmptyState>
                <p>{emptyStateText}</p>
              </EmptyState>
            )}
          </div>
        </div>
      </Paper>
    );
  }
}

NewBioSetCard.propTypes = {
  classes: PropTypes.object,
  header: PropTypes.string,
  subtext: PropTypes.string,
  inputLabel: PropTypes.string,
  questionListLabel: PropTypes.string,
  title: PropTypes.string,
  defaultQuestionListLabel: PropTypes.string,
  emptyStateText: PropTypes.string,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      default: PropTypes.bool,
      id: PropTypes.number,
      index: PropTypes.number,
      maxl: PropTypes.number,
      name: PropTypes.string,
      type: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ),
  onTitleChange: PropTypes.func,
  selectedQuestions: PropTypes.array,
  onSelectedQuestionsChange: PropTypes.func,
  errors: PropTypes.object,
};

NewBioSetCard.defaultProps = {
  header: 'Default header',
  subtext: 'Default subtext',
  questions: [],
  inputLabel: 'Biographical set title',
  title: '',
  onTitleChange: () => {},
  selectedQuestions: [],
  onSelectedQuestionsChange: () => {},
  questionListLabel: 'Biographical questions you can add to your set',
  defaultQuestionListLabel: 'Default questions included in every set',
  emptyStateText: "Your League has not yet added any custom biographical questions. Once you've created this race, you can add custom questions or manage your new biographical set by going to the Biographical Questions section of your guide and editing it there.",
  errors: {},
};

export default withStyles(styles)(NewBioSetCard);
